import { get } from 'lodash';

const styles = ({ color }) => ({
  content: {
    margin: 36,
    minHeight: 'calc(100vh - 125px)',
    backgroundColor: get(color, 'uiBackground'),
    marginTop: 0,
    padding: '24px 0px 36px 0px',
    '& button': {
      '&:focus': {
        outlineWidth: '1px !important',
        outlineOffset: '1px !important',
        outlineStyle: 'solid !important',
      },
    },
  },
  bodyContent: {
    display: 'block',
  },
  gridContainer: {
    display: 'inline-flex',
    width: '100%',
    paddingLeft: 36,
  },
  tabsContainer: {
    display: 'inline-flex',
  },
  divider: {
    backgroundColor: get(color, 'bodyText'),
  },
  notificationWidthClass: {
    width: '96%',
    padding: '0 15px',
    marginLeft: 0,
  },
  appFooter: {
    transition: '1s',
  },
});

export default styles;
