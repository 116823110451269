import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';
import { get, some } from 'lodash';
import { oneOfType, func, bool, object, string, number, arrayOf } from 'prop-types';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Button,
  Checkbox,
  Typography,
  Grid,
  withStyles,
  Dialog,
} from '@material-ui/core';
import {
  createKey,
  updateKey,
  deleteKey,
  fetchKeyNameUnique,
  fetchSingleApp,
  fetchProxiesByApiKey,
} from '../../../../actions/application';
import {
  getCreateKeyStatus,
  getUpdateKeyStatus,
  getDeleteKeyStatus,
  getIsUniqueKey,
  getCreatedKey,
  getDeleteKeyErrors,
} from '../../../../reducers/application';

import {
  FormTextField,
  FormButtonGroup,
  AlertDialog,
  AlertMessages,
  LoadingDialog,
} from '../../../../components';
import styles from './styles';
import { hasError } from '../../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { processErrors } from '../utils';
import {
  API_KEY_STATUS_ENABLED,
  API_KEY_STATUS_DISABLED,
  ALERT_SUCCESS,
  ALERT_ERROR,
  API_KEY_STATUS_DELETE_FAILED,
} from '../../../../constants';
import { Close, ReportProblem } from '@material-ui/icons';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const keyStatusOptions = [
  {
    key: API_KEY_STATUS_ENABLED,
    label: getI18nFormattedMessage('label.application.key.state.enabled'),
  },
  {
    key: API_KEY_STATUS_DISABLED,
    label: getI18nFormattedMessage('label.application.key.state.disabled'),
  },
];

export const isStatusFieldDisabled =
  ({ defaultKey, keyIsDefault }) => (defaultKey || keyIsDefault);

export const isDefaultKeyFieldDisabled =
  ({ editable, defaultKey, status, keyStatus, keyCount }) => (
    some([
      !editable,
      defaultKey,
      status === API_KEY_STATUS_DISABLED, // Status persisted is Disabled
      keyStatus === API_KEY_STATUS_DISABLED, // Status on the form is Disabled
      keyCount === 0,
    ])
  );

const EditKey = (props) => {
  const {
    idx,
    editable = true,
    applicationUuid,
    details: {
      name = '',
      status = '',
      defaultKey = false,
      oauthCallbackUrl = '',
      oauthScope = '',
      oauthType = null,
      apiKey = '',
      keySecret = '',
      isHashed = '',
      isRegenerateSecret = false,
    },
    isUniqueKey,
    notifyMessages,
    appSecretHashingMetadata,
    closeAction,
    classes,
    deleteKeyStatus='',
    updateKeyStatus='',
    createKeyStatus='',
    createdKey,
    keyCount,
    isLocking,
    deleteKeyErrors,
    fetchSingleApp,
    isAdmin,
    isPortalAdmin,
  } = props;

  const intl = getI18n(useIntl());
  const [keyName, setKeyName] = useState(name);
  const [keyStatus, setKeyStatus] = useState(status);
  const [keyIsDefault, setKeyIsDefault] = useState(defaultKey);
  const [keyOAuthCallbackUrl, setKeyOAuthCallbackUrl] = useState(oauthCallbackUrl);
  const [keyOAuthScope, setKeyOAuthScope] = useState(oauthScope);
  const [keyOAuthType, setKeyOAuthType] = useState('PUBLIC');
  const [keyClientSecret, setKeyClientSecret] = useState(keySecret);
  const [keyIsHashed, setKeyIsHashed] = useState(isHashed);
  const [keyErrors, setKeyErrors] = useState({});
  const [keyRegenerateSecret, setRegenerateSecret] = useState(isRegenerateSecret);
  const [isGenerateSecretDialogOpen, setIsGenerateSecretDialogOpen] =useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [keyCreating, setKeyCreating] = useState(false);
  const [keyUpdating, setKeyUpdating] = useState(false);
  const [keyDeleting, setKeyDeleting] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(localStorage.getItem('notificationMessage'));
  const [notificationStatus, setNotificationStatus] = useState(localStorage.getItem('notificationStatus'));
  const [createdApiKey, setCreatedApiKey] = useState('');
  const [createdSecret, setCreatedSecret] = useState('');
  const closeDialog = () => {
    closeAction();
  }
    
  useEffect(() => { setKeyName(name); }, [name]);
  useEffect(() => { setKeyStatus(status); }, [status]);
  useEffect(() => { setKeyIsDefault(defaultKey); }, [defaultKey]);
  useEffect(() => {
    if(keyCount === (apiKey ? 1 : 0)) {
      setKeyIsDefault(true);
    }
  },[keyCount, apiKey]);
  useEffect(() => { setKeyOAuthCallbackUrl(oauthCallbackUrl); }, [oauthCallbackUrl]);
  useEffect(() => { setKeyOAuthScope(oauthScope); }, [oauthScope]);
  useEffect(() => { oauthType ? setKeyOAuthType(oauthType) : '' }, [oauthType]);
  useEffect(() => { setKeyClientSecret(keySecret); }, [keySecret]);
  useEffect(() => { setKeyIsHashed(isHashed); }, [isHashed]);
  useEffect(() => { setRegenerateSecret(isRegenerateSecret); }, [isRegenerateSecret]);
  const [showProxyErrorAlert, setShowProxyErrorAlert] = useState(false);

  useEffect(() => {
    if(keyDeleting) {
      if(deleteKeyStatus === 'SUCCESS') {
        setKeyDeleting(false);
        setShowLoadingDialog(false);
        notifyMessages(intl.getI18nMessage('label.application.details.keys.key.delete.success'), ALERT_SUCCESS, true);
        closeDialog();
      } else if (deleteKeyStatus === 'FAIL') {
        setKeyDeleting(false);
        setDeleteClicked(false);
        setShowLoadingDialog(false);
        const defaultErrorMessage = intl.getI18nFormattedMessage('label.application.request.submit.error');
        const errorMessage = processErrors(deleteKeyErrors, defaultErrorMessage);
        if(deleteKeyErrors.length > 0 && deleteKeyErrors[0].field === 'proxyCheck') {
          setShowProxyErrorAlert(true);
          notifyMessagesInDialog(errorMessage, ALERT_ERROR, true);
        } else {
          if (deleteKeyErrors.length > 0 && deleteKeyErrors[0].field === 'apiKey') {
            closeDialog();
            notifyMessages(deleteKeyErrors[0].error, ALERT_ERROR, true);
          }
          setShowProxyErrorAlert(false);
          notifyMessagesInDialog(intl.getI18nMessage('label.application.details.keys.key.delete.error'), ALERT_ERROR, true);
        }
        fetchSingleApp(applicationUuid);
      }
    }
  },[deleteKeyStatus]);

  useEffect(() => {
    if(keyUpdating) {
      if(updateKeyStatus === 'SUCCESS') {
        notifyMessages(intl.getI18nMessage(`label.application.details.keys.key.update.${isLocking ? 'request.':''}success`), ALERT_SUCCESS, true);
        closeDialog();
        props.onSaveDone();
      } else if (updateKeyStatus === 'FAIL') {
        notifyMessagesInDialog(intl.getI18nMessage('label.application.details.keys.key.update.error'), ALERT_ERROR, true);
      }
      if(updateKeyStatus) {
        setKeyUpdating(false);
      }
    }
  },[updateKeyStatus]);

  useEffect(() => {
    if(keyCreating && createKeyStatus === 'FAIL') {
      notifyMessagesInDialog(intl.getI18nMessage('label.application.details.keys.key.create.error'), ALERT_ERROR, true);
      setKeyCreating(false);
    }
  },[createKeyStatus]);
  useEffect(() => {
    setKeyErrors({
      ...keyErrors,
      name: (isUniqueKey === false),
    });
  }, [isUniqueKey]);
  useEffect(() => {
    if(createdKey && keyCreating) {
      setCreatedApiKey(createdKey.apiKey);
      setCreatedSecret(createdKey.keySecret);
      setIsGenerateSecretDialogOpen(true);
      props.fetchProxiesByApiKey(createdKey.apiKey);
      setTimeout(() => {
        props.fetchProxiesByApiKey(createdKey.apiKey);
      }, 10000);
    }
  },[createdKey]);

  const notifyMessagesInDialog = (message, status, timeout) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
    if(timeout) {
      setTimeout(() => {
        notifyMessagesInDialog('','')
      }, 3000);
    }
  };
  const checkErrors = (obj) => Object.keys(obj).filter(key => obj[key]).length > 0;

  const saveKey = () => {
    let hasErrors = checkErrors(keyErrors);
    let errors = { ...keyErrors };

    if (keyName === '') {
      errors = { ...errors, name: true };
      hasErrors = true;
    } else if (keyName.length > 255) {
      errors = { ...errors, name: true };
      hasErrors = true;
    }

    if (hasErrors) {
      setKeyErrors(errors);
    } else {
      const keyData = {
        apiKey,
        appUuid: applicationUuid,
        name: keyName,
        defaultKey: keyIsDefault,
        oauthCallbackUrl: keyOAuthCallbackUrl,
        oauthScope: keyOAuthScope,
        oauthType: keyOAuthType,
        keySecretHashed: keyIsHashed === 'HASHED_SECRET',
      };

      if (apiKey) {
        let data = {
          ...keyData,
          status: keyStatus,
        };

        if (keyRegenerateSecret) {
          data = {
            ...data,
            keySecret: keyClientSecret,
          };
        }
        setRegenerateSecret(false);
        setKeyUpdating(true);
        props.updateKey(data);
      } else {
        const data = {
          ...keyData,
          status: keyStatus,
        };
        setKeyCreating(true);
        props.createKey(data);
      }
    }
  };

  const handleChange = async (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'name':
        setKeyName(fieldValue);
        setKeyErrors({ ...keyErrors, name: hasError(fieldValue, true) });
        await props.fetchKeyNameUnique(fieldValue, apiKey, applicationUuid);
        break;
      case 'status':
        setKeyStatus(fieldValue);
        break;
      case 'oauthCallbackUrl':
        setKeyOAuthCallbackUrl(fieldValue);
        break;
      case 'scope':
        setKeyOAuthScope(fieldValue);
        break;
      case 'type':
        setKeyOAuthType(fieldValue);
        break;
      case 'defaultKey':
        setKeyIsDefault(fieldValue);
        break;
      case 'isHashed':
        setKeyIsHashed(fieldValue);
        setKeyErrors({ ...keyErrors, isHashed: hasError(fieldValue, true) });
        break;
      default:
    }
  };

  const displayHashingOptions = get(appSecretHashingMetadata, 'plaintextAllowed');
  const secretOptions = [
    {
      id: 0,
      key: 'HASHED_SECRET',
      name: 'Hashed',
    },
    {
      id: 1,
      key: 'PLAIN_SECRET',
      name: 'Plaintext',
    },
  ];

  useEffect(() => {
    if (!displayHashingOptions) {
      if (get(appSecretHashingMetadata, 'algorithm') && !get(appSecretHashingMetadata, 'plaintextAllowed')) {
        setKeyIsHashed('HASHED_SECRET');
      } else {
        setKeyIsHashed('PLAIN_SECRET');
      }
    } else {
      setKeyIsHashed('HASHED_SECRET');
    }
  }, [appSecretHashingMetadata]);


  const deleteApiKey =() => {
    setKeyDeleting(true);
    props.deleteKey({
      keyDetails: {
        appUuid: applicationUuid,
        apiKey,
      },
    });
  }

  const deleteApiKeyIgnoringProxyErrors =() => {
    setKeyDeleting(true);
    setShowProxyErrorAlert(false);
    setShowLoadingDialog(true);
    props.deleteKey({
      keyDetails: {
        appUuid: applicationUuid,
        apiKey,
      },
      ignoreProxyCheck: true,
    });
  }

  const forceDeleteAPIKey = () => {
    setKeyDeleting(true);
    props.deleteKey({
      keyDetails: {
        appUuid: applicationUuid,
        apiKey,
      },
      ignoreProxyCheck: true,
      isForceDelete: true,
    });
  }

  const showCopied = (value, valueType) => {
    let strType = '';
    if (valueType === 'API_KEY') {
      strType = 'API Key';
    } else if (valueType === 'SECRET') {
      strType = 'Secret';
    }
    notifyMessagesInDialog(`${strType}: ${value} was copied to the clipboard.`, ALERT_SUCCESS, true);
  };
  const proxyAlertTitle = intl.getI18nMessage('label.application.details.proxy.alert.title');
  const proxyAlertDescription = intl.getI18nMessage('label.application.details.proxy.alert.description');
  const proxyAlertSubmitText = intl.getI18nMessage('label.application.details.proxy.alert.submit');
  const proxyAlertCancelText = intl.getI18nMessage('label.cancel.button');
  const deleteLabel = keyStatus === API_KEY_STATUS_DELETE_FAILED ?
    intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.force.delete.button')
    :
    intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.delete.button');
  const deleteFn = keyStatus === API_KEY_STATUS_DELETE_FAILED && isPortalAdmin ?
    forceDeleteAPIKey
    :
    deleteApiKey;
  return (
    <div id="key-fragment">
      {notificationMessage &&
        <AlertMessages
          id="app-details-notification"
          variant={notificationStatus}
          message={notificationMessage}
          containerClass={classes.notificationWidthClass}
          onClose={() => { notifyMessagesInDialog('', ''); }}
        />
      }
      <Dialog
        open={isGenerateSecretDialogOpen}
        onClose={() => setIsGenerateSecretDialogOpen(false)}
        aria-labelledby="generate-secret-dialog"
        id="generate-secret-dialog"
        classes={{
          paper: classes.paperClass,
        }}
      >
        <div className={classes.generateSecretDialog}>
          <h4>{intl.getI18nFormattedMessage('label.application.details.keys.key.new.key.created.dialog.heading')}</h4>
          <p className={classes.generateSecretDialogContent}>
            {intl.getI18nFormattedMessage('label.application.details.keys.key.new.key.generated.dialog.content')}
          </p>
          <Grid item xs={12} className={classes.rightPanel}>
            <div className={classes.fieldCopyContainer}>
              <FormTextField
                id={`client-key`}
                name={getI18nFormattedMessage('label.application.key')}
                value={createdApiKey}
                disabled
                fieldContainerClass={classes.textField}
              />
              <Button
                id="copy-key-btn"
                variant="outlined"
                onClick={() => {
                  copy(createdApiKey);
                  showCopied(createdApiKey, 'API_KEY', notifyMessages);
                }}
                className={classes.copyButton}
                >
                {getI18nFormattedMessage('label.application.details.keys.overview.copy.button')}
              </Button>
            </div>
            <div className={classes.fieldCopyContainer}>
              <FormTextField
                id="client-secret"
                name={getI18nFormattedMessage('label.application.key.shared.secret')}
                value={createdSecret}
                fieldContainerClass={classes.textField}
                disabled
                errorHelperText={createdKey && createdKey.keySecretHashed}
                helperText={createdKey && createdKey.keySecretHashed ?
                  getI18nFormattedMessage('label.application.key.shared.secret.hashed.generated.help'): ''}
              />
              <Button
                id="copy-secret-btn"
                data-apim-test="copy-secret-btn"
                variant="outlined"
                onClick={() => {
                  copy(createdSecret);
                  showCopied(createdSecret, 'SECRET', notifyMessages);
                }}
                className={classes.copyButton}
                >
                {getI18nFormattedMessage('label.application.details.keys.overview.copy.button')}
              </Button>
              </div>
          </Grid>
          <div className={classes.dialogButtonPanel}>
            <Button
              id="key-created-dialog-close"
              data-apim-test="key-created-dialog-close"
              className={classes.saveButton}
              color="secondary"
              variant="contained"
              onClick={() => {
                setIsGenerateSecretDialogOpen(false);
                closeDialog();
              }}
            >
            {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.close.button')}
            </Button>
          </div>
        </div>
      </Dialog>
      <AlertDialog
        isOpen={showProxyErrorAlert}
        title={proxyAlertTitle}
        description={proxyAlertDescription}
        submitText={proxyAlertSubmitText}
        cancelText={proxyAlertCancelText}
        onClose={setShowProxyErrorAlert.bind(null, false)}
        onSubmit={deleteApiKeyIgnoringProxyErrors}
        onCancel={setShowProxyErrorAlert.bind(null, false)}
        dialogId={'app-delete-proxy-checks-dialog'}
        submitButtonClass={classes.proxyAlertSubmitButton}
      />
      <LoadingDialog
        className={classes.loadingDialog}
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage('label.api.key.delete.loading.title')}
        description={intl.getI18nMessage('label.api.key.delete.loading.text')}
        dialogId="deleting-key-dialog"
      />
      <div className = {classes.headingLine}>
        <div className={classes.headingAndDelete}>
          <h3 className={classes.heading}>{intl.getI18nMessage('label.application.details.keys.key.edit.heading')}</h3>
          {!defaultKey && apiKey && isAdmin && (deleteClicked ?
          <div className={classes.deleteConfirmPanel}>
            <Button
              className={classes.errorBackgroundButton}
              onClick={deleteFn}
              >
              {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.delete.confirm.button')}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDeleteClicked(false)}
              className={classes.cancelButton}
            >
              {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.cancel.button')}
            </Button>
          </div>
          : <Button
              className={classes.deleteKeyButton}
              color="primary"
              variant="outlined"
              onClick={() => setDeleteClicked(true)}
            >
            {deleteLabel}
          </Button>
          )}
        </div>
        <Button
          color="primary"
          variant="outlined"
          className={classes.closeIconButton}
          onClick={() => closeDialog()}
          >
            <Close />
        </Button>
      </div>
      {isLocking &&
        <div className={classes.lockingWarning}>
          <ReportProblem className={classes.warningIcon}/>
          <div>{getI18nFormattedMessage('label.application.details.keys.locking.warning')}</div>
        </div>
      }
      <div>
      <FormTextField
        id="key-name"
        name={intl.getI18nMessage('label.application.key.name')}
        value={keyName}
        helperText={intl.getI18nMessage('label.application.key.name.help')}
        handleChange={(value) => { handleChange('name', value); }}
        error={keyErrors.name || (isUniqueKey === false)}
        errorHelperText={keyErrors.name || (isUniqueKey === false)}
        maxLength="255"
        disabled={!editable}
        autoFocus={true}
      />
      <FormControlLabel
        classes={{
          root: classes.defaultCheckboxContainer,
        }}
        control={
          <Checkbox
            classes={{ root: classes.iconRoot }}
            defaultChecked={defaultKey}
            disabled=
              {isDefaultKeyFieldDisabled({ editable, defaultKey, status, keyStatus, keyCount })}
            size="small"
            checked={keyIsDefault}
            onChange={(event) => { handleChange('defaultKey', event.target.checked); }}
          />
        }
        label={intl.getI18nMessage('label.application.key.default')}
      />
      <FormHelperText classes={{ root: classes.checkboxHelpText }}>
        {intl.getI18nMessage('label.application.key.default.help')}
      </FormHelperText>
      {(defaultKey !== keyIsDefault) &&
        <FormHelperText classes={{ root: classes.defaultKeyWarningText }}>
          {getI18nFormattedMessage('label.application.details.apikeys.devs.edit.message')}
        </FormHelperText>
      }
      {apiKey &&
      <FormControl component="fieldset" className={classes.statusField}>
        <FormLabel variant="h4" component="legend" focused={false}>
          {getI18nFormattedMessage('label.application.details.keys.key.edit.status.title')}
        </FormLabel>
        <RadioGroup
          value={keyStatus}
          onChange={(e) => setKeyStatus(e.target.value)}
        >
          {keyStatusOptions.map((item) => (
            <Fragment key={item.key}>
              <FormControlLabel
                control={
                  <Radio
                    classes={{ root: classes.iconRoot }}
                    disabled={isStatusFieldDisabled({ defaultKey, keyIsDefault }) || !apiKey}
                  />
                }
                key={item.key}
                label={item.label}
                value={item.key}
              />
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
      }
      <Typography variant="h4" classes={{ root: classes.sectionTitle }} gutterBottom>
        {getI18nFormattedMessage('label.application.details.keys.oauth.section.title')}
      </Typography>
      <FormTextField
        variant="h4"
        id={`oauth-callback-url-key-${idx}`}
        name={intl.getI18nMessage('label.application.key.oauth.callback.url')}
        value={keyOAuthCallbackUrl}
        helperText={intl.getI18nMessage('label.application.key.oauth.callback.url.help')}
        multiline
        rows={1}
        optional
        maxLength="2048"
        handleChange={(value) => { handleChange('oauthCallbackUrl', value); }}
        disabled={!editable}
      />
      <FormTextField
        variant="h4"
        id={`oauth-scope-key`}
        name={intl.getI18nMessage('label.application.key.oauth.scope')}
        value={keyOAuthScope}
        helperText={intl.getI18nMessage('label.application.key.oauth.scope.help')}
        multiline
        rows={1}
        optional
        maxLength="4000"
        handleChange={(value) => { handleChange('scope', value); }}
        disabled={!editable}
      />
      <FormButtonGroup
        variant="h4"
        id={`oauth-type-key-`}
        name={'Type'}
        value={keyOAuthType}
        data={[
          { uuid: 'PUBLIC', name: getI18nFormattedMessage('label.application.details.apikeys.oauthtype.public') },
          { uuid: 'CONFIDENTIAL', name: getI18nFormattedMessage('label.application.details.apikeys.oauthtype.confidential') },
        ]}
        handleChange={(value) => { handleChange('type', value || null); }}
        disabled={!editable}
      />
      {!apiKey && displayHashingOptions &&
            <Fragment>
              <Typography variant="h4" classes={{ root: classes.sectionTitle }} gutterBottom>
                {getI18nFormattedMessage('label.application.details.keys.secret.type.heading')}
              </Typography>
              <RadioGroup
                value={keyIsHashed}
                onChange={(e) => handleChange('isHashed', e.target.value)}
                defaultValue={'HASHED_SECRET'}
              >
                {secretOptions.map((item) =>
                  <FormControlLabel
                    control={<Radio />}
                    key={item.key}
                    label={item.name}
                    value={item.key}
                  />,
                )}
              </RadioGroup>
            </Fragment>
          }
      <div className={classes.buttons}>
        <Button
          id="btn-key-save"
          className={classes.saveButton}
          color="secondary"
          variant="contained"
          onClick={saveKey}
          disabled={!editable}
        >
          {getI18nFormattedMessage(`label.application.details.keys.key.${isLocking ? 'submit':'save'}.button`)}
        </Button>
        <Button
          id="btn-close"
          color="primary"
          variant="outlined"
          onClick={() => closeDialog()}
          className={classes.closeButton}
        >
          {getI18nFormattedMessage('label.application.details.keys.key.close.button')}
        </Button>
      </div>
    </div>
    </div>
  );
};

const mapStateToProps = state => ({
  createKeyStatus: getCreateKeyStatus(state),
  updateKeyStatus: getUpdateKeyStatus(state),
  deleteKeyStatus: getDeleteKeyStatus(state),
  isUniqueKey: getIsUniqueKey(state),
  createdKey: getCreatedKey(state),
  deleteKeyErrors: getDeleteKeyErrors(state),
});

const mapDispatchToProps = {
  createKey,
  updateKey,
  deleteKey,
  fetchKeyNameUnique,
  fetchSingleApp,
  fetchProxiesByApiKey,
};

EditKey.propTypes = {
  idx: number,
  applicationUuid: string,
  editable: bool,
  details: object,
  isUniqueKey: oneOfType([string, bool]),
  notifyMessages: func,
  appSecretHashingMetadata: object,
  closeAction: func,
  classes: object,
  updateKey: func,
  createKey: func,
  deleteKey: func,
  fetchKeyNameUnique: func,
  createKeyStatus: string,
  deleteKeyStatus: string,
  updateKeyStatus: string,
  createdKey: string,
  keyCount: number,
  isLocking: bool,
  onSaveDone: func,
  deleteKeyErrors: arrayOf(object),
  fetchSingleApp: func,
  isAdmin: bool,
  fetchProxiesByApiKey: func,
  isPortalAdmin: bool,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EditKey);
