import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import { Badge, ClickAwayListener, Grow, IconButton, MenuList, MenuItem, Paper, Popper, Typography } from '@material-ui/core';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import theme from './index.theme';
import { getI18nFormattedMessage } from '../../../utils/intl';


const requestsSVG =
  (<Fragment>
    <path opacity="0.6" d="M8.01874 19H16.0187C16.0091 21.201 14.2219 22.9823 12.0187 22.9823C9.81546 22.9823 8.02827 21.201 8.01874 19Z" />
    <path d="M12 1C14.8689 1 17.3012 2.98557 17.7068 5.65863L18.776 12.7067C18.9134 13.6124 19.5797 14.3687 20.5 14.6635L20.6444 14.7097C21.455 14.9694 22 15.6858 22 16.4917V17H2V16.4825C2 15.6715 2.55194 14.9516 3.36975 14.6961L3.5 14.6554C4.4191 14.3681 5.0865 13.6163 5.22335 12.714L6.29343 5.65866C6.69884 2.98561 9.1311 1 12 1Z" />
  </Fragment>);
const collapseSVG = <path d="M12.023 7.04426L19.1319 13.9239C19.6227 14.3988 19.6227 15.1689 19.1319 15.6438C18.6412 16.1187 17.8455 16.1187 17.3547 15.6438L11.9771 10.4397L6.6453 15.5995C6.15453 16.0745 5.35884 16.0745 4.86808 15.5995C4.37731 15.1246 4.37731 14.3546 4.86808 13.8796L11.977 7L12.0229 7.04442L12.023 7.04426Z" />;
const REQUEST_TYPES = {
  REGISTRATION: getI18nFormattedMessage('label.request.list.request.type.registration'),
  APPLICATION_CREATE: getI18nFormattedMessage('label.request.list.request.type.application'),
  APPLICATION_UPDATE: getI18nFormattedMessage('label.request.list.request.type.application.update'),
  APPLICATION_DELETE: getI18nFormattedMessage('label.request.list.request.type.application.delete'),
  APPLICATION_DETAILS: getI18nFormattedMessage('label.request.list.request.type.application.details'),
  APPLICATION_APIS: getI18nFormattedMessage('label.request.list.request.type.application.apis'),
  APPLICATION_API_GROUPS: getI18nFormattedMessage('label.request.list.request.type.application.api.groups'),
  APPLICATION_CUSTOM_FIELDS: getI18nFormattedMessage('label.request.list.request.type.application.custom.fields'),
  APPLICATION_API_KEYS: getI18nFormattedMessage('label.request.list.request.type.application.api.keys'),
  APPLICATION_API_PLANS: getI18nFormattedMessage('label.request.list.request.type.application.api.plans'),
};
const getRequestTypeMsg = (requestType, requestSubtype) => {
  const reqType = requestType === 'APPLICATION' ? `${requestType}_${requestSubtype}` : requestType;
  return REQUEST_TYPES[reqType];
};
const NotificationsIcon = createSvgIcon(requestsSVG, 'requests');
const CollapseIcon = createSvgIcon(collapseSVG, 'collapse');

const renderRequestItem = (requestItem, classes) => {
  const label = `${requestItem.requestType.toLowerCase()}: ${requestItem.organizationName.toLowerCase()}`;
  return (<MenuItem
    className={clsx(classes.HeaderRequestsMenuItem, classes.menuItemClass)}
    component={'a'}
    href={`/admin/console/requests/details/${requestItem.entityUuid}`}
    key={requestItem.Uuid}
    tabIndex={0}
    title={label}
    data-apim-test={requestItem.uuid}
  >
      <Typography variant="body1">
        {requestItem.entityName}
      </Typography>
      <Typography variant="body2" className={classes.subTitle}>
        {getRequestTypeMsg(requestItem.requestType, requestItem.requestSubtype)}
      </Typography>
      

  </MenuItem>);
};

const renderRequestItems =
  (requestItems, classes) =>
    requestItems.map(item => renderRequestItem(item, classes));

function Requests({
  classes = {},
  fetchRequests = () => {},
  requestItems = [],
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const [localRequestsVersion, setLocalRequestsVersion] = React.useState(0);
  const handleClick = event => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListKeyDown = (event) => {
    if ((event.key === 'Tab') || (event.key === 'Escape')) {
      event.preventDefault();
      anchorEl.focus();
      setAnchorEl(null);
    }
  };

  const handleBtnKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setAnchorEl(null);
    } else if ((isEmpty(requestItems)) && (event.key === 'Tab')) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    setLocalRequestsVersion(0);
    localStorage.setItem('serverRequestsVersion', 0);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const serverVersionStr = localStorage.getItem('serverRequestsVersion');
      const versionNum = parseInt(serverVersionStr, 10);
      if (isNumber(versionNum) && (versionNum > localRequestsVersion)) {
        setLocalRequestsVersion(versionNum);
        fetchRequests();
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  });

  const requestsCount = isArray(requestItems) ? requestItems.length : 0;

  return (
    <div className={classes.HeaderRequestsButton}>
      <IconButton
        onKeyDown={handleBtnKeyDown}
        onClick={handleClick}
        data-apim-test="header-requests"
      >
        <Badge
          badgeContent={requestsCount}
          color="primary"
          classes={{
            colorPrimary: classes.BadgePrimary,
          }}
        >
          {!anchorEl ?
            <NotificationsIcon className={classes.NotificationsIcon} />
            :
            <CollapseIcon className={classes.NotificationsIcon} />
          }
        </Badge>
      </IconButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        role={undefined}
        transition
        className={classes.Popper}
        disablePortal
        placement={'bottom-end'}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              {isEmpty(requestItems) &&
                <Paper
                  classes={{
                    root: classes.MenuPaper,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div className={classes.Menu}>
                      <div className={classes.HeaderRequestsNoRequestsIcon}>
                        <BlockIcon className={classes.NoRequestsIcon} />
                      </div>
                      <div
                        className={classes.HeaderRequestsNoRequestsText}
                        data-apim-test="no-requests"
                      >
                        No Requests
                      </div>
                      <div className={classes.HeaderRequestsNoRequestsMessage}>
                        All active requests have been resolved
                      </div>
                    </div>
                  </ClickAwayListener>
                </Paper>
              }
              {!isEmpty(requestItems) &&
                <Paper
                  classes={{
                    root: classes.MenuPaper,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      classes={{
                        root: classes.Menu,
                      }}
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        className={classes.SubHeaderUppercase}
                        tabIndex={-1}
                        disabled
                      >
                        Recent Requests
                      </MenuItem>
                      {renderRequestItems(requestItems, classes)}
                      <MenuItem
                        className={classes.HeaderRequestsMenuItem}
                        tabIndex={0}
                      >
                        <a href={`/admin/console/requests`}>
                          <span>Show All</span>
                        </a>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              }
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
}


Requests.propTypes = {
  classes: PropTypes.shape(),
  fetchRequests: PropTypes.func,
  requestItems: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(theme)(Requests);
