import React, { Fragment } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { bool, func, object, arrayOf } from 'prop-types';
import { get, find } from 'lodash';

import {
  FormTextField,
  FormSelect,
} from '../../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';
import { Lock, ReportProblem } from '@material-ui/icons';
const MAX_LENGTH = 5000;

export const getCustomField = (
  customFieldValues,
  { type, uuid, name, description, optionsList, required },
  handleChange,
  classes,
  isSaveClicked,
  intl,
  locked,
  isEditDisabled,
) => {
  const customFieldValue = customFieldValues[uuid];
  const textLengthHelperText = intl.getI18nMessage('label.application.details.customfields.textfield.length.helpertext');
  const helperText = description ? `${description}. ${textLengthHelperText}` : textLengthHelperText;
  const isError = isSaveClicked && ((required && !customFieldValue) ||
  customFieldValue && customFieldValue.length > MAX_LENGTH);
  switch (type) {
    case 'TEXT':
      return (
        <FormTextField
          key={uuid}
          id={uuid}
          name={name}
          value={customFieldValue}
          error={isError}
          errorHelperText={isError}
          handleChange={(value) => handleChange(uuid, value)}
          helperText={helperText}
          disabled={locked || isEditDisabled}
          {...(!required && { optional: true })}
        />
      );
    case 'SINGLE_SELECT': {
      const data = optionsList.map(({ value }) => ({ uuid: value, name: value }));
      const handleSingleSelectChange = (e) => {
        const singleSelectValue = find(
          optionsList,
          ({ value }) => (value === get(e, 'target.value')),
        );
        handleChange(uuid, get(singleSelectValue, 'value'));
      };
      return (
        <FormSelect
          key={uuid}
          id={uuid}
          selectFieldClass={classes.selectFieldClass}
          name={name}
          value={customFieldValue}
          defaultText={'Select an option.'}
          data={data}
          handleChange={handleSingleSelectChange}
          helperText={description}
          disabled={locked || isEditDisabled}
          fieldContainerClass={classes.selectField}
          error={isSaveClicked && required && !customFieldValue}
          {...(!required && { optional: true })}
        />
      );
    }
    default:
      return null;
  }
};

export default function CustomFields(props) {
  const {
    classes,
    setCustomFieldValues,
    customFieldValues = [],
    customFields = [],
    isSaveClicked,
    locked,
    isLocking,
    disabledByInternal,
    isEditDisabled,
  } = props;
  const intl = getI18n(useIntl());
  const handleChange = (customFieldUuid, value) => {
    setCustomFieldValues({ ...customFieldValues, [customFieldUuid]: value });
  };
  return (
    <div id="custom-fields-fragment" data-apim-test="custom-fields-fragment">
      <Fragment>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Typography variant="h2" className={classes.pageTitle}>
            {getI18nFormattedMessage('label.application.details.smalltitle.customfields')}
          </Typography>
          {locked &&
            <Tooltip
              title={getI18nFormattedMessage(disabledByInternal? 'label.application.details.locked.by.internal.tooltip':
              'label.application.details.customfields.locked.tooltip')}
              arrow placement="right">
              <Lock className={classes.lockIcon}/>
            </Tooltip>
          }
          {!locked && isLocking &&
            <Tooltip
              title={getI18nFormattedMessage('label.application.details.customfields.locking.tooltip')}
              arrow placement="right">
              <ReportProblem className={classes.warningIcon}/>
            </Tooltip>
          }
          <Grid md={12} item>
            {customFields.map(customField => getCustomField(
                customFieldValues, customField, handleChange,
                classes, isSaveClicked, intl, locked, isEditDisabled,
            ))}
          </Grid>
        </Grid>
      </Fragment>
    </div>
  );
}

CustomFields.propTypes = {
  classes: object,
  setCustomFieldValues: func,
  customFields: arrayOf(object),
  customFieldValues: object,
  isSaveClicked: bool,
  locked: bool,
  isLocking: bool,
  disabledByInternal: bool,
  isEditDisabled: bool,
};
