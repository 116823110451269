import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { arrayOf, bool, func, number, oneOfType, shape, string, object } from 'prop-types';
import { findIndex, get, isNull, isUndefined } from 'lodash';

import {
  getDict,
  getUserDetails,
  getUserActiveOrgUuid,
  getUserAccessibleOrgs,
  getFeatureFlagApiPlans,
  getUseLegacyApplicationPages,
  getUseMultiApiKeys,
  getAppSecretHashingMetadata,
  getIsApplicationRequestWorkflowEnabled,
  getIsEditApplicationRequestWorkflowEnabled,
} from '../../../reducers/portalConfig';
import {
  fetchAppSecretHashingMetadata,
  fetchApplicationRequestSetting,
  fetchApplicationEditRequestSetting,
} from '../../../actions/portalConfig';
import {
  showLoading,
  resetApp,
  fetchApplication,
  createApplication,
  updateApplication,
  updateAndGetApplication,
  updateApplicationSecret,
  fetchOrganizations,
  fetchAllOrganizations,
  fetchApplicationNameUnique,
  fetchCustomFields,
  fetchSelectedApis,
  fetchAvailableApis,
  fetchApiEula,
  fetchSelectedApiGroups,
  fetchAvailableApiGroups,
  fetchApiGroupEulas,
  generateSecret,
  resetSecret,
  fetchAvailableApiPlans,
  fetchApplicationKeys,
  fetchKeyNameUnique,
  createKey,
  updateKey,
  deleteKey,
} from '../../../actions/application';
import {
  fetchAllTags,
} from '../../../actions/api';
import {
  getIsLoading,
  getIsError,
  getErrors,
  getApplicationDetails,
  getOrganizations1,
  getOrganizations,
  getOrganizationsCount,
  getIsApplicationNameUnique,
  getCustomFields,
  getSelectedApis,
  getAvailableApis,
  getAvailableApisCount,
  getApiEula,
  getSelectedApiGroups,
  getSecret,
  getAvailableApiGroups1,
  getAvailableApiGroups,
  getAvailableApiGroupsCount,
  getApiGroupEulas,
  getIsSaveApplicationSuccess,
  getIsSaveApplicationSecretSuccess,
  getAvailableApiPlans,
  getIsUniqueKey,
  getCreateKeyStatus,
  getUpdateKeyStatus,
  getDeleteKeyStatus,
  getKeysResults,
  getKeysTotalPages,
  getKeysTotalElements,
  getCreatedKey,
  getDeleteKeyErrors,
} from '../../../reducers/application';
import {
  getAllPortalTags,
} from '../../../reducers/api';
import {
  hasOrgBoundRole,
  hasOrgDeveloperRole,
  hasPublisherRole,
  isEditApplicationDisabled,
  isEditApplicationKeysDisabled,
} from '../../../utils';
import {
  TabsContainer,
  TabPanel,
} from '../../../components';
import {
  ALERT_ERROR,
  HELP_ITEMS,
  MULTIKEY_HELP_ITEMS,
  APPLICATION_STATUS_DELETE_FAILED,
} from '../../../constants';
import EditContainer from '../../edit';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import Tabs from './tabs';
import styles from './styles';

export const getApplicationUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/edit/')
  && get(props, 'match.params.applicationUuid');

export const getTabs = (tabProps) => Tabs
  .filter(({ isHidden }) => !(isHidden && isHidden(tabProps)))
  .map(tab => ({
    ...tab,
    disabled: tab.isDisabled && tab.isDisabled(tabProps),
  }));

export const getDefaultSelectedTabIndex = (tabs, match) => {
  const tabName = get(match, 'params.tabName');
  const index = findIndex(tabs, tab => (tab.id === tabName));
  // if none found default to 0
  return (index > 0 ? index : 0);
};

const getApplicatonDetailsToPersist = (applicationDetails, isHashingEnabled, shouldHash) => ({
  ...applicationDetails,
  ...(
    !isUndefined(isHashingEnabled) &&
    (!isUndefined(shouldHash) || !isNull(shouldHash)
  ) ? { shouldHash } : {}),
});

export const ApplicationEdit = (props) => {
  const {
    userContext,
    applicationErrors,
    match,
    isApplicationNameUnique,
    featureFlagApiPlans,
    availableApiPlans,
    customFields,
    isSaveApplicationSuccess,
    useLegacyApplicationPages,
    useMultiApiKeys,
    appKeys = [],
    appKeysTotalPages,
    appKeysTotalElements,
    isUniqueKey,
    createKeyStatus,
    updateKeyStatus,
    deleteKeyStatus,
    appSecretHashingMetadata,
  } = props;
  const applicationUuid = getApplicationUuid(props) || '';
  if (useLegacyApplicationPages) {
    window.localStorage.setItem('v4.4.applications', 'true');
    const prefixUrl = hasOrgBoundRole(userContext) ? '/app' : '';
    if (applicationUuid && get(props, 'match.path').includes('/edit/')) {
      window.location.href = `/admin${prefixUrl}/applications#edit/${applicationUuid}`;
    } else if (get(props, 'match.path').includes('/add')) {
      window.location.href = `/admin${prefixUrl}/applications#add`;
    }
  }
  const headerRef = useRef(null);

  // Retrieving Application Status from the store (persisted value) and not the local state
  const applicationStatus = get(props.applicationDetails, 'status');
  const isMultiKeySupport = (
    useMultiApiKeys && hasPublisherRole(userContext) && appKeys.length > 0
  );

  const intl = getI18n(useIntl());
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogSubmitText, setDialogSubmitText] = useState(intl.getI18nMessage('label.dialog.unsaved.changes.exit'));
  const [isCancelButton, setIsCancelButton] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(props.applicationDetails);
  const [isApplicationErrors, setIsApplicationErrors] = useState(false);
  const [isHashedSecret, setIsHashedSecret] = useState(false);
  const [showOneTimePasswordWarning, setShowOneTimePasswordWarning] = useState(false);
  const [isPlainTextCheckBoxSelected, setIsPlainTextCheckBoxSelected] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [isLoading, setIsLoading] = useState(props.isLoading);

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    //localStorage.setItem('notificationStatus', status);
    setNotificationMessage(message);
    //localStorage.setItem('notificationMessage', message);
  };

  const tabProps = {
    userContext,
    applicationUuid,
    applicationDetails,
    isApplicationNameUnique,
    featureFlagApiPlans,
    availableApiPlans,
    customFields,
    appKeys,
    isMultiKeySupport,
  };

  const [tabs, setTabs] = useState(getTabs(tabProps));
  const defaultIndex = getDefaultSelectedTabIndex(tabs, match);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[tabIndex].tabId);
  const [redirectTabValue, setRedirectTabValue] = useState(tabs[defaultIndex].tabId);
  const AUTHENTICATION_TAB = 'authentication-tab';
  const KEY_TAB = 'key-tab';

  useEffect(() => {
    if (hasOrgDeveloperRole(userContext) && !applicationUuid) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    localStorage.setItem('isAppUnSavedChanges', false);
    props.fetchCustomFields();
    props.fetchAppSecretHashingMetadata();
    props.fetchApplicationRequestSetting();
    props.fetchApplicationEditRequestSetting();
    if (applicationUuid) {
      props.fetchApplication(applicationUuid);
      props.fetchSelectedApis(applicationUuid);
      props.fetchSelectedApiGroups(applicationUuid);
      props.fetchApplicationKeys(applicationUuid);
    }
  }, []);

  useEffect(() => {
    if (applicationUuid) {
      if (updateKeyStatus === 'SUCCESS' || deleteKeyStatus === 'SUCCESS' || isSaveApplicationSuccess) {
        props.fetchApplicationKeys(applicationUuid);
      }
    }
  }, [createKeyStatus, updateKeyStatus, deleteKeyStatus, isSaveApplicationSuccess]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    if (!props.applicationDetails) { return; }
    // Allow the org-bound user to view the Key page
    // when Edit Application Request workflow is enabled.
    if (applicationUuid && ['authentication-tab', 'key-tab'].includes(currentTab)) { return; }
    if (applicationUuid && props.applicationDetails.status === APPLICATION_STATUS_DELETE_FAILED) {
      props.push('/404');
    }
    if (applicationUuid &&
      isEditApplicationDisabled(userContext, props.applicationDetails) &&
      isEditApplicationKeysDisabled(userContext, props.applicationDetails)) {
      props.push('/404');
    }

    // Assigning the fetched Application Details to the local state
    setApplicationDetails(props.applicationDetails);
    const { organizationUuid } = props.applicationDetails;
    if (organizationUuid) {
      props.fetchAvailableApis({ orgUuid: organizationUuid });
      props.fetchAvailableApiGroups({ orgUuid: organizationUuid });
    }
  }, [props.applicationDetails]);

  useEffect(() => {
    // Assigning the fetched Application Details to the local state
    // Assigning the fetched APIs & API Groups to the local state
    setApplicationDetails({
      ...props.applicationDetails,
      apiIds: [
        ...(props.applicationDetails.apiIds || []),
        ...(props.selectedApis ?
          props.selectedApis.map(selectedApi => (selectedApi.uuid)) : []),
      ],
      apiGroupIds: [
        ...(props.applicationDetails.apiGroupIds || []),
        ...(props.selectedApiGroups ?
          props.selectedApiGroups.map(selectedApiGroup => (selectedApiGroup.uuid)) : []),
      ],
    });
  }, [props.applicationDetails, props.selectedApis, props.selectedApiGroups]);

  useEffect(() => {
    const filterTabs = getTabs(tabProps);
    setTabs(filterTabs);
  }, [
    applicationUuid,
    applicationDetails,
    isApplicationNameUnique,
    availableApiPlans,
    appKeys,
  ]);

  useEffect(() => {
    setCurrentTab(tabs[defaultIndex].tabId);
  }, [defaultIndex]);

  useEffect(() => {
    const filterTabs = getTabs(tabProps);
    const newTabIndex = getDefaultSelectedTabIndex(filterTabs, match);

    setTabs(filterTabs);
    setTabIndex(newTabIndex);
    setCurrentTab(filterTabs[newTabIndex].tabId);
  }, [customFields]);

  useEffect(() => {
    if (applicationErrors.length > 0) {
      notifyMessages(getI18nFormattedMessage('error.applications.update'), ALERT_ERROR);
      // TODO: Needs to be retested for more error scenarios
      // setErrors(applicationErrors);
    }
  }, [applicationErrors]);

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const redirectTab = async (newTab) => {
    if (currentTab === KEY_TAB && newTab !== KEY_TAB) {
      await props.showLoading(true);
      // Reset the secret when navigating back to the key tab
      await props.resetSecret(applicationUuid);
      // Re-fetch the Application / APIs / API Groups
      await props.fetchApplication(applicationUuid);
      await props.fetchSelectedApis(applicationUuid);
      await props.fetchSelectedApiGroups(applicationUuid);
      await props.fetchApplicationKeys(applicationUuid);
      // Remove the warning to show Hashed secret once
      setShowOneTimePasswordWarning(false);
    }
    setCurrentTab(newTab);
    if (applicationUuid) {
      const tIndex = tabs.findIndex(({ tabId }) => (tabId === newTab));
      const { id } = tabs[tIndex];
      if (
        // handling a specific scenario for a defect
        useMultiApiKeys &&
        hasPublisherRole(userContext) &&
        currentTab === KEY_TAB &&
        newTab === AUTHENTICATION_TAB
      ) {
        props.push(`/publish/applications/edit/${applicationUuid}/keys`);
      } else {
        props.push(`/publish/applications/edit/${applicationUuid}/${id}`);
      }
    }
    window.scrollTo(0, headerRef.offsetTop);
  };

  const onDialogSubmit = () => {
    localStorage.setItem('isAppUnSavedChanges', false);
    setDialogSubmitText(getI18nFormattedMessage('label.dialog.unsaved.changes.exit'));
    if (isCancelButton) {
      setIsCancelButton(false);
      window.location.href = '/admin/console/applications';
    } else {
      setIsDialogOpen(false);
      redirectTab(redirectTabValue);
    }
  };

  const onCancel = () => {
    if (localStorage.getItem('isAppUnSavedChanges') === 'true') {
      setIsCancelButton(true);
      setIsDialogOpen(true);
    } else {
      window.location.href = '/admin/console/applications';
    }
  };

  const onUpdateApplicationSecret = (shouldHash) => {
    const isHashingEnabled = get(appSecretHashingMetadata, 'algorithm');
    const applicationDetailsToPersist =
      getApplicatonDetailsToPersist(applicationDetails, isHashingEnabled, shouldHash);
    props.updateApplicationSecret(applicationDetailsToPersist);
  };

  const onSaveApplication = (shouldHash, isGet) => {
    const isHashingEnabled = get(appSecretHashingMetadata, 'algorithm');
    const applicationDetailsToPersist =
      getApplicatonDetailsToPersist(applicationDetails, isHashingEnabled, shouldHash);
    if (applicationUuid) {
      if (isGet) {
        props.updateAndGetApplication(applicationDetailsToPersist);
      } else {
        props.updateApplication(applicationDetailsToPersist);
      }
    } else {
      props.createApplication(applicationDetailsToPersist);
    }
  };

  const getHelpItems = () => (isMultiKeySupport ? MULTIKEY_HELP_ITEMS.details : HELP_ITEMS.details);

  const handleTabChange = async (e, newTab) => {
    // Stop invalid navigation
    if (currentTab === newTab) { return; }

    const hasUnsavedChanges = localStorage.getItem('isAppUnSavedChanges');
    if (hasUnsavedChanges === 'true' && newTab === KEY_TAB) {
      setIsCancelButton(false);
      setDialogSubmitText(getI18nFormattedMessage('label.dialog.unsaved.changes.submit'));
      setIsDialogOpen(true);
      setRedirectTabValue(newTab);
    } else {
      redirectTab(newTab);
    }
  };

  return (
    <EditContainer
      editPageId="application-edit-page"
      dialogId="api-unsaved-dialog"
      isDialogOpen={isDialogOpen}
      onDialogClose={onDialogClose}
      dialogSubmitText={dialogSubmitText}
      onDialogSubmit={onDialogSubmit}
      isLoading={isLoading}
      leftSidebarId="application-edit-left-sidebar"
      mainContentId="application-edit-main-content"
      notificationId="application-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      tabsContainer={(
        <TabsContainer
          useTabId
          tabValue={currentTab}
          tabItems={tabs}
          handleTabChange={handleTabChange}
        />
      )}
      pageHeaderRef={headerRef}
      pageHeaderTitle={
        applicationUuid ?
          `${intl.getI18nMessage('label.application.edit')}: ${applicationDetails.name}` :
          intl.getI18nMessage('label.application.add')
      }
      pageContent={
        <Fragment>
          {tabs.map(
            ({ tabId, tabPanel }) => (
              <TabPanel
                id={`${tabId}-panel`}
                visible={currentTab === tabId}
                key={tabId}
              >
                {
                  tabPanel({
                    ...props,
                    errors: props.applicationErrors,
                    visible: (currentTab === tabId),
                    headerRef,
                    currentTab,
                    setCurrentTab,
                    applicationUuid,
                    onCancel,
                    onSaveApplication,
                    onUpdateApplicationSecret,
                    applicationDetails,
                    setApplicationDetails,
                    applicationStatus,
                    isHashedSecret,
                    setIsHashedSecret,
                    showOneTimePasswordWarning,
                    setShowOneTimePasswordWarning,
                    isPlainTextCheckBoxSelected,
                    setIsPlainTextCheckBoxSelected,
                    isApplicationErrors,
                    setIsApplicationErrors,
                    isSaveApplicationSuccess,
                    notifyMessages,
                    fetchApplicationKeys: props.fetchApplicationKeys,
                    appKeys,
                    appKeysTotalPages,
                    appKeysTotalElements,
                    fetchKeyNameUnique: props.fetchKeyNameUnique,
                    createKey: props.createKey,
                    updateKey: props.updateKey,
                    isUniqueKey,
                    createKeyStatus,
                    updateKeyStatus,
                    isMultiKeySupport,
                  })
                }
              </TabPanel>
            ))}
        </Fragment>
      }
      rightSidebarId="application-edit-right-sidebar"
      helpItems={getHelpItems()}
    />
  );
};

ApplicationEdit.propTypes = {
  userContext: object,
  applicationErrors: arrayOf(object),
  featureFlagApiPlans: bool,
  isLoading: bool,
  match: shape({
    params: shape({
      tabName: string,
      applicationUuid: string,
    }),
  }),
  applicationDetails: object,
  isApplicationNameUnique: bool,
  availableApiPlans: object,
  isSaveApplicationSuccess: bool,
  customFields: arrayOf(object),
  selectedApis: arrayOf(object),
  selectedApiGroups: arrayOf(object),
  useLegacyApplicationPages: bool,
  useMultiApiKeys: bool,
  appKeys: arrayOf(object),
  appKeysTotalPages: number,
  appKeysTotalElements: number,
  updateKey: func,
  createKeyStatus: string,
  updateKeyStatus: string,
  deleteKeyStatus: string,
  isUniqueKey: oneOfType([string, bool]),
  appSecretHashingMetadata: object,
  fetchCustomFields: func,
  fetchAppSecretHashingMetadata: func,
  fetchApplicationRequestSetting: func,
  fetchApplicationEditRequestSetting: func,
  fetchSelectedApiGroups: func,
  fetchApplicationKeys: func,
  push: func,
  fetchAvailableApis: func,
  fetchAvailableApiGroups: func,
  showLoading: func,
  resetSecret: func,
  fetchApplication: func,
  fetchSelectedApis: func,
  updateApplicationSecret: func,
  updateAndGetApplication: func,
  updateApplication: func,
  createApplication: func,
  fetchKeyNameUnique: func,
  createKey: func,
};

const mapStateToProps = (state) => ({
  dict: getDict(state),
  userContext: getUserDetails(state),
  userActiveOrgUuid: getUserActiveOrgUuid(state),
  userAccessibleOrgs: getUserAccessibleOrgs(state),
  isLoading: getIsLoading(state),
  isError: getIsError(state),
  applicationErrors: getErrors(state),
  applicationDetails: getApplicationDetails(state),
  isSaveApplicationSuccess: getIsSaveApplicationSuccess(state),
  isSaveApplicationSecretSuccess: getIsSaveApplicationSecretSuccess(state),
  organizations1: getOrganizations1(state),
  organizations: getOrganizations(state),
  organizationsCount: getOrganizationsCount(state),
  isApplicationNameUnique: getIsApplicationNameUnique(state),
  customFields: getCustomFields(state),
  selectedApis: getSelectedApis(state),
  availableApis: getAvailableApis(state),
  availableApisCount: getAvailableApisCount(state),
  apiEula: getApiEula(state),
  selectedApiGroups: getSelectedApiGroups(state),
  secret: getSecret(state),
  availableApiGroups1: getAvailableApiGroups1(state),
  availableApiGroups: getAvailableApiGroups(state),
  availableApiGroupsCount: getAvailableApiGroupsCount(state),
  apiGroupEulas: getApiGroupEulas(state),
  allTags: getAllPortalTags(state),
  featureFlagApiPlans: getFeatureFlagApiPlans(state),
  useLegacyApplicationPages: getUseLegacyApplicationPages(state),
  useMultiApiKeys: getUseMultiApiKeys(state),
  isApplicationRequestWorkflowEnabled: getIsApplicationRequestWorkflowEnabled(state),
  isEditApplicationRequestWorkflowEnabled: getIsEditApplicationRequestWorkflowEnabled(state),
  availableApiPlans: getAvailableApiPlans(state),
  appSecretHashingMetadata: getAppSecretHashingMetadata(state),
  appKeys: getKeysResults(state),
  appKeysTotalPages: getKeysTotalPages(state),
  appKeysTotalElements: getKeysTotalElements(state),
  isUniqueKey: getIsUniqueKey(state),
  createKeyStatus: getCreateKeyStatus(state),
  updateKeyStatus: getUpdateKeyStatus(state),
  deleteKeyStatus: getDeleteKeyStatus(state),
  createdKey: getCreatedKey(state),
  deleteKeyErrors: getDeleteKeyErrors(state),
});

const mapDispatchToProps = {
  push,
  showLoading,
  resetApp,
  fetchApplication,
  fetchOrganizations,
  fetchAllOrganizations,
  fetchApplicationNameUnique,
  fetchCustomFields,
  fetchSelectedApis,
  fetchAvailableApis,
  fetchApiEula,
  fetchSelectedApiGroups,
  fetchAvailableApiGroups,
  generateSecret,
  resetSecret,
  fetchApiGroupEulas,
  fetchAllTags,
  createApplication,
  updateApplication,
  updateAndGetApplication,
  updateApplicationSecret,
  fetchAvailableApiPlans,
  fetchAppSecretHashingMetadata,
  fetchApplicationRequestSetting,
  fetchApplicationEditRequestSetting,
  fetchApplicationKeys,
  fetchKeyNameUnique,
  createKey,
  updateKey,
  deleteKey,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationEdit);
