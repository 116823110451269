import React from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { camelCase, startCase } from 'lodash';
import Grid from '@material-ui/core/Grid';

import {
  FormAutoCompleteCombo, FormSelect, FormTextField, TableMultiValueCell,
} from '../../../components';
import {
  USER_LIST_STATUS_FILTER_BY, USER_LIST_ROLE, USER_LIST_SORT_BY, ROLE_ORG_ADMIN_UUID,
  ROLE_ORG_PUBLISHER_UUID, ROLE_DEVELOPER_UUID,
} from '../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import { getRoleLabel } from '../../../utils/rbac';

export const FilterByName = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="user-filter-by-name"
    />
  </Grid>
);

export const FilterByEmail = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="user-filter-by-email"
    />
  </Grid>
);

export const FilterByOrganization = (props) => {
  const intl = getI18n(useIntl());
  const onChange = (e, value) =>
    props.handleChange(e, value);

  return (
    <Grid item md={2} sm={2} xs={12}>
      <FormAutoCompleteCombo
        {...props}
        id="user-filter-by-organization"
        inputLabel={intl.getI18nMessage('label.user.list.page.organization.by.all.organizations')}
        onChange={onChange}
      />
    </Grid>
  );
};

FilterByOrganization.propTypes = {
  handleChange: func,
  data: arrayOf(object),
  value: string,
};

export const USER_LIST_PUBLISHER_ROLE_FILTER_BY = Object.keys(USER_LIST_ROLE)
  .map(
    role => ({
      uuid: role,
      text: getI18nFormattedMessage(USER_LIST_ROLE[role]),
    }),
  );

export const USER_LIST_ORGBOUND_ROLE_FILTER_BY = USER_LIST_PUBLISHER_ROLE_FILTER_BY
  .filter(
    ({ uuid }) => (
      [ROLE_ORG_ADMIN_UUID, ROLE_ORG_PUBLISHER_UUID, ROLE_DEVELOPER_UUID].includes(uuid)
    ),
  );

export const FilterByRole = (props) => {
  const intl = getI18n(useIntl());
  const data = props.isPublisher ?
    USER_LIST_PUBLISHER_ROLE_FILTER_BY : USER_LIST_ORGBOUND_ROLE_FILTER_BY;
  const renderValue = (selected) => {
    if (selected.length === 0) {
      return intl.getI18nMessage('label.user.list.page.role.by.all.roles');
    }
    return (
      selected
        .map(s => intl.getI18nMessage(USER_LIST_ROLE[s]))
        .join(', ')
    );
  };
  return (
    <Grid item md={2} sm={2} xs={12}>
      <FormSelect
        {...props}
        id="user-filter-by-role"
        data={data}
        noNoneOption
        noNativeSelect
        multiple
        displayEmpty
        renderValue={renderValue}
      />
    </Grid>
  );
};

FilterByRole.propTypes = {
  isPublisher: bool,
};

export const FilterByStatus = (props) => (
  <Grid item md={2} sm={2} xs={12}>
    <FormSelect
      {...props}
      id="user-filter-by-status"
      data={USER_LIST_STATUS_FILTER_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    {!props.hidden &&
      <FormSelect
        {...props}
        id="user-sort-by"
        data={USER_LIST_SORT_BY}
        noNoneOption
        noNativeSelect
      />
    }
  </Grid>
);

SortBy.propTypes = {
  hidden: bool,
};

export const FilterAndSortSeparator = () => (
  <Grid item md sm={9} />
);

export const Organization = ({ access, expanded }) => (
  <TableMultiValueCell
    entity={getI18nFormattedMessage('label.organizations')}
    text={(role) => (role.orgName && `${role.orgName} (${startCase(camelCase(role.orgType))})`)}
    items={access}
    expanded={expanded}
    uniqueKey={(item) => `${item.orgUuid} ${item.roleUuid}`}
  />
);

Organization.propTypes = {
  access: arrayOf(shape({})),
  expanded: bool,
};

export const Role = ({ access, expanded }) => (
  <TableMultiValueCell
    entity={getI18nFormattedMessage('label.roles')}
    text={(role) => getRoleLabel(role)}
    items={access}
    expanded={expanded}
    uniqueKey={(item) => `${item.orgUuid} ${item.roleUuid}`}
  />
);

Role.propTypes = {
  access: arrayOf(shape({})),
  expanded: bool,
};
