import { get } from 'lodash';

const styles = ({ color, typography, fontSize, margin, padding }) => ({
  deploymentsContainer: {
    backgroundColor: get(color, 'uiBackground'),
    width: '100%',
    marginTop: get(margin, 'm5'),
    '& #api-details-deployments-pagination': {
      display: 'none',
    },
    '& #api-details-deployments-list .statusBtn button': {
      height: '21px',
      lineHeight: '21px',
      padding: 0,
      verticalAlign: 'top',
    },
  },
  filterContainer: {
    marginTop: get(margin, 'm24'),
  },
  fieldByName: {
    maxWidth: '200px',
    marginRight: get(margin, 'm5'),
  },
  fieldContainer: {
    marginRight: get(margin, 'm5'),
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'normal',
  },
  bodyContent: {
    padding: '0 36px',
  },
  selectField: {
    padding: '2px 10px !important',
  },
  deploymentCard: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  cardStatus: {
    color: get(color, 'bodyText'),
    minWidth: '200px',
    '& svg': {
      fontSize: '16px',
      verticalAlign: 'middle',
    },
  },
  cardStatusIcon: {
    fontSize: 12,
  },
  helpIcon: {
    fontSize: 18,
    margin: get(margin, 'm4'),
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: `${get(color, 'link')} !important`,
  },
  helpTextContainer: {
    '& ui': {
      padding: 0,
      listStyleType: 'none',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkWrapper: {
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: `${get(margin, 'm0')} !important`,
      padding: `${get(padding, 'p0')} !important`,
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
    '& a:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textDecoration: 'none',
    },
  },
  actionLink: {
    margin: get(margin, 'm10'),
  },
  deploymentButtonsContainer: {
    minWidth: '300px',
    '& button:first-of-type': {
      paddingLeft: `${get(padding, 'p10')} !important`,
    },
    '& button': {
      border: 'none',
      margin: get(margin, 'm0'),
      padding: get(padding, 'p0'),
      paddingRight: `${get(padding, 'p10')} !important`,
      // paddingBottom: `${get(padding, 'p5')}  !important`,
      height: 'auto',
      lineHeight: '1 !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textTransform: 'none',
      textDecoration: 'none',
    },
  },
  deploymentStatusContainer: {
    marginLeft: get(margin, 'm5'),
    display: 'inline-block',
    '& button': {
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
    },
  },
  checkStatusContainer: {
    margin: -5,
  },
  checkStatusButtonContainer: {
    display: 'block',
  },
  messageContent: {
    paddingTop: get(padding, 'p24'),
  },
});

export default styles;
