import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shape, func, bool, arrayOf } from 'prop-types';
import { Button, IconButton, makeStyles, withStyles } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { getI18n } from '../../../../utils/intl';
import { FormActionButtons, FormTextField } from '../../../../components';
import styles from './styles';
import { ALERT_SUCCESS } from '../../../../constants';

export const Tiers = (props) => {
  const {
    tiers,
    fetchTiers,
    saveTiers,
    isTiersSaveSuccess,
    notifyMessages,
    isCurrentTab,
    isError,
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const intl = getI18n(useIntl());
  const [hasChanges, setHasChanges] = useState(false);
  const [newPayload, setNewPayload] = useState([]);

  useEffect(() => {
    if (tiers?.length > 0) {
      setNewPayload([...tiers]);
    }
  }, [tiers]);

  useEffect(() => {
    JSON.stringify(newPayload) === JSON.stringify(tiers) ?
      setHasChanges(false) : setHasChanges(true);
  }, [newPayload]);

  useEffect(() => {
    if (isCurrentTab) {
      fetchTiers();
    }
  }, [isCurrentTab]);

  useEffect(() => {
    if (isCurrentTab && isError) {
      setHasChanges(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isTiersSaveSuccess) {
      setHasChanges(false);
      notifyMessages(
        ALERT_SUCCESS,
        intl.getI18nMessage('products.tiers.update.success'),
      );
      fetchTiers();
      setTimeout(() => {
        notifyMessages('', '');
      }, 2500);
    }
  }, [isTiersSaveSuccess]);

  const onCancel = () => {
    fetchTiers();
    setHasChanges(false);
  };

  const onSave = () => {
    saveTiers(newPayload);
  };

  const onMoveTierPosition = (index, newIndex) => {
    setNewPayload((newPayload) =>
      newPayload.map((tier, i) => {
        if (i === index) {
          return newPayload[newIndex];
        }
        if (i === newIndex) {
          return newPayload[index];
        }
        return tier;
      }),
    );
  };

  const onRemoveTier = (index) => {
    setNewPayload(newPayload.filter((tier, i) => i !== index));
  };
  const onAddTier = () => {
    setNewPayload((newPayload) => [...newPayload, { name: '' }]);
  };

  const handleChange = (index, value) => {
    setNewPayload((newPayload) =>
      newPayload.map((tier, i) => {
        if (i === index) {
          return { ...tier, name: value };
        }
        return tier;
      }),
    );
  };

  return (
    <div
      id="products-list-tiers-container"
      data-apim-test="products-list-tiers-container"
      data-layer7-test="products-list-tiers-container"
      className={classes.detailsContainer}
    >
      {newPayload.map((tier, index) => (
        <div key={index} className={classes.tierContainer}>
          <IconButton
            id={`products-list-tier-${index}-upward`}
            className={classes.arrowButton}
            aria-label="move tier upward"
            data-apim-test="products-list-tiers-upward"
            data-layer7-test="products-list-tiers-upward"
            disabled={index === 0}
            onClick={() => onMoveTierPosition(index, index - 1)}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            id={`products-list-tier-${index}-downward`}
            className={classes.arrowButton}
            aria-label="move tier down"
            data-apim-test="products-list-tiers-downward"
            data-layer7-test="products-list-tiers-downward"
            disabled={index === newPayload.length - 1}
            onClick={() => onMoveTierPosition(index, index + 1)}
          >
            <ArrowDownwardIcon />
          </IconButton>
          <FormTextField
            id={`products-list-tier-${index}`}
            value={tier?.name ? tier.name : ''}
            data-apim-test="products-list-tiers-name-field"
            data-layer7-test="products-list-tiers-name-field"
            handleChange={(value) => handleChange(index, value)}
            maxLength={'255'}
            fieldContainerClass={classes.fieldContainer}
            textFieldClass={classes.textField}
            placeholder={intl.getI18nMessage(
              'label.product.tier.name.placeholder',
            )}
          />
          <Button
            id={`products-list-tier-${index}-remove`}
            variant="contained"
            color="secondary"
            className={classes.removeButton}
            data-apim-test={`remove-tier-button`}
            data-layer7-test={`remove-tier-${index}`}
            onClick={() => onRemoveTier(index)}
            disabled={newPayload.length < 2}
          >
            {intl.getI18nMessage('label.remove')}
          </Button>
        </div>
      ))}
      <Button
        variant="contained"
        color="secondary"
        className={classes.addButton}
        id={`add-tier-button`}
        data-apim-test={`add-tier-button`}
        data-layer7-test={`add-tier-button`}
        onClick={onAddTier}
        disabled={newPayload.length >= 20}
      >
        {intl.getI18nMessage('label.product.tier.add.button')}
      </Button>
      <FormActionButtons
        isContainerFooter={true}
        onNextClick={onSave}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="products-list-tiers-save-footer"
        containerClass={
          hasChanges ? classes.showFooterContainer : classes.hideFooterContainer
        }
        isDisabled={newPayload.some((tier) => !tier.name)}
      />
    </div>
  );
};

Tiers.displayName = 'Tiers';
Tiers.propTypes = {
  classes: shape({}),
  tiers: arrayOf(shape({})),
  isTiersSaveSuccess: bool,
  isCurrentTab: bool,
  notifyMessages: func,
  fetchTiers: func,
  saveTiers: func,
  isError: bool,
};

export default withStyles(styles)(Tiers);
