import React, { useState } from 'react';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { bool, func, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import AddOrganizations from './add.js';
import EditOrganizations from './edit.js';
import styles from './styles';

export const Organizations = (props) => {
  const {
    productUuid,
    isCurrentTab,
    notifyMessages,
  } = props;

  const [page, setPage] = useState('edit');

  const onPageChange = (value) => {
    setPage(value)
  }
  
  return (
    <>
      {page === 'edit' ?
        <EditOrganizations
          productUuid={productUuid}
          isCurrentTab={isCurrentTab}
          onPageChange={onPageChange}
          notifyMessages={notifyMessages}
        />
      :
        <AddOrganizations
          productUuid={productUuid}
          isCurrentTab={isCurrentTab}
          onPageChange={onPageChange}
          notifyMessages={notifyMessages}
        />
      }
    </>
  )
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

Organizations.propTypes = {
  productUuid: string,
  isCurrentTab: bool,
  notifyMessages: func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Organizations);
