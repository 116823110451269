import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import {
  Button,
  Grid,
  Link,
  withStyles,
} from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import { useIntl } from 'react-intl';
import { cloneDeep, difference, findIndex, each, keys, map } from 'lodash';

import {
  FilterByName,
} from './controls';
import styles from './styles';
import { FormActionButtons, Switch } from '../../../../components';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
 } from '../../../../constants';
import {
  fetchApisProductList,
  updateApisProductList,
} from '../../../../actions/api';
import {
  getIsProductAccessSaveSuccess,
  getProductListTotalPages,
  getProductListTotalElements,
  getProductListResults,
} from '../../../../reducers/api';
import { getI18n } from '../../../../utils/intl';

const CHANGE_MODE_REMOVE_ACCESS = 'CHANGE_MODE_REMOVE_ACCESS';
const CHANGE_MODE_ADD_ACCESS = 'CHANGE_MODE_ADD_ACCESS';

export const Products = (props) => {
  const {
    apiUuid,
    classes,
    fetchList,
    isApiUpdateSuccess,
    notifyMessages,
    rows = [],
    totalElements,
    totalPages,
    updateAccess,
    tabValue,
  } = props;

  const { getI18nMessage } = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByName, setFilterByName] = useState('');
  const [showFormActionBtns, setShowFormActionBtns] = useState(false);
  const [changeMode, setChangeMode] = useState(CHANGE_MODE_REMOVE_ACCESS);
  const [currentProductsRows, setCurrentProductRows] = useState([]);
  const [modifiedRecords, setModifiedRecords] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const emptyFunction = () => {};

  useEffect(() => {
    if (apiUuid && tabValue === 'api-details-products-tab') {
      fetchListWithFilters();
    }
  }, [apiUuid]);

  const getListColumns = ({ getI18nMessage }) => [{
    id: 'productName',
    label: getI18nMessage('label.api.details.apiproducts.list.product.title'),
    minWidth: 200,
    format: ({ productName, uuid } = {}) => (<Link underline="none" href={`/admin/products/details/${uuid}`}>
      {productName}
    </Link>),
    value: ({ productName, productUuid } = {}) => ({ productName, uuid: productUuid }),
  },{
    id: 'productApiAccessStatus',
    label: getI18nMessage('label.api.details.apiproducts.list.access.title'),
    minWidth: 50,
    // eslint-disable-next-line react/prop-types
    format: ({ hasAccess, uuid }) =>
      (<Switch
        onChange={(val) => onAccessToggle(val, uuid)}
        checked={hasAccess}
      />
    ),
    value: ({ productUuid, productApiAccessStatus }) => ({ uuid: productUuid, hasAccess: productApiAccessStatus === 'ASSIGNED' }),
  }];

  useEffect(() => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS) || isFormDirty) {
      setShowFormActionBtns(true);
    }
    if ((changeMode !== CHANGE_MODE_ADD_ACCESS) && !isFormDirty) {
      setShowFormActionBtns(false);
    }
  }, [changeMode, isFormDirty]);

  useEffect(() => {
    setCurrentProductRows(cloneDeep(rows));
  }, [rows]);

  useEffect(() => {
    if (isApiUpdateSuccess) {
      notifyMessages('success', getI18nMessage('label.product.updated'));
      setChangeMode(CHANGE_MODE_REMOVE_ACCESS);
      setPage(0);
      fetchListWithFilters();
      setTimeout(() => {
        notifyMessages('', '');
      }, 2500);
    }
  }, [isApiUpdateSuccess]);

  const onAccessToggle = (val, uuid) => {
      updateCurrentProductRow(uuid, !!val);
  };

  useEffect(() => {
    const rowsMap = {};
    each(rows, item => {
      rowsMap[item.productUuid] = item;
    });
    const currentProductsRowsMap = {};
    each(currentProductsRows, item => {
      currentProductsRowsMap[item.productUuid] = item;
    });
    const rowIds = keys(rowsMap);
    const currentProductsRowsIds = keys(currentProductsRowsMap);
    if ( difference(rowIds, currentProductsRowsIds).length !== 0) {
      return ;
    }
    let modifiedRecords = 0;
    each(rowIds, id => {
      if (rowsMap[id].productApiAccessStatus !==
        currentProductsRowsMap[id].productApiAccessStatus) {
        modifiedRecords++;
      }
    });
    setModifiedRecords(modifiedRecords);
  }, [currentProductsRows]);

  useEffect(() => {
    if (modifiedRecords > 0) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [modifiedRecords]);

  useEffect(() => {
    if(tabValue === 'api-details-products-tab') {
      fetchListWithFilters();
    }
  }, [changeMode]);

  const updateCurrentProductRow = (uuid, access) => {
    const index = findIndex(currentProductsRows, item => item.productUuid === uuid);
    currentProductsRows[index].productApiAccessStatus = access ? 'ASSIGNED' : 'NOT_ASSIGNED';
    setCurrentProductRows([...currentProductsRows]);
  };

  const fetchListWithFilters = ({
    hasAccess = changeMode === CHANGE_MODE_REMOVE_ACCESS ? true : false,
    name = filterByName,
    pageNum = page,
    rowsPerPageArg = rowsPerPage,
  } = { }) => {
    fetchList({
      apiUuid,
      filterByName : name,
      hasAccess,
      page: pageNum,
      rowsPerPage: rowsPerPageArg,
    });
  };
  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      fetchListWithFilters();
    }
  };
  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchListWithFilters({ pageNum: newPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchListWithFilters({ pageNum: 0, rowsPerPageArg : newRowsPerPage });
  };

  const addButtonLabel = getI18nMessage('label.api.details.apiproducts.list.add.button');
  const handleAddButton = () => {
    setChangeMode(CHANGE_MODE_ADD_ACCESS);
  };
  const showAddBtn = changeMode === CHANGE_MODE_REMOVE_ACCESS;
  const addBtnFunc = showAddBtn ? handleAddButton : emptyFunction;

  const saveUpdatedRows = () => {
    if (changeMode === CHANGE_MODE_REMOVE_ACCESS) {
      const removedAPIProducts = currentProductsRows.filter(item => item.productApiAccessStatus === 'NOT_ASSIGNED');
      const data = map(removedAPIProducts,
          item => ({ productUuid: item.productUuid }),
        );
      updateAccess({
        apiUuid,
        action: 'remove',
        data,
      });
    } else if (changeMode === CHANGE_MODE_ADD_ACCESS) {
      const addededAPIProducts = currentProductsRows.filter(item => item.productApiAccessStatus === 'ASSIGNED');
      const data = map(addededAPIProducts,
          item => ({ productUuid: item.productUuid }),
        );
      updateAccess({
        apiUuid,
        data,
      });
    }
  };

  const onCancel = () => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS) && !isFormDirty) {
      setChangeMode(CHANGE_MODE_REMOVE_ACCESS);
    }
  }

  const assistText = isFormDirty ? (<span>
    {getI18nMessage('label.api.details.apiproducts.list.save.assist.text', { num: modifiedRecords })}
    <WarningIcon className={classes.warningIcon} />
    {getI18nMessage('label.redepolyment.required')}
  </span>) : '';

  return (
    <Fragment>
      <ListContainer
        hasPaginationDisabled={isFormDirty}
        listPageId="products-list"
        isLoading={false}
        notificationId="request-notifications"
        notificationStatus={''}
        setNotificationStatus={() => {}}
        notificationMessage={''}
        setNotificationMessage={() => {}}
        pageHeaderTitle={''}
        pageHeaderDescription={''}
        pageHeaderTooltipTitle={getI18nMessage('label.request.list.page.title.help')}
        addButtonLabel={''}
        onAdd={false}
        showListHeader={true}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.nameFieldContainer}
              handleChange={setFilterByName}
              name={getI18nMessage('label.filter')}
              onKeyPress={onFilterByNameKeyPress}
              placeholder={getI18nMessage('label.product')}
              value={filterByName}
            />
            <Grid item md={4} sm={4} xs={6}>
              {showAddBtn && <Button
                className={classes.addBtn}
                data-apim-test={'api-products-add'}
                data-layer7-test={'api-products-add'}
                disabled={isFormDirty}
                id={'api-products-add'}
                onClick={addBtnFunc}
                variant="contained" color="secondary"
              >
                {addButtonLabel}
              </Button>}
            </Grid>
          </Fragment>
        )}
        columns={getListColumns({ getI18nMessage, classes })}
        rows={currentProductsRows}
        noResultsMessage={getI18nMessage('label.no.results')}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        notifyMessages={emptyFunction}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        pageBodyClass={classes.pageBodyClass}
      />
      {showFormActionBtns && <FormActionButtons
        assistText={assistText}
        isDisabled={!isFormDirty}
        nextText={'Save'}
        onCancelClick={onCancel}
        onNextClick={saveUpdatedRows}
        id="app-api-products-form-buttons"
      />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  rows: getProductListResults(state),
  totalElements: getProductListTotalElements(state),
  totalPages: getProductListTotalPages(state),
  isApiUpdateSuccess: getIsProductAccessSaveSuccess(state),
});

Products.propTypes = {
  apiUuid: string,
  apiDetails: object,
  classes: shape({}),
  fetchList: func,
  isApiUpdateSuccess: bool,
  notifyMessages: func,
  rows: arrayOf(shape({})),
  totalElements: number,
  totalPages: number,
  updateAccess: func,
  userContext: object,
  tabValue: string,
};
Products.displayName = 'Products';

const mapDispatchToProps = {
  fetchList: fetchApisProductList,
  updateAccess: updateApisProductList,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Products);
