import { map } from 'lodash';

const MAX_LENGTH = 5000;
export const isApplicationDirty = (base, modified) => {
 return !base ||
  base.name !== modified.name ||
  ((base.description || modified.description) && base.description !== modified.description);
}

export const checkCustomFieldsDirty = (base, modified) =>
  !base ||
  Object.keys(base).length !== Object.keys(modified).length ||
  Object.keys(base).some(key => modified[key] !== base[key]);

export const validateAppDetails = (appDetailsObject) =>
  appDetailsObject.name &&
  appDetailsObject.name.length > 0 &&
  appDetailsObject.name.length < 51;

export const validateCustomFields = (customFields, customFieldValues) =>
  customFields &&
  customFields.length>0 &&
  customFields.every(customField =>
    (!customField.required ||
      (customFieldValues[customField.uuid] &&
       customFieldValues[customField.uuid].length >0)
      ) && (!customFieldValues[customField.uuid] ||
        customFieldValues[customField.uuid].length <= MAX_LENGTH));

export const isAPisFormDirty = (apisArray, baseApisArray) =>
  apisArray.length !== baseApisArray ||
  apisArray.some(api => !baseApisArray.includes(api));

export const processErrors = (arrErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  if (arrErrors.length > 0) {
    errorMessage = map(arrErrors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage;
};
