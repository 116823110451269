import * as React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField } from '../../../components';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="products-list-filter-by-name"
      data-apim-test="products-list-filter-by-name"
    />
  </Grid>
);
