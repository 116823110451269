import React from 'react';
import {
  makeStyles,
  Button,
  Grid,
  AppBar,
} from '@material-ui/core';
import { bool, string, func, shape, oneOfType } from 'prop-types';
import styles from './styles';
import { getI18nFormattedMessage } from '../../../../utils/intl';

export default function ApplicationFooter(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    onCancel,
    onSave,
    onPublish,
    showCancel = true,
    showSave = true,
    showPublish = true,
    isSaveDisabled = false,
    saveText,
    publishText,
  } = props;

  return (
    <AppBar className={classes.appBar} id={id} data-layer7-test={id}>
      <Grid container>
        <Grid md={2} item className={classes.cancelContainer}>
          {showCancel &&
            <Button
              id="cancel-button"
              data-apim-test="cancel-button"
              color="primary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={() => onCancel()}
            >
              {getI18nFormattedMessage('label.application.details.footer.cancel.button')}
            </Button>
          }
        </Grid>
        <Grid md={10} item>
          <div className={classes.saveContainer}>
            {showPublish &&
            <>
              <div className={classes.assistText}>
                {getI18nFormattedMessage('label.application.details.footer.publish.assist.text1')}
                <br/>
                {showSave && getI18nFormattedMessage('label.application.details.footer.publish.assist.text2')}
              </div>
              <Button
                id="publish-button"
                data-apim-test="publish-button"
                variant="contained"
                color="secondary"
                className={classes.saveButton}
                onClick={() => onPublish()}
                disabled={showSave}
              >
                {publishText}
              </Button>
            </>
            }
            {showSave &&
              <Button
                id="save-button"
                data-apim-test="save-button"
                variant="contained"
                color="secondary"
                className={classes.saveButton}
                onClick={() => onSave()}
                disabled={isSaveDisabled}
              >
                {saveText}
              </Button>
            }
          </div>
        </Grid>
      </Grid>
    </AppBar>
  );
}

ApplicationFooter.propTypes = {
  id: string,
  assistText: string,
  cancelText: string,
  nextText: oneOfType([string, shape({})]),
  onCancel: func,
  onSave: func,
  onPublish: func,
  showCancel: bool,
  showSave: bool,
  showPublish: bool,
  isSaveDisabled: bool,
  saveText: string,
  publishText: string,
};
