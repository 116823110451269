/**
 * Services for fetching the Setup data required for bootstrapping Portal.
 */

// @flow

import { merge, get } from 'lodash';
import defaultConfig from './defaultConfig';
import axios from 'axios';
import { readCookie } from '../utils/cookie';

const SETTINGS_SERVICE_ID = '0084e50c-7a6b-4c4b-808f-fd891f58802d';
const PUBLISHER_SERVICE_ID = '6633bfa5-2efc-44a9-a8db-1a9233a3edaa';
const DEVELOPER_SERVICE_ID = 'f3d3d2ba-c84a-4764-876c-c36a4f3cd275';

type Portal = {
  tenantPrefix: string,
}

export default async function() {
  const hostname = (process.env.REACT_APP_APIM_PORT) ?
    `${process.env.REACT_APP_APIM_HOST}:${process.env.REACT_APP_APIM_PORT}` :
    (process.env.REACT_APP_APIM_HOST || window.location.origin);
  const [features, config] = await Promise.all([
    fetchFeatures(hostname),
    fetchConfig(hostname),
  ]);
  const { portal } = config;
  const userPrefs = JSON.parse(readCookie('userPrefs') || '{}');
  if(!portal.tenantPrefix) {
    const tenantId = await Promise.all([
      fetchTenant(hostname),
    ]);
    portal.tenantPrefix = tenantId;
  }
  if(features.FEATURE_FLAG_NEW_LANDING_PAGE === 'true') {
    const userContexts = await fetchUserContexts(portal);
    const [ dict, themes, featureFlags, globalNavigation ] = await Promise.all([
      fetchDict(portal),
      fetchThemes(portal, userContexts),
      buildFeatureFlags(portal, features),
      fetchGlobalServicesNavigation(portal),
    ]);
    let cqNavigation;
    const saasContentMgmtPage = globalNavigation.filter(item => 'label.appnav.contentmanagement' === item.titleKey);
    if(saasContentMgmtPage && saasContentMgmtPage.length === 1) {
      cqNavigation = await fetchCqPagesFromNavigationJson(portal);
    }
    return {
      config,
      dict,
      userContexts,
      themes,
      featureFlags,
      globalNavigation,
      cqNavigation,
      ...(cqNavigation ? { cqNavigation } : {}),
      userPrefs,
    };
  } else {
    const userContexts = await fetchUserContexts(portal);
    return {
      config,
      dict: await fetchDict(portal),
      userContexts: userContexts,
      services: await fetchServices(portal),
      themes: await fetchThemes(portal, userContexts),
      publisherNavigation: await fetchPublisherNavigation(portal),
      developerNavigation: await fetchDeveloperNavigation(portal),
      settingsNavigation: await fetchSettingsNavigation(portal),
      featureFlags: await fetchFeatureFlags(portal),
      userPrefs,
    };
  }
}

export async function loadHeaderConfig() {
  const hostname = process.env.REACT_APP_APIM_HOST || window.location.origin;
  const config = await fetchConfig(hostname);
  const { portal } = config;
  const userContexts = await fetchUserContexts(portal);

  const [ dict, themes, featureFlags, globalNavigation ] = await Promise.all([
    fetchDict(portal),
    fetchThemes(portal, userContexts),
    fetchFeatureFlagsForGlobalHeader(portal),
    fetchGlobalServicesNavigation(portal),
  ]);
  let cqNavigation;
  const saasContentMgmtPage = globalNavigation.filter(item => 'label.appnav.contentmanagement' === item.titleKey);
  if(saasContentMgmtPage && saasContentMgmtPage.length === 1) {
    cqNavigation = await fetchCqPagesFromNavigationJson(portal);
  }
  return {
    config,
    dict,
    userContexts,
    themes,
    featureFlags,
    globalNavigation,
    cqNavigation,
    ...(cqNavigation ? { cqNavigation } : {}),
  };
}

/**
 * Only executed when session is logged out and no Header is displayed. eg. Login / Registration
 */
export async function loadCookieConsentConfig() {
  const hostname = process.env.REACT_APP_APIM_HOST || window.location.origin;
  const tenantPrefix = window.location.hostname.split('.')[0];

  const themes = await fetchThemes({ hostname, tenantPrefix });
  return {
    themes,
  };
}

export async function loadNoAuthConfig() {
  const hostname = process.env.REACT_APP_APIM_HOST || window.location.origin;
  const tenantPrefix = window.location.hostname.split('.')[0];

  const themes = await fetchThemes({ hostname, tenantPrefix });
  const cmsSettings = await fetchCmsSettings({ hostname });
  return {
    themes,
    cmsSettings,
  };
}

export async function fetchConfig(hostname: string){
  let config = defaultConfig;
  try {
    const fetchedConfig = await axios.get(`${hostname}/dev/config.json`);
    const { data }= fetchedConfig;
    // TODO: Needs to be implemented via a more standard way as in the react-15 code
    if(!data.server && window.location.pathname !== '/admin/app/home') {
      window.location.href =
        (`${hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`);
    }
    data.portal = { ...data.portal, hostname };
    config = merge(config, data);
  } catch (e){
    console.error("Load config json error: ", e);
  }
  return config;
}
export async function fetchTenant(hostname: string){
  try {
    const fetchedAuthSchemes = await axios.get(`${hostname}/admin/public/auth/schemes`);
    const { data }= fetchedAuthSchemes;
    return data.authSchemes[0].tenantId;
  } catch (e){
    console.error("Load AuthScheme error: ", e);
    return "";
  }
}
async function fetchFeatures(hostname: string){
  try {
    const response = await axios.get(`${hostname}/admin/features`);
    return response && response.data;
  } catch(e) {
    return [];
  }
}

export async function fetchDict({ hostname }: Portal){
  const response = await axios.get(`${hostname}/admin/dict.json`);
  return (response && response.data);
}

export async function fetchUserContexts({ hostname, tenantPrefix }: Portal){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/userContexts`);
  return (response && response.data);
}

export async function fetchServices({ hostname, tenantPrefix }: Portal){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/papi/1.0/services`);
  return (response && response.data);
}

export async function fetchGlobalServicesNavigation({ hostname }: Portal){
  try {
    const response = await axios.get(`${hostname}/admin/navigation/internal/header-menu`);
    return (response && response.data);
  } catch(e) {
    console.error('Error loading header-menu api', e);
    return [];
  }
}

export async function fetchCqPagesFromNavigationJson({ hostname }: Portal){
  const response = await axios.get(`${hostname}/app/navigation.json`).catch(function(error){
    console.error('Error while invoking navigation.json', error);
    return [];
  });
  return (response && response.data);
}

export async function fetchThemes({ hostname, tenantPrefix }: Portal, userContexts){
  const activeOrgUuid = get(userContexts, 'userContexts[0].activeOrgUuid');
  const config = activeOrgUuid
    ? { headers: { 'APIM-OrgUuid': activeOrgUuid } }
    : {};
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/branding/1.0/themes`, config);
  return get(response, 'data', {});
}

export async function fetchSettingsNavigation({ hostname, tenantPrefix }: Portal){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/papi/1.0/services/${SETTINGS_SERVICE_ID}/navigation`);
  return (response && response.data);
}

export async function fetchPublisherNavigation({ hostname, tenantPrefix }: Portal){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/papi/1.0/services/${PUBLISHER_SERVICE_ID}/navigation`);
  return (response && response.data);
}

export async function fetchDeveloperNavigation({ hostname, tenantPrefix }: Portal){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/papi/1.0/services/${DEVELOPER_SERVICE_ID}/navigation`);
  return (response && response.data);
}

export async function fetchFeatureFlagsForGlobalHeader({ hostname, tenantPrefix }: Portal){
  const newLandingPage = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_NEW_LANDING_PAGE');
  return {
    newLandingPage,
  };
}

export async function fetchFeatureFlags({ hostname, tenantPrefix }: Portal){
  const apiPlatform = await fetchFeatureFlag(hostname, tenantPrefix, 'APIM_PLATFORM');
  const apiPlans = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_API_PLANS');
  const uiSidebar = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_UI_SIDEBAR');
  const customPages = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_CUSTOM_PAGES');
  const newLandingPage = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_NEW_LANDING_PAGE');
  const useLegacyApiPages = await fetchFeatureFlagLegacyApiPages(hostname, tenantPrefix, 'FEATURE_FLAG_USE_LEGACY_API_PAGES');
  const useLegacyApplicationPages = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_USE_LEGACY_APPLICATION_PAGES');
  const useMultiApiKeys = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_MULTI_API_KEYS');
  const l7Manager = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_L7IM');
  const product = await fetchFeatureFlag(hostname, tenantPrefix, 'FEATURE_FLAG_PRODUCT');
  return {
    apiPlatform, apiPlans, uiSidebar, customPages, useLegacyApiPages,
    useLegacyApplicationPages, newLandingPage, useMultiApiKeys, l7Manager, product,
  };
}

async function buildFeatureFlags({ hostname, tenantPrefix }: Portal, features){
  const apiPlatform = await fetchFeatureFlag(hostname, tenantPrefix, 'APIM_PLATFORM');
  const apiPlans = buildFeatureFlag(features, 'FEATURE_FLAG_API_PLANS');
  const uiSidebar = buildFeatureFlag(features, 'FEATURE_FLAG_UI_SIDEBAR');
  const customPages = buildFeatureFlag(features, 'FEATURE_FLAG_CUSTOM_PAGES');
  const newLandingPage = buildFeatureFlag(features, 'FEATURE_FLAG_NEW_LANDING_PAGE');
  const useLegacyApiPages = buildFeatureFlag(features, 'FEATURE_FLAG_USE_LEGACY_API_PAGES');
  const useLegacyApplicationPages = buildFeatureFlag(features, 'FEATURE_FLAG_USE_LEGACY_APPLICATION_PAGES');
  const useMultiApiKeys = buildFeatureFlag(features, 'FEATURE_FLAG_MULTI_API_KEYS');
  const l7Manager = buildFeatureFlag(features, 'FEATURE_FLAG_L7IM');
  const product = buildFeatureFlag(features, 'FEATURE_FLAG_PRODUCT');
  return {
    apiPlatform, apiPlans, uiSidebar, customPages, useLegacyApiPages, useLegacyApplicationPages,
    newLandingPage, useMultiApiKeys, l7Manager, product,
  };
}

function buildFeatureFlag(features, flagName){
  return {
    Name: flagName,
    Value: features[flagName],
  };
}
export async function fetchFeatureFlag(hostname: string, tenantPrefix: string,
  featureFlagName: string){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/Settings('${featureFlagName}')`)
    .then((response) => response)
    .catch(() => (
      { data: { Value: 'false' } }
    ));
  return (response && response.data);
}

export async function fetchFeatureFlagLegacyApiPages(hostname: string, tenantPrefix: string,
  featureFlagName: string){
  const response = await axios.get(`${hostname}/api/${tenantPrefix}/Settings('${featureFlagName}')`)
    .then((response) => response)
    .catch(() => (
      { data: { Value: 'false' } }
    ));
  return (response && response.data);
}

export async function checkValidSession(){
  const hostname = process.env.REACT_APP_APIM_HOST || window.location.origin;
  const tenantPrefix = window.location.hostname.split('.')[0];

  const response = await axios.get(`${hostname}/api/${tenantPrefix}/sessionCheck`)
    .then((response) => response)
    .catch(() => (
      { data: { status: false } }
    ));
    return (get(response, 'data'));
}

export async function fetchCmsSettings({ hostname }: Portal){
  const response = await axios.get(`${hostname}/admin/cmsSettings`);
  return (response && response.data);
}
