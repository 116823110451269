import React, { Fragment, useEffect, useState } from 'react';
import { number, string, bool, func, object, arrayOf } from 'prop-types';
import {
  Grid, Tooltip, Typography,
} from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import {
  ErrorContainer,
  FormAutoCompleteCombo,
  FormTextField,
} from '../../../../components';
import EntityScrollBar from './EntityScrollBar';
import { fetchOrganizations } from '../../../../actions/application';
import { getOrganizations1 } from '../../../../reducers/application';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import CustomFields from './CustomFields';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { useIntl } from 'react-intl';
import { ReportProblem } from '@material-ui/icons';
const getSelectedOrganization = (organizations = [], organizationUuid) => (
  organizations.find(organization => (organization.uuid === organizationUuid)) || {}
);

const getSelectedOrganizationForOrgBoundUser = (userAccessibleOrgs = [], userActiveOrgUuid) => (
  userAccessibleOrgs.find(userAccessibleOrg => (userAccessibleOrg.uuid === userActiveOrgUuid)) || {}
);

export const Overview =(props) => {
  const {
    classes,
    errors = [],
    isOrgBoundUser,
    userActiveOrgUuid,
    userAccessibleOrgs,
    appDetailsObj = {},
    setAppDetails,
    organizations1 = {},
    customFields,
    customFieldValues,
    setCustomFieldValues,
    isSaveClicked,
    validateNameUniqueness,
    setActiveTab,
    apiCount,
    isApiPlansEnabled,
    apiGroupsCount,
    keysCount,
    locked,
    customFieldsLocked,
    isLocking,
    disabledByInternal,
    disableEntityScrollBar,
    isEditDisabled,
  } = props;
  const intl = getI18n(useIntl());
  const { description = '', organizationUuid, organizationName } = appDetailsObj;
  const [selectedOrg, setSelectedOrg] = useState('');
  const [applicationDescription, setApplicationDescription] = useState(description);
  useEffect(() => {
    if(appDetailsObj){
      setApplicationDescription(description);
      setSelectedOrg(isOrgBoundUser ?
        getSelectedOrganizationForOrgBoundUser(userAccessibleOrgs, userActiveOrgUuid) :
        getSelectedOrganization(organizations1.results, organizationUuid));
    }
  }, [appDetailsObj]);

  useEffect(() => {
    if (!isOrgBoundUser) {
      props.fetchOrganizations(0, 100);
    }
  }, []);

  const handleChange = async (fieldName, fieldValue) => {
    localStorage.setItem('isAppUnSavedChanges', true);
    switch (fieldName) {
      case 'organizationUuid':
        if(fieldValue) {
          validateNameUniqueness(appDetailsObj.name, appDetailsObj.uuid, fieldValue.uuid);
          setAppDetails({
            ...appDetailsObj,
            organizationUuid: fieldValue.uuid,
          });
          setSelectedOrg(fieldValue);
        }
        break;
      case 'description':
        setApplicationDescription(fieldValue);
        setAppDetails({
          ...appDetailsObj,
          description: fieldValue,
        });
        break;
      default:
    }
  };

  const handleBlurDescriptionField = (descriptionValue) => {
    // Set to Application Details on blur to reduce the render cycles
    setAppDetails({
      ...appDetailsObj,
      description: descriptionValue,
    });
  };

  useEffect(() => {
    // Recurring fetch call to cache the organizations
    if ((organizations1.currentPage + 1) < (organizations1.totalPages)) {
      props.fetchOrganizations(organizations1.currentPage + 1, 20);
    }
  }, [organizations1]);
  
  const getEntitiesCounts = () => {
    const array = [{ entity: "APIs", count: apiCount },{ entity: "Keys", count:keysCount }];
    if(!isApiPlansEnabled) {
      array.splice(1, 0, { entity: "APIGroups", count: apiGroupsCount });
    }
    return array;
  };
  return (
    <Fragment>
      {(errors.length > 0) &&
        <ErrorContainer errors={errors} />
      }
      <EntityScrollBar
        setActiveTab={setActiveTab}
        entities={getEntitiesCounts()}
        disabled={disableEntityScrollBar}
      />
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid md={6} item>
          <div className={classes.h2Container} id="overview-fragment">
            <Typography variant="h2" className={classes.pageTitle}>
            {getI18nFormattedMessage('label.application.details.smalltitle.details')}
            </Typography>
            {locked &&
            <Tooltip
              title={getI18nFormattedMessage(disabledByInternal ? 'label.application.details.locked.by.internal.tooltip':
               'label.application.details.overview.locked.tooltip')}
              arrow placement="right">
              <Lock className={classes.lockIcon}/>
            </Tooltip>}
            {!locked && isLocking &&
            <Tooltip
              title={getI18nFormattedMessage('label.application.details.overview.locking.tooltip')}
              arrow placement="right">
              <ReportProblem className={classes.warningIcon}/>
            </Tooltip>
            }
          </div>
          {!isOrgBoundUser &&
            (appDetailsObj && appDetailsObj.uuid ?
              <FormTextField
                id={'application-selected-organization'}
                name={intl.getI18nMessage('label.application.details.overview.org.name')}
                value={organizationName}
                disabled
                fieldContainerClass={classes.autoComplete}
              /> :
              <FormAutoCompleteCombo
                id="application-selected-organization"
                name={intl.getI18nMessage('label.application.details.overview.org.name')}
                selectedValues={selectedOrg}
                data={organizations1.results}
                onChange={(e, value) => { handleChange('organizationUuid', value); }}
                loading={organizations1.currentPage < (organizations1.totalPages - 1)}
                error={isSaveClicked && (!selectedOrg || !selectedOrg.uuid)}
                fieldContainerClass={classes.autoComplete}
                helperText={intl.getI18nMessage('label.application.details.overview.org.helpertext')}
              />)
          }
          <FormTextField
            id={'application-description'}
            name={'Public Description'}
            value={applicationDescription}
            handleChange={value => { handleChange('description', value || ''); }}
            handleBlur={value => { handleBlurDescriptionField(value); }}
            helperText={intl.getI18nMessage('label.application.details.overview.desc.helpertext')}
            multiline
            disabled={locked || isEditDisabled}
            rows={4}
            optional
            maxLength="255"
          />
        </Grid>
        <Grid md={6} className={classes.customFields}>
          {customFields && customFields.length>0 &&
          (<CustomFields
            classes={classes}
            customFields={customFields}
            customFieldValues={customFieldValues}
            setCustomFieldValues={setCustomFieldValues}
            isSaveClicked={isSaveClicked}
            intl={intl}
            locked={customFieldsLocked}
            isLocking={isLocking}
            disabledByInternal={disabledByInternal}
            isEditDisabled={isEditDisabled}
          />)}
        </Grid>
      </Grid>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  organizations1: getOrganizations1(state),
});

const mapDispatchToProps = {
  fetchOrganizations,
};
Overview.propTypes = {
  classes: object,
  isOrgBoundUser: bool,
  userActiveOrgUuid: string,
  userAccessibleOrgs: arrayOf(object),
  errors: arrayOf(object),
  applicationDetails: object,
  organizations1: object,
  fetchOrganizations: func,
  appDetailsObj: object,
  onCancel: func,
  customFields: arrayOf(object),
  customFieldValues: object,
  setCustomFieldValues: func,
  setAppDetails: func,
  isSaveClicked: bool,
  validateNameUniqueness: func,
  setActiveTab: func,
  apiCount: number,
  isApiPlansEnabled: bool,
  apiGroupsCount: number,
  keysCount: number,
  onPublish: func,
  locked: bool,
  customFieldsLocked: bool,
  isLocking: bool,
  disabledByInternal: bool,
  disableEntityScrollBar: bool,
  isEditDisabled: bool,
};
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Overview);