import React, { Fragment, useState, useEffect } from 'react';
import { string, bool, shape, number, object, func } from 'prop-types';
import { capitalCase } from 'change-case';
import get from 'lodash/get';

import { TableCell, TableRow, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LensIcon from '@material-ui/icons/Lens';
import { ArrowRightAlt, Cached, KeyboardBackspace } from '@material-ui/icons';
import AlertDialog from '../../../../../components/AlertDialog';

import { hasAdminRole, formatStatusMessage } from '../../../../../utils';
import { getDeployedStatusColor, getDeployedStatusLabel } from '../../../../../utils/deployment';
import getDateTime from '../../../../../utils/datetime';

import {
  DEPLOYMENT_ACTION_TYPE_DEPLOY,
  DEPLOYMENT_ACTION_TYPE_REDEPLOY,
  DEPLOYMENT_ACTION_TYPE_UNDEPLOY,
  DEPLOYMENT_ACTION_LABEL_DEPLOY,
  DEPLOYMENT_ACTION_LABEL_REDEPLOY,
  DEPLOYMENT_ACTION_LABEL_UNDEPLOY,
  MORE_INFO_TEXT,
  CHECK_NOW_TEXT,
  UPDATING_STATUS_TEXT,
  SECONDS_UNIT_TEXT,
  DEPLOYMENT_STATUS_ERROR,
  DEPLOYMENT_STATUS_LABEL_UNDEPLOYED,
  DEPLOYMENT_STATUS_LABEL_VALIDATION_ERROR,
  DEPLOYMENT_STATUS_LABEL_DEPLOYMENT_ERROR,
  APP_DEPLOYMENT_TYPE_ON_DEMAND,
  INFORMATION_TEXT,
  WARNING_TEXT,
  CONFIRM_ACTION_TEXT,
  OK_TEXT,
  CANCEL_TEXT,
} from '../../labels';


import styles from '../styles';

const renderTitle = (isAdmin, name = '', uuid, classes) => isAdmin ?(
  <span data-test={'proxy-title'} className={classes.linkWrapper}>
    <Link href={`/publish/api-proxy/details/${uuid}`}>
      {name}
    </Link>
  </span>
) : (<span>{name}</span>);

export const formatDateString = (dateString) => {
  if (dateString) {
    const arrDateFields = dateString.split(' ');
    const month = arrDateFields[1];
    const date = arrDateFields[2];
    const year = arrDateFields[3];

    let formattedTimeString = '';
    if (arrDateFields[4]) { // time string
      const arrTimeFields = arrDateFields[4].split(':');
      const hour = arrTimeFields[0];
      const mins = arrTimeFields[1];
      formattedTimeString = `${hour}:${mins}`;
    }
    return `${month} ${date} ${year} ${formattedTimeString}`;
  }
  return '';
};

export const getStatusColor = (errorMessage, status) => {
  let statusLabel = '';
  let statusColor = '';
  if (errorMessage) {
    statusLabel = DEPLOYMENT_STATUS_LABEL_VALIDATION_ERROR;
    statusColor = getDeployedStatusColor(DEPLOYMENT_STATUS_ERROR);
  } else {
    statusLabel = (status === DEPLOYMENT_STATUS_ERROR)
      ? DEPLOYMENT_STATUS_LABEL_DEPLOYMENT_ERROR
      : getDeployedStatusLabel(status);
    statusColor = getDeployedStatusColor(status);
  }
  return {
    statusLabel, statusColor,
  }
};

export const DeploymentsCard = ({
  details: {
    apiKey = '',
    uuid,
    name,
    type,
    status,
    statusMessage,
    lastTimeDeployed,
  },
  errorObj = {},
  refreshTime = 10,
  deployApiKey,
  redeployApiKey,
  undeployApiKey,
  checkStatus,
  user,
  classes,
  isDeletePending,
  isEditDisabled,
}) => {
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [updating, setUpdating] = useState(false);
  const [timer, setTimer] = useState(parseInt(refreshTime, 10) || 10);

   // Error Info
  const errorMessage = get(errorObj, 'errorMessage');

  const startAction = (action) => {
    switch (action) {
      case DEPLOYMENT_ACTION_TYPE_DEPLOY: {
        deployApiKey(apiKey, uuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_REDEPLOY: {
        redeployApiKey(apiKey, uuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_UNDEPLOY: {
        undeployApiKey(apiKey, uuid);
        break;
      }
      default: {
        break;
      }
    }
    setUpdating(!updating);
  };

  const stopAction = () => {
    setTimer(refreshTime);
    setUpdating(false);
    checkStatus(apiKey);
  };

  useEffect(() => {
    let interval = null;
    if (updating) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        if (timer === 1) {
          stopAction();
          clearInterval(interval);
        }
      }, 1000);
    } else if (!updating && timer !== refreshTime) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [updating, timer]);

  const { statusLabel, statusColor } = getStatusColor(errorMessage, status);
  const statusStyle = { color: statusColor };

  const openStatusDialog = () => setShowStatusDialog(true);
  const closeStatusDialog = () => setShowStatusDialog(false);

  const openConfirmDialog = (action) => {
    setShowConfirmDialog(true);
    setConfirmAction(action);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setConfirmAction('');
  };

  const saveConfirmDialog = () => {
    startAction(confirmAction);
    closeConfirmDialog();
  };

  const MofeInfo = () => (
    <span data-test={'moreInfoBtn'}>
      <Button onClick={openStatusDialog}>
        {MORE_INFO_TEXT}
      </Button>
    </span>
  );

  const CheckStatus = () => (
    <span data-test={'checkStatusBtn'}>
      <Button onClick={stopAction}>
        {CHECK_NOW_TEXT}
      </Button>
    </span>
  );

  const DeploymentActions = () => (
    <span>
      {(statusLabel === DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'deployBtn'}>
          <Button
           onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_DEPLOY)}
           className={classes.button}
           disabled={isDeletePending || isEditDisabled}
          >
          <ArrowRightAlt />{DEPLOYMENT_ACTION_LABEL_DEPLOY}
          </Button>
        </span>
      }
      {(statusLabel !== DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'redeployBtn'}>
          <Button
            onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_REDEPLOY)}
            className={classes.button}
            disabled={isDeletePending || isEditDisabled}
          >
            <Cached />{DEPLOYMENT_ACTION_LABEL_REDEPLOY}
          </Button>
        </span>
      }
      {(statusLabel !== DEPLOYMENT_STATUS_LABEL_UNDEPLOYED) &&
        <span data-test={'undeployBtn'}>
          <Button
            onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_UNDEPLOY)}
            className={classes.button}
            disabled={isDeletePending || isEditDisabled}
          >
            <KeyboardBackspace />{DEPLOYMENT_ACTION_LABEL_UNDEPLOY}
          </Button>
        </span>
      }
    </span>
  );

  return (
    <Fragment>
      {showStatusDialog &&
        <AlertDialog
          isOpen={showStatusDialog}
          title={INFORMATION_TEXT}
          description={errorMessage || formatStatusMessage(statusMessage)}
          submitText={OK_TEXT}
          cancelText={CANCEL_TEXT}
          onClose={closeStatusDialog}
          onSubmit={closeStatusDialog}
          onCancel={closeStatusDialog}
          showCancel={false}
          dialogId={'app-deployment-status-dialog'}
        />
      }
      {showConfirmDialog &&
        <AlertDialog
          isOpen={showConfirmDialog}
          title={WARNING_TEXT}
          description={CONFIRM_ACTION_TEXT}
          submitText={OK_TEXT}
          cancelText={CANCEL_TEXT}
          onClose={closeConfirmDialog}
          onSubmit={saveConfirmDialog}
          onCancel={closeConfirmDialog}
          dialogId={'app-deployment-confirm-dialog'}
        />
      }
        <TableRow>
          <TableCell>
            {renderTitle(hasAdminRole(user),name, uuid, classes)}
          </TableCell>
          <TableCell>
            {capitalCase(type)}
          </TableCell>
          <TableCell>
            {updating ?
              <span>
                <LensIcon className={classes.cardStatusIcon} />
                {`${UPDATING_STATUS_TEXT} ${timer}${SECONDS_UNIT_TEXT}.`}
              </span> :
              <span style={statusStyle}>
                <LensIcon className={classes.cardStatusIcon} /> {statusLabel}
              </span>
            }
            <div className={classes.deploymentButtonsContainer}>
              {(!updating && (errorMessage || statusMessage)) &&
                <MofeInfo />
              }
              {(type === APP_DEPLOYMENT_TYPE_ON_DEMAND && updating) &&
                  <CheckStatus />
              }
            </div>
          </TableCell>
          <TableCell>
            {getDateTime(lastTimeDeployed)}
          </TableCell>
          <TableCell>
              {(type === APP_DEPLOYMENT_TYPE_ON_DEMAND && !updating) &&
              <span data-test={'app-deployment-actions'}>
                <DeploymentActions />
              </span>
              }
          </TableCell>
        </TableRow>
    </Fragment>
  );
};

DeploymentsCard.propTypes = {
  index: number,
  details: shape({
    applicationUuid: string,
    uuid: string,
    name: string,
    status: string,
    statusMessage: string,
    modifyTs: string,
    apiKey: string,
    type: string,
  }),
  errorObj: object,
  refreshTime: number,
  deployApiKey: func,
  redeployApiKey: func,
  undeployApiKey: func,
  checkStatus: func,
  user: object,
  classes: object,
  isDeletePending: bool,
  isEditDisabled: bool,
};

export default withStyles(styles)(DeploymentsCard);
