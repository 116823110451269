import { get } from 'lodash';

const styles = ({ color, margin }) => ({
  addBtn: {
    float: 'right',
    marginTop: 52,
  },
  pageBodyClass: {
    paddingTop: 0,
  },
  pageFilterAndSortClass: {
    marginTop: 0,
    paddingTop: 0,
  },
  warningIcon: {
    color: get(color, 'warning'),
    marginLeft: get(margin, 'm10'),
    marginRight: get(margin, 'm4'),
    fontSize: 14,
    marginTop: get(margin, 'm4'),
  },
});

export default styles;
