
import get from 'lodash/get';

const styles = ({ color, margin, padding }) => ({
  tiersProductContainer: {
    '& #tiers-product-pagination': {
      display: 'none',
    },
    '& td': {
      verticalAlign: 'bottom',
    },
  },
  pageHeaderDescriptionDivider: {
    marginBottom: `${get(margin, 'm10')}`,
  },
  pageHeader: {
    padding: 0,
    '&> div': {
      maxWidth: '100%',
      flexBasis: '100%',
      '&> div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  pageTitle: {
    fontSize: '18px',
  },
  addButton: {
    display: 'none',
  },
  defaultButton: {
    textAlign: 'left',
    padding: 0,
    justifyContent: 'flex-start',
    height: '15px',
    verticalAlign: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      height: '15px',
    },
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm10'),
  },
  dialogContainer: {
    height: 450,
  },
  dialogActionClass: {
    justifyContent: 'flex-start',
  },
  marginPanelContainer: {
    minHeight: '800px',
    padding: '0 36px',
  },
  detailsContainer: {
    padding: '20px 36px',
  },
  paper: {
    paddingRight: '20px',
  },
  hideFooterContainer: {
    display: 'none',
  },
  showFooterContainer: {
    display: 'visible',
  },
});

export default styles;
