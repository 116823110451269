import React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField, FormSelect } from '../../../../components';

export const FilterByName = (props) => (
  <Grid item md={4} sm={4} xs={6}>
    <FormTextField
      {...props}
      id="org-products-filter-by-name"
      data-apim-test="org-products-filter-by-name"
    />
  </Grid>
);

export const FilterByTier = (props) => (
  <Grid item md={4} sm={4} xs={6}>
    <FormSelect
        {...props}
        id="orgs-products-tiers-list"
        data-apim-test="orgs-products-tiers-list"
        noNoneOption
        noNativeSelect
        hideLabel
    />
  </Grid>
);
