import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import {
  ALL,
  APP_LOADING,
  APP_RESET,
  USERS_DELETE_RESET,
  USERS_GET_SUCCESS,
  USERS_GET_ERROR,
  USER_ORGANIZATIONS_FILTERED_SUCCESS,
  USER_ORGANIZATIONS_FILTERED_ERROR,
} from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '../';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import { stringifyUrl } from '../../utils/actions';

const init = (dispatch, isLoading = true) =>
  dispatch({
    type: APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: APP_RESET });
export const resetDeleteNotification = () => dispatch => {
  localStorage.removeItem('isUserDeleteSuccess');
  dispatch({ type: USERS_DELETE_RESET });
};
export const showLoading = isLoading => dispatch => init(dispatch, isLoading);

export const fetchUsers = ({
  username,
  name,
  email,
  roleUuids = [],
  orgUuids,
  status,
  sortBy,
  sortOrder,
  page = 0,
  rowsPerPage = 12,
} = {}) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const urlPrefix = `${portal.hostname}/admin/v2/users`;
    const url = stringifyUrl({
      url: urlPrefix,
      query: {
        username,
        roleUuids: !isEmpty(roleUuids) ? roleUuids.join(',') : undefined,
        orgUuids,
        status: (status !== ALL) ? status : undefined,
        search: !!(name || email) || undefined,
        field: (name && 'name') || (email && 'email') || undefined,
        query: name || email || undefined,
        sortBy: sortBy || undefined,
        sortOrder: sortOrder || undefined,
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        includeAnonymous: true,
      },
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: USERS_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: USERS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchOrganizationsFiltered = (size, pageNumber, name = '') =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&size=${size}&page=${pageNumber}&name=${name}`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: USER_ORGANIZATIONS_FILTERED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: USER_ORGANIZATIONS_FILTERED_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
