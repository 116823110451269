/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import React from 'react';
import { useIntl } from 'react-intl';
import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

// Get user to know if user is logged in or not
import { getUserDetails } from '../../reducers/portalConfig';
import { getI18n } from '../../utils/intl';
import styles from './styles';
import { DOC_BASE_URL } from '../../constants';
import PropTypes from 'prop-types';

const Home = ({ classes }) => {
  const intl = getI18n(useIntl());

  return (
    <Container className={classes.container}>
      <div className="hero">
        <Grid container spacing={3} className={classes.heroTopContainer}>
          <Grid item xs={12}>
            <Typography variant="h1" component="h1">
              {intl.getI18nMessage('home.hero.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.hero.description')}</p>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="top"
          className={classes.row}
        >
          <Grid item xs={4} className={classes.heroBottomCard}>
            <Typography variant="h2" className={classes.h2}>
              {intl.getI18nMessage('home.hero.card1.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.hero.card1.description')}</p>
          </Grid>
          <Grid item xs={4} className={classes.heroBottomCard}>
            <Typography variant="h2" className={classes.h2}>
              {intl.getI18nMessage('home.hero.card2.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.hero.card2.description')}</p>
          </Grid>
          <Grid item xs={4} className={classes.heroBottomCard}>
            <Typography variant="h2" className={classes.h2}>
              {intl.getI18nMessage('home.hero.card3.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.hero.card3.description')}</p>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Typography variant="h1" className={classes.h1}>
          {intl.getI18nMessage('home.section1.title')}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="top"
        >
          <Grid item xs={4} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section1.card1.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section1.card1.description')}</p>
          </Grid>
          <Grid item xs={4} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section1.card2.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section1.card2.description')}</p>
          </Grid>
          <Grid item xs={4} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section1.card3.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section1.card3.description')}</p>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="top"
        >
          <Grid item xs={6} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section1.card4.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section1.card4.description')}</p>
          </Grid>
          <Grid item xs={6} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section1.card5.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section1.card5.description')}</p>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Typography variant="h1" className={classes.h1}>
          {intl.getI18nMessage('home.section2.title')}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="top"
        >
          <Grid item xs={6} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section2.card1.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section2.card1.description')}</p>
            <a
              href={`${DOC_BASE_URL}/manage/manage-policy-templates.html`}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {intl.getI18nMessage('home.section2.card1.link')}
            </a>
          </Grid>
          <Grid item xs={6} className={classes.card}>
            <Typography variant="h3" className={classes.h3}>
              {intl.getI18nMessage('home.section2.card2.title')}
            </Typography>
            <p>{intl.getI18nMessage('home.section2.card2.description')}</p>
            <a
              href={`${DOC_BASE_URL}/manage/manage-policy-templates/manage-policy-with-gateway-bundles.html`}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {intl.getI18nMessage('home.section2.card2.link')}
            </a>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userContexts: getUserDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  // fetchDashboardEntityCount: () => dispatch(fetchDashboardEntityCount()),
});

Home.propTypes = {
  classes: PropTypes.object,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Home);
