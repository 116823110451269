import { get } from 'lodash';
import { modifyAlpha } from '../../../utils/modifyColour';

export const styles = ({ color, fontWeight, fontSize, fontStyle, margin, typography }) => ({
  pageHeaderDescriptionDivider: {
    marginBottom: `${get(margin, 'm10')}`,
  },
  pageHeader: {
    '&> div': {
      maxWidth: '100%',
      flexBasis: '100%',
      '&> div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  acceptBtn: {
    color: color.primaryButtonBackground,
  },
  actionBtn: {
    fontSize: fontSize.bodyText,
    fontWeight: fontWeight.normal,
    height: 20,
  },
  alertMessageClass: {
    padding: '6px 0 !important',
    width: '540px !important',
  },
  alertMessageContainerClass: {
    margin: '0 0 !important',
  },
  alertMessageHeaderContainerClass: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertMessageLeftHeaderClass: {
    color: modifyAlpha(color.bodyText, 0.7),
    fontFamily: typography.bodyText,
    fontSize:fontSize.bodyText,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.bold,
  },
  alertMessageRightHeaderClass: {
    color: modifyAlpha(color.bodyText, 0.7),
    fontFamily: typography.bodyText,
    fontSize:fontSize.bodyText,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.normal,
  },
  alertMessageMaxLengthWarningClass: {
    color: modifyAlpha(color.bodyText, 0.7),
    fontFamily: typography.smallText,
    fontSize:fontSize.smallText,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.normal,
  },
  nameFieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '8px !important',
  },
  rejectBtn: {
    color: color.error,
  },
  rejectSubmitButton: {
    backgroundColor: `${color.error} !important`,
    borderColor: `${color.error} !important`,
  },
  pageFilterAndSortClass: {
    justifyContent: 'left',
  },
});

export default styles;
 