import { get, has, isEmpty, uniqBy, cloneDeep } from 'lodash';

import * as Constants from '../../constants';
import {
  getParsedApplicationDetails,
  getParsedCustomFields,
  getHashingMetadata,
} from './responseParser';
import { reduceReducers } from '../util';
import apiProductsReducer from './apiProductsReducer';

const initialState = {
  isLoading: true,
  errors: [],
  organizations1: {},
  organizations: [],
  organizationsCount: 0,
  applicationDetails: {
    apiIds: [],
    apiApiPlanIds: [],
    apiGroupIds: [],
    customFieldValues: {},
    oauthType: 'PUBLIC',
  },
  applicationNameUnique: { d: { result: true } },
  customFields: [],
  selectedApis: [],
  availableApis: [],
  availableApisCount: 0,
  availableApps: [],
  availableAppsCount: 0,
  availableOrgs: [],
  availableOrgsCount: 0,
  apiEula: {},
  apiGroupEulas: [],
  selectedApiGroups: [],
  availableApiGroups1: {},
  availableApiGroups: [],
  availableApiGroupsCount: 0,
  availableApiPlans: {},
  isApplicationSaveSuccess: false,
  tenantInfos: {},
  proxyErrors: [],
  proxies: [],
  apiKeyProxies: [],
  singleApplication: {},
  allApis: [],
  allApps: [],
  allApiPlans: [],
  updateApplicationStatus: '',
  deleteApplicationStatus: Constants.NONE,
  createdKey: null,
  createKeyStatus: '',
  updateKeyStatus: '',
  deleteKeyStatus: '',
  deleteKeyErrors: [],
  isUniqueKey: '',
  keys: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  applicationAPIProducts: {},
};

const updateProxyErrors = (arr = [], obj) => {
  const tempArr = cloneDeep(arr);
  const index = arr.findIndex((e) => (e.proxyUuid === obj.proxyUuid && e.apiKey === obj.apiKey));
  if (index === -1) {
    tempArr.push(obj);
  } else {
    tempArr[index] = obj;
  }
  return tempArr;
};

const updateApiKeyProxies = (arr = [], obj) => {
  const tempArr = cloneDeep(arr);
  const index = arr.findIndex((e) => e.key === obj.key);
  if (index === -1) {
    tempArr.push(obj);
  } else {
    tempArr[index] = obj;
  }
  return tempArr;
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.APPLICATION_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applications: action.payload,
      };
    case Constants.APPLICATION_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isListError: true,
      };
    case Constants.APPLICATION_LOADING:
      return {
        ...state,
        apiEula: {},
        apiGroupEulas: [],
        isLoading: action.isLoading,
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APP_RESET:
      return {
        ...state,
        errors: [],
      };
    case Constants.APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        applicationDetails: getParsedApplicationDetails(get(action, 'payload.d')),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_GRANULAR_CREATE_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          singleApplication: action.payload,
          isAddApplicationSuccess: true,
      };
      case Constants.APPLICATION_GRANULAR_UPDATE_REQUEST:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          isUpdateApplicationError: false,
          isUpdateApplicationSuccess: false,
      };
      case Constants.APPLICATION_GRANULAR_UPDATE_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          isUpdateApplicationSuccess: true,
          isUpdateApplicationError: false,
      };
      case Constants.APPLICATION_GRANULAR_UPDATE_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
          isUpdateApplicationError: true,
          isUpdateApplicationSuccess: false,
      };
      case Constants.APPLICATION_PUBLISH_REQUEST:
        return {
          ...state,
          isLoading: action.isLoading,
          publishResult: {
            result:'CALLING',
          },
        };
      case Constants.APPLICATION_PUBLISH_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          publishResult: {
            result: 'SUCCESS',
          },
        };
      case Constants.APPLICATION_PUBLISH_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          publishResult : {
            result:'FAIL',
            errors: action.payload,
          },
        };
      case Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_REQUEST:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          isUpdateApplicationCustomFieldsSuccess: false,
          isUpdateApplicationCustomFieldsError: false,
      };
    case Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          isUpdateApplicationCustomFieldsSuccess: true,
      };
    case Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          isUpdateApplicationCustomFieldsError: true,
      };
    case Constants.APPLICATION_CREATE_SUCCESS:
    case Constants.APPLICATION_UPDATE_SUCCESS: {
      const applicationDetails = getParsedApplicationDetails(get(action, 'payload.d'));
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        ...(applicationDetails && { applicationDetails }),
        isApplicationSaveSuccess: true,
      };
    }
    case Constants.APPLICATION_UPDATE_AND_GET_SUCCESS: {
      const applicationDetails = getParsedApplicationDetails(get(action, 'payload.d'));
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        ...(applicationDetails && { applicationDetails }),
        isApplicationSaveSuccess: true,
      };
    }
    case Constants.APPLICATION_SECRET_UPDATE_SUCCESS: {
      return {
        ...state,
        isApplicationSecretSaveSuccess: true,
      };
    }
    case Constants.APPLICATION_ORGANIZATIONS_GET_SUCCESS: {
      const organizations1 = state.organizations1;
      const payload = action.payload;
      if (has(payload, 'results') && has(organizations1, 'results')) {
        payload.results = [...organizations1.results, ...action.payload.results];
      }
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        organizations1: payload,
        organizations: get(action, 'payload.results'),
        organizationsCount: get(action, 'payload.totalElements'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.APPLICATION_NAME_UNIQUE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        applicationNameUnique: undefined,
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_NAME_UNIQUE_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        applicationNameUnique: action.payload,
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_CUSTOM_FIELDS_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        customFields: getParsedCustomFields(get(action, 'payload.d.results')),
      };
    case Constants.APPLICATION_ASSIGNED_APIS_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        appAssignedAPIs: action.payload,
      };
    case Constants.APPLICATION_ASSIGNED_APIS_GET_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: action.payload,
        appAssignedAPIsGetFailure: true,
      };
    case Constants.APPLICATION_ASSIGNED_APIS_UPDATE_REQUEST:
      return {
        ...state,
        appAssignedAPIsUpdateResult: 'UPDATING',
      };
    case Constants.APPLICATION_ASSIGNED_APIS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        appAssignedAPIsUpdateResult: 'SUCCESS',
      };
    case Constants.APPLICATION_ASSIGNED_APIS_UPDATE_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: action.payload,
        appAssignedAPIsUpdateResult: 'FAILURE',
      };
      case Constants.APPLICATION_ASSIGNED_API_PLANS_GET_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          appAssignedApiPlans: action.payload,
        };
      case Constants.APPLICATION_ASSIGNED_API_PLANS_GET_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
          appAssignedApiPlansGetFailure: true,
        };
      case Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_REQUEST:
        return {
          ...state,
          appAssignedApiPlansUpdateResult: 'UPDATING',
        };
      
      case Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          appAssignedApiPlansUpdateResult: 'SUCCESS',
        };
      case Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
          appAssignedApiPlansUpdateResult: 'FAILURE',
        };
      case Constants.APPLICATION_GET_REQUEST_STATUS_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          appRequestStatus: action.payload,
        };
      case Constants.APPLICATION_GET_REQUEST_STATUS_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
        };
      case Constants.APPLICATION_GET_REQUEST_STATUS_REQUEST:
        return {
          ...state,
          isLoading: action.isLoading,
        };
      case Constants.APPLICATION_ASSIGNED_API_GROUPS_GET_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          appAssignedAPIGroups: action.payload,
        };
      case Constants.APPLICATION_ASSIGNED_API_GROUPS_GET_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
          appAssignedAPIGroupsGetFailure: true,
        };
      case Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_REQUEST:
        return {
          ...state,
          appAssignedAPIGroupsUpdateResult: '',
        };
      case Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_SUCCESS:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: [],
          appAssignedAPIGroupsUpdateResult: 'SUCCESS',
        };
      case Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_ERROR:
        return {
          ...state,
          isLoading: action.isLoading,
          errors: action.payload,
          appAssignedAPIGroupsUpdateResult: 'FAILURE',
        };
  
    case Constants.APPLICATION_SELECTED_APIS_GET_SUCCESS:
      return {
        ...state,
        errors: [],
        selectedApis: get(action, 'payload.d.results')
          .map(({ ApiUuid, Name, Description, PortalStatus }) => ({
            uuid: ApiUuid,
            name: Name,
            description: Description,
            portalStatus: PortalStatus,
          })),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_AVAILABLE_APIS_GET_SUCCESS: {
      let availableApis = get(action, 'payload.results');
      /* if page number is valid and > 0, add the apis from
      response to already pulled apis. else clear it.
      */
      if (Number.isInteger(action.page) && (action.page > 0)) {
        const cachedAPIs = state.availableApis;
        availableApis = [...cachedAPIs, ...availableApis];
      }
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        availableApis,
        availableApisCount: get(action, 'payload.totalElements'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.APPLICATION_AVAILABLE_APPS_GET_SUCCESS: {
      let availableApps = get(action, 'payload.results');
      /* if page number is valid and > 0, add the apps from
      response to already pulled apps. else clear it.
      */
      if (Number.isInteger(action.page) && (action.page > 0)) {
        const cachedAPPs = state.availableApps;
        availableApps = [...cachedAPPs, ...availableApps];
      }
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        availableApps,
        availableAppsCount: get(action, 'payload.totalElements'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.APPLICATION_AVAILABLE_ORGS_GET_SUCCESS: {
      let availableOrgs = get(action, 'payload.results');
      /* if page number is valid and > 0, add the apps from
      response to already pulled apps. else clear it.
      */
      if (Number.isInteger(action.page) && (action.page > 0)) {
        const cachedORGs = state.availableOrgs;
        availableOrgs = [...cachedORGs, ...availableOrgs];
      }
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        availableOrgs,
        availableOrgsCount: get(action, 'payload.totalElements'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.APPLICATION_API_EULA_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        apiEula: get(action, 'payload'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_GENERATE_SECRET_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        secret: get(action, 'payload.d.result'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_CUSTOM_FIELD_VALUES_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        appCustomFieldValues: get(action, 'payload'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_SELECTED_API_GROUPS_GET_SUCCESS:
      return {
        ...state,
        errors: [],
        selectedApiGroups: uniqBy(
          get(action, 'payload.d.results'),
          ({ uuid }) => (uuid),
        ),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_AVAILABLE_API_GROUPS_GET_SUCCESS: {
      const availableApiGroups1 = state.availableApiGroups1;
      const payload = action.payload;
      if (has(action, 'payload.results') && has(availableApiGroups1, 'results') && action.page !== 0) {
        payload.results = [...availableApiGroups1.results, ...action.payload.results];
      }
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        availableApiGroups1: payload,
        availableApiGroups: get(action, 'payload.results'),
        availableApiGroupsCount: get(action, 'payload.totalElements'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.APPLICATION_API_GROUP_EULAS_GET_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        apiGroupEulas: get(action, 'payload.d.results'),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    case Constants.APPLICATION_AVAILABLE_API_PLANS_GET_SUCCESS: {
      const { availableApiPlans } = state;
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        availableApiPlans: {
          ...availableApiPlans,
          ...action.payload,
        },
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.TENANT_INFO_GET_SUCCESS: {
      return {
        ...state,
        isLoading: action.isLoading,
        errors: [],
        tenantInfos: getHashingMetadata(get(action, 'payload.d')),
        isApplicationSaveSuccess: false,
        isApplicationSecretSaveSuccess: false,
      };
    }
    case Constants.PROXIES_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        proxies: [],
      };
    }
    case Constants.PROXIES_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        proxies: action.payload,
      };
    }
    case Constants.API_KEY_PROXIES_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        apiKeyProxies: updateApiKeyProxies(get(state, 'apiKeyProxies'), action.payload),
      };
    }
    case Constants.APPLICATION_ALL_KEYS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        keys: action.payload,
        deleteKeyStatus: '',
      };
    }
    case Constants.APPLICATION_ALL_KEYS_GET_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    }
    case Constants.APPLICATION_SINGLE_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        singleApplication: action.payload,
      };
    }
    case Constants.APPLICATION_SINGLE_GET_ERROR: {
      return {
        ...state,
        isLoading: false,
        getAppErrors: action.payload,
        
      };
    }
    case Constants.APPLICATION_API_PLANS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        allApiPlans: action.payload,
      };
    }
    case Constants.APPLICATION_STATUS_UPDATE_RESET: {
      return {
        ...state,
        updateApplicationStatus: '',
      };
    }
    case Constants.APPLICATION_STATUS_UPDATE_SUCCESS: {
      const applicationDetails = getParsedApplicationDetails(get(action, 'payload.d'));
      return {
        ...state,
        isLoading: false,
        errors: [],
        ...(applicationDetails && { applicationDetails }),
        updateApplicationStatus: Constants.SUCCESS,
      };
    }
    case Constants.APPLICATION_STATUS_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        updateApplicationStatus: Constants.FAIL,
      };
    }
    case Constants.APPLICATION_DELETE_RESET: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        deleteApplicationStatus: Constants.NONE,
      };
    }
    case Constants.APPLICATION_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        deleteApplicationStatus: Constants.SUCCESS,
      };
    }
    case Constants.APPLICATION_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        deleteApplicationStatus: Constants.FAIL,
      };
    }
    case Constants.API_KEY_DEPLOY_SUCCESS:
    case Constants.API_KEY_REDEPLOY_SUCCESS:
    case Constants.API_KEY_UNDEPLOY_SUCCESS: {
      const obj = {
        apiKey: get(action.payload, 'apiKey'),
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: '',
        validation: Constants.SUCCESS,
      };
      return {
        ...state,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }
    case Constants.API_KEY_DEPLOY_ERROR:
    case Constants.API_KEY_REDEPLOY_ERROR:
    case Constants.API_KEY_UNDEPLOY_ERROR: {
      const obj = {
        apiKey: get(action.payload, 'apiKey'),
        proxyUuid: get(action.payload, 'proxyUuid'),
        errorMessage: get(action.payload, 'errorMessage'),
        validation: Constants.FAIL,
      };
      return {
        ...state,
        proxyErrors: updateProxyErrors(get(state, 'proxyErrors'), obj),
      };
    }
    case Constants.API_KEY_PROXIES_GET_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    }
    case Constants.KEY_NAME_UNIQUE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        isUniqueKey: action.payload,
      };
    }
    case Constants.KEY_NAME_UNIQUE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        isUniqueKey: '',
      };
    }
    case Constants.KEY_STATUS_RESET: {
      return {
        ...state,
        errors: [],
        deleteKeyStatus: '',
        createKeyStatus: '',
        updateKeyStatus: '',
      };
    }
    case Constants.KEY_CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        createKeyStatus: Constants.SUCCESS,
        createdKey: action.payload,
      };
    }
    case Constants.KEY_CREATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        createKeyStatus: Constants.FAIL,
        createdKey: null,
      };
    }
    case Constants.KEY_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        updateKeyStatus: Constants.SUCCESS,
        createdKey: null,
      };
    }
    case Constants.KEY_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        updateKeyStatus: Constants.FAIL,
        createdKey: null,
      };
    }
    case Constants.KEY_DELETE_STATUS_RESET: {
      return {
        ...state,
        errors: [],
        deleteKeyErrors: [],
      };
    }
    case Constants.KEY_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        deleteKeyErrors: [],
        createKeyStatus: '',
        updateKeyStatus: '',
        deleteKeyStatus: Constants.SUCCESS,
        createdKey: null,
      };
    }
    case Constants.KEY_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        deleteKeyErrors: action.payload,
        createKeyStatus: '',
        updateKeyStatus: '',
        deleteKeyStatus: Constants.FAIL,
        createdKey: null,
      };
    }
    case Constants.FETCH_API_PLAN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        singleApiPlan: action.payload,
      };
    }
    case Constants.FETCH_API_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        singleApiGroup: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reduceReducers(applicationReducer, apiProductsReducer);

export const getIsLoading = (state) => get(state, 'application.isLoading');
export const getErrors = (state) => get(state, 'application.errors');
export const getIsError = (state) => !isEmpty(getErrors(state));

export const getIsApplicationNameUnique = (state) => get(state, 'application.applicationNameUnique.d.result');
export const getIsAppNameUnique = (state) => get(state, 'application.applicationNameUnique.result');
export const getApplicationDetails = (state) => get(state, 'application.applicationDetails');
export const getIsSaveApplicationSuccess = state => get(state, 'application.isApplicationSaveSuccess');
export const getIsSaveApplicationSecretSuccess = state => get(state, 'application.isApplicationSecretSaveSuccess');

// TODO: Clean the multiple getters for Organizations
export const getOrganizations1 = (state) => get(state, 'application.organizations1');
export const getOrganizations = (state) => get(state, 'application.organizations');
export const getOrganizationsCount = (state) => get(state, 'application.organizationsCount');

export const getCustomFields = (state) => get(state, 'application.customFields');

export const getSelectedApis = (state) => get(state, 'application.selectedApis');
export const getAvailableApis = (state) => get(state, 'application.availableApis');
export const getAvailableApisCount = (state) => get(state, 'application.availableApisCount');

export const getAvailableApps = (state) => get(state, 'application.availableApps');
export const getAvailableAppsCount = (state) => get(state, 'application.availableAppsCount');

export const getAvailableOrgs = (state) => get(state, 'application.availableOrgs');
export const getAvailableOrgsCount = (state) => get(state, 'application.availableOrgsCount');

export const getApiEula = (state) => get(state, 'application.apiEula');

export const getSelectedApiGroups = (state) => get(state, 'application.selectedApiGroups');

// TODO: Clean the multiple getters for Organizations
export const getAvailableApiGroups1 = (state) => get(state, 'application.availableApiGroups1');
export const getAvailableApiGroups = (state) => get(state, 'application.availableApiGroups');
export const getAvailableApiGroupsCount = (state) => get(state, 'application.availableApiGroupsCount');
export const getIsListError = (state) => get(state, 'application.isListError');
export const getApplicationsListTotalPages = (state) => get(state, 'application.applications.totalPages');
export const getApplicationsListTotalElements = (state) => get(state, 'application.applications.totalElements');
export const getApplicationsListResults = (state) => get(state, 'application.applications.results');

export const getApiGroupEulas = (state) => get(state, 'application.apiGroupEulas');

export const getAvailableApiPlans = (state) => get(state, 'application.availableApiPlans');

export const getSecret = (state) => get(state, 'application.secret');
export const getTenantInfos = (state) => get(state, 'application.tenantInfos');

export const getProxyErrors = (state) => get(state, 'application.proxyErrors');
export const getProxies = (state) => get(state, 'application.proxies');
export const getProxiesByApiKey = (state) => get(state, 'application.apiKeyProxies');

export const getIsUniqueKey = (state) => get(state, 'application.isUniqueKey');
export const getCreateKeyStatus = (state) => get(state, 'application.createKeyStatus');
export const getUpdateKeyStatus = (state) => get(state, 'application.updateKeyStatus');
export const getDeleteKeyStatus = (state) => get(state, 'application.deleteKeyStatus');

export const getAllApis = (state) => get(state, 'application.allApis');
export const getAllApps = (state) => get(state, 'application.allApps');
export const getAllApiPlans = (state) => get(state, 'application.allApiPlans');
export const getSingleApplication = (state) => get(state, 'application.singleApplication');
export const getSingleApplicationErrors = (state) => get(state, 'application.getAppErrors');
export const getUpdateApplicationStatus = state => get(state, 'application.updateApplicationStatus');
export const getDeleteApplicationStatus = state => get(state, 'application.deleteApplicationStatus');
export const isAddApplicationSuccess = state => get(state, 'application.isAddApplicationSuccess');
export const isUpdateApplicationSuccess = state => get(state, 'application.isUpdateApplicationSuccess');
export const getIsUpdateApplicationCustomFieldsSuccess = state => get(state, 'application.isUpdateApplicationCustomFieldsSuccess');
export const getIsUpdateApplicationCustomFieldsError = state => get(state, 'application.isUpdateApplicationCustomFieldsError');
export const getKeysTotalPages = (state) => get(state, 'application.keys.totalPages');
export const getKeysTotalElements = (state) => get(state, 'application.keys.totalElements');
export const getKeysResults = (state) => get(state, 'application.keys.results');
export const getPublishResult = (state) => get(state, 'application.publishResult');
export const getAppRequestStatus = (state) => get(state, 'application.appRequestStatus');
export const getAppCustomFieldValues = (state) => get(state, 'application.appCustomFieldValues');
export const getCreatedKey = (state) => get(state, 'application.createdKey');
export const getAppAssignedAPIsUpdateResult = (state) => get(state, 'application.appAssignedAPIsUpdateResult');
export const getAppAssignedAPIs = (state) => get(state, 'application.appAssignedAPIs');
export const getIsappAssignedApisFailure = (state) => get(state, 'application.appAssignedAPIsGetFailure');
export const getAppAssignedApiPlansUpdateResult = (state) => get(state, 'application.appAssignedApiPlansUpdateResult');
export const getAppAssignedApiPlans = (state) => get(state, 'application.appAssignedApiPlans');
export const getIsappAssignedApiPlansFailure = (state) => get(state, 'application.appAssignedApiPlansGetFailure');
export const getAppAssignedAPIGroupsUpdateResult = (state) => get(state, 'application.appAssignedAPIGroupsUpdateResult');
export const getAppAssignedAPIGroups = (state) => get(state, 'application.appAssignedAPIGroups');
export const getSingleApiPlan = (state) => get(state, 'application.singleApiPlan');
export const getSingleApiGroup = (state) => get(state, 'application.singleApiGroup');

export const getDeleteKeyErrors = (state) => get(state, 'application.deleteKeyErrors');
