import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { get, map } from 'lodash';
import { useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AlertDialog, LoadingDialog,
 } from '../../../../components';
import {
  resetDeleteApplicationStatus,
  deleteApplication,
} from '../../../../actions/application';
import {
  getDeleteApplicationStatus,
  getErrors,
  getApplicationDetails,
} from '../../../../reducers/application';
import {
  FAIL,
  SUCCESS,
  ROLE_DEVELOPER,
  APPLICATION_STATUS_DELETE_FAILED,
} from '../../../../constants';
import {
  getUserRole,
  hasOrgBoundRole,
  hasPublisherRole,
  hasAdminRole,
} from '../../../../utils';
import { getI18n } from '../../../../utils/intl';
import styles from './styles';
import {
  getIsDeleteApplicationRequestWorkflowEnabled,
} from '../../../../reducers/portalConfig';
import {
  fetchApplicationDeleteRequestSetting,
} from '../../../../actions/portalConfig';

export const processErrors = (arrErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  if (arrErrors.length > 0) {
    errorMessage = map(arrErrors, (validationError) => validationError.error).join('\n');
  }
  return errorMessage ? errorMessage : defaultMessage;
};

export const ActionsRaw = (props) => {
  const {
    applicationDetails,
    isEditAppDisabled,
    isEditAppKeysDisabled,
    setNotificationStatus,
    setNotificationMessage,
    deleteAppStatus,
    isMultiKeySupport,
    errors,
    history,
    user,
    classes,
    isDeleteApplicationRequestWorkflowEnabled,
  } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => { setOpen(!open); };
  const handleClickAway = () => { setOpen(false); };
  const appUuid = get(applicationDetails, 'uuid');
  const intl = getI18n(useIntl());

  // canEdit
  const status = get(applicationDetails, 'status');
  const [showDeleteAppDialog, setShowDeleteAppDialog] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const openDeleteAppDialog = () => setShowDeleteAppDialog(true);
  const closeDeleteAppDialog = () => setShowDeleteAppDialog(false);
  const [showForceDeleteAppDialog, setShowForceDeleteAppDialog] = useState(false);
  const openForceDeleteAppDialog = () => setShowForceDeleteAppDialog(true);
  const closeForceDeleteAppDialog = () => setShowForceDeleteAppDialog(false);
  const [showProxyDeleteAppDialog, setShowProxyDeleteAppDialog] = useState(false);
  const openProxyDeleteAppDialog = () => setShowProxyDeleteAppDialog(true);
  const closeProxyDeleteAppDialog = () => setShowProxyDeleteAppDialog(false);
  const openLoadingDialog = () => setShowLoadingDialog(true);
  const closeLoadingDialog = () => setShowLoadingDialog(false);

  const deleteApp = async (isForceDelete, ignoreProxyCheck) => {
    await props.deleteApplication(applicationDetails, isForceDelete, ignoreProxyCheck);
    closeLoadingDialog();
  };

  const actions = [
    {
      key: '1',
      value: 'Edit Application',
      disabled: isEditAppDisabled || status === APPLICATION_STATUS_DELETE_FAILED,
      onClick: () => {
        props.resetDeleteApplicationStatus();
        if(history) history.push(`/publish/applications/edit/${appUuid}`);
      },
      visible: true,
    },
    {
      key: '2',
      value: 'Edit API Assignment',
      disabled: isEditAppDisabled || status === APPLICATION_STATUS_DELETE_FAILED,
      onClick: () => {
        props.resetDeleteApplicationStatus();
        if(history) history.push(`/publish/applications/edit/${appUuid}/api-management`);
      },
      visible: true,
    },
    {
      key: '3',
      value: 'Edit Keys',
      disabled: isEditAppKeysDisabled || status === APPLICATION_STATUS_DELETE_FAILED,
      onClick: () => {
        props.resetDeleteApplicationStatus();
        if(history) history.push(`/publish/applications/edit/${appUuid}/keys`);
      },
      visible: (isMultiKeySupport && hasPublisherRole(user)),
    },
    {
      key: '4',
      value: 'Delete Application',
      disabled: isEditAppDisabled,
      onClick: () => {openDeleteAppDialog(), props.fetchApplicationDeleteRequestSetting()},
      visible: getUserRole(user) !== ROLE_DEVELOPER,
    },
    {
      key: '5',
      value: 'Force Delete Application',
      disabled: isEditAppDisabled,
      onClick: () => openForceDeleteAppDialog(),
      visible: (hasAdminRole(user) && status === APPLICATION_STATUS_DELETE_FAILED),
    },
  ];


  useEffect(() => {
      if (deleteAppStatus === SUCCESS) {
        let redirectUrl = '';
       if (hasOrgBoundRole(user)) {
          redirectUrl = isDeleteApplicationRequestWorkflowEnabled ?
          `/publish/applications/details/${appUuid}` : '/admin/console/applications';
          setNotificationStatus('success');
          setNotificationMessage(intl.getI18nMessage('label.application.request.submit.success'));
        } else {
          redirectUrl = '/admin/console/applications';
        }
        window.location.href = redirectUrl;
      } else if (deleteAppStatus === FAIL) {
        props.fetchApplication(appUuid);
        const defaultErrorMessage = 'There was an error deleting this application.';
        const errorMessage = processErrors(errors, defaultErrorMessage);
        if(errors.length > 0 && errors[0].field === 'proxyCheck') {
          openProxyDeleteAppDialog();
        } else {
          setNotificationStatus('error');
          setNotificationMessage(errorMessage);
        }
      }
  }, [deleteAppStatus]);

  return (
    <div
      className={classes.actionsContainer}
      id="app-details-actions-container" data-layer7-test="app-details-actions-container"
    >
      <AlertDialog
        isOpen={showDeleteAppDialog}
        title={intl.getI18nMessage('label.application.delete')}
        description={intl.getI18nMessage('label.application.delete.confirmation.text')}
        submitText={intl.getI18nMessage('label.delete.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        onClose={closeDeleteAppDialog}
        onSubmit={() => {
          closeDeleteAppDialog();
          openLoadingDialog();
          deleteApp();
        }}
        onCancel={closeDeleteAppDialog}
        dialogId="app-delete-dialog"
        submitButtonClass={classes.submitButton}
      />
      <AlertDialog
        isOpen={showForceDeleteAppDialog}
        title={intl.getI18nMessage('label.application.force.delete.title')}
        description={intl.getI18nMessage('label.application.force.delete.confirmation.text')}
        submitText={intl.getI18nMessage('label.force.delete.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        onClose={closeForceDeleteAppDialog}
        onSubmit={() => {
          closeForceDeleteAppDialog();
          openLoadingDialog();
          deleteApp(true);
        }}
        onCancel={closeForceDeleteAppDialog}
        dialogId="app-force-delete-dialog"
        submitButtonClass={classes.submitButton}
      />
      <AlertDialog
        isOpen={showProxyDeleteAppDialog}
        title={intl.getI18nMessage('label.application.proxy.delete.title')}
        description={intl.getI18nMessage('label.application.proxy.delete.confirmation.text')}
        submitText={intl.getI18nMessage('label.proceed.delete.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        onClose={closeProxyDeleteAppDialog}
        onSubmit={() => {
          closeProxyDeleteAppDialog();
          openLoadingDialog();
          deleteApp('', true);
        }}
        onCancel={closeProxyDeleteAppDialog}
        dialogId="app-force-delete-dialog"
        submitButtonClass={classes.submitButton}
      />
      <LoadingDialog
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage('label.application.deleting')}
        description={intl.getI18nMessage('label.application.deleting.status.text')}
        dialogId="deleting-app-dialog"
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="nav">
          <ListItem classes={{ root: classes.listButton }} button onClick={handleClick}>
            <ListItemText primary={'Actions'} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className={classes.collapseContainer} in={open} unmountOnExit>
            {actions.filter(({ visible }) => visible).map((obj) => (
              <List key={obj.key} component="div" disablePadding>
                <ListItem
                  button
                  disabled={obj.disabled}
                  onClick={obj.onClick}
                  className={classes.listItem}
                  data-layer7-test={obj.value}
                >
                  <ListItemText primary={obj.value} />
                </ListItem>
              </List>
            ))}
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deleteAppStatus: getDeleteApplicationStatus(state),
  errors: getErrors(state),
  applicationDetails: getApplicationDetails(state),
  isDeleteApplicationRequestWorkflowEnabled: getIsDeleteApplicationRequestWorkflowEnabled(state),
});

const mapDispatchToProps = {
  resetDeleteApplicationStatus,
  deleteApplication,
  fetchApplicationDeleteRequestSetting,
};

ActionsRaw.propTypes = {
  applicationDetails: PropTypes.object,
  isEditAppDisabled: PropTypes.bool,
  isEditAppKeysDisabled: PropTypes.bool,
  setNotificationMessage: PropTypes.func,
  setNotificationStatus: PropTypes.func,
  deleteAppStatus: PropTypes.string,
  isMultiKeySupport: PropTypes.bool,
  user: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  classes: PropTypes.object,
  deleteApplication: PropTypes.func,
  resetDeleteApplicationStatus: PropTypes.func,
  isDeleteApplicationRequestWorkflowEnabled: PropTypes.bool,
  fetchApplicationDeleteRequestSetting: PropTypes.func,
  fetchApplication: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ActionsRaw);
