import React, { useState } from 'react';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { bool, func, string } from 'prop-types';

import { withStyles } from '@material-ui/core';

import AddApis from './add.js';
import EditApis from './edit.js'
import styles from './styles';

export const Apis = (props) => {
  const {
    productUuid,
    isCurrentTab,
    notifyMessages,
  } = props;

  const [page, setPage] = useState('edit');

  const onPageChange = (value) => {
    setPage(value)
  }
  
  return (
    <>
      {page === 'edit' ?
        <EditApis
          productUuid={productUuid}
          isCurrentTab={isCurrentTab}
          onPageChange={onPageChange}
          notifyMessages={notifyMessages}
        />
      :
        <AddApis
          productUuid={productUuid}
          isCurrentTab={isCurrentTab}
          onPageChange={onPageChange}
        />
      }
    </>
  )
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

Apis.propTypes = {
  productUuid: string,
  isCurrentTab: bool,
  notifyMessages: func,
};

Apis.displayName = 'Apis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Apis);
