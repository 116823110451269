import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape } from 'prop-types';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import {
  Footer, TabPanel, TabsContainer,
} from '../../../components';
import { getTabIndex } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  fetch,
  fetchApis,
  fetchOrganizations,
  fetchApisOrganizationsForDetails,
  deleteRateQuota,
} from '../../../actions/rateQuota';
import {
  getDetails,
  getIsDeleteSuccess,
  getIsError,
  getErrors,
  getIsLoading,
  getDeleteErrors,
  getApisListResults,
  getOrgsListResults,
  getApisOrgsDetailsListResults,
} from '../../../reducers/rateQuota';
import {
  getConfig,
  getUserDetails,
} from '../../../reducers/portalConfig';
import {
  QUOTA_ASSIGNMENT_API,
  QUOTA_ASSIGNMENT_ORG,
  QUOTA_ASSIGNMENT_API_ORG,
  QUOTA_ASSIGNMENT_API_ORG_TEXT,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION_TEXT,
} from '../../../constants';
import { DetailsHeader } from '../../../components';
import DetailsContainer from '../../details';
import Overview from './Overview';
import Actions from './Actions';
import Apis from "./Apis";
import ApisOrganizations from "./ApisOrganizations";
import Organizations from "./Organizations";

export const getRqUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/details/')
  && get(props, 'match.params.rqUuid');

const getTabItems = (details) => [
  {
    id: 'overview',
    tabId: 'rate-quota-overview-tab',
    label: getI18nFormattedMessage('label.rate.quota.details.page.overview.title'),
    visible: true,
  },
  {
    id: 'apis',
    tabId: 'rate-quota-apis-tab',
    label: getI18nFormattedMessage('label.rate.quota.details.page.apis.title'),
    visible: get(details, 'assignmentLevel') === QUOTA_ASSIGNMENT_API,
  },
  {
    id: 'orgs',
    tabId: 'rate-quota-orgs-tab',
    label: getI18nFormattedMessage('label.rate.quota.details.page.orgs.title'),
    visible: get(details, 'assignmentLevel') === QUOTA_ASSIGNMENT_ORG,
  },
  {
    id: 'apiorgs',
    tabId: 'rate-quota-api-orgs-tab',
    label: getI18nFormattedMessage('label.apis.organizations'),
    visible: get(details, 'assignmentLevel') === QUOTA_ASSIGNMENT_API_ORG,
  },
];

const getTabs = (tabItems) => tabItems.filter(({ visible }) => visible);

export const RateQuotaDetails = (props) => {
  const {
    isLoading,
    userContext,
    rateQuotaDetails,
    pageErrors,
    isError,
  } = props;

  const pageHeaderRef = useRef(null);
  const rqUuid = get(props, 'match.params.rqUuid');
  const tabItems = getTabItems(rateQuotaDetails);
  const tabs = getTabs(tabItems);
  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const defaultIndex = getTabIndex(tabs, props.match);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[defaultIndex].tabId);

  useEffect(() => {
    if (!get(userContext, 'permissions.RATE_QUOTA', []).includes('READ')) {
      props.push('/404');
    }
  }, [userContext]);

  useEffect(() => {
    props.fetch(rqUuid);
    window.scrollTo(0, pageHeaderRef.offsetTop);
  }, []);

  useEffect(() => {
    if( get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_API) {
      props.fetchApis({ rateQuotaUuid: rqUuid });
    } else if (get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_ORG) {
      props.fetchOrganizations({ accountPlanUuid: rqUuid });
    } else if (get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_API_ORG) {
      props.fetchApisOrganizationsForDetails({ rqUuid, hasApiOrgRateQuota: true });
    }
  }, [rateQuotaDetails]);

  useEffect(() => {
    const defaultIndex = getTabIndex(tabs, props.match);
    setTabIndex(defaultIndex);
    setCurrentTab(tabs[defaultIndex].tabId);
  }, [props.match]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    setErrors(isError ? pageErrors : []);
  }, [isError, pageErrors]);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    setCurrentTab(tabs[newValue].tabId);
  };

  const hasActionButton = get(userContext, 'permissions.RATE_QUOTA',[])
    && (userContext.permissions.RATE_QUOTA.includes('DELETE') || userContext.permissions.RATE_QUOTA.includes('UPDATE'));
  const assignmentLevel = get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_API_ORG
    ? QUOTA_ASSIGNMENT_API_ORG_TEXT
    : get(rateQuotaDetails, 'assignmentLevel') === QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION
      ? QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION_TEXT
      : get(rateQuotaDetails, 'assignmentLevel')
  const subTitle = assignmentLevel ? `Assignment Level: ${assignmentLevel}`: '';
  const marker = get(rateQuotaDetails, 'defaultRateQuota') ?
    getI18nFormattedMessage('label.default') : '';

  return (
    <DetailsContainer
      detailsPageId="rate-quota-details-page"
      isLoading={isLoading}
      notificationId="rate-quota-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationMessage}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      errors={errors}
      pageHeaderRef={pageHeaderRef}
      header={
        <DetailsHeader
          headerId="rate-quota-details-header-container"
          title={intl.getI18nMessage('label.rate.quota.details.page.title')}
          subTitle={subTitle}
          name={get(rateQuotaDetails, 'name')}
          marker={marker}
        />
      }
      actions={ hasActionButton &&
        <Actions
          {...props}
          rqUuid={rqUuid}
          notifyMessages={notifyMessages}
        />
      }
      tabs={
        <TabsContainer
          id={'rate-quota-details-tab-container'}
          tabValue={tabIndex}
          orientation="horizontal"
          tabItems={tabs}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 'rate-quota-overview-tab'}
            id="rate-quota-overview-tab-panel"
          >
            <Overview
              {...props}
              isCurrentTab={currentTab === 'rate-quota-overview-tab'}
              rqUuid={rqUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'rate-quota-apis-tab'}
            id="rate-quota-apis-tab-panel"
          >
            <Apis
              {...props}
              isCurrentTab={currentTab === 'rate-quota-apis-tab'}
              rqUuid={rqUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'rate-quota-orgs-tab'}
            id="rate-quota-orgs-tab-panel"
          >
            <Organizations
              {...props}
              isCurrentTab={currentTab === 'rate-quota-orgs-tab'}
              rqUuid={rqUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'rate-quota-api-orgs-tab'}
            id="rate-quota-api-orgs-tab-panel"
          >
            <ApisOrganizations
              {...props}
              isCurrentTab={currentTab === 'rate-quota-api-orgs-tab'}
              rqUuid={rqUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
        </Fragment>
      }
      footer={
        <Footer />
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  isLoading: getIsLoading(state),
  rateQuotaDetails: getDetails(state),
  isRateQuotaDeleteSuccess: getIsDeleteSuccess(state),
  isError: getIsError(state),
  pageErrors: getErrors(state),
  userContext: getUserDetails(state),
  deleteRateQuotaErrors: getDeleteErrors(state),
  apisList: getApisListResults(state),
  orgsList: getOrgsListResults(state),
  apiOrgsList: getApisOrgsDetailsListResults(state),
});

const mapDispatchToProps = {
  push,
  fetch,
  fetchApis,
  fetchOrganizations,
  fetchApisOrganizationsForDetails,
  deleteRateQuota,
};

RateQuotaDetails.propTypes = {
  config: shape({}),
  userContext: shape({}),
  history: shape({}),
  rateQuotaDetails: shape({}),
  isLoading: bool,
  isError: bool,
  pageErrors: arrayOf(shape({})),
  match: shape({}),
  fetch: func,
  fetchApis: func,
  fetchOrganizations: func,
  fetchApisOrganizationsForDetails: func,
  deleteRateQuota: func,
  push: func,
  deleteRateQuotaErrors: arrayOf(shape({})),
  apisList: arrayOf(shape({})),
  orgsList: arrayOf(shape({})),
  apiOrgsList: arrayOf(shape({})),
};

RateQuotaDetails.displayName = 'RateQuotaDetails';

export default (
  connect(mapStateToProps, mapDispatchToProps)
)(RateQuotaDetails);
