import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, fontSize, typography, lineHeight, margin }) => ({
  keyHeading: {
    color: get(color, 'link'),
  },
  keyDetailsContainer: {
    display: 'block',
    '& :first-child': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  keysContainer: {
    marginTop: 10,
    '& table': {
      backgroundColor: get(color, 'uiBackground'),
    },
    '& tr': {
      borderTop: `1px solid ${get(color, 'borderColor')}`,
    },
    '& th': {
      color: get(color, 'primaryButtonBackground'),
      padding: 12,
      cursor: 'pointer',
      border: 'none',
    },
    '& td': {
      padding: 12,
      border: 'none',
    },
  },
  expandCollapseButton: {
    color: get(color, 'primaryButtonBackground'),
  },
  tableContainer: {
    boxShadow: 'none',
  },
  tableHead: {
    '& th': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
      cursor: 'auto',
    },
  },
  paginationContainer: {
    margin: '0 -10px',
    '& > div:first-child': {
      borderBottom: `1px solid ${get(color, 'borderColor')}`,
    },
  },
  pageTitle: {
    display: 'inline',
  },
  addButton: {
    float: 'right',
    lineHeight: get(lineHeight, 'lh20'),
  },
  deleteButton: {
    border: `1px solid ${get(color, 'borderColor')}`,
    color: get(color, 'red'),
    marginRight: `${get(margin, 'm5')}`,
    float: 'right',
    '&:hover': {
      color: get(color, 'red'),
    },
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
  },
  openRow: {
    '& th': {
      borderBottom: '0px',
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
    },
    '& td': {
      borderBottom: '0px',
    },
  },
  openRowTitle: {
    color: `${get(color, 'bodyText')} !important`,
    fontWeight: 700,
  },
  changeButton: {
    color: get(color, 'link'),
  },
  disabledRow: {
    background: `${get(color, 'gray')} !important`,
    opacity: '0.7',
    pointerEvents: 'none',
  },
  defaultChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'bodyText'), 0.05)} !important`,
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  enabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'success'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  disabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'error'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
});

export default styles;
