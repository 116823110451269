import React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField } from '../../../../components';

export const FilterByName = (props) => (
  <Grid item md={4} sm={4} xs={6}>
    <FormTextField
      {...props}
      id="api-apiproducts-filter-by-name"
      data-apim-test="api-apiproducts-filter-by-name"
    />
  </Grid>
);
