import axios from 'axios';
import * as Constants from '../../constants';
import { AXIOS_DEFAULT_OPTIONS } from '../';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';

export const fetchTiers = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
    `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/tiers`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) =>
        dispatch({
          type: Constants.TIERS_GET_SUCCESS,
          payload: data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.TIERS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
