/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';

import configureStore, { history } from './store';
import Routes from './routes';
import mapPortalThemeToMuiTheme from './theme';
import Main from './layouts/Main';
import GlobalHeader from './components/GlobalHeader';
import Footer from './components/Footer';
import CookiePolicyBanner from './components/CookiePolicyBanner'
import messagesEn from './labels/messages_en.json';
import getCustomFontFaces from './utils/customTypography';

export const getMessages = () => messagesEn;

const renderChild = (component, isLoggedIn) => {
  switch (component) {
    case 'root':
      return (
        <Main isLoggedIn={isLoggedIn}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Main>);
    case 'footer':
      return <Footer />;
    case 'header':
      return <GlobalHeader />;
    case 'cookieConsent':
      return <CookiePolicyBanner />;
    default:
      return (<span />);
  }
}

const renderFaviconAndTitle = ({ favicon, siteTitle }) => {
  if (favicon) {
    const favIconElement = document.getElementById('layer7-favicon');
    if (favIconElement) {
      favIconElement.href = favicon;
    }
  }
  if (siteTitle) {
    document.title = siteTitle;
  }
}

const customTypographystyles = (theme) => ({
  '@font-face': getCustomFontFaces(
    get(theme, 'typography.custom'),
  ),
});

const CustomTypographyWrapper = withStyles(customTypographystyles)(
  ({ children }) => (
    <>{children}</>
  ),
);

const App = (props) => {
  const { portalConfig, component, isLoggedIn } = props;
  const theme = mapPortalThemeToMuiTheme(portalConfig);
  const { dict } = portalConfig;
  const locale = 'en';
  const messages = dict ? { ...dict, ...getMessages(locale) } : getMessages(locale);
  const store = configureStore({ portalConfig });

  renderFaviconAndTitle(theme);
  
  return (
    <ThemeProvider theme={createMuiTheme(theme)}>
      <IntlProvider locale={locale} messages={messages} >
        <Provider store={store}>
          <CustomTypographyWrapper>
            {renderChild(component, isLoggedIn)}
          </CustomTypographyWrapper>
        </Provider>
      </IntlProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  portalConfig: PropTypes.object,
  component: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

export default (App);
