import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Chip, Grid, Button, FormLabel } from '@material-ui/core';
import { number, obj, func, bool } from 'prop-types';
import { map } from 'lodash';
import { FormTextField } from '../../../../../components';
import { AppAPIAccessListContext } from '..';
import { getI18n } from '../../../../../utils/intl';
export const FilterByName = (props) => (
  <FormTextField
    {...props}
    id="apis-org-filter-by-name"
    data-apim-test="apis-org-filter-by-name"
    type="text"
  />
);
export const Tags = ({ tags, numSelectedRows }) => {
  const appAPIAccessListContext = useContext(AppAPIAccessListContext);
  const clickable = (numSelectedRows === 0);
  return map(
    tags,
    tag => (
      <Chip
        variant="outlined" size="small"
        clickable={clickable}
        style={{ marginRight: '2px' }}
        key={tag} label={tag}
        onClick={() => {
          if(clickable) { appAPIAccessListContext.onClickTag(tag) }
        }}
      />
    ),
  );
}
export const HeaderActionButtons = (props) => {
  const {
    classes = {},
    bulkActionsItemsCount,
    onAssign,
    onUnassign,
    disableAdd,
    disableRemove,
  } = props;

  const id = 'app-api-assignment'
  const intl = getI18n(useIntl());

  return (
    <div className={classes.actionsFieldContainer}>
      <FormLabel className={ classes.actionsLabel }>
        {intl.getI18nMessage('label.application.details.api.actions.count', { count: bulkActionsItemsCount })}
      </FormLabel>
      <Grid
        item md={12} sm={12} xs={12}
        className={classes.headerButtons}
      >
        <Button
          variant="contained" color="secondary"
          className={classes.addButton}
          id={`${id}-add`} data-apim-test={`${id}-add`} data-layer7-test={`${id}-add`}
          onClick={onAssign}
          disabled={disableAdd}
        >
          {intl.getI18nMessage('label.application.details.apis.actions.assign')}
        </Button>
        <Button
          variant="contained" color="secondary"
          className={classes.removeButton}
          id={`${id}-remove`} data-apim-test={`${id}-remove`} data-layer7-test={`${id}-remove`}
          onClick={onUnassign}
          disabled={disableRemove}
        >
          {intl.getI18nMessage('label.application.details.apis.actions.unassign')}
        </Button>
      </Grid>
    </div>
  );
}
HeaderActionButtons.propTypes = {
  classes: obj,
  bulkActionsItemsCount: number,
  onAssign: func,
  onUnassign: func,
  disableAdd: bool,
  disableRemove: bool,
};
