import React from 'react';
import { Grid } from '@material-ui/core';

import { FormAutoCompleteCombo, FormSelect, FormTextField } from '../../../components';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="request-filter-by-name"
      data-apim-test="request-filter-by-name"
    />
  </Grid>
);

export const FilterByType = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="request-filter-by-type"
      data-apim-test="request-filter-by-type"
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByOrg = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormAutoCompleteCombo
      {...props}
      id="request-filter-by-org"
      data-apim-test="request-filter-by-org"
    />
  </Grid>
);
