import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { number, bool, shape, arrayOf, func, string, object } from 'prop-types';
import { connect } from 'react-redux';
import { Button, Link, withStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import moment from 'moment';
import clsx from 'clsx';
import { filter, get } from 'lodash';

import styles from './styles';
import ListContainer from '../../list';
import {
  AlertDialog,
  FormTextField,
} from '../../../components';
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
  FAIL,
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  SUCCESS,
  REQUEST_LIST_TYPES,
 } from '../../../constants';
import {
  fetchList,
  fetchOrgSuggestions,
  submitRequest,
} from '../../../actions/requests';
import {
  FilterByName,
  FilterByOrg,
  FilterByType,
} from './controls';
import {
  getIsLoading,
  getListTotalPages,
  getListTotalElements,
  getListResults,
  getOrgSuggestions,
  getRequestUpdateStatus,
  getErrors,
} from '../../../reducers/request';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import { hasDeveloperRole, hasAdminRole } from '../../../utils';
import { getUserDetails } from '../../../reducers/portalConfig';

const REQUEST_TYPE_APPLICATION = 'APPLICATION';
const REQUEST_SUBTYPE_CREATE = 'CREATE';
const REQUEST_SUBTYPE_UPDATE = 'UPDATE';
const REQUEST_TYPE_APPLICATION_DETAILS = 'APPLICATION_DETAILS';
const REQUEST_TYPE_APPLICATION_APIS = 'APPLICATION_APIS';
const REQUEST_TYPE_APPLICATION_API_GROUPS = 'APPLICATION_API_GROUPS';
const REQUEST_TYPE_APPLICATION_CUSTOM_FIELDS = 'APPLICATION_CUSTOM_FIELDS';
const REQUEST_TYPE_APPLICATION_API_KEYS = 'APPLICATION_API_KEYS';
const REQUEST_TYPE_APPLICATION_API_PLANS = 'APPLICATION_API_PLANS';
const REQUEST_TYPE_REGISTRATION = 'REGISTRATION';

const ListRaw = (props) => {
  const {
    classes,
    fetchList,
    fetchOrgSuggestions,
    isLoading = false,
    orgSuggestions,
    requestUpdateStatus,
    results = [],
    submitRequest,
    totalElements = 0,
    totalPages,
    user,
    errors,
  } = props;

  const { getI18nMessage } = getI18n(useIntl());
  const [alertReason, setAlertReason] = useState('');
  const [requestId, setRequestId] = useState('');
  const [isAcceptAction, setIsAcceptAction] = useState(true);
  const [disableActionSubmit, setDisableActionSubmit] = useState(false);
  const handleAlertReasonChange = (value) => {
    setAlertReason(value);
    if (!isAcceptAction) {
      if (value) {
        setDisableActionSubmit(false);
      } else {
        setDisableActionSubmit(true);
      }
    }
  };

  const getAlertDialogInnerComponent = () => {
    let lerftHeaderText = '';
    let placeHolderText = '';
    const maxLengthWarningMsg = getI18nMessage('label.request.list.actions.alert.max.length.warning');
    let rightHeaderText = '';
    if (isAcceptAction) {
      lerftHeaderText = getI18nMessage('label.request.list.actions.alert.accept.sub.title');
      placeHolderText = getI18nMessage('label.request.list.actions.alert.accept.placeholder');
      rightHeaderText = getI18nMessage('label.request.list.actions.alert.accept.optional.sub.title');
    } else {
      lerftHeaderText = getI18nMessage('label.request.list.actions.alert.reject.sub.title');
      placeHolderText = getI18nMessage('label.request.list.actions.alert.reject.placeholder');
    }
    return (
      <div>
        <div className={classes.alertMessageHeaderContainerClass}>
          <div className={classes.alertMessageLeftHeaderClass}>
            {lerftHeaderText}
          </div>
          <div className={classes.alertMessageRightHeaderClass}>
            {rightHeaderText}
          </div>
        </div>
        <FormTextField
          fieldContainerClass={classes.alertMessageContainerClass}
          maxLength={1000}
          multiline
          handleChange={handleAlertReasonChange}
          placeholder={placeHolderText}
          rows={4}
          textFieldClass={classes.alertMessageClass}
          value={alertReason}
        />
        <div className={classes.alertMessageMaxLengthWarningClass}>
          {maxLengthWarningMsg}
        </div>
      </div>
    );
  };

  const [notificationStatus, setNotificationStatus] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByType, setFilterByType] = useState('ALL');
  const [filterByOrg, setFilterByOrg] = useState('');
  const [orgSearchText, setOrgSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [showActionAlert, setShowActionAlert] = useState(false);
  const [actionAlertTitle, setActionAlertTitle] = useState('');
  const [alertSubmitText, setAlertSubmitText] = useState('Accept');
  const [isReviewSubmitted, setReviewSubmitted] = useState(false);
  const actionAlertDesc = getI18nMessage('label.request.list.actions.alert.description');
  const emptyFunction = () => {};

  const getLinkContent = (value = '', uuid) => (
    <Link underline="none" href={`/admin/console/requests/details/${uuid}`}>
      {value}
    </Link>
  );

  const getListColumns = ({ getI18nMessage, classes }) => [{
    id: 'entityName',
    label: getI18nMessage('label.request.list.entity.name'),
    minWidth: 200,
    value: ({ entityUuid, entityName }) => {
      return { entityUuid, entityName };
    },
    format: ({ entityUuid, entityName }) => {
      const linkEl = getLinkContent(entityName, entityUuid)
      return linkEl;
    },
  },{
    id: 'organizationName',
    label: getI18nMessage('label.request.list.organization.name'),
    minWidth: 200,
    format: (value) => value,
  }, {
    id: 'requestType',
    label: getI18nMessage('label.request.list.request.type.name'),
    minWidth: 200,
    value: ({ requestType, requestSubtype }) => {
      if (requestType === REQUEST_TYPE_APPLICATION) {
        if(requestSubtype === REQUEST_SUBTYPE_CREATE) {
          return getI18nMessage('label.request.list.request.type.application');
        } else if (requestSubtype === REQUEST_SUBTYPE_UPDATE) {
          return getI18nMessage('label.request.list.request.type.application.update');
        }
        return getI18nMessage('label.request.list.request.type.application.delete');
      } else if (requestType === REQUEST_TYPE_APPLICATION_DETAILS) {
        return getI18nMessage('label.request.list.request.type.application.details');
      } else if (requestType === REQUEST_TYPE_APPLICATION_APIS) {
        return getI18nMessage('label.request.list.request.type.application.apis');
      } else if (requestType === REQUEST_TYPE_APPLICATION_API_GROUPS) {
        return getI18nMessage('label.request.list.request.type.application.api.groups');
      } else if (requestType === REQUEST_TYPE_APPLICATION_CUSTOM_FIELDS) {
        return getI18nMessage('label.request.list.request.type.application.custom.fields');
      } else if (requestType === REQUEST_TYPE_APPLICATION_API_KEYS) {
        return getI18nMessage('label.request.list.request.type.application.api.keys');
      } else if (requestType === REQUEST_TYPE_APPLICATION_API_PLANS) {
        return getI18nMessage('label.request.list.request.type.application.api.plans');
      } else if (requestType === REQUEST_TYPE_REGISTRATION) {
        return getI18nMessage('label.request.list.request.type.user.registration');
      }
      return '';
    },
  }, {
    id: 'modifyTs',
    label: getI18nFormattedMessage('label.request.list.request.date.name'),
    minWidth: 200,
    format: (value) => moment(value).format('MMMM DD, YYYY'),
  }, {
    id: 'actionable',
    label: getI18nFormattedMessage('label.request.list.actions.name'),
    minWidth: 200,
    format: (value, secondaryValue, uuid) =>
      (<Fragment>
          <Button
            className={clsx(classes.actionBtn, classes.acceptBtn)}
            onClick={() => handleAccept(uuid)}
            variant="text"
          >
            {getI18nMessage('label.request.list.actions.accept')}
          </Button>
          <Button
            className={clsx(classes.actionBtn, classes.rejectBtn)}
            onClick={() => handleReject(uuid)}
            variant="text"
          >
            {getI18nMessage('label.request.list.actions.reject')}
          </Button>
        </Fragment>),
  }];

  const handleAccept = (uuid) => {
    setActionAlertTitle(getI18nMessage('label.request.list.actions.alert.accept.title'));
    setAlertSubmitText(getI18nMessage('label.request.list.actions.accept'));
    setRequestId(uuid);
    setIsAcceptAction(true);
    setDisableActionSubmit(false);
    setAlertReason('');
    setShowActionAlert(true);
  };

  const handleReject = (uuid) => {
    setActionAlertTitle(getI18nMessage('label.request.list.actions.alert.reject.title'));
    setAlertSubmitText(getI18nMessage('label.request.list.actions.reject'));
    setRequestId(uuid);
    setIsAcceptAction(false);
    setDisableActionSubmit(true);
    setAlertReason('');
    setShowActionAlert(true);
  };

  const handleClose = () => {
    setShowActionAlert(false);
  };

  const handleSubmit = () => {
    setReviewSubmitted(true);
    submitRequest({ isAccept: isAcceptAction, requestId, reason: alertReason })
  }

  useEffect(() => {
    if(hasDeveloperRole(user)) {
      props.push('/404');
    }
    setNotificationStatus(localStorage.getItem('notificationStatusRequestSaveSuccess'));
    setNotificationMessage(localStorage.getItem('notificationMessageRequestSaveSuccess'))
    localStorage.setItem('notificationStatusRequestSaveSuccess', '');
    localStorage.setItem('notificationMessageRequestSaveSuccess', '');
    fetchListWithFilters({ pageNum: page, rowsPerPageArg: rowsPerPage });
    setTimeout(() => {
      setNotificationMessage('');
      setNotificationStatus('');
    }, 3000);
  }, []);

  useEffect(() => {
    if (requestUpdateStatus) {
      setShowActionAlert(false);
      setAlertReason('');
      if (requestUpdateStatus === SUCCESS && isReviewSubmitted) {
        setNotificationStatus(ALERT_SUCCESS);
        if (isAcceptAction) {
          setNotificationMessage(getI18nMessage('label.request.list.actions.accept.success'));
        } else {
          setNotificationMessage(getI18nMessage('label.request.list.actions.reject.success'));
        }
      } else if (requestUpdateStatus === FAIL) {
        setNotificationStatus(ALERT_ERROR);
        if (isAcceptAction) {
          if(errors && errors.length > 0 && errors[0].field === 'proxyCheck') {
            setNotificationMessage(errors[0].error);
          } else {
            setNotificationMessage(getI18nMessage('label.request.list.actions.accept.fail'));
          }
        } else {
          setNotificationMessage(getI18nMessage('label.request.list.actions.reject.fail'));
        }
      }
      setPage(0);
      fetchListWithFilters({ pageNum: 0 });
    }
  }, [requestUpdateStatus]);

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchListWithFilters({ pageNum: newPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchListWithFilters({ pageNum: 0, rowsPerPageArg : newRowsPerPage });
  };

  const fetchListWithFilters = ({
    name = filterByName,
    org = filterByOrg,
    type = filterByType,
    pageNum = page,
    rowsPerPageArg = rowsPerPage,
  } = { }) => {
    fetchList({
      filterByName : name,
      filterByOrg: org,
      filterByType : type,
      page: pageNum,
      size: rowsPerPageArg,
    });
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      fetchListWithFilters();
    }
  };

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByTypeChange = (type) => {
    setFilterByType(type);
    setPage(0);
    fetchListWithFilters({ type });
  };

  const onFilterByOrgChange = (event = { }) => {
    const value = get(event, 'target.value', '');
    if (value) {
      fetchOrgSuggestions({ name: value });
    }
    // clear on selection by click
    if (get(event, "type", "") === "click") {
      setOrgSearchText('');
    } else {
      setOrgSearchText(value);
    }
  };

  const onOrgSelection = (event) => {
    const orgName = get(event, 'target.innerText', '');
    setFilterByOrg(orgName);
    fetchListWithFilters({ org: orgName });
  };

  const pageHeaderDescription = getI18nFormattedMessage('label.request.list.page.description', {
      a: msg => (
      <Link href="/admin/console/request-settings">
        {msg}
      </Link>
      ),
    });

  const rows = results.map(
      item => ({ ...item, classes: { root: classes.rowClass } }),
    );

  // Don't render option to filter requests by the type registration for non-admin users
  let requestTypeOptions = REQUEST_LIST_TYPES;
  if (!hasAdminRole(user)) {
    requestTypeOptions = filter(requestTypeOptions, item => item.uuid !== 'REGISTRATION');
  }

  return (
    <Fragment>
      <AlertDialog
        id="request-list-accept-reject-dialog"
        data-apim-test="request-list-accept-reject-dialog"
        isOpen={showActionAlert}
        title={actionAlertTitle}
        description={actionAlertDesc}
        disableSubmit={disableActionSubmit}
        submitText={alertSubmitText}
        cancelText={getI18nMessage('label.cancel.button')}
        component={showActionAlert && getAlertDialogInnerComponent()}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        submitButtonClass={isAcceptAction ? '' : classes.rejectSubmitButton}
      />
      <ListContainer
        listPageId="request-list"
        isLoading={isLoading}
        classes={classes}
        notificationId="request-notifications"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        pageHeaderTitle={getI18nMessage('label.request.list.page.title')}
        pageHeaderDescription={pageHeaderDescription}
        pageHeaderTooltipTitle={getI18nMessage('label.request.list.page.title.help')}
        addButtonLabel={''}
        onAdd={false}
        showListHeader={true}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.nameFieldContainer}
              handleChange={onFilterByNameChange}
              name={getI18nMessage('label.filter.by')}
              onKeyPress={onFilterByNameKeyPress}
              placeholder={getI18nMessage('label.request.name')}
              value={filterByName}
            />
            <FilterByType
              data={requestTypeOptions}
              fieldContainerClass={classes.fieldContainer}
              handleChange={onFilterByTypeChange}
              hideLabel
              name={getI18nMessage('label.filter.by')}
              value={filterByType}
            />
            <FilterByOrg
              fieldContainerClass={classes.fieldContainer}
              filterSelectedOptions={false}
              getOptionLabel={item => item}
              getOptionSelected={item => item}
              hideLabel
              name={getI18nMessage('label.filter.by')}
              onInputChange={onFilterByOrgChange}
              onChange={onOrgSelection}
              options={orgSearchText ? orgSuggestions : []}
              inputLabel={getI18nMessage('label.request.org')}
              renderOption={item => item}
              searchText={orgSearchText}
              value={filterByOrg}
            />
          </Fragment>
      )}
        columns={getListColumns({ getI18nMessage, classes })}
        rows={rows}
        noResultsMessage={getI18nMessage('label.request.list.no.results')}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        notifyMessages={emptyFunction}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
      />
    </Fragment>
  );
};

ListRaw.propTypes = {
  classes: shape({}),
  fetchList: func,
  fetchOrgSuggestions: func,
  isLoading: bool,
  orgSuggestions: arrayOf(string),
  requestUpdateStatus: bool,
  results: arrayOf(shape({})),
  submitRequest: func,
  totalElements: number,
  totalPages: number,
  user: object,
  push: func,
  errors: arrayOf(object),
};

const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
  orgSuggestions: getOrgSuggestions(state),
  requestUpdateStatus: getRequestUpdateStatus(state),
  results: getListResults(state),
  totalElements: getListTotalElements(state),
  totalPages: getListTotalPages(state),
  user: getUserDetails(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  fetchList,
  fetchOrgSuggestions,
  submitRequest,
  push,
};

ListRaw.displayName = 'Requests';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListRaw);
