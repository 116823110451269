import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useIntl } from 'react-intl';
import { withStyles, Link, Button } from '@material-ui/core';
import { get, lowerCase } from 'lodash';
import { object, func, arrayOf, bool, string } from 'prop-types';

import {
  hasAdminRole,
  hasPublisherRole,
  hasOrgPublisherRole,
  processErrorsMessage,
} from '../../../../utils';
import {
  fetchPolicyTemplates,
  fetchRateLimitQuotas,
  fetchApiRateLimitQuota,
  fetchApiOrgAccess,
  addApiRateLimitQuota,
  removeApiRateLimitQuota,
  addApiVisibility,
  removeApiVisibility,
} from '../../../../actions/api';
import {
  getIsLoading,
  getRateLimitQuotas,
  getRateLimitQuotasPage,
  getOrgsAccessListResults,
  getOrgsAccessListTotalElements,
  getOrgsAccessListTotalPages,
  getIsOrgAccessSaveSuccess,
  getErrors,
} from '../../../../reducers/api';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import ListContainer from '../../../list';
import {
  AlertDialog,
  ErrorContainer,
  FormAutoCompleteCombo,
  Switch,
  ToolTip as ToolTipComp,
} from '../../../../components';
import {
  API_ACCESS_STATUS_PRIVATE,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ACCESS_TYPE_GRANTED,
  ACCESS_TYPE_MANAGED,
  ACCESS_TYPE_NONE,
} from '../../../../constants';
import {
  HeaderActionButtons,
  FilterByName,
  AccessFilterButtons,
  FilterAndSortSeparator,
  TooltipIcon,
} from './controls';
import styles from './styles';

const getLinkContent = (value, uuid) => (
  <Link underline="none" href={`/admin/console/organizations/details/${uuid}`}>
    {value}
  </Link>
);

const getTooltipContent = (classes) => (
    <span className={classes.tooltipContainer}>
      {getI18nFormattedMessage('label.manager')}
    </span>
);

const getRateQuotaContent = (item, rateLimitQuotas,
  classes, onAddRateLimitLink) => {
  const defaultRateQuota = rateLimitQuotas.length > 0
    && rateLimitQuotas[0].uuid === item.rateQuotaUuid;

  return(
    <div>
      <>
        {item.rateQuotaName ?
        <Button
          className={classes.defaultButton}
          data-apim-test="alert-dialog-rateQuota"
          id='alert-action-rateQuota' data-layer7-test='alert-action-rateQuota'
          onClick={() => onAddRateLimitLink(item.uuid)}
          variant="text"
          color="secondary"
        >
          {item.rateQuotaName}
        </Button>
        :
          'N/A'
        }
        {defaultRateQuota &&
          <span className={classes.tooltipContainer}>
            {getI18nFormattedMessage('label.default')}
          </span>
        }
      </>
    </div>
  );
}

const formatVisibility = (item, onAccessChange, classes) => {
  const accessStatus = item.apiOrgAccessStatus;
  if(accessStatus === 'PUBLIC' || accessStatus === 'MANAGED') {
    return (<TooltipIcon classes={classes}
      tooltipLabel={getI18nFormattedMessage(`label.organization.apiaccess.${accessStatus.toLowerCase()}.tick.tooltip`)}
      content={formatSwitch(item.uuid, accessStatus, onAccessChange, classes)}
      />
    );
  } else {
    return formatSwitch(item.uuid, accessStatus, onAccessChange, classes);
  }
}

const formatSwitch = (uuid, accessStatus, onAccessChange, classes) => (
  <div className={classes.switchAccess}>
    <Switch
      id={`${uuid}-access`}
      checked={accessStatus === 'GRANTED_DIRECT' || accessStatus === 'PUBLIC' || accessStatus === 'MANAGED' }
      onChange={(e) => { onAccessChange(uuid, e) }}
      disabled={accessStatus === 'PUBLIC' || accessStatus === 'MANAGED'}
    />
  </div>
);

export const getOrgListColumns = ({ userContext, apiDetails, classes },
  onAccessChange, rateLimitQuotas, onAddRateLimitLink) => [{
    id: 'name',
    secondaryId: 'apiOrgAccessStatus',
    label: getI18nFormattedMessage('label.organization'),
    minWidth: 100,
    format: (value, secondaryValue, uuid) => {
      const linkEl = (hasAdminRole(userContext) || hasPublisherRole(userContext))
      ? getLinkContent(value, uuid) : value;
      if (secondaryValue === ACCESS_TYPE_MANAGED) {
        const content = getTooltipContent(classes);
        return <div>{linkEl}  {content}</div>;
      }
      return linkEl;
    },
  }, {
    id: 'type',
    label: getI18nFormattedMessage('label.type'),
    minWidth: 100,
    isHidden : true,
  }, {
    id: 'apiOrgAccessStatus',
    secondaryId: 'uuid',
    label: (
      <ToolTipComp
        classes={classes}
        label={getI18nFormattedMessage('label.access')}
        info={<div>{getI18nFormattedMessage('label.organization.apiaccess.visibility.head.tooltip')}</div>}
      />
    ),
    minWidth: 50,
    isHidden : apiDetails &&
      apiDetails.accessStatus === API_ACCESS_STATUS_PRIVATE ? false : true,
    value: (item) => formatVisibility(item, onAccessChange, classes),
  }, {
    id: 'rateQuotaName',
    secondaryId: 'rateQuotaUuid',
    label: getI18nFormattedMessage('label.api.per.org.limit'),
    minWidth: 100,
    isHidden: apiDetails && !apiDetails.publishedByPortal,
    value: (item) => {
      return getRateQuotaContent(item, rateLimitQuotas,
        classes, onAddRateLimitLink);
    },
  }];

export const OrganizationAccess = (props) => {
  const {
    classes,
    apiDetails,
    userContext,
    isLoading,
    apiUuid,
    tabValue,
    orgsAccessList,
    orgsAccessListPage,
    orgsAccessListTotalElements,
    isOrgAccessSaveSuccess,
    rateLimitQuotas,
    errors,
    canEdit,
   } = props;
  
  const intl = getI18n(useIntl());

  const [notification, setNotification] = useState({ status: '', message: '' });
  const [filterByName, setFilterByName] = useState('');
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);
  const [access, setAccess] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsObject, setSelectedRowsObject] = useState([]);
  const [showRateLimitDialog, setShowRateLimitDialog] = useState(false);
  const [selectedRateLimitQuota, setSelectedRateLimitQuota] = useState({});
  const [orgAccessErrors, setOrgAccessErrors] = useState([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState({});
  const [selectedRowsMapRateQuota, setSelectedRowsMapRateQuota] = useState({});
  const [selectedRowsMapDefaultRQ, setSelectedRowsMapDefaultRQ] = useState({});
  const [isDisableAddAccess, setDisableAddAcccess] = useState(true);
  const [isDisableRemoveAccess, setDisableRemoveAcccess] = useState(true);
  const [isDisableAddRateQuota, setDisableAddRateQuota] = useState(true);
  const [isDisableDefaultAddRateQuota, setDisableDefaultAddRateQuota] = useState(true);
  const disabledRows = orgsAccessList ? orgsAccessList.map(item =>
    (apiDetails && apiDetails.accessStatus === 'PUBLIC' && !apiDetails.publishedByPortal) ||
    (hasOrgPublisherRole(userContext) &&
      apiDetails.managingOrgUuid !== userContext.organizationUuid)
    ? item.uuid : null) || !canEdit
    || (userContext.permissions.API
      && !userContext.permissions.API.includes('UPDATE')) : [];

  useEffect(() => {
    if (tabValue === 'api-details-org-access-tab') {
      props.fetchRateLimitQuotas(2000, 0, 'API_ORGANIZATION');
      props.fetchPolicyTemplates();
      if(apiUuid) {
        props.fetchApiOrgAccess({ apiUuid, filterByName, access,
          page, rowsPerPage });
      }
    }
    setSelectedRows([]);
    setSelectedRowsObject([]);
    setSelectedRowsMap({});
    setSelectedRowsMapDefaultRQ({});
    setSelectedRowsMapRateQuota({});
  }, [apiUuid, tabValue, isLoading]);

  useEffect(() => {
    if (isOrgAccessSaveSuccess) {
      setOrgAccessErrors([]);
      props.fetchApiOrgAccess({ apiUuid, filterByName, access,
        page, rowsPerPage });
    }
  }, [isOrgAccessSaveSuccess]);

  useEffect(() => {
    if(tabValue === 'api-details-org-access-tab' && errors.length > 0 && !isOrgAccessSaveSuccess ) {
      const orgErrors = processErrorsMessage(errors, 'selectedRowsObject');
      setOrgAccessErrors(orgErrors);
    }
  }, [errors]);

  useEffect(() => {
    if(rateLimitQuotas.length > 0) {
      rateLimitQuotas.map(item => {
        const limit = get(item, 'rateLimit.maxRequestsPerSecond') ? `${get(item, 'rateLimit.maxRequestsPerSecond')} / sec` : 'None';
        const quota = get(item, 'quota.quota') ? `${get(item, 'quota.quota')} / ${lowerCase(get(item, 'quota.interval'))}`
        : 'None';
        item.secondaryText = `Rate: ${limit}; Quota: ${quota}`;
      });
    }
  }, [rateLimitQuotas]);

  useEffect(() => {
    if(selectedRows.length > 0) {
      const selectedRowsMapLocal = selectedRowsMap;
      const selectedRowsMapRateQuotaLocal = selectedRowsMapRateQuota;
      const selectedRowsMapDefaultRQLocal = selectedRowsMapDefaultRQ;
    
      orgsAccessList.filter(item =>
        selectedRows.includes(item.uuid))
            .forEach(item => selectedRowsMapLocal[item.uuid] = item.apiOrgAccessStatus);
      //When user deselects the already selected checkbox.
      if(selectedRows.length <= Object.keys(selectedRowsMapLocal).length) {
        Object.keys(selectedRowsMapLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapLocal[key];
          }
        });
      }
      setDisableAddAcccess(!Object.keys(selectedRowsMapLocal).some(item =>
        selectedRowsMap[item] === 'NONE'));
      setDisableRemoveAcccess(!Object.keys(selectedRowsMapLocal).some(item =>
        selectedRowsMap[item] === 'GRANTED_DIRECT'));
      setSelectedRowsMap(selectedRowsMapLocal);

      //add Rate & Quota
      orgsAccessList.filter(item =>
        selectedRows.includes(item.uuid))
            .forEach(item =>
              selectedRowsMapRateQuotaLocal[item.uuid] = apiDetails.publishedByPortal);

      orgsAccessList.filter(item =>
        selectedRows.includes(item.uuid))
            .forEach(item => selectedRowsMapDefaultRQLocal[item.uuid] = item.rateQuotaUuid);

      //When user deselects the already selected checkbox.
      if(selectedRows.length <= Object.keys(selectedRowsMapRateQuotaLocal).length) {
        Object.keys(selectedRowsMapRateQuotaLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapRateQuotaLocal[key];
          }
        });
        Object.keys(selectedRowsMapDefaultRQLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapDefaultRQLocal[key];
          }
        });
      }
      setDisableAddRateQuota(!Object.keys(selectedRowsMapRateQuotaLocal).some(item =>
        selectedRowsMapRateQuota[item] === true));
      if(rateLimitQuotas.length > 0 ) {
        setDisableDefaultAddRateQuota(!Object.keys(selectedRowsMapDefaultRQLocal).some(item =>
          selectedRowsMapDefaultRQ[item] !== rateLimitQuotas[0].uuid));
      }
      setSelectedRowsMapRateQuota(selectedRowsMapRateQuotaLocal);
      setSelectedRowsMapDefaultRQ(selectedRowsMapDefaultRQLocal);
    }
  }, [selectedRows]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
    setFilterByAnyApplied(true);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setFilterByName(filterByName);
      props.fetchApiOrgAccess({ apiUuid, filterByName, access,
        page:0, rowsPerPage });
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchApiOrgAccess({ apiUuid, filterByName, access,
      page:newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchApiOrgAccess({ apiUuid, filterByName, access,
      page:0, rowsPerPage:newRowsPerPage });
  };

  const onSortButtonClick = (e, buttonName) => {
    const accessType = buttonName === 'all' ? '' :
      buttonName === 'access' ? `${ACCESS_TYPE_GRANTED},${ACCESS_TYPE_MANAGED}`
      : ACCESS_TYPE_NONE;
    setAccess(accessType);
    setFilterByAnyApplied(true);
    props.fetchApiOrgAccess({ apiUuid, filterByName, access: accessType,
      page:0, rowsPerPage });
  };

  const onAddVisible = () => {
    localStorage.setItem('isUnSavedChanges', false);
    localStorage.setItem('selectedRowsObject', JSON.stringify(selectedRowsObject));
    const rowsToAdd = selectedRows.filter(item => selectedRowsMap[item] === 'NONE');
    props.addApiVisibility(apiUuid, rowsToAdd);
  };

  const onRemoveVisible = () => {
    localStorage.setItem('isUnSavedChanges', false);
    localStorage.setItem('selectedRowsObject', JSON.stringify(selectedRowsObject));
    const rowsToRemove = selectedRows.filter(item => selectedRowsMap[item] === 'GRANTED_DIRECT');
    props.removeApiVisibility(apiUuid, rowsToRemove);
  };

  const onAccessChange = (uuid, isChange) => {
    localStorage.setItem('isUnSavedChanges', false);
    const accessData = orgsAccessList.filter(item => item.uuid === uuid);
    localStorage.setItem('selectedRowsObject', JSON.stringify(accessData));
    if(isChange) {
      props.addApiVisibility(apiUuid, [uuid]);
    } else {
      props.removeApiVisibility(apiUuid, [uuid]);
    }

  }

  const onAddRateLimitLink = (uuid) => {
    const accessData = orgsAccessList.filter(item => item.uuid === uuid);
    setSelectedRows([uuid]);
    setSelectedRowsObject(accessData);
    setShowRateLimitDialog(true);
  };

  const onAddRateLimit = () => {
    setShowRateLimitDialog(true);
  };

  const onRateLimitSelect = (rateLimitQuota) => {
    setSelectedRateLimitQuota(rateLimitQuota);
  };

  const onAddRateLimitSubmit = () => {
    const rqUuid = rateLimitQuotas.length > 0 ? rateLimitQuotas[0].uuid : null;
    const rowsToAdd = selectedRows.filter(item => selectedRowsMapRateQuota[item] !== rqUuid);
    const data = [];
    rowsToAdd.map(item => {
      const payload = {
        organizationUuid: item,
        rateQuotaUuid: get(selectedRateLimitQuota, 'uuid'),
      }
      data.push(payload);
    });
    localStorage.setItem('selectedRowsObject', JSON.stringify(selectedRowsObject));
    localStorage.setItem('isUnSavedChanges', false);
    setShowRateLimitDialog(false);
    props.addApiRateLimitQuota(apiUuid, data);
  };

  const closeRateLimitDialog = () => {
    setShowRateLimitDialog(false);
  };

  const onRemoveRateLimit = () => {
    const rqUuid = rateLimitQuotas.length > 0 ? rateLimitQuotas[0].uuid : null;
    const rowsToRemove = selectedRows.filter(item => selectedRowsMapDefaultRQ[item] !== rqUuid);
    const data = [];
    rowsToRemove.map(item => {
      const payload = {
        organizationUuid: item,
        rateQuotaUuid: selectedRowsMapDefaultRQ[item],
      }
      data.push(payload);
    });
    localStorage.setItem('selectedRowsObject', JSON.stringify(selectedRowsObject));
    localStorage.setItem('isUnSavedChanges', false);
    setShowRateLimitDialog(false);
    props.removeApiRateLimitQuota(apiUuid, data);
  };

  if(selectedRows.length > 0 ){
    localStorage.setItem('isUnSavedChanges', true);
  } else {
    localStorage.setItem('isUnSavedChanges', false);
  }

  const noResultsMessage = filterByAnyApplied ?
  intl.getI18nMessage('label.organization.filter.no.results') :
  intl.getI18nMessage('label.organization.no.results');

  const RateLimitQuotaSelect = () => (
    <FormAutoCompleteCombo
      data={rateLimitQuotas}
      selectedValues={selectedRateLimitQuota}
      onChange={(e, value) => onRateLimitSelect(value)}
      isSecondaryText={true}
      loading={true}
      id="api-org-access-select-rate-limit-quota"
      inputLabel="Select Rate Limit Quota"
    />
  );

  return (
    <div id="api-org-access-container" data-apim-test="api-org-access-container">
      {orgAccessErrors.length > 0 &&
        <ErrorContainer errors={orgAccessErrors} />
      }
      {showRateLimitDialog &&
        <AlertDialog
          id="api-org-access-list-dialog"
          isOpen={showRateLimitDialog}
          title={intl.getI18nMessage('label.rate.quota.assign.title')}
          component={<RateLimitQuotaSelect />}
          submitText={intl.getI18nMessage('label.save.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          defaultText={intl.getI18nMessage('label.restore.default')}
          onDefault={onRemoveRateLimit}
          showDefault={true}
          onClose={closeRateLimitDialog}
          onSubmit={onAddRateLimitSubmit}
          onCancel={closeRateLimitDialog}
          containerClass={classes.dialogContainer}
          submitButtonClass={classes.submitButton}
          dialogActionClass={classes.dialogActionClass}
        />
      }
      <ListContainer
        listPageId="api-org-access-list-page"
        isLoading={isLoading}
        pageBodyClass={classes.listContainer}
        notificationId="api-org-access-notifications"
        notificationStatus={notification.status}
        setNotificationStatus={(status) => setNotification({ status, message: '' })}
        notificationMessage={notification.message}
        setNotificationMessage={(message) => setNotification({ status: '', message })}
        pageHeaderTitle={''}
        addButtonLabel={intl.getI18nMessage('label.organization.list.add.button')}
        onAdd={false}
        showListHeader={false}
        pageFilterAndSortClass={classes.pageFilterAndSort}
        filterAndSortContent={(
          <>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.organization.name.list.page.filter.by.name.placeholder')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
            />
            <FilterAndSortSeparator />
            {apiDetails.accessStatus === API_ACCESS_STATUS_PRIVATE &&
              <AccessFilterButtons
                onSortButtonClick={onSortButtonClick}
                fieldContainerClass={classes.sortContainerClass}
                access={access}
                activeButton={classes.activeButton}
              />
            }
          </>
        )}
        bulkActionsContent={
          <HeaderActionButtons
            {...props}
            bulkActionsItemsCount={selectedRows.length}
            hasVisibleActions={apiDetails && apiDetails.accessStatus === API_ACCESS_STATUS_PRIVATE}
            hasRateQuotaActions={apiDetails && apiDetails.publishedByPortal}
            onAddVisible={onAddVisible}
            onRemoveVisible={onRemoveVisible}
            onAddRateLimit={onAddRateLimit}
            onRemoveRateLimit={onRemoveRateLimit}
            disableAddAccess={isDisableAddAccess}
            disableRemoveAccess={isDisableRemoveAccess}
            disableAddRateQuota={isDisableAddRateQuota}
            disableDefaultAddRateQuota={isDisableDefaultAddRateQuota}
          />
        }
        columns={getOrgListColumns(props, onAccessChange,
          rateLimitQuotas, onAddRateLimitLink, disabledRows)}
        rows={orgsAccessList}
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={orgsAccessListTotalElements}
        totalPages={orgsAccessListPage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowsObject={selectedRowsObject}
        setSelectedRowsObject={setSelectedRowsObject}
        disabledRows={disabledRows}
        hasPaginationDisabled={selectedRows.length>0}
        paginationTooltipMessage={intl.getI18nMessage('label.organization.access.pagination.disabled.message')}
      />
    </div>
  );
}

OrganizationAccess.propTypes = {
  classes: object,
  apiDetails: object,
  orgsAccessList: arrayOf(object),
  userContext: object,
  isLoading: bool,
  onCancel: func,
  orgsAccessListPage: object,
  orgsAccessListTotalElements: object,
  errors: arrayOf(object),
  apiUuid: string,
  push: object,
  tabValue: string,
  fetchApiOrgAccess: func,
  addApiRateLimitQuota: func,
  removeApiRateLimitQuota: func,
  rateLimitQuotas: arrayOf(object),
  policyTemplates: arrayOf(object),
  fetchRateLimitQuotas: func,
  fetchPolicyTemplates: func,
  addApiVisibility: func,
  removeApiVisibility: func,
  isOrgAccessSaveSuccess: bool,
  hasPaginationDisabled: bool,
  canEdit: bool,
  paginationTooltipMessage: string,
}

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  rateLimitQuotas: getRateLimitQuotas(state),
  rateLimitQuotasPage: getRateLimitQuotasPage(state),
  orgsAccessList: getOrgsAccessListResults(state),
  orgsAccessListTotalElements: getOrgsAccessListTotalElements(state),
  orgsAccessListPage: getOrgsAccessListTotalPages(state),
  isOrgAccessSaveSuccess: getIsOrgAccessSaveSuccess(state),
  errors: getErrors(state),
});
  
const mapDispatchToProps = {
  fetchPolicyTemplates,
  fetchRateLimitQuotas,
  fetchApiRateLimitQuota,
  fetchApiOrgAccess,
  addApiRateLimitQuota,
  removeApiRateLimitQuota,
  addApiVisibility,
  removeApiVisibility,
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationAccess);
  