import React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField, FormSelect } from '../../../components';
import {
  RATE_QUOTAS_LIST_LEVELS,
  RATE_QUOTAS_LIST_LIMITS,
  RATE_QUOTAS_LIST_SORT_BY,
  RATE_QUOTAS_LIST_LEVELS_WITH_PRODUCTS,
} from '../../../constants';

export const FilterByName = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormTextField
      {...props}
      id="rate-quote-filter-by-name"
      data-apim-test="rate-quote-filter-by-name"
    />
  </Grid>
);

export const FilterByLevels = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="rate-quote-filter-by-level"
      data-apim-test="rate-quote-filter-by-level"
      // eslint-disable-next-line react/prop-types
      data={props.featureFlagProduct
        ? RATE_QUOTAS_LIST_LEVELS_WITH_PRODUCTS
        : RATE_QUOTAS_LIST_LEVELS}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterByLimit = (props) => (
  <Grid item md={2} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="rate-quote-filter-by-limit"
      data-apim-test="rate-quote-filter-by-limit"
      data={RATE_QUOTAS_LIST_LIMITS}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const SortBy = (props) => (
  <Grid item md={3} sm={3} xs={12}>
    <FormSelect
      {...props}
      id="rate-quote-sort-by"
      data-apim-test="rate-quote-sort-by"
      data={RATE_QUOTAS_LIST_SORT_BY}
      noNoneOption
      noNativeSelect
    />
  </Grid>
);

export const FilterAndSortSeparator = () => (
  <Grid item md={1} />
);
