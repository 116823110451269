import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { withStyles, Link, Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WarningIcon from "@material-ui/icons/Warning";
import { forEach } from 'lodash';

import styles from './styles';
import { fetchApis, updateProductApis } from "../../../../actions/products";
import {
  getApisListResults,
  getApisListTotalElements,
  getApisListTotalPages,
  getIsApiUpdateSuccess,
} from "../../../../reducers/products";
import ListContainer from "../../../list";
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24,
  GRID_ROWS_PER_PAGE_EXTRA_OPTIONS,
  ALERT_SUCCESS,
} from "../../../../constants";
import { getI18n, getI18nFormattedMessage } from "../../../../utils/intl";
import { getStatusColor, getStatusLabel } from "../../../Api/List/utils";
import {
  FilterByName,
  FilterSeparator,
  AddButton,
} from './controls';
import {
  Switch,
  ToolTip as ToolTipComp,
  FormActionButtons,
} from '../../../../components';

const formatSwitch = (uuid, assignStatus, onAssignChange, classes, selectedRows) => (
  <div className={classes.switchAccess}>
    <Switch
      id={`${uuid}-assign`}
      checked={assignStatus && !selectedRows.includes(uuid)}
      onChange={(e) => { onAssignChange(uuid, e) }}
    />
  </div>
);

const getLinkContent = (value, uuid) => (
  <Link underline="none" href={`/publish/apis/details/${uuid}`}>
    {value}
  </Link>
);

export const getAPIListColumns = (classes, onAssignChange, selectedRows) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.api.name'),
  minWidth: 100,
  value: (item) => getLinkContent(item.name, item.apiUuid),
}, {
  id: 'portalStatus',
  label: getI18nFormattedMessage('label.state'),
  minWidth: 50,
  value: (item) => {
    const { portalStatus } = item;
    const status = getStatusLabel(portalStatus);
    const statusIconStyle = {
      fill: getStatusColor(portalStatus),
    };
    return (
      <span>
          <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />
          {status === 'Deprecated' || status === 'Disabled' ?
            <span> {status}
              <Tooltip
                title={status === 'Deprecated' ?
                  getI18nFormattedMessage('label.product.apis.deprecated.tooltip')
                :
                  getI18nFormattedMessage('label.product.apis.disabled.tooltip')}
                arrow
                placement="top"
              >
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            </span>
          :
            <span>{status}</span>
          }
      </span>);
  },
},{
  id: 'productApiAccessStatus',
  secondaryId: 'uuid',
  label: (
    <ToolTipComp
      classes={classes}
      label={getI18nFormattedMessage('label.assign')}
      info={<div>{getI18nFormattedMessage('label.product.apis.assign.tooltip')}</div>}
    />
  ),
  minWidth: 50,
  value: (item) =>
    formatSwitch(item.apiUuid, item.productApiAccessStatus, onAssignChange, classes, selectedRows),
}];

export const EditApis = (props) => {
  const {
    classes,
    productUuid,
    isLoading,
    apisList = [],
    totalElements,
    totalPages,
    isCurrentTab,
    isApiUpdateSuccess,
    onPageChange,
    notifyMessages,
  } = props;

  const [filterByName, setFilterByName] = useState('');
  const [filterAssign, setFilterAssign] = useState('ASSIGNED');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [isFieldEdit, setIsFieldEdit] = useState(false);
  const [filterByNameApplied, setFilterByNameApplied] = useState(false);
  const intl = getI18n(useIntl());
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (productUuid && isCurrentTab) {
      props.fetchApis({ uuid: productUuid, page: 0, size: rowsPerPage, assign: filterAssign });
    }
  }, [productUuid, isCurrentTab]);

  useEffect(() => {
    if (isApiUpdateSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.product.updated'));
      setPage(0);
      props.fetchApis({ uuid: productUuid, page: 0, size: rowsPerPage, assign: 'ASSIGNED' });
      setTimeout(() => { notifyMessages('', '')}, 2500);
    }
  }, [isApiUpdateSuccess]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByNameApplied(true);
      setPage(0);
      props.fetchApis({
        uuid: productUuid, page: 0, name: filterByName, size: rowsPerPage, assign: filterAssign,
      });
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchApis({
      uuid: productUuid, name: filterByName, page: newPage, size: rowsPerPage, assign: filterAssign,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchApis({
      uuid: productUuid, name: filterByName, page: 0, size: newRowsPerPage, assign: filterAssign,
    });
  };

  const onAssignChange = (uuid, isChange) => {
    localStorage.setItem('isUnSavedChanges-products', true);
    let selectRowsMap = selectedRows;
    if(isChange){
      selectRowsMap = selectRowsMap.filter(id => id !== uuid)
    } else {
      selectRowsMap.push(uuid);
    }

    setSelectedRows(selectRowsMap);
    setSelectedRowCount(selectRowsMap.length);
    setIsFieldEdit(true);
  }

  const onClickAssign = () => {
    onPageChange('add');
  };


  const onSave = () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    const data = [];
    forEach(selectedRows, function(value){
      const obj = { apiUuid: value };
      data.push(obj);
    });
    const action = 'remove';
    props.updateProductApis(productUuid, data, action);
    setSelectedRowCount(0);
    setSelectedRows([]);
    setIsFieldEdit(false);
  }

  const onCancel = () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    setIsFieldEdit(false);
    setFilterAssign('ASSIGNED');
    setSelectedRowCount(0);
    setSelectedRows([]);
    props.fetchApis({ uuid: productUuid, page: page, size: rowsPerPage, assign: 'ASSIGNED' });
  }

  const noResultsMessage = filterByNameApplied ?
    `${intl.getI18nMessage('label.apis.filter.no.results')}` :
    intl.getI18nMessage('label.product.apis.no.results');

  return (
    <>
      <ListContainer
        listPageId="product-details-apis-edit-list"
        isLoading={isLoading}
        showListHeader={false}
        pageClass={classes.listContent}
        pageBodyClass={classes.pageBodyClass}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter.by')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.gateway.bundle.apis.filter.by.name.placeholder')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
            />
            <FilterSeparator />
            <AddButton
              onClickAssign={onClickAssign}
              fieldContainerClass={classes.sortContainerClass}
              activeButton={classes.activeButton}
              disabled={selectedRowCount > 0}
            />
          </Fragment>
        )}
        columns={getAPIListColumns(classes, onAssignChange, selectedRows) || []}
        rows={apisList}
        noResultsMessage={noResultsMessage}
        page={page}
        count={totalElements}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_EXTRA_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
      />
      <FormActionButtons
        isContainerFooter={true}
        onNextClick={onSave}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="product-details-apis-tab-edit-form-buttons"
        containerClass={!isFieldEdit?
          classes.hideFooterContainer: classes.showFooterContainer}
        isDisabled={selectedRowCount === 0}
        assistText={
          <span>
            {selectedRowCount}  {getI18nFormattedMessage('label.changed')}
            <WarningIcon className={classes.warningIcon} />
            {getI18nFormattedMessage('label.redepolyment.required')}
          </span>
          }
      />
    </>
  )
};

EditApis.propTypes = {
  classes: shape({}),
  productDetails: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  apisList: arrayOf(shape({})),
  fetchApis: func,
  productUuid: string,
  isCurrentTab: bool,
  isApiUpdateSuccess: bool,
  onPageChange: func,
  notifyMessages: func,
  updateProductApis: func,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  apisList: getApisListResults(state),
  totalPages: getApisListTotalPages(state),
  totalElements: getApisListTotalElements(state),
  isApiUpdateSuccess: getIsApiUpdateSuccess(state),
});

const mapDispatchToProps = {
  fetchApis,
  updateProductApis,
};
EditApis.displayName = 'EditApis';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EditApis);
