import React from 'react';
import { Link, Button } from '@material-ui/core';
import { lowerCase } from 'lodash';

import { getI18nFormattedMessage } from '../../../../utils/intl';
import { Switch, ToolTip } from '../../../../components';
import {
  TIERS_PRODUCT_ASSIGNED,
  TIERS_PRODUCT_NOT_ASSIGNED,
} from '../../../../constants';
import { formatNumber } from '../../../../utils';

const getLinkContent = (value, secondaryValue, classes) => (
  <div>
    <Link underline="none" href={`/admin/products/tiers`}>
      {value}
    </Link>
    {secondaryValue && (
      <span className={classes.tooltipContainer}>
        {getI18nFormattedMessage('label.default')}
      </span>
    )}
  </div>
);

export const getListColumns = (
  classes,
  setHasChanges,
  setNewPayload,
  setShowRateQuotaDialog,
  setSelectedRateLimitQuota,
  rateLimitQuotas,
  setEditingTierUuid,
  assignRateQuotaToNewPayload,
) => [
  {
    id: 'tierName',
    secondaryId: 'defaultRateQuota',
    label: getI18nFormattedMessage('label.product.tiers.list.column.tier.name'),
    value: (tier) => getLinkContent(tier.tierName, tier.isDefaultTier, classes),
    minWidth: 130,
  },
  {
    id: 'productTierAccessStatus',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nFormattedMessage('label.access')}
        info={
          <div>
            {getI18nFormattedMessage(
              'label.product.tiers.list.column.access.tooltip',
            )}
          </div>
        }
      />
    ),
    value: (item) => (
      <div className={classes.switchAccess}>
        <Switch
          id={`${item.tierUuid}-access`}
          checked={
            item.productTierAccessStatus === TIERS_PRODUCT_ASSIGNED
              ? true
              : false
          }
          onChange={() => {
            setNewPayload((newPayload) => {
              localStorage.setItem('isUnSavedChanges-products', true);
              // Find the index and if already assigned, remove rateQuota and unassign
              const index = newPayload.findIndex(
                (payload) => payload.tierUuid === item.tierUuid,
              );

              if (
                index > -1 &&
                newPayload[index].productTierAccessStatus ===
                  TIERS_PRODUCT_ASSIGNED
              ) {
                newPayload[index].productTierAccessStatus =
                  TIERS_PRODUCT_NOT_ASSIGNED;
                delete newPayload[index].rateQuota;
                newPayload[index].isModified = true;
              } else if (
                index > -1 &&
                newPayload[index].productTierAccessStatus ===
                  TIERS_PRODUCT_NOT_ASSIGNED
              ) {
                // Find default rateQuota and assign it
                rateLimitQuotas.find((rateLimitQuota) => {
                  if (rateLimitQuota.defaultRateQuota) {
                    assignRateQuotaToNewPayload(
                      newPayload,
                      index,
                      rateLimitQuota,
                    );
                  }
                });
              }

              setHasChanges(true);

              return newPayload;
            });
          }}
          disabled={item.isDefaultTier}
        />
      </div>
    ),
  },
  {
    id: 'rateQuota',
    label: getI18nFormattedMessage('label.product.tiers.list.column.rateQuota'),
    value: (item) => (
      <>
        <Button
          className={classes.defaultButton}
          data-apim-test="open-popup-rateQuota"
          id="open-popup-rateQuota"
          data-layer7-test="open-popup-rateQuota"
          onClick={() => {
            setEditingTierUuid(item.tierUuid);
            // Pre-select rateQuota if one is already assigned & show modal
            if (item.rateQuota?.uuid) {
              rateLimitQuotas.find((rateLimitQuota) => {
                if (rateLimitQuota.uuid === item.rateQuota.uuid) {
                  setSelectedRateLimitQuota(rateLimitQuota);
                }
              });
            }
            setShowRateQuotaDialog(true);
          }}
          variant="text"
          color="secondary"
        >
          {item.rateQuota && item.rateQuota?.name
            ? item.rateQuota.name
            : getI18nFormattedMessage(
                'label.product.tiers.list.column.rateQuota.addLimit',
              )}
        </Button>
        {item.rateQuota && item.rateQuota.isDefault && (
          <span className={classes.tooltipContainer}>
            {getI18nFormattedMessage('label.default')}
          </span>
        )}
      </>
    ),
    minWidth: 320,
  },
  {
    id: 'rateLimit',
    label: getI18nFormattedMessage('label.product.tiers.list.column.rateLimit'),
    value: (item) =>
      item.rateQuota?.rateLimit ? formatNumber(item.rateQuota.rateLimit) : '',
  },
  {
    id: 'quota',
    label: getI18nFormattedMessage('label.product.tiers.list.column.quota'),
    value: (item) =>
      item.rateQuota && item.rateQuota.quota
        ? `${formatNumber(item.rateQuota.quota)} / ${lowerCase(
            item.rateQuota.quotaInterval,
          )}`
        : '',
  },
  {
    id: 'action',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nFormattedMessage(
          'label.product.tiers.list.column.action',
        )}
        info={
          <div>
            {getI18nFormattedMessage(
              'label.product.tiers.list.column.action.tooltip',
            )}
          </div>
        }
      />
    ),
    value: (item) =>
      !item.isDefaultTier &&
      item.productTierAccessStatus === TIERS_PRODUCT_ASSIGNED ? (
        <Button
          className={classes.defaultButton}
          data-apim-test="make-tier-default-action"
          id="make-tier-default-action"
          data-layer7-test="make-tier-default-action"
          onClick={() => {
            setHasChanges(true);
            setNewPayload((newPayload) => {
              const index = newPayload.findIndex(
                (payload) => payload.tierUuid === item.tierUuid,
              );

              if (index > -1) {
                newPayload.forEach((payload) => {
                  payload.isDefaultTier = false;
                });
                newPayload[index].isModified = true;
                newPayload[index].isDefaultTier = true;
              }

              return newPayload;
            });
          }}
          variant="text"
          color="secondary"
        >
          {getI18nFormattedMessage(
            'label.product.tiers.list.column.action.default',
          )}
        </Button>
      ) : null,
    minWidth: 200,
  },
];

export default getListColumns;
