import React from 'react';
import Editor from '@uiw/react-md-editor';
import { makeStyles } from '@material-ui/core';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import { markdownTable } from 'markdown-table'
import { func, object, string } from 'prop-types';

export const MarkdownEditor = ({
    value,
    onChange,
    ...rest
}) => {
    const handleChange = (text) => {
        onChange(text);
    };
    const classes = useStyles(rest);

    const tableCmd = {
        name: "table",
        keyCommand: "table",
        buttonProps: { "aria-label": "Insert table" },
        icon: (<TableChartOutlinedIcon className={classes.icon} />),
        execute: (state, api) => {
          const modifyText = markdownTable([
            ['Header', 'Header', 'Header'],
            ['Data', 'Data', 'Data'],
            ['Data', 'Data', 'Data'],
          ]);
          api.replaceSelection(modifyText);
        },
      };

    return (
        <div className={classes.editorContainer}>
            <Editor
                value={(value)}
                visible
                className={classes.editorInput}
                onChange={handleChange}
                extraCommands={[tableCmd]}
                data-color-mode="light"
            />
        </div>
    );
};

const useStyles = makeStyles(
    () => ({
        editorContainer: {
            marginBottom: '10px',
            '& .w-md-editor-toolbar': {
                justifyContent: 'left',
            },
        },
        editorInput: {
            marginTop: '10px',
            minHeight: '400px',
            width: '100%',
        },
        icon: {
            fontSize: '14px',
        },
    }),
);

MarkdownEditor.propTypes = {
    options: object,
    name: string,
    value: string,
    onChange: func,
    className: object,
};