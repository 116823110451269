import { get } from 'lodash';

const styles = ({ margin, padding, color, lineHeight }) => ({
  statusIcon: {
    height: 15,
    marginRight: 3,
    width: 15,
  },
  listContent: {
    margin: get(margin, 'm0'),
  },
  pageBodyClass: {
    paddingTop: get(padding, 'p0'),
  },
  pageFilterAndSortClass: {
    marginTop: get(margin, 'm24'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
  selectField: {
    padding: '2px 10px !important',
  },
  activeButton: {
    textTransform: 'none',
    color: get(color, 'primaryButtonText'),
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    float: 'right',
    marginRight: get(margin, 'm10'),
    lineHeight: get(lineHeight, 'lh20'),
    marginTop: get(margin, 'm20'),
  },
  hideFooterContainer: {
    display: 'none',
  },
  showFooterContainer: {
    display: 'visible',
  },
  warningIcon: {
    color: get(color, 'warning'),
    marginLeft: get(margin, 'm10'),
    marginRight: get(margin, 'm4'),
    fontSize: 14,
    marginTop: get(margin, 'm4'),
  },
  addButton: {
    margin: `0 ${get(margin, 'm2')}`,
  },
  selectContainer: {
    marginTop: '0 !important',
    marginLeft: get(margin, 'm4'),
  },
  buttonContainer: {
    marginTop: get(margin, 'm10'),
    paddingLeft: get(margin, 'm4'),
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
});

export default styles;
