import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { bool, func, shape, arrayOf, object, string } from 'prop-types';

import {
  AlertDialog,
  FormActionButtons,
  FormAutoCompleteCombo,
} from '../../../../components';
import {
  fetchTiersProduct,
  saveTiersProduct,
  resetFetchTiersProduct,
} from '../../../../actions/products';
import {
  getIsTiersProductSaveSuccess,
  getTiersProduct,
  getIsFetchTiersProduct,
} from '../../../../reducers/products';
import styles from './styles';
import ListContainer from '../../../list';
import {
  ALERT_SUCCESS,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION,
  TIERS_PRODUCT_ASSIGNED,
  TIERS_PRODUCT_NOT_ASSIGNED,
} from '../../../../constants';
import { getI18n } from '../../../../utils/intl';
import { getRateLimitQuotas } from '../../../../reducers/api';
import { fetchRateLimitQuotas } from '../../../../actions/api';
import getListColumns from './columns';
import { isEmpty } from 'lodash';

export const RateQuota = (props) => {
  const {
    classes,
    isLoading,
    productUuid,
    tiersProduct,
    rateLimitQuotas,
    isTiersProductSaveSuccess,
    fetchTiersProduct,
    fetchRateLimitQuotas,
    resetFetchTiersProduct,
    isFetchTiersProduct,
    saveTiersProduct,
    isCurrentTab,
    notifyMessages,
    isError,
  } = props;

  const intl = getI18n(useIntl());

  const [hasChanges, setHasChanges] = useState(false);
  const [newPayload, setNewPayload] = useState([]);
  const [showRateQuotaDialog, setShowRateQuotaDialog] = useState(false);
  const [selectedRateLimitQuota, setSelectedRateLimitQuota] = useState({});
  const [editingTierUuid, setEditingTierUuid] = useState('');

  useEffect(() => {
    if (isTiersProductSaveSuccess) {
      fetchTiersProduct(productUuid);
      notifyMessages(
        ALERT_SUCCESS,
        intl.getI18nMessage('label.product.tiers.update.successful'),
      );
      setTimeout(() => {
        notifyMessages('', '');
      }, 2500);
    }
  }, [isTiersProductSaveSuccess]);

  useEffect(() => {
    if (isCurrentTab) {
      fetchTiersProduct(productUuid);
      fetchRateLimitQuotas(2000, 0, QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION);
    }
  }, [isCurrentTab]);

  useEffect(() => {
    if (isError && isCurrentTab) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [isCurrentTab, isError]);

  useEffect(() => {
    // Reformat the payload to match the API PATCH request
    if (tiersProduct?.length > 0 && isFetchTiersProduct) {
      resetFetchTiersProduct();
      const initialPayload = [];
      tiersProduct.forEach((tierProduct) => {
        initialPayload.push({
          ...tierProduct,
          rateQuotaUuid: tierProduct.rateQuota?.uuid,
        });
      });
      setNewPayload(initialPayload);
    }
  }, [tiersProduct]);

  const onModalClose = () => {
    setEditingTierUuid('');
    setSelectedRateLimitQuota({});
    setShowRateQuotaDialog(false);
  };

  // Assign the rateQuota to the newPayload to fit GET format from API
  const assignRateQuotaToNewPayload = (newPayload, index, rateLimitQuota) => {
    newPayload[index].rateQuota && delete newPayload[index].rateQuota;
    newPayload[index].rateQuotaUuid = rateLimitQuota.uuid;
    newPayload[index].rateQuota = {
      uuid: rateLimitQuota.uuid,
      name: rateLimitQuota.name,
      quota: rateLimitQuota.quota.quota,
      quotaInterval: rateLimitQuota.quota.interval,
      rateLimit: rateLimitQuota.rateLimit.maxRequestsPerSecond,
      isDefault: rateLimitQuota.defaultRateQuota,
    };
    newPayload[index].isModified = true;
    newPayload[index].productTierAccessStatus = TIERS_PRODUCT_ASSIGNED;
  };

  const updateRateQuota = () => {
    localStorage.setItem('isUnSavedChanges-products', true);
    if (!isEmpty(selectedRateLimitQuota)) {
      setNewPayload((newPayload) => {
        const index = newPayload.findIndex(
          (payload) => payload.tierUuid === editingTierUuid,
        );

        if (index > -1 && selectedRateLimitQuota) {
          assignRateQuotaToNewPayload(
            newPayload,
            index,
            selectedRateLimitQuota,
          );
        }

        return newPayload;
      });

      // If initialTier rateQuota uuid !== selectedRateLimitQuota uuid show save footer
      const initialTier = tiersProduct.find(
        (tierProduct) => tierProduct.tierUuid === editingTierUuid,
      );

      if (initialTier.rateQuota?.uuid !== selectedRateLimitQuota.uuid) {
        setHasChanges(true);
      }
    }

    onModalClose();
  };

  const onSave = async () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    const unassignPayload = newPayload.filter(
      (payload) =>
        payload.productTierAccessStatus === TIERS_PRODUCT_NOT_ASSIGNED && payload.isModified,
    );
    const updatePayload = newPayload.filter(
      (payload) => payload.productTierAccessStatus === TIERS_PRODUCT_ASSIGNED && payload.isModified,
    );

    // Avoid sending remove request for already unassigned tiersProduct
    tiersProduct.forEach((tierProduct) => {
      if (tierProduct.productTierAccessStatus === TIERS_PRODUCT_NOT_ASSIGNED) {
        const index = unassignPayload.findIndex(
          (payload) => payload.tierUuid === tierProduct.tierUuid && !!payload.isModified,
        );
        if (index > -1) {
          unassignPayload.splice(index, 1);
        }
      }
    });

    if(updatePayload.length > 0){
      await saveTiersProduct(productUuid, updatePayload);
    }
    if (unassignPayload.length > 0) {
      saveTiersProduct(productUuid, unassignPayload, 'remove');
    }
    setHasChanges(false);
  };

  return (
    <div
      id="tiers-product-container"
      data-apim-test="tiers-product-container"
      className={classes.tiersProductContainer}
    >
      {showRateQuotaDialog && (
        <AlertDialog
          id="tiers-product-list-rateQuota-dialog"
          isOpen={showRateQuotaDialog}
          title={intl.getI18nMessage('label.rate.limit.quotas')}
          component={
            <FormAutoCompleteCombo
              data={rateLimitQuotas}
              selectedValues={selectedRateLimitQuota}
              onChange={(e, value) => {
                setSelectedRateLimitQuota(value);
              }}
              isSecondaryText={true}
              loading={true}
              id="tiers-product-rateQuota-select"
              inputLabel={intl.getI18nMessage('label.search.button')}
            />
          }
          submitText={intl.getI18nMessage('label.apply.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={onModalClose}
          onSubmit={updateRateQuota}
          onCancel={onModalClose}
          containerClass={classes.dialogContainer}
          dialogActionClass={classes.dialogActionClass}
        />
      )}
      <ListContainer
        listPageId="tiers-product"
        isLoading={isLoading}
        pageHeaderTitle={intl.getI18nMessage(
          'label.product.tiers.list.page.title',
        )}
        pageHeaderDescription={intl.getI18nMessage(
          'label.product.tiers.list.page.description',
        )}
        showListHeader={true}
        columns={getListColumns(
          classes,
          setHasChanges,
          setNewPayload,
          setShowRateQuotaDialog,
          setSelectedRateLimitQuota,
          rateLimitQuotas,
          setEditingTierUuid,
          assignRateQuotaToNewPayload,
        )}
        rows={newPayload}
        hasPaginationDisabled={true}
        classes={classes}
        disableAdd={true}
      />
      <FormActionButtons
        isContainerFooter={true}
        onNextClick={onSave}
        onCancelClick={() => {
          localStorage.setItem('isUnSavedChanges-products', false);
          fetchTiersProduct(productUuid) && setHasChanges(false)
        }}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="product-details-ratequota-save-footer"
        containerClass={
          hasChanges ? classes.showFooterContainer : classes.hideFooterContainer
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: false,
  tiersProduct: getTiersProduct(state),
  rateLimitQuotas: getRateLimitQuotas(state),
  isTiersProductSaveSuccess: getIsTiersProductSaveSuccess(state),
  isFetchTiersProduct: getIsFetchTiersProduct(state),
});

const mapDispatchToProps = {
  fetchTiersProduct,
  fetchRateLimitQuotas,
  resetFetchTiersProduct,
  saveTiersProduct,
  push,
};

RateQuota.propTypes = {
  classes: shape({}),
  isLoading: bool,
  productUuid: string,
  tiersProduct: arrayOf(object),
  rateLimitQuotas: arrayOf(object),
  isTiersProductSaveSuccess: bool,
  fetchTiersProduct: func,
  fetchRateLimitQuotas: func,
  saveTiersProduct: func,
  isCurrentTab: bool,
  push: func,
  notifyMessages: func,
  resetFetchTiersProduct: func,
  isFetchTiersProduct: bool,
  isError: bool,
};

RateQuota.displayName = 'TiersProduct';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(RateQuota);
