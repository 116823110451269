import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Link,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, Check } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { PropTypes, shape } from 'prop-types';

import { AlertDialog, LoadingDialog } from '../../../../components';
import { getI18nFormattedMessage } from '../../../../utils/intl';
import { hasPublisherRole } from '../../../../utils/rbac';
import { hasAPIPortalStatus } from "../../../../utils";
import styles from './styles';
import { getI18n } from '../../../../utils/intl';

import { processErrors } from '../../../../utils';
import { FAIL, SUCCESS } from '../../../../constants';


export const ActionsRaw = ({
  apiDetails,
  applications,
  userContext,
  apiUuid,
  history,
  canEdit,
  canDelete,
  deleteApi,
  deleteApiStatus,
  setNotificationStatus,
  setNotificationMessage,
  errors,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const intl = getI18n(useIntl());
  const [open, setOpen] = useState(false);
  const [openAddApiToApplicationModal, setOpenAddApiToApplicationModal] =
    useState(false);

  // Delete dialog
  const [showDeleteApiDialog, setShowDeleteApiDialog] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const closeDeleteApiDialog = () => setShowDeleteApiDialog(false);

  // Handle API Delete Status
  useEffect(() => {
    if (deleteApiStatus === SUCCESS) {
      setShowLoadingDialog(false);
      setNotificationStatus("success");
      setNotificationMessage(
        intl.getI18nMessage("label.api.request.submit.success"),
      );

      history && history.push(`/publish/apis`);
    } else if (deleteApiStatus === FAIL) {
      const defaultErrorMessage = intl.getI18nMessage(
        "label.api.request.submit.fail",
      );
      const errorMessage = processErrors(errors, defaultErrorMessage);
      setShowLoadingDialog(false);
      setNotificationStatus("error");
      setNotificationMessage(errorMessage);
    }
  }, [deleteApiStatus]);

  // Permissions logics
  const canAddApp = hasAPIPortalStatus(apiDetails);
  const canEditByPortalStatus = canEdit && hasAPIPortalStatus(apiDetails);
  const canEditPermissions =
    canEdit && hasPublisherRole(userContext) && hasAPIPortalStatus(apiDetails);
  const canDeletePermissions =
    canDelete &&
    apiDetails.possibleStatuses &&
    apiDetails.possibleStatuses.includes("DELETED") &&
    ["DISABLED", "INCOMPLETE"].includes(apiDetails.portalStatus);

  // List of Actions
  const actions = [
    {
      text: "Add API to Application",
      onClick: () => setOpenAddApiToApplicationModal(true),
      visible: canAddApp,
    },
    {
      text: "Edit API Details",
      onClick: () => {
        history && history.push(`/publish/apis/edit/${apiUuid}`);
      },
      visible: canEdit,
    },
    {
      text: "Edit Management Permissions",
      onClick: () => {
        history && history.push(`/publish/apis/edit/${apiUuid}/management`);
      },
      visible: canEditPermissions,
    },
    {
      text: "Edit Tags",
      onClick: () => {
        history && history.push(`/publish/apis/edit/${apiUuid}/tags`);
      },
      visible: canEditByPortalStatus,
    },
    {
      text: "Delete API",
      onClick: () => setShowDeleteApiDialog(true),
      visible: canDelete,
      disabled: !canDeletePermissions,
      disabledTooltip: intl.getI18nMessage("label.api.delete.tooltip.text"),
    },
  ];

  return (
    <div
      id="api-details-actions-container"
      data-apim-test="api-details-actions-container"
      className={classes.actionsContainer}
    >
      <AlertDialog
        isOpen={showDeleteApiDialog}
        title={intl.getI18nMessage("label.api.delete")}
        description={intl.getI18nMessage("label.api.delete.confirmation.text")}
        submitText={intl.getI18nMessage("label.ok.button")}
        cancelText={intl.getI18nMessage("label.cancel.button")}
        onClose={closeDeleteApiDialog}
        onSubmit={() => {
          closeDeleteApiDialog();
          setShowLoadingDialog(true);
          deleteApi(apiUuid);
        }}
        onCancel={closeDeleteApiDialog}
        dialogId="api-delete-dialog"
      />
      <LoadingDialog
        isOpen={showLoadingDialog}
        title={intl.getI18nMessage("label.api.deleting")}
        description={intl.getI18nMessage("label.api.deleting.status.text")}
        dialogId="deleting-api-dialog"
      />
      <AlertDialog
        isOpen={openAddApiToApplicationModal}
        containerClass={classes.addApplicationDialog}
        title={intl.getI18nMessage("label.api.add.application.title.modal")}
        description={intl.getI18nMessage(
          "label.api.add.application.description.modal",
        )}
        component={
          <List
            dense={true}
            component="div"
            className={classes.applicationList}
          >
            {applications && applications.length > 0 ? (
              applications.map((application) => (
                <ListItem
                  key={application.Uuid}
                  button
                  onClick={() => {
                    history &&
                      application.Uuid &&
                      history.push(
                        `/admin/console/applications/details/${application.Uuid}#api-management`,
                      );
                  }}
                  className={classes.listItem}
                >
                  {application.Name && (
                    <Link underline="none">{application.Name}</Link>
                  )}
                  {application.ApiIds.results &&
                    application.ApiIds.results.includes(apiUuid) && (
                      <ListItemSecondaryAction>
                        <IconButton size="small" edge="end">
                          <Check fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                </ListItem>
              ))
            ) : (
              <ListItem
                disabled
                className={classes.listItem}
                data-apim-test="no-application"
              >
                <ListItemText primary={intl.getI18nMessage("label.api.applications.not.found")} />
              </ListItem>
            )}
          </List>
        }
        showSubmit={false}
        cancelText={intl.getI18nMessage("label.cancel.button")}
        onClose={() => setOpenAddApiToApplicationModal(false)}
        onCancel={() => setOpenAddApiToApplicationModal(false)}
        dialogId="add-api-dialog"
      />
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <List component="nav">
          <ListItem
            id="api-details-actions-button"
            data-apim-test="api-details-actions-button"
            classes={{ root: classes.listButton }}
            button
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ListItemText
              primary={getI18nFormattedMessage("label.actions.button")}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            className={classes.collapseContainer}
            in={open}
            unmountOnExit
          >
            <List
              id="api-action-list-container"
              data-apim-test="api-action-list-container"
              component="div"
              disablePadding
              className={classes.list}
            >
              {actions
                .filter(({ visible }) => visible)
                .map((obj, index) => (
                  obj.disabled ?
                  <Tooltip
                    arrow disableFocusListener disableTouchListener placement="right"
                    title={get(obj, 'disabledTooltip')}
                    key={`keyTool-${index}`}
                  >
                    <ListItem
                      key={`key-${index}`}
                      button
                      disabled={obj.disabled}
                      onClick={obj.onClick}
                      className={classes.listItem}
                      data-apim-test={obj.text}
                    >
                      <ListItemText primary={obj.text} />
                    </ListItem>
                  </Tooltip>
                  :
                    <ListItem
                    key={`key-${index}`}
                    button
                    disabled={obj.disabled}
                    onClick={obj.onClick}
                    className={classes.listItem}
                    data-apim-test={obj.text}
                  >
                    <ListItemText primary={obj.text} />
                  </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </div>
  );
};

ActionsRaw.propTypes = {
  apiDetails: PropTypes.object,
  applications: PropTypes.arrayOf(PropTypes.object),
  userContext: PropTypes.object,
  apiUuid: PropTypes.string,
  history: shape({}),
  canEdit: PropTypes.boolean,
  canDelete: PropTypes.boolean,
  deleteApi: PropTypes.func,
  deleteApiStatus: PropTypes.string,
  setNotificationStatus: PropTypes.func,
  setNotificationMessage: PropTypes.func,
  errors: PropTypes.array,
};

ActionsRaw.displayName = "Actions";

export default ActionsRaw;
