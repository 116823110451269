import React from 'react';
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   makeStyles,
 } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import clsx from 'clsx';

export default function FormDialog(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    children,
    isOpen,
    cancelText,
    submitText,
    title,
    dialogId,
    handleClose,
    onSubmit,
    onCancel,
    submitButtonDisabled,
    hideCancelButton = false,
    isDialogContentText = true,
    paperClass,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id={dialogId}
      classes={{
        paper: clsx(paperClass, classes.paper),
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography
        className={classes.title}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {isDialogContentText &&
          <DialogContentText id="alert-dialog-content">
            { children }
          </DialogContentText>
        }
        {!isDialogContentText &&
          <div>
            {children}
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="secondary"
          className={classes.saveButton}
          disabled={!!submitButtonDisabled}
          data-apim-test="dialog-submit"
        >
          {submitText}
        </Button>
        {!hideCancelButton &&
          <Button
            onClick={onCancel}
            variant="outlined"
            className={classes.cancelButton}
            data-apim-test="dialog-cancel"
          >
            {cancelText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  dialogId: PropTypes.string,
  handleClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButtonDisabled: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  isDialogContentText: PropTypes.bool,
  paperClass: PropTypes.string,
};
