import React, { useEffect, useState } from 'react';
import {
  IconButton,
  withStyles,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  PROXY_ERROR_DEFAULT_MESSAGE,
  CLOSE_TEXT_ID,
  DIALOG_TITLE_ERROR,
} from '../../../constants';
import { processErrors } from '../../../utils';
import AlertDialog from '../../AlertDialog';
import Status from './status';
import styles from './styles';
import { getI18n } from '../../../utils/intl';


export const ProxyStatus = (props) => {
  const {
    classes = {},
    fetchProxyStatus = () => {},
    errors = [],
  } = props

  const [isShowDialogError, setIsShowDialogError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openErrorDialog = () => setIsShowDialogError(true);
  const closeErrorDialog = () => setIsShowDialogError(false);
  const { getI18nMessage } = getI18n(useIntl());

  useEffect(() => {
    localStorage.setItem('statusTimer', 0);
    setInterval( () => {
      const statusTimer = localStorage.getItem('statusTimer');
      if(statusTimer === "0") {
        fetchProxyStatus();
        localStorage.setItem('statusTimer', new Date());
      } else {
        if(new Date().getMinutes() - new Date(statusTimer).getMinutes() >= 3 ) {
          fetchProxyStatus();
          localStorage.setItem('statusTimer', new Date());
        }
      }
    }, 3000)
  },[]);

  useEffect(()=> {
    const message = processErrors(errors, PROXY_ERROR_DEFAULT_MESSAGE);
    setErrorMessage(message);
  }, [errors]);

  return (
    <div className={classes.HeaderRequestsButton}>
      <AlertDialog
          isOpen={isShowDialogError}
          title={DIALOG_TITLE_ERROR}
          description={errorMessage}
          submitText={getI18nMessage(CLOSE_TEXT_ID)}
          onClose={closeErrorDialog}
          onSubmit={closeErrorDialog}
          onCancel={closeErrorDialog}
          showCancel={false}
          dialogId={'proxy-status-error-dialog'}
        />
      {!isEmpty(errors) ?
        <IconButton
          onClick={openErrorDialog}
          data-apim-test="header-proxy-status-warning"
        >
          <WarningIcon className={classes.WarningIcon} />
        </IconButton>
      :
        <Status {...props} />
      }
    </div>
  );
}


ProxyStatus.propTypes = {
  classes: PropTypes.shape(),
  fetchProxyStatus: PropTypes.func,
  proxyStatus: PropTypes.arrayOf(PropTypes.shape()),
  errors: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(styles)(ProxyStatus);
