import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { withStyles, Link } from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";

import styles from './styles';
import {
  fetchOrganizations,
  updateProductOrgs,
  fetchTiersProduct,
  resetFetchOrgs,
} from "../../../../actions/products";
import {
  getOrgsListResults,
  getOrgsListTotalElements,
  getOrgsListTotalPages,
  getIsOrgUpdateSuccess,
  getTiersProduct,
  getIsFetchOrgs,
} from "../../../../reducers/products";
import ListContainer from "../../../list";
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24,
  GRID_ROWS_PER_PAGE_EXTRA_OPTIONS,
  ASSIGNED,
  NOT_ASSIGNED,
  ALERT_SUCCESS,
} from "../../../../constants";
import { getI18n, getI18nFormattedMessage } from "../../../../utils/intl";
import {
  FilterByName,
  FilterSeparator,
  HeaderActionButtons,
} from './controls';
import {
  Switch,
  ToolTip as ToolTipComp,
  FormActionButtons,
  AlertDialog,
  FormSelect,
} from '../../../../components';

const formatSwitch = (uuid, assignStatus, onAssignChange, classes) => (
  <div className={classes.switchAccess}>
    <Switch
      id={`${uuid}-assign`}
      checked={assignStatus === ASSIGNED}
      onChange={(e) => { onAssignChange(uuid, e) }}
    />
  </div>
);

const getLinkContent = (value, uuid) => (
  <Link underline="none" href={`/admin/console/organizations/details/${uuid}`}>
    {value}
  </Link>
);

const hasDefaultTier = (tiersList, uuid) => tiersList.find(({ tierUuid, isDefaultTier }) =>
  tierUuid === uuid && isDefaultTier)
const getDefaultTier = (tiersList) => tiersList.find(({ isDefaultTier }) => isDefaultTier);

const getTierContent = (item, onClickTier, classes, tiersList) => {
  const isDefault = hasDefaultTier(tiersList, item.tierUuid);
  return (
    <>
    <Link aria-describedby={`${item.uuid}-tier-pop`} underline="none" onClick={() => onClickTier(item.uuid)}>
      {item.tierName ? item.tierName : 'Add Tier'}
    </Link>
    {isDefault &&
      <span className={classes.tooltipContainer}>
        {getI18nFormattedMessage('label.default')}
      </span>
    }
  </>
   
  )
};

export const getOrgsListColumns = (classes, onAssignChange, selectedRows,
  onClickTier, tiersList) => [{
  id: 'organizationName',
  label: getI18nFormattedMessage('label.organization'),
  minWidth: 100,
  value: (item) => getLinkContent(item.organizationName, item.organizationUuid),
},{
  id: 'productOrgAccessStatus',
  label: (
    <ToolTipComp
      classes={classes}
      label={getI18nFormattedMessage('label.access')}
      info={<div>{getI18nFormattedMessage('label.product.orgs.assign.tooltip')}</div>}
    />
  ),
  minWidth: 50,
  value: (item) =>
    formatSwitch(item.organizationUuid, item.productOrgAccessStatus,
      onAssignChange, classes, selectedRows),
},{
  id: 'tierName',
  label: (
    <ToolTipComp
      classes={classes}
      label={getI18nFormattedMessage('label.tier')}
      info={<div>{getI18nFormattedMessage('label.product.orgs.tier.tooltip')}</div>}
    />
  ),
  minWidth: 50,
  value: (item) => getTierContent(item, onClickTier, classes, tiersList),
}];


export const AddOrganizations = (props) => {
  const {
    classes,
    productUuid,
    isLoading,
    orgsListResults = [],
    tiersList = [],
    totalElements,
    totalPages,
    isCurrentTab,
    isOrgUpdateSuccess,
    notifyMessages,
    isFetchOrgs,
    onPageChange,
  } = props;

  const [filterByName, setFilterByName] = useState('');
  const [isOpenTier, setIsOpenTier] = useState(false);
  const [filterAssign, setFilterAssign] = useState(NOT_ASSIGNED);
  const [orgsList, setOrgsList] = useState([orgsListResults]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsObject, setSelectedRowsObject] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedTierDialog, setSelectedTierDialog] = useState('');
  const [selectedTierArray, setSelectedTierArray] = useState([]);
  const [isFieldEdit, setIsFieldEdit] = useState(true);
  const [filterByNameApplied, setFilterByNameApplied] = useState(false);
  const [hasAssignedOrg, setHasAssignedOrg] = useState(false);
  const intl = getI18n(useIntl());
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24);
  const [page, setPage] = useState(0);

  const onClickTier = (uuid) => {
    setSelectedRows([uuid]);
    setIsOpenTier(true);
  }
  const onCloseTier = () => setIsOpenTier(false);

  const clearDisable = () => {
    setSelectedRows([]);
    setOrgsList([]);
    setSelectedRowCount(0);
    setFilterByName('');
    setFilterByNameApplied(false);
  }

  useEffect(() => {
    if (productUuid && isCurrentTab) {
      setFilterAssign(NOT_ASSIGNED);
      props.fetchTiersProduct(productUuid, ASSIGNED);
      props.fetchOrganizations({ uuid: productUuid, page: 0, size: rowsPerPage,
        assign: filterAssign });
    }
  }, [productUuid, isCurrentTab]);

  useEffect(() => {
    if (isOrgUpdateSuccess) {
      notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.product.updated'));
      setPage(0);
      setTimeout(() => { notifyMessages('', '')}, 2500);
      clearDisable();
      onPageChange('edit');
    }
  }, [isOrgUpdateSuccess]);

  useEffect(() => {
    if(isFetchOrgs) {
      props.resetFetchOrgs();
      setOrgsList(orgsListResults);
    }
  }, [orgsListResults, isFetchOrgs]);

  useEffect(() => {
    if (tiersList.length > 0 ) {
      const tier = getDefaultTier(tiersList);
      setSelectedTier(tier.tierUuid);
      setSelectedTierDialog(tier.tierUuid);
    }
  }, [tiersList]);

  useEffect(() => {
    if (orgsList.length > 0 ) {
      const orgListChanges = orgsList.filter(org => org.isModify === true);
      setSelectedRowCount(orgListChanges.length);
    }
  }, [selectedRows]);

  useEffect(() => {
    let isAssignedOrg = true;
    if (orgsList.length > 0 ) {
      selectedRows.forEach((id) => {
        orgsList.find(({ organizationUuid, productOrgAccessStatus }) => {
          if(organizationUuid === id && productOrgAccessStatus === NOT_ASSIGNED) {
            isAssignedOrg = false;
          }
        })
      });
    }
    setHasAssignedOrg(isAssignedOrg);
  }, [selectedRowCount, selectedRows]);



  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByNameApplied(true);
      setPage(0);
      clearDisable();
      props.fetchOrganizations({
        uuid: productUuid, page: 0, name: filterByName, size: rowsPerPage, assign: filterAssign,
      });
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchOrganizations({
      uuid: productUuid,name: filterByName, page: newPage, size: rowsPerPage, assign: filterAssign,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchOrganizations({
      uuid: productUuid, name: filterByName, page: 0, size: newRowsPerPage, assign: filterAssign,
    });
  };

  const onSelectTier = (value) => {
    setSelectedTierDialog(value);
  }

  const onAddTierSubmit = () => {
    localStorage.setItem('isUnSavedChanges-products', true);
    const orgsListArray = orgsList;
    const tier = tiersList.find(({ tierUuid }) => tierUuid === selectedTierDialog );
      orgsListArray.filter(obj => {
        if(obj.organizationUuid === selectedRows[0]) {
          obj.productOrgAccessStatus = ASSIGNED;
          obj.tierName = tier.tierName;
          obj.tierUuid = tier.tierUuid;
          obj.isModify = true; //Added isModify to identify which record has changed in the orgslist
        }
      });
    onCloseTier();
    setSelectedRows([]);
    setIsFieldEdit(true);
    setSelectedTierDialog('');
  }

  const onAssignChange = (uuid, isChange) => {
    localStorage.setItem('isUnSavedChanges-products', true);
    let selectRowsMap = selectedRows;
    const orgsListArray = orgsList;
    if(isChange){
      const isSelectedRow = selectRowsMap.find(id => id === uuid);
      !isSelectedRow ? selectRowsMap.push(uuid) : '';
      const tier = getDefaultTier(tiersList);
      orgsListArray.filter(obj => {
        if(obj.organizationUuid === uuid) {
          obj.productOrgAccessStatus = ASSIGNED;
          obj.tierName = tier.tierName;
          obj.tierUuid = tier.tierUuid;
          obj.isModify = true; //Added isModify to identify which record has changed in the orgslist
        }
      });
      setOrgsList(orgsListArray);
    } else {
      selectRowsMap = selectRowsMap.filter(id => id !== uuid);
      orgsListArray.filter(obj => {
        if(obj.organizationUuid === uuid) {
          obj.productOrgAccessStatus = NOT_ASSIGNED;
          obj.tierName = '';
          obj.tierUuid = '';
          obj.isModify = false; //updated isModify that record changes has reversed in the orgslist
        }
      });
      setOrgsList(orgsListArray);
    }
    const orgListChanges = orgsList.filter(org => org.isModify === true);
      setSelectedRowCount(orgListChanges.length);
    setSelectedRows(selectRowsMap);
    setIsFieldEdit(true);
  }

  const onAddAccessTier = () => {
    localStorage.setItem('isUnSavedChanges-products', true);
    const orgsListArray = orgsList;
    selectedRows.forEach((id) => {
      orgsListArray.filter(obj => {
        if(obj.organizationUuid === id) {
          obj.productOrgAccessStatus = ASSIGNED;
          let tier = getDefaultTier(tiersList);
          if(selectedTierArray.length > 0) {
            tier = selectedTierArray.find(({ organizationUuid }) => organizationUuid === id );
          }
          obj.tierName = tier.tierName;
          obj.tierUuid = tier.tierUuid;
          obj.isModify = true;
        }
      });
    });
    setOrgsList(orgsListArray);
    setIsFieldEdit(true);
    setSelectedRows([]);
  }

  const onSave = () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    const data = [];
    orgsList.map((org)=> {
      if(org.isModify) {
        const obj = { organizationUuid: org.uuid, tierUuid: org.tierUuid };
        data.push(obj);
      }
    });
    props.updateProductOrgs(productUuid, data);
  }

  const onCancel = () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    clearDisable();
    onPageChange('edit');
  }

  const onRemoveAccess = () => {
    const orgsListArray = orgsList;
    selectedRows.forEach((id) => {
      orgsListArray.filter(obj => {
        if(obj.organizationUuid === id) {
          obj.productOrgAccessStatus = NOT_ASSIGNED;
          obj.tierName = '';
          obj.tierUuid = '';
          obj.isModify = false; //updated isModify that record changes has reversed in the orgslist
        }
      });
    });
    setSelectedRows([]);
    setOrgsList(orgsListArray);
  }

  const onChangeTier = (value) => {
    const tiersMap = [];
    selectedRows.forEach((id) => {
      const tier = tiersList.find( ({ tierUuid }) => tierUuid === value);
      const obj = { organizationUuid: id, tierUuid: value, tierName: tier.tierName };
      tiersMap.push(obj);
    })
    setSelectedTierArray(tiersMap);
    setSelectedTier(value);
  }

  const noResultsMessage = filterByNameApplied ?
    `${intl.getI18nMessage('label.organization.filter.no.results')}` :
    intl.getI18nMessage('label.product.orgs.no.results');

  const TierSelect = () => (
    <FormSelect
      {...props}
      handleChange={onSelectTier}
      id="product-details-orgs-select-tier"
      data-apim-test="product-details-orgs-select-tier"
      data={tiersList}
      value={selectedTierDialog}
      noNoneOption
      noNativeSelect
      fieldContainerClass={classes.selectContainer}
    />
  );

  return (
    <>
      {isOpenTier &&
        <AlertDialog
          id="product-details-orgs-tier-dialog"
          isOpen={isOpenTier}
          title={intl.getI18nMessage('label.select.tier')}
          component={<TierSelect />}
          submitText={intl.getI18nMessage('label.apply.tier')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={onCloseTier}
          onSubmit={onAddTierSubmit}
          onCancel={onCloseTier}
          containerClass={classes.dialogContainer}
          submitButtonClass={classes.submitButton}
          dialogActionClass={classes.dialogActionClass}
        />
      }
      <ListContainer
        listPageId="product-details-orgs-add-list"
        isLoading={isLoading}
        showListHeader={false}
        pageClass={classes.listContent}
        pageBodyClass={classes.pageBodyClass}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter.by')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.organization')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
            />
            <FilterSeparator />
          </Fragment>
        )}
        columns={getOrgsListColumns(classes, onAssignChange, selectedRows,
          onClickTier, tiersList) || []}
        rows={orgsList}
        bulkActionsContent={
          <HeaderActionButtons
            {...props}
            tiersList={tiersList}
            bulkActionsItemsCount={selectedRows.length}
            onAddAccessTier={onAddAccessTier}
            onRemoveAccess={onRemoveAccess}
            disableAddAccess={false}
            disableRemoveAccess={selectedRowCount === 0}
            onChangeTier={onChangeTier}
            selectedTier={selectedTier}
            orgsList={orgsList}
            hasApplyTierLabel={!hasAssignedOrg}
          />
        }
        noResultsMessage={noResultsMessage}
        page={page}
        count={totalElements}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_EXTRA_OPTIONS}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowsObject={selectedRowsObject}
        setSelectedRowsObject={setSelectedRowsObject}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        hasPaginationDisabled={selectedRowCount > 0}
        paginationTooltipMessage={intl.getI18nMessage('label.organization.access.pagination.disabled.message')}
      />
      <FormActionButtons
        isContainerFooter={true}
        onNextClick={onSave}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="product-details-orgs-tab-add-form-buttons"
        containerClass={!isFieldEdit?
          classes.hideFooterContainer: classes.showFooterContainer}
        isDisabled={selectedRowCount === 0}
        assistText={
          <span>
            {selectedRowCount}  {getI18nFormattedMessage('label.changed')}
            <WarningIcon className={classes.warningIcon} />
            {getI18nFormattedMessage('label.redepolyment.required')}
          </span>
          }
      />
    </>
  )
};

AddOrganizations.propTypes = {
  classes: shape({}),
  productDetails: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  orgsListResults: arrayOf(shape({})),
  tiersList: arrayOf(shape({})),
  fetchOrganizations: func,
  productUuid: string,
  isCurrentTab: bool,
  isOrgUpdateSuccess: bool,
  isFetchOrgs: bool,
  onPageChange: func,
  updateProductOrgs: func,
  fetchTiersProduct: func,
  notifyMessages: func,
  resetFetchOrgs: func,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  orgsListResults: getOrgsListResults(state),
  totalPages: getOrgsListTotalPages(state),
  totalElements: getOrgsListTotalElements(state),
  isOrgUpdateSuccess: getIsOrgUpdateSuccess(state),
  tiersList: getTiersProduct(state),
  isFetchOrgs: getIsFetchOrgs(state),
});

const mapDispatchToProps = {
  fetchOrganizations,
  updateProductOrgs,
  fetchTiersProduct,
  resetFetchOrgs,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AddOrganizations);
