import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { withStyles, Button, Typography } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';

import AlertDialog from '../../../../../components/AlertDialog';
import {
  getDeployedStatusColor,
  getDeployedStatusLabel,
} from '../../../../../utils/deployment';
import { formatStatusMessage } from '../../../../../utils';
import {
  DEPLOYMENT_STATUS_ERROR,
  DEPLOYMENT_STATUS_NOT_DEPLOYED,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  API_STATUS_INCOMPLETE,
} from '../../../../../constants';
import styles from '../styles';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';

export const Status = ({
  apiDetails,
  details: {
    proxyUuid,
    type,
    status = DEPLOYMENT_STATUS_NOT_DEPLOYED,
    statusMessage,
  },
  updatingProxies,
  setUpdatingProxies,
  errorObj = {},
  refreshTime = 10,
  checkStatus,
  classes,
}) => {
  const intl = getI18n(useIntl());
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [timer, setTimer] = useState(parseInt(refreshTime, 10));
  const [updating, setUpdating] = useState(false);
  const errorMessage = get(errorObj, 'errorMessage');

  const stopAction = () => {
    setTimer(refreshTime);

    // Remove proxyUuid from updatingProxies
    const proxyUuidIndex = updatingProxies.indexOf(proxyUuid);
    if (proxyUuidIndex > -1) {
      setUpdatingProxies((updatingProxies, proxyUuidIndex) => {
        updatingProxies.splice(proxyUuidIndex, 1);
        return [...updatingProxies];
      });
    }

    checkStatus(proxyUuid);
  };

  useEffect(() => {
    setUpdating(updatingProxies.includes(proxyUuid));
  }, [updatingProxies, timer]);

  useEffect(() => {
    let interval = null;
    if (updating) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        if (timer === 1) {
          stopAction();
          clearInterval(interval);
        }
      }, 1000);
    } else if (!updating && timer !== refreshTime) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [updating, timer]);

  let statusLabel = '';
  let statusColor = '';
  if (errorMessage) {
    statusLabel = getI18nFormattedMessage('error.validation.error');
    statusColor = getDeployedStatusColor(DEPLOYMENT_STATUS_ERROR);
  } else {
    statusLabel =
      status === DEPLOYMENT_STATUS_ERROR
        ? getI18nFormattedMessage('error.deployment.error')
        : getDeployedStatusLabel(status);
    statusColor = getDeployedStatusColor(status);
  }

  const openStatusDialog = () => setShowStatusDialog(true);
  const closeStatusDialog = () => setShowStatusDialog(false);

  const MoreInfo = () => (
    <span className="statusBtn" data-test={'moreInfoBtn'}>
      <Button onClick={() => openStatusDialog()}>
        {getI18nFormattedMessage('label.more.info')}
      </Button>
    </span>
  );

  const CheckStatus = () => (
    <span className="statusBtn" data-test={'checkStatusBtn'}>
      <Button onClick={() => stopAction()}>
        {getI18nFormattedMessage('label.check.now')}
      </Button>
    </span>
  );

  return (
    <>
      {showStatusDialog && (
        <AlertDialog
          isOpen={showStatusDialog}
          title={intl.getI18nMessage('label.information')}
          description={errorMessage || formatStatusMessage(statusMessage)}
          submitText={intl.getI18nMessage('label.ok.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={() => closeStatusDialog()}
          onSubmit={() => closeStatusDialog()}
          onCancel={() => closeStatusDialog()}
          showCancel={false}
          dialogId="api-proxy-status-dialog"
        />
      )}
      <Typography component="div" className={classes.cardStatus}>
        {updating ? (
          <span data-test-apim="status-display-updating">
            <LensIcon className={classes.cardStatusIcon} />
            {` ${intl.getI18nMessage(
              'label.updating.status.updates.in',
            )} ${timer}${intl.getI18nMessage('label.unit.seconds')}.`}
          </span>
        ) : (
          <span>
            <span style={{ color: statusColor }} data-test-apim="status-display">
              <LensIcon className={classes.cardStatusIcon} /> {statusLabel}
            </span>
          </span>
        )}
        <div className={classes.deploymentStatusContainer} data-test-apim="status-actions-container">
          {!updating && (errorMessage || statusMessage) && <MoreInfo />}
          {type === PROXY_DEPLOYMENT_TYPE_ON_DEMAND &&
            apiDetails.portalStatus !== API_STATUS_INCOMPLETE &&
            updating && <CheckStatus />}
        </div>
      </Typography>
    </>
  );
};

Status.propTypes = {
  index: PropTypes.number,
  userContext: PropTypes.object,
  canEdit: PropTypes.bool,
  apiDetails: PropTypes.object,
  details: PropTypes.shape({
    proxyUuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    statusMessage: PropTypes.string,
    modifyTs: PropTypes.number,
    apiUuid: PropTypes.string,
    type: PropTypes.string,
  }),
  updatingProxies: PropTypes.array,
  setUpdatingProxies: PropTypes.func,
  errorObj: PropTypes.object,
  refreshTime: PropTypes.number,
  deployApi: PropTypes.func,
  redeployApi: PropTypes.func,
  undeployApi: PropTypes.func,
  checkStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(Status);
