import axios from 'axios';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import { AXIOS_DEFAULT_OPTIONS } from '../';

const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_RESET,
    isLoading,
  });

const buildQueryString = (params) => {
  let queryString = stringify(params, true);
  queryString = queryString ? `?${queryString}` : '';
  return queryString;
};

export const fetchProduct = (productUuid) => async (dispatch, getState) => {
  init(dispatch);
  const config = getConfig(getState());
  const { portal } = config;

  const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${productUuid}`;
  await axios
    .get(url, AXIOS_DEFAULT_OPTIONS)
    .then((response) =>
      dispatch({
        type: Constants.PRODUCTS_DETAILS_GET_SUCCESS,
        payload: response.data,
      }),
    )
    .catch((error) =>
      dispatch({
        type: Constants.PRODUCTS_DETAILS_GET_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const saveProducts =
  (name, description) => async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { name: name, description: description };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products`;
    await axios
      .post(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCTS_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PRODUCTS_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateProducts =
  (name, description, uuid) => async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const data = { name: name, description: description, uuid: uuid };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${uuid}`;
    await axios
      .put(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        response = { data: { uuid: uuid } };
        dispatch({
          type: Constants.PRODUCTS_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PRODUCTS_SAVE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApis = ({ uuid, name = null, page = 0, size = 12, assign, portalStatus }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      name: !isEmpty(name) ? name : undefined,
      portalStatus: portalStatus ? 'Enabled' : undefined,
      productApiAccessStatus: assign,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/access/apis-product/${uuid}?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.PRODUCTS_APIS_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.PRODUCTS_APIS_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const resetFetchOrgs = () => async (dispatch) => {
  init(dispatch);
  dispatch({
    type: Constants.PRODUCTS_ORGS_RESET_LIST,
  });
}

export const resetFetchTiersProduct = () => async (dispatch) => {
  init(dispatch);
  dispatch({
    type: Constants.PRODUCTS_TIERS_PRODUCT_RESET_LIST,
  });
}

export const fetchOrganizations = ({ uuid, name = null, page = 0, size = 12, assign }) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      organizationName: !isEmpty(name) ? name : undefined,
      productOrgAccessStatus: assign,
      page,
      size,
    }, true);

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/access/organizations-product/${uuid}?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.PRODUCTS_ORGS_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.PRODUCTS_ORGS_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateProductApis = (productUuid, data, action ) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      action,
    }, true);
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${productUuid}/apis?${params}`;
    await axios.patch(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCTS_APIS_ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.PRODUCTS_APIS_UPDATE_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const fetchProducts =
  (params = {}) =>
  async (dispatch, getState) => {
    init(dispatch);
    const defaultParams = {
      size: 24,
      page: 0,
    };
    const { searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(searchParam && { name: searchParam }),
    };
    const queryString = buildQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products${queryString}`;

    await axios
      .get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCT_LIST_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PRODUCT_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchTiers = () =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/tiers`;

    await axios
      .get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCTS_TIERS_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PRODUCTS_TIERS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const saveTiers = (tiers) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/tiers`;
    await axios.put(url, tiers, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCTS_TIERS_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.PRODUCTS_TIERS_SAVE_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };

export const updateProductOrgs = (productUuid, data, action ) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      action,
    }, true);
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${productUuid}/organizations?${params}`;
    await axios.patch(url, data, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PRODUCTS_ORGS_ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.PRODUCTS_ORGS_UPDATE_ERROR,
          payload: getValidationErrors(error),
        });
      });
};

export const fetchTiersProduct = (productUuid, assign) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      productTierAccessStatus: !isEmpty(assign) ? assign : undefined,
    }, true);

    dispatch({
      type: Constants.TIERS_PRODUCT_GET_REQUEST,
    });

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/access/tiers-product/${productUuid}?${params}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.TIERS_PRODUCT_GET_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.TIERS_PRODUCT_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const saveTiersProduct = (productUuid, tiersProduct, action) =>
  async (dispatch, getState) => {
    init(dispatch);

    const config = getConfig(getState());
    const { portal } = config;

    const params = stringify({
      action,
    }, true);
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${productUuid}/tiers?${params}`;

    await axios.patch(url, tiersProduct, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
            type: Constants.TIERS_PRODUCT_SAVE_SUCCESS,
            payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
            type: Constants.TIERS_PRODUCT_SAVE_ERROR,
            payload: getValidationErrors(error),
        }),
      );
  };

  export const deleteProduct = (productUuid) => async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
  
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/products/${productUuid}`;
    await axios
      .delete(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) =>
        dispatch({
          type: Constants.PRODUCT_DELETE_SUCCESS,
          payload: response.data,
        }),
      )
      .catch((error) =>
        dispatch({
          type: Constants.PRODUCT_DELETE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
