import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withStyles, Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import AlertDialog from '../../../../../components/AlertDialog';
import { canAPIPerformAction } from '../../../../../utils';
import {
  DEPLOYMENT_ACTION_TYPE_DEPLOY,
  DEPLOYMENT_ACTION_TYPE_REDEPLOY,
  DEPLOYMENT_ACTION_TYPE_UNDEPLOY,
  DEPLOYMENT_STATUS_NOT_DEPLOYED,
  DEPLOYMENT_STATUS_UNDEPLOYED_ERROR,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  API_STATUS_INCOMPLETE,
} from '../../../../../constants';
import styles from '../styles';
import { getI18n, getI18nFormattedMessage } from '../../../../../utils/intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { deployApi, redeployApi, undeployApi } from '../../../../../actions/api';

export const Actions = ({
  userContext,
  canEdit,
  apiDetails,
  details: {
    apiUuid,
    proxyUuid,
    type,
    status = DEPLOYMENT_STATUS_NOT_DEPLOYED,
  },
  updatingProxies,
  setUpdatingProxies,
  deployApi,
  redeployApi,
  undeployApi,
  classes,
}) => {
  const intl = getI18n(useIntl());
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');

  const startAction = (action) => {
    switch (action) {
      case DEPLOYMENT_ACTION_TYPE_DEPLOY: {
        deployApi(apiUuid, proxyUuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_REDEPLOY: {
        redeployApi(apiUuid, proxyUuid);
        break;
      }
      case DEPLOYMENT_ACTION_TYPE_UNDEPLOY: {
        undeployApi(apiUuid, proxyUuid);
        break;
      }
      default: {
        break;
      }
    }

    setUpdatingProxies((updatingProxies) => [...updatingProxies, proxyUuid]);
  };

  const openConfirmDialog = (action) => {
    setShowConfirmDialog(true);
    setConfirmAction(action);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setConfirmAction('');
  };

  const saveConfirmDialog = () => {
    startAction(confirmAction);
    closeConfirmDialog();
  };

  const DeploymentActions = () => (
    <span>
      {status === DEPLOYMENT_STATUS_NOT_DEPLOYED &&
        canAPIPerformAction(userContext, apiDetails, canEdit, 'CREATE') && (
          <span data-test={'deployBtn'}>
            <Button
              onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_DEPLOY)}
              startIcon={<ArrowForwardIcon />}
            >
              {getI18nFormattedMessage('label.deploy')}
            </Button>
          </span>
        )}
      {status !== DEPLOYMENT_STATUS_NOT_DEPLOYED &&
        canAPIPerformAction(userContext, apiDetails, canEdit, 'DELETE') && (
          <span data-test={'undeployBtn'}>
            <Button
              onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_UNDEPLOY)}
              startIcon={<ArrowBackIcon />}
            >
              {getI18nFormattedMessage('label.undeploy')}
            </Button>
          </span>
        )}
      {status !== DEPLOYMENT_STATUS_NOT_DEPLOYED &&
        status !== DEPLOYMENT_STATUS_UNDEPLOYED_ERROR &&
        canAPIPerformAction(userContext, apiDetails, canEdit, 'UPDATE') && (
          <span data-test={'redeployBtn'}>
            <Button
              onClick={() => openConfirmDialog(DEPLOYMENT_ACTION_TYPE_REDEPLOY)}
              startIcon={<AutorenewIcon />}
            >
              {getI18nFormattedMessage('label.redeploy')}
            </Button>
          </span>
        )}
    </span>
  );

  return (
    <Fragment>
      {showConfirmDialog && (
        <AlertDialog
          isOpen={showConfirmDialog}
          title={intl.getI18nMessage('warning.status')}
          description={intl.getI18nMessage('label.action.confirmation.text')}
          submitText={intl.getI18nMessage('label.ok.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          onClose={() => closeConfirmDialog()}
          onSubmit={() => saveConfirmDialog()}
          onCancel={() => closeConfirmDialog()}
          dialogId="api-proxy-action-dialog"
        />
      )}
      <Typography component="div" className={classes.cardStatus}>
        <div className={classes.deploymentButtonsContainer}>
          {type === PROXY_DEPLOYMENT_TYPE_ON_DEMAND &&
            apiDetails.portalStatus !== API_STATUS_INCOMPLETE && (
              <span data-test={'api-deployment-actions'}>
                {!updatingProxies.includes(proxyUuid) && <DeploymentActions />}
              </span>
            )}
        </div>
      </Typography>
    </Fragment>
  );
};

const mapDispatchToProps = {
  deployApi,
  undeployApi,
  redeployApi,
};

Actions.propTypes = {
  index: PropTypes.number,
  userContext: PropTypes.object,
  canEdit: PropTypes.bool,
  apiDetails: PropTypes.object,
  details: PropTypes.shape({
    proxyUuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    statusMessage: PropTypes.string,
    modifyTs: PropTypes.number,
    apiUuid: PropTypes.string,
    type: PropTypes.string,
  }),
  updatingProxies: PropTypes.array,
  setUpdatingProxies: PropTypes.func,
  errorObj: PropTypes.object,
  refreshTime: PropTypes.string,
  deployApi: PropTypes.func,
  redeployApi: PropTypes.func,
  undeployApi: PropTypes.func,
  checkStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Actions);
