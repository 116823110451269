import { get } from 'lodash';

const styles = ({ color, fontSize, typography, theme }) => ({
  appHeaderContainer: {
    padding: '0 36px',
  },
  pageTitle: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'pageTitle')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    lineHeight: '48px',
    margin: '5px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50%',
  },
  statusBar: {
    margin: '10px 0px',
  },
  nameDiv: {
    display: 'flex',
    marginBottom: 35,
  },
  editIcon: {
    fill: get(color, 'primaryButtonBackground'),
    minWidth: 0,
    width: 35,
    height: 35,
    padding: 0,
    marginLeft: 5,
    marginTop: 12,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  nameField: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
    width: '60%',
  },
  textFieldClass: {
    '& div': {
      color: `${get(color, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'pageTitle')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontWeight: 'bold',
      lineHeight: '48px',
    },
  },
  statusIcon: {
    fontSize: get(fontSize, 'smallText'),
  },
  status: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '15px',
    verticalAlign: 'middle',
    letterSpacing: 0,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  available: {
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  pending: {
    '&:before': {
      backgroundColor: get(color, 'pending'),
    },
  },
  unavailable: {
    '&:before': {
      backgroundColor: get(color, 'error'),
    },
  },
  noStatus: {
    '&:before': {
      backgroundColor: get(color, 'disabledBackground'),
    },
  },
  grayText: {
    color: get(color, 'smallText'),
    '&:before': {
      content: '"Client ID: "',
      textTransform: 'uppercase',
    },
  },
  deleteButton: {
    textTransform: 'none',
    color: `${get(color, 'error')} !important`,
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor:  `${get(color, 'error')} !important`,
    marginLeft: 'auto',
    float: 'right',
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
    display: 'inline-flex',
    height: 'fit-content',
    marginTop: 12,
  },
  cancelButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonBackground'),
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
    padding: 5,
    marginLeft: 10,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  reviewRequestButton: {
    marginLeft: 20,
  },
  disabledClass: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '& a': {
      color: `${get(color, 'gray')} !important`,
    },
  },
  statusConfirmContainer: {
    display: 'inline-flex',
  },
  deleteConfirmContainer: {
    display: 'inline-flex',
    marginLeft: 'auto',
  },
  cancelIcon: {
    marginLeft: 3,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  warningIcon: {
    fill: get(color, 'warning'),
    marginLeft: 5,
    verticalAlign: 'middle',
  },
  errorBackgroundButton: {
    backgroundColor: get(color, 'error'),
    color: get(color, 'bodyBackground'),
    '&:hover': {
      backgroundColor: get(color, 'borderColor'),
    },
    '&:focus': {
      backgroundColor: get(color, 'borderColor'),
    },
  },
  proxyAlertSubmitButton: {
    backgroundColor: `${get(color, 'error')} !important`,
    borderColor: `${get(color, 'error')} !important`,
  },
});

export default styles;
