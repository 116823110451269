import { get } from 'lodash';
const styles = ({ breakpoint, color }) => ({
  pageFilterAndSort: {
    justifyContent: 'start',
  },
  textFiltersContainer: {
    display: 'contents',
  },
  filterField: {
    maxWidth: '12%',
    width: 100,
    marginRight: 5,
  },
  statusField: {
    display: 'flex',
  },
  statusIcon: {
    height: 18,
    marginRight: 4,
    verticalAlign: 'middle',
    width: 18,
  },
  available: {
    color: get(color, 'success'),
  },
  pending: {
    color: get(color, 'warning'),
  },
  disabled: {
    color: get(color, 'disabledBackground'),
  },
  rejected: {
    color: get(color, 'error'),
  },
  searchBarContainer: {
    marginBottom: 5,
    padding: '10px 0',
  },
  searchBarContainerWrapper: {
    alignSelf: 'flex-end',
    paddingBottom: 17,
    marginRight: 10,
    display: 'block',
    maxWidth: '15%',
  },
  autoCompleteRoot: {
    height: 36,
    borderRadius: 4,
  },
  autoCompleteInputRoot: {
    borderRadius: 4,
  },
  autoCompleteFocused: {
    boxShadow: '0px 0px',
  },
  [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    textFiltersContainer: {
      width: '100%',
    },
    searchBarContainerWrapper: {
      maxWidth: '73%',
      paddingBottom: 0,
    },
    filterField: {
      maxWidth: '75%',
      width: '75%',
    },
  },
  '& button:focus': {
    outlineWidth: '1px !important',
    outlineOffset: '1px !important',
    outlineStyle: 'solid !important',
  },
});

export default styles;
