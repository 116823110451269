import React, { createContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Link, Button } from '@material-ui/core';
import { map, lowerCase, upperFirst, isEmpty, get, isArray } from 'lodash';
import HelpIcon from '@material-ui/icons/Help';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { uniq } from 'lodash';
import {
  fetchOrgApiAccess,
  updateApisAccessWithOrg,
  updateApiRateLimitQuota,
} from '../../../../actions/organization';
import { fetchAllTags } from '../../../../actions/api';
import { fetchList } from '../../../../actions/rateQuota';
import { getStatusColor } from '../../../Api/List/utils';
import {
  getIsApiAccessListError,
  getIsApiAccessListLoading,
  getOrgAPIAccessList,
  getOrgAPIAccessListTotalPages,
  getOrgAPIAccessListTotalElements,
  isUpdatingOrgApisAccessSuccess,
  updatingOrgApisAccessError,
  isUpdatingOrgApisRateQuotaSuccess,
  updatingOrgApisRateQuotaError,
} from '../../../../reducers/organization';
import { getListResults } from '../../../../reducers/rateQuota';
import {
  FormButtonGroup, Switch, AlertDialog, FormAutoCompleteCombo,
} from '../../../../components';
import { getAllPortalTags } from '../../../../reducers/api';

import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ALERT_SUCCESS,
  ALERT_ERROR,
  QUOTA_ASSIGNMENT_API_ORG,
  ACCESS_TYPE_NONE,
} from '../../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { getConfig } from '../../../../reducers/portalConfig';
import useNotification from '../../../../hooks/useNotification';
import {
  FilterByName,
  FilterByAccessStatus,
  Tags,
  HeaderActionButtons,
  TooltipIcon,
} from './controls';
import { FilterByTags } from '../../../Api/List/controls';
export const OrgAPIAccessListContext = createContext();
export const OrgAPIAccessList = (props) => {
  const {
    allTags,
    classes,
    orgUuid,
    isLoading,
    totalPages,
    totalElements = 0,
    defaultSelectedRows = [],
    apiAccessResults,
    fetchAllTags,
    fetchList,
    rateLimitQuotas,
    updateApisAccessWithOrg,
    updatingOrgApisAccessSuccess,
    updateApiRateLimitQuota,
    updatingOrgApiAccessError,
    updatingOrgApisRateQuotaSuccess,
    updatingOrgApiRateQuotaError,
    isLoadingError,
  } = props;

  const formatAccess = (access) => upperFirst(lowerCase(access));
  const formatApiStatus = (status) => {
    const statusIconStyle = {
      fill: getStatusColor(status),
    };
    return (
      <span className={classes.apiStatusField}>
        <FiberManualRecordIcon style={statusIconStyle} className={classes.statusIcon} />
        <span>{upperFirst(lowerCase(status))}</span>
      </span>);
  }
  const formatVisibility = (item) => {
    const accessStatus = item.apiOrgAccessStatus;
    if(accessStatus === 'PUBLIC' || accessStatus === 'MANAGED' || accessStatus === 'GRANTED_PRODUCT') {
      return (<TooltipIcon classes={classes}
        tooltipLabel={getI18nFormattedMessage(`label.organization.apiaccess.${accessStatus.toLowerCase()}.tick.tooltip`)}
        content={formatSwitch(item.uuid, accessStatus)}
        />
      );
    } else {
      return formatSwitch(item.uuid, accessStatus);
    }
  }
  const formatSwitch = (uuid, accessStatus) => (<div className={classes.switchAccess}><Switch
    id={`${uuid}-access`}
    checked={accessStatus === 'GRANTED_DIRECT' || accessStatus === 'PUBLIC' || accessStatus === 'MANAGED' || accessStatus === 'GRANTED_PRODUCT'}
    onChange={() => {
      onSwitchVisibility([uuid], orgUuid, accessStatus === 'GRANTED_DIRECT'? 'remove': 'add');
    }}
    disabled={accessStatus === 'PUBLIC' || accessStatus === 'MANAGED' || accessStatus === 'GRANTED_PRODUCT'}
  /></div>);

  const formatName = (item) => {
    const linkNameElement = getLinkContent(item.apiName, item.apiUuid)
    if (item.apiOrgAccessStatus === 'MANAGED') {
      return <div>{linkNameElement}   <span className={classes.managedContainer}>
        {getI18nFormattedMessage('label.organization.apiaccess.managed')}
      </span></div>;
    }
    return linkNameElement;
  }

  const getLinkContent = (value, uuid) => (
    <Link href={`/publish/apis/details/${uuid}`}>
      {value}
    </Link>
  );

  const getRateQuotaContent = (item, rateLimitQuotas,
    classes, onAddRateLimitLink) => {
    const defaultRateQuota = rateLimitQuotas.length > 0
      && rateLimitQuotas[0].uuid === item.rateQuotaUuid;

    return(
      <div>
        { item.apiOrgAccessStatus === ACCESS_TYPE_NONE ?
          ''
          :
          <>
            {item.rateQuotaName ?
            <Button
              className={classes.defaultButton}
              data-apim-test="alert-dialog-rateQuota"
              id='alert-action-rateQuota' data-layer7-test='alert-action-rateQuota'
              onClick={() => onAddRateLimitLink(item.uuid)}
              variant="text"
              color="secondary"
            >
              {item.rateQuotaName}
            </Button>
            :
              'N/A'
            }
            {defaultRateQuota &&
              <span className={classes.tooltipContainer}>
                {getI18nFormattedMessage('label.default')}
              </span>
            }
          </>
        }
      </div>
    );
  }

  const getOrgAPIAccessListColumns = (rateLimitQuotas, onAddRateLimitLink) => [{
    id: 'name',
    label: getI18nFormattedMessage('label.organization.apiaccess.apiname'),
    minWidth: 200,
    value: (item) => formatName(item),
    }, {
      id: 'tags',
      label: getI18nFormattedMessage('label.organization.apiaccess.tags'),
      minWidth: 250,
      value: Tags,
    }, {
      id: 'apiStatus',
      label: getI18nFormattedMessage('label.organization.apistatus.column.title'),
      minWidth: 50,
      value: (item) => formatApiStatus(item.apiStatus),
    }, {
      id: 'apiPublishedByPortal',
      label: getI18nFormattedMessage('label.api.publish.source'),
      minWidth: 100,
      value: (item) => item.apiPublishedByPortal ?
        intl.getI18nFormattedMessage('label.api.list.value.source.portal')
      :
        intl.getI18nFormattedMessage('label.api.list.value.source.gateway'),
    }, {
      id: 'access',
      label: getI18nFormattedMessage('label.organization.apiaccess.access'),
      minWidth: 50,
      value: (item) => formatAccess(item.apiAccessStatus),
    }, {
      id: 'visibility',
      label: (
        <TooltipIcon classes={classes} label={getI18nFormattedMessage('label.organization.apiaccess.visibility')}
              tooltipLabel={getI18nFormattedMessage('label.organization.apiaccess.visibility.head.tooltip')}
              content={<HelpIcon className={classes.helpIcon} />}/>
      ),
      minWidth: 100,
      value: (item) => formatVisibility(item),
  }, {
      id: 'rateQuotaName',
      secondaryId: 'rateQuotaUuid',
      label: (
        <TooltipIcon classes={classes} label={getI18nFormattedMessage('label.api.per.org.limit')}
              tooltipLabel={getI18nFormattedMessage('label.api.per.org.limit.head.tooltip')}
              content={<HelpIcon className={classes.helpIcon} />}/>
      ),
      minWidth: 200,
      value: (item) => {
        return getRateQuotaContent(item, rateLimitQuotas,
          classes, onAddRateLimitLink);
      },
  }];

  const intl = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [appliedNameFilter, setAppliedNameFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows);
  const [selectedRowsObject, setSelectedRowsObject] = useState([]);
  const [isDisableAddAccess, setDisableAddAcccess] = useState(true);
  const [isDisableRemoveAccess, setDisableRemoveAcccess] = useState(true);
  const [isDisableAddRateQuota, setDisableAddRateQuota] = useState(true);
  const [isDisableDefaultAddRateQuota, setDisableDefaultAddRateQuota] = useState(true);
  const [hasRateQuotaActions, setHasRateQuotaActions] = useState(true);
  const [undoAction, setUndoAction] = useState('');
  const [undoPayload, setUndoPayload] = useState([]);
  const [filterByName, setFilterByName] = useState('');
  const [filterByTag, setFilterByTag] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState('any');
  const [filterByOrgApiAccess, setFilterByOrgApiAccess] = useState('All');
  const [filterByOrgApiAccessValue, setFilterByOrgApiAccessValue] = useState();
  const [selectedRowsMap, setSelectedRowsMap] = useState({});
  const [selectedRowsMapRateQuota, setSelectedRowsMapRateQuota] = useState({});
  const [selectedRowsMapDefaultRQ, setSelectedRowsMapDefaultRQ] = useState({});
  const [showRateLimitDialog, setShowRateLimitDialog] = useState(false);
  const [selectedRateLimitQuota, setSelectedRateLimitQuota] = useState({});
  
  const [notification, setNotification] = useNotification({ status: '', message: '' });
  const allTagsOptions = allTags.map(({ name }) => ({ value: name, label: name }));
  const apiAccessresults = map(apiAccessResults, (item) => ({ ...item, uuid: item.apiUuid }));
  const disabledRows = apiAccessResults ? apiAccessResults
     .filter(item => item.apiOrgAccessStatus === 'PUBLIC' && !item.apiPublishedByPortal)
     .map(item => item.apiUuid) : [];
  
  useEffect(() => {
    props.fetchOrgApiAccess({
      page: 0,
      size: GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
      orgUuid: orgUuid,
    });
    return () => { };
  }, []);
  useEffect(() => {
    fetchAllTags();
    fetchList({ filterByLevel: QUOTA_ASSIGNMENT_API_ORG, page: 0, rowsPerPage: 2000 });
  }, []);
  useEffect(() => {
    applyFilter();
  },[filterByTag, filterByStatus, filterByOrgApiAccess]);
  useEffect(() => {
    if(selectedRows.length > 0) {
      const selectedRowsMapLocal = selectedRowsMap;
      const selectedRowsMapRateQuotaLocal = selectedRowsMapRateQuota;
      const selectedRowsMapDefaultRQLocal = selectedRowsMapDefaultRQ;
    
      apiAccessResults.filter(item =>
        selectedRows.includes(item.apiUuid))
            .forEach(item => selectedRowsMapLocal[item.apiUuid] = item.apiOrgAccessStatus);
      //When user deselects the already selected checkbox.
      if(selectedRows.length <= Object.keys(selectedRowsMapLocal).length) {
        Object.keys(selectedRowsMapLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapLocal[key];
          }
        });
      }
      setDisableAddAcccess(!Object.keys(selectedRowsMapLocal).some(item =>
        selectedRowsMap[item] === 'NONE'));
      setDisableRemoveAcccess(!Object.keys(selectedRowsMapLocal).some(item =>
        selectedRowsMap[item] === 'GRANTED_DIRECT'));
      setSelectedRowsMap(selectedRowsMapLocal);

      //Rate&Quota add Rate & Quota
      apiAccessResults.filter(item =>
        selectedRows.includes(item.apiUuid))
            .forEach(item =>
              selectedRowsMapRateQuotaLocal[item.apiUuid] = !item.apiPublishedByPortal);

      apiAccessResults.filter(item =>
        selectedRows.includes(item.apiUuid))
            .forEach(item => selectedRowsMapDefaultRQLocal[item.apiUuid] = item.rateQuotaUuid);

      //When user deselects the already selected checkbox.
      if(selectedRows.length <= Object.keys(selectedRowsMapRateQuotaLocal).length) {
        Object.keys(selectedRowsMapRateQuotaLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapRateQuotaLocal[key];
          }
        });
        Object.keys(selectedRowsMapDefaultRQLocal).forEach(key => {
          if(!selectedRows.includes(key)) {
            delete selectedRowsMapDefaultRQLocal[key];
          }
        });
      }
      let defaultDisable = true;
      Object.keys(selectedRowsMapRateQuotaLocal).some(item => {
        if(selectedRowsMapRateQuota[item] === false ) {
          defaultDisable = false;
        }
      });
      setDisableAddRateQuota(defaultDisable);
      if(rateLimitQuotas.length > 0 ) {
        setDisableDefaultAddRateQuota(!Object.keys(selectedRowsMapDefaultRQLocal).some(item =>
          selectedRowsMapDefaultRQ[item] !== rateLimitQuotas[0].uuid &&
          selectedRowsMapDefaultRQ[item] !== null));
      }
      setSelectedRowsMapRateQuota(selectedRowsMapRateQuotaLocal);
      setSelectedRowsMapDefaultRQ(selectedRowsMapDefaultRQLocal);
      setHasRateQuotaActions(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (isLoadingError) {
      setNotification({
        status: ALERT_ERROR,
        message: intl.getI18nMessage('error.organization.apiaccess.list.fetch'),
      });
    }
  }, [isLoadingError]);
  useEffect(() => {
    if (!isEmpty(updatingOrgApiAccessError) || !isEmpty(updatingOrgApiRateQuotaError)) {
      const errorMsg = !isEmpty(updatingOrgApiAccessError) ? updatingOrgApiAccessError
        : updatingOrgApiRateQuotaError;
      const msg = errorMsg.map(({ error, message }) =>
        error ? isArray(error) ? error.join(', ') : error : message);
      setNotification({
        status: ALERT_ERROR,
        message: msg,
      });
      window.setTimeout(() => setNotification(), 3000);
    }
  }, [updatingOrgApiAccessError, updatingOrgApiRateQuotaError ]);

  useEffect(() => {
    if (updatingOrgApisAccessSuccess || updatingOrgApisRateQuotaSuccess ) {
      applyFilter();
      setSelectedRows([]);
      setSelectedRowsMap({});
      setSelectedRowsMapDefaultRQ({});
      setSelectedRowsMapRateQuota({});
      const onClickUndo = () => {
        if(updatingOrgApisAccessSuccess) {
          updateApisAccessWithOrg(undoPayload, orgUuid, undoAction);
        } else if(updatingOrgApisRateQuotaSuccess) {
          updateApiRateLimitQuota('', undoPayload, undoAction);
        }
      };
      const msg = updatingOrgApisAccessSuccess ? intl.getI18nMessage('label.organization.apiaccess.updated.notification')
        : intl.getI18nMessage('label.organization.ratequota.updated.notification')
      setNotification({
        status: ALERT_SUCCESS,
        message: (
          <>
            <span>{msg}</span>
            <Button onClick={onClickUndo}>
              {intl.getI18nMessage('label.undo')}
            </Button>
          </>
        ),
      });
      window.setTimeout(() => setNotification(), 3000);
    }
  }, [updatingOrgApisAccessSuccess, updatingOrgApisRateQuotaSuccess]);
  const applyFilter = (pageNum) => {
    setPage(pageNum || 0);
    props.fetchOrgApiAccess({
      orgUuid: orgUuid,
      apiName: filterByName,
      apiTags: filterByTag,
      apiAccessStatus: filterByStatus,
      orgApiAccess: filterByOrgApiAccessValue,
      page: pageNum || 0,
      size: rowsPerPage,
    });
    props.fetchAllTags();
  };
  const anyFilterApplied = () =>
      (filterByName && filterByName !== '') ||
      (filterByTag && filterByTag.length > 0) ||
      filterByStatus !== 'any' ||
      filterByOrgApiAccess !== 'All';

  const handleBlur = () => {
    if (appliedNameFilter !== filterByName) {
      applyFilter();
      setAppliedNameFilter(filterByName);
    }
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter' && appliedNameFilter !== filterByName) {
      applyFilter();
      setAppliedNameFilter(filterByName);
    }
  };
  const onAccessStatusChange = (val) => {
    setFilterByStatus (val);
  };
  const onOrgApiAccessChange = (val) => {
    setFilterByOrgApiAccess(val);
    let filterParam = undefined;
    if (val === 'Access') {
      filterParam = 'PUBLIC,MANAGED,GRANTED_DIRECT,GRANTED_PRODUCT';
    } else if (val === 'NoAccess') {
      filterParam = 'NONE';
    }
    setFilterByOrgApiAccessValue(filterParam);
  };
  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    applyFilter(newPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };
  const onTagsSelectionChange = (items) => {
    setFilterByTag(uniq(map(items, item => item.value)));
  };
  const onAddAccess = () => {
    setUndoAction('remove');
    const rowsToAdd = selectedRows.filter(item => selectedRowsMap[item] === 'NONE');
    setUndoPayload(rowsToAdd);
    updateApisAccessWithOrg(rowsToAdd, orgUuid, 'add');
  };
  const onRemoveAccess = () => {
    setUndoAction('add');
    const rowsToRemove = selectedRows.filter(item => selectedRowsMap[item] === 'GRANTED_DIRECT');
    setUndoPayload(rowsToRemove);
    updateApisAccessWithOrg(rowsToRemove, orgUuid, 'remove');
  };
  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchOrgApiAccess({
      page: 0,
      size: newRowsPerPage,
      orgUuid: orgUuid,
    });
  };
  const onClickTag = (tag) => {
    setFilterByTag(
      uniq([...filterByTag, tag]),
    );
  }
  const onSwitchVisibility = (rowsToUpdate, orgUuid, action) => {
    setUndoAction(action === 'remove' ? 'add': 'remove');
    setUndoPayload(rowsToUpdate);
    updateApisAccessWithOrg(rowsToUpdate, orgUuid, action);
  };

  const onAddRateLimitLink = (uuid) => {
    const accessData = apiAccessResults.filter(item => item.apiUuid === uuid);
    setSelectedRows([uuid]);
    setSelectedRowsObject(accessData);
    setShowRateLimitDialog(true);
  };

  const onAddRateLimit = () => {
    setShowRateLimitDialog(true);
  };

  const onRateLimitSelect = (rateLimitQuota) => {
    setSelectedRateLimitQuota(rateLimitQuota);
  };

  const onAddRateLimitSubmit = () => {
    setUndoAction('remove');
    const rqUuid = rateLimitQuotas.length > 0 ? rateLimitQuotas[0].uuid : null;
    const rowsToAdd = selectedRows.filter(item => selectedRowsMapRateQuota[item] !== rqUuid
      && selectedRowsMapRateQuota[item] !== true );
    setUndoPayload(rowsToAdd);
    const data = [];
    rowsToAdd.map(item => {
      const payload = {
        apiUuid: item,
        rateQuotaUuid: get(selectedRateLimitQuota, 'uuid'),
      }
      data.push(payload);
    });
    setShowRateLimitDialog(false);
    updateApiRateLimitQuota(orgUuid, data, 'add');
  };

  const closeRateLimitDialog = () => {
    setShowRateLimitDialog(false);
  };

  const onRemoveRateLimit = () => {
    setUndoAction('add');
    const rqUuid = rateLimitQuotas.length > 0 ? rateLimitQuotas[0].uuid : null;
    const rowsToRemove = selectedRows.filter(item => selectedRowsMapDefaultRQ[item] !== rqUuid &&
      selectedRowsMapDefaultRQ[item] !== null);
    setUndoPayload(rowsToRemove);
    const data = [];
    rowsToRemove.map(item => {
      const payload = {
        apiUuid: item,
        rateQuotaUuid: selectedRowsMapDefaultRQ[item],
      }
      data.push(payload);
    });
    setShowRateLimitDialog(false);
    updateApiRateLimitQuota(orgUuid, data, 'remove');
  };


  const noResultsMessage = anyFilterApplied ?
      intl.getI18nMessage('label.organization.apiaccess.filter.no.results') :
      intl.getI18nMessage('label.organization.apiaccess.no.results');
  
  const RateLimitQuotaSelect = () => (
    <FormAutoCompleteCombo
      data={rateLimitQuotas}
      selectedValues={selectedRateLimitQuota}
      onChange={(e, value) => onRateLimitSelect(value)}
      isSecondaryText={true}
      loading={true}
      id="organization-apiaccess-select-rate-limit-quota"
      inputLabel="Select Rate Limit Quota"
    />
  );

  return (
    <OrgAPIAccessListContext.Provider value={{ onClickTag }}>
      {showRateLimitDialog &&
        <AlertDialog
          id="organization-apiaccess-list-dialog"
          isOpen={showRateLimitDialog}
          title={intl.getI18nMessage('label.rate.quota.assign.title')}
          component={<RateLimitQuotaSelect />}
          submitText={intl.getI18nMessage('label.save.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          defaultText={intl.getI18nMessage('label.restore.default')}
          onDefault={onRemoveRateLimit}
          showDefault={true}
          onClose={closeRateLimitDialog}
          onSubmit={onAddRateLimitSubmit}
          onCancel={closeRateLimitDialog}
          containerClass={classes.dialogContainer}
          submitButtonClass={classes.submitButton}
          dialogActionClass={classes.dialogActionClass}
        />
      }
      <ListContainer
        listPageId="organization-apiaccesslist-page"
        isLoading={isLoading}
        notificationId="apis-org-access-notifications"
        notificationStatus={notification.status}
        setNotificationStatus={(status) => setNotification({ status, message: '' })}
        notificationMessage={notification.message}
        setNotificationMessage={(message) => setNotification({ status: '', message })}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        columns={getOrgAPIAccessListColumns(rateLimitQuotas, onAddRateLimitLink)}
        rows={apiAccessresults}
        filterAndSortContent={(
          <>
            <div className={classes.textFiltersContainer}>
              <FilterByName
                fieldContainerClass={classes.textFieldContainer}
                name={intl.getI18nMessage('label.filter')}
                value={filterByName}
                placeholder={intl.getI18nMessage('label.org.apilist.filter.name.placeholder')}
                handleChange={val=>setFilterByName(val)}
                onKeyPress={onFilterByNameKeyPress}
                handleBlur={handleBlur}
              />
              <FilterByTags
                fieldContainerClass={classes.tagsContainer}
                name="tags"
                onChange={onTagsSelectionChange}
                noOptionsMessage={() => intl.getI18nMessage('label.application.api.tag.nooptions.text')}
                optionsData={allTagsOptions}
                placeholder={intl.getI18nMessage('label.application.api.tag.placeholder.text')}
                value={filterByTag}
              />
              <FilterByAccessStatus
                fieldContainerClass={classes.textFieldContainer}
                name={intl.getI18nMessage('label.filter')}
                value={filterByStatus}
                handleChange={onAccessStatusChange}
                hideLabel
              />
            </div>
            <FormButtonGroup
              id={'org-api-access'}
              name={ intl.getI18nMessage('label.org.apilist.filter.access.title') }
              value={filterByOrgApiAccess}
              data={[
                { uuid: 'All', name: intl.getI18nMessage('label.org.apilist.filter.access.all.text') },
                { uuid: 'Access', name: intl.getI18nMessage('label.org.apilist.filter.access.access.text') },
                { uuid: 'NoAccess', name: intl.getI18nMessage('label.org.apilist.filter.access.no.access.text') },
              ]}
              handleChange={(value) => { onOrgApiAccessChange(value); }}
              filedContainerClass={classes.fieldContainer}
            />
          </>
        )}
        bulkActionsContent={
          <HeaderActionButtons
            {...props}
            fieldContainerClass={classes.fieldContainer}
            bulkActionsItemsCount={selectedRows.length}
            classes={classes}
            addAccess={onAddAccess}
            removeAccess={onRemoveAccess}
            disableAddAccess={isDisableAddAccess}
            disableRemoveAccess={isDisableRemoveAccess}
            disableAddRateQuota={isDisableAddRateQuota}
            disableDefaultAddRateQuota={isDisableDefaultAddRateQuota}
            hasRateQuotaActions={hasRateQuotaActions}
            onAddRateLimit={onAddRateLimit}
            onRemoveRateLimit={onRemoveRateLimit}
          />
        }
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowsObject={selectedRowsObject}
        setSelectedRowsObject={setSelectedRowsObject}
        disabledRows={disabledRows}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
        classes={classes}
      />
    </OrgAPIAccessListContext.Provider>
    );
};

OrgAPIAccessList.propTypes = {
  classes: object,
  orgUuid: string,
  isLoading: bool,
  isError: bool,
  totalPages: number,
  totalElements: number,
  apiAccessResults: arrayOf(object),
  defaultSelectedRows: arrayOf(string),
  fetchOrgApiAccess: func,
  allTags: arrayOf(string),
  fetchAllTags: func,
  config: object,
  updateApisAccessWithOrg: func,
  fetchList: func,
  updateApiRateLimitQuota: func,
  updatingOrgApisAccessSuccess: bool,
  updatingOrgApiAccessError: object,
  rateLimitQuotas: arrayOf(object),
  updatingOrgApisRateQuotaSuccess: bool,
  updatingOrgApiRateQuotaError: object,
  isLoadingError: bool,
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  isLoadingError: getIsApiAccessListError(state),
  isLoading: getIsApiAccessListLoading(state),
  totalPages: getOrgAPIAccessListTotalPages(state),
  totalElements: getOrgAPIAccessListTotalElements(state),
  apiAccessResults: getOrgAPIAccessList(state),
  allTags: getAllPortalTags(state),
  updatingOrgApisAccessSuccess: isUpdatingOrgApisAccessSuccess(state),
  updatingOrgApiAccessError: updatingOrgApisAccessError(state),
  updatingOrgApisRateQuotaSuccess: isUpdatingOrgApisRateQuotaSuccess(state),
  updatingOrgApiRateQuotaError: updatingOrgApisRateQuotaError(state),
  rateLimitQuotas: getListResults(state),
});

const mapDispatchToProps = {
  fetchOrgApiAccess,
  fetchAllTags,
  updateApisAccessWithOrg,
  fetchList,
  updateApiRateLimitQuota,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(OrgAPIAccessList);
