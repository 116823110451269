import React from 'react';

import { FormSelect, FormTextField } from '../../../../components';

export const FilterByName = (props) => (
  <FormTextField
    {...props}
    id="app-apiproducts-filter-by-name"
    data-apim-test="app-apiproducts-filter-by-name"
  />
);

export const FilterByType = (props) => (
  <FormSelect
    {...props}
    id="app-apiproducts-filter-by-type"
    data-apim-test="app-apiproducts-filter-by-type"
    noNativeSelect
  />
);
