import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import {
  withStyles,
  Grid,
  Divider,
 } from '@material-ui/core';
 import clsx from 'clsx';
import { bool, shape, arrayOf, string, func } from 'prop-types';

import {
  AlertMessages,
  AlertDialog,
  Loading,
  ErrorContainer,
} from '../components';
import { getI18n } from '../utils/intl';
import styles from './details.styles';

export const Details = (props) => {
  const {
    classes,
    detailsPageId,
    dialogId,
    isDialogOpen,
    onDialogClose,
    dialogSubmitText,
    onDialogSubmit,
    isLoading,
    divider=true,
    notificationId,
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    errors=[],
    header,
    actions,
    pending,
    tabs,
    page,
    footer,
    notificationContainerClass,
    hasNotificationIcon = false,
    isActionsEnabled = true,
    pageHeaderRef,
  } = props;

  const intl = getI18n(useIntl());

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  return (
    <div
      className={classes.content}
      id={detailsPageId} data-apim-test={detailsPageId} data-layer7-test={detailsPageId}
      ref={pageHeaderRef}
    >
      <AlertDialog
        isOpen={isDialogOpen}
        title={intl.getI18nMessage('label.dialog.unsaved.changes.title')}
        description={intl.getI18nMessage('label.dialog.unsaved.changes.description')}
        submitText={dialogSubmitText}
        cancelText={intl.getI18nMessage('label.dialog.unsaved.changes.cancel')}
        onClose={onDialogClose}
        onSubmit={onDialogSubmit}
        onCancel={onDialogClose}
        dialogId={dialogId}
      />
      {isLoading && <Loading pageLoader /> }
      {notificationMessage &&
        <AlertMessages
          id={notificationId}
          variant={notificationStatus}
          message={notificationMessage}
          containerClass={clsx(notificationContainerClass,classes.notificationWidthClass)}
          onClose={() => notifyMessages('', '')}
          hasNotificationIcon={hasNotificationIcon}
        />
      }
      {errors.length > 0 &&
        <div className={classes.errorContainer}>
          <ErrorContainer errors={errors} />
        </div>
      }
      <Fragment>
        <Grid container justify="space-between">
          <Grid item md={isActionsEnabled ? 10 : 12} sm={isActionsEnabled ? 9 : 12} xs={12}>
            {header}
          </Grid>
          {actions &&
            <Grid item md={2} sm={3} xs={12} className={classes.actionsContainer}>
              {actions}
            </Grid>
          }
        </Grid>
        {pending ?
          <Fragment>
            {pending}
          </Fragment>
          :
          <Fragment>
            <Grid container className={classes.gridContainer}>
              {tabs}
            </Grid>
            {divider && <Divider className={classes.divider} />}
            <Grid>
              {page}
            </Grid>
          </Fragment>
        }
        {footer}
      </Fragment>
    </div>
  );
};

Details.propTypes = {
  classes: shape({}),
  detailsPageId: string,
  editPageId: string,
  dialogId: string,
  isDialogOpen: bool,
  onDialogClose: func,
  dialogSubmitText: string,
  onDialogSubmit: func,
  isLoading: bool,
  divider: bool,
  notificationId: string,
  notificationStatus: string,
  setNotificationStatus: func,
  notificationMessage: string,
  setNotificationMessage: func,
  infoId: string,
  errors: arrayOf(shape({})),
  header: shape({}),
  actions: shape({}),
  pending: shape({}),
  tabs: shape({}),
  page: shape({}),
  footer: shape({}),
  pageHeaderRef: shape({}),
  notificationContainerClass: string,
  hasNotificationIcon: bool,
  isActionsEnabled: bool,
};
Details.displayName = 'Details';

export default compose(
  withStyles(styles),
)(Details);
