import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { bool, func, string, number, arrayOf, object } from 'prop-types';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { sentenceCase } from 'change-case';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HelpIcon from '@material-ui/icons/Help';
import Chip from '@material-ui/core/Chip';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Tooltip, withStyles } from '@material-ui/core';

import {
  TablePagination,
} from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

import {
  fetchProxies,
  fetchProxiesByApiKey,
  deployApiKeyToProxy,
  redeployApiKeyToProxy,
  undeployApiKeyFromProxy,
} from '../../../../actions/application';
import {
  getProxies,
  getProxiesByApiKey,
  getProxyErrors,
} from '../../../../reducers/application';
import {
  fetchDeploymentRefreshTime,
} from '../../../../actions/portalConfig';
import {
  getUserDetails,
  getDeploymentRefreshTime,
} from '../../../../reducers/portalConfig';
import {
  hasPublisherRole,
} from '../../../../utils';
import { getTimeZoneAbbr } from '../../../../utils/datetime';
import DeploymentCard from './DeploymentCard';

import {
  KEY_NAME_LABEL,
  STATUS_LABEL,
  KEY_STATUS_ENABLED,
  NO_RESULTS_TEXT,
} from '../labels';

import styles from './styles';
import { APPLICATION_STATUS_DELETE_PENDING_APPROVAL } from '../../../../constants';
import { Lock } from '@material-ui/icons';
import clsx from 'clsx';

export const getDeploymentList = (deployments = [], apiKeyProxies = []) => {
  const combinedList = [];
  deployments.map((deployment) => {
    const matchedDeployment = apiKeyProxies.find(apiKeyDeployment =>
      apiKeyDeployment.proxyUuid === deployment.uuid);
    if (matchedDeployment) {
      combinedList.push(Object.assign({}, matchedDeployment, deployment));
    } else {
      combinedList.push(Object.assign({}, deployment));
    }
    return true;
  });
  return combinedList;
};
export const renderDeploymentTypeHelpText = (classes) => (
  <p className={classes.helpTextContainer}>
    <span className={classes.boldText}>{getI18nFormattedMessage('label.application.key.deployment.tooltip.title')}</span><br />
    <span>{getI18nFormattedMessage('label.application.key.deployment.tooltip.subtitle')}</span><br />
    <span>
      <span className={classes.boldText}>&#8226; {getI18nFormattedMessage('label.application.key.deployment.automatic.tooltip.title')}: </span>
      {getI18nFormattedMessage('label.application.key.deployment.automatic.tooltip.subtitle')}
    </span>
    <br />
    <span>
      <span className={classes.boldText}>&#8226; {getI18nFormattedMessage('label.application.key.deployment.on.demand.tooltip.title')}: </span>
      {getI18nFormattedMessage('label.application.key.deployment.on.demand.tooltip.subtitle')}
    </span>
  </p>
);
const SingleKeyView = (props) => {
  const {
    applicationUuid,
    apiKey,
    deploymentList = [],
    deploymentErrors,
    user,
    history,
    classes,
    isDeletePending,
    isEditDisabled,
  } = props;

const formatActionsColumnTitle = () => (<span className={classes.actionsColumnTitle}>
  {getI18nFormattedMessage('label.application.details.keys.deployments.table.columns.deployment.actions')}
  {isDeletePending &&
      <Tooltip
        title={getI18nFormattedMessage('label.application.details.deployments.locked.tooltip')}
        arrow placement="right">
        <Lock className={classes.lockIcon}/>
      </Tooltip>
    }
</span>);
const appKeyDeploymentColumns = [{
    id: 'proxy',
    label: getI18nFormattedMessage('label.application.details.keys.deployments.table.columns.proxy'),
    minWidth: 200,
    value: (item) => item.name,
  }, {
    id: 'deploymentType',
    label: getI18nFormattedMessage('label.application.details.keys.deployments.table.columns.deployment.type'),
    minWidth: 300,
    helpText: true,
    value: (item) => item.name,
  }, {
    id: 'deploymentStatus',
    label: getI18nFormattedMessage('label.application.details.keys.deployments.table.columns.deployment.status'),
    minWidth: 100,
    value: (item) => item.name,
  }, {
    id: 'lastTimeDeployed',
    label: getI18nFormattedMessage('label.api.date.last.deployed', { zone: getTimeZoneAbbr() }),
    minWidth: 50,
  }, {
    id: 'deploymentActions',
    label: formatActionsColumnTitle(),
    minWidth: 100,
    value: (item) => item.name,
  }];
  return (
    <Fragment>
      {deploymentList.length > 0 ?
      <div className={classes.innerTable}>
        <Table>
          <TableHead className={classes.innerTableHead}>
            {appKeyDeploymentColumns.map(column =>
              <TableCell key={column.id}>
                {column.label}
                {column.helpText &&
                  <Tooltip title={renderDeploymentTypeHelpText(classes)} arrow placement="right">
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>}
              </TableCell>)
            }
          </TableHead>
          <TableBody>
          {deploymentList.map((obj, idx) => (
            <DeploymentCard
              key={idx}
              index={idx}
              details={{
                applicationUuid,
                apiKey,
                uuid: obj.uuid,
                name: obj.name,
                type: obj.appDeploymentType,
                status: obj.status,
                statusMessage: obj.message,
                lastTimeDeployed: obj.lastTimeDeployed,
              }}
              errorObj={
                deploymentErrors.find(err =>
                  (err.proxyUuid === obj.uuid && err.apiKey === apiKey))
              }
              refreshTime={Number.parseFloat(props.refreshTime)}
              deployApiKey={props.deployApiKeyToProxy}
              redeployApiKey={props.redeployApiKeyToProxy}
              undeployApiKey={props.undeployApiKeyFromProxy}
              checkStatus={props.fetchProxiesByApiKey}
              user={user}
              history={history}
              classes={classes}
              isDeletePending={isDeletePending}
              isEditDisabled={isEditDisabled}
            />))}
          </TableBody>
        </Table>
        </div>:
        <span>{NO_RESULTS_TEXT}</span>
      }
    </Fragment>
  );
};

SingleKeyView.propTypes = {
  applicationUuid: string,
  apiKey: string,
  deploymentList: arrayOf(object),
  deploymentErrors: arrayOf(object),
  fetchProxiesByApiKey: func,
  user: object,
  history: object,
  classes: object,
  refreshTime: string,
  deployApiKeyToProxy: func,
  redeployApiKeyToProxy: func,
  undeployApiKeyFromProxy: func,
  fetchProxies: func,
  fetchDeploymentRefreshTime: func,
  isDeletePending: bool,
  isEditDisabled: bool,
};

const SingleRow = (props) => {
  const {
    idx,
    row,
    deployments,
    classes,
    isDeletePending,
    expandIndex,
    setExpandIndex,
    isEditDisabled,
  } = props;

  return (
    <Fragment>
      <TableRow onClick={() => setExpandIndex(expandIndex === idx ? -1 : idx)}>
        <TableCell component="th" scope="row">
          {row.name}
          {get(row, 'defaultKey') &&
            <Chip
              className={classes.defaultChip}
              size="small"
              label={'Default'}
            />
          }
        </TableCell>
        <TableCell component="th" scope="row">
          <span className={clsx(classes.status,
            get(row, 'status') === KEY_STATUS_ENABLED ? classes.enabled : classes.disabled)}>
            {sentenceCase(get(row, 'status'))}
          </span>
        </TableCell>
        <TableCell>
          <IconButton
            className={classes.expandCollapseButton}
            size="small"
            onClick={() => setExpandIndex(expandIndex === idx ? -1 : idx)}
          >
            {expandIndex === idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={expandIndex === idx} timeout="auto" unmountOnExit>
            <SingleKeyView
              {...props}
              apiKey={get(row, 'apiKey')}
              deploymentList={getDeploymentList(deployments, get(row, 'proxies', []))}
              isDeletePending={isDeletePending}
              isEditDisabled={isEditDisabled}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

SingleRow.propTypes = {
  applicationUuid: string,
  idx: number,
  row: number,
  deployments: arrayOf(object),
  classes: object,
  isDeletePending: bool,
  expandIndex: number,
  setExpandIndex: func,
  isEditDisabled: bool,
};

const GRID_ROWS_PER_PAGE_OPTIONS = [10, 20];
const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 10;

export const DeploymentsRaw = (props) => {
  const {
    applicationUuid,
    applicationDetails,
    fetchApplicationKeys,
    appKeys = [],
    appKeysTotalPages,
    appKeysTotalElements,
    isMultiKeySupport,
    deployments = [],
    apiKeyProxies = [],
    deploymentErrors = [],
    user,
    history,
    classes,
    isEditDisabled,
  } = props;
  const intl = getI18n(useIntl());
  const [isMultiKeyView, setIsMultiKeyView] = useState(false);
  const [deploymentList, setDeploymentList] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [expandIndex, setExpandIndex] = useState(0);
  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchApplicationKeys(applicationUuid, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchApplicationKeys(applicationUuid, 0, newRowsPerPage);
  };

  useEffect(() => {
    if (isMultiKeySupport && hasPublisherRole(user)) { // Multikey View
      props.fetchProxies();
      props.fetchDeploymentRefreshTime();
      appKeys.map(obj => {
        props.fetchProxiesByApiKey(obj.apiKey);
        return true;
      });
    } else {
      const apiKey = get(applicationDetails, 'apiKey');
      if (apiKey) {
        if (hasPublisherRole(user)) {
          props.fetchProxies();
          props.fetchDeploymentRefreshTime();
          props.fetchProxiesByApiKey(apiKey);
        }
      }
    }
  }, [applicationDetails, isMultiKeySupport]);

  useEffect(() => {
    if (appKeys.length > 0) {
      const tempArr = [];
      appKeys.map((obj) => {
        tempArr.push(Object.assign({},
          apiKeyProxies.find((o) => o.key === obj.apiKey),
          obj),
        );
        return true;
      });
      setRows(tempArr);
      setIsMultiKeyView(true);
    } else {
      const apiKey = get(applicationDetails, 'apiKey');
      const keyObject = apiKeyProxies.find(o => o.key === apiKey);
      setDeploymentList(getDeploymentList(deployments, get(keyObject, 'proxies', [])));
      setIsMultiKeyView(false);
    }
  }, [apiKeyProxies, appKeys.length, apiKeyProxies.length, deployments.length]);

  return (
    <div
      className={classes.appDeploymentsContainer}
      id="app-deployments-container" data-layer7-test="app-deployments-container"
    >
      {isMultiKeyView ?
        <Fragment>
          <TableContainer className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>{KEY_NAME_LABEL}</TableCell>
                  <TableCell align={'center'}>{STATUS_LABEL}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) =>
                  <SingleRow
                    key={idx}
                    {...props}
                    row={row}
                    idx={idx}
                    history={history}
                    isDeletePending=
                    {applicationDetails
                      && applicationDetails.status === APPLICATION_STATUS_DELETE_PENDING_APPROVAL}
                    expandIndex={expandIndex}
                    setExpandIndex={setExpandIndex}
                    isEditDisabled={isEditDisabled}
                  />)}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.paginationContainer}>
            <TablePagination
              id="app-deployments-keys-pagination"
              data-apim-test="app-deployments-keys-pagination"
              page={page}
              count={appKeysTotalElements}
              pageCount={appKeysTotalPages}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              onChangePreviousPage={onChangePreviousPage}
              onChangeNextPage={onChangeNextPage}
              labelRowsPerPage={intl.getI18nMessage('label.pagination.rows.per.page')}
            />
          </div>
        </Fragment> :
        <SingleKeyView
          {...props}
          applicationUuid={applicationUuid}
          apiKey={get(applicationDetails, 'apiKey')}
          deploymentErrors={deploymentErrors}
          deploymentList={deploymentList}
          history={history}
        />
      }
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserDetails(state),
  deployments: getProxies(state),
  apiKeyProxies: getProxiesByApiKey(state),
  deploymentErrors: getProxyErrors(state),
  refreshTime: getDeploymentRefreshTime(state),
});

const mapDispatchToProps = {
  fetchProxies,
  fetchProxiesByApiKey,
  fetchDeploymentRefreshTime,
  deployApiKeyToProxy,
  redeployApiKeyToProxy,
  undeployApiKeyFromProxy,
};

DeploymentsRaw.propTypes = {
  applicationUuid: string,
  applicationDetails: object,
  fetchApplicationKeys: func,
  appKeys: arrayOf(object),
  appKeysTotalPages: number,
  appKeysTotalElements: number,
  isMultiKeySupport: bool,
  deployments: arrayOf(object),
  apiKeyProxies: arrayOf(object),
  deploymentErrors: arrayOf(object),
  history: object,
  user: object,
  classes: object,
  fetchProxies: func,
  fetchDeploymentRefreshTime: func,
  isEditDisabled: bool,
  fetchProxiesByApiKey: func,
};
DeploymentsRaw.displayName = 'Deployments';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DeploymentsRaw);
