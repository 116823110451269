import { get } from 'lodash';

const styles = ({ color, margin, typography, padding }) => ({
  addBtn: {
    float: 'right',
    marginTop: 28,
  },
  pageClass: {
    margin: 0,
    marginBottom: 84,
    minHeight: 'calc(100vh - 340px)',
  },
  pageBodyClass: {
    padding: get(padding, 'p0'),
  },
  productName: {
    color: get(color, 'primaryButtonBackground'),
    fontFamily: get(typography, 'buttonText'),
  },
  warningIcon: {
    color: get(color, 'warning'),
    marginLeft: get(margin, 'm10'),
    marginRight: get(margin, 'm4'),
    fontSize: 14,
    marginTop: get(margin, 'm4'),
  },
  dialogContainer: {
    maxWidth: '750px',
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    color: get(color, 'black'),
    fontFamily: get(typography, 'bodyText'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
  rate: {
    fontFamily: get(typography, 'bodyText'),
  },
  link: {
    cursor: 'pointer',
  },
  linkText: {
    color: get(color, 'primaryButtonBackground'),
    fontFamily: get(typography, 'buttonText'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: '5px !important',
  },
});

export default styles;
