// @flow
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import theme from './index.theme';
import { getI18n } from '../../../../utils/intl';

// workaround till we update react-scripts > 2.0.0, which supports adding svg images
const collapseSVG = <path d="M12.023 7.04426L19.1319 13.9239C19.6227 14.3988 19.6227 15.1689 19.1319 15.6438C18.6412 16.1187 17.8455 16.1187 17.3547 15.6438L11.9771 10.4397L6.6453 15.5995C6.15453 16.0745 5.35884 16.0745 4.86808 15.5995C4.37731 15.1246 4.37731 14.3546 4.86808 13.8796L11.977 7L12.0229 7.04442L12.023 7.04426Z" />;
const expandSVG = <path d="M12.023 16.9557L12.0229 16.9556L11.977 17L4.86808 10.1204C4.37731 9.64543 4.37731 8.87541 4.86808 8.40047C5.35884 7.92553 6.15453 7.92553 6.6453 8.40047L11.9771 13.5603L17.3547 8.3562C17.8455 7.88127 18.6412 7.88127 19.1319 8.3562C19.6227 8.83114 19.6227 9.60117 19.1319 10.0761L12.023 16.9557Z" />;
/* eslint-disable */
const mobileNavSVG = (
  <Fragment>
    <path
      fillRule="evenodd" clipRule="evenodd" d="M7.25 2.5H2.5V7.25H7.25V2.5ZM2.5 9.5H7.25V14.25H2.5V9.5ZM14.25 9.5H9.5V14.25H14.25V9.5ZM21.5 16.75H16.75V21.5H21.5V16.75Z"
    />
    <path
      opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M9.5 16.75H14.25V21.5H9.5V16.75ZM16.75 9.5H21.5V14.25H16.75V9.5ZM2.5 16.75H7.25V21.5H2.5V16.75ZM16.75 2.5H21.5V7.25H16.75V2.5ZM9.5 2.5H14.25V7.25H9.5V2.5Z"
    />
  </Fragment>
);
/* eslint-enable */

export default function Menu(props) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { navigations, isRootMenu } = props;
  const [context, setContext] = React.useState([]);
  const anchorRef = React.useRef(null);
  const intl = getI18n(useIntl());

  const handleToggle = () => {
    setTimeout(() => {
      setOpen((currentVal) => currentVal ? null : !currentVal);
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if ((event.key === 'Tab') || (event.key === 'Escape')) {
      event.preventDefault();
      anchorRef.current.focus();
      setOpen(false);
    }
  }

  function handleBtnKeyDown(event) {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getCurrentNode = (node, contextParam) => {
    if (contextParam.length === 0) {
      return node;
    }
    const index = contextParam[0];
    const nextNode = node.navigations[index];
    return getCurrentNode(nextNode, contextParam.slice(1));
  };

  const popContext = () => setContext(contextVal => contextVal.splice(0, contextVal.length - 1));
  const pushContext = (index) => setContext(contextVal => contextVal.concat([index]));

  const renderMenuItem = (item, index) => {
    let isLink = true;
    if (item.navigations && (item.navigations.length > 0)) {
      isLink = false;
    }
    let itemProps = {};
    let relKey = '';
    if (item.externalLink) {
      relKey = 'noopener';
      if (item.titleKey !== 'label.appnav.portalapi') {
        relKey += ' noreferrer';
      }
    }
    if (isLink) {
      itemProps = {
        href: item.href,
        target: item.externalLink ? '_blank' : '_self',
        rel: relKey,
      };
    } else {
      itemProps = {
        onClick: () => {
          pushContext(index);
          event.preventDefault();
        },
      };
    }
    return (
      <MenuItem
        className={classes.MenuItems}
        component={'a'}
        key={item.titleKey || item.menuTitle}
        value={item.titleKey || item.menuTitle}
        {...itemProps}
        data-apim-test={item.titleKey || item.menuTitle}
      >
        {item.titleKey ? intl.getI18nMessage(item.titleKey) : item.menuTitle}
        {!isLink && <ChevronRightIcon className={classes.arrowRight} />}
      </MenuItem>);
  };

  const renderMenu = () => {
    const menuItems = [];
    let currentNavItems = navigations;
    if (context.length > 0) {
      const initialNode = {
        navigations: currentNavItems,
      };
      const currentNode = getCurrentNode(initialNode, context);
      currentNavItems = currentNode.navigations;
      menuItems.push(
        <MenuItem
          className={clsx(classes.MenuItems, classes.BackBtn)}
          component={'a'}
          key={currentNode.titleKey}
          value={currentNode.titleKey}
          onClick={popContext}
          data-apim-test={currentNode.titleKey || currentNode.menuTitle}
        >
          <ChevronLeftIcon className={classes.arrowLeft} />
          {intl.getI18nMessage(currentNode.titleKey)}
        </MenuItem>);
    }
    menuItems.push(currentNavItems.map(renderMenuItem));
    return menuItems;
  };

  const CollapseIcon = createSvgIcon(collapseSVG, 'collapse');
  const ExpandIcon = createSvgIcon(expandSVG, 'expand');
  const MobileNavIcon = createSvgIcon(mobileNavSVG, 'mobileNav');
  return (
    <div>
      {isRootMenu ?
      (<IconButton
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup={'true'}
        className={classes.collapsedMenuButton}
        onClick={handleToggle}
        onKeyDown={handleBtnKeyDown}
        ref={anchorRef}
        data-apim-test="mobile-navigation"
      >
        <MobileNavIcon className={classes.MenuIcon} />
      </IconButton>)
      :
      (<Button
        classes={{
          root: classes.menuButton,
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup={'true'}
        onClick={handleToggle}
        endIcon={open ? <CollapseIcon /> : <ExpandIcon />}
        onKeyDown={handleBtnKeyDown}
        data-apim-test={props.titleKey}
      >
        {intl.getI18nMessage(props.titleKey)}
      </Button>)
      }
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-start'}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              classes={{
                root: classes.MenuPaper,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  classes={{
                    root: classes.MenuList,
                  }}
                  id={'menu-list-grow'}
                  onKeyDown={handleListKeyDown}
                >
                  {renderMenu()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

Menu.propTypes = {
  isRootMenu: PropTypes.bool,
  navigations: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    titleKey: PropTypes.string,
    externalLink: PropTypes.bool,
  })),
  titleKey: PropTypes.string,
};
