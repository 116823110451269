import React from 'react';
import { makeStyles } from '@material-ui/core';
import { bool, func, string, shape, arrayOf } from 'prop-types';
import styles from './styles';
import HorizontalScrollBar from '../../../../../components/HorizontalScrollBar';
import EntityCard from '../EntityCard';

const entityOrder = [
  'APIs',
  'APIGroups',
  'Keys',
];
export default function EntityScrollBar(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const getEntityObj = entityName =>
    props.entities.find(entityObj => entityObj.entity === entityName);
  return (
    <React.Fragment>
      <HorizontalScrollBar scrollEntity="referenceCard">
        {props.entities && props.entities.length > 0 ? (
          <div className={classes.leftBorder} />
        ) : null}
        {entityOrder.map(
          (entityName, id) =>
            getEntityObj(entityName) && (
              <EntityCard
                setActiveTab={props.setActiveTab}
                entity={getEntityObj(entityName)}
                id={id}
                key={entityName}
                disabled={props.disabled}
              />
            ),
        )}
        {props.entities && props.entities.length > 0 ? (
          <div className={classes.rightBorder} />
        ) : null}
      </HorizontalScrollBar>
    </React.Fragment>
  );
}
EntityScrollBar.propTypes = {
  entities: arrayOf(shape({
    key: string,
    value: string,
  })),
  setActiveTab: func,
  disabled: bool,
};
