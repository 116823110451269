import { get } from 'lodash';

const styles = ({ padding, margin, color, fontSize, lineHeight }) => ({
  container: {
    '& p': {
      fontSize: get(fontSize, 'bodyText'),
      lineHeight: get(lineHeight, 'lh21'),
      margin: '0',
      padding: '0',
    },
  },
  heroTopContainer: {
    background: get(color, 'green'),
    boxShadow: 'inset 0px 0px 50px rgba(0, 0, 0, 0.5)',
    padding: get(padding, 'p50'),
    margin: get(margin, 'm0'),
    width: '100%',
    color: get(color, 'white'),
    '& p': {
      fontSize: get(fontSize, 'title'),
      lineHeight: get(lineHeight, 'lh28'),
      fontWeight: '500',
    },
    '& h1': {
      color: get(color, 'white'),
    },
  },
  heroBottomCard: {
    padding: `${get(padding, 'p36')}`,
    border: `1px solid ${get(color, 'grayBorder')}`,
  },
  section: {
    padding: `0 0 ${get(padding, 'p36')} 0`,
  },
  card: {
    padding: `${get(padding, 'p36')}`,
    '& h3': {
      marginTop: get(margin, 'm0'),
      marginBottom: get(margin, 'm12'),
    },
    '& a': {
      color: get(color, 'link'),
      fontSize: get(fontSize, 'bodyText'),
      lineHeight: get(lineHeight, 'lh50'),
      textDecoration: 'none',
    },
    border: `1px solid ${get(color, 'grayBorder')}`,
  },
  row: {
    padding: `0 0 ${get(padding, 'p20')} 0`,
  },
  h1: {
    margin: `0 0 0 ${get(padding, 'p36')}`,
  },
  h2: {
    color: get(color, 'blackHeading'),
  },
  h4: {
    color: get(color, 'blackHeading'),
  },
});

export default styles;
