import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  withStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { shape, bool, arrayOf, func } from 'prop-types';

import {
  AlertMessages,
  FormTextField,
  RadioGroupSection,
  FormActionButtons,
  Loading,
  ErrorContainer,
  HelpContainer,
  FormSelect,
} from '../../../components';
import { fetch, save, update } from '../../../actions/rateQuota';
import {
  getUserDetails,
  getFeatureFlagForProduct,
} from '../../../reducers/portalConfig';
import {
  getIsLoading,
  getIsError,
  getErrors,
  getIsSaveSuccess,
  getDetails,
} from '../../../reducers/rateQuota';
import { hasError, isNumberOnly } from '../../../utils';
import { getI18n } from '../../../utils/intl';
import {
  ALERT_ERROR,
  RATE_QUOTA_ASSIGNMENT_LEVEL,
  RATE_QUOTA_HELP_ITEMS,
  QUOTAS_INTERVAL_API,
  QUOTAS_INTERVAL_ORG,
  QUOTA_ASSIGNMENT_ORG,
  ORGANIZATION,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION,
  QUOTAS_INTERVAL_PRODUCT_PER_APPLICATION,
  RATE_QUOTA_ASSIGNMENT_LEVEL_WITH_PRODUCT,
  RATE_QUOTA_HELP_ITEMS_WITH_PRODUCT,
} from '../../../constants';
import styles from './styles';

const getUuid = (props) =>
  get(props, 'match.path') &&
  get(props, 'match.path').includes('/edit/') &&
  get(props, 'match.params.rqUuid');

export const RateQuotaEdit = (props) => {
  const {
    classes,
    userContext,
    featureFlagProduct,
    isLoading,
    rateQuotaErrors,
    isError,
    isSaveSuccess,
    details,
  } = props;

  const uuid = getUuid(props) || '';

  const getHeaderTitle = () => {
    if (uuid && details) {
      return `${intl.getI18nMessage('label.rate.quota.add.title')}: ${
        details.name
      }`;
    }
    return intl.getI18nMessage('label.rate.quota.edit.title');
  };

  const intl = getI18n(useIntl());
  const [name, setName] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [assignmentLevel, setAssigmentLevel] = useState('API');
  const [isRate, setIsRate] = useState(false);
  const [rateLimit, setRateLimit] = useState('');
  const [spreadWindow, setSpreadWindow] = useState('');
  const [maxConcurrency, setMaxConcurrency] = useState('');
  const [rateLimitError, setRateLimitError] = useState(false);
  const [spreadWindowError, setSpreadWindowError] = useState(false);
  const [maxConcurrencyError, setMaxConcurrencyError] = useState(false);
  const [isQuota, setIsQuota] = useState(false);
  const [quotaInterval, setQuotaInterval] = useState();
  const [listIntervals, setListIntervals] = useState(QUOTAS_INTERVAL_API);
  const [quota, setQuota] = useState('');
  const [quotaError, setQuotaError] = useState(false);
  const [description, setDescription] = useState();
  const [errors, setErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [headerTitle, setHeaderTitle] = useState(getHeaderTitle());

  const notifyMessages = (message, status) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  const clearErrorMessages = () => {
    notifyMessages('', '');
    setErrors([]);
  };

  useEffect(() => {
    if (
      !uuid &&
      !get(userContext, 'permissions.RATE_QUOTA', []).includes('CREATE')
    ) {
      props.push('/404');
    } else if (
      uuid &&
      !get(userContext, 'permissions.RATE_QUOTA', []).includes('UPDATE')
    ) {
      props.push('/404');
    }
  }, [userContext]);

  useEffect(() => {
    if (uuid) {
      props.fetch(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (details && details.uuid) {
      setName(details.name);
      setAssigmentLevel(details.assignmentLevel);
      setDescription(details.description);
      if (get(details, 'rateLimit.maxRequestsPerSecond')) {
        setIsRate(true);
        setRateLimit(get(details, 'rateLimit.maxRequestsPerSecond').toString());
        if (get(details, 'rateLimit.windowSizeInSeconds'))
          setSpreadWindow(
            get(details, 'rateLimit.windowSizeInSeconds').toString(),
          );
        if (get(details, 'rateLimit.maxConcurrency'))
          setMaxConcurrency(
            get(details, 'rateLimit.maxConcurrency').toString(),
          );
      }
      if (get(details, 'quota.quota')) {
        setIsQuota(true);
        setQuotaInterval(get(details, 'quota.interval'));
        setQuota(get(details, 'quota.quota').toString());
      }
      if (details.assignmentLevel === QUOTA_ASSIGNMENT_ORG) {
        setListIntervals(QUOTAS_INTERVAL_ORG);
      }
      if (
        details.assignmentLevel === QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION
      ) {
        setListIntervals(QUOTAS_INTERVAL_PRODUCT_PER_APPLICATION);
      }
      setHeaderTitle(getHeaderTitle());
    }
  }, [details]);

  useEffect(() => {
    if (isError) {
      notifyMessages(intl.getI18nMessage('error.title'), ALERT_ERROR);
      setErrors(rateQuotaErrors);
    } else if (!isNameError) {
      clearErrorMessages();
    }
  }, [isError, rateQuotaErrors]);

  useEffect(() => {
    if (isSaveSuccess) {
      clearErrorMessages();
      const id = details && details.uuid ? details.uuid : uuid;
      props.push(`/admin/rate-quotas/details/${id}`);
    }
  }, [isSaveSuccess, details]);

  const handleChange = (fieldName, value) => {
    switch (fieldName) {
      case 'name': {
        setName(value);
        setIsNameError(hasError(value, true));
        break;
      }
      case 'assignmentLevel': {
        setAssigmentLevel(value);
        if (value === QUOTA_ASSIGNMENT_ORG) {
          setListIntervals(QUOTAS_INTERVAL_ORG);
        } else if (value === QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION) {
          setListIntervals(QUOTAS_INTERVAL_PRODUCT_PER_APPLICATION);
        } else {
          setListIntervals(QUOTAS_INTERVAL_API);
        }
        break;
      }
      case 'ratelimit': {
        const isRateError =
          hasError(value, true) ||
          isNaN(parseInt(value)) ||
          isNumberOnly(value);
        setRateLimit(value);
        setRateLimitError(isRateError);
        break;
      }
      case 'spreadWindow': {
        const isSpreadError = value
          ? isNaN(parseInt(value)) || isNumberOnly(value)
          : false;
        setSpreadWindow(value);
        setSpreadWindowError(isSpreadError);
        break;
      }
      case 'maxConcurrency': {
        const isMaxConcurrencyError = value
          ? isNaN(parseInt(value)) || isNumberOnly(value)
          : false;
        setMaxConcurrency(value);
        setMaxConcurrencyError(isMaxConcurrencyError);
        break;
      }
      case 'quota': {
        const isQuotaError =
          hasError(value, true) ||
          isNaN(parseInt(value)) ||
          isNumberOnly(value);
        setQuotaError(isQuotaError);
        setQuota(value);
        break;
      }
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleQuotaIntervalChange = (value) => {
    setQuotaInterval(value);
  };

  const handleRateChecked = (e) => {
    setIsRate(e.target.checked);
    if (!e.target.checked) {
      setRateLimit('');
      setSpreadWindow('');
      setMaxConcurrency('');
    } else {
      if (uuid) {
        if (get(details, 'rateLimit.maxRequestsPerSecond'))
          setRateLimit(
            get(details, 'rateLimit.maxRequestsPerSecond').toString(),
          );
        if (get(details, 'rateLimit.windowSizeInSeconds'))
          setSpreadWindow(
            get(details, 'rateLimit.windowSizeInSeconds').toString(),
          );
        if (get(details, 'rateLimit.maxConcurrency'))
          setMaxConcurrency(
            get(details, 'rateLimit.maxConcurrency').toString(),
          );
      }
    }
  };

  const handleQuotaChecked = (e) => {
    setIsQuota(e.target.checked);
    if (!e.target.checked) {
      setQuotaInterval(null);
      setQuota('');
    } else {
      if (uuid) {
        setQuotaInterval(
          get(details, 'quota.quota')
            ? get(details, 'quota.interval')
            : listIntervals[0].uuid,
        );
        setQuota(
          get(details, 'quota.quota') ? get(details, 'quota.quota') : '',
        );
      } else {
        setQuotaInterval(listIntervals[0].uuid);
      }
    }
  };

  const onCancel = () => {
    clearErrorMessages();
    window.location.href = '/admin/rate-quotas/';
  };
  const hasValidSaveButton = () => {
    const nameUiError = hasError(name, true);
    const quotaUiError =
      isQuota &&
      (hasError(quota, true) || isNaN(parseInt(quota)) || isNumberOnly(quota));
    const rateUiError =
      isRate &&
      (hasError(rateLimit, true) ||
        isNaN(parseInt(rateLimit)) ||
        isNumberOnly(rateLimit));
    const spreadWindowUiError = spreadWindow
      ? isNaN(parseInt(spreadWindow)) || isNumberOnly(spreadWindow)
      : false;
    const maxCurrencyUiError = maxConcurrency
      ? isNaN(parseInt(maxConcurrency)) || isNumberOnly(maxConcurrency)
      : false;
    setIsNameError(nameUiError);
    setQuotaError(quotaUiError);
    setRateLimitError(rateUiError);
    setSpreadWindowError(spreadWindowUiError);
    setMaxConcurrencyError(maxCurrencyUiError);
    if (
      nameUiError ||
      quotaUiError ||
      rateUiError ||
      spreadWindowUiError ||
      maxCurrencyUiError
    ) {
      notifyMessages(
        intl.getI18nMessage('error.required.fields.valid'),
        ALERT_ERROR,
      );
    }
    return (
      nameUiError ||
      quotaUiError ||
      rateUiError ||
      spreadWindowUiError ||
      maxCurrencyUiError
    );
  };

  const onNext = async () => {
    clearErrorMessages();
    if (!hasValidSaveButton()) {
      if (uuid) {
        await props.update({
          name,
          assignmentLevel,
          description,
          quota,
          quotaInterval,
          rateLimit,
          uuid,
          spreadWindow,
          maxConcurrency,
        });
      } else {
        await props.save({
          name,
          assignmentLevel,
          description,
          quota,
          quotaInterval,
          rateLimit,
          spreadWindow,
          maxConcurrency,
        });
      }
    }
  };

  return (
    <div
      className={classes.content}
      id="rate-quota-edit-page"
      data-apim-test="rate-quota-edit-page"
    >
      {isLoading && <Loading pageLoader />}
      <Grid container className={classes.gridContainer}>
        <Grid
          md={2}
          item
          className={classes.leftSideBar}
          id="rate-quota-edit-left-sidebar"
          data-apim-test="rate-quota-edit-left-sidebar"
        />
        <Grid
          md={7}
          item
          className={classes.mainContent}
          id="rate-quota-edit-main-content"
          data-apim-test="rate-quota-edit-main-content"
        >
          {notificationMessage && (
            <AlertMessages
              id="rate-quota-edit-notification"
              data-apim-test="rate-quota-edit-notification"
              message={notificationMessage}
              variant={notificationStatus}
              onClose={() => {
                notifyMessages('', '');
              }}
            />
          )}
          {errors.length > 0 && <ErrorContainer errors={errors} />}
          <h1 className={classes.pageTitle}>{headerTitle}</h1>
          <FormTextField
            id={'rate-quota-edit-page-name'}
            name={intl.getI18nMessage('label.rate.quota.name')}
            value={name}
            error={isNameError}
            maxLength={'50'}
            handleChange={(value) => handleChange('name', value)}
            helperText={intl.getI18nMessage('label.rate.quota.name.help')}
          />
          <h4 className={classes.formLabel}>
            {intl.getI18nMessage('label.rate.quota.assignment.level')}
          </h4>
          <RadioGroupSection
            selected={assignmentLevel}
            onChange={(e) => handleChange('assignmentLevel', e.target.value)}
            data={
              featureFlagProduct
                ? RATE_QUOTA_ASSIGNMENT_LEVEL_WITH_PRODUCT
                : RATE_QUOTA_ASSIGNMENT_LEVEL
            }
            id={'rate-quota-edit-page-assignment-level'}
            defaultSelect={''}
            isDisable={uuid ? true : false}
          />
          <h4 className={classes.formLabel}>
            {intl.getI18nMessage('label.rate.quota.limit')}
          </h4>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id={'rate-quota-edit-page-isRate'}
                  classes={{ root: classes.iconRoot }}
                  checked={isRate}
                  onChange={handleRateChecked}
                />
              }
              label={intl.getI18nMessage('label.rate.quota.limit.rate')}
            />
            {isRate && (
              <div className={classes.inputContainer}>
                <FormTextField
                  id={'rate-quota-edit-page-rate-limit'}
                  fieldContainerClass={classes.fieldTextContainer}
                  name={intl.getI18nMessage('label.rate.quota.rate.limit')}
                  value={rateLimit}
                  error={rateLimitError}
                  maxLength={'10'}
                  handleChange={(value) => handleChange('ratelimit', value)}
                  helperText={intl.getI18nMessage(
                    'label.rate.quota.limit.help',
                  )}
                />
                {assignmentLevel !== ORGANIZATION && (
                  <>
                    <FormTextField
                      id={'rate-quota-edit-page-spread-limit-window'}
                      fieldContainerClass={classes.fieldTextContainer}
                      name={intl.getI18nMessage(
                        'label.rate.quota.spread.limit.window',
                      )}
                      error={spreadWindowError}
                      value={spreadWindow}
                      maxLength={'10'}
                      handleChange={(value) =>
                        handleChange('spreadWindow', value)
                      }
                      helperText={intl.getI18nMessage('label.seconds')}
                    />
                    <FormTextField
                      id={'rate-quota-edit-page-maximum-concurrency'}
                      fieldContainerClass={classes.fieldTextContainer}
                      name={intl.getI18nMessage(
                        'label.rate.quota.maximum.concurrency',
                      )}
                      error={maxConcurrencyError}
                      value={maxConcurrency}
                      maxLength={'10'}
                      handleChange={(value) =>
                        handleChange('maxConcurrency', value)
                      }
                      helperText={intl.getI18nMessage('label.requests')}
                    />
                  </>
                )}
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  id={'rate-quota-edit-page-isQuota'}
                  classes={{ root: classes.iconRoot }}
                  checked={isQuota}
                  onChange={handleQuotaChecked}
                />
              }
              label={intl.getI18nMessage('label.rate.quota.limit.quota')}
            />
            {isQuota && (
              <div className={classes.inputContainer}>
                <FormSelect
                  {...props}
                  id={'rate-quota-edit-page-quota-interval'}
                  name={intl.getI18nMessage(
                    'label.rate.quota.limit.quota.interval',
                  )}
                  fieldContainerClass={classes.fieldTextContainer}
                  value={quotaInterval}
                  data={listIntervals}
                  handleChange={handleQuotaIntervalChange}
                  noNoneOption
                  noNativeSelect
                />
                <FormTextField
                  id={'rate-quota-edit-page-quota'}
                  name={intl.getI18nMessage('label.rate.quota.limit.quota')}
                  value={quota}
                  error={quotaError}
                  maxLength={'10'}
                  handleChange={(value) => handleChange('quota', value)}
                  helperText={intl.getI18nMessage(
                    'label.rate.quota.limit.help',
                  )}
                />
              </div>
            )}
          </FormGroup>
          <FormTextField
            id={'rate-quota-edit-page-description'}
            name={intl.getI18nMessage('label.rate.quota.description')}
            value={description}
            handleChange={(value) => handleChange('description', value)}
            helperText={intl.getI18nMessage(
              'label.rate.quota.description.help',
            )}
            multiline
            rows={4}
            optional
            maxLength={'255'}
          />
        </Grid>
        <Grid
          md={3}
          item
          className={classes.rightSideBar}
          id="rate-quota-edit-right-sidebar"
          data-apim-test="rate-quota-edit-right-sidebar"
        >
          {featureFlagProduct ? (
            <HelpContainer helpItems={RATE_QUOTA_HELP_ITEMS_WITH_PRODUCT} />
          ) : (
            <HelpContainer helpItems={RATE_QUOTA_HELP_ITEMS} />
          )}
        </Grid>
      </Grid>
      <FormActionButtons
        onNextClick={onNext}
        onCancelClick={onCancel}
        nextText={intl.getI18nMessage('label.save.button')}
        cancelText={intl.getI18nMessage('label.cancel.button')}
        id="rate-quota-edit-form-buttons"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isError: getIsError(state),
  isLoading: getIsLoading(state),
  rateQuotaErrors: getErrors(state),
  isSaveSuccess: getIsSaveSuccess(state),
  details: getDetails(state),
  userContext: getUserDetails(state),
  featureFlagProduct: getFeatureFlagForProduct(state),
});

const mapDispatchToProps = {
  fetch,
  save,
  update,
  push,
};

RateQuotaEdit.propTypes = {
  classes: shape({}),
  assets: shape({}),
  isLoading: bool,
  isError: bool,
  isSaveSuccess: bool,
  rateQuotaErrors: arrayOf(shape({})),
  fetch: func,
  save: func,
  update: func,
  push: func,
  userContext: shape({}),
  featureFlagProduct: bool,
  details: shape({}),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(RateQuotaEdit);
