import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import PropTypes, { bool, shape } from 'prop-types';
import {
  Chip, Divider, Grid, Typography, withStyles, Link,
} from '@material-ui/core';

import styles from './styles';
import {
  DetailsPanelContainer, Footer,
} from '../../../../components';
import {
  hasPublisherRole,
} from '../../../../utils';
import { isEmpty, map } from "lodash";
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import WarningIcon from "@material-ui/icons/Warning";
import AppsIcon from "@material-ui/icons/Apps";
import ApiAssets from "../Assets";
import { getFeatureFlagApiPlans } from "../../../../reducers/portalConfig";
import UrlContainer from "../../../../components/UrlContainer";

export const onAPITagsEdit = (uuid) => {
  const { pathname } = window.location;
  window.location.href = window.location.href.replace(
    window.location.href.endsWith('.com') ? `${pathname}#` : pathname,
    `/publish/apis/edit/${uuid}/tags`);
};

const getRightNavButtons = ({ apiUuid, featureFlagApiPlans, userContext }) => ([{
  id: 'api-applications-link',
  label: 'label.applications',
  icon: <AppsIcon/>,
  linkTo: `/admin/console/applications#api/${apiUuid}`,
  visible: true,
}, {
  id: 'api-groups-link',
  label: 'label.api.groups',
  icon: <WarningIcon/>,
  linkTo: `/admin/api-groups#list/${apiUuid}`,
  visible: hasPublisherRole(userContext) && !featureFlagApiPlans,
}]);

export const Tags = ({ tags }) => {
  return map(tags, ({ name }) => (<Chip
      variant="outlined" size="small"
      style={{ marginRight: '2px' }}
      key={name} label={name}
    />));
};

export const Overview = (props) => {
  const {
    classes,
    featureFlagApiPlans,
    notifyMessages,
    userContext,
    canEdit,
    apiUuid,
    apiDetails,
    assets,
    apiTags,
  } = props;

  const intl = getI18n(useIntl());

  const {
    locationUrl,
    ssgUrl,
    apiServiceType,
    publishedByPortal,
    description,
    privateDescription,
  } = apiDetails;
  const RightNavButtons = getRightNavButtons(
    { apiUuid, featureFlagApiPlans, userContext });
  let defaultRibbonCols = 4;
  const shouldShowApiLocation = apiDetails.publishedByPortal && canEdit
    && !isEmpty(locationUrl);
  defaultRibbonCols = shouldShowApiLocation ? defaultRibbonCols - 1: defaultRibbonCols;
  return (<div
      id="api-details-overview-container"
      data-apim-test="api-details-overview-container"
      data-layer7-test="api-details-overview-container"
    >
      <Grid
        container
        id="metadata-top-panel-container"
        data-apim-test="metadata-top-panel-container"
        data-layer7-test="metadata-top-panel-container"
        className={classes.topPanelContainer}>
        {shouldShowApiLocation &&
        <Grid item md={3} sm={12} xs={12}>
          <UrlContainer
            id="overview-api-location"
            title={intl.getI18nMessage('label.api.location')}
            proxyUrl={locationUrl}
            notifyMessages={notifyMessages}
            copyMessage={intl.getI18nMessage('label.api.details.api.location.copied')}
          />
        </Grid>
        }
        <Grid item md={defaultRibbonCols} sm={6} xs={12}>
          <UrlContainer
            id="overview-api-uri"
            title={intl.getI18nMessage('label.api.proxyUrl')}
            proxyUrl={ssgUrl}
            notifyMessages={notifyMessages}
            copyMessage={intl.getI18nMessage('label.api.details.api.uri.copied')}
          />
        </Grid>
        <Grid item md={defaultRibbonCols} sm={6} xs={12}>
          <DetailsPanelContainer
            id="overview-api-type"
            title={intl.getI18nMessage('label.api.type')}
            subTitle={apiServiceType}
          />
        </Grid>
        <Grid item md={defaultRibbonCols} sm={12} xs={12}>
          <DetailsPanelContainer
            id="overview-published-source"
            title={intl.getI18nMessage('label.api.publish.source')}
            subTitle={publishedByPortal ? intl.getI18nFormattedMessage(
                'label.proxy.details.api.portal')
              : intl.getI18nFormattedMessage(
                'label.proxy.details.api.gateway')}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider}/>
      <Grid container className={classes.marginPanelContainer}>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <div className={classes.detailsContainer}
               data-apim-test="api-details-container">
            {description && <>
            <Typography id="api-public-description-title" variant="h4">{getI18nFormattedMessage(
              'label.public.description')}</Typography>
            <div data-apim-test="api-public-description"
                 className={classes.detailsSubTitle}>{description}</div>
            </>}
            {(canEdit && privateDescription) && <>
              <Typography id="api-private-description-title" variant="h4">{getI18nFormattedMessage(
                'label.private.description')}</Typography>
              <div data-apim-test="api-private-description"
                   className={classes.detailsSubTitle}>{privateDescription}</div>
            </>}
            <Typography id="api-tags-title" variant="h4">{getI18nFormattedMessage(
              'label.api.tags')}</Typography>
            <span>
              <Tags tags={apiTags}/>
              {canEdit && <Link id={'api-tags-edit'}
                className={classes.editTagsLink}
                onClick={() => onAPITagsEdit(apiUuid)}>
                {intl.getI18nFormattedMessage('label.edit')}
              </Link>}
            </span>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <div className={classes.detailsContainer}
               data-apim-test="api-reference-buttons">
            <Typography variant="h4">{intl.getI18nFormattedMessage(
              'label.usage')}</Typography>
            <ul>
              {RightNavButtons.filter(({ visible }) => visible).map(
                ({ id, label, linkTo }) => (<li key={label}><Link
                  id={id} href={linkTo}>{intl.getI18nFormattedMessage(label)}</Link>
                  </li>))}
            </ul>
            {assets &&
            <ApiAssets api={apiDetails} apiAssets={assets}/>
            }
          </div>
        </Grid>
      </Grid>
      <Footer/>
    </div>);
};

const mapStateToProps = state => ({
  featureFlagApiPlans: getFeatureFlagApiPlans(state),
});

Overview.propTypes = {
  classes: shape({}),
  featureFlagApiPlans: bool,
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  canEdit: PropTypes.bool,
  assets: PropTypes.arrayOf(PropTypes.object),
  apiTags: PropTypes.arrayOf(PropTypes.object),
  userContext: PropTypes.object,
  notifyMessages: PropTypes.func,
};
Overview.displayName = 'Overview';

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  )(Overview);
