import React from 'react';
import Actions from './Actions';
import { Link, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import {
  PROXY_DEPLOYMENT_TYPE_AUTOMATIC,
  PROXY_DEPLOYMENT_TYPE_ON_DEMAND,
  PROXY_DEPLOYMENT_TYPE_SCRIPTED,
} from '../../../../constants';
import Status from './Status';
import { hasAdminRole } from '../../../../utils';

export const getDeploymentsListColumns = (
  intl,
  getDateTime,
  updatingProxies,
  setUpdatingProxies,
  userContext,
  canEdit,
  apiDetails,
  apiUuid,
  fetchDeploymentByApi,
  deploymentErrors,
  refreshTime,
  classes,
) => {
  const renderHelpTextDeploymentTypes = () => (
    <div className={classes.helpTextContainer}>
      <div>
        <span className={classes.boldText}>
          {intl.getI18nFormattedMessage(
            'label.api.deployments.list.column.deployment.type',
          )}
        </span>
        <br />
        <span>
          {intl.getI18nFormattedMessage(
            'label.api.deployments.help.deployment.types.desc',
          )}
        </span>
      </div>
      <br />
      <li>
        <span className={classes.boldText}>
          {intl.getI18nFormattedMessage('label.api.deployments.type.automatic')}
          {': '}
        </span>
        {intl.getI18nFormattedMessage(
          'label.api.deployments.help.automatic.desc',
        )}
      </li>
      <br />
      <li>
        <span className={classes.boldText}>
          {intl.getI18nFormattedMessage('label.api.deployments.type.on.demand')}{' '}
          {': '}
        </span>
        {intl.getI18nFormattedMessage(
          'label.api.deployments.help.on.demand.desc',
        )}
      </li>
      <br />
      <li>
        <span className={classes.boldText}>
          {intl.getI18nFormattedMessage('label.api.deployments.type.manual')}
          {': '}
        </span>
        {intl.getI18nFormattedMessage('label.api.deployments.help.manual.desc')}
      </li>
    </div>
  );

  return [
    {
      id: 'proxyName',
      label: intl.getI18nFormattedMessage(
        'label.api.deployments.list.column.name',
      ),
      value: (item) =>
        hasAdminRole(userContext) ? (
          <Link
            underline="none"
            href={`/publish/api-proxy/details/${item.uuid}`}
          >
            {item.name}
          </Link>
        ) : (
          <span>{item.name}</span>
        ),
    },
    {
      id: 'lastTimeDeployed',
      label: intl.getI18nFormattedMessage(
        'label.api.deployments.list.column.last.updated',
      ),
      value: (item) => {
        const proxy = updatingProxies.find(
          (proxy) => proxy === item.uuid, // Verify values here
        );

        return proxy
          ? intl.getI18nMessage('label.updating')
          : item.lastTimeDeployed
          ? getDateTime(item.lastTimeDeployed)
          : intl.getI18nFormattedMessage('label.not.applicable');
      },
    },
    {
      id: 'deploymentType',
      label: (
        <>
          {intl.getI18nFormattedMessage(
            'label.api.deployments.list.column.deployment.type',
          )}
          <Tooltip
            title={renderHelpTextDeploymentTypes()}
            arrow
            placement="right"
          >
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        </>
      ),
      value: (item) => {
        switch (item.deploymentType) {
          case PROXY_DEPLOYMENT_TYPE_AUTOMATIC:
            return intl.getI18nFormattedMessage(
              'label.api.deployments.type.automatic',
            );
          case PROXY_DEPLOYMENT_TYPE_ON_DEMAND:
            return intl.getI18nFormattedMessage(
              'label.api.deployments.type.on.demand',
            );
          case PROXY_DEPLOYMENT_TYPE_SCRIPTED:
            return intl.getI18nFormattedMessage(
              'label.api.deployments.type.manual',
            );
          default:
            return item.deploymentType;
        }
      },
    },
    {
      id: 'status',
      label: intl.getI18nFormattedMessage(
        'label.api.deployments.list.column.status',
      ),
      value: (item) => (
        <Status
          apiDetails={apiDetails}
          details={{
            status: item.status,
            statusMessage: item.message,
            proxyUuid: item.uuid,
            type: item.deploymentType,
          }}
          updatingProxies={updatingProxies}
          setUpdatingProxies={setUpdatingProxies}
          errorObj={deploymentErrors.find((err) => err.proxyUuid === item.uuid)}
          refreshTime={refreshTime}
          checkStatus={() => {
            fetchDeploymentByApi(apiUuid);
          }}
          classes={classes}
        />
      ),
    },
    {
      id: 'actions',
      label: intl.getI18nFormattedMessage(
        'label.api.deployments.list.column.deployment.actions',
      ),
      value: (item) => (
        <Actions
          userContext={userContext}
          canEdit={canEdit}
          apiDetails={apiDetails}
          details={{
            name: item.name,
            status: item.status,
            statusMessage: item.message,
            modifyTs: item.lastTimeDeployed,
            apiUuid: apiUuid,
            proxyUuid: item.uuid,
            type: item.deploymentType,
          }}
          updatingProxies={updatingProxies}
          setUpdatingProxies={setUpdatingProxies}
        />
      ),
    },
  ];
};
