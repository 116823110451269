import React, { createContext, Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Link, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Radio, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, lowerCase, map } from 'lodash';
import { omit, uniq } from 'lodash';
import { fetchAllTags, fetchAPIs } from '../../../../actions/api';
import {
  updateAppAssignedAPIs,
  fetchAppAssignedApiPlans,
  updateAppAssignedApiPlans,
  fetchAvailableApiPlans,
  fetchApiEula,
  } from '../../../../actions/application';
import { Switch, FormDialog as Dialog, LoadingDialog } from '../../../../components';
import {
  getAllPortalTags,
  getAPIs,
  getAPIListTotalElements,
  getAPIListTotalPages,
} from '../../../../reducers/api';
import {
  getAppAssignedAPIsUpdateResult,
  getIsappAssignedApisFailure,
  getAppAssignedApiPlans,
  getIsappAssignedApiPlansFailure,
  getAvailableApiPlans,
  getAppAssignedApiPlansUpdateResult,
  getIsLoading,
  getApiEula,
  getErrors,
 } from '../../../../reducers/application';
import styles from './styles';
import ListContainer from '../../../list';
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
} from '../../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { getConfig } from '../../../../reducers/portalConfig';
import {
  FilterByName,
  HeaderActionButtons,
  Tags,
} from './controls';
import { FilterByTags } from '../../../Api/List/controls';
import useNotification from '../../../../hooks/useNotification';
import { Lock, ReportProblem } from '@material-ui/icons';
export const AppAPIAccessListContext = createContext();
export const AppAPIManagement = (props) => {
  const {
    allTags,
    classes,
    isLoading,
    totalPages,
    totalElements = 0,
    apis,
    fetchAllTags,
    assignedAPIs = [],
    applicationDetails = {},
    assignedAPIsUpdateResult,
    eulaDetails,
    apiPlansEnabled,
    isGetAssignedApisFailed,
    assignedApiPlans,
    isGetAssignedApiPlansFailed,
    assignedApiPlansUpdateResult,
    availableApiPlans,
    locked,
    isLocking,
    isSaveClicked,
    setApisTabDirty,
    setSaveClicked,
    disabledByInternal,
    isEditDisabled,
    errors,
  } = props;
  const SHOW_ERROR_FROM_BACKEND_LIST = ['error.validation.applications.save.noapisorapigroups'];
  const isApiAssigned = uuid => apiPlansEnabled ?
    Object.keys(apiPlansToBeSaved).includes(uuid) : apisToBeSaved.includes(uuid);
  const formatSwitch = (uuid, portalStatus) => (<div className={classes.accessField}>
    <Switch
      id={`${uuid}-access`}
      onChange={(checked) => onSwitch(checked, uuid)}
      checked={isApiAssigned(uuid)}
      disabled={locked || ((portalStatus === 'DEPRECATED' || portalStatus === 'DISABLED') && !isApiAssigned(uuid)) || isEditDisabled}
    />
    {(portalStatus === 'DEPRECATED' || portalStatus === 'DISABLED') && !locked && !isEditDisabled &&
      <Tooltip
        title={getI18nFormattedMessage(`label.application.details.${lowerCase(portalStatus)}.api.locked`)}
        arrow placement="right">
          <ReportProblem className={classes.warningIcon}/>
      </Tooltip>
    }
  </div>);
  const onSwitch = (checked, uuid) => {
    setIsUndoAllowed(false);
    if (checked) {
      const switchingApi = apis.find(api => api.uuid === uuid);
      props.fetchApiEula(switchingApi.apiEulaUuid);
      setEulaApiMap({
        [switchingApi.apiEulaUuid] : [switchingApi],
      });
      if(apiPlansEnabled) {
        setUpdatingQRLApi(uuid);
        setShowQRLDialog(true);
        setApiPlanUpdateFlow(false);
      } else {
        setRowsToAdd([uuid]);
        setIsApiEulaDialogOpen(true);
      }
    } else {
      if(apiPlansEnabled) {
        const tempApiPlanMap = apiPlansToBeSaved;
        delete tempApiPlanMap[uuid];
        setApiPlansToBeSaved(tempApiPlanMap);
        updateApiPlans();
      } else {
        updateApis('unassign', [uuid]);
      }
    }
  }

  const getLinkContent = (value, uuid) => (
    <Link href={`/publish/apis/details/${uuid}`}>
      {value}
    </Link>
  );
  const formatApiPlan = (uuid) => {
    if(availableApiPlans[uuid] &&
      availableApiPlans[uuid].length > 0 &&
      Object.keys(apiPlansToBeSaved).includes(uuid)) {
        const apiPlan = availableApiPlans[uuid].find(apiPlan =>
          apiPlan.uuid === apiPlansToBeSaved[uuid]);
        if(apiPlan) {
          return (
            (locked || isEditDisabled) ?
            <span>{apiPlan.name}</span> :
            <Link component="button" onClick={() => onApiPlanClick(uuid)}>{apiPlan.name}</Link>
          )
        }
    }
    return "";
  };
  const onApiPlanClick = (uuid) => {
    setUpdatingQRLApi(uuid);
    setApiPlanUpdateFlow(true);
    setShowQRLDialog(true);
  };
  const formatApiAssignTitle = () => (<div className={classes.assignToolTip}>
    {getI18nFormattedMessage('label.application.details.apis.assign.column.title')}
    {locked &&
      <Tooltip
        title={getI18nFormattedMessage(disabledByInternal? 'label.application.details.locked.by.internal.tooltip':
        'label.application.details.apis.locked.tooltip')}
        arrow placement="right">
        <Lock className={classes.lockIcon}/>
      </Tooltip>}
    {!locked && isLocking &&
      <Tooltip
        title={getI18nFormattedMessage('label.application.details.apis.locking.tooltip')}
        arrow placement="right">
        <ReportProblem className={classes.warningIcon}/>
      </Tooltip>
    }
    </div>
    );
  const getAppAPIAssignListColumns = () => {
    const columns = [{
      id: 'name',
      label: getI18nFormattedMessage('label.application.details.apis.api.column.title'),
      minWidth: 200,
      value: (item) => getLinkContent(item.name, item.uuid),
    }, {
      id: 'tags',
      label: getI18nFormattedMessage('label.application.details.apis.tags.column.title'),
      minWidth: 300,
      value: Tags,
    }, {
      id: 'assign',
      label: formatApiAssignTitle(),
      minWidth: 100,
      value: (item) => formatSwitch(item.uuid, item.portalStatus),
    }];
    if(apiPlansEnabled) {
      columns.push( {
        id: 'apiplan',
        label: getI18nFormattedMessage('label.application.details.apis.apiplan.column.title'),
        minWidth: 100,
        value: (item) => formatApiPlan(item.uuid),
      } );
    }
    return columns;
  }

  const getAppApiApiPlansListColumns = () => [{
    id: 'selected',
    label: '',
    minwidth: 30,
    maxWidth: 40,
    value: (item) => <Radio id={item.uuid}
                        name="api-plan-radio"
                        checked={item.uuid === apiPlansToBeSaved[updatingQRLApi]}
                        onChange={() => setApiPlansToBeSaved({
                          ...apiPlansToBeSaved,
                          [updatingQRLApi] :item.uuid })
                        }
                        className={classes.radioButton}
                      />,
    },
    {
    id: 'apiplan',
    label: getI18nFormattedMessage('label.application.details.apis.apiplans.dialog.columns.apiplan.title'),
    minwidth: 100,
    value: (item) => item.name,
  },
  {
    id: 'qrl',
    label: getI18nFormattedMessage('label.application.details.apis.apiplans.dialog.columns.qrl.title'),
    minwidth: 100,
    value: (item) => getQRLContent(item),
  }];
  const getQRLContent= item => {
    const limit = get(item, 'rateLimit') ? `${get(item, 'rateLimit')} / sec` : 'None';
    const quota = get(item, 'quota') ? `${get(item, 'quota')} / ${lowerCase(get(item, 'quotaInterval'))}`
    : 'None';
    return `Rate: ${limit}; Quota: ${quota}`;
  }
  const intl = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [notification, setNotification] = useNotification({ status: '', message: '' });
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [appliedNameFilter, setAppliedNameFilter] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByTag, setFilterByTag] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [undoAction, setUndoAction] = useState('');
  const [undoPayload, setUndoPayload] = useState([]);
  const [selectedApiMap, setSelectedApiMap] = useState({});
  const [eulaApiMap, setEulaApiMap] = useState();
  const [isApiEulaDialogOpen, setIsApiEulaDialogOpen] = useState(false);
  const [rowsToAdd, setRowsToAdd] = useState([]);
  const [eulaDetailsMap, setEulaDetailsMap] = useState();
  const [expandedEulaPanel, setExpandedEulaPanel] = useState('first');
  const [isUndoAllowed, setIsUndoAllowed] = useState(false);
  const [showQRLDialog, setShowQRLDialog] = useState(false);
  const [updatingQRLApi,setUpdatingQRLApi] = useState('');
  const [apiPlanUpdateFlow, setApiPlanUpdateFlow] = useState('');
  const [apiPlanMap, setApiPlanMap] = useState({});
  const [apisToBeSaved, setApisToBeSaved] = useState([]);
  const [apiPlansToBeSaved, setApiPlansToBeSaved] = useState([]);
  const [updatingApis, setUpdatingApis] = useState(false);
  const allTagsOptions = allTags.map(({ name }) => ({ value: name, label: name }));
  useEffect(() => {
    if(applicationDetails && applicationDetails.organizationUuid && (!apis || apis.length === 0)) {
      props.fetchAPIs({
        page: 0,
        size: GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
        portalStatus: 'ENABLED,DEPRECATED,DISABLED',
        orgUuid: applicationDetails.organizationUuid,
      });
      if(apiPlansEnabled) {
        props.fetchAppAssignedApiPlans(applicationDetails.uuid);
      }
    }
  }, [applicationDetails]);
  useEffect(() => {
    fetchAllTags();
  }, []);
  useEffect(() => {
    if(apiPlansEnabled && apis && apis.length > 0) {
      apis.forEach(api => {
        if(!availableApiPlans[api]) {
          props.fetchAvailableApiPlans(applicationDetails.organizationUuid, api.uuid);
        }
      });
    }
  },[apis]);
  useEffect(() => {
    if(assignedAPIsUpdateResult === 'FAILURE') {
      let errorMessage = intl.getI18nMessage('label.application.details.apis.save.failed');
      if(errors && errors.length > 0 && SHOW_ERROR_FROM_BACKEND_LIST.includes(errors[0].key)) {
        errorMessage = errors[0].error;
      }
      setNotification({ status: ALERT_ERROR,
        message: errorMessage });
      window.setTimeout(() => setNotification(), 3000);
      props.fetchAssignedAPIs(applicationDetails.uuid);
    } else if(assignedAPIsUpdateResult === 'SUCCESS') {
      props.fetchAssignedAPIs(applicationDetails.uuid);
      props.onSaveDone();
      setSelectedRows([]);
      setApisTabDirty(false);
      const onClickUndo = () => {
        setIsUndoAllowed(false);
        updateApis(undoAction, undoPayload);
      };
      setNotification({
        status: ALERT_SUCCESS,
        message: (
          <>
            <span>{intl.getI18nMessage(`label.application.details.apis.${isLocking ? 'submit' : 'save'}.success`)}</span>
            { isUndoAllowed && !isLocking &&
              (<Button onClick={onClickUndo}>
                {intl.getI18nMessage('label.undo')}
              </Button>)
            }
          </>
        ),
      });
      window.setTimeout(() => setNotification(), 3000);
    }
    setUpdatingApis(assignedAPIsUpdateResult === 'UPDATING');
  }, [assignedAPIsUpdateResult]);
  useEffect(() => {
    if(eulaDetails && eulaDetails.uuid) {
      setEulaDetailsMap({
        ...eulaDetailsMap,
        [eulaDetails.uuid]: eulaDetails,
      })
    }
  },[eulaDetails]);
  useEffect(() =>{
    const selectedApisMapLocal = selectedApiMap;
    apis.forEach(api => {
      if(selectedRows.includes(api.uuid)) {
        selectedApisMapLocal[api.uuid] = api;
      } else {
        delete selectedApisMapLocal[api.uuid];
      }
    });
    setSelectedApiMap(selectedApisMapLocal);
  },[selectedRows]);
  useEffect(() => {
    if(isSaveClicked) {
      if(apiPlansEnabled) {
        callUpdateApiPlans(apiPlansToBeSaved);
      } else {
        callUpdateApis(apisToBeSaved);
      }
      setSaveClicked(false);
    }
  },[isSaveClicked]);
  useEffect(() => {
    if(assignedAPIs) {
      setApisToBeSaved(assignedAPIs);
    }
  },[assignedAPIs]);
  useEffect(() => {
    if(isGetAssignedApisFailed) {
      setNotification({ status: ALERT_ERROR,
        message: getI18nFormattedMessage('label.application.details.apis.get.failed') });
    }
  },[isGetAssignedApisFailed]);
  useEffect(() => {
    if(isGetAssignedApiPlansFailed) {
      setNotification({ status: ALERT_ERROR,
        message: getI18nFormattedMessage('label.application.details.apiplans.get.failed') });
    }
  },[isGetAssignedApiPlansFailed]);
  useEffect(() => {
    if(assignedApiPlansUpdateResult === 'FAILURE') {
      setNotification({ status: ALERT_ERROR,
        message: getI18nFormattedMessage('label.application.details.apiplans.save.failed') });
      window.setTimeout(() => setNotification(), 3000);
      props.fetchAppAssignedApiPlans(applicationDetails.uuid);
      props.fetchAssignedAPIs(applicationDetails.uuid);
    } else if(assignedApiPlansUpdateResult === 'SUCCESS'){
      props.fetchAppAssignedApiPlans(applicationDetails.uuid);
      props.fetchAssignedAPIs(applicationDetails.uuid);
      setApisTabDirty(false);
      props.onSaveDone();
      setNotification({
        status: ALERT_SUCCESS,
        message: isLocking ?
          getI18nFormattedMessage('label.application.details.apiplans.submit.success') :
          getI18nFormattedMessage('label.application.update.success'),
      });
      window.setTimeout(() => setNotification(), 3000);
    }
    setUpdatingApis(assignedApiPlansUpdateResult === 'UPDATING');
  }, [assignedApiPlansUpdateResult]);
  useEffect(() => {
    if(assignedApiPlans && assignedApiPlans.length > 0) {
      const tempApiPlanMap = {};
      assignedApiPlans.forEach(obj => tempApiPlanMap[obj.uuid]=obj.apiPlanUuid);
      setApiPlanMap(JSON.parse(JSON.stringify(tempApiPlanMap)));
      setApiPlansToBeSaved(JSON.parse(JSON.stringify(tempApiPlanMap)));
    }
  },[assignedApiPlans]);
  const anyFilterApplied = () =>
      (filterByName && filterByName !== '') ||
      (filterByTag && filterByTag.length > 0);

  const handleBlur = () => {
    if (appliedNameFilter !== filterByName) {
      setAppliedNameFilter(filterByName);
      applyFilter('name', filterByName);
    }
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter' && appliedNameFilter !== filterByName) {
      setAppliedNameFilter(filterByName);
      applyFilter('name', filterByName);
    }
  };

  const onChangePage = (newPage) => {
   if(page !== newPage) {
    setPage(newPage);
    applyFilter('page', newPage);
   }
  };
  const applyFilter = (field, value) => {
    const filter ={
      name: filterByName,
      tags: filterByTag,
      page: page,
      size: rowsPerPage,
      portalStatus: 'ENABLED,DEPRECATED,DISABLED',
      orgUuid: applicationDetails.organizationUuid,
    };
    if(field !== 'page') {
      filter['page'] = 0;
      setPage(0);
    }
    filter[field] = value;
    props.fetchAPIs(filter);
  }
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };
  const onTagsSelectionChange = (items) => {
    const tagsSelected = uniq(map(items, item => item.value));
    setFilterByTag(tagsSelected);
    applyFilter('tags', tagsSelected);
  };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    applyFilter('size', newRowsPerPage);
  };

  const isDisableAddAccess = () => !selectedRows.some(uuid => !apisToBeSaved.includes(uuid));
  const isDisableRemoveAccess = () => !selectedRows.some(uuid => apisToBeSaved.includes(uuid));
  const onBulkUpdate = (action) => {
    const rowsToConsider = selectedRows.filter(item => action === 'assign' ?
      !apisToBeSaved.includes(item): apisToBeSaved.includes(item));
    if(action === 'assign') {
      const eulaApiMapLocal={};
      Object.keys(selectedApiMap).filter(apiUuid => rowsToConsider.includes(apiUuid))
        .forEach(apiUuid => {
          const eulaUuid = selectedApiMap[apiUuid].apiEulaUuid;
          eulaApiMapLocal[eulaUuid]= eulaApiMapLocal[eulaUuid] ?
           [...eulaApiMapLocal[eulaUuid], selectedApiMap[apiUuid]] : [selectedApiMap[apiUuid]];
        });
      setEulaApiMap(eulaApiMapLocal);
      Object.keys(eulaApiMapLocal).forEach(eulaUuid => props.fetchApiEula(eulaUuid));
      setRowsToAdd(rowsToConsider);
      setIsApiEulaDialogOpen(true);
    } else {
      updateApis(action, rowsToConsider);
    }
    setIsUndoAllowed(true);
  };
  const onClickTag = (tag) => {
    const tagFilter = uniq([...filterByTag, tag]);
    setFilterByTag(tagFilter);
    applyFilter('tags', tagFilter);
  }
  const noResultsMessage = anyFilterApplied ?
      intl.getI18nMessage('label.application.details.apis.filter.no.results') :
      intl.getI18nMessage('label.application.details.apis.no.results');
  const updateApis = (action, apis) => {
    setUndoAction(action === 'assign' ? 'unassign' : 'assign');
    setUndoPayload(apis);
    const apisToSave = action === 'assign' ?
        uniq([...apisToBeSaved, ...apis]) :
        apisToBeSaved.filter(api => !apis.includes(api));
    setApisToBeSaved(apisToSave);
    if(isLocking) {
      setApisTabDirty(apisToSave.length !== assignedAPIs.length ||
        apisToSave.some(api => !assignedAPIs.includes(api)));
    } else {
      callUpdateApis(apisToSave);
    }
  };
  const callUpdateApis = (apisToSave) => {
    props.updateAppAssignedAPIs(
      applicationDetails.uuid, apisToSave.map(api => { return { 'uuid' : api } }));
  };
  const updateApiPlans = () => {
    if(isLocking) {
      const isDirty = (Object.keys(apiPlanMap).length !== Object.keys(apiPlansToBeSaved).length) ||
       Object.keys(apiPlanMap).some(key => apiPlanMap[key] !== apiPlansToBeSaved[key]);
      setApisTabDirty(isDirty);
    } else {
      callUpdateApiPlans(apiPlansToBeSaved);
    }
  }
  const callUpdateApiPlans = (apiPlanMap) => {
    const updatingApiPlansList = Object.keys(apiPlanMap).map(planMapKey => {
      return {
        uuid: planMapKey,
        apiPlanUuid: apiPlanMap[planMapKey],
      };
    });
    props.updateAppAssignedApiPlans(applicationDetails.uuid, updatingApiPlansList);
  };
  const handleExpandEulaPanel = eulaUuid => (event, expanded) => {
    setExpandedEulaPanel(expanded ? eulaUuid : false);
  }
  const apiPlansLoading = () =>
      apis.find(api => !Object.keys(availableApiPlans).includes(api.uuid));

  const listClasses = omit(classes, [
    'accordionTitleBold', 'accordionTitle', 'actionsFieldContainer', 'actionsLabel', 'addTagsButton', 'apiGroupPanel',
    'accessField', 'assignToolTip', 'blueTick', 'fieldContainer', 'greyTick', 'headerButtons',
    'helpIconContainer', 'lockIcon', 'managedContainer', 'pageBodyClass', 'pageClass', 'panelContent',
    'paperClass', 'radioButton', 'removeTagsButton', 'statusIcon', 'tagButtons', 'tagsContainer',
    'textFieldContainer', 'textFiltersContainer', 'warningIcon',
  ]);
  const apiPlanSelectDialog = () => (
    <ListContainer
        listPageId="app-api-apiplan-dialog"
        isLoading={isLoading}
        columns={getAppApiApiPlansListColumns()}
        rows={availableApiPlans[updatingQRLApi]}
        noResultsMessage={intl.getI18nMessage('label.application.details.apis.apiplans.no.results')}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
        classes={listClasses}
        hasPaginationDisabled={true}
        hidePagination
        hideFooter
      />
  );
  const renderApiEulaDialog = () => (
    <Fragment>
      {eulaApiMap &&
       eulaDetailsMap &&
       Object.keys(eulaApiMap).map((apiEula, index) => ( eulaDetailsMap[apiEula] &&
        <ExpansionPanel
          expanded={expandedEulaPanel === apiEula || (index === 0 && expandedEulaPanel === 'first')}
          className={classes.apiGroupPanel}
          key={apiEula}
          onChange={handleExpandEulaPanel(apiEula)}
          >
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <span className={classes.heading}>
              <span className={classes.accordionTitleBold}>
                {getI18nFormattedMessage('label.application.details.apis.eulas.accordion.title',
              { index: index+1, count: Object.keys(eulaApiMap).length }) }</span>
              <span className={classes.accordionTitle}>{eulaApiMap[apiEula] && eulaApiMap[apiEula].map(api => api.name).join(', ')}</span>
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <span
              className={classes.panelContent}
              dangerouslySetInnerHTML={{ __html: (eulaDetailsMap[apiEula].content) }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        ))}
    </Fragment>
  );
  return (
    <AppAPIAccessListContext.Provider value={{ onClickTag }}>
     <Dialog
        dialogId="application-api-group-eula-dialog"
        isOpen={isApiEulaDialogOpen}
        title={intl.getI18nMessage('label.application.details.apis.eulas.title')}
        submitText={intl.getI18nMessage('label.application.details.apis.eulas.acceptbutton')}
        isDialogContentText={false}
        onSubmit={() => {
          if(apiPlansEnabled) {
            updateApiPlans();
          } else {
            updateApis('assign', rowsToAdd);
          }
          setIsApiEulaDialogOpen(false);
        }}
        cancelText={intl.getI18nMessage('label.application.details.apis.apiplans.dialog.cancel.button')}
        onCancel={() => { setIsApiEulaDialogOpen(false);
                const tempApiPlans = apiPlansToBeSaved;
                delete tempApiPlans[updatingQRLApi];
                setApiPlansToBeSaved(tempApiPlans);}}
        paperClass={classes.paperClass}
        handleClose={() => setIsApiEulaDialogOpen(false)}
      >
        {renderApiEulaDialog()}
      </Dialog>
      <LoadingDialog
        isOpen={updatingApis}
        title={intl.getI18nMessage('label.application.details.apis.updating.title')}
      />
      <Dialog
        dialogId="application-api-apiplan-dialog"
        isOpen={showQRLDialog}
        title={intl.getI18nMessage('label.application.details.apis.apiplans.dialog.title')}
        submitText={intl.getI18nMessage('label.application.details.apis.apiplans.dialog.add.button')}
        isDialogContentText={false}
        onSubmit={() => {
          setShowQRLDialog(false);
          if(!apiPlanUpdateFlow) {
            setIsApiEulaDialogOpen(true);
            if(availableApiPlans[updatingQRLApi].length < 1) {
              setApiPlansToBeSaved({
                ...apiPlansToBeSaved,
                [updatingQRLApi] : null });
            }
          } else {
            updateApiPlans();
          }
        }}
        cancelText={intl.getI18nMessage('label.application.details.apis.apiplans.dialog.cancel.button')}
        onCancel={() => { setShowQRLDialog(false);
            if(!apiPlanUpdateFlow) {
              const tempApiPlans = apiPlansToBeSaved;
              delete tempApiPlans[updatingQRLApi];
              setApiPlansToBeSaved(tempApiPlans);
            }
          }}
        handleClose={() => setShowQRLDialog(false)}
        paperClass={classes.paperClass}
        submitButtonDisabled={!apiPlansToBeSaved[updatingQRLApi] &&
           availableApiPlans[updatingQRLApi] &&
           availableApiPlans[updatingQRLApi].length>0}
      >
        {apiPlanSelectDialog()}
      </Dialog>
    
      <ListContainer
        listPageId="organization-apiaccesslist-page"
        isLoading={isLoading ||
                  (apiPlansEnabled && apiPlansLoading())}
        notificationId="apis-org-access-notifications"
        notificationStatus={notification.status}
        setNotificationStatus={(status) => setNotification({ status, message: '' })}
        notificationMessage={notification.message}
        setNotificationMessage={(message) => setNotification({ status: '', message })}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        columns={getAppAPIAssignListColumns()}
        rows={apis}
        filterAndSortContent={(
          <>
            <div className={classes.textFiltersContainer}>
              <FilterByName
                fieldContainerClass={classes.textFieldContainer}
                name={intl.getI18nMessage('label.filter')}
                value={filterByName}
                placeholder={intl.getI18nMessage('label.org.apilist.filter.name.placeholder')}
                handleChange={val=>setFilterByName(val)}
                onKeyPress={onFilterByNameKeyPress}
                handleBlur={handleBlur}
              />
              <FilterByTags
                fieldContainerClass={classes.tagsContainer}
                name="tags"
                onChange={onTagsSelectionChange}
                noOptionsMessage={() => intl.getI18nMessage('label.application.api.tag.nooptions.text')}
                optionsData={allTagsOptions}
                placeholder={intl.getI18nMessage('label.application.api.tag.placeholder.text')}
                value={filterByTag}
              />
            </div>
          </>
        )}
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
        classes={listClasses}
        bulkActionsContent={!apiPlansEnabled && !locked &&
          (<HeaderActionButtons
            {...props}
            fieldContainerClass={classes.fieldContainer}
            bulkActionsItemsCount={selectedRows.length}
            classes={classes}
            onAssign={() => onBulkUpdate('assign')}
            onUnassign={() => onBulkUpdate('unassign')}
            disableAdd={isDisableAddAccess()}
            disableRemove={isDisableRemoveAccess()}
          />)
        }
      />
    </AppAPIAccessListContext.Provider>
    );
};

AppAPIManagement.propTypes = {
  classes: object,
  orgUuid: string,
  isLoading: bool,
  isError: bool,
  totalPages: number,
  totalElements: number,
  allTags: arrayOf(string),
  fetchAllTags: func,
  config: object,
  isLoadingError: bool,
  apis: arrayOf(object),
  fetchAPIs: func,
  updateAssignedAPIs: func,
  applicationDetails: object,
  fetchAssignedAPIs: func,
  assignedAPIs: arrayOf(object),
  assignedAPIsUpdateResult: bool,
  updateAppAssignedAPIs: func,
  eulaDetails: object,
  fetchApiEula: func,
  apiPlansEnabled: bool,
  updateAppAssignedApiPlans: func,
  fetchAppAssignedApiPlans: func,
  isGetAssignedApisFailed: bool,
  assignedApiPlans: arrayOf(object),
  isGetAssignedApiPlansFailed: bool,
  assignedApiPlansUpdateResult: string,
  allApiPlans: arrayOf(object),
  fetchAvailableApiPlans: func,
  availableApiPlans: arrayOf(object),
  locked: bool,
  isLocking: bool,
  setApisTabDirty: func,
  isSaveClicked: bool,
  setSaveClicked: func,
  onSaveDone: func,
  disabledByInternal: bool,
  isEditDisabled: bool,
  getErrors: func,
  errors: arrayOf(object),
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  allTags: getAllPortalTags(state),
  apis: getAPIs(state),
  isLoading: getIsLoading(state),
  totalElements: getAPIListTotalElements(state),
  totalPages: getAPIListTotalPages(state),
  assignedAPIsUpdateResult: getAppAssignedAPIsUpdateResult(state),
  eulaDetails: getApiEula(state),
  isGetAssignedApisFailed: getIsappAssignedApisFailure(state),
  assignedApiPlans: getAppAssignedApiPlans(state),
  isGetAssignedApiPlansFailed: getIsappAssignedApiPlansFailure(state),
  assignedApiPlansUpdateResult: getAppAssignedApiPlansUpdateResult(state),
  availableApiPlans: getAvailableApiPlans(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  fetchAllTags,
  fetchAPIs,
  updateAppAssignedAPIs,
  fetchApiEula,
  fetchAppAssignedApiPlans,
  updateAppAssignedApiPlans,
  fetchAvailableApiPlans,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AppAPIManagement);
