import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, fontSize, typography }) => ({
  appConfigurationContainer: {
    padding: '0 36px',
    '& table': {
      backgroundColor: get(color, 'uiBackground'),
    },
    '& tr': {
      borderTop: `1px solid ${get(color, 'borderColor')}`,
    },
    '& th': {
      verticalAlign: 'middle',
      color: get(color, 'bodyText'),
      padding: 2,
      paddingLeft: 12,
      cursor: 'pointer',
      border: 'none',
      '&:first-child' : {
        color: get(color, 'primaryButtonBackground'),
      },
    },
    '& td': {
      verticalAlign: 'middle',
      padding: 12,
      border: 'none',
    },
  },
  paperClass: {
    padding: 32,
    paddingTop: 0,
    maxHeight: 'calc(100% - 125px)',
    maxWidth: 750,
  },
  generateSecretDialog: {
    fontFamily: `${get(typography, 'bodyText')} !important`,
    color: get(color, 'bodyText'),
    margin: 20,
    width: 500,
  },
  errorBackgroundButton: {
    backgroundColor: get(color, 'error'),
    color: get(color, 'bodyBackground'),
    '&:hover': {
      color: get(color, 'bodyBackground'),
      backgroundColor: get(color, 'error'),
    },
    marginRight: 20,
  },
  cancelButton: {
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  dialogButtonPanel: {
    marginTop: 30,
  },
  generateSecretDialogContent: {
    fontSize: `${get(fontSize, 'bodyText')} !important`,
  },
  
  button: {
    color: get(color, 'primaryButtonBackground'),
  },
  expandCollapseButton: {
    color: get(color, 'primaryButtonBackground'),
    float: 'right',
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  tableHead: {
    '& th': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
      cursor: 'pointer',
      padding: 12,
    },
  },
  generateSecretContainer: {
    display: 'flex',
  },
  generateSecretMenuButton: {
    border: `1px solid ${get(color, 'borderColor')}`,
    color: get(color, 'error'),
    '&:hover': {
      color: get(color, 'error'),
    },
    '&:focus': {
      border: `1px solid ${get(color, 'error')}`,
    },
    marginBottom: 50,
    height: 'fit-content',
  },
  paginationContainer: {
    margin: '0 !important',
    '& .MuiTablePagination-toolbar': {
      margin: '0 !important',
    },
  },
  singleKeyViewContainer: {
    backgroundColor: `${get(color, 'uiBackground')} !important`,
  },
  addKeyButton: {
    marginLeft: 'auto',
    marginTop: 30,
    marginBottom: 30,
    marginRight: 50,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'primaryButtonBackground'),
    },
  },
  filtersAndAdd: {
    float: 'right',
  },
  singleKeyViewCell: {
    padding: 20,
    backgroundColor: convertHexToRGB(get(color, 'borderColor'), 0.3),
  },
  defaultChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'bodyText'), 0.05)} !important`,
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  enabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'success'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  disabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'error'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  leftPanel: {
    backgroundColor: `${get(color, 'uiBackground')} !important`,
    width: '80%',
    padding: 20,
    '& a': {
      color: get(color, 'link'),
    },
    wordBreak: 'break-all',
  },
  leftPanelItemContainer: {
    marginTop: '10px !important',
    marginBottom: '10px !important',
  },
  leftPanelTitle: {
    backgroundColor: 'transparent !important',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold !important',
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: '21px !important',
  },
  leftPanelDesc: {
    backgroundColor: 'transparent !important',
    lineHeight: '21px !important',
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    outline: 'transparent !important',
    '-webkit-font-smoothing': 'antialiased',
  },
  tableContainer: {
    overflowY:'hidden',
  },
  copyButton: {
    color: get(color, 'primaryButtonBackground'),
    marginTop: 51,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'borderColor'),
    },
  },
  editKeyButton: {
    color: get(color, 'primaryButtonBackground'),
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'borderColor'),
    },
  },
  forceDeleteButton: {
    backgroundColor: get(color, 'error'),
    '&:hover': {
      backgroundColor: get(color, 'errorHover'),
    },
    '&:focus': {
      backgroundColor: get(color, 'error'),
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'error'),
    },
  },
  fieldCopyContainer: {
    display: 'flex',
    width: '100%',
  },
  textField: {
    width: '40%',
  },
  dialogTextField: {
    width: '65%',
  },
  rightPanel: {
    padding: 20,
  },
  lockIcon: {
    fill: convertHexToRGB(get(color, 'bodyText'), 0.6),
    marginLeft: 5,
  },
  warningIcon: {
    fill: get(color, 'warning'),
    marginLeft: 5,
  },
  lockIcon2: {
    fill: convertHexToRGB(get(color, 'bodyText'), 0.6),
    marginLeft: 5,
    marginTop: 5,
  },
  warningIcon2: {
    fill: get(color, 'warning'),
    marginLeft: 5,
    marginTop: 5,
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    marginTop: 50,
  },
  status: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '15px',
    verticalAlign: 'middle',
    letterSpacing: 0,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  enabled: {
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  disabled: {
    '&:before': {
      backgroundColor: get(color, 'disabledBackground'),
    },
  },
  deleteFailed: {
    '&:before': {
      backgroundColor: get(color, 'error'),
    },
  },
});

export default styles;
