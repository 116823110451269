import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { Button, Checkbox, FormControlLabel, Link, Tooltip, Typography, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { getI18n } from '../../../utils/intl';

import {
  AlertDialog,
  AlertMessages, Footer, FormTextField,
} from '../../../components';

import {
  fetchList,
  fetchRequestDetails,
  submitRequest,
  reviewApplication,
} from '../../../actions/requests';


import {
  getAppReviewUpdateStatus,
  getErrors,
  getListResults,
  getRequestDetails,
  getRequestUpdateStatus,
} from '../../../reducers/request';

import styles from './styles';

import { useIntl } from 'react-intl';
import { arrayOf, func, object, string } from 'prop-types';
import { RequestSectionTable } from './SectionTable';
import moment from 'moment';
import { buildDetailsData, buildEditRequestTable, buildRegistrationRequestTable, buildSingleApplicationRequestMap, getTableColumns } from './utils';
import { ALERT_SUCCESS, SUCCESS, ALERT_ERROR, FAIL } from '../../../constants';
import { hasAdminRole, hasDeveloperRole, hasPublisherRole } from '../../../utils';
import { getUserDetails } from '../../../reducers/portalConfig';
export const ApplicationRequestDetails = (props) => {
  const {
    classes,
    listResults,
    requestDetails,
    requestUpdateStatus,
    appReviewUpdateStatus,
    errors,
  } = props;

const getAlertDialogInnerComponent = () => {
  return (
    <div>
      <div className={classes.alertMessageHeaderContainerClass}>
        <div className={classes.alertMessageLeftHeaderClass}>
          {intl.getI18nMessage(`label.request.list.actions.alert.${actionType}.sub.title`)}
        </div>
        {actionType === 'accept' &&
          <div className={classes.alertMessageRightHeaderClass}>
            {intl.getI18nMessage('label.request.list.actions.alert.accept.optional.sub.title')}
          </div>
        }
      </div>
      <FormTextField
        fieldContainerClass={classes.alertMessageContainerClass}
        maxLength={1000}
        multiline
        handleChange={(value) => setAlertReason(value)}
        placeholder={intl.getI18nMessage(`label.request.list.actions.alert.${actionType}.placeholder`)}
        rows={4}
        textFieldClass={classes.alertMessageClass}
        value={alertReason}
      />
      <div className={classes.alertMessageMaxLengthWarningClass}>
        {intl.getI18nMessage('label.request.list.actions.alert.max.length.warning')}
      </div>
    </div>
  );
};
  const orderList = ["registration", "status", "addinfo", "details", "drydetails",
               "customfields", "apis", "apiplans", "apigroups", "apikeys"];
  const [notificationMessage, setNotificationMessage] = useState(localStorage.getItem('notificationMessage'));
  const [notificationStatus, setNotificationStatus] = useState(localStorage.getItem('notificationStatus'));
  const [requestDetailsMap, setRequestDetailsMap] = useState({});
  const [orgName, setOrgName] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [requestor, setRequestor] = useState('');
  const [requestType, setRequestType] = useState('');
  const [entityName, setEntityName] = useState('');
  const [showChangesOnly, setShowChangesOnly] = useState(false);
  const [requestInfoMap, setRequestInfoMap] = useState({});
  const [singleRequest, setSingleRequest] = useState(false);
  const [showActionAlert, setShowActionAlert] = useState(false);
  const [actionType, setActionType] = useState('');
  const [uuidType, setUuidType] = useState('');
  const [actionUuid, setActionUuid] =useState('');
  const [actionTable, setActionTable] = useState('');
  const [pendingRequestCount, setPendingRequestCount] = useState(-1);
  const [actionTableMap, setActionTableMap] = useState({});
  const [alertReason, setAlertReason] = useState('');
  const [detailsRequestExist, setDetailsRequestExist] = useState(true);
  const [singleRequestUuid, setSingleRequestUuid] = useState('');
  const intl = getI18n(useIntl());
  const entityUuid = get(props, 'match.path') &&
                     get(props, 'match.path').includes('/details/') &&
                     get(props, 'match.params.entityUuid');
  const getRequestTypeHeading = requestType => {
    if(!requestType) {
      return ''
    }
    switch(requestType) {
      case 'APPLICATION':
        return intl.getI18nMessage('label.request.details.request.type.heading.application');
      case 'APPLICATION_DELETE':
        return (<span className={classes.deleteHeading}> {intl.getI18nMessage('label.request.details.request.type.heading.application.delete')}</span>);
      case 'REGISTRATION':
        return intl.getI18nMessage('label.request.details.request.type.heading.registration');
    }
    return (<div className={classes.descriptionField}>
      {intl.getI18nMessage('label.request.details.request.type.heading.application.edit')}
      <Tooltip
        title={intl.getI18nMessage('label.request.details.edit.request.tooltip')}
        arrow placement="right">
          <HelpIcon className={classes.helpIconEdit}/>
      </Tooltip>
      </div>);
  }
  const getUserName = () => hasAdminRole(props.user) ?
  (<Link href={`/admin/users#edit/${requestor.uuid}`}>
    {requestor.name}
  </Link>) :
  (<span>
    {requestor.name}
  </span>);
  const getOrgName = () => hasPublisherRole(props.user) ?
  (<Link href={`/admin/console/organizations/details/${orgName.uuid}`}>
      {orgName.name}
    </Link>):
  (<span>{orgName.name}</span>);
  const getSubTitleContent = () => {
    if(requestType === 'REGISTRATION') {
      return `${intl.getI18nMessage("label.request.details.requestdate.sub.title")}: ${requestDate}`;
    }
    const subTitle = (<span>{intl.getI18nMessage('label.request.details.organization.sub.title')}
        {getOrgName()}
      </span>);
    if(requestDate) {
      return (<div>{subTitle} • {intl.getI18nMessage('label.request.details.requestdate.sub.title')}: {requestDate} •
                  {intl.getI18nMessage('label.request.details.requestor.sub.title')}
                  {getUserName()} </div>)
    }
    return subTitle;
  }
  useEffect(()=> {
    if(hasDeveloperRole(props.user)) {
      props.push('/404');
    }
    props.fetchList({ entityUuid });
  },[]);
  useEffect(() => {
    if(listResults) {
      if(listResults.length > 0) {
        const firstRequest = listResults[0];
        const reqType = get(firstRequest, 'requestType');
        const reqSubtype = get(firstRequest, 'requestSubtype');
        const reqSubType = get(firstRequest, 'requestSubtype');
        if(reqType === 'APPLICATION' || reqType === 'REGISTRATION' ||
          (reqType === 'APPLICATION_DETAILS' && reqSubType === 'APP_STATUS_CHANGE'))
        {
          setSingleRequest(true);
          setRequestDate(moment(firstRequest.modifyTs).format('ddd, MMM DD, YYYY HH:mm:ss ([GMT]Z)'));
          if(reqType === 'REGISTRATION'){
            setRequestDetailsMap({
              ['registration']: buildRegistrationRequestTable(firstRequest),
            });
          }
          setRequestType(reqSubtype === 'DELETE' || reqSubtype === 'UPDATE'? `APPLICATION_${reqSubType}` : reqType);
          setSingleRequestUuid(firstRequest.uuid);
        } else {
          setDetailsRequestExist(listResults.find(req => req.requestType === 'APPLICATION_DETAILS'));
          setRequestType('APPLICATION_EDIT');
        }
        setEntityName(firstRequest.entityName);
        setOrgName({
          name: firstRequest.organizationName,
          uuid: firstRequest.organizationUuid,
        });
        setPendingRequestCount(listResults.length);
        if(reqType !== 'REGISTRATION') {
          listResults.map(req => props.fetchRequestDetails(req.uuid));
        }
      } else {
        props.push('/404');
      }
    }
  },[listResults]);
  useEffect(() => {
    if(requestDetails && requestDetails.request) {
      const reqType=requestDetails.request.requestType;
      if(singleRequest) {
        setRequestor({
          name: requestDetails.request.modifiedBy,
          uuid: requestDetails.request.userUuid,
        });
      }
      if(reqType === 'APPLICATION') {
        setRequestDetailsMap(buildSingleApplicationRequestMap(classes, requestDetails));
      } else {
        const { type, dataTable } = buildEditRequestTable(classes, requestDetails);
        if(requestDetails.request.requestSubtype === 'APP_STATUS_CHANGE') {
          setRequestDetailsMap({
            ...requestDetailsMap,
            [type]: dataTable,
            ['addinfo'] : buildDetailsData(classes, requestDetails.original.applicationDetails, null, true),
          });
        } else {
          if(!requestDetailsMap['details'] && !detailsRequestExist) {
            setRequestDetailsMap({
              ...requestDetailsMap,
              [type]: dataTable,
              drydetails: buildDetailsData(classes, requestDetails.applicationExcerpt, null, false),
            });
          } else {
            setRequestDetailsMap({
              ...requestDetailsMap,
              [type]: dataTable,
            });
          }
        }
        setRequestInfoMap({
          ...requestInfoMap,
          [type]: requestDetails.request,
        });
      }
    }
  }, [requestDetails]);
  useEffect(() => {
    if(requestUpdateStatus) {
      setShowActionAlert(false);
      setAlertReason('');
      if(singleRequest) {
        if(requestUpdateStatus === SUCCESS) {
          const message=intl.getI18nMessage(`label.application.details.section.${actionType}.success`);
          localStorage.setItem('notificationStatusRequestSaveSuccess', ALERT_SUCCESS);
          localStorage.setItem('notificationMessageRequestSaveSuccess', message);
          props.push('/admin/console/requests');
        } else if (requestUpdateStatus === FAIL){
          let errorMsg = intl.getI18nMessage(`label.application.details.section.${actionType}.error`);
          if(errors && errors.length > 0 && errors[0].field === 'proxyCheck') {
            errorMsg = errors[0].error;
          }
          notifyMessages(errorMsg, ALERT_ERROR);
        }
      } else {
        if(requestUpdateStatus === SUCCESS) {
          if(pendingRequestCount === 1) {
            const message=intl.getI18nMessage(`label.application.details.section.${actionType}.all.success`);
            localStorage.setItem('notificationStatusRequestSaveSuccess', ALERT_SUCCESS);
            localStorage.setItem('notificationMessageRequestSaveSuccess', message);
            props.push('/admin/console/requests');
          } else {
            setPendingRequestCount(pendingRequestCount - 1);
          }
        }
        setActionTableMap({
          ...actionTableMap,
          [actionTable]: {
            status: requestUpdateStatus,
            actionType: actionType,
          },
        });
      }
    }
  },[requestUpdateStatus]);
  useEffect(() => {
    setShowActionAlert(false);
    if(appReviewUpdateStatus === SUCCESS) {
      localStorage.setItem('notificationStatusRequestSaveSuccess', ALERT_SUCCESS);
      localStorage.setItem('notificationMessageRequestSaveSuccess',
              intl.getI18nMessage('label.application.details.section.accept.all.success'));
      props.push('/admin/console/requests');
    } else if(appReviewUpdateStatus === FAIL) {
      notifyMessages(intl.getI18nMessage(`label.application.details.section.${actionType}.all.error`), ALERT_ERROR, true);
    }
  },[appReviewUpdateStatus]);
  const notifyMessages = (message, status, timeout) => {
    setNotificationStatus(status);
    localStorage.setItem('notificationStatus', status);
    setNotificationMessage(message);
    localStorage.setItem('notificationMessage', message);
    if(timeout) {
      setTimeout(() => {
        notifyMessages('', '');
      }, 3000);
    }
  };
  const reviewEntityRequest = (action) => {
    if(singleRequest) {
      setUuidType('REQUEST');
      setActionUuid(singleRequestUuid);
    } else {
      setUuidType('ENTITY');
      setActionUuid(entityUuid);
    }
    setActionType(action);
    setShowActionAlert(true);
  }
  const review = (uuidType, action, uuid, actionTable) => {
    setUuidType(uuidType);
    setActionType(action);
    setActionUuid(uuid);
    setActionTable(actionTable);
    setShowActionAlert(true);
  };
  const submitReview = () => {
    if(uuidType === 'ENTITY'){
      props.reviewApplication(actionUuid, actionType, alertReason);
    } else {
      props.submitRequest({
        isAccept : actionType === 'accept',
        requestId:  actionUuid,
        reason: alertReason,
      });
    }
  };
  return (
    <Fragment>
      <div className={classes.content}
        id="request-details-page" data-apim-test="request-details-page"
      >
        {notificationMessage &&
          <AlertMessages
            id="app-details-notification"
            variant={notificationStatus}
            message={notificationMessage}
            containerClass={classes.notificationWidthClass}
            onClose={() => { notifyMessages('', ''); }}
          />
        }
        <AlertDialog
          id="request-detail-accept-reject-dialog"
          data-apim-test="request-detail-accept-reject-dialog"
          isOpen={showActionAlert}
          title={intl.getI18nMessage(`label.request.list.actions.alert.${actionType}.title`)}
          description={intl.getI18nMessage('label.request.list.actions.alert.description')}
          disableSubmit={actionType === 'reject' && (!alertReason || !alertReason.trim())}
          submitText={intl.getI18nMessage(`label.request.list.actions.${actionType}`)}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          component={showActionAlert && getAlertDialogInnerComponent()}
          onClose={() => setShowActionAlert(false)}
          onSubmit={() => submitReview()}
          onCancel={() => setShowActionAlert(false)}
          submitButtonClass={actionType === 'accept' ? '' : classes.rejectSubmitButton}
        />
        <div className={classes.header}>
          <Typography variant="h6" className={classes.smallLabel}>
            {intl.getI18nMessage(`label.request.details.small.title.${requestType==='REGISTRATION' ? 'registration' : 'applications'}`)}
          </Typography>
          <div className={classes.sectionHead}>
            {(requestType === 'APPLICATION' || requestType === 'REGISTRATION') ?
              <Typography variant="h1" className={classes.pageTitle}>
                {entityName}
              </Typography> :
                <Link underline='none' variant="h1" href={`/admin/console/applications/details/${entityUuid}`} className={classes.pageTitle}>
                {entityName}
                </Link>
            }
            { (singleRequest || pendingRequestCount > 1) &&
              <div className={classes.pageButtonDiv}>
                <Button
                  id="accept-button"
                  data-apim-test="accept-button"
                  variant="contained"
                  color="secondary"
                  className={classes.acceptButton}
                  onClick={() => reviewEntityRequest('accept')}
                >
                  {intl.getI18nMessage(`label.application.details.section.accept.${singleRequest? '' : 'all.'}button`)}
                </Button>
                <Button
                  id="reject-button"
                  data-apim-test="reject-button"
                  className={classes.rejectButton}
                  onClick={() => reviewEntityRequest('reject')}
                  >
                  {intl.getI18nMessage(`label.application.details.section.reject.${singleRequest? '' : 'all.'}button`)}
                </Button>
              </div>
            }
          </div>
          <Typography variant="body1">
            {getSubTitleContent(requestDate, requestType, requestor)}
          </Typography>
          <Typography variant="h3">
            {getRequestTypeHeading(requestType)}
          </Typography>
          {!singleRequest || requestType === 'APPLICATION_UPDATE' &&
            <FormControlLabel
              control = {
                <Checkbox
                  value={showChangesOnly}
                  onChange={(e) => setShowChangesOnly(e.target.checked)}
                />
              }
              label={intl.getI18nMessage("label.request.details.show.changed.values.checkbox")}
              />
          }
        </div>
        {orderList.filter(type => Object.keys(requestDetailsMap).includes(type)).map(key =>
          <RequestSectionTable
            key={key}
            sectionType={key}
            columns={getTableColumns(classes, singleRequest && requestType !== 'APPLICATION_UPDATE', key)}
            rows={showChangesOnly && key !== "drydetails"
                  ? requestDetailsMap[key].filter(row => row.selected)
                  : requestDetailsMap[key]}
            requestInfo={requestInfoMap[key]}
            actionStatus={actionTableMap[key]}
            onReview={review}
            classes={classes}
            user={props.user}
          />)}
          <Footer />
        </div>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  listResults: getListResults(state),
  requestDetails: getRequestDetails(state),
  requestUpdateStatus: getRequestUpdateStatus(state),
  appReviewUpdateStatus: getAppReviewUpdateStatus(state),
  user: getUserDetails(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  fetchList,
  fetchRequestDetails,
  submitRequest,
  reviewApplication,
  push,
};

ApplicationRequestDetails.propTypes = {
  fetchList: func,
  requestDetails: object,
  fetchRequestDetails: func,
  listResults: arrayOf(object),
  getListResults: func,
  classes: object,
  requestUpdateStatus: string,
  appReviewUpdateStatus: string,
  submitRequest: func,
  reviewApplication: func,
  push: func,
  user: object,
  errors: arrayOf(object),
};


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationRequestDetails);
