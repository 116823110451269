import { get } from 'lodash';
import { modifyAlpha } from '../../../utils/modifyColour';

const styles = ({ color, fontWeight, fontSize, fontStyle, margin, typography }) => ({
  content: {
    margin: 36,
    minHeight: 'calc(100vh - 125px)',
    backgroundColor: get(color, 'uiBackground'),
    marginTop: 0,
    paddingBottom: 72,
    '& button': {
      '&:focus': {
        outlineWidth: '1px !important',
        outlineOffset: '1px !important',
        outlineStyle: 'solid !important',
      },
    },
  },
  bodyContent: {
    display: 'block',
  },
  notificationWidthClass: {
    width: '96%',
    padding: '0 15px',
    marginLeft: 0,
  },
  pageTitle: {
    margin: `${get(margin, 'm5')} 0`,
    wordWrap: 'break-word',
  },
  statusField: {
    display: 'flex',
  },
  statusIcon: {
    height: 18,
    marginRight: 4,
    verticalAlign: 'middle',
    width: 18,
  },
  header: {
    paddingLeft: 36,
    marginTop: 20,
    paddingTop: 36,
  },
  deleteHeading: {
    color: get(color, 'error'),
  },
  link: {
    margin: `${get(margin, 'm5')} 0`,
    wordWrap: 'break-word',
  },
  acceptButton: {
    marginRight: 10,
    outlineColor: get(color, 'primaryButtonBackground'),
  },
  tableFooter: {
    display: 'flex',
    marginLeft: 36,
    marginTop: 8,
  },
  keyNote: {
    marginLeft: 'auto',
    marginRight: 36,
  },
  rejectButton: {
    backgroundColor: get(color, 'error'),
    color: get(color, 'bodyBackground'),
    outlineColor: get(color, 'error'),
    '&:hover': {
      color: get(color, 'bodyBackground'),
      backgroundColor: modifyAlpha(get(color, 'error'), '0.7'),
    },
    marginRight: 5,
  },
  buttonDiv: {
    marginTop: 16,
    marginRight: 36,
    marginLeft: 'auto',
  },
  pageButtonDiv: {
    marginTop: 12,
    marginRight: 36,
    marginLeft: 'auto',
  },
  sectionHead: {
    display: 'flex',
  },
  tableHead: {
    display: 'flex',
    marginLeft: 36,
    marginTop: 36,
  },
  actionBtn: {
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: get(fontWeight, 'normal'),
  },
  alertMessageClass: {
    padding: '6px 0 !important',
    width: '540px !important',
  },
  alertMessageContainerClass: {
    margin: '0 0 !important',
  },
  alertMessageHeaderContainerClass: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertMessageLeftHeaderClass: {
    color: modifyAlpha(get(color, 'bodyText'), 0.7),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontStyle: get(fontStyle, 'normal'),
    fontWeight: get(fontWeight, 'bold'),
  },
  alertMessageRightHeaderClass: {
    color: modifyAlpha(get(color, 'bodyText'), 0.7),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontStyle: get(fontStyle, 'normal'),
    fontWeight: get(fontWeight, 'normal'),
  },
  alertMessageMaxLengthWarningClass: {
    color: modifyAlpha(get(color, 'bodyText'), 0.7),
    fontFamily: get(typography, 'smallText'),
    fontSize: get(fontSize, 'smallText'),
    fontStyle: get(fontStyle, 'normal'),
    fontWeight: get(fontWeight, 'normal'),
  },
  checkIcon: {
    color: get(color, 'success'),
    marginLeft: 18,
  },
  acceptedText: {
    color: get(color, 'success'),
    fontWeight: get(fontWeight, 'bold'),
    marginLeft: 6,
  },
  cancelIcon: {
    color: get(color, 'error'),
    fontWeight: get(fontWeight, 'bold'),
    marginLeft: 18,
  },
  notificationDiv: {
    display: 'flex',
    paddingTop: 18,
    paddingBottom: 18,
    borderStyle: 'solid',
    borderRadius: 5,
    alignItems: 'center',
    border: 2,
    borderColor: get(color, 'borderColor'),
  },
  notification: {
    marginTop: 3,
    marginLeft: 5,
  },
  error: {
    color: get(color, 'error'),
    marginLeft: 8,
  },
  helpIcon: {
    color: get(color, 'primaryButtonBackground'),
    marginLeft: 6,
    height: 20,
    width: 20,
  },
  descriptionField: {
    display: 'flex',
  },
  helpIconEdit: {
    color: get(color, 'primaryButtonBackground'),
    marginLeft: 6,
    marginTop: 4,
    height: 24,
    width: 24,
  },
});


export default styles;
