import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { number, bool, func, shape, arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import queryString from 'query-string';

import { fetchProxyApiKeysList } from '../../../../actions/proxy';
import {
  getKeysListTotalPages,
  getKeysListTotalElements,
  getKeysListResults,
} from '../../../../reducers/proxy';
import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
 } from '../../../../constants';
import {
  FilterByKeyName,
  FilterByApiKey,
  FilterByDeploymentStatus,
  FilterByKeyStatus,
  SortBy,
  FilterAndSortSeparator,
} from './controls';
import getDateTime, { getTimeZoneAbbr } from '../../../../utils/datetime';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

const LIST_COLUMNS = [{
  id: 'name',
  label: getI18nFormattedMessage('label.api.keys.name'),
  minWidth: 100,
  link: '/admin/console/applications/details',
}, {
  id: 'apiKey',
  label: getI18nFormattedMessage('label.api.keys.api.key'),
  minWidth: 50,
},{
  id: 'keyStatus',
  label: getI18nFormattedMessage('label.api.keys.status'),
  minWidth: 50,
  isStatusIcon: true,
}, {
  id: 'status',
  label: getI18nFormattedMessage('label.api.keys.deployment.status'),
  minWidth: 50,
  isStatusIcon: true,
}, {
  id: 'lastTimeDeployed',
  label: getI18nFormattedMessage('label.api.date.last.deployed', { zone: getTimeZoneAbbr() }),
  format: value => getDateTime(value),
  minWidth: 50,
},
];

export const KeyListRaw = (props) => {
  const {
    proxyUuid,
    classes,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    notifyMessages,
    isCurrentTab,
  } = props;

  const parsed = queryString.parse(props.location.search);
  const deploymentStatus = parsed.status || 'all';
  const filterSearch = parsed.status ? true : false;
  const intl = getI18n(useIntl());

  const [keyName, setKeyName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [keyStatus, setKeyStatus] = useState('all');
  const [status, setStatus] = useState(deploymentStatus);
  const [sortBy, setSortBy] = useState('lastTimeDeployed,DESC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterApiKey, setFilterApiKey] = useState(false);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(filterSearch);

  const emptyFunction = () => {};

  useEffect(() => {
    if (proxyUuid) {
     props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey, status,
      sortBy, page, rowsPerPage });
    }
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const deploymentStatus = parsed.status || 'all';
    const filterSearch = parsed.status ? true : false;
    setStatus(deploymentStatus);
    setFilterByAnyApplied(filterSearch);
    if (proxyUuid && isCurrentTab) {
     props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey,
      status: deploymentStatus, sortBy, page, rowsPerPage });
    }
  }, [props.location, isCurrentTab]);

  const onFilterByKeyNameChange = (value) => {
    setFilterApiKey(false);
    setKeyName(value);
  };

  const onFilterByKeyNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setFilterByAnyApplied(true);
      props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey,
        status, sortBy, page:0, rowsPerPage });
    }
  };

  const onFilterByApiKeyChange = (value) => {
    setApiKey(value);
  };

  const onFilterByApiKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterApiKey(true);
      setPage(0);
      props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey,
        status, sortBy, page:0, rowsPerPage });
    }
  };

  const onFilterByKeyStatus = (newKeyStatus) => {
    setFilterApiKey(false);
    setKeyStatus(newKeyStatus);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus: newKeyStatus, apiKey,
      status,sortBy, page:0, rowsPerPage });
  };

  const onFilterByDeploymentStatus = (newStatus) => {
    setFilterApiKey(false);
    setStatus(newStatus);
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey,
      status: newStatus, sortBy, page:0, rowsPerPage });
  };

  const onSortByChange = (newSortBy) => {
    setFilterApiKey(false);
    setSortBy(newSortBy);
    setPage(0);
    props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey, status,
      sortBy: newSortBy, page:0, rowsPerPage });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey, status,
      sortBy, page: newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchProxyApiKeysList({ proxyUuid, keyName, keyStatus, apiKey, status,
      sortBy, page:0, rowsPerPage: newRowsPerPage });
  };

  const noResultsMessage = filterApiKey ?
    `${intl.getI18nMessage('label.api.keys.api.key.list.no.results')}` :
      filterByAnyApplied ? `${intl.getI18nMessage('label.api.keys.search.results')}` :
        intl.getI18nMessage('label.api.keys.list.no.results');

  return (
    <ListContainer
      listPageId="proxy-details-keys-list"
      isLoading={isLoading}
      notificationId="proxy-details-notifications"
      notificationStatus={''}
      setNotificationStatus={emptyFunction}
      notificationMessage={''}
      setNotificationMessage={emptyFunction}
      pageHeaderTitle={''}
      pageHeaderTooltipTitle={''}
      addButtonLabel={''}
      onAddBundle={emptyFunction}
      showListHeader={false}
      pageClass={classes.listContent}
      pageBodyClass={classes.pageBodyClass}
      pageFilterAndSortClass={classes.pageFilterAndSortClass}
      filterAndSortContent={(
        <Fragment>
          <FilterByKeyName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={keyName}
            placeholder={intl.getI18nMessage('label.api.keys.key.name.placeholder')}
            handleChange={onFilterByKeyNameChange}
            onKeyPress={onFilterByKeyNameKeyPress}
          />
          <FilterByApiKey
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={apiKey}
            placeholder={intl.getI18nMessage('label.api.keys.api.key.placeholder')}
            handleChange={onFilterByApiKeyChange}
            onKeyPress={onFilterByApiKeyPress}
            hideLabel
          />
          <FilterByKeyStatus
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={keyStatus}
            handleChange={onFilterByKeyStatus}
            hideLabel
          />
          <FilterByDeploymentStatus
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            selectFieldClass={classes.selectField}
            value={status}
            handleChange={onFilterByDeploymentStatus}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort.by')}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
     )}
      columns={LIST_COLUMNS}
      rows={results}
      uuidName={'applicationUuid'}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      notifyMessages={notifyMessages}
    />
  );
};

KeyListRaw.propTypes = {
  classes: shape({}),
  location: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchProxyApis: func,
  proxyUuid: string,
  fetchProxyApiKeysList: func,
  notifyMessages: func,
  isCurrentTab: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getKeysListTotalPages(state),
  totalElements: getKeysListTotalElements(state),
  results: getKeysListResults(state),
});

const mapDispatchToProps = {
  fetchProxyApiKeysList,
};
KeyListRaw.displayName = 'Keys';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(KeyListRaw);
