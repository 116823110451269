import get from 'lodash/get';

const styles = ({ color, margin, padding, fontSize } = {}) => ({
  content: {
    minHeight: 'calc(100vh - 150px)',
    marginTop: 0,
    padding: `0 0 ${get(padding, 'p24')} 0`,
    display: 'flex',
    flexDirection: 'column',
    '&> div:not([role="alert"])': {
      '&> div': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },
    '& .MuiSnackbarContent-root': {
      minWidth: '168px',
    },
  },
  pageBody: {
    padding: get(padding, 'p0'),
  },
  divider: {
    backgroundColor: get(color, 'bodyText'),
  },
  fieldContainer: {
    margin: `${get(margin, 'm2')} ${get(margin, 'm4')} ${get(
      margin,
      'm2',
    )} ${get(margin, 'm0')} !important`,
    padding: '10px 0',
  },
  tabPanel: {
    width: '100%',
    '& #products-page': {
      margin: get(margin, 'm0'),
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: `${get(padding, 'p20')} 0`,
  },
  pageFilterAndSort: {
    marginTop: get(margin, 'm0'),
  },
  subTitle: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontFamily: 'open-sans-regular',
    lineHeight: '21px',
  },
  notificationContainer: {
    minWidth: '200px !important',
    width: 'auto !important',
    left: '35% !important',
    backgroundColor: get(color, 'white'),
    color: get(color, 'black'),
    fontWeight: 800,
  },
});

export default styles;
