import React from 'react';
import cx from 'classnames';
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Typography,
} from '@material-ui/core';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import { number, oneOfType, shape, string } from 'prop-types';

import Footer from './footer';
import { PROXY_DISCONNECTED } from '../../../../../constants';

export const Paper = (props) => {
  const {
    classes = {},
    id,
    title,
    subTitle,
    proxyStatus,
    content,
    contentMore,
    deploymentType,
    lastUpdated,
  } = props;

  const isProxyDisconnected = (proxyStatus === PROXY_DISCONNECTED);
  const containerClass = isProxyDisconnected ?
    cx(classes.paper, classes.disconnectedBackground) : classes.paper;

  return (
    <MuiPaper
      square elevation={0}
      className={containerClass}
      id={`${id}-status`} data-layer7-test={`${id}-status`}
    >
      <Grid container>
        <Grid item>
          <Typography
            variant="h2"
            className={classes.title}
            id={`${id}-status-title`} data-layer7-test={`${id}-status-title`}
          >
            {title}
          </Typography>
          {subTitle &&
            <Typography
              variant="h6"
              className={classes.subTitle}
              id={`${id}-status-sub-title`} data-layer7-test={`${id}-status-sub-title`}
            >
              {subTitle}
            </Typography>
          }
        </Grid>
        {isProxyDisconnected &&
          <Grid item>
            <IconButton
              className={classes.disconnectedIndicatorIcon}
              data-disconnected
            >
              <SyncDisabledIcon/>
            </IconButton>
          </Grid>
        }
      </Grid>
      <Typography
        variant="h1"
        className={classes.value}
      >
        {content}
      </Typography>
      {contentMore}
      <Footer
        id={id}
        deploymentType={deploymentType}
        lastUpdated={lastUpdated}
      />
    </MuiPaper>
  );
}

export default Paper;

Paper.propTypes = {
  classes: shape({}),
  id: string,
  title: string,
  subTitle: string,
  proxyStatus: string,
  content: oneOfType([number, string]),
  contentMore: shape({}),
  deploymentType: string,
  lastUpdated: string,
};
