import { get, isArray } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
  isFormSubmissionSuccessful: false,
  isCreateError: false,
  createErrorMsg: '',
  isUpdateError: false,
  updateErrorMsg: '',
  isFetchingRateLimitQuotas: false,
  isFetchingOrganization: false,
  isCreatingOrganization: false,
  isUpdatingOrganization: false,
  isUpdatingOrganizationTags: false,
  isUpdatedOrganizationTags: false,
  isUpdatedOrganizationTagsError: false,
  createdTagUuid: null,
  updateOrganizationTagsErrors: [],
  isFetchingAPIAccess: false,
  isApiAccessLoadingError: false,
  updatingOrgApisAccessError: [],
  isUpdatingOrgApisAccessSuccess: false,
  updatingOrgApisRateQuotaError: [],
  isUpdatingOrgApisRateQuotaSuccess: false,
};

const initialState = {
  ...resetState,
  isLoading: false,
  isListError: false,
  isFormLoadingError: false,
  isFormValidationError: false,
  isCreateError: false,
  isUpdateError: false,
  createErrorMsg: '',
  updateErrorMsg: '',
  isFetchingRateLimitQuotas: false,
  isFetchingOrganization: false,
  isCreatingOrganization: false,
  isUpdatingOrganization: false,
  isUpdatingOrganizationTags: false,
  isUpdatedOrganizationTags: false,
  organizationNameUnique: true,
  isFormSubmissionSuccessful: false,
  isFetchingAPIAccess: false,
  isApiAccessLoadingError: false,
  updatingOrgApisAccessError: [],
  isUpdatingOrgApisAccessSuccess: false,
  updatingOrgApisRateQuotaError: [],
  isUpdatingOrgApisRateQuotaSuccess: false,
  organizations: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  rateLimitQuotas: {
    results: [],
    currentPage: 0,
    totalPages: 1,
  },
  products: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  isProductUpdateSuccess: false,
  productTiers: [],
};

const getValidationErrorMessage = (payload) => {
  const validationErrors = get(payload, 'error.detail.validationErrors', []);
  let errorMsg = '';
  if (isArray(validationErrors)) {
    validationErrors.forEach(item => {
      errorMsg += item.error;
    });
  }
  return errorMsg;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.ORGANIZATIONS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        organizations: action.payload,
      };
    case Constants.ORGANIZATIONS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
      };
    case Constants.ORGANIZATION_LIST_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organizations: action.payload,
      };
    case Constants.ORGANIZATION_LIST_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isListError: true,
      };
    case Constants.ORGANIZATION_GET_REQUEST:
      return {
        ...state,
        isFetchingOrganization: true,
      };
    case Constants.ORGANIZATION_GET_SUCCESS:
      return {
        ...state,
        isFetchingOrganization: false,
        organizationDetails: action.payload,
      };
    case Constants.ORGANIZATION_GET_ERROR:
      return {
        ...state,
        isFetchingOrganization: false,
        isFormLoadingError: true,
      };
    case Constants.ORGANIZATION_NAME_UNIQUE_REQUEST:
      return {
        ...state,
        organizationNameUnique: true,
      };
    case Constants.ORGANIZATION_NAME_UNIQUE_SUCCESS:
      return {
        ...state,
        organizationNameUnique: action.payload.d.result,
      };
    case Constants.ORGANIZATION_NAME_UNIQUE_ERROR:
      return {
        ...state,
        organizationNameUnique: false,
        isFormValidationError: true,
      };
    case Constants.ORGANIZATION_CREATE_REQUEST:
      return {
        ...state,
        isCreatingOrganization: true,
      };
    case Constants.ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        isCreatingOrganization: false,
        isFormSubmissionSuccessful: true,
        organizationDetails: action.payload,
      };
    case Constants.ORGANIZATION_CREATE_ERROR: {
      const createErrorMsg = getValidationErrorMessage(action.payload);
      return {
        ...state,
        isCreatingOrganization: false,
        isCreateError: true,
        ...(createErrorMsg ? { createErrorMsg } : {}),
      };
    }
    case Constants.ORGANIZATION_UPDATE_REQUEST:
      return {
        ...state,
        isUpdatingOrganization: true,
      };
    case Constants.ORGANIZATION_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingOrganization: false,
        isFormSubmissionSuccessful: true,
        organizationDetails: action.payload,
      };
    case Constants.ORGANIZATION_UPDATE_ERROR: {
      const updateErrorMsg = getValidationErrorMessage(action.payload);
      return {
        ...state,
        isUpdatingOrganization: false,
        isUpdateError: true,
        ...(updateErrorMsg ? { updateErrorMsg } : {}),
      };
    }
    case Constants.ORG_TAG_POST_PUT_REQUEST:
    case Constants.ORG_TAG_PUT_REQUEST: {
      return {
        ...state,
        isUpdatingOrganizationTags: true,
        isUpdatedOrganizationTags: false,
        isUpdatedOrganizationTagsError: false,
        createdTagUuid: null,
      }
    }
    case Constants.ORG_TAG_POST_PUT_SUCCESS:
    case Constants.ORG_TAG_PUT_SUCCESS: {
      return {
        ...state,
        isUpdatingOrganizationTags: false,
        isUpdatedOrganizationTags: true,
        isUpdatedOrganizationTagsError: false,
        createdTagUuid: get(action, 'payload.tagUuid'),
        updateOrganizationTagsErrors: [],
      }
    }
    case Constants.ORG_TAG_POST_PUT_ERROR:
    case Constants.ORG_TAG_PUT_ERROR: {
      return {
        ...state,
        isUpdatingOrganizationTags: false,
        isUpdatedOrganizationTags: false,
        isUpdatedOrganizationTagsError: true,
        createdTagUuid: null,
        updateOrganizationTagsErrors: action.payload,
      }
    }
    case Constants.ORG_RATE_QUOTA_GET_LIST_REQUEST:
      return {
        ...state,
        isFetchingRateLimitQuotas: true,
      };
    case Constants.ORG_RATE_QUOTA_GET_LIST_SUCCESS: {
      return {
        ...state,
       isFetchingRateLimitQuotas: false,
       rateLimitQuotas: action.payload,
      };
    }
    case Constants.ORG_RATE_QUOTA_GET_LIST_ERROR :
      return {
        ...state,
        isFetchingRateLimitQuotas: false,
        isFormLoadingError: true,
      };
    case Constants.ORG_APIACCESS_GET_LIST_REQUEST:
      return {
        ...state,
        isFetchingAPIAccess: true,
      };
    case Constants.ORG_APIACCESS_GET_LIST_SUCCESS: {
      return {
        ...state,
        isFetchingAPIAccess: false,
        apiAccessResults: action.payload,
      };
    }
    case Constants.ORG_APIACCESS_GET_LIST_ERROR:
      return {
        ...state,
        isFetchingAPIAccess: false,
        isApiAccessLoadingError: true,
      };
      case Constants.API_ORGANIZATION_ACCESS_PATCH_REQUEST:
      return {
        ...state,
        isUpdatingOrgApisAccess: true,
        updatingOrgApisAccessError: [],
        isUpdatingOrgApisAccessSuccess: false,
      };
    case Constants.API_ORGANIZATION_ACCESS_PATCH_SUCCESS: {
      return {
        ...state,
        isUpdatingOrgApisAccess: false,
        updatingOrgApisAccessError: [],
        isUpdatingOrgApisAccessSuccess: true,
      };
    }
    case Constants.API_ORGANIZATION_ACCESS_PATCH_ERROR:
      return {
        ...state,
        isUpdatingOrgApisAccess: false,
        isUpdatingOrgApisAccessSuccess: false,
        updatingOrgApisAccessError: action.payload,
      };
    case Constants.API_ORG_ACCESS_RATE_QUOTA_PATCH_SUCCESS: {
      return {
        ...state,
        updatingOrgApisRateQuotaError: [],
        isUpdatingOrgApisRateQuotaSuccess: true,
      };
    }
    case Constants.API_ORG_ACCESS_RATE_QUOTA_PATCH_ERROR:
      return {
        ...state,
        isUpdatingOrgApisRateQuotaSuccess: false,
        updatingOrgApisRateQuotaError: action.payload,
      };
    case Constants.ORG_PRODUCTS_LIST_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        products: action.payload,
        isProductUpdateSuccess: false,
      };
    case Constants.ORG_PRODUCTS_LIST_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        isProductUpdateSuccess: false,
      };
    case Constants.ORG_PRODUCT_TIERS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        products: action.payload,
        isProductUpdateSuccess: false,
      };
    case Constants.ORG_PRODUCT_TIERS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        isProductUpdateSuccess: false,
      };
    case Constants.ORGS_PRODUCTS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isProductUpdateSuccess: true,
      };
    case Constants.ORGS_PRODUCTS_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        isProductUpdateSuccess: false,
      };
    case Constants.ORG_PRODUCTS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        orgProducts: action.payload,
      };
    case Constants.ORG_PRODUCTS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
      };
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'organization.isLoading');

export const getIsError = (state) => get(state, 'organization.isError');
export const getErrors = state => get(state, 'organization.errors');

export const getOrganizationListTotalElements = (state) => get(state, 'organization.organizations.totalElements');
export const getOrganizationListResults = (state) => get(state, 'organization.organizations.results');

export const getIsListError = (state) => get(state, 'organization.isListError');
export const getIsFormLoadingError = (state) => get(state, 'organization.isFormLoadingError');
export const getIsFormValidationError = (state) => get(state, 'organization.isFormValidationError');

export const getIsFormCreateError = (state) => get(state, 'organization.isCreateError');
export const getCreateErrorMsg = (state) => get(state, 'organization.createErrorMsg');
export const getIsFormUpdateError = (state) => get(state, 'organization.isUpdateError');
export const getUpdateErrorMsg = (state) => get(state, 'organization.updateErrorMsg');

export const getIsFormSubmissionSuccessful = (state) => get(state, 'organization.isFormSubmissionSuccessful');

export const getOrganizationsListTotalPages = (state) => get(state, 'organization.organizations.totalPages');
export const getOrganizationsListTotalElements = (state) => get(state, 'organization.organizations.totalElements');
export const getOrganizationsListResults = (state) => get(state, 'organization.organizations.results');

export const getIsOrganizationNameUnique = (state) => get(state, 'organization.organizationNameUnique');

export const getOrganizationDetails = (state) => get(state, 'organization.organizationDetails');
export const getAccountPlans = (state) => get(state, 'organization.accountPlans');
export const getAccountPlansMap = (state) => get(state, 'organization.accountPlansMap');

export const getIsFormLoading = (state, isEditMode) =>
  (isEditMode ?
  (
    get(state, 'organization.isFetchingOrganization') ||
    get(state, 'organization.isFetchingRateLimitQuotas') ||
    get(state, 'organization.isUpdatingOrganization')
  )
  :
  (
    get(state, 'organization.isFetchingRateLimitQuotas') ||
    get(state, 'organization.isCreatingOrganization')
  ));

export const getIsUpdatingOrganizationTags = (state) => get(state, 'organization.isUpdatingOrganizationTags');
export const getIsUpdatedOrganizationTags = (state) => get(state, 'organization.isUpdatedOrganizationTags');

export const getCreatedTagUuid = (state) => get(state, 'organization.createdTagUuid');

export const getUpdateOrganizationTagsErrors = (state) => get(state, 'organization.updateOrganizationTagsErrors');

export const getRateLimitQuotas = (state) => get(state, 'organization.rateLimitQuotas.results');

export const getRateLimitQuotasPage = (state) => get(state, 'organization.rateLimitQuotas');

export const getOrgAPIAccessList = (state) => get(state, 'organization.apiAccessResults.results');

export const getOrgAPIAccessListTotalElements = (state) => get(state, 'organization.apiAccessResults.totalElements');

export const getOrgAPIAccessListTotalPages = (state) => get(state, 'organization.apiAccessResults.totalPages');

export const getIsApiAccessListLoading = (state) => get(state, 'organization.isFetchingAPIAccess');

export const getIsApiAccessListError = (state) => get(state, 'organization.isApiAccessLoadingError');

export const isUpdatingOrgApisAccess = (state) => get(state, 'organization.isUpdatingOrgApisAccess');

export const isUpdatingOrgApisAccessSuccess = (state) => get(state, 'organization.isUpdatingOrgApisAccessSuccess');

export const updatingOrgApisAccessError = (state) => get(state, 'organization.updatingOrgApisAccessError');

export const isUpdatingOrgApisRateQuotaSuccess = (state) => get(state, 'organization.isUpdatingOrgApisRateQuotaSuccess');

export const updatingOrgApisRateQuotaError = (state) => get(state, 'organization.updatingOrgApisRateQuotaError');

export const getProductsListTotalPages = (state) => get(state, 'organization.products.totalPages');
export const getProductsListTotalElements = (state) => get(state, 'organization.products.totalElements');
export const getProductsListResults = (state) => get(state, 'organization.products.results');

export const getProductTiers = (state) => get(state, 'organization.productTiers');
export const getIsProductUpdateSuccess = (state) => get(state, 'organization.isProductUpdateSuccess');
export const getOrgProducts = (state) => get(state, 'organization.orgProducts');
