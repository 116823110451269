import { get } from 'lodash';
// import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, typography, fontSize }) => ({
  applicationLink: {
    display: 'table',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
  },
  btnLabel: {
    height: 21,
  },
  btnRoot: {
    color: color.link,
    fontFamily: typography.bodyText,
    fontSize: fontSize.bodyText,
    height: 21,
    padding: '0 0',
    margin: '0 0',
  },
  collapse: {
    width: '100%',
  },
  selectionContainer: {
    border: `1px solid ${get(color, 'borderColor')}`,
    marginTop: 0,
    padding: '0 24px',
    paddingBottom: 40,
  },
  selectionDropdown: {
    marginTop: '12px !important',
  },
});

export default styles;
