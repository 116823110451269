import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Grid, Button } from '@material-ui/core';
import { func, object, arrayOf, bool, number, shape } from 'prop-types';
import { push } from 'connected-react-router';

import ListContainer from '../../list';
import DetailsContainer from '../../details';
import { fetchProducts, fetchTiers, saveTiers } from '../../../actions/products';
import {
  getErrors,
  getIsError,
  getIsLoading,
  getIsTiersSaveSuccess,
  getProducts,
  getProductsListTotalElements,
  getProductsListTotalPages,
  getTiers,
} from '../../../reducers/products';
import {
  getFeatureFlagForProduct,
  getUserDetails,
} from '../../../reducers/portalConfig';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import styles from './styles';
import {
  DetailsHeader,
  Footer,
  TabPanel,
  TabsContainer,
} from '../../../components';
import { FilterByName } from './filters';
import { getTabIndex, hasAdminRole } from '../../../utils';
import { Tiers } from './Tiers';

const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 24;
const GRID_ROWS_PER_PAGE_OPTIONS = [24, 48, 96];

export const getProductListColumns = (intl) => [
  {
    id: 'name',
    label: intl.getI18nFormattedMessage('label.product.list.column.name'),
    link: '/admin/products/details',
  },
  {
    id: 'description',
    label: intl.getI18nFormattedMessage(
      'label.product.list.column.description',
    ),
    value: (item) => item.description,
    isCellOverflow: true,
  },
];

const getTabItems = () => [
  {
    id: "overview",
    tabId: 'products-list-tab',
    label: getI18nFormattedMessage('label.product.list.title'),
    visible: true,
  },
  {
    id: "tiers",
    tabId: 'products-list-tiers-tab',
    label: getI18nFormattedMessage('label.product.tiers.title'),
    visible: true,
  },
];

const getTabs = (tabItems) => tabItems.filter(({ visible }) => visible);

export const ProductsList = (props) => {
  const {
    featureFlagProduct,
    products,
    push,
    classes,
    fetchProducts,
    isLoading,
    totalElements,
    totalPages,
    user,
    pageErrors,
    isError,
  } = props;

  useEffect(() => {
    if (!featureFlagProduct || !hasAdminRole(user)) {
      props.push('/404');
    }
  }, [featureFlagProduct, user]);

  // Tab logic
  const tabItems = getTabItems();
  const tabs = getTabs(tabItems);
  const defaultIndex = getTabIndex(getTabItems(), props.match);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[defaultIndex].tabId);
  useEffect(() => {
    const defaultIndex = getTabIndex(tabs, props.match);
    setTabIndex(defaultIndex);
    setCurrentTab(tabs[defaultIndex].tabId);
  }, [props.match]);
  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    setCurrentTab(tabs[newValue].tabId);
    if (tabs[newValue].id === 'overview') push(`/admin/products`);
    else push(`/admin/products/${tabs[newValue].id}`);
  };

  const intl = getI18n(useIntl());
  const [rowsPerPage, setRowsPerPage] = useState(
    GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  );
  const [page, setPage] = useState(0);
  const onChangePreviousPage = () => {
    onChangePage(page - 1);
  };
  const onChangeNextPage = () => {
    onChangePage(page + 1);
  };

  const [errors, setErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    fetchProducts({ page, rowsPerPage });
  }, []);

  useEffect(() => {
    setErrors(isError ? pageErrors : []);
  }, [isError, pageErrors]);

  const [nameFilterValue, setNameFilterValue] = useState('');
  useEffect(() => {
    !nameFilterValue &&
      fetchProducts({
        page,
        size: rowsPerPage,
        ...(nameFilterValue && {
          name: nameFilterValue,
        }),
      });
  }, [page, rowsPerPage, nameFilterValue]);
  const onChangePage = (newPage) => {
    setPage(newPage);
  };
  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const renderFilters = () => (
    <>
      <FilterByName
        fieldContainerClass={classes.fieldContainer}
        name={intl.getI18nMessage('label.filter')}
        value={nameFilterValue}
        placeholder={intl.getI18nMessage('label.rate.quota.name')}
        handleChange={(value) => {
          setNameFilterValue(value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setPage(0);
            fetchProducts({
              page: 0,
              size: rowsPerPage,
              ...(nameFilterValue && {
                name: nameFilterValue,
              }),
            });
          }
        }}
      />
      <Grid item className={classes.actionContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => push('/admin/products/details')}
          id="products-list-add"
          data-apim-test="products-list-add"
          data-layer7-test="products-list-add"
        >
          {intl.getI18nMessage('label.product.add')}
        </Button>
      </Grid>
    </>
  );

  return (
    <div
      className={classes.content}
      id="products-page"
      data-layer7-test="products-page"
    >
      <DetailsContainer
        detailsPageId="products-list-page"
        isLoading={isLoading}
        classes={classes}
        notificationId="products-list-notifications"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationMessage}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        notificationContainerClass={classes.notificationContainer}
        hasNotificationIcon={true}
        errors={errors}
        header={
          <DetailsHeader
            headerId="products-list-header-container"
            type="textField"
            name={intl.getI18nMessage('label.product.list.title')}
            subTitle={intl.getI18nMessage('label.product.list.description')}
            isFieldEdit={false}
            classes={classes}
            showDelete={false}
          />
        }
        tabs={
          <TabsContainer
            id="products-list-tab-container"
            tabValue={tabIndex}
            orientation="horizontal"
            tabItems={tabs}
            handleTabChange={handleTabChange}
            tabActiveClass={classes.tabActive}
            tabIndicatorClass={classes.tabIndicator}
          />
        }
        page={
          <>
            <TabPanel
              visible={currentTab === "products-list-tab"}
              id="products-list-tab-panel"
              classes={classes.tabPanel}
            >
              <ListContainer
                listPageId="products-page"
                isLoading={isLoading}
                pageBodyClass={classes.pageBody}
                filterAndSortContent={renderFilters()}
                columns={getProductListColumns(intl) || []}
                rows={products}
                noResultsMessage={
                  nameFilterValue
                    ? intl.getI18nMessage(
                        'label.product.list.no.results.search',
                      )
                    : intl.getI18nMessage('label.product.list.no.results')
                }
                page={page}
                pageFilterAndSortClass={classes.pageFilterAndSort}
                rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
                totalElements={totalElements}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                onChangePreviousPage={onChangePreviousPage}
                onChangeNextPage={onChangeNextPage}
              />
            </TabPanel>
            <TabPanel
              visible={currentTab === "products-list-tiers-tab"}
              id="products-list-tiers-tab-panel"
            >
              <Tiers
                {...props}
                isCurrentTab={currentTab === 'products-list-tiers-tab'}
                notifyMessages={notifyMessages}
              />
            </TabPanel>
          </>
        }
        footer={<Footer />}
      />
    </div>
  );
};

ProductsList.propTypes = {
  featureFlagProduct: bool,
  products: arrayOf(object),
  classes: object,
  fetchProducts: func,
  fetchTiers: func,
  saveTiers: func,
  push: func,
  isLoading: bool,
  totalElements: number,
  totalPages: number,
  user: object,
  isError: bool,
  pageErrors: arrayOf(object),
  match: shape({}),
};

const mapStateToProps = (state) => ({
  featureFlagProduct: getFeatureFlagForProduct(state),
  products: getProducts(state),
  isLoading: getIsLoading(state),
  totalElements: getProductsListTotalElements(state),
  totalPages: getProductsListTotalPages(state),
  user: getUserDetails(state),
  isError: getIsError(state),
  pageErrors: getErrors(state),
  isTiersSaveSuccess: getIsTiersSaveSuccess(state),
  tiers: getTiers(state),
});

const mapDispatchToProps = {
  push,
  fetchProducts,
  fetchTiers,
  saveTiers,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductsList);
