import axios from 'axios';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { getValidationErrors } from '../../utils';
import { stringifyUrl } from '../../utils/actions';
import { AXIOS_DEFAULT_OPTIONS } from '..';
import { upperCase } from 'lodash';

const init = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APP_LOADING,
    isLoading,
  });

export const resetApp = () => dispatch => dispatch({ type: Constants.APP_RESET });

export const showLoading = isLoading => dispatch => init(dispatch, isLoading);

export const fetchRequestDetails = (requestUuid) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;

    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/application-requests/${requestUuid}`;
    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.REQUEST_GET_DETAILS_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.REQUEST_GET_DETAILS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const fetchOrgSuggestions = ({ name }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = stringifyUrl({
      url: `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations`,
      query: {
        name,
      },
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.REQUESTS_GET_ORG_SUGGEST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.REQUESTS_GET_ORG_SUGGEST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchList = ({
  filterByName = '',
  filterByOrg = '',
  filterByType = 'ALL',
  page = 0,
  requestStatus = 'PENDING',
  entityUuid,
  size = 12,
} = {}) =>
  async (dispatch, getState) => {
    init(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url = stringifyUrl({
      url: `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/requests`,
      query: {
        ...(filterByOrg ? { organizationName: filterByOrg } : {}),
        ...(filterByName ? { entityName: filterByName } : {}),
        page,
        requestStatus,
        ...(filterByType === 'ALL' ? {} : { requestType: filterByType }),
        entityUuid,
        size,
      },
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(response =>
        dispatch({
          type: Constants.REQUESTS_GET_LIST_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error =>
        dispatch({
          type: Constants.REQUESTS_GET_LIST_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const submitRequest = ({ isAccept, requestId, reason }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = stringifyUrl({
      url: `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/requests/${requestId}/reviews`,
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });
  const data = {
    uuid: requestId,
    action: isAccept ? 'ACCEPT' : 'REJECT',
    reason,
  };
  dispatch({ type: Constants.REQUESTS_PUT_SUBMIT_REQUEST }),
  await axios.put(url, data , AXIOS_DEFAULT_OPTIONS)
    .then(response =>
      dispatch({
        type: Constants.REQUESTS_PUT_SUBMIT_SUCCESS,
        payload: response.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: Constants.REQUESTS_PUT_SUBMIT_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const reviewApplication = (uuid, action, reason) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = stringifyUrl({
      url: `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/requests/applications/${uuid}/reviews`,
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });
  const data = {
    uuid,
    action: upperCase(action),
    reason,
  };
  dispatch({ type: Constants.APPLICATION_REVIEWS_PUT_REQUEST }),
  await axios.put(url, data , AXIOS_DEFAULT_OPTIONS)
    .then(response =>
      dispatch({
        type: Constants.APPLICATION_REVIEWS_PUT_SUCCESS,
        payload: response.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: Constants.APPLICATION_REVIEWS_PUT_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};
