import React from 'react';
import compose from 'recompose/compose';
import { get, capitalize } from 'lodash';
import { shape } from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import { getI18nFormattedMessage } from '../../../../utils/intl';
import { formatNumber } from '../../../../utils';
import {
  Footer,
} from '../../../../components';
import { ORGANIZATION } from '../../../../constants';
import styles from './styles';


export const Overview = (props) => {
  const {
    classes,
    rateQuotaDetails,
  } = props;

  const rateLimit = get(rateQuotaDetails, 'rateLimit');
  const quota = get(rateQuotaDetails, 'quota.quota') ?
    `${formatNumber(get(rateQuotaDetails, 'quota.quota'))} / ${capitalize(get(rateQuotaDetails, 'quota.interval'))}` : getI18nFormattedMessage('label.none');
  const description = get(rateQuotaDetails, 'description') ? get(rateQuotaDetails, 'description') : getI18nFormattedMessage('label.not.applicable');

  return (
    <div
      id="rate-quota-details-overview-container"
      data-apim-test="rate-quota-details-overview-container"
    >
      <Grid container className={classes.marginPanelContainer}>
        <Grid item md={4} sm={12} xs={12} className={classes.paper}>
          <div className={classes.detailsContainer} data-apim-test="rate-quota-details-container">
            <Typography variant="h4">{getI18nFormattedMessage('label.rate.quota.rate.limit')}</Typography>
            <Typography variant="h5" className={classes.colTitle}>{getI18nFormattedMessage('label.rate.quota.rate.limit.seconds')}</Typography>
            <div className={classes.detailsSubTitle}>{get(rateLimit, 'maxRequestsPerSecond') ? formatNumber(get(rateLimit, 'maxRequestsPerSecond')) : getI18nFormattedMessage('label.none')}</div>
            {get(rateQuotaDetails, 'assignmentLevel') !== ORGANIZATION &&
              <>
                <Typography variant="h5" className={classes.colTitle}>{getI18nFormattedMessage('label.rate.quota.spread.limit.window.seconds')}</Typography>
                <div className={classes.detailsSubTitle}>{get(rateLimit, 'windowSizeInSeconds') ? formatNumber(get(rateLimit, 'windowSizeInSeconds')) : getI18nFormattedMessage('label.none')}</div>
                <Typography variant="h5" className={classes.colTitle}>{getI18nFormattedMessage('label.rate.quota.maximum.concurrency')} :</Typography>
                <div className={classes.detailsSubTitle}>{get(rateLimit, 'maxConcurrency') ? formatNumber(get(rateLimit, 'maxConcurrency')) : getI18nFormattedMessage('label.none')}</div>
              </>
            }
            <Typography variant="h4">{getI18nFormattedMessage('label.rate.quota.limit.quota')}</Typography>
            <Typography variant="h5" className={classes.colTitle}>{getI18nFormattedMessage('label.rate.quota.limit.quota')}</Typography>
            <div className={classes.detailsSubTitle}>{quota}</div>
          </div>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className={classes.paper}>
          <div className={classes.detailsContainer} data-apim-test="rate-quota-details-descriptions-container">
            <Typography variant="h4">{getI18nFormattedMessage('label.description')}</Typography>
            <div className={classes.detailsSubTitle}>{description}</div>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

Overview.propTypes = {
  classes: shape({}),
  rateQuotaDetails: shape({}),
};
Overview.displayName = 'Overview';

export default compose(
  withStyles(styles),
)(Overview);
