import React from 'react';
import {
  withStyles,
  SnackbarContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { func, shape, string, bool } from 'prop-types';
import styles from './styles';

const AlertMessages = (props) => {
  const { id, message, classes, variant, onClose, containerClass = '', className = '', closeText, hasAction = true, hasNotificationIcon } = props;
  return (
    <SnackbarContent
      className={clsx(
        classes[variant],
        classes.messageContainer,
        containerClass,
        className,
      )}
      id={id} data-apim-test={id} data-layer7-test={id}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {hasNotificationIcon &&
            <IconButton
              className={variant === 'error' ? classes.errorIcon : classes.doneIcon } color="inherit"
              id="done-alert-message" data-layer7-test="done-alert-message"
            >
              {variant === 'success' &&
                <CheckCircle className={classes.icon} />
              }
              {variant === 'error' &&
                <ErrorIcon className={classes.icon} />
              }
            </IconButton>
          }
          {message}
        </span>
      }
      action={hasAction && [
        <IconButton
          className={classes.closeButton} color="inherit"
          onClick={onClose}
          id="close-alert-message" data-layer7-test="close-alert-message"
          key="close" aria-label="close"
        > {closeText ? closeText : (<CloseIcon className={classes.icon} />)}
        </IconButton>,
      ]}
    />
  );
};

AlertMessages.propTypes = {
  id: string,
  message: string,
  classes: shape({}),
  containerClass: string,
  variant: string,
  onClose: func,
  className: string,
  hasAction: bool,
  closeText: string,
  hasNotificationIcon: bool,
};

export default withStyles(styles)(AlertMessages);
