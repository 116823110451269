import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { compose } from "recompose";
import { push } from 'connected-react-router';
import cx from 'classnames';
import {
  withStyles,
  Typography,
} from "@material-ui/core";
import WarningAmberIcon from '@material-ui/icons/WarningRounded';
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchRequestSettings,
  updateRequestSettings,
} from "../../actions/requestSettings";
import {
  getIsLoading,
  getIsFormLoadingError,
  getRequestSettings,
  getUpdateErrorMsg,
  getIsFormUpdateError,
  getIsUpdateSuccessful,
} from "../../reducers/requestSettings";
import styles from "./styles";
import { getI18n } from "../../utils/intl";
import { Loading, AlertMessages, FormActionButtons, Switch } from "../../components";
import { ALERT_ERROR, ALERT_SUCCESS, REQUEST_SETTINGS_STATUS_ENABLED, REQUEST_SETTINGS_STATUS_DISABLED } from "../../constants";
import { hasAdminRole } from '../../utils';
import { getUserDetails } from '../../reducers/portalConfig';
export const RequestSettings = (props) => {
  const {
    isFormLoadingError,
    isUpdateError,
    updateErrorMsg,
    classes,
    requestSettings,
    isLoading,
    isUpdateSuccess,
    updateRequestSettings,
    user,
  } = props;
  const intl = getI18n(useIntl());

  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
   const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };
  const [reqSettingsState, setReqSettingsState] = useState({});
  const workflowsMap = {
    registrationRequestWorkflowStatus: "registration",
    applicationRequestWorkflowStatus: "application.add",
    editApplicationRequestWorkflowStatus: "application.edit",
    deleteApplicationRequestWorkflowStatus: "application.delete",
    editApplicationNotificationEmailWorkflowStatus: "application.email",
  };
  useEffect(() => {
    if(!hasAdminRole(user)) {
      props.push('/404');
    }
    props.fetchRequestSettings();
  }, []);
  useEffect(() => {
    if (requestSettings) {
      setReqSettingsState(requestSettings);
    }
  }, [requestSettings]);
  useEffect(() => {
    if (isFormLoadingError) {
      notifyMessages(
        ALERT_ERROR, intl.getI18nMessage("error.settings.requestsettings.load"));
    }
  }, [isFormLoadingError]);
  useEffect(() => {
    if(isUpdateSuccess) {
      props.fetchRequestSettings();
      notifyMessages(
        ALERT_SUCCESS,
        updateErrorMsg ||
          intl.getI18nMessage("label.settings.requestsettings.update.success"));
      setIsFormDirty(false);
    }
  }, [isUpdateSuccess]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const emailDisabled = () =>
    reqSettingsState.applicationRequestWorkflowStatus === REQUEST_SETTINGS_STATUS_DISABLED &&
    reqSettingsState.editApplicationRequestWorkflowStatus === REQUEST_SETTINGS_STATUS_DISABLED &&
    reqSettingsState.deleteApplicationRequestWorkflowStatus === REQUEST_SETTINGS_STATUS_DISABLED;
  const handleUnload = (evt) => {
    const unsaved_changes_warning = intl.getI18nMessage("label.user.profile.unsaved.changes.description");
    evt.returnValue = unsaved_changes_warning;
    return unsaved_changes_warning;
  };
  useEffect(() => {
    if (isFormDirty) {
      window.addEventListener("beforeunload", handleUnload);
      return () => {
        window.removeEventListener("beforeunload", handleUnload);
      };
    }
  }, [isFormDirty]);
  useEffect(() => {
    if (isUpdateError) {
      notifyMessages(
        ALERT_ERROR,
        updateErrorMsg ||
          intl.getI18nMessage("error.settings.requestsettings.update"));
    }
  }, [isUpdateError]);

  const onSave = () => {
    if(emailDisabled()) {
      updateRequestSettings({
        ...reqSettingsState,
        editApplicationNotificationEmailWorkflowStatus: REQUEST_SETTINGS_STATUS_DISABLED,
      });
    } else {
      updateRequestSettings(reqSettingsState);
    }
  };

  const onCancel = () => {
    location.reload();
  };
  const handleChange = (checked, workflow) => {
    // Check if current field is changed or not
    let dirty = checked ?
      requestSettings[workflow] === REQUEST_SETTINGS_STATUS_DISABLED
      :
      requestSettings[workflow] === REQUEST_SETTINGS_STATUS_ENABLED;
    if(!dirty) {
      // If current field is not modified, check all other fields
      const ind = Object.keys(workflowsMap).filter(workflowItem => workflowItem !== workflow)
        .findIndex(workflowItem =>
          requestSettings[workflowItem] !== reqSettingsState[workflowItem]);
      dirty = ind !== -1;
    }
    setIsFormDirty(dirty);
    setReqSettingsState({
      ...reqSettingsState,
      [workflow]: checked ? REQUEST_SETTINGS_STATUS_ENABLED : REQUEST_SETTINGS_STATUS_DISABLED,
    });
  }

  const renderSetting = (item) =>
  <div key={item}>
    <div className={classes.workflowHead}>
      <Typography variant="h4" className={item === "editApplicationNotificationEmailWorkflowStatus" &&
        emailDisabled() ? cx(classes.disabledSmallTitle, classes.smallTitle) : classes.smallTitle }>
        {intl.getI18nMessage(`label.settings.requestsettings.${workflowsMap[item]}.title`)}
      </Typography>
      <Switch
        id={item}
        checked={reqSettingsState[item] === REQUEST_SETTINGS_STATUS_ENABLED && (
          item !== "editApplicationNotificationEmailWorkflowStatus" || !emailDisabled())}
        onChange={handleChange}
        disabled={item === "editApplicationNotificationEmailWorkflowStatus" && emailDisabled()}
      />
    </div>
    <hr className={classes.hr}/>
    <Typography className={classes.bodyText}>
        {intl.getI18nMessage(`label.settings.requestsettings.${workflowsMap[item]}.title.helpertext`)}
    </Typography>
  </div>;
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.outerDiv}>
          <div className={classes.details}>
            <Typography variant="h6" className={classes.smallLabel}>
              {intl.getI18nMessage("label.settings.title")}
            </Typography>
            <Typography variant="h1" className={classes.pageTitle}>
              {intl.getI18nMessage("label.settings.requestsettings.title")}
            </Typography>
            <Typography className={classes.bodyText}>
              {intl.getI18nMessage("label.settings.requestsettings.title.helpertext")}
            </Typography>
            <div className={classes.warningDiv}>
              <WarningAmberIcon className={classes.warningIcon} />
              <Typography className={classes.bodyTextWarning}>
                {intl.getI18nMessage("label.settings.requestsettings.disabling.warning.helpertext")}
              </Typography>
            </div>
            {Object.keys(workflowsMap).map(renderSetting)}
          </div>
          <FormActionButtons
            id={"appBar"}
            cancelText={intl.getI18nMessage("label.cancel.button")}
            nextText={intl.getI18nMessage("label.save.button")}
            onCancelClick={onCancel}
            onNextClick={onSave}
            isDisabled={!isFormDirty}
          />
        </div>
      )}
      {notificationMessage && (
        <AlertMessages
          className={classes.notification}
          id="notificationId"
          data-apim-test="notificationId"
          variant={notificationStatus}
          message={notificationMessage}
          onClose={() => {
            setNotificationMessage("");
            setNotificationStatus("");
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  requestSettings: getRequestSettings(state),
  isLoading: getIsLoading(state),
  isUpdateError: getIsFormUpdateError(state),
  isUpdateSuccess: getIsUpdateSuccessful(state),
  isFormLoadingError: getIsFormLoadingError(state),
  updateErrorMsg: getUpdateErrorMsg(state),
  user: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchRequestSettings,
  updateRequestSettings,
  push,
};

RequestSettings.propTypes = {
  requestSettings: PropTypes.object,
  updateRequestSettings: PropTypes.func,
  isLoading: PropTypes.bool,
  isFormLoadingError: PropTypes.bool,
  isUpdateError: PropTypes.bool,
  updateErrorMsg: PropTypes.string,
  isUpdateSuccess: PropTypes.bool,
  fetchRequestSettings: PropTypes.func,
  classes: PropTypes.object,
  user: PropTypes.object,
  push: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps))(RequestSettings);
