import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import {
  Footer, TabPanel, TabsContainer,
} from '../../../components';
import { getTabIndex, hasAdminRole } from '../../../utils';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';

import { PROCEED_TEXT, ALERT_SUCCESS, SUCCESS } from '../../../constants';
import {
  fetchProduct,
  deleteProduct,
} from '../../../actions/products';
import {
  getIsError,
  getErrors,
  getIsLoading,
  getproductDetails,
  getIsProductSaveSuccess,
  getDeleteProductStatus,
} from '../../../reducers/products';
import {
  getConfig,
  getUserDetails,
  getFeatureFlagForProduct,
} from '../../../reducers/portalConfig';
import { DetailsHeader } from '../../../components';
import DetailsContainer from '../../details';
import { hasError } from '../../../utils';
import Overview from './Overview';
import Apis from './Apis';
import Organizations from './Organizations';
import styles from './styles';
import RateQuota from './RateQuota';

const getTabItems = (productUuid) => [
  {
    id: 'overview',
    tabId: 'products-details-overview-tab',
    label: getI18nFormattedMessage('label.overview'),
    visible: true,
  },
  {
    id: 'ratequota',
    tabId: 'products-details-rate-quota-tab',
    label: getI18nFormattedMessage('label.rate.limit.quotas'),
    visible: !!productUuid,
  },
  {
    id: 'apis',
    tabId: 'products-details-apis-tab',
    label: getI18nFormattedMessage('label.apis'),
    visible: !!productUuid,
  },
  {
    id: 'organizations',
    tabId: 'products-details-organizations-tab',
    label: getI18nFormattedMessage('label.organizations'),
    visible: !!productUuid,
  },
];

const getTabs = (tabItems) => tabItems.filter(({ visible }) => visible);

export const ProductsDetails = (props) => {
  const {
    isLoading,
    userContext,
    pageErrors,
    isError,
    productDetails,
    featureFlagProduct,
    classes,
    history,
    isProductSaveSuccess,
    productDeleteSuccess,
  } = props;

  const productUuid = get(props, 'match.params.productUuid');
  const tabItems = getTabItems(productUuid);

  const pageHeaderRef = useRef(null);
  const intl = getI18n(useIntl());
  const [productName, setProductName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFieldEdit, setIsFieldEdit] = useState(!productUuid);
  const [showDelete, setShowDelete] = useState(!productUuid);
  const [isFieldEditIcon, setIsFieldEditIcon] = useState(!productUuid);
  const [isFieldEditName, setIsFieldEditName] = useState(false);
  const [isProductNameError, setIsProductNameError] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const tabs = getTabs(tabItems);
  const defaultIndex = getTabIndex(tabs, props.match);
  const [redirectTabValue, setRedirectTabValue] = useState(tabs[defaultIndex].tabId);

  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [currentTab, setCurrentTab] = useState(tabs[defaultIndex].tabId);

  useEffect(() => {
    window.scrollTo(0, pageHeaderRef.offsetTop);
    if (!featureFlagProduct) { props.push('/404'); }
  }, [featureFlagProduct]);

  useEffect(() => {
    if (productDeleteSuccess === SUCCESS) {
        notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.product.delete.success'));
        window.location.href = '/admin/products';
    }
}, [productDeleteSuccess]);

  useEffect(() => {
    localStorage.setItem('isUnSavedChanges-products', false);
    if (!hasAdminRole(userContext)) { props.push('/404'); }
  }, [userContext]);

  useEffect(() => {
    if (isProductSaveSuccess || productUuid) {
      setIsFieldEdit(false);
      setIsFieldEditName(false);
      setShowDelete(true);
      if(productUuid) {
        props.fetchProduct(productUuid);
      }
      if(isProductSaveSuccess) {
        setTimeout(() => { notifyMessages('', '')}, 2500);
      }
    }
  }, [isProductSaveSuccess, productUuid]);


  useEffect(() => {
    if(productDetails && productDetails.name) {
      setProductName(productDetails.name);
    }
  }, [productDetails]);

  useEffect(() => {
     const defaultIndex = getTabIndex(tabs, props.match);
      setTabIndex(defaultIndex);
      setCurrentTab(tabs[defaultIndex].tabId);
      if(tabs[defaultIndex].tabId === 'products-details-overview-tab') {
        setIsFieldEditIcon(true);
      } else {
        setIsFieldEditIcon(false);
      }
  }, [props.match]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    setIsFieldEditName(false);
    setErrors(isError ? pageErrors : []);
  }, [isError, pageErrors]);

  const handleChange = (value) => {
    localStorage.setItem('isUnSavedChanges-products', true);
    const nameUiError = hasError(value, true);
    setIsFieldEditName(true);
    setProductName(value);
    setIsProductNameError(nameUiError);
  }

  const onClickEditIcon = () => setIsFieldEdit(true);

  const deleteFn = () => {
    props.deleteProduct(productUuid);
  };

  const onHideEditTitle = () => {
    setIsFieldEditName(false);
    setProductName(get(productDetails, 'name'));
    setIsFieldEdit(false);
  }

  const handleTabChange = (e, newValue) => {
    const hasUnsavedChanges = localStorage.getItem('isUnSavedChanges-products');
    if (hasUnsavedChanges === 'true') {
      setIsDialogOpen(true);
      setRedirectTabValue(newValue);
    } else {
      if(tabs[newValue].tabId !== 'products-details-overview-tab') {
        setIsFieldEditIcon(false);
        onHideEditTitle();
      }
      setTabIndex(newValue);
      setCurrentTab(tabs[newValue].tabId);
      history.push(`/admin/products/details/${productUuid}/${tabs[newValue].id}`)
    }
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const onDialogSubmit = () => {
    onHideEditTitle();
    localStorage.setItem('isUnSavedChanges-products', false);
    setIsDialogOpen(false);
    setTabIndex(redirectTabValue);
    setCurrentTab(tabs[redirectTabValue].tabId);
    history.push(`/admin/products/details/${productUuid}/${tabs[redirectTabValue].id}`)
  };


  return (
    <DetailsContainer
      detailsPageId="products-details-page"
      isLoading={isLoading}
      dialogId="products-unsaved-dialog"
      isDialogOpen={isDialogOpen}
      onDialogClose={onDialogClose}
      dialogSubmitText={PROCEED_TEXT}
      onDialogSubmit={onDialogSubmit}
      notificationId="products-details-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationStatus}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      notificationContainerClass={classes.notificationContainer}
      hasNotificationIcon={true}
      isActionsEnabled={false}
      errors={errors}
      pageHeaderRef={pageHeaderRef}
      header={
        <DetailsHeader
          headerId="products-details-header-container"
          title={intl.getI18nMessage('label.product')}
          type="textField"
          handleChange={handleChange}
          name={productName}
          helperText={intl.getI18nMessage('label.product.name.help')}
          isNameError={isProductNameError}
          isFieldEdit={isFieldEdit}
          isFieldEditIcon={isFieldEditIcon}
          onClickEditIcon={onClickEditIcon}
          deleteFn={deleteFn}
          showDelete={productUuid && showDelete}
        />
      }
      tabs={
        <TabsContainer
          id={'products-details-tab-container'}
          tabValue={tabIndex}
          orientation="horizontal"
          tabItems={tabs}
          handleTabChange={handleTabChange}
        />
      }
      page={
        <Fragment>
          <TabPanel
            visible={currentTab === 'products-details-overview-tab'}
            id="products-overview-tab-panel"
          >
            <Overview
              {...props}
              isCurrentTab={currentTab === 'products-details-overview-tab'}
              productUuid={productUuid}
              notifyMessages={notifyMessages}
              productName={productName}
              productDetails={productDetails}
              isFieldEditName={isFieldEditName}
              onHideEditTitle={onHideEditTitle}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'products-details-rate-quota-tab'}
            id="products-details-rate-quota-tab-panel"
          >
            <RateQuota
              {...props}
              productUuid={productUuid}
              isCurrentTab={currentTab === 'products-details-rate-quota-tab'}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'products-details-apis-tab'}
            id="products-details-apis-tab-panel"
          >
            <Apis
              {...props}
              isCurrentTab={currentTab === 'products-details-apis-tab'}
              productUuid={productUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
          <TabPanel
            visible={currentTab === 'products-details-organizations-tab'}
            id="products-details-orgs-tab-panel"
          >
            <Organizations
              {...props}
              isCurrentTab={currentTab === 'products-details-organizations-tab'}
              productUuid={productUuid}
              notifyMessages={notifyMessages}
            />
          </TabPanel>
        </Fragment>
      }
      footer={
        <Footer />
      }
    />
  );
};

const mapStateToProps = state => ({
  config: getConfig(state),
  isLoading: getIsLoading(state),
  isError: getIsError(state),
  pageErrors: getErrors(state),
  userContext: getUserDetails(state),
  productDetails: getproductDetails(state),
  featureFlagProduct: getFeatureFlagForProduct(state),
  isProductSaveSuccess: getIsProductSaveSuccess(state),
  productDeleteSuccess: getDeleteProductStatus(state),
});

const mapDispatchToProps = {
  fetchProduct,
  push,
  deleteProduct,
};

ProductsDetails.propTypes = {
  config: shape({}),
  userContext: shape({}),
  history: shape({}),
  isLoading: bool,
  isError: bool,
  pageErrors: arrayOf(shape({})),
  match: shape({}),
  push: func,
  productDetails: shape({}),
  featureFlagProduct: bool,
  isProductSaveSuccess: bool,
  classes: shape({}),
  fetchProduct: func,
  deleteProduct: func,
  productDeleteSuccess: string,
};

ProductsDetails.displayName = 'ProductsDetails';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductsDetails);
