import get from 'lodash/get';

const styles = ({ color }) => ({
  detailsContainer: {
    position: 'relative',
    padding: '20px 36px',
  },
  hideFooterContainer: {
    display: 'none',
  },
  showFooterContainer: {
    display: 'visible',
  },
  tierContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fieldContainer: {
    margin: '0px!important',
  },
  textField: {
    padding: '5px 0!important',
    width: '300px!important',
  },
  arrowButton: {
    height: '40px',
    width: '40px',
    marginRight: '8px',
    border: `1px solid`,
    borderRadius: 4,
    padding: '10px',
  },
  removeButton: {
    height: '40px',
    width: '90px',
    marginLeft: '5px',
    border: `1px solid`,
    borderRadius: 4,
    color: get(color, 'error'),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: get(color, 'error'),
      color: get(color, 'white'),
      border: `1px solid ${get(color, 'error')}`,
    },
  },
  addButton: {
    height: '40px',
    width: '90px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    margin: '20px 36px',
  },
});

export default styles;
