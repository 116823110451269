import { getI18nFormattedMessage } from '../utils/intl';

/** Common */
export const DOC_BASE_URL = 'https://techdocs.broadcom.com/us/en/ca-enterprise-software/layer7-api-management/api-developer-portal/5-1/';
export const KEY_ENTER = 'Enter';
export const ANY = 'any';
export const ALL = 'all';
export const KEYS = 'keys';
export const ORGANIZATION = 'ORGANIZATION';

export const LIST_SORT_BY = [
  {
    uuid: 'name,ASC',
    text: getI18nFormattedMessage('label.sort.by.name.asc'),
  }, {
    uuid: 'name,DESC',
    text: getI18nFormattedMessage('label.sort.by.name.desc'),
  }, {
    uuid: 'modifyTs,ASC',
    text: getI18nFormattedMessage('label.sort.by.updated.asc'),
  }, {
    uuid: 'modifyTs,DESC',
    text: getI18nFormattedMessage('label.sort.by.updated.desc'),
  }, {
    uuid: 'createTs,ASC',
    text: getI18nFormattedMessage('label.sort.by.created.asc'),
  }, {
    uuid: 'createTs,DESC',
    text: getI18nFormattedMessage('label.sort.by.created.desc'),
  },
];

export const APP_RESET = 'APP_RESET';
export const APP_LOADING = 'APP_LOADING';

export const PORTAL_STATUS_NEW = 'NEW';
export const PORTAL_STATUS_ENABLED = 'ENABLED';

export const API_ACCESS_STATUS_PRIVATE = 'PRIVATE';

export const USER_CONTEXTS_UPDATE_SUCCESS = 'API_PERMISSION_SAVE_USER_CONTEXTS_SUCCESS';

export const FETCH_REQUESTS_SUCCESS = 'FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_ERROR = 'FETCH_REQUESTS_ERROR';

export const FETCH_APP_SECRET_HASHING_METADATA_SUCCESS = 'FETCH_APP_SECRET_HASHING_METADATA_SUCCESS';
export const FETCH_APP_SECRET_HASHING_METADATA_ERROR = 'FETCH_APP_SECRET_HASHING_METADATA_ERROR';
export const FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_SUCCESS = 'FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_SUCCESS';
export const FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_ERROR = 'FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_ERROR';

export const FETCH_APPLICATION_REQUEST_SETTING_SUCCESS = 'FETCH_APPLICATION_REQUEST_SETTING_SUCCESS';
export const FETCH_APPLICATION_EDIT_REQUEST_SETTING_SUCCESS = 'FETCH_APPLICATION_EDIT_REQUEST_SETTING_SUCCESS';
export const FETCH_APPLICATION_DELETE_REQUEST_SETTING_SUCCESS = 'FETCH_APPLICATION_DELETE_REQUEST_SETTING_SUCCESS';

export const FETCH_SETTING_USER_LOCK_CREATION_SUCCESS = 'FETCH_SETTING_USER_LOCK_CREATION_SUCCESS';

export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';
export const API_ERROR_CLOSE = 'API_ERROR_CLOSE';

export const API_DELETE_SUCCESS = 'API_DELETE_SUCCESS';
export const API_DELETE_ERROR = 'API_DELETE_ERROR';
export const API_DELETE_RESET = 'API_DELETE_RESET';

export const API_PERMISSION_AVAILABLE_USER_SUCCESS = 'API_PERMISSION_AVAILABLE_USER_SUCCESS';
export const API_PERMISSION_SAVE_SUCCESS = 'API_PERMISSION_SAVE_SUCCESS';
export const API_PERMISSION_SELECTED_USER_SUCCESS = 'API_PERMISSION_SELECTED_USER_SUCCESS';

export const API_LIST_REQUEST = 'API_LIST_REQUEST';
export const API_LIST_SUCCESS = 'API_LIST_SUCCESS';
export const API_LIST_ERROR = 'API_LIST_ERROR';
export const API_LIST_APPLICATIONS_SUCCESS = 'API_LIST_APPLICATIONS_SUCCESS';

export const API_LIST_ANALYTICS_LATENCY_REQUEST = 'API_LIST_ANALYTICS_LATENCY_REQUEST';
export const API_LIST_ANALYTICS_LATENCY_SUCCESS = 'API_LIST_ANALYTICS_LATENCY_SUCCESS';
export const API_LIST_ANALYTICS_LATENCY_ERROR = 'API_LIST_ANALYTICS_LATENCY_ERROR';

export const API_RECENT_SEARCH_SUCCESS = 'API_RECENT_SEARCH_SUCCESS';
export const API_RECENT_SEARCH_ERROR = 'API_RECENT_SEARCH_ERROR';
export const API_AUTO_SUGGESTION_SUCCESS = 'API_AUTO_SUGGESTION_SUCCESS';
export const API_AUTO_SUGGESTION_ERROR = 'API_AUTO_SUGGESTION_ERROR';

export const API_PERMISSION_ORGANIZATIONS_FILTERED_SUCCESS = 'API_PERMISSION_ORGANIZATIONS_FILTERED_SUCCESS';
export const API_PERMISSION_ORGANIZATIONS_SUCCESS = 'API_PERMISSION_ORGANIZATIONS_SUCCESS';
export const API_PERMISSION_ALL_ORGANIZATIONS_SUCCESS = 'API_PERMISSION_ALL_ORGANIZATIONS_SUCCESS';
export const API_PERMISSION_SELECTED_ORGANIZATIONS_SUCCESS = 'API_PERMISSION_SELECTED_ORGANIZATIONS_SUCCESS';
export const API_PERMISSION_SAVE_ORGANIZATIONS_SUCCESS = 'API_PERMISSION_SAVE_ORGANIZATIONS_SUCCESS';
export const API_PERMISSION_SELECTED_MANAGE_ORGANIZATION_SUCCESS = 'API_PERMISSION_SELECTED_MANAGE_ORGANIZATION_SUCCESS';
export const API_PERMISSION_REDIRECT_API_TAB = 'API_PERMISSION_REDIRECT_API_TAB';

export const API_PERMISSION_ACCESS_STATUS_SUCCESS = 'API_PERMISSION_ACCESS_STATUS_SUCCESS';
export const API_PERMISSION_ACCESS_STATUS_SAVE_SUCCESS = 'API_PERMISSION_ACCESS_STATUS_SAVE_SUCCESS';
export const API_PERMISSION_PERMITTED_SUCCESS = 'API_PERMISSION_PERMITTED_SUCCESS';
export const API_PERMISSION_PERMITTED_ERROR = 'API_PERMISSION_PERMITTED_ERROR';

export const API_ORGANIZATION_ACCESS_SUCCESS = 'API_ORGANIZATION_ACCESS_SUCCESS';
export const API_ORG_ACCESS_BUTTONS_ADD_SUCCESS = 'API_ORG_ACCESS_BUTTONS_ADD_SUCCESS';
export const API_ORG_ACCESS_BUTTONS_REMOVE_SUCCESS = 'API_ORG_ACCESS_BUTTONS_REMOVE_SUCCESS';
export const API_ORGANIZATION_ACCESS_PATCH_REQUEST = 'API_ORGANIZATION_ACCESS_PATCH_REQUEST';
export const API_ORGANIZATION_ACCESS_PATCH_SUCCESS = 'API_ORGANIZATION_ACCESS_PATCH_SUCCESS';
export const API_ORGANIZATION_ACCESS_PATCH_ERROR = 'API_ORGANIZATION_ACCESS_PATCH_ERROR';

export const APIS_PRODUCTS_GET_SUCCESS = 'APIS_PRODUCTS_GET_SUCCESS';
export const APIS_PRODUCTS_UPDATE_ACCESS_REQUEST = 'APIS_PRODUCTS_UPDATE_ACCESS_REQUEST';
export const APIS_PRODUCTS_UPDATE_ACCESS_SUCCESS = 'APIS_PRODUCTS_UPDATE_ACCESS_SUCCESS';

export const API_TAGS_ALL_TAGS_REQUEST = 'API_TAGS_ALL_TAGS_REQUEST';
export const API_TAGS_ALL_TAGS_SUCCESS = 'API_TAGS_ALL_TAGS_SUCCESS';
export const API_TAGS_API_TAGS_REQUEST = 'API_TAGS_API_TAGS_REQUEST';
export const API_TAGS_API_TAGS_SUCCESS = 'API_TAGS_API_TAGS_SUCCESS';
export const API_SWAGGER_SUCCESS = 'API_SWAGGER_SUCCESS';
export const API_TAGS_CREATE_TAGS_SUCCESS = 'API_TAGS_CREATE_TAGS_SUCCESS';
export const API_TAGS_ASSOCIATE_REQUEST = 'API_TAGS_ASSOCIATE_REQUEST';
export const API_TAGS_ASSOCIATE_SUCCESS = 'API_TAGS_ASSOCIATE_SUCCESS';

export const API_USER_CONTEXTS = 'API_USER_CONTEXTS';

export const ACCESS_TYPE_GRANTED = 'GRANTED_DIRECT';
export const ACCESS_TYPE_MANAGED = 'MANAGED';
export const ACCESS_TYPE_NONE = 'NONE';

export const API_DETAILS_GET_PROXY_LIST_SUCCESS = 'API_DETAILSPROXY_LIST_SUCCESS';
export const API_DETAILS_GET_PROXY_LIST_ERROR = 'API_DETAILS_GET_PROXY_LIST_ERROR';
export const API_DETAILS_DEPLOY_PROXY_SUCCESS = 'API_DETAILS_DEPLOY_PROXY_SUCCESS';
export const API_DETAILS_REDEPLOY_PROXY_SUCCESS = 'API_DETAILS_REDEPLOY_PROXY_SUCCESS';
export const API_DETAILS_UNDEPLOY_PROXY_SUCCESS = 'API_DETAILS_UNDEPLOY_PROXY_SUCCESS';
export const API_DETAILS_DEPLOY_PROXY_ERROR = 'API_DETAILS_DEPLOY_PROXY_ERROR';
export const API_DETAILS_UNDEPLOY_PROXY_ERROR = 'API_DETAILS_UNDEPLOY_PROXY_ERROR';
export const API_DETAILS_REDEPLOY_PROXY_ERROR = 'API_DETAILS_REDEPLOY_PROXY_ERROR';
export const API_DETAILS_GET_DEPLOYMENT_LIST_SUCCESS = 'API_DETAILS_GET_DEPLOYMENT_LIST_SUCCESS';
export const API_DETAILS_GET_DEPLOYMENT_LIST_ERROR = 'API_DETAILS_GET_DEPLOYMENT_LIST_ERROR';
export const API_DETAILS_GET_DOCUMENT_LIST_SUCCESS = 'API_DETAILS_GET_DOCUMENT_LIST_SUCCESS';
export const API_DETAILS_GET_DOCUMENT_LIST_ERROR = 'API_DETAILS_GET_DOCUMENT_LIST_ERROR';
export const API_DETAILS_ADD_DOCUMENT = 'API_DETAILS_ADD_DOCUMENT';
export const API_DETAILS_GET_DOCUMENT_SUCCESS = 'API_DETAILS_GET_DOCUMENT_SUCCESS';
export const API_DETAILS_GET_DOCUMENT_ERROR = 'API_DETAILS_GET_DOCUMENT_ERROR';
export const API_DETAILS_DOCUMENT_SAVE_SUCCESS = 'API_DETAILS_DOCUMENT_SAVE_SUCCESS';
export const API_DETAILS_DOCUMENT_SAVE_ERROR = 'API_DETAILS_DOCUMENT_SAVE_ERROR';
export const API_DETAILS_DOCUMENT_DELETE_SUCCESS = 'API_DETAILS_DOCUMENT_DELETE_SUCCESS';
export const API_DETAILS_DOCUMENT_DELETE_ERROR = 'API_DETAILS_DOCUMENT_DELETE_ERROR';
export const API_DETAILS_DOCUMENT_UPDATE_TREE_SUCCESS = 'API_DETAILS_DOCUMENT_UPDATE_TREE_SUCCESS';
export const API_DETAILS_DOCUMENT_UPDATE_TREE_ERROR = 'API_DETAILS_DOCUMENT_UPDATE_TREE_ERROR';
export const API_DETAILS_DOCUMENT_RESET_NOTIFICATION = 'API_DETAILS_DOCUMENT_RESET_NOTIFICATION';

export const CHANGE = 'CHANGE';
export const API_PROXY_LOADING = 'API_PROXY_LOADING';
export const API_PROXY_RESET = 'API_PROXY_RESET';
export const API_PROXY_VALIDATE_UNIQUE_NAME_RESET = 'API_PROXY_VALIDATE_UNIQUE_NAME_RESET';
export const API_PROXY_VALIDATE_UNIQUE_NAME_SUCCESS = 'API_PROXY_VALIDATE_UNIQUE_NAME_SUCCESS';
export const API_PROXY_VALIDATE_UNIQUE_NAME_ERROR = 'API_PROXY_VALIDATE_UNIQUE_NAME_ERROR';
export const API_PROXY_SAVE_STATUS_RESET = 'API_PROXY_SAVE_STATUS_RESET';
export const API_PROXY_CREATE_SUCCESS = 'API_PROXY_CREATE_SUCCESS';
export const API_PROXY_CREATE_ERROR = 'API_PROXY_CREATE_ERROR';
export const API_PROXY_UPDATE_SUCCESS = 'API_PROXY_UPDATE_SUCCESS';
export const API_PROXY_UPDATE_ERROR = 'API_PROXY_UPDATE_ERROR';
export const API_PROXY_DELETE_SUCCESS = 'API_PROXY_DELETE_SUCCESS';
export const API_PROXY_DELETE_ERROR = 'API_PROXY_DELETE_ERROR';
export const API_PROXY_FETCH_DEPLOYMENT_TYPE_SUCCESS = 'API_PROXY_FETCH_DEPLOYMENT_TYPE_SUCCESS';
export const API_PROXY_FETCH_DEPLOYMENT_TYPE_ERROR = 'API_PROXY_FETCH_DEPLOYMENT_TYPE_ERROR';
export const API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_SUCCESS = 'API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_SUCCESS';
export const API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_ERROR = 'API_PROXY_UPDATE_KEY_DEPLOYMENT_TYPE_ERROR';
export const API_PROXY_FETCH_ORGANIZATIONS_SUCCESS = 'API_PROXY_FETCH_ORGANIZATIONS_SUCCESS';
export const API_PROXY_FETCH_ORGANIZATIONS_ERROR = 'API_PROXY_FETCH_ORGANIZATIONS_ERROR';
export const API_PROXY_FETCH_SELECTED_ORGANIZATIONS_SUCCESS = 'API_PROXY_FETCH_SELECTED_ORGANIZATIONS_SUCCESS';
export const API_PROXY_FETCH_SELECTED_ORGANIZATIONS_ERROR = 'API_PROXY_FETCH_SELECTED_ORGANIZATIONS_ERROR';
export const API_PROXY_UPDATE_ORGS_SUCCESS = 'API_PROXY_UPDATE_ORGS_SUCCESS';
export const API_PROXY_UPDATE_ORGS_ERROR = 'API_PROXY_UPDATE_ORGS_ERROR';
export const API_PROXY_FETCH_DETAILS_SUCCESS = 'API_PROXY_FETCH_DETAILS_SUCCESS';
export const API_PROXY_FETCH_DETAILS_ERROR = 'API_PROXY_FETCH_DETAILS_ERROR';
export const FETCH_API_SYNC_FEATURE_FLAG_SUCCESS = 'FETCH_API_SYNC_FEATURE_FLAG_SUCCESS';
export const FETCH_API_SYNC_FEATURE_FLAG_ERROR = 'FETCH_API_SYNC_FEATURE_FLAG_ERROR';
export const FETCH_API_SYNC_COUNT_SUCCESS = 'FETCH_API_SYNC_COUNT_SUCCESS';
export const FETCH_API_SYNC_COUNT_ERROR = 'FETCH_API_SYNC_COUNT_ERROR';

export const FETCH_GATEWAYS_SUCCESS = 'FETCH_GATEWAYS_SUCCESS';
export const FETCH_GATEWAYS_ERROR = 'FETCH_GATEWAYS_ERROR';
export const FETCH_GATEWAY_DETAILS_SUCCESS = 'FETCH_GATEWAY_DETAILS_SUCCESS';
export const FETCH_GATEWAY_DETAILS_ERROR = 'FETCH_GATEWAY_DETAILS_ERROR';
export const FETCH_APIS_SUCCESS = 'FETCH_APIS_SUCCESS';
export const FETCH_APIS_ERROR = 'FETCH_APIS_ERROR';
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
export const FETCH_APPS_ERROR = 'FETCH_APPS_ERROR';
export const FETCH_ACCOUNT_PLANS_SUCCESS = 'FETCH_ACCOUNT_PLANS_SUCCESS';
export const FETCH_ACCOUNT_PLANS_ERROR = 'FETCH_ACCOUNT_PLANS_ERROR';
export const FETCH_API_PLANS_SUCCESS = 'FETCH_API_PLANS_SUCCESS';
export const FETCH_API_PLANS_ERROR = 'FETCH_API_PLANS_ERROR';
export const FETCH_API_PLAN_SUCCESS = 'FETCH_API_PLAN_SUCCESS';
export const FETCH_API_GROUP_SUCCESS = 'FETCH_API_GROUP_SUCCESS';
export const NONE = '';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const RESET = 'RESET';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const ALERT_SUCCESS = 'success';
export const ALERT_ERROR = 'error';
export const INFO = 'info';
export const NONE_TEXT = 'None';
export const ASSIGNED = 'ASSIGNED';
export const NOT_ASSIGNED = 'NOT_ASSIGNED';

export const PENDING_DEPLOYMENT = 'PENDING_DEPLOYMENT';

export const API_OWNER_TEXT = 'API Owner';
export const DEVELOPER_TEXT = 'Organization Admin';
export const ORGANIZATION_ADMIN_TEXT = 'Developer';

export const CUSTOM_FIELDS_GET_SUCCESS = 'CUSTOM_FIELDS_GET_SUCCESS';
export const POLICY_TEMPLATES_GET_SUCCESS = 'POLICY_TEMPLATES_GET_SUCCESS';

export const API_EULAS_SUCCESS = 'API_EULAS_SUCCESS';
export const APPLICATION_API_GROUP_EULAS_GET_SUCCESS = 'APPLICATION_API_GROUP_EULAS_GET_SUCCESS';
export const API_NAME_UNIQUE_SUCCESS = 'API_NAME_UNIQUE_SUCCESS';
export const API_PROXY_URL_UNIQUE_SUCCESS = 'API_PROXY_URL_UNIQUE_SUCCESS';
export const API_GET_SUCCESS = 'API_GET_SUCCESS';
export const API_SAVE_SUCCESS = 'API_SAVE_SUCCESS';
export const API_UPDATE_SUCCESS = 'API_UPDATE_SUCCESS';
export const API_PUBLISH_SAVE_SUCCESS = 'API_PUBLISH_SAVE_SUCCESS';
export const API_ASSETS_GET_SUCCESS = 'API_ASSETS_GET_SUCCESS';
export const API_USAGE_GET_SUCCESS = 'API_USAGE_GET_SUCCESS';
export const API_CUSTOM_FIELDS_GET_SUCCESS = 'API_CUSTOM_FIELDS_GET_SUCCESS';
export const API_CUSTOM_FIELDS_SAVE_SUCCESS = 'API_CUSTOM_FIELDS_SAVE_SUCCESS';
export const API_POLICY_TEMPLATES_GET_SUCCESS = 'API_POLICY_TEMPLATES_GET_SUCCESS';
export const API_POLICY_TEMPLATES_SAVE_SUCCESS = 'API_POLICY_TEMPLATES_SAVE_SUCCESS';

export const APPLICATION_LOADING = 'APPLICATION_LOADING';
export const APPLICATION_ERROR = 'APPLICATION_ERROR';

export const APPLICATION_GET_SUCCESS = 'APPLICATION_GET_SUCCESS';
export const APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS';
export const APPLICATION_GRANULAR_CREATE_REQUEST = 'APPLICATION_GRANULAR_CREATE_REQUEST';
export const APPLICATION_GRANULAR_CREATE_SUCCESS = 'APPLICATION_GRANULAR_CREATE_SUCCESS';
export const APPLICATION_GRANULAR_CREATE_ERROR = 'APPLICATION_GRANULAR_CREATE_ERROR';
export const APPLICATION_PUBLISH_REQUEST = 'APPLICATION_PUBLISH_REQUEST';
export const APPLICATION_PUBLISH_ERROR = 'APPLICATION_PUBLISH_ERROR'
export const APPLICATION_PUBLISH_SUCCESS= 'APPLICATION_PUBLISH_SUCCESS';
export const APPLICATION_ASSIGNED_APIS_GET_SUCCESS = 'APPLICATION_ASSIGNED_APIS_GET_SUCCESS';
export const APPLICATION_ASSIGNED_APIS_GET_ERROR = 'APPLICATION_ASSIGNED_APIS_GET_ERROR';
export const APPLICATION_ASSIGNED_APIS_UPDATE_REQUEST = 'APPLICATION_ASSIGNED_APIS_UPDATE_REQUEST';
export const APPLICATION_ASSIGNED_APIS_UPDATE_SUCCESS = 'APPLICATION_ASSIGNED_APIS_UPDATE_SUCCESS';
export const APPLICATION_ASSIGNED_APIS_UPDATE_ERROR = 'APPLICATION_ASSIGNED_APIS_UPDATE_ERROR';

export const APPLICATION_ASSIGNED_API_PLANS_GET_SUCCESS = 'APPLICATION_ASSIGNED_API_PLANS_GET_SUCCESS';
export const APPLICATION_ASSIGNED_API_PLANS_GET_ERROR = 'APPLICATION_ASSIGNED_API_PLANS_GET_ERROR';
export const APPLICATION_ASSIGNED_API_PLANS_UPDATE_REQUEST = 'APPLICATION_ASSIGNED_API_PLANS_UPDATE_REQUEST';
export const APPLICATION_ASSIGNED_API_PLANS_UPDATE_SUCCESS = 'APPLICATION_ASSIGNED_API_PLANS_UPDATE_SUCCESS';
export const APPLICATION_ASSIGNED_API_PLANS_UPDATE_ERROR = 'APPLICATION_ASSIGNED_API_PLANS_UPDATE_ERROR';

export const APPLICATION_API_PRODUCTS_ACCESS_UPDATE_REQUEST = 'APPLICATION_API_PRODUCTS_ACCESS_UPDATE_REQUEST';
export const APPLICATION_API_PRODUCTS_ACCESS_UPDATE_SUCCESS = 'APPLICATION_API_PRODUCTS_ACCESS_UPDATE_SUCCESS';
export const APPLICATION_API_PRODUCTS_ACCESS_UPDATE_ERROR = 'APPLICATION_API_PRODUCTS_ACCESS_UPDATE_ERROR';

export const APPLICATION_GET_REQUEST_STATUS_REQUEST = 'APPLICATION_GET_REQUEST_STATUS_REQUEST';
export const APPLICATION_GET_REQUEST_STATUS_SUCCESS = 'APPLICATION_GET_REQUEST_STATUS_SUCCESS';
export const APPLICATION_GET_REQUEST_STATUS_ERROR = 'APPLICATION_GET_REQUEST_STATUS_ERROR';

export const APPLICATION_ASSIGNED_API_GROUPS_GET_SUCCESS = 'APPLICATION_ASSIGNED_API_GROUPS_GET_SUCCESS';
export const APPLICATION_ASSIGNED_API_GROUPS_GET_ERROR = 'APPLICATION_ASSIGNED_API_GROUPS_GET_ERROR';
export const APPLICATION_ASSIGNED_API_GROUPS_UPDATE_REQUEST = 'APPLICATION_ASSIGNED_API_GROUPS_UPDATE_REQUEST';
export const APPLICATION_ASSIGNED_API_GROUPS_UPDATE_SUCCESS = 'APPLICATION_ASSIGNED_API_GROUPS_UPDATE_SUCCESS';
export const APPLICATION_ASSIGNED_API_GROUPS_UPDATE_ERROR = 'APPLICATION_ASSIGNED_API_GROUPS_UPDATE_ERROR';

export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS';
export const APPLICATION_GRANULAR_UPDATE_REQUEST = 'APPLICATION_GRANULAR_UPDATE_REQUEST';
export const APPLICATION_GRANULAR_UPDATE_SUCCESS = 'APPLICATION_GRANULAR_UPDATE_SUCCESS';
export const APPLICATION_GRANULAR_UPDATE_ERROR = 'APPLICATION_GRANULAR_UPDATE_ERROR';
export const APPLICATION_CUSTOM_FIELD_VALUES_GET_SUCCESS = 'APPLICATION_CUSTOM_FIELD_VALUES_GET_SUCCESS';
export const APPLICATION_CUSTOM_FIELD_VALUES_GET_ERROR = 'APPLICATION_CUSTOM_FIELD_VALUES_GET_ERROR';
export const APPLICATION_UPDATE_AND_GET_SUCCESS = 'APPLICATION_UPDATE_AND_GET_SUCCESS';
export const APPLICATION_SECRET_UPDATE_SUCCESS = 'APPLICATION_SECRET_UPDATE_SUCCESS';
export const APPLICATION_NAME_UNIQUE_SUCCESS = 'APPLICATION_NAME_UNIQUE_SUCCESS';
export const APPLICATION_NAME_UNIQUE_REQUEST = 'APPLICATION_NAME_UNIQUE_REQUEST';
export const APPLICATION_NAME_UNIQUE_ERROR = 'APPLICATION_NAME_UNIQUE_ERROR';
export const APPLICATION_CUSTOM_FIELDS_UPDATE_REQUEST = 'APPLICATION_CUSTOM_FIELDS_UPDATE_REQUEST';
export const APPLICATION_CUSTOM_FIELDS_UPDATE_SUCCESS = 'APPLICATION_CUSTOM_FIELDS_UPDATE_SUCCESS';
export const APPLICATION_CUSTOM_FIELDS_UPDATE_ERROR = 'APPLICATION_CUSTOM_FIELDS_UPDATE_ERROR';
export const APPLICATION_ORGANIZATIONS_GET_SUCCESS = 'APPLICATION_ORGANIZATIONS_GET_SUCCESS';
export const APPLICATION_CUSTOM_FIELDS_GET_SUCCESS = 'APPLICATION_CUSTOM_FIELDS_GET_SUCCESS';
export const APPLICATION_SELECTED_APIS_GET_SUCCESS = 'APPLICATION_SELECTED_APIS_GET_SUCCESS';
export const APPLICATION_AVAILABLE_APIS_GET_SUCCESS = 'APPLICATION_AVAILABLE_APIS_GET_SUCCESS';
export const APPLICATION_AVAILABLE_APPS_GET_SUCCESS = 'APPLICATION_AVAILABLE_APPS_GET_SUCCESS';
export const APPLICATION_AVAILABLE_ORGS_GET_SUCCESS = 'APPLICATION_AVAILABLE_ORGS_GET_SUCCESS';
export const APPLICATION_API_EULA_GET_SUCCESS = 'APPLICATION_API_EULA_GET_SUCCESS';
export const APPLICATION_SELECTED_API_GROUPS_GET_SUCCESS = 'APPLICATION_SELECTED_API_GROUPS_GET_SUCCESS';
export const APPLICATION_GENERATE_SECRET_GET_SUCCESS = 'APPLICATION_GENERATE_SECRET_GET_SUCCESS';
export const APPLICATION_AVAILABLE_API_GROUPS_GET_SUCCESS = 'APPLICATION_AVAILABLE_API_GROUPS_GET_SUCCESS';
export const APPLICATION_AVAILABLE_API_PRODUCTS_GET_REQUEST = 'APPLICATION_AVAILABLE_API_PRODUCTS_GET_REQUEST';
export const APPLICATION_AVAILABLE_API_PRODUCTS_GET_SUCCESS = 'APPLICATION_AVAILABLE_API_PRODUCTS_GET_SUCCESS';
export const APPLICATION_AVAILABLE_API_PLANS_GET_SUCCESS = 'APPLICATION_AVAILABLE_API_PLANS_GET_SUCCESS';

export const TENANT_INFO_GET_SUCCESS = 'TENANT_INFO_GET_SUCCESS';
export const APPLICATION_APP_KEY_SECRET_DEFAULT_HASH_FROM_DB = '********';
export const APPLICATION_ALL_APIS_GET_SUCCESS = 'APPLICATION_ALL_APIS_GET_SUCCESS';
export const APPLICATION_ALL_APPS_GET_SUCCESS = 'APPLICATION_ALL_APPS_GET_SUCCESS';

export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_ERROR = 'PRODUCT_LIST_ERROR';

export const PROXIES_GET_SUCCESS = 'PROXIES_GET_SUCCESS';
export const PROXIES_ERROR = 'PROXIES_ERROR';

export const API_KEY_STATUS_DISABLED = 'DISABLED';
export const API_KEY_STATUS_ENABLED = 'ENABLED';
export const API_KEY_STATUS_DELETE_FAILED = 'DELETE_FAILED';
export const API_KEY_PROXIES_GET_SUCCESS = 'API_KEY_PROXIES_GET_SUCCESS';
export const API_KEY_PROXIES_GET_ERROR = 'API_KEY_PROXIES_GET_ERROR';
export const API_KEY_DEPLOY_SUCCESS = 'API_KEY_DEPLOY_SUCCESS';
export const API_KEY_DEPLOY_ERROR = 'API_KEY_DEPLOY_ERROR';
export const API_KEY_REDEPLOY_SUCCESS = 'API_KEY_REDEPLOY_SUCCESS';
export const API_KEY_REDEPLOY_ERROR = 'API_KEY_REDEPLOY_ERROR';
export const API_KEY_UNDEPLOY_SUCCESS = 'API_KEY_UNDEPLOY_SUCCESS';
export const API_KEY_UNDEPLOY_ERROR = 'API_KEY_UNDEPLOY_ERROR';

export const APPLICATION_ALL_KEYS_GET_SUCCESS = 'APPLICATION_ALL_KEYS_GET_SUCCESS';
export const APPLICATION_ALL_KEYS_GET_ERROR = 'APPLICATION_ALL_KEYS_GET_ERROR';
export const APPLICATION_ALL_KEYS_COUNT_GET_SUCCESS = 'APPLICATION_ALL_KEYS_COUNT_GET_SUCCESS';
export const APPLICATION_ALL_KEYS_COUNT_GET_ERROR = 'APPLICATION_ALL_KEYS_COUNT_GET_ERROR';
export const KEY_NAME_UNIQUE_SUCCESS = 'KEY_NAME_UNIQUE_SUCCESS';
export const KEY_NAME_UNIQUE_ERROR = 'KEY_NAME_UNIQUE_ERROR';
export const KEY_STATUS_RESET = 'KEY_STATUS_RESET';
export const KEY_DELETE_STATUS_RESET = 'KEY_DELETE_STATUS_RESET';
export const KEY_CREATE_SUCCESS = 'KEY_CREATE_SUCCESS';
export const KEY_CREATE_ERROR = 'KEY_CREATE_ERROR';
export const KEY_UPDATE_SUCCESS = 'KEY_UPDATE_SUCCESS';
export const KEY_UPDATE_ERROR = 'KEY_UPDATE_ERROR';
export const KEY_DELETE_SUCCESS = 'KEY_DELETE_SUCCESS';
export const KEY_DELETE_ERROR = 'KEY_DELETE_ERROR';
export const KEY_STATUS_DELETE_FAILED = 'DELETE_FAILED';
export const APPLICATION_SINGLE_GET_SUCCESS = 'APPLICATION_SINGLE_GET_SUCCESS';
export const APPLICATION_SINGLE_GET_ERROR = 'APPLICATION_SINGLE_GET_ERROR';
export const APPLICATION_API_PLANS_GET_SUCCESS = 'APPLICATION_API_PLANS_GET_SUCCESS';
export const APPLICATION_DELETE_RESET = 'APPLICATION_DELETE_RESET';
export const APPLICATION_DELETE_SUCCESS = 'APPLICATION_DELETE_SUCCESS';
export const APPLICATION_DELETE_ERROR = 'APPLICATION_DELETE_ERROR';
export const APPLICATION_STATUS_UPDATE_RESET = 'APPLICATION_STATUS_UPDATE_RESET';
export const APPLICATION_STATUS_UPDATE_SUCCESS = 'APPLICATION_STATUS_UPDATE_SUCCESS';
export const APPLICATION_STATUS_UPDATE_ERROR = 'APPLICATION_STATUS_UPDATE_ERROR';

export const TYPE_API = 'TYPE_API';
export const TYPE_API_GROUP = 'TYPE_API_GROUP';

export const API_STATUS_DISABLED = 'DISABLED';
export const API_STATUS_ENABLED = 'ENABLED';
export const API_STATUS_NEW = 'NEW';
export const API_STATUS_DEPRECATED = 'DEPRECATED';
export const API_STATUS_INCOMPLETE = 'INCOMPLETE';
export const API_STATUS_PENDING_BUNDLE = 'PENDING_BUNDLE';

export const APPLICATION_STATUS_ENABLED = 'ENABLED';
export const APPLICATION_STATUS_DISABLED = 'DISABLED';
export const APPLICATION_STATUS_INCOMPLETE = 'INCOMPLETE';
export const APPLICATION_STATUS_PENDING_APPROVAL = 'APPLICATION_PENDING_APPROVAL';
export const APPLICATION_STATUS_EDIT_PENDING_APPROVAL = 'EDIT_APPLICATION_PENDING_APPROVAL';
export const APPLICATION_STATUS_REJECTED = 'REJECTED';
export const APPLICATION_STATUS_DELETE_PENDING_APPROVAL = 'DELETE_APPLICATION_PENDING_APPROVAL';
export const APPLICATION_STATUS_DELETE_FAILED = 'DELETE_FAILED';

export const APPLICATION_DISABLED_BY_TYPE_EXTERNAL = 'EXTERNAL';

export const DASHBOARD_AUTOSUGGEST_SHOW_SUCCESS = 'DASHBOARD_AUTOSUGGEST_SHOW_SUCCESS';
export const DASHBOARD_AUTOSUGGEST_SHOW_ERROR = 'DASHBOARD_AUTOSUGGEST_SHOW_ERROR';
export const DASHBOARD_GET_ENTITY_COUNT_SUCCESS = 'DASHBOARD_GET_ENTITY_COUNT_SUCCESS';
export const DASHBOARD_GET_ENTITY_COUNT_ERROR = 'DASHBOARD_GET_ENTITY_COUNT_ERROR';
export const DASHBOARD_CHART_DATA_SUCCESS = 'DASHBOARD_CHART_DATA_SUCCESS';
export const DASHBOARD_CHART_DATA_ERROR = 'DASHBOARD_CHART_DATA_ERROR';

export const DEPLOYMENT_STATUS_DEPLOYED = 'DEPLOYED';
export const DEPLOYMENT_STATUS_PENDING_DEPLOYMENT = 'PENDING_DEPLOYMENT';
export const DEPLOYMENT_STATUS_PENDING_UNDEPLOYMENT = 'PENDING_UNDEPLOYMENT';
export const DEPLOYMENT_STATUS_ERROR = 'ERROR';
export const DEPLOYMENT_STATUS_ERROR_UNDEPLOY = 'ERROR_UNDEPLOY';
export const DEPLOYMENT_STATUS_NOT_DEPLOYED = 'NOT_DEPLOYED';
export const DEPLOYMENT_ACTION_TYPE_DEPLOY = 'DEPLOY';
export const DEPLOYMENT_ACTION_TYPE_REDEPLOY = 'REDEPLOY';
export const DEPLOYMENT_ACTION_TYPE_UNDEPLOY = 'UNDEPLOY';
export const DEPLOYMENT_STATUS_UNDEPLOYED_ERROR = 'ERROR_UNDEPLOY';

export const FETCH_CUSTOM_PAGES_SUCCESS = 'FETCH_CUSTOM_PAGES_SUCCESS';
export const FETCH_CUSTOM_PAGES_ERROR = 'FETCH_CUSTOM_PAGES_ERROR';
export const FETCH_CUSTOM_PAGES_BUNDLES_SUCCESS = 'FETCH_CUSTOM_PAGES_BUNDLES_SUCCESS';
export const FETCH_CUSTOM_PAGES_BUNDLES_ERROR = 'FETCH_CUSTOM_PAGES_BUNDLES_ERROR';
export const FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_SUCCESS = 'FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_SUCCESS';
export const FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_ERROR = 'FETCH_CUSTOM_PAGES_LIST_IN_BUNDLE_ERROR';
export const UPLOAD_CUSTOM_PAGES_BUNDLE_SUCCESS = 'UPLOAD_CUSTOM_PAGES_BUNDLE_SUCCESS';
export const UPLOAD_CUSTOM_PAGES_BUNDLE_ERROR = 'UPLOAD_CUSTOM_PAGES_BUNDLE_ERROR';
export const DELETE_CUSTOM_PAGES_BUNDLE_SUCCESS = 'DELETE_CUSTOM_PAGES_BUNDLE_SUCCESS';
export const DELETE_CUSTOM_PAGES_BUNDLE_ERROR = 'DELETE_CUSTOM_PAGES_BUNDLE_ERROR';
export const SAVE_CUSTOM_PAGES_SUCCESS = 'SAVE_CUSTOM_PAGES_SUCCESS';
export const SAVE_CUSTOM_PAGES_ERROR = 'SAVE_CUSTOM_PAGES_ERROR';
export const SAVE_CUSTOM_PAGES_RESET_SUCCESS = 'SAVE_CUSTOM_PAGES_RESET_SUCCESS';
export const RESET_CUSTOM_PAGES_SUCCESS_MESSAGE = 'RESET_CUSTOM_PAGES_SUCCESS_MESSAGE';

/** APIs */
export const APIS_LIST_VISIBILITIES_FILTER_OPTIONS = [
  {
    uuid: 'All',
    text: getI18nFormattedMessage('label.apis.filter.visibilities.all'),
  }, {
    uuid: 'Public',
    text: getI18nFormattedMessage('label.apis.filter.visibilities.public'),
  }, {
    uuid: 'Private',
    text: getI18nFormattedMessage('label.apis.filter.visibilities.private'),
  },
];

export const API_EDIT_ACCESS_STATUS = [
  {
    uuid: 'PUBLIC',
    name: 'Public',
  }, {
    uuid: 'PRIVATE',
    name: 'Private',
  },
];

export const APIS_LIST_STATES_FILTER_OPTIONS = [
  {
    uuid: 'All',
    text: getI18nFormattedMessage('label.apis.filter.states.all'),
  }, {
    uuid: 'Enabled',
    text: getI18nFormattedMessage('label.apis.filter.states.enabled'),
  }, {
    uuid: 'Disabled',
    text: getI18nFormattedMessage('label.apis.filter.states.disabled'),
  }, {
    uuid: 'Deprecated',
    text: getI18nFormattedMessage('label.apis.filter.states.deprecated'),
  }, {
    uuid: 'New',
    text: getI18nFormattedMessage('label.apis.filter.states.unpublished'),
  }, {
    uuid: 'Incomplete',
    text: getI18nFormattedMessage('label.apis.filter.states.incomplete'),
  },
];

export const APIS_LIST_SORT_BY = [
  {
    uuid: 'name,ASC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.name.asc'),
  }, {
    uuid: 'name,DESC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.name.desc'),
  }, {
    uuid: 'createTs,DESC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.date.created.desc'),
  }, {
    uuid: 'createTs,ASC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.date.created.asc'),
  }, {
    uuid: 'modifyTs,DESC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.date.modified.desc'),
  }, {
    uuid: 'modifyTs,ASC',
    text: getI18nFormattedMessage('label.apis.filter.sortby.date.modified.asc'),
  },
];

/** Applications */
export const HELP_ITEMS = {
  details: {
    title: 'How do I Publish an Application?',
    descriptions: '',
    subItems: [
      {
        id: 0,
        title: '',
        descriptions: 'On the Details tab, select the organization (if you are a Global Publisher) and enter a name and description. Note: If you are an Org User, the org for the application will be the org that you belong to.',
      },
      {
        id: 1,
        title: '',
        descriptions: 'On the API Management tab, add APIs.',
      },
      {
        id: 2,
        title: '',
        descriptions: 'On the Authentication tab, if any of the added APIs use OAuth, specify the callback/redirect URL and scope. Set the type to public or confidential.',
      },
      {
        id: 3,
        title: '',
        descriptions: 'On the Key tab, you can view the API Key and Shared Secret and you can generate a new plain or hashed secret.',
      },
      {
        id: 4,
        title: '',
        descriptions: 'You can add multiple API keys to your application. First, add your application. This generates the default API key for the application. To add other API keys, edit your application.',
      },
    ],
  },
};

export const MULTIKEY_HELP_ITEMS = {
  details: {
    title: 'How do I publish an Application?',
    descriptions: '',
    subItems: [
      {
        id: 0,
        title: '',
        descriptions: 'On the Details tab, select the organization (if you are a Global Publisher) and enter a name and description. Note: If you are an Org User, the org for the application will be the org that you belong to.',
      },
      {
        id: 1,
        title: '',
        descriptions: 'On the API Management tab, add APIs.',
      },
      {
        id: 2,
        title: '',
        descriptions: 'On the Authentication & Keys tab, manage the API keys for the application. API Portal auto-generates an API key, a shared secret, and the other settings that you defined for the application when a user adds an application to API Portal. This is the default API key for the application. You can add more keys to the application.',
      },
    ],
  },
};

/** Gateway Bundles */

export const SYSTEM_BUNDLE_GROUP_NAME = 'l7.apim.system';

export const GATEWAY_BUNDLES_LIST_SORT_BY = [
  {
    uuid: 'createTs,DESC',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.sort.by.date.created.desc'),
  }, {
    uuid: 'createTs,ASC',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.sort.by.date.created.asc'),
  }, {
    uuid: 'name,ASC',
    text: getI18nFormattedMessage('label.sort.by.name.asc'),
  }, {
    uuid: 'name,DESC',
    text: getI18nFormattedMessage('label.sort.by.name.desc'),
  },
];

export const GATEWAY_BUNDLES_LIST_TYPE_BY = [
  {
    uuid: 'any',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.type.by.any.bundle.types'),
  }, {
    uuid: 'ALL',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.type.by.all.bundle.types'),
  },
  {
    uuid: 'ENCAPSULATED_ASSERTION',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.type.by.encapsulated.assertion'),
  }, {
    uuid: 'ENVIRONMENT',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.type.by.environment'),
  },
];

export const GATEWAY_BUNDLES_LIST_PORTAL_TEMPLATE_BY = [
  {
    uuid: 'any',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.policy.template.by.any'),
  }, {
    uuid: 'true',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.policy.template.by.true'),
  }, {
    uuid: 'false',
    text: getI18nFormattedMessage('label.gateway.bundle.list.page.policy.template.by.false'),
  },
];

export const GATEWAY_BUNDLES_API_SORT_BY = [
  {
    uuid: 'apiModifyTs,DESC',
    text: getI18nFormattedMessage('label.gateway.bundle.api.page.sort.by.date.modified.desc'),
  }, {
    uuid: 'apiModifyTs,ASC',
    text: getI18nFormattedMessage('label.gateway.bundle.api.page.sort.by.date.modified.asc'),
  }, {
    uuid: 'apiName,ASC',
    text: getI18nFormattedMessage('label.sort.by.api.name.asc'),
  }, {
    uuid: 'apiName,DESC',
    text: getI18nFormattedMessage('label.sort.by.api.name.desc'),
  },
];

export const GATEWAY_BUNDLES_API_STATES_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.gateway.bundle.api.states.all'),
  }, {
    uuid: 'Enabled',
    text: getI18nFormattedMessage('label.gateway.bundle.api.states.enabled'),
  }, {
    uuid: 'Disabled',
    text: getI18nFormattedMessage('label.gateway.bundle.api.states.disabled'),
  }, {
    uuid: 'Deprecated',
    text: getI18nFormattedMessage('label.gateway.bundle.api.states.deprecated'),
  }, {
    uuid: 'New',
    text: getI18nFormattedMessage('label.gateway.bundle.api.states.unpublished'),
  },
];

export const GATEWAY_BUNDLES_DETAILS_SUCCESS = 'GATEWAY_BUNDLES_DETAILS_SUCCESS';
export const GATEWAY_BUNDLES_DETAILS_ERROR = 'GATEWAY_BUNDLES_DETAILS_ERROR';
export const GATEWAY_BUNDLES_GET_SUCCESS = 'GATEWAY_BUNDLES_GET_SUCCESS';
export const GATEWAY_BUNDLES_GET_ERROR = 'GATEWAY_BUNDLES_GET_ERROR';
export const GATEWAY_BUNDLES_SAVE_SUCCESS = 'GATEWAY_BUNDLES_SAVE_SUCCESS';
export const GATEWAY_BUNDLES_SAVE_ERROR = 'GATEWAY_BUNDLES_SAVE_ERROR';
export const GATEWAY_BUNDLES_GET_USAGE_SUCCESS = 'GATEWAY_BUNDLES_GET_USAGE_SUCCESS';
export const GATEWAY_BUNDLES_GET_USAGE_ERROR = 'GATEWAY_BUNDLES_GET_USAGE_ERROR';

export const GATEWAY_BUNDLES_DELETE_SUCCESS = 'GATEWAY_BUNDLES_DELETE_SUCCESS';
export const GATEWAY_BUNDLES_DELETE_ERROR = 'GATEWAY_BUNDLES_DELETE_ERROR';

export const GATEWAY_BUNDLES_GET_PROXY_SUCCESS = 'GATEWAY_BUNDLES_GET_PROXY_SUCCESS';
export const GATEWAY_BUNDLES_GET_PROXY_ERROR = 'GATEWAY_BUNDLES_GET_PROXY_ERROR';
export const GATEWAY_BUNDLES_GET_PROXY_LIST_SUCCESS = 'GATEWAY_BUNDLES_GET_PROXY_LIST_SUCCESS';
export const GATEWAY_BUNDLES_GET_PROXY_LIST_ERROR = 'GATEWAY_BUNDLES_GET_PROXY_LIST_ERROR';

export const GATEWAY_BUNDLES_GET_APIS_SUCCESS = 'GATEWAY_BUNDLES_GET_APIS_SUCCESS';
export const GATEWAY_BUNDLES_GET_APIS_ERROR = 'GATEWAY_BUNDLES_GET_APIS_ERROR';

export const GATEWAY_BUNDLES_DEPLOY_PROXY_SUCCESS = 'GATEWAY_BUNDLES_DEPLOY_PROXY_SUCCESS';
export const GATEWAY_BUNDLES_REDEPLOY_PROXY_SUCCESS = 'GATEWAY_BUNDLES_REDEPLOY_PROXY_SUCCESS';
export const GATEWAY_BUNDLES_UNDEPLOY_PROXY_SUCCESS = 'GATEWAY_BUNDLES_UNDEPLOY_PROXY_SUCCESS';
export const GATEWAY_BUNDLES_DEPLOY_PROXY_ERROR = 'GATEWAY_BUNDLES_DEPLOY_PROXY_ERROR';
export const GATEWAY_BUNDLES_UNDEPLOY_PROXY_ERROR = 'GATEWAY_BUNDLES_UNDEPLOY_PROXY_ERROR';
export const GATEWAY_BUNDLES_REDEPLOY_PROXY_ERROR = 'GATEWAY_BUNDLES_REDEPLOY_PROXY_ERROR';
export const GATEWAY_BUNDLES_DELETE_RESET = 'GATEWAY_BUNDLES_DELETE_RESET';
export const GATEWAY_BUNDLES_ENCAPSULATED_ASSERTION = 'ENCAPSULATED_ASSERTION';

export const GATEWAY_BUNDLES_ADD_REQUEST = 'GATEWAY_BUNDLES_ADD_REQUEST';

/* Organizations */
export const ORGANIZATIONS_GET_SUCCESS = 'ORGANIZATIONS_GET_SUCCESS';
export const ORGANIZATIONS_GET_ERROR = 'ORGANIZATIONS_GET_ERROR';
export const ORGANIZATION_LIST_GET_SUCCESS = 'ORGANIZATION_LIST_GET_SUCCESS';
export const ORGANIZATION_LIST_GET_ERROR = 'ORGANIZATION_LIST_GET_ERROR';
export const ORGANIZATION_GET_REQUEST = 'ORGANIZATION_GET_REQUEST';
export const ORGANIZATION_GET_SUCCESS = 'ORGANIZATION_GET_SUCCESS';
export const ORGANIZATION_GET_ERROR = 'ORGANIZATION_GET_ERROR';
export const ACCOUNTPLANS_GET_REQUEST = 'ACCOUNTPLANS_GET_REQUEST';
export const ACCOUNTPLANS_GET_SUCCESS = 'ACCOUNTPLANS_GET_SUCCESS';
export const ACCOUNTPLANS_GET_ERROR = 'ACCOUNTPLANS_GET_ERROR';
export const APPLICATION_LIST_REQUEST = 'APPLICATION_LIST_REQUEST';
export const APPLICATION_LIST_SUCCESS = 'APPLICATION_LIST_SUCCESS';
export const APPLICATION_LIST_ERROR = 'APPLICATION_LIST_ERROR';
export const ORGANIZATION_NAME_UNIQUE_REQUEST = 'ORGANIZATION_NAME_UNIQUE_REQUEST';
export const ORGANIZATION_NAME_UNIQUE_SUCCESS = 'ORGANIZATION_NAME_UNIQUE_SUCCESS';
export const ORGANIZATION_NAME_UNIQUE_ERROR = 'ORGANIZATION_NAME_UNIQUE_ERROR';
export const ORGANIZATION_CREATE_REQUEST = 'ORGANIZATION_CREATE_REQUEST';
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS';
export const ORGANIZATION_CREATE_ERROR = 'ORGANIZATION_CREATE_ERROR';
export const ORGANIZATION_UPDATE_REQUEST = 'ORGANIZATION_UPDATE_REQUEST';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_ERROR = 'ORGANIZATION_UPDATE_ERROR';
export const ORGANIZATION_TYPE_PUBLISHER = 'PUBLISHER';
export const ORGANIZATION_TYPE_CONSUMER = 'CONSUMER';
export const USER_PROFILE_GET_REQUEST = 'USER_PROFILE_GET_REQUEST';
export const USER_PROFILE_GET_SUCCESS = 'USER_PROFILE_GET_SUCCESS';
export const USER_PROFILE_GET_ERROR = 'USER_PROFILE_GET_ERROR';
export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';
export const USER_ORGANIZATIONS_FILTERED_SUCCESS = 'USER_ORGANIZATIONS_FILTERED_SUCCESS';
export const USER_ORGANIZATIONS_FILTERED_ERROR = 'USER_ORGANIZATIONS_FILTERED_ERROR';
export const PUBLIC_KEY_GET_REQUEST = 'PUBLIC_KEY_GET_REQUEST';
export const PUBLIC_KEY_GET_SUCCESS = 'PUBLIC_KEY_GET_SUCCESS';
export const PUBLIC_KEY_GET_ERROR = 'PUBLIC_KEY_GET_ERROR';
export const PASSWORD_POLICY_GET_REQUEST = 'PASSWORD_POLICY_GET_REQUEST';
export const PASSWORD_POLICY_GET_SUCCESS = 'PASSWORD_POLICY_GET_SUCCESS';
export const PASSWORD_POLICY_GET_ERROR = 'PASSWORD_POLICY_GET_ERROR';
export const REQUEST_SETTINGS_GET_REQUEST = 'REQUEST_SETTINGS_GET_REQUEST';
export const REQUEST_SETTINGS_GET_SUCCESS = 'REQUEST_SETTINGS_GET_SUCCESS';
export const REQUEST_SETTINGS_GET_ERROR = 'REQUEST_SETTINGS_GET_ERROR';
export const REQUEST_SETTINGS_UPDATE_REQUEST = 'REQUEST_SETTINGS_UPDATE_REQUEST';
export const REQUEST_SETTINGS_UPDATE_SUCCESS = 'REQUEST_SETTINGS_UPDATE_SUCCESS';
export const REQUEST_SETTINGS_UPDATE_ERROR = 'REQUEST_SETTINGS_UPDATE_ERROR';
export const API_ORG_ACCESS_RATE_QUOTA_PATCH_SUCCESS = 'API_ORG_ACCESS_RATE_QUOTA_PATCH_SUCCESS';
export const API_ORG_ACCESS_RATE_QUOTA_PATCH_ERROR = 'API_ORG_ACCESS_RATE_QUOTA_PATCH_ERROR';

export const ORGANIZATION_LIST_TYPE_FILTER_BY = [
  {
    uuid: ANY,
    text: getI18nFormattedMessage('label.organization.list.page.filter.by.all.types'),
  }, {
    uuid: 'PUBLISHER',
    text: getI18nFormattedMessage('label.organization.list.page.filter.by.publisher.types'),
  }, {
    uuid: 'CONSUMER',
    text: getI18nFormattedMessage('label.organization.list.page.filter.by.consumer.types'),
  },
];

export const APPLICATION_LIST_STATUS_BY = [
  {
    uuid: ANY,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.all.status'),
  },
  {
    uuid: APPLICATION_STATUS_ENABLED,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.enabled.status'),
  },
  {
    uuid: APPLICATION_STATUS_DISABLED,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.disabled.status'),
  },
  {
    uuid: `${APPLICATION_STATUS_DELETE_PENDING_APPROVAL},${APPLICATION_STATUS_EDIT_PENDING_APPROVAL},${APPLICATION_STATUS_PENDING_APPROVAL}`,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.pending.status'),
  },
  {
    uuid: APPLICATION_STATUS_REJECTED,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.rejected.status'),
  },
  {
    uuid: APPLICATION_STATUS_INCOMPLETE,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.incomplete.status'),
  },
  {
    uuid: APPLICATION_STATUS_DELETE_FAILED,
    text: getI18nFormattedMessage('label.application.list.page.filter.by.delete.failed.status'),
  },
];
export const API_ACCESS_STATUS_FILTER_BY = [
  {
    uuid: ANY,
    text: getI18nFormattedMessage('label.apis.filter.accesstype.all'),
  }, {
    uuid: 'PRIVATE',
    text: getI18nFormattedMessage('label.apis.filter.accesstype.private'),
  }, {
    uuid: 'PUBLIC',
    text: getI18nFormattedMessage('label.apis.filter.accesstype.public'),
  },
];

export const ORGANIZATION_STATUS_ENABLED = 'ENABLED';
export const ORGANIZATION_STATUS_REGISTRATION_INIT = 'REGISTRATION_INIT';
export const ORGANIZATION_STATUS_REGISTRATION_PENDING_APPROVAL = 'REGISTRATION_PENDING_APPROVAL';

export const ORGANIZATION_STATUS_LABEL = {
  [ORGANIZATION_STATUS_ENABLED]: getI18nFormattedMessage('label.organization.status.enabled'),
  [ORGANIZATION_STATUS_REGISTRATION_INIT]: getI18nFormattedMessage('label.organization.status.registration.init'),
  [ORGANIZATION_STATUS_REGISTRATION_PENDING_APPROVAL]: getI18nFormattedMessage('label.organization.status.registration.pending.approval'),
};


export const ORGANIZATION_LIST_STATUS_FILTER_BY = [
  {
    uuid: ALL,
    text: getI18nFormattedMessage('label.organization.list.page.status.by.all.states'),
  }, {
    uuid: ORGANIZATION_STATUS_ENABLED,
    text: ORGANIZATION_STATUS_LABEL[ORGANIZATION_STATUS_ENABLED],
  }, {
    uuid: ORGANIZATION_STATUS_REGISTRATION_INIT,
    text: ORGANIZATION_STATUS_LABEL[ORGANIZATION_STATUS_REGISTRATION_INIT],
  }, {
    uuid: ORGANIZATION_STATUS_REGISTRATION_PENDING_APPROVAL,
    text: ORGANIZATION_STATUS_LABEL[ORGANIZATION_STATUS_REGISTRATION_PENDING_APPROVAL],
  },
];

/** Users */
export const ROLE_PORTAL_ADMIN_UUID = '00000001-0001-0001-0001-000000000001';
export const ROLE_PORTAL_ADMIN = 'portaladministrators';
export const ROLE_API_OWNER_UUID = '00000004-0004-0004-0004-000000000004';
export const ROLE_API_OWNER = 'apiowners';
export const ROLE_ORG_ADMIN_UUID = '00000005-0005-0005-0005-000000000005';
export const ROLE_ORG_ADMIN = 'devorgadministrators';
export const ROLE_DEVELOPER_UUID = '00000006-0006-0006-0006-000000000006';
export const ROLE_DEVELOPER = 'developers';
export const ROLE_ORG_PUBLISHER_UUID = '00000009-0009-0009-0009-000000000009';
export const ROLE_ORG_PUBLISHER = 'orgpublishers';

export const USER_LIST_ROLE = {
  [ROLE_PORTAL_ADMIN_UUID]: `label.user.role.${ROLE_PORTAL_ADMIN}`,
  [ROLE_API_OWNER_UUID]: `label.user.role.${ROLE_API_OWNER}`,
  [ROLE_ORG_ADMIN_UUID]: `label.user.role.${ROLE_ORG_ADMIN}`,
  [ROLE_ORG_PUBLISHER_UUID]: `label.user.role.${ROLE_ORG_PUBLISHER}`,
  [ROLE_DEVELOPER_UUID]: `label.user.role.${ROLE_DEVELOPER}`,
};

export const USER_STATUS_ENABLED = 'ENABLED';
export const USER_STATUS_DISABLED = 'DISABLED';
export const USER_STATUS_LOCKED = 'LOCKED';
export const USER_STATUS_REGISTRATION_INIT = 'REGISTRATION_INIT';
export const USER_STATUS_REGISTRATION_PENDING_APPROVAL = 'REGISTRATION_PENDING_APPROVAL';

export const USER_STATUS_LABEL = {
  [USER_STATUS_ENABLED]: getI18nFormattedMessage('label.user.status.enabled'),
  [USER_STATUS_DISABLED]: getI18nFormattedMessage('label.user.status.disabled'),
  [USER_STATUS_LOCKED]: getI18nFormattedMessage('label.user.status.locked'),
  [USER_STATUS_REGISTRATION_INIT]: getI18nFormattedMessage('label.user.status.registration.init'),
  [USER_STATUS_REGISTRATION_PENDING_APPROVAL]: getI18nFormattedMessage('label.user.status.registration.pending.approval'),
};

export const USER_LIST_STATUS_FILTER_BY = [
  {
    uuid: ALL,
    text: getI18nFormattedMessage('label.user.list.page.status.by.all.statuses'),
  }, {
    uuid: USER_STATUS_ENABLED,
    text: USER_STATUS_LABEL[USER_STATUS_ENABLED],
  }, {
    uuid: USER_STATUS_DISABLED,
    text: USER_STATUS_LABEL[USER_STATUS_DISABLED],
  }, {
    uuid: USER_STATUS_LOCKED,
    text: USER_STATUS_LABEL[USER_STATUS_LOCKED],
  }, {
    uuid: USER_STATUS_REGISTRATION_INIT,
    text: USER_STATUS_LABEL[USER_STATUS_REGISTRATION_INIT],
  }, {
    uuid: USER_STATUS_REGISTRATION_PENDING_APPROVAL,
    text: USER_STATUS_LABEL[USER_STATUS_REGISTRATION_PENDING_APPROVAL],
  },
];

export const USER_LIST_SORT_BY = [
  {
    uuid: 'username,ASC',
    text: getI18nFormattedMessage('label.sort.by.name.asc'),
  }, {
    uuid: 'username,DESC',
    text: getI18nFormattedMessage('label.sort.by.name.desc'),
  }, {
    uuid: 'status,ASC',
    text: getI18nFormattedMessage('label.sort.by.status.asc'),
  }, {
    uuid: 'status,DESC',
    text: getI18nFormattedMessage('label.sort.by.status.desc'),
  },
];

export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_ERROR = 'USERS_GET_ERROR';
export const USERS_DELETE_RESET = 'USERS_DELETE_RESET';

/** Proxies **/
export const PROXY_DEPLOYMENT_TYPE_AUTOMATIC = 'AUTOMATIC';
export const PROXY_DEPLOYMENT_TYPE_ON_DEMAND = 'ON_DEMAND';
export const PROXY_DEPLOYMENT_TYPE_SCRIPTED = 'MANUAL';

export const PROXY_GET_SUCCESS = 'PROXY_GET_SUCCESS';
export const PROXY_GET_ERROR = 'PROXY_GET_ERROR';
export const PROXY_DELETE_SUCCESS = 'PROXY_DELETE_SUCCESS';
export const PROXY_DELETE_ERROR = 'PROXY_DELETE_ERROR';
export const PROXY_DETAILS_GET_SUCCESS = 'PROXY_DETAILS_GET_SUCCESS';
export const PROXY_DETAILS_GET_ERROR = 'PROXY_DETAILS_GET_ERROR';
export const PROXY_GATEWAY_GET_REQUEST = 'PROXY_GATEWAY_GET_REQUEST';
export const PROXY_GATEWAY_GET_SUCCESS = 'PROXY_GATEWAY_GET_SUCCESS';
export const PROXY_GATEWAY_GET_ERROR = 'PROXY_GATEWAY_GET_ERROR';
export const PROXY_APIS_GET_REQUEST = 'PROXY_APIS_GET_REQUEST';
export const PROXY_APIS_GET_SUCCESS = 'PROXY_APIS_GET_SUCCESS';
export const PROXY_APIS_GET_ERROR = 'PROXY_APIS_GET_ERROR';
export const PROXY_ORGANIZATIONS_GET_SUCCESS = 'PROXY_ORGANIZATIONS_GET_SUCCESS';
export const PROXY_ORGANIZATIONS_GET_ERROR = 'PROXY_ORGANIZATIONS_GET_ERROR';
export const PROXY_CONFIG_UPDATE_NEW_SYNC_SUCCESS = 'PROXY_CONFIG_UPDATE_NEW_SYNC_SUCCESS';
export const PROXY_CONFIG_UPDATE_NEW_SYNC_ERROR = 'PROXY_CONFIG_UPDATE_NEW_SYNC_ERROR';
export const PROXY_APIS_LIST_GET_SUCCESS = 'PROXY_APIS_LIST_GET_SUCCESS';
export const PROXY_APIS_LIST_GET_ERROR = 'PROXY_APIS_LIST_GET_ERROR';
export const PROXY_APIS_KEYS_LIST_GET_SUCCESS = 'PROXY_APIS_KEYS_LIST_GET_SUCCESS';
export const PROXY_APIS_KEYS_LIST_GET_ERROR = 'PROXY_APIS_KEYS_LIST_GET_ERROR';
export const PROXY_APIS_KEYS_COUNT_GET_SUCCESS = 'PROXY_APIS_KEYS_COUNT_GET_SUCCESS';
export const PROXY_APIS_KEYS_COUNT_GET_ERROR = 'PROXY_APIS_KEYS_COUNT_GET_ERROR';
export const PROXY_API_PLANS_GET_REQUEST = 'PROXY_API_PLANS_GET_REQUEST';
export const PROXY_API_PLANS_GET_SUCCESS = 'PROXY_API_PLANS_GET_SUCCESS';
export const PROXY_API_PLANS_GET_ERROR = 'PROXY_API_PLANS_GET_ERROR';
export const PROXY_DEPLOYMENT_STATUS_GET_SUCCESS = 'PROXY_DEPLOYMENT_STATUS_GET_SUCCESS';
export const PROXY_DEPLOYMENT_STATUS_GET_ERROR = 'PROXY_DEPLOYMENT_STATUS_GET_ERROR';

export const PROXY_RATE_LIMIT_QUOTA_GET_SUCCESS = 'PROXY_RATE_LIMIT_QUOTA_GET_SUCCESS';
export const PROXY_RATE_LIMIT_QUOTA_GET_ERROR = 'PROXY_RATE_LIMIT_QUOTA_GET_ERROR';

export const PROXY_ENROLMENT_STATUS_ACTIVE = 'ACTIVE';
export const PROXY_ENROLMENT_STATUS_PENDING = 'PENDING';

export const PROXY_CONNECTED = 'PROXY_CONNECTED';
export const PROXY_DISCONNECTED = 'PROXY_DISCONNECTED';

export const BULK_DEPLOYMENT_STATUS_DONE = 'DONE';
export const BULK_DEPLOYMENT_STATUS_RUNNING = 'RUNNING';

export const PROXY_DEPLOYEMT_AUTOMATIC = 'AUTOMATIC';

export const PROXY_DETAILS_URL = '/publish/api-proxy/details/';

export const PROXY_CERTIFICATES_LIST_GET_SUCCESS = 'PROXY_CERTIFICATES_LIST_GET_SUCCESS';
export const PROXY_CERTIFICATES_LIST_GET_ERROR = 'PROXY_CERTIFICATES_LIST_GET_ERROR';
export const PROXY_ENTITIES_SUMMARY_GET_SUCCESS = 'PROXY_ENTITIES_SUMMARY_GET_SUCCESS';
export const PROXY_ENTITIES_SUMMARY_GET_ERROR = 'PROXY_ENTITIES_SUMMARY_GET_ERROR';

export const PROXY_ENROLLMENT_HELP_ITEMS = {
  title: 'Help',
  subItems: [{
    id: 0,
    title: '',
    descriptions: 'For more information, refer to Integrate On-Premise API Proxies > Enroll the On-Premise API Proxy Cluster step in the documentation.',
  }, {
    id: 1,
    title: '',
    descriptions: 'The enrollment URL is only valid for 24 hours. After 24 hours have elapsed, a new link must be generated by refreshing this page.',
  }, {
    id: 2,
    title: '',
    descriptions: 'Upon successful enrollment, you can view the Proxy details and manage the configurations of the proxy.',
  }],
};

export const PROXY_API_QUERY_PARAMS = {
  portalPublished: '?source=true',
  portalPending: '?source=true&status=PENDING_DEPLOYMENT',
  portalDeployed: '?source=true&status=DEPLOYED',
  portalError: '?source=true&status=ERROR',
  gatewayPublished: '?source=false',
  gatewayPending: '?source=false&status=PENDING_DEPLOYMENT',
  gatewayDeployed: '?source=false&status=DEPLOYED',
  gatewayError: '?source=false&status=ERROR',
  totalPublished: '',
  totalPending: '?status=PENDING_DEPLOYMENT',
  totalDeployed: '?status=DEPLOYED',
  totalError: '?status=ERROR',
};

export const PROXY_KEYS_QUERY_PARAMS = {
  totalPublished: '',
  totalPending: '?status=PENDING_DEPLOYMENT',
  totalDeployed: '?status=DEPLOYED',
  totalError: '?status=ERROR',
};

/** APIs */
export const APIS_GET_SUCCESS = 'APIS_GET_SUCCESS';
export const APIS_GET_ERROR = 'APIS_GET_ERROR';

export const APIS_SORT_BY = [
  {
    uuid: 'apiModifyTs,DESC',
    text: getI18nFormattedMessage('label.gateway.bundle.api.page.sort.by.date.modified.desc'),
  }, {
    uuid: 'apiModifyTs,ASC',
    text: getI18nFormattedMessage('label.gateway.bundle.api.page.sort.by.date.modified.asc'),
  }, {
    uuid: 'apiName,ASC',
    text: getI18nFormattedMessage('label.sort.by.api.name.asc'),
  }, {
    uuid: 'apiName,DESC',
    text: getI18nFormattedMessage('label.sort.by.api.name.desc'),
  },
];

export const APIS_STATES_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.apis.states.all'),
  }, {
    uuid: 'Enabled',
    text: getI18nFormattedMessage('label.apis.states.enabled'),
  }, {
    uuid: 'Disabled',
    text: getI18nFormattedMessage('label.apis.states.disabled'),
  }, {
    uuid: 'Deprecated',
    text: getI18nFormattedMessage('label.apis.states.deprecated'),
  }, {
    uuid: 'New',
    text: getI18nFormattedMessage('label.apis.states.unpublished'),
  },
];

export const PROXY_APIS_SORT_BY = [
  {
    uuid: 'lastTimeDeployed,DESC',
    text: getI18nFormattedMessage('label.apis.page.sort.by.last.deployed.desc'),
  }, {
    uuid: 'lastTimeDeployed,ASC',
    text: getI18nFormattedMessage('label.apis.page.sort.by.last.deployed.asc'),
  },
];

export const PROXY_APIS_STATES_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.apis.states.all'),
  }, {
    uuid: 'NEW',
    text: getI18nFormattedMessage('label.apis.states.unpublished'),
  },{
    uuid: 'ENABLED',
    text: getI18nFormattedMessage('label.apis.states.enabled'),
  }, {
    uuid: 'DISABLED',
    text: getI18nFormattedMessage('label.apis.states.disabled'),
  }, {
    uuid: 'DEPRECATED',
    text: getI18nFormattedMessage('label.apis.states.deprecated'),
  }, {
    uuid: 'DELETED',
    text: getI18nFormattedMessage('label.apis.states.deleted'),
  },
];

export const APIS_PUBLISH_SOURCES_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.apis.publish.source.all'),
  }, {
    uuid: true,
    text: getI18nFormattedMessage('label.apis.publish.source.portal'),
  }, {
    uuid: false,
    text: getI18nFormattedMessage('label.apis.publish.source.gateway'),
  },
];

export const PROXY_CERTIFICATES_SORT_BY = [
  {
    uuid: 'notAfter,DESC',
    text: getI18nFormattedMessage('label.proxy.details.certificates.sort.by.last.deployed.desc'),
  }, {
    uuid: 'notAfter,ASC',
    text: getI18nFormattedMessage('label.proxy.details.certificates.sort.by.last.deployed.asc'),
  },
];

export const APIS_DEPLOYMENT_STATUS_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.deployments.state.any'),
  }, {
    uuid: 'DEPLOYED',
    text: getI18nFormattedMessage('label.deployments.state.deployed'),
  }, {
    uuid: 'PENDING_DEPLOYMENT',
    text: getI18nFormattedMessage('label.deployments.state.pending.deployment'),
  }, {
    uuid: 'PENDING_UNDEPLOYMENT',
    text: getI18nFormattedMessage('label.deployments.state.pending.undeployment'),
  }, {
    uuid: 'ERROR',
    text: getI18nFormattedMessage('label.deployments.state.error'),
  },
];

export const PROXY_APIS_KEY_STATUS_FILTER_BY = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.apis.key.status.any'),
  }, {
    uuid: 'ENABLED',
    text: getI18nFormattedMessage('label.apis.states.enabled'),
  }, {
    uuid: 'DISABLED',
    text: getI18nFormattedMessage('label.apis.states.disabled'),
  },
];

export const CLOSE_TEXT_ID = 'label.close.button';
export const COPY_TEXT = getI18nFormattedMessage('label.copy.button');
export const COPY_CLOSE_TEXT = getI18nFormattedMessage('label.copy.close.button');
export const DIALOG_TITLE_ERROR = 'Error';

/** Applications **/
export const API_KEYS_COUNT_GET_REQUEST = 'API_KEYS_COUNT_GET_REQUEST';
export const API_KEYS_COUNT_GET_SUCCESS = 'API_KEYS_COUNT_GET_SUCCESS';
export const API_KEYS_COUNT_GET_ERROR = 'API_KEYS_COUNT_GET_ERROR';

/** Account Plans **/
export const ACCOUNT_PLANS_GET_SUCCESS = 'ACCOUNT_PLANS_GET_SUCCESS';
export const ACCOUNT_PLANS_GET_ERROR = 'ACCOUNT_PLANS_GET_ERROR';

/** API Plans **/
export const API_PLANS_GET_SUCCESS = 'API_PLANS_GET_SUCCESS';
export const API_PLANS_GET_ERROR = 'API_PLANS_GET_ERROR';

export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_SUCCESS = 200;

export const UNSAVED_DIALOG_TITLE = 'Unsaved Changes';
export const UNSAVED_DIALOG_DESCRIPTIONS = 'Your changes will be lost if you leave this page. Are you sure you want to continue away from this page?';
export const UNSAVED_DIALOG_CANCEL_TEXT = 'Stay';
export const UNSAVED_DIALOG_SUBMIT_TEXT = 'Continue';
export const UNSAVED_DIALOG_EXIT_TEXT = 'Exit';
export const PROCEED_TEXT = 'Proceed';
export const NEXT_TEXT = 'Next';
export const DONE_TEXT = 'Done';

export const GRID_ROWS_PER_PAGE_OPTIONS = [12, 24];
export const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 12;
export const GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24 = 24;
export const GRID_ROWS_PER_PAGE_EXTRA_OPTIONS = [12, 24, 48, 96];

export const VERSION_TEXT = 'Version';
export const CHANGE_ACTION_TEXT = 'Change';
export const ADD_APIPLAN_ACTION_TEXT = 'Add API Plan';

/** Eula **/

export const EULA_SORT_BY = [
  {
    uuid: 'apiModifyTs,DESC',
    text: getI18nFormattedMessage('label.sort.by.date.created.desc'),
  }, {
    uuid: 'apiModifyTs,ASC',
    text: getI18nFormattedMessage('label.sort.by.date.created.asc'),
  },
  {
    uuid: 'apiModifyTs,DESC',
    text: getI18nFormattedMessage('label.sort.by.date.updated.desc'),
  }, {
    uuid: 'apiModifyTs,ASC',
    text: getI18nFormattedMessage('label.sort.by.date.updated.asc'),
  }, {
    uuid: 'apiName,ASC',
    text: getI18nFormattedMessage('label.sort.by.name.asc'),
  }, {
    uuid: 'apiName,DESC',
    text: getI18nFormattedMessage('label.sort.by.name.desc'),
  },
];

export const EULAS_GET_LIST_SUCCESS = 'EULAS_GET_LIST_SUCCESS';
export const EULAS_GET_LIST_ERROR = 'EULAS_GET_LIST_ERROR';
export const EULAS_DETAILS_SUCCESS = 'EULAS_DETAILS_SUCCESS';
export const EULAS_DETAILS_ERROR = 'EULA_DETAILS_ERROR';
export const EULA_ADD_REQUEST = 'EULA_ADD_REQUEST';
export const EULA_SAVE_SUCCESS = 'EULA_SAVE_SUCCESS';
export const EULA_SAVE_ERROR = 'EULA_SAVE_ERROR';
export const EULA_DELETE_RESET = 'EULA_DELETE_RESET';
export const EULA_DELETE_SUCCESS = 'EULA_DELETE_SUCCESS';
export const EULA_DELETE_ERROR = 'EULA_DELETE_ERROR';
export const EULA_NAME_UNIQUE_SUCCESS = 'EULA_NAME_UNIQUE_SUCCESS';
export const EULA_NAME_UNIQUE_ERROR = 'EULA_NAME_UNIQUE_ERROR';
export const EULAS_APIS_LIST_SUCCESS = 'EULAS_APIS_LIST_SUCCESS';
export const EULAS_APIS_LIST_ERROR = 'EULA_APIS_LIST_ERROR';

/** Header Proxy status **/
export const HEADER_PROXY_STATUS_GET_SUCCESS = 'HEADER_PROXY_STATUS_GET_SUCCESS';
export const HEADER_PROXY_STATUS_GET_ERROR = 'HEADER_PROXY_STATUS_GET_ERROR';
export const PROXY_ERROR_DEFAULT_MESSAGE = 'Proxy status error';
export const PROXY_PORTAL_API_TYPE = 'PortalApiDeployment';
export const PROXY_KEY_TYPE = 'ApiKeyDeployment';
export const PROXY_GATEWAY_API_TYPE = 'GWPublishedApiDeployment';
export const PROXY_CERTIFICATE_EXPIRED_TYPE = 'EXPIRED';
export const PROXY_CERTIFICATE_EXPIRING_TYPE = 'EXPIRING';

/** Organizations */
export const ORG_TAG_POST_PUT_REQUEST = 'ORG_TAG_POST_PUT_REQUEST';
export const ORG_TAG_POST_PUT_SUCCESS = 'ORG_TAG_POST_PUT_SUCCESS';
export const ORG_TAG_POST_PUT_ERROR = 'ORG_TAG_POST_PUT_ERROR';
export const ORG_TAG_PUT_REQUEST = 'ORG_TAG_PUT_REQUEST';
export const ORG_TAG_PUT_SUCCESS = 'ORG_TAG_PUT_SUCCESS';
export const ORG_TAG_PUT_ERROR = 'ORG_TAG_PUT_ERROR';
export const ORG_RATE_QUOTA_GET_LIST_REQUEST = 'ORG_RATE_QUOTA_GET_LIST_REQUEST';
export const ORG_RATE_QUOTA_GET_LIST_SUCCESS = 'ORG_RATE_QUOTA_GET_LIST_SUCCESS';
export const ORG_RATE_QUOTA_GET_LIST_ERROR = 'ORG_RATE_QUOTA_GET_LIST_ERROR';
export const ORG_TAG_ADD = 'add';
export const ORG_TAG_REMOVE = 'remove';
export const ORG_PRODUCTS_LIST_GET_SUCCESS = 'ORG_PRODUCTS_LIST_GET_SUCCESS';
export const ORG_PRODUCTS_LIST_GET_ERROR = 'ORG_PRODUCTS_LIST_GET_ERROR';
export const ORG_PRODUCT_TIERS_GET_SUCCESS = 'ORG_PRODUCT_TIERS_GET_SUCCESS';
export const ORG_PRODUCT_TIERS_GET_ERROR = 'ORG_PRODUCT_TIERS_GET_ERROR';
export const ORGS_PRODUCTS_UPDATE_SUCCESS = 'ORGS_PRODUCTS_UPDATE_SUCCESS';
export const ORGS_PRODUCTS_UPDATE_ERROR = 'ORGS_PRODUCTS_UPDATE_ERROR';

/** Organization - APIAccess */
export const ORG_APIACCESS_GET_LIST_REQUEST = 'ORG_APIACCESS_GET_LIST_REQUEST';
export const ORG_APIACCESS_GET_LIST_SUCCESS = 'ORG_APIACCESS_GET_LIST_SUCCESS';
export const ORG_APIACCESS_GET_LIST_ERROR = 'ORG_APIACCESS_GET_LIST_ERROR';

/** Rate and Quota */
export const RATE_QUOTA_HELP_ITEMS = {
  title: getI18nFormattedMessage('label.rate.quota.edit.help.title'),
  descriptions: '',
  subItems: [
    {
      id: 0,
      title: '',
      descriptions: 'A limit requires 3 things:',
      list: {
        items: [
          '1 - A limit defined and assigned (varies by assignment level, see below).',
          '2 - The appropriate l7.apim.system - Rate Limit & Quota Policy Template assigned to the API during publishing (see version details per assignment level).',
          '3 - The appropriate gateway bundle deployed to the proxy where the API is to be deployed.',
        ],
      },
    },
    {
      id: 1,
      title: '',
      descriptions: 'Assignment Levels:',
      list: {
        items: [
          'API - for backend protection of your API (policy template 1.0 or higher required). No further assignment is necessary.',
          'Organization - limit per organization across all APIs (formerly known as Account Plan). When creating an Organization, assign the Rate Limit & Quota to the organization. A default limit is always assigned if no limit is explicitly defined. Note that no policy template is necessary.',
          'API per Organization - for setting limits that are applied to each API across all applications within an Organization (policy template 2.0 required). Assign this limit through the API or Organization detail pages. A default limit is always assigned if no limit is explicitly defined.',
        ],
      },
    },
    {
      id: 2,
      title: '',
      descriptions: 'Limit Options:',
      list: {
        items: [
          'Rate Limit - maximum requests per second.',
          'Spread Limit Window - when specified, this allows a burst of requests to spread over the window size in seconds. If limit exceeds, excess requests shall be throttled.',
          'Maximum Concurrency - requests cannot exceed the specified number of concurrent requests.',
          'Quota Interval - interval used for the quota.',
          'Quota - maximum number of requests per defined interval.',
        ],
      },
    },
  ],
};

export const RATE_QUOTA_HELP_ITEMS_WITH_PRODUCT = {
  title: getI18nFormattedMessage('label.rate.quota.edit.help.title'),
  descriptions: '',
  subItems: [
    {
      id: 0,
      title: '',
      descriptions: 'A limit requires 3 things:',
      list: {
        items: [
          '1 - A limit defined and assigned (varies by assignment level, see below).',
          '2 - The appropriate l7.apim.system - Rate Limit & Quota Policy Template assigned to the API during publishing (see version details per assignment level).',
          '3 - The appropriate gateway bundle deployed to the proxy where the API is to be deployed.',
        ],
      },
    },
    {
      id: 1,
      title: '',
      descriptions: 'Assignment Levels:',
      list: {
        items: [
          'API - for backend protection of your API (policy template 1.0 or higher required). No further assignment is necessary.',
          'Organization - limit per organization across all APIs (formerly known as Account Plan). When creating an Organization, assign the Rate Limit & Quota to the organization. A default limit is always assigned if no limit is explicitly defined. Note that no policy template is necessary.',
          'API per Organization - for setting limits that are applied to each API across all applications within an Organization (policy template 2.0 required). Assign this limit through the API or Organization detail pages. A default limit is always assigned if no limit is explicitly defined.',
          "Product per Application - the collective limit for all APIs assigned to a product. All keys for an application share this limit. Assignment of this limit is based the product tier. Policy template 2.0.2 or higher is required on all APIs included in the product.",
        ],
      },
    },
    {
      id: 2,
      title: '',
      descriptions: 'Limit Options:',
      list: {
        items: [
          'Rate Limit - maximum requests per second.',
          'Spread Limit Window - when specified, this allows a burst of requests to spread over the window size in seconds. If limit exceeds, excess requests shall be throttled.',
          'Maximum Concurrency - requests cannot exceed the specified number of concurrent requests.',
          'Quota Interval - interval used for the quota.',
          'Quota - maximum number of requests per defined interval.',
        ],
      },
    },
  ],
};

export const RATE_QUOTA_ASSIGNMENT_LEVEL = [
  {
    id: 'API',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.api'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.api.help'),
  },
  {
    id: 'ORGANIZATION',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.organization'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.organization.help'),
  },
  {
    id: 'API_ORGANIZATION',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.api.org'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.api.organization.help'),
  },
];

export const RATE_QUOTA_ASSIGNMENT_LEVEL_WITH_PRODUCT = [
  {
    id: 'API',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.api'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.api.help'),
  },
  {
    id: 'ORGANIZATION',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.organization'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.organization.help'),
  },
  {
    id: 'API_ORGANIZATION',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.api.org'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.api.organization.help'),
  },
  {
    id: 'PRODUCT_PER_APPLICATION',
    name: getI18nFormattedMessage('label.rate.quota.assignment.level.products.per.application'),
    description: getI18nFormattedMessage('label.rate.quota.assignment.level.products.per.application.help'),
  },
];

export const RATE_QUOTA_GET_LIST_SUCCESS = 'RATE_QUOTA_GET_LIST_SUCCESS';
export const RATE_QUOTA_GET_LIST_ERROR = 'RATE_QUOTA_GET_LIST_ERROR';
export const RATE_QUOTA_DETAILS_SUCCESS = 'RATE_QUOTA_DETAILS_SUCCESS';
export const RATE_QUOTA_DETAILS_ERROR = 'RATE_QUOTA_DETAILS_ERROR';
export const RATE_QUOTA_ADD_REQUEST = 'RATE_QUOTA_ADD_REQUEST';
export const RATE_QUOTA_SAVE_SUCCESS = 'RATE_QUOTA_SAVE_SUCCESS';
export const RATE_QUOTA_SAVE_ERROR = 'RATE_QUOTA_SAVE_ERROR';
export const RATE_QUOTA_DELETE_RESET = 'RATE_QUOTA_DELETE_RESET';
export const RATE_QUOTA_DELETE_SUCCESS = 'RATE_QUOTA_DELETE_SUCCESS';
export const RATE_QUOTA_DELETE_ERROR = 'RATE_QUOTA_DELETE_ERROR';
export const API_RATE_QUOTA_GET_LIST_SUCCESS = 'API_RATE_QUOTA_GET_LIST_SUCCESS';
export const API_RATE_QUOTA_SUCCESS = 'API_RATE_QUOTA_SUCCESS';
export const RATE_QUOTA_ORGANIZATION_LIST_GET_SUCCESS = 'RATE_QUOTA_ORGANIZATION_LIST_GET_SUCCESS';
export const RATE_QUOTA_ORGANIZATION_LIST_GET_ERROR = 'RATE_QUOTA_ORGANIZATION_LIST_GET_ERROR';
export const RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_SUCCESS = 'RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_SUCCESS';
export const RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_ERROR = 'RATE_QUOTA_APIS_ORGANIZATION_LIST_GET_ERROR';
export const RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_SUCCESS = 'RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_SUCCESS';
export const RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_ERROR = 'RATE_QUOTA_APIS_ORGANIZATION_DETAILS_GET_ERROR';

export const REQUESTS_GET_LIST_SUCCESS = 'REQUESTS_GET_LIST_SUCCESS';
export const REQUESTS_GET_LIST_ERROR = 'REQUESTS_GET_LIST_ERROR';
export const REQUEST_GET_DETAILS_SUCCESS = 'REQUEST_GET_DETAILS_SUCCESS';
export const REQUEST_GET_DETAILS_ERROR = 'REQUEST_GET_DETAILS_ERROR';
export const REQUESTS_GET_ORG_SUGGEST_SUCCESS = 'REQUESTS_GET_ORG_SUGGEST_SUCCESS';
export const REQUESTS_GET_ORG_SUGGEST_ERROR = 'REQUESTS_GET_ORG_SUGGEST_ERROR';
export const REQUESTS_PUT_SUBMIT_REQUEST = 'REQUESTS_PUT_SUBMIT_REQUEST';
export const REQUESTS_PUT_SUBMIT_SUCCESS = 'REQUESTS_PUT_SUBMIT_SUCCESS';
export const REQUESTS_PUT_SUBMIT_ERROR = 'REQUESTS_PUT_SUBMIT_ERROR';

export const APPLICATION_REVIEWS_PUT_REQUEST = 'APPLICATION_REVIEWS_PUT_REQUEST';
export const APPLICATION_REVIEWS_PUT_SUCCESS = 'APPLICATION_REVIEWS_PUT_SUCCESS';
export const APPLICATION_REVIEWS_PUT_ERROR = 'APPLICATION_REVIEWS_PUT_ERROR';

export const REQUEST_LIST_TYPES = [
  {
    uuid: 'ALL',
    text: getI18nFormattedMessage('label.request.list.request.type.all'),
  },
  {
    uuid: 'REGISTRATION',
    text: getI18nFormattedMessage('label.request.list.request.type.registration'),
  },
  {
    uuid: 'APPLICATION',
    text: getI18nFormattedMessage('label.request.list.request.type.application.filter'),
  },
  {
    uuid: 'APPLICATION_DETAILS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.details'),
  },
  {
    uuid: 'APPLICATION_APIS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.apis'),
  },
  {
    uuid: 'APPLICATION_API_GROUPS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.api.groups'),
  },
  {
    uuid: 'APPLICATION_CUSTOM_FIELDS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.custom.fields'),
  },
  {
    uuid: 'APPLICATION_API_KEYS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.api.keys'),
  },
  {
    uuid: 'APPLICATION_API_PLANS',
    text: getI18nFormattedMessage('label.request.list.request.type.application.api.plans'),
  },
];

export const QUOTA_INTERVAL_HOUR = 'HOUR';
export const QUOTA_INTERVAL_DAY = 'DAY';
export const QUOTA_INTERVAL_MONTH = 'MONTH';
export const QUOTA_ASSIGNMENT_API = 'API';
export const QUOTA_ASSIGNMENT_ORG = 'ORGANIZATION';
export const QUOTA_ASSIGNMENT_API_ORG = 'API_ORGANIZATION';
export const QUOTA_ASSIGNMENT_API_ORG_TEXT = 'API per Organization';
export const QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION = 'PRODUCT_PER_APPLICATION';
export const QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION_TEXT = 'Product per Application';

export const QUOTAS_INTERVAL_API = [
  {
    uuid: QUOTA_INTERVAL_HOUR,
    text: getI18nFormattedMessage('label.rate.quota.per.hourly'),
  }, {
    uuid: QUOTA_INTERVAL_DAY,
    text: getI18nFormattedMessage('label.rate.quota.per.daily'),
  },
  {
    uuid: QUOTA_INTERVAL_MONTH,
    text: getI18nFormattedMessage('label.rate.quota.per.monthly'),
  },
];

export const QUOTAS_INTERVAL_ORG = [
  {
    uuid: QUOTA_INTERVAL_DAY,
    text: getI18nFormattedMessage('label.rate.quota.per.daily'),
  },
  {
    uuid: QUOTA_INTERVAL_MONTH,
    text: getI18nFormattedMessage('label.rate.quota.per.monthly'),
  },
];

export const QUOTAS_INTERVAL_PRODUCT_PER_APPLICATION = [
  {
    uuid: QUOTA_INTERVAL_HOUR,
    text: getI18nFormattedMessage('label.rate.quota.per.hourly'),
  }, {
    uuid: QUOTA_INTERVAL_DAY,
    text: getI18nFormattedMessage('label.rate.quota.per.daily'),
  },
  {
    uuid: QUOTA_INTERVAL_MONTH,
    text: getI18nFormattedMessage('label.rate.quota.per.monthly'),
  },
];

export const RATE_QUOTAS_LIST_SORT_BY = [
  {
    uuid: 'name,ASC',
    text: getI18nFormattedMessage('label.sort.by.name.asc'),
  }, {
    uuid: 'name,DESC',
    text: getI18nFormattedMessage('label.sort.by.name.desc'),
  },
];

export const RATE_QUOTAS_LIST_LEVELS = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.rate.quota.all.levels'),
  }, {
    uuid: 'API',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.api'),
  }, {
    uuid: 'ORGANIZATION',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.organization'),
  },
  {
    uuid: 'API_ORGANIZATION',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.api.org'),
  },
];

export const RATE_QUOTAS_LIST_LEVELS_WITH_PRODUCTS = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.rate.quota.all.levels'),
  }, {
    uuid: 'API',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.api'),
  }, {
    uuid: 'ORGANIZATION',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.organization'),
  },
  {
    uuid: 'API_ORGANIZATION',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.api.org'),
  },
  {
    uuid: 'PRODUCT_PER_APPLICATION',
    text: getI18nFormattedMessage('label.rate.quota.assignment.level.products.per.application'),
  },
];

export const RATE_QUOTAS_LIST_LIMITS = [
  {
    uuid: 'all',
    text: getI18nFormattedMessage('label.rate.quota.all.limits'),
  },
  {
    uuid: 'rate',
    text: getI18nFormattedMessage('label.rate.quota.rate.limits'),
  },
  {
    uuid: 'quota',
    text: getI18nFormattedMessage('label.rate.quota.quota.limits'),
  },
];
export const RATE_LIMIT_QUOTA_DEFAULT_FIELD = 'l7.rateQuotaUuid';
export const RATE_LIMIT_QUOTA_LIST_FIELD = 'rateLimitQuota';
export const SYSTEM_TEMPLATE_RATE_LIMIT_QUOTA = 'l7.rq.apiOrg.enabled';

export const REQUEST_SETTINGS_STATUS_ENABLED = "ENABLED";
export const REQUEST_SETTINGS_STATUS_DISABLED = "DISABLED"

export const TIERS_GET_SUCCESS ='TIERS_GET_SUCCESS';
export const TIERS_GET_ERROR ='TIERS_GET_ERROR';

/** Products */
export const PRODUCTS_ADD_HELP_ITEMS = {
  title: getI18nFormattedMessage('label.product.introduction'),
  descriptions: '',
  subItems: [
    {
      id: 0,
      title: 'Products and API Access',
      descriptions: '',
      list: {
        items: [
          getI18nFormattedMessage('label.product.overview.helptext.api.item1'),
          getI18nFormattedMessage('label.product.overview.helptext.api.item2'),
          getI18nFormattedMessage('label.product.overview.helptext.api.item3'),
          getI18nFormattedMessage('label.product.overview.helptext.api.item4'),
          getI18nFormattedMessage('label.product.overview.helptext.api.item5'),
        ],
      },
    },
    {
      id: 1,
      title: 'Product Tiers and Rate Limit & Quota',
      descriptions: '',
      list: {
        items: [
          getI18nFormattedMessage('label.product.overview.helptext.tier.item1'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item2'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item3'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item4'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item5'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item6'),
          getI18nFormattedMessage('label.product.overview.helptext.tier.item7'),
        ],
      },
    },
  ],
};

export const PRODUCTS_SAVE_SUCCESS = 'PRODUCTS_SAVE_SUCCESS';
export const PRODUCTS_SAVE_ERROR = 'PRODUCTS_SAVE_ERROR';
export const PRODUCTS_DETAILS_GET_SUCCESS = 'PRODUCTS_DETAILS_GET_SUCCESS';
export const PRODUCTS_DETAILS_GET_ERROR = 'PRODUCTS_DETAILS_GET_ERROR';
export const PRODUCTS_APIS_GET_LIST_SUCCESS = 'PRODUCTS_APIS_GET_LIST_SUCCESS';
export const PRODUCTS_APIS_GET_LIST_ERROR = 'PRODUCTS_APIS_GET_LIST_ERROR';
export const PRODUCTS_APIS_ADD_REQUEST = 'PRODUCTS_APIS_ADD_REQUEST';
export const PRODUCTS_APIS_ADD_SUCCESS = 'PRODUCTS_APIS_ADD_SUCCESS';
export const PRODUCTS_APIS_UPDATE_ERROR = 'PRODUCTS_APIS_UPDATE_ERROR';
export const PRODUCTS_APIS_REMOVE_SUCCESS = 'PRODUCTS_APIS_REMOVE_SUCCESS';
export const PRODUCTS_ORGS_GET_LIST_SUCCESS ='PRODUCTS_ORGS_GET_LIST_SUCCESS';
export const PRODUCTS_ORGS_GET_LIST_ERROR = 'PRODUCTS_ORGS_GET_LIST_ERROR';
export const PRODUCTS_ORGS_ADD_SUCCESS = 'PRODUCTS_ORGS_ADD_SUCCESS';
export const PRODUCTS_ORGS_REMOVE_SUCCESS = 'PRODUCTS_ORGS_ADD_SUCCESS';
export const PRODUCTS_ORGS_UPDATE_ERROR = 'PRODUCTS_ORGS_UPDATE_ERROR';
export const PRODUCTS_ORGS_RESET_LIST = 'PRODUCTS_ORGS_RESET_LIST';
export const TIERS_PRODUCT_GET_REQUEST = 'TIERS_PRODUCT_GET_REQUEST';
export const TIERS_PRODUCT_GET_SUCCESS = 'TIERS_PRODUCT_GET_SUCCESS';
export const TIERS_PRODUCT_GET_ERROR = 'TIERS_PRODUCT_GET_ERROR';
export const TIERS_PRODUCT_SAVE_SUCCESS = 'TIERS_PRODUCT_SAVE_SUCCESS';
export const TIERS_PRODUCT_SAVE_ERROR = 'TIERS_PRODUCT_SAVE_ERROR';
export const TIERS_PRODUCT_ASSIGNED = 'ASSIGNED';
export const TIERS_PRODUCT_NOT_ASSIGNED = 'NOT_ASSIGNED';
export const PRODUCTS_TIERS_PRODUCT_RESET_LIST = 'PRODUCTS_TIERS_PRODUCT_RESET_LIST';
export const PRODUCTS_TIERS_SUCCESS = 'PRODUCT_TIERS_SUCCESS';
export const PRODUCTS_TIERS_ERROR = 'PRODUCT_TIERS_ERROR';
export const PRODUCTS_TIERS_SAVE_SUCCESS = 'PRODUCTS_TIERS_SAVE_SUCCESS';
export const PRODUCTS_TIERS_SAVE_ERROR = 'PRODUCTS_TIERS_SAVE_ERROR';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';
export const ORG_PRODUCTS_GET_REQUEST = 'ORG_PRODUCTS_GET_REQUEST';
export const ORG_PRODUCTS_GET_SUCCESS = 'ORG_PRODUCTS_GET_SUCCESS';
export const ORG_PRODUCTS_GET_ERROR = 'ORG_PRODUCTS_GET_ERROR';
