import { get } from 'lodash';
import { modifyAlpha } from '../../../../utils/modifyColour';

export const styles = ({ color, margin, padding, typography }) => ({
  addBtn: {
    float: 'right',
    marginTop: 22,
  },
  checkIcon: {
    color: get(color, 'primaryButtonBackground'),
  },
  controlsContainer: {
    display: 'flex',
  },
  nameFieldContainer: {
    width: 320,
  },
  pageBodyClass: {
    paddingTop: 0,
  },
  pageFilterAndSortClass: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    paddingTop: 0,
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: modifyAlpha(color.primaryButtonBackground, 0.2),
      "& > .MuiTableCell-root": {
        backgroundColor: 'transparent',
        verticalAlign: 'middle',
      },
    },
    "& > .MuiTableCell-root": {
      verticalAlign: 'middle',
    },
  },
  tooltipContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
  },
  tsTierName: {
    color: get(color, 'primaryButtonBackground'),
    fontFamily: get(typography, 'buttonText'),
  },
  typeFieldContainer: {
    marginLeft: 4,
    width: 320,
  },
  warningIcon: {
    color: get(color, 'warning'),
    marginLeft: get(margin, 'm10'),
    marginRight: get(margin, 'm4'),
    fontSize: 14,
    marginTop: get(margin, 'm4'),
  },
});

export default styles;