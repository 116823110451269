import get from 'lodash/get';

const styles = ({ color }) => ({
    notificationContainer: {
      minWidth: '200px !important',
      width: 'auto !important',
      left: '35% !important',
      backgroundColor: get(color, 'white'),
      color: get(color, 'black'),
      fontWeight: 800,
    },
  });

  export default styles;
