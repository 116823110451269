import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { Footer } from '../../../../components';
import { fetchDeploymentRefreshTime } from '../../../../actions/portalConfig';
import { getDeploymentRefreshTime } from '../../../../reducers/portalConfig';
import {
  fetchDeploymentProxyList,
  fetchDeploymentByApi,
} from '../../../../actions/api';
import {
  getProxyListResults,
  getApiDeploymentList,
  getProxyListErrors,
  getProxyErrors,
} from '../../../../reducers/api';
import styles from './styles';
import { getI18n } from '../../../../utils/intl';
import { ALERT_ERROR } from '../../../../constants';
import ListContainer from '../../../list';
import getDateTime from '../../../../utils/datetime';
import { getDeploymentsListColumns } from './columns';

export const getDeploymentList = (deployments, apiDeployments) => {
  const combinedList = [];
  deployments.map((deployment) => {
    const matchedDeployment = apiDeployments.find(
      (apiDeployment) => apiDeployment.proxyUuid === deployment.uuid,
    );
    if (matchedDeployment) {
      combinedList.push(Object.assign({}, matchedDeployment, deployment));
    } else {
      combinedList.push(Object.assign({}, deployment));
    }
    return true;
  });
  return combinedList;
};
export const GRID_ROWS_PER_PAGE_OPTIONS = [24, 48, 72, 96];
export const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 24;

export const DeploymentsRaw = (props) => {
  const {
    apiUuid,
    userContext,
    canEdit,
    apiDetails,
    deploymentProxyList = [],
    apiDeployments = [],
    refreshTime,
    fetchDeploymentProxyList,
    fetchDeploymentByApi,
    fetchDeploymentRefreshTime,
    deploymentErrors = [],
    listProxyErrors = [],
    notificationStatus,
    setNotificationStatus,
    notificationMessage,
    setNotificationMessage,
    classes,
    currentTab,
  } = props;

  const intl = getI18n(useIntl());
  const [deploymentList, setDeploymentList] = useState([]);
  const [updatingProxies, setUpdatingProxies] = useState([]);

  useEffect(() => {
    setDeploymentList(getDeploymentList(deploymentProxyList, apiDeployments));
  }, [deploymentProxyList, apiDeployments]);

  useEffect(() => {
    if (apiUuid) {
      fetchDeploymentProxyList(apiUuid);
      fetchDeploymentByApi(apiUuid);
      fetchDeploymentRefreshTime();
    }
  }, [apiUuid]);

  useEffect(() => {
    if (currentTab === 'api-details-overview-tab') {
      if (listProxyErrors.length > 0) {
        setNotificationStatus(ALERT_ERROR);
        setNotificationMessage(
          intl.getI18nMessage('error.apis.proxy.list.fetch'),
        );
      } else {
        setNotificationStatus('');
        setNotificationMessage('');
      }
    }
  }, [listProxyErrors]);

  return (
    <div
      id="api-details-deployments-container"
      data-apim-test={'api-details-deployments-container'}
      className={classes.deploymentsContainer}
    >
      <ListContainer
        listPageId="api-details-deployments"
        isLoading={false}
        pageBodyClass={classes.listContainer}
        notificationId="api-details-deployments-notifications"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        onAdd={false}
        showListHeader={false}
        pageFilterAndSortClass={classes.filterSection}
        columns={getDeploymentsListColumns(
          intl,
          getDateTime,
          updatingProxies,
          setUpdatingProxies,
          userContext,
          canEdit,
          apiDetails,
          apiUuid,
          fetchDeploymentByApi,
          deploymentErrors,
          refreshTime,
          classes,
        )}
        rows={deploymentList}
        noResultsMessage={intl.getI18nMessage(
          'label.api.details.deployments.no.proxy.organizations',
        )}
        hasPaginationDisabled={true}
      />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  deploymentProxyList: getProxyListResults(state),
  apiDeployments: getApiDeploymentList(state),
  refreshTime: getDeploymentRefreshTime(state),
  deploymentErrors: getProxyErrors(state),
  listProxyErrors: getProxyListErrors(state),
});

const mapDispatchToProps = {
  fetchDeploymentProxyList,
  fetchDeploymentByApi,
  fetchDeploymentRefreshTime,
};

DeploymentsRaw.propTypes = {
  apiUuid: PropTypes.string,
  apiDetails: PropTypes.object,
  userContext: PropTypes.object,
  canEdit: PropTypes.bool,
  deploymentProxyList: PropTypes.arrayOf(PropTypes.object),
  apiDeployments: PropTypes.arrayOf(PropTypes.object),
  deploymentErrors: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  currentTab: PropTypes.string,
  listProxyErrors: PropTypes.arrayOf(PropTypes.object),
  fetchDeploymentProxyList: PropTypes.func,
  fetchDeploymentRefreshTime: PropTypes.func,
  fetchDeploymentByApi: PropTypes.func,
  refreshTime: PropTypes.func,
  notificationStatus: PropTypes.string,
  setNotificationStatus: PropTypes.func,
  notificationMessage: PropTypes.string,
  setNotificationMessage: PropTypes.func,
};
DeploymentsRaw.displayName = 'Deployments';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DeploymentsRaw);
