import React from 'react';
import classNames from 'classnames';
import { makeStyles, FormHelperText, FormLabel } from '@material-ui/core';
import { get } from 'lodash';
import { func, string, object, bool } from 'prop-types';

import { MarkdownEditor } from '.';

export const MarkdownInput = ({
    options = {},
    name,
    helperText,
    formClassName,
    onChange,
    value,
    error,
    className,
    id = 'markdown-editor',
    ...rest
}) => {
    const classes = useStyles(rest);

    return (
        <div
            className={classNames(formClassName, className)}
            {...rest}
            id={id}
            data-apim-test={id}
        >
            <div className={classes.editorContainer}>
                <div className={classes.labelContainer}>
                    <FormLabel>{name}</FormLabel>
                </div>
                <div className={error ? classes.errorEditor : classes.editor }>
                    <MarkdownEditor
                        value={value}
                        options={options}
                        onChange={onChange}
                    />
                    <FormHelperText className={classes.helpTextField} error={error} variant="filled" margin="dense">
                        {helperText}
                    </FormHelperText>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        editorContainer: {
            margin: '0px',
        },
        errorEditor: {
            '& .md-editor': {
                border: `1px solid ${get(theme, 'color.error')}`,
            },
        },
        editor: {
            '& .md-editor': {
                border: `1px solid ${get(theme, 'color.gray')}`,
            },
        },
        labelContainer: {
            display: 'flex',
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    }),
);

MarkdownInput.propTypes = {
    options: object,
    name: string,
    helperText: string,
    formClassName: string,
    onChange: func,
    value: string,
    error: bool,
    className: object,
    id : string,
};