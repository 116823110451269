import get from 'lodash/get';

const styles = ({ fontSize }) => ({
  marginPanelContainer: {
    minHeight: '800px',
    padding: '0 36px',
  },
  detailsContainer: {
    padding: '20px 36px',
  },
  paper: {
    paddingRight: '20px',
    marginTop: '20px',
  },
  hideFooterContainer: {
    display: 'none',
  },
  showFooterContainer: {
    display: 'visible',
  },
  title: {
    fontSize: `${get(fontSize, 'smallTitle')} !important`,
    marginTop: 0,
  },
});

export default styles;
