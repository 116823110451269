import { get } from 'lodash';

const styles = (theme) => ({
  dialogContainer: {
    minWidth: 400,
    minHeight: 200,
    backgroundColor: get(theme, 'color.uiBackground'),
  },
  title: {
    margin: 0,
  },
  cancelButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonBackground'),
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
    borderRadius: 3,
  },
  submitButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonText'),
    backgroundColor: get(theme, 'color.primaryButtonBackground'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
  },
  defaultButton: {
    float: 'left',
  },
  dialogTitle: {
    display: 'inline-flex',
  },
  closeButton: {
    height: 12,
    position: 'absolute',
    right: 4,
    top: 20,
    color: get(theme, 'color.borderColor'),
    fontSize: 16,
  },
  assistText: {
    color: get(theme, 'color.bodyText'),
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
  },
});

export default styles;
