import React, { useEffect, useState } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import GridPanelCard from '../GridPanelCard';
import theme from './styles';
import { buildQuery } from '../analytics_utils/customMetrics';
import {
  API_ENTITY,
  ORGANIZATION_ENTITY,
  PROXY_ENTITY,
  APPLICATION_ENTITY,
} from '../labels';


export default function GridPanel(props) {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const { fetchAvailableApis, apis } = props;
  const { fetchAllOrgs, allOrgs } = props;
  const { fetchAvailableApps, apps } = props;
  const { fetchAllProxies, allProxies } = props;
  const { fetchDashboardChartData, chartData, isApiOwnerOrOrgPub } = props;
  const { analytics } = props;
  const [isLoading, setLoading] = useState(true);

  const getchartQueryParams = (entityId) => ({
    metrics: { type: 'hits', aggregation: 'count' },
    timeRange: { type: 'period', period: '30D', aggregation: 'day' },
    dimensions: { groupBy: [{ dimension: entityId, type: 'top', limit: 24 }] },
  });

  const entityNameMap = {
    api: 'apiName',
    org: 'orgName',
    proxy: 'gatewayServerName',
    app: 'appName',
  };

  const entityIdMap = {
    api: 'apiId',
    org: 'orgId',
    proxy: 'gatewayServerId',
    app: 'appId',
  };

  const entityDataMap = {
    api: apis,
    org: allOrgs,
    proxy: allProxies,
    app: apps,
  };


  useEffect(() => {
    if (analytics) {
      const query = buildQuery(getchartQueryParams(entityIdMap[props.chartEntity]));
      fetchDashboardChartData(query, props.chartEntity).then(() => setLoading(false));
    } else {
      if (props.chartEntity === API_ENTITY) {
        fetchAvailableApis().then(() => setLoading(false));
      }
      if (props.chartEntity === ORGANIZATION_ENTITY) {
        fetchAllOrgs().then(() => setLoading(false));
      }
      if (props.chartEntity === APPLICATION_ENTITY) {
        fetchAvailableApps().then(() => setLoading(false));
      }
      if (props.chartEntity === PROXY_ENTITY) {
        fetchAllProxies().then(() => setLoading(false));
      }
    }
  }, []);


  const getEntityURL = uuid => {
    let entityURL = '';
    if (props.chartEntity === API_ENTITY) {
      entityURL = `https://${window.location.hostname}/publish/apis/details/${uuid}`;
    }
    if (props.chartEntity === ORGANIZATION_ENTITY) {
      entityURL = `https://${window.location.hostname}/admin/console/organizations/details/${uuid}`;
    }
    if (props.chartEntity === APPLICATION_ENTITY) {
      entityURL = `https://${window.location.hostname}/admin/console/applications/details/${uuid}`;
    }
    if (props.chartEntity === PROXY_ENTITY) {
      entityURL = `https://${window.location.hostname}/publish/api-proxy/details/${uuid}`;
    }
    return entityURL;
  };

  const showGridPanelCardWithoutAnalytics = name =>
    (<GridPanelCard
      cardEntity={name.Name || name.name}
      cardEntityUUID={name.Uuid || name.uuid}
      key={name.Uuid || name.uuid}
      url={getEntityURL(name.Uuid || name.uuid)}
      isApiOwnerOrOrgPub={props.isApiOwnerOrOrgPub}
      chartEntity={props.chartEntity}
    />);

  const showGridPanelCardWithAnalytics = (name, entity) =>
    (
      <GridPanelCard
        cardEntity={name[entityNameMap[entity]]}
        cardEntityUUID={name[entityIdMap[entity]]}
        key={name[entityIdMap[entity]]}
        url={getEntityURL(name[entityIdMap[entity]])}
        count={name.count}
        isApiOwnerOrOrgPub={isApiOwnerOrOrgPub}
        chartEntity={props.chartEntity}
      />
    );

  return (
    <div className={classes.panel}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
        ) :
       ((!analytics && entityDataMap[props.chartEntity] &&
        // limiting the number of cards which could be shown to 24
        entityDataMap[props.chartEntity].slice(0, 24).map((name) => (
          showGridPanelCardWithoutAnalytics(name)
        ))) ||
        (chartData[props.chartEntity] &&
        chartData[props.chartEntity].data.map((name) => (
          showGridPanelCardWithAnalytics(name, props.chartEntity)
        ))))
      }
    </div>
  );
}

GridPanel.propTypes = {
  apis: PropTypes.arrayOf(PropTypes.shape({})),
  fetchAvailableApis: PropTypes.func,
  allOrgs: PropTypes.arrayOf(PropTypes.shape({})),
  fetchAllOrgs: PropTypes.func,
  apps: PropTypes.arrayOf(PropTypes.shape({})),
  fetchAvailableApps: PropTypes.func,
  allProxies: PropTypes.arrayOf(PropTypes.shape({})),
  fetchAllProxies: PropTypes.func,
  chartEntity: PropTypes.string,
  chartData: PropTypes.shape({}),
  fetchDashboardChartData: PropTypes.func,
  analytics: PropTypes.bool,
  isApiOwnerOrOrgPub: PropTypes.bool,
};
