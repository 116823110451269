import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { capitalCase, sentenceCase } from 'change-case';
import { withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { TablePagination } from '../../../../components';

import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';

import {
  KEY_NAME_LABEL,
  STATUS_LABEL,
  KEY_STATUS_ENABLED,
  KEY_STATUS_DELETE_FAILED,
} from '../labels';

import styles from './styles';

const displayItem = (applicationDetails, classes, item) => {
  const value = get(applicationDetails, item.key) || '';
  return (
    <div key={item.id} id={item.id} className={classes.leftPanelItemContainer}>
      <Typography className={classes.leftPanelTitle}>{item.label}</Typography>
      {item.type === 'link'
        ? <Link href={value}>{value}</Link>
        : <Typography className={classes.leftPanelDesc}>
          {item.useSentenceCase ? sentenceCase(value) : value}
        </Typography>
      }
    </div>
  );
};

const items = [
  {
    id: 'app-client-id',
    key: 'apiKey',
    label: getI18nFormattedMessage('label.application.key'),
  },
  {
    id: 'app-shared-secret',
    key: 'keySecret',
    label: getI18nFormattedMessage('label.application.key.shared.secret'),
  },
  {
    id: 'app-status',
    key: 'status',
    label: 'Status',
    useSentenceCase: true,
  },
  {
    id: 'app-oauth-callback-url',
    key: 'oauthCallbackUrl',
    label: 'OAuth Callback URL(s)',
    type: 'link',
  },
  {
    id: 'app-oauth-scope',
    key: 'oauthScope',
    label: 'OAuth Scope',
  },
  {
    id: 'app-oauth-type',
    key: 'oauthType',
    label: 'OAuth Type',
    useSentenceCase: true,
  },
];

const SingleKeyView = ({ keyDetails, classes }) => (
  <Grid container>
    <Grid item xs={8} className={classes.leftPanel}>
      {items.map((item) => displayItem(keyDetails, classes, item))}
    </Grid>
  </Grid>
);

SingleKeyView.propTypes = {
  keyDetails: PropTypes.object,
  classes: PropTypes.object,
};

const SingleRow = ({ row, idx, classes }) => {
  const [open, setOpen] = useState(idx === 0);
  return (
    <Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {row.name}
          {get(row, 'defaultKey') &&
            <Chip
              className={classes.defaultChip}
              size="small"
              label={'Default'}
            />
          }
        </TableCell>
        <TableCell component="th" scope="row" align={'center'}>
          <Chip
            className={get(row, 'status') === KEY_STATUS_ENABLED
              ? classes.enabledChip
              : classes.disabledChip
            }
            size="small"
            label={capitalCase(get(row, 'status'))}
          />
          {get(row, 'status') === KEY_STATUS_DELETE_FAILED &&
            <Tooltip
              arrow disableFocusListener disableTouchListener placement="top"
              title={getI18nFormattedMessage('label.application.key.delete.failed.status.tooltip')}
            >
              <span className={classes.helpIcon}>{getI18nFormattedMessage('label.more.info')}</span>
            </Tooltip>
          }
        </TableCell>
        <TableCell>
          <IconButton className={classes.expandCollapseButton} size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SingleKeyView
              keyDetails={row}
              classes={classes}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

SingleRow.propTypes = {
  row: PropTypes.number,
  idx: PropTypes.number,
  classes: PropTypes.object,
};

const GRID_ROWS_PER_PAGE_OPTIONS = [10, 20];
const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 10;

export const ConfigurationRaw = ({
  applicationUuid,
  applicationDetails,
  fetchApplicationKeys,
  appKeys,
  appKeysTotalPages,
  appKeysTotalElements,
  isMultiKeySupport,
  classes,
}) => {
  const intl = getI18n(useIntl());
  const rows = isMultiKeySupport ? appKeys : [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchApplicationKeys(applicationUuid, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchApplicationKeys(applicationUuid, 0, newRowsPerPage);
  };

  return (
    <div id="app-configuration-container" className={classes.appConfigurationContainer}>
      {isMultiKeySupport ?
        <Fragment>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>{KEY_NAME_LABEL}</TableCell>
                  <TableCell align={'center'}>{STATUS_LABEL}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) =>
                  <SingleRow key={idx} row={row} idx={idx} classes={classes} />)
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.paginationContainer}>
            <TablePagination
              id="app-configuration-keys-pagination"
              data-apim-test="app-configuration-keys-pagination"
              page={page}
              count={appKeysTotalElements}
              pageCount={appKeysTotalPages}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              onChangePreviousPage={onChangePreviousPage}
              onChangeNextPage={onChangeNextPage}
              labelRowsPerPage={intl.getI18nMessage('label.pagination.rows.per.page')}
            />
          </div>
        </Fragment> :
        <SingleKeyView
          keyDetails={applicationDetails}
          classes={classes}
        />
      }
    </div>
  );
};

ConfigurationRaw.propTypes = {
  applicationUuid: PropTypes.string,
  applicationDetails: PropTypes.object,
  fetchApplicationKeys: PropTypes.func,
  appKeys: PropTypes.arrayOf(PropTypes.object),
  appKeysTotalPages: PropTypes.number,
  appKeysTotalElements: PropTypes.number,
  isMultiKeySupport: PropTypes.bool,
  classes: PropTypes.object,
};
ConfigurationRaw.displayName = 'Configuration';

export default withStyles(styles)(ConfigurationRaw);
