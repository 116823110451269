import React from 'react';
import compose from 'recompose/compose';
import { Button, Link, Typography } from '@material-ui/core';
import { getI18nFormattedMessage } from '../../../../utils/intl';
import { SUCCESS, FAIL } from '../../../../constants';

import { arrayOf, func, object, string } from 'prop-types';
import moment from 'moment';
import SimpleTable from '../../../../components/Table/simpleTable';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { hasAdminRole } from '../../../../utils';
export const RequestSectionTable = (props) => {
  const {
    sectionType,
    columns,
    rows,
    requestInfo,
    classes,
    onReview,
    actionStatus,
    user,
  } = props;
  const getUserName = () => hasAdminRole(user) ?
  (<Link href={`/admin/users#edit/${requestInfo.userUuid}`}>
    {requestInfo.modifiedBy}
  </Link>) :
  (<span>
    {requestInfo.modifiedBy}
  </span>);
  return (
    <div
      id={`section-table-page-${sectionType}`} data-layer7-test={`section-table-page-${sectionType}`}
    >
        <div className={classes.tableHead}>
          {sectionType !== 'registration' &&
            <Typography variant="h4">
              {getI18nFormattedMessage(`label.request.details.section.title.${sectionType}`)}
            </Typography>
          }
          {requestInfo && sectionType !== 'status' && (!actionStatus || actionStatus.status !== SUCCESS) &&
          <div id="buttons" className={classes.buttonDiv}>
            <Button
              id="accept-button"
              data-apim-test="accept-button"
              variant="contained"
              color="secondary"
              className={classes.acceptButton}
              onClick={() => onReview('REQUEST', 'accept', requestInfo.uuid, sectionType)}
            >
              {getI18nFormattedMessage('label.application.details.section.accept.button')}
            </Button>
            <Button
              id="reject-button"
              data-apim-test="reject-button"
              className={classes.rejectButton}
              onClick={() => onReview('REQUEST', 'reject', requestInfo.uuid, sectionType)}
              >
              {getI18nFormattedMessage('label.application.details.section.reject.button')}
            </Button>
          </div>
        }
        </div>
        { actionStatus && actionStatus.status === FAIL &&
          <div className={classes.notificationDiv}>
            <CancelIcon className={classes.cancelIcon} />
            <span className={classes.error}>{getI18nFormattedMessage(`label.applicaiton.details.section.${actionStatus.actionType}.failed.notification`)}</span>
          </div>
        }
        { actionStatus && actionStatus.status === SUCCESS ?
        <div className={classes.notificationDiv}>
          <CheckCircleIcon className={classes.checkIcon}/>
          <div className={classes.notification}>
            <span>{getI18nFormattedMessage('label.applicaiton.details.section.action.request.text')}</span>
            <span className={classes.acceptedText}>
              {getI18nFormattedMessage(`label.applicaiton.details.section.action.${actionStatus.actionType}.notification`)}
            </span>
          </div>
        </div> :
        <>
          <SimpleTable
            id={`${sectionType}-list`}
            columns={columns}
            rows={rows}
          />
          <div className={classes.tableFooter}>
            {requestInfo && sectionType !== 'status' &&
              <Typography variant="body1">
                {getI18nFormattedMessage("label.request.details.requestdate.sub.title")}: {moment(requestInfo.modifyTs).format('ddd, MMM DD, YYYY HH:mm:ss ([GMT]Z)')} • {getI18nFormattedMessage("label.request.details.requestor.sub.title")}
                {getUserName()}
              </Typography>
            }
            {sectionType === "apikeys" &&
              <Typography variant="body1" className={classes.keyNote}>
                {getI18nFormattedMessage("label.request.details.keys.more.note")}
              </Typography>
            }
          </div>
        </>
        }
    </div>
  );
};
RequestSectionTable.propTypes = {
  sectionType: string,
  columns: object,
  rows: object,
  requestInfo: arrayOf(object),
  classes: object,
  onReview: func,
  actionStatus: string,
  user: object,
};

export default compose()(RequestSectionTable);
