import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
};
const initialState = {
  ...resetState,
  tiers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.TIERS_GET_SUCCESS:
      return {
        ...state,
        tiers: action.payload,
      };
    default:
      return state;
  }
}

export const getTiers = (state) => get(state, 'tier.tiers');
