import React from 'react';
import { makeStyles } from '@material-ui/core';
import { object, func, bool } from 'prop-types';
import styles from './styles';
import { getI18nFormattedMessage } from '../../../../../utils/intl';


export default function EntityCard({ entity, setActiveTab, disabled }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const entityTitles = {
    APIs: 'apis',
    APIGroups: 'apigroups',
    Keys: 'keys',
  };
  const entityClickTab = {
    APIs: 'app-apis-tab',
    APIGroups: 'app-api-groups-tab',
    Keys: 'app-key-overview-tab',
  }
  const handleCardClick = (event, entityName) => {
    if(!disabled) {
      setActiveTab(entityClickTab[entityName]);
    }
  };

  const handleEnterKey = (event, entityName) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleCardClick(event, entityName);
    }
  };
  return (
    <div
      tabIndex="0"
      className={disabled ? classes.disabledCard : classes.card}
      onClick={e => handleCardClick(e, entity.entity)}
      onKeyDown={e => handleEnterKey(e, entity.entity, true)}
    >
      <div className={disabled ? classes.disabledEntityTitle : classes.entityTitle}>
        {getI18nFormattedMessage(`label.application.details.overview.scrollbar.${entityTitles[entity.entity]}.title`)}
      </div>
      <div className={classes.entityCount}>{entity.count}</div>
    </div>
  );
}

EntityCard.propTypes = {
  entity: object,
  setActiveTab: func,
  disabled: bool,
};
