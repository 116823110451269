import React, { Fragment, useEffect, useState } from 'react';
import { bool, string, object, arrayOf, func } from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';
import { get } from 'lodash';
import {
  Button,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FormTextField from '../../../../components/FormTextField';
import {
  checkAppNameUnique,
  deleteSingleApplication,
} from '../../../../actions/application';
import {
  getDeleteApplicationStatus,
  getIsAppNameUnique,
  getErrors,
} from '../../../../reducers/application';
import styles from './styles';
import {
  APPLICATION_STATUS_ENABLED,
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  APPLICATION_STATUS_REJECTED,
  APPLICATION_STATUS_DELETE_PENDING_APPROVAL,
  KEY_ENTER,
  APPLICATION_STATUS_DISABLED,
  APPLICATION_STATUS_INCOMPLETE,
  APPLICATION_STATUS_DELETE_FAILED,
  ALERT_SUCCESS,
  ALERT_ERROR,
} from '../../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  AlertDialog,
  LoadingDialog,
 } from '../../../../components';
 import {
  FAIL,
  SUCCESS,
} from '../../../../constants';
import { ReportProblem } from '@material-ui/icons';
import { processErrors } from '../utils';

export const getStatusColor = (classes, status) => {
  let statusLabel = '';
  let statusColorClass = '';
  switch (status) {
    case APPLICATION_STATUS_ENABLED:
      statusLabel = 'enabled';
      statusColorClass = classes.available;
      break;
    case APPLICATION_STATUS_PENDING_APPROVAL:
      statusLabel = 'add.pending';
      statusColorClass = classes.pending;
      break;
    case APPLICATION_STATUS_EDIT_PENDING_APPROVAL:
      statusLabel = 'edit.pending';
      statusColorClass = classes.pending;
      break;
    case APPLICATION_STATUS_DELETE_PENDING_APPROVAL:
      statusLabel = 'delete.pending';
      statusColorClass = classes.pending;
      break;
    case APPLICATION_STATUS_REJECTED:
      statusLabel = 'rejected';
      statusColorClass = classes.unavailable;
      break;
    case APPLICATION_STATUS_INCOMPLETE:
      statusLabel = 'incomplete';
      statusColorClass = classes.unavailable;
      break;
    case APPLICATION_STATUS_DISABLED:
      statusLabel = 'disabled';
      statusColorClass = classes.unavailable;
      break;
    case APPLICATION_STATUS_DELETE_FAILED:
      statusLabel = 'delete.failed';
      statusColorClass = classes.unavailable;
      break;
    default:
      statusLabel = 'nostatus';
      statusColorClass = classes.noStatus;
  }
  statusLabel = `label.application.details.header.status.${statusLabel}`;
  return {
    statusLabel, statusColorClass,
  }
};

export const HeaderRaw = (props) => {
  const {
    applicationDetails,
    classes,
    isAddFlow,
    changeStatus,
    isAppNameUnique,
    setAppDetails,
    isSaveClicked,
    deleteAppStatus,
    errors,
    notifyMessages,
    validateNameUniqueness,
    isDeleteWorkflowEnabled,
    isAdmin,
    isOnlyDev,
    locked,
    isLocking,
    canEditName,
    isEditDisabled,
    isPortalAdmin,
  } = props;
  const intl = getI18n(useIntl());
  const { name, status } = applicationDetails;
  const { statusLabel, statusColorClass } = getStatusColor(classes, status);
  const [editingName, setEditingName] = useState(false);
  const [appName, setAppName] = useState('');
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isStatusChangeClicked, setIsStatusChangeClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [showProxyErrorAlert, setShowProxyErrorAlert] = useState(false);
  const onNameChange = (val) => {
    setAppName(val);
  };
  const isNameError = () => !appName || appName.length < 1 || appName.length > 50;
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const closeLoadingDialog = setShowLoadingDialog.bind(null, false);
  const appUuid = get(applicationDetails, 'uuid');
  useEffect(() => {
    if(isAddFlow) {
      setEditingName(true);
    }
  }, []);
  useEffect(() => {
    if(applicationDetails && applicationDetails.name) {
      setAppName(applicationDetails.name);
    }
  },[applicationDetails]);
  useEffect(() => {
    if(isAppNameUnique === false){
      setIsNameUnique(false);
      setEditingName(true);
    } else if(appName && isAppNameUnique === true) {
      setAppDetails({
        ...applicationDetails,
        name: appName,
      });
      setEditingName(false);
      setIsNameUnique(true);
    }
  },[isAppNameUnique]);
  const onNameKeyPress = (e) => {
    if (e.key === KEY_ENTER && appName !== applicationDetails.name) {
      setEditingName(false);
      validateName();
    } else if (e.key === 'Escape') {
      setAppName(name);
      setEditingName(false);
    }
  }
  const editName = () => {
    setEditingName(true);
  }

  const deleteApp = async () => {
    await props.deleteSingleApplication(appUuid);
    closeLoadingDialog();
  };
  const deleteAppIgnoringProxyErrors = async () => {
    setShowProxyErrorAlert(false);
    setShowLoadingDialog(true);
    await props.deleteSingleApplication(appUuid, true);
    closeLoadingDialog();
  };
  const forceDeleteApp = async () => {
    await props.deleteSingleApplication(appUuid, true, true);
    closeLoadingDialog();
  };
  const deleteWorkFlowEnabled =
            !isAdmin &&
            isDeleteWorkflowEnabled &&
            status !== APPLICATION_STATUS_INCOMPLETE &&
            status !== APPLICATION_STATUS_REJECTED;
  const validateName = () => {
    if(appName && appName.length > 0 && appName.length < 51) {
      const orgUuid = applicationDetails['organizationUuid'];
      if(!orgUuid && isAdmin) {
        setEditingName(false);
      } else {
        validateNameUniqueness(appName, applicationDetails['uuid'], orgUuid);
      }
      setAppDetails({
        ...applicationDetails,
        name: appName,
      });
    }
  }

  useEffect(() => {
    if (deleteAppStatus === SUCCESS) {
      if (!isAdmin && deleteWorkFlowEnabled && status !== APPLICATION_STATUS_INCOMPLETE) {
        notifyMessages(intl.getI18nMessage('label.application.request.submit.success'), ALERT_SUCCESS, true);
        props.fetchSingleApp(appUuid);
        props.setDeleteAndFetchApp(true);
        props.fetchAppRequestStatus(appUuid);
      } else {
        notifyMessages(intl.getI18nMessage('label.application.delete.success'), ALERT_SUCCESS, true);
        window.location.href = '/admin/console/applications';
      }
    } else if (deleteAppStatus === FAIL) {
      setIsDeleteClicked(false);
      const defaultErrorMessage = intl.getI18nFormattedMessage('label.application.request.submit.error');
      const errorMessage = processErrors(errors, defaultErrorMessage);
      if(errors.length > 0 && errors[0].field === 'proxyCheck' && status !== APPLICATION_STATUS_DELETE_FAILED) {
        setShowProxyErrorAlert(true);
      } else {
        props.fetchSingleApp(appUuid);
      }
      notifyMessages(errorMessage, ALERT_ERROR, true);
    }
}, [deleteAppStatus]);

  const applicationURL = `/admin/console/requests/details/${appUuid} `;
  let showReviewRequest = false;
  let reviewRequestMessage = '';
  if (status === APPLICATION_STATUS_PENDING_APPROVAL) {
    showReviewRequest = true;
    reviewRequestMessage = intl.getI18nMessage('label.application.details.header.review.create.request');
  } else if (status === APPLICATION_STATUS_EDIT_PENDING_APPROVAL) {
    showReviewRequest = true;
    reviewRequestMessage = intl.getI18nMessage('label.application.details.header.review.edit.request');
  } else if (status === APPLICATION_STATUS_DELETE_PENDING_APPROVAL) {
    showReviewRequest = true;
    reviewRequestMessage = intl.getI18nMessage('label.application.details.header.review.delete.request');
  }
  let deleteLabel = '';
  let deleteFn = () => {};
  if (deleteWorkFlowEnabled) {
    deleteLabel = intl.getI18nMessage('label.application.details.overview.delete.request.button.text');
    deleteFn = deleteApp;
  } else {
    if (status === APPLICATION_STATUS_DELETE_FAILED && isPortalAdmin) {
      deleteLabel = intl.getI18nMessage('label.application.details.overview.force.delete.button.text');
      deleteFn = forceDeleteApp;
    } else {
      deleteLabel = intl.getI18nMessage('label.application.details.overview.delete.button.text');
      deleteFn = deleteApp;
    }
  }
  const proxyAlertTitle = intl.getI18nMessage('label.application.details.proxy.alert.title');
  const proxyAlertDescription = intl.getI18nMessage('label.application.details.proxy.alert.description');
  const proxyAlertSubmitText = intl.getI18nMessage('label.application.details.proxy.alert.submit');
  const proxyAlertCancelText = intl.getI18nMessage('label.cancel.button');

  return (
    <Fragment>
      <AlertDialog
        isOpen={showProxyErrorAlert}
        title={proxyAlertTitle}
        description={proxyAlertDescription}
        submitText={proxyAlertSubmitText}
        cancelText={proxyAlertCancelText}
        onClose={setShowProxyErrorAlert.bind(null, false)}
        onSubmit={deleteAppIgnoringProxyErrors}
        onCancel={setShowProxyErrorAlert.bind(null, false)}
        dialogId={'app-delete-proxy-checks-dialog'}
        submitButtonClass={classes.proxyAlertSubmitButton}
      />
      <div
        className={classes.appHeaderContainer}
        id="app-header-container" data-layer7-test="app-header-container"
      >
        <Typography variant="h6" className={classes.smallLabel}>
          {intl.getI18nMessage("label.application.details.small.title")}
        </Typography>
        {editingName && canEditName ?
        <div>
          <FormTextField
            id="app-name-field"
            data-layer7-test="app-name-field"
            placeholder={intl.getI18nMessage('label.application.details.header.name.title')}
            helperText={intl.getI18nMessage('label.application.details.header.name.helpertext')}
            onKeyUp={onNameKeyPress}
            handleBlur={validateName}
            value={appName}
            error={(isSaveClicked && isNameError()) || !isNameUnique}
            errorHelperText={(isSaveClicked && isNameError()) || !isNameUnique}
            handleChange={(value) => onNameChange(value)}
            fieldContainerClass={classes.nameField}
            textFieldClass={classes.textFieldClass}
          />
        </div>
      :
        <div className={classes.nameDiv}>
          <h1 className={classes.pageTitle}>{appName}</h1>
          {!locked && canEditName && !isEditDisabled &&
            <Button
              color="primary"
              variant="outlined"
              onClick={() => editName()}
              className={classes.editIcon}
              >
              <EditIcon />
            </Button>
          }
          {status && !isOnlyDev &&
            (isDeleteClicked ?
            <div className={classes.deleteConfirmContainer}>
              <Button
                id="delete-confirm-button"
                data-apim-test="delete-confirm-button"
                className={classes.errorBackgroundButton}
                onClick={() => {
                              setIsDeleteClicked(false);
                              setShowLoadingDialog(true);
                              deleteFn();
                            }
                        }
                >
                {intl.getI18nMessage('label.application.details.overview.confirm.status.button')}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.cancelIcon}
                onClick={() => setIsDeleteClicked(false)}
                >
                {intl.getI18nFormattedMessage('label.application.details.header.cancel.delete')}
              </Button>
            </div> :
            <Button
              id="delete-button"
              data-apim-test="delete-button"
              variant="outlined"
              className={classes.deleteButton}
              onClick={() => setIsDeleteClicked(true)}
              disabled={status.endsWith('PENDING_APPROVAL')}
              >
              {deleteLabel}
            </Button>
          )}
        </div>}
        <div className={classes.statusBar}>
          {statusLabel &&
            <span className={cx(classes.status, statusColorClass)}>
              {intl.getI18nMessage(statusLabel)}
            </span>
          }
          {(!locked && !isEditDisabled &&
          (status === APPLICATION_STATUS_DISABLED || status === APPLICATION_STATUS_ENABLED)) && (
            isStatusChangeClicked ?
            <div className={classes.statusConfirmContainer}>
              <Button
                id="enable-disable--confirm-button"
                data-apim-test="enable-disable-confirm-button"
                color="primary"
                variant="outlined"
                className={classes.cancelButton}
                onClick={() => {
                  setIsStatusChangeClicked(false);
                  changeStatus(status === APPLICATION_STATUS_DISABLED)}}
                >
                {intl.getI18nMessage('label.application.details.overview.confirm.status.button')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancelButton}
                onClick={() => setIsStatusChangeClicked(false)}>
                {intl.getI18nFormattedMessage('label.application.details.header.cancel.status.update')}
              </Button>
            </div> :
            <Button
              id="enable-disable-button"
              data-apim-test="enable-disable-button"
              color="primary"
              variant="outlined"
              className={classes.cancelButton}
              onClick=
              {() => isLocking ?
                setIsStatusChangeClicked(true) :
                changeStatus(status === APPLICATION_STATUS_DISABLED)}
              >
              {intl.getI18nMessage(`label.application.details.overview.${status === APPLICATION_STATUS_DISABLED ? 'enable': 'disable'}.button.text`)}
            </Button>)}
            {!locked && isLocking && !isStatusChangeClicked &&
            (status === APPLICATION_STATUS_DISABLED || status === APPLICATION_STATUS_ENABLED) &&
              <Tooltip
                title={getI18nFormattedMessage('label.application.details.header.status.locking.tooltip')}
                arrow placement="right">
                <ReportProblem className={classes.warningIcon}/>
              </Tooltip>
            }
            { showReviewRequest && isAdmin &&
              <Button className={classes.reviewRequestButton} color="primary" href={applicationURL} variant="outlined">
                {reviewRequestMessage}
              </Button>
            }
        </div>
        <LoadingDialog
          isOpen={showLoadingDialog}
          title={intl.getI18nMessage('label.application.deleting')}
          description={intl.getI18nMessage('label.application.deleting.status.text')}
          dialogId="deleting-app-dialog"
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isAppNameUnique: getIsAppNameUnique(state),
  deleteAppStatus: getDeleteApplicationStatus(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  checkAppNameUnique,
  deleteSingleApplication,
};

HeaderRaw.propTypes = {
  applicationDetails: object,
  isEditDisabled: bool,
  classes: object,
  hideDeployments: func,
  fetchSingleApp: func,
  isAddFlow: bool,
  changeStatus: func,
  setAppDetails: func,
  isAppNameUnique: bool,
  checkAppNameUnique: func,
  validateNameUniqueness: func,
  isSaveClicked: bool,
  deleteSingleApplication: func,
  deleteAppStatus: string,
  fetchApplicationDeleteRequestSetting: func,
  isDeleteApplicationRequestWorkflowEnabled: bool,
  setNotificationStatus: func,
  setNotificationMessage: func,
  errors: arrayOf(object),
  user: object,
  locked: bool,
  notifyMessages: func,
  isDeleteWorkflowEnabled: bool,
  isAdmin: bool,
  isLocking: bool,
  isOnlyDev: bool,
  setDeleteAndFetchApp: bool,
  canEditName: bool,
  isPortalAdmin: bool,
  fetchAppRequestStatus: func,
};
HeaderRaw.displayName = 'Header';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderRaw);
