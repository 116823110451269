import React, { useState } from 'react';
import { number, shape, string, bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/intl';
import {
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';
import FormTextField from '../../FormTextField';
import StatusIndicator from '../../StatusIndicator';

export const DetailsHeader = (props) => {
  const {
    classes,
    headerId,
    title,
    subTitle='',
    marker='',
    name,
    modifyTs,
    status,
    version,
    access,
    type='text',
    handleChange,
    isNameError,
    helperText,
    maxLength,
    isFieldEdit,
    isFieldEditIcon,
    onClickEditIcon,
    showDelete = false,
    deleteFn,
  } = props;

  const [isDeleteClicked , setIsDeleteClicked] = useState(false);
  const intl = getI18n(useIntl());
  return (
    <div className={classes.topContainer}>
      <div
        className={classes.headerContainer}
        id={headerId} data-apim-test={headerId} data-layer7-test={headerId}
      >
        <Typography variant="h6" className={classes.pageTitle}>
          {title}
        </Typography>
        {type === "textField" && isFieldEdit ?
          <FormTextField
            id={`${headerId}-textField`}
            name={''}
            value={name}
            error={isNameError}
            maxLength={maxLength ? maxLength : 255}
            handleChange={(value) => handleChange(value)}
            helperText={helperText}
            textFieldClass={classes.textFieldClass}
            fieldContainerClass={classes.textFieldContainer}
          />
        :
            <Typography variant="h1" className={classes.pageTitle}>
              {name}
              {type === "textField" && isFieldEditIcon &&
                <IconButton aria-label="edit" className={classes.editIcon} onClick={onClickEditIcon}>
                  <EditIcon />
                </IconButton>
              }
            </Typography>
        }
        {subTitle &&
          <div className={classes.subTitle}>
            {subTitle}
            {marker && <span className={classes.markerContainer}>{marker}</span>}
          </div>
        }
        {status &&
          <StatusIndicator
            status={status}
            modifyTs={modifyTs}
            version={version}
            access={access}
          />
        }
      </div>
      <div className={classes.deleteContainer}>
      { showDelete &&
            (isDeleteClicked ?
            <div className={classes.deleteConfirmContainer}>
              <Button
                id="delete-confirm-button"
                data-apim-test="delete-confirm-button"
                className={classes.errorBackgroundButton}
                onClick={() => {
                              setIsDeleteClicked(false);
                              deleteFn();
                            }
                        }
                >
                {intl.getI18nMessage('label.details.header.delete.confirm.status.button')}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.cancelIcon}
                onClick={() => setIsDeleteClicked(false)}
                >
                {intl.getI18nFormattedMessage('label.details.header.delete.cancel.delete')}
              </Button>
            </div> :
            <Button
              id="delete-button"
              data-apim-test="delete-button"
              variant="outlined"
              className={classes.deleteButton}
              onClick={() => setIsDeleteClicked(true)}
              >
              {intl.getI18nFormattedMessage('label.details.header.delete')}
            </Button>
          )}
      </div>
    </div>
  );
};

DetailsHeader.propTypes = {
  classes: shape({}),
  headerId: string,
  title: string,
  name: string,
  modifyTs: number,
  status: string,
  subTitle: string,
  version: string,
  access: string,
  marker: string,
  type: string,
  handleChange: func,
  isNameError: bool,
  helperText: string,
  maxLength: number,
  isFieldEdit: bool,
  isFieldEditIcon: bool,
  onClickEditIcon: func,
  deleteFn: func,
  showDelete: bool,
};
DetailsHeader.displayName = 'DetailsHeader';

export default withStyles(styles)(DetailsHeader);
