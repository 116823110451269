import React from 'react';
import { Grid, Button } from '@material-ui/core';

import FormTextField from "../../../../components/FormTextField";
import { getI18nFormattedMessage } from '../../../../utils/intl';
import { bool, shape, func } from 'prop-types';

export const FilterByName = (props) => (
    <Grid item md={3} sm={6} xs={12}>
      <FormTextField
        {...props}
        id="product-details-apis-filter-by-name"
        data-apim-test="product-details-apis-filter-by-name"
      />
    </Grid>
  );

export const AddButton = (props) => (
    <Grid
      item md={2} sm={2} xs={12}
      id="product-details-add-api-container"
      data-apim-test="product-details-add-api-container"
      data-layer7-test="product-details-add-api-container"
    >
        <Button
          id="product-details-add-api"
          data-apim-test="product-details-add-api"
          data-layer7-test="product-details-add-api"
          color="primary"
          variant="contained"
          className={props.activeButton}
          onClick={(e) => props.onClickAssign(e, '')}
          disabled={props.disabled}
        >
          {getI18nFormattedMessage('label.api.add')}
        </Button>
    </Grid>
  );

  AddButton.propTypes = {
    activeButton: shape({}),
    onClickAssign: func,
    disabled: bool,
  };

export const FilterSeparator = () => (
    <Grid item md={7} sm={4} xs={12}/>
);
