import { get, map } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
};
const initialState = {
  ...resetState,
  isLoading: false,
  requests: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
      };
    case Constants.APP_LOADING:
      return {
        ...state,
        ...resetState,
        isLoading: action.isLoading,
      };
    case Constants.REQUESTS_GET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        requestList: action.payload,
      };
    case Constants.REQUEST_GET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        requestDetails: action.payload,
      };
    case Constants.REQUEST_GET_DETAILS_ERROR:
    case Constants.REQUESTS_GET_LIST_ERROR:
      return {
        ...state,
        isListError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.REQUESTS_GET_ORG_SUGGEST_SUCCESS:
      return {
        ...state,
        orgSuggestions: map(action.payload.results, item => item.name),
      };
    case Constants.REQUESTS_GET_ORG_SUGGEST_ERROR:
      return {
        ...state,
        orgSuggestions: [],
      };
    case Constants.REQUESTS_PUT_SUBMIT_REQUEST:
      return {
        ...state,
        requestUpdateStatus: '',
      };
    case Constants.REQUESTS_PUT_SUBMIT_SUCCESS:
      return {
        ...state,
        requestUpdateStatus: Constants.SUCCESS,
      };
    case Constants.REQUESTS_PUT_SUBMIT_ERROR:
      return {
        ...state,
        requestUpdateStatus: Constants.FAIL,
        errors: action.payload,
      };
    case Constants.APPLICATION_REVIEWS_PUT_REQUEST:
      return {
        ...state,
        appReviewUpdateStatus: '',
      };
    case Constants.APPLICATION_REVIEWS_PUT_SUCCESS:
      return {
        ...state,
        appReviewUpdateStatus: Constants.SUCCESS,
      };
    case Constants.APPLICATION_REVIEWS_PUT_ERROR:
      return {
        ...state,
        appReviewUpdateStatus: Constants.FAIL,
      };
    default:
      return state;
  }
}

export const getIsLoading = (state) => get(state, 'request.isLoading');

export const getIsError = (state) => get(state, 'request.isError');

export const getErrors = state => get(state, 'request.errors');

export const getListTotalPages = (state) => get(state, 'request.requestList.totalPages');
export const getListTotalElements = (state) => get(state, 'request.requestList.totalElements');
export const getListResults = (state) => get(state, 'request.requestList.results');

export const getRequestDetails = (state) => get(state, 'request.requestDetails');

export const getOrgSuggestions = (state) => get(state, 'request.orgSuggestions');

export const getRequestUpdateStatus = (state) => get(state, 'request.requestUpdateStatus');
export const getAppReviewUpdateStatus = (state) => get(state, 'request.appReviewUpdateStatus');
