import React from 'react';
import { makeStyles, TableBody, TableContainer, TableHead, Table as MuiTable } from '@material-ui/core';
import { getColumns, getRows } from '.';
import styles from './styles';
import { PropTypes } from 'victory';

function SimpleTable(props) {
  const {
    id,
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const selectedStyles = classes.rowsSelected;

  const rows = getRows({
    ...props,
    userContext: {},
    hasBulkActions: false,
    selectedStyles,
    classes,
  });
  const columns = getColumns({
    ...props,
  });

  return (
    <TableContainer
      className={classes.tableContainer}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <MuiTable stickyHeader aria-label="sticky table">
        <TableHead>{columns}</TableHead>
        <TableBody>{rows}</TableBody>
      </MuiTable>
    </TableContainer>
  );
}
SimpleTable.propTypes = {
    id: PropTypes.string,
};

export default SimpleTable;