import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button, FormLabel, Tooltip } from '@material-ui/core';
import { func, object, string, bool, shape } from 'prop-types';

import { FormTextField, FormSelect } from '../../../../components';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';


export const FilterByName = (props) => (
    <Grid item md={5} sm={5} xs={12}>
        <FormTextField
        {...props}
        id="product-details-orgs-filter-by-name"
        data-apim-test="product-details-orgs-filter-by-name"
        />
    </Grid>
);

export const FilterSeparator = () => (
  <Grid item md={5} />
);

export const TypeHeaderTooltip = ({ tooltipLabel } ) => {
  return (
    <div>
      <div>
        { tooltipLabel }
      </div>
    </div>
  );
}
TypeHeaderTooltip.propTypes = {
  tooltipLabel: string,
};
export const TooltipIcon = ({ classes, label, tooltipLabel, content }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip tooltipLabel={tooltipLabel}/>} placement="bottom">
      {content}
    </Tooltip>
  </div>
);
TooltipIcon.propTypes = {
  tooltipLabel: string,
  classes: object,
  label: string,
  content: object,
};

export const AddButton = (props) => (
  <Grid
    item md={2} sm={2} xs={12}
    id="product-details-add-orgs-container"
    data-apim-test="product-details-add-orgs-container"
    data-layer7-test="product-details-add-orgs-container"
  >
      <Button
        id="product-details-add-org"
        data-apim-test="product-details-add-org"
        data-layer7-test="product-details-add-org"
        color="primary"
        variant="contained"
        className={props.activeButton}
        onClick={(e) => props.onClickAssign(e, '')}
        disabled={props.disabled}
      >
        {getI18nFormattedMessage('label.organization.list.add.button')}
      </Button>
  </Grid>
);

AddButton.propTypes = {
  activeButton: shape({}),
  onClickAssign: func,
  disabled: bool,
};

export const HeaderActionButtons = (props) => {
  const {
    classes = {},
    bulkActionsItemsCount,
    onAddAccessTier,
    onRemoveAccess,
    disableRemoveAccess,
    tiersList,
    selectedTier,
    onChangeTier,
    hasApplyTierLabel,
  } = props;

  const id = 'product-org-header-buttons'
  const intl = getI18n(useIntl());

  return (
    <>
        <FormLabel className={ classes.actionsLabel }>
            {intl.getI18nMessage('label.organization.apiaccess.actions.label', { count: bulkActionsItemsCount })}
        </FormLabel>
        <Grid item container md={12} sm={12} xs={12} className={classes.headerButtons}>
            <Grid item xs={3}>
                <FormSelect
                    {...props}
                    handleChange={onChangeTier}
                    id={`${id}-tiers-list`}
                    data-apim-test={`${id}-tiers-list`}
                    // eslint-disable-next-line react/prop-types
                    data={tiersList}
                    value={selectedTier}
                    noNoneOption
                    noNativeSelect
                    fieldContainerClass={classes.selectContainer}
                />
            </Grid>
            <Grid item xs={9} className={classes.buttonContainer}>
                <Button
                    variant="contained" color="secondary"
                    className={classes.addButton}
                    id={`${id}-add-access-tier`}
                    data-apim-test={`${id}-add-access-tier`}
                    data-layer7-test={`${id}-add-access-tier`}
                    onClick={onAddAccessTier}
                >
                    {hasApplyTierLabel ?
                      intl.getI18nMessage('label.access.apply.tier') :
                      intl.getI18nMessage('label.apply.tier')
                    }
                </Button>
                <Button
                    variant="contained" color="secondary"
                    className={classes.removeButton}
                    id={`${id}-remove-access`}
                    data-apim-test={`${id}-remove-access`}
                    data-layer7-test={`${id}-remove-access`}
                    onClick={onRemoveAccess}
                    disabled={disableRemoveAccess}
                >
                    {intl.getI18nMessage('label.remove.access')}
                </Button>
            </Grid>
        </Grid>
    </>
  );
}

HeaderActionButtons.propTypes = {
  classes: object,
  onAddAccessTier: func,
  onRemoveAccess: func,
  disableRemoveAccess: bool,
  bulkActionsItemsCount: string,
  tiersList: shape({}),
  selectedTier: string,
  onChangeTier: func,
  hasApplyTierLabel: bool,
};