import { get } from 'lodash';

import { modifyAlpha } from '../../utils/modifyColour';

const styles = (theme) => ({
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formLabelContainer: {
    width: '98%',
  },
  hideFormLabel: {
    visibility: 'hidden',
  },
  optional: {
    float: 'right',
    opacity: 0.5,
  },
  selectField: {
    border: `1px solid ${modifyAlpha(get(theme, 'color.primaryButtonBackground'), 0.4)}`,
    height: get(theme, 'height.h40'),
    padding: `${get(theme, 'padding.p5')} ${get(theme, 'padding.p10')}`,
    margin: `${get(theme, 'margin.m10')} 0 0 0`,
    width: '100%',
    '&.MuiNativeSelect-select:focus, .MuiNativeSelect-select, .MuiSelect-select:focus, .MuiSelect-select': {
      borderRadius: 'unset',
      backgroundColor: 'transparent',
    },
    '&.MuiInput-underline.Mui-error': {
      borderColor: `${get(theme, 'color.error')} !important`,
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'transparent',
    },
    '&.MuiInput-underline:after, .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '&.MuiInput-underline:before': {
      borderBottom: 0,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  checkbox: {
    padding: `0 ${get(theme, 'padding.p6')} 0 0`,
  },
  warningSelectField: {
    color: get(theme, 'color.red'),
  },
  /* Todo : material ui overide style
  selectField: {
    fontFamily: theme.typography.bodyText,
    fontSize: theme.fontSize.bodyText,
    width: '99%',
    border: '1px solid #cccccc',
    borderRadius: 4,
    color: '#000000',
    padding: '2px 0px 2px 6px',
  },
  selectFieldRoot: {
    '&$focused': {
      borderRadius: 'unset',
      backgroundColor: 'transparent',
    },
    '&$outlined$error': {
      borderColor: '#f44336 !important',
    },
    '&$outlined$error:after': {
      borderBottomColor: 'transparent',
    },
    '&$outlined:after, $outlined:before': {
      borderBottom: 0,
    },
  },
  outlined: {},
  focused: {},
  error: {},
  underline: {},
  */
  helperText: {
    color: `${get(theme, 'color.smallText')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },
  errorHelperText: {
    color: `${get(theme, 'color.error')} !important`,
    fontFamily: `${get(theme, 'typography.smallText')} !important`,
    fontSize: `${get(theme, 'fontSize.smallText')} !important`,
  },
  [`@media (max-width: ${get(theme, 'breakpoint.xs')})`]: {
    fieldContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    hideFormLabel: {
      display: 'none',
    },
  },
});

export default styles;
