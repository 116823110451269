import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import get from 'lodash/get';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, withStyles } from '@material-ui/core';
import { lowerCase, startCase } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { fetchApplications, fetchApiPlanDetails, fetchApiGroupDetails } from '../../../actions/application';
import { fetchAutoSuggestions, fetchApi } from '../../../actions/api';
import { getConfig, getUserDetails } from '../../../reducers/portalConfig';
import {
  getApplicationsListTotalPages,
  getApplicationsListTotalElements,
  getApplicationsListResults,
  getIsListError,
  getIsLoading,
  getSingleApiGroup,
  getSingleApiPlan,
} from '../../../reducers/application';
import { getAutoSuggestions, getApiDetails } from '../../../reducers/api';
import styles from './styles';
import ListContainer from '../../list';
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ALERT_ERROR,
  INFO,
  ANY,
  APPLICATION_LIST_STATUS_BY,
  APPLICATION_STATUS_ENABLED,
  APPLICATION_STATUS_PENDING_APPROVAL,
  APPLICATION_STATUS_EDIT_PENDING_APPROVAL,
  APPLICATION_STATUS_REJECTED,
  APPLICATION_STATUS_DELETE_PENDING_APPROVAL,
  APPLICATION_STATUS_DISABLED,
  APPLICATION_STATUS_INCOMPLETE,
  APPLICATION_STATUS_DELETE_FAILED,
} from '../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  isOnlyDeveloper,
  hasOrgBoundRole,
} from '../../../utils';
import SearchBar from '../../../components/SearchBar';
import { FormSelect, FormTextField } from '../../../components';

export const getHashParams = (props) => get(props, 'location.hash');
const getAppListColumns = (isOrgUser, classes) => {
  const columnList = [];
  columnList.push({
    id: 'name',
    label: getI18nFormattedMessage('label.applicaiton.list.page.application.name.column'),
    minWidth: 200,
    link: '/admin/console/applications/details',
  });
  if(!isOrgUser) {
    columnList.push({
      id: 'organizationName',
      label: getI18nFormattedMessage('label.applicaiton.list.page.organization.name.column'),
      minWidth: 200,
    });
  }
  columnList.push({
    id: 'status',
    label: getI18nFormattedMessage('label.applicaiton.list.page.status.column'),
    minWidth: 200,
    value: (item) => formatAppStatus(item.status, classes),
  });
  return columnList;
}

export const getStatusColorClass = (status, classes) => {
  switch (status) {
    case APPLICATION_STATUS_ENABLED:
      return classes.available;
    case APPLICATION_STATUS_PENDING_APPROVAL:
    case APPLICATION_STATUS_EDIT_PENDING_APPROVAL:
    case APPLICATION_STATUS_DELETE_PENDING_APPROVAL:
      return classes.pending;
    case APPLICATION_STATUS_REJECTED:
    case APPLICATION_STATUS_INCOMPLETE:
    case APPLICATION_STATUS_DELETE_FAILED:
      return classes.rejected;
    case APPLICATION_STATUS_DISABLED:
      return classes.disabled;
  }
};
const formatAppStatus = (status, classes) => {
  return (
    <span className={classes.statusField}>
      <FiberManualRecordIcon
        className={clsx(classes.statusIcon, getStatusColorClass(status, classes))}/>
      <span>{startCase(lowerCase(status))}</span>
    </span>);
}
export const ApplicationList = (props) => {
  const {
    classes,
    userContext,
    isError,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    fetchAutoSuggestions,
    fetchApi,
    fetchApiGroupDetails,
    fetchApiPlanDetails,
    apiDetails,
    autoSuggestions,
    apiPlanDetails,
    apiGroupDetails,
  } = props;

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByOrgName, setFilterByOrgName] = useState('');
  const [filterByStatus, setFilterByStatus] = useState(ANY);
  const [filterByXref, setFilterByXref] = useState('');
  const [appliedNameFilter, setAppliedNameFilter] = useState('');
  const [appliedOrgFilter, setAppliedOrgFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [hideAddButton, setHideAddButton] = useState(true);
  const [apiNameFilterInputValue, setApiNameFilterInputValue] = useState('');
  const [apiNameFilterValue, setApiNameFilterValue] = useState('');
  const [filterByXrefUuid, setFilterByXrefUuid] = useState('');
  const [statusFilterList, setStatusFilterList] = useState(APPLICATION_LIST_STATUS_BY);
  const [selectedApiName, setSelectedApiName] = useState('');
  const options = autoSuggestions.map(entity => ({ key: entity.uuid, value: entity.name }));
  const autoSuggestHandler = (val) => fetchAutoSuggestions({ searchText: val });
  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };
  const hashParams = getHashParams(props);
  const fetchApplicationsOnLoad = () => {
    const payload = {
      page,
      size: rowsPerPage,
    };
    if (hashParams) {
      const hashArray = hashParams.split("/");
      if (hashArray && hashArray.length === 2) {
        const entityType = hashArray[0].substring(1);
        const uuid = hashArray[1];
        if(entityType === "api" || entityType === "apiPlan" || entityType === "apiGroup") {
          if(entityType === "api") {
            fetchApi(uuid);
          } else {
            if (entityType === "apiPlan") {
              fetchApiPlanDetails(uuid);
            } else if (entityType === "apiGroup") {
              fetchApiGroupDetails(uuid);
            }
            setFilterByXref(entityType);
            setFilterByXrefUuid(uuid);
          }
          payload[`${entityType}Uuid`] = uuid;
        }
      }
    }
    props.fetchApplications(payload, hasOrgBoundRole(userContext));
  };

  const onCloseNotification = () => {
    window.location.href = '/admin/console/applications';
  };
  const isFilterDirty = () =>
    filterByName !== '' ||
    filterByOrgName !== ''||
    filterByStatus !== ANY ||
    apiNameFilterValue !== '' ||
    filterByXref !== '';
  const retrieveApplications = (filtersAndPages) => {
    const payload = {
      name: filtersAndPages.name || filterByName,
      orgName: filtersAndPages.orgName || filterByOrgName,
      status: filtersAndPages.status || filterByStatus,
      apiUuid: filtersAndPages.apiUuid || filtersAndPages.apiUuid === ''? filtersAndPages.apiUuid : apiNameFilterValue,
      page: filtersAndPages.page || 0,
      size: filtersAndPages.size || rowsPerPage,
    };
    if(filterByXrefUuid) {
      payload[`${filterByXref}Uuid`] = filterByXrefUuid;
    }
    props.fetchApplications(payload, hasOrgBoundRole(userContext));
  };
  useEffect(() => {
    if(apiDetails && apiDetails.name) {
      setApiNameFilterInputValue(apiDetails.name);
      setApiNameFilterValue(apiDetails.uuid);
      setSelectedApiName(apiDetails.name);
    }
  }, [apiDetails]);
  useEffect(() => {
    if(apiPlanDetails && apiPlanDetails.name) {
      notifyMessages(INFO,
        `Currently filtering Applications by API Plan → ${apiPlanDetails.name}`);
    }
  }, [apiPlanDetails]);
  useEffect(() => {
    if(apiGroupDetails && apiGroupDetails.name) {
      notifyMessages(INFO,
        `Currently filtering Applications by API Group → ${apiGroupDetails.name}`);
    }
  }, [apiGroupDetails]);
  
  useEffect(() => {
    fetchApplicationsOnLoad();
  }, []);
  const isOrgUser = hasOrgBoundRole(userContext);
  useEffect(() => {
    const isDev = isOnlyDeveloper(userContext);
    setHideAddButton(isDev);
    if (!isOrgUser) {
      setStatusFilterList(APPLICATION_LIST_STATUS_BY.filter(status =>
        status.uuid !== APPLICATION_STATUS_REJECTED));
    } else if (isDev) {
      setStatusFilterList(APPLICATION_LIST_STATUS_BY.filter(status =>
        status.uuid !== APPLICATION_STATUS_INCOMPLETE));
    }
  }, [userContext]);

  useEffect(() => {
    if (isError) {
      notifyMessages(ALERT_ERROR, intl.getI18nMessage('error.applications.list.fetch'));
    }
  }, [isError]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByOrgNameChange = (value) => {
    setFilterByOrgName(value);
  };

  const onFilterByStatusChange = (newStatus) => {
    setFilterByStatus(newStatus);
    retrieveApplications({ status: newStatus });
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter' && filterByName !== appliedNameFilter) {
      retrieveApplications({ });
      setAppliedNameFilter(filterByName);
    }
  };
  const onFilterByOrgKeyPress = (e) => {
    if (e.key === 'Enter' && filterByOrgName !== appliedOrgFilter) {
      retrieveApplications({ });
      setAppliedOrgFilter(filterByOrgName);
    }
  };
  const handleBlurName = () => {
    if(filterByName !== appliedNameFilter) {
      retrieveApplications({ });
      setAppliedNameFilter(filterByName);
    }
  };

  const handleBlurOrgName = () => {
    if(filterByOrgName !== appliedOrgFilter) {
      retrieveApplications({ });
      setAppliedOrgFilter(filterByOrgName);
    }
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    retrieveApplications({ page: newPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    retrieveApplications({ size: newRowsPerPage });
  };

  const onAddApplication = () => {
    props.push('/admin/console/applications/add');
  };
  const onFilterByApiNameChange = () => {
    if(selectedApiName !== apiNameFilterInputValue) {
      setApiNameFilterInputValue('');
      setApiNameFilterValue('');
      retrieveApplications({ apiUuid: '' });
    }
  };
  const onFilterByApiNameSelectionChange = (option) => {
    if (option && option.value) {
      setApiNameFilterInputValue(option.value);
      setApiNameFilterValue(option.key);
      setSelectedApiName(option.value);
      setPage(0);
      retrieveApplications({ apiUuid: option.key });
    }
  };
  const onFilterByApiNameInputChange = (value) => {
    setApiNameFilterInputValue(value);
  };
  const noResultsMessage = isFilterDirty() ?
    intl.getI18nMessage('label.application.filter.no.results') :
    intl.getI18nMessage('label.application.no.results');

  return (
    <ListContainer
      listPageId="application-list-page"
      isLoading={isLoading}
      notificationId="application-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationStatus}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      onCloseNotification={onCloseNotification}
      closeNotificationText={intl.getI18nMessage('label.application.list.notification.close.button.text')}
      pageHeaderTitle={intl.getI18nMessage('label.application.list.page.title')}
      addButtonLabel={intl.getI18nMessage('label.application.list.add.button')}
      onAdd={!hideAddButton && onAddApplication}
      pageFilterAndSortClass={classes.pageFilterAndSort}
      filterAndSortContent={!filterByXref || filterByXref==='api' ? (
        <div className={classes.textFiltersContainer}>
        <Fragment>
          <Grid item md={3} sm={3} xs={12} className={classes.filterField}>
            <FormTextField
              name={intl.getI18nMessage('label.filter')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.application.list.page.filter.by.name.placeholder')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
              handleBlur={handleBlurName}
              id="applications-filter-by-name"
              data-apim-test="applications-filter-by-name"
            />
          </Grid>
          {!isOrgUser &&
            (<Grid item md={3} sm={3} xs={12} className={classes.filterField}>
              <FormTextField
                {...props}
                id="applications-filter-by-org-name"
                data-apim-test="applications-filter-by-org-name"
                name="Filter"
                value={filterByOrgName}
                textFieldClass={classes.textField}
                placeholder={intl.getI18nMessage('label.application.list.page.filter.by.orgname.placeholder')}
                handleChange={onFilterByOrgNameChange}
                onKeyPress={onFilterByOrgKeyPress}
                handleBlur={handleBlurOrgName}
                hideLabel
              />
            </Grid>
          )}
          <Grid item className={classes.searchBarContainerWrapper}>
            <Grid className={classes.searchBarContainer}>
              <SearchBar
                autoSuggestSuggestions={options}
                dispatchAutoSuggest={autoSuggestHandler}
                handleSelection={onFilterByApiNameSelectionChange}
                handleInputChange={onFilterByApiNameInputChange}
                handleTextChange={onFilterByApiNameChange}
                inputValue={apiNameFilterInputValue}
                placeHolderText={'All APIs'}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid item md={3} sm={3} xs={12} className={classes.filterField}>
            <FormSelect
              {...props}
              id="applications-filter-by-status"
              data-apim-test="applications-filter-by-status"
              name={intl.getI18nMessage('label.filter')}
              value={filterByStatus}
              data={statusFilterList}
              handleChange={onFilterByStatusChange}
              hideLabel
              noNoneOption
              noNativeSelect
            />
          </Grid>
        </Fragment>
        </div>
      ): null }
      columns={getAppListColumns(isOrgUser, classes)}
      rows={results}
      noResultsMessage={noResultsMessage}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
    />
  );
};

ApplicationList.propTypes = {
  classes: shape({ }),
  userContext: shape({ }),
  isLoading: bool,
  isError: bool,
  push: func,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({ })),
  autoSuggestions: arrayOf(shape({ })),
  fetchApplications: func,
  fetchAutoSuggestions: func,
  fetchApi: func,
  fetchApiGroupDetails: func,
  fetchApiPlanDetails: func,
  apiDetails: shape({ }),
  apiGroupDetails: shape({ }),
  apiPlanDetails: shape({ }),
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  userContext: getUserDetails(state),
  isError: getIsListError(state),
  isLoading: getIsLoading(state),
  totalPages: getApplicationsListTotalPages(state),
  totalElements: getApplicationsListTotalElements(state),
  results: getApplicationsListResults(state),
  autoSuggestions: getAutoSuggestions(state),
  apiDetails: getApiDetails(state),
  apiPlanDetails: getSingleApiPlan(state),
  apiGroupDetails: getSingleApiGroup(state),
});

const mapDispatchToProps = {
  push,
  fetchApplications,
  fetchAutoSuggestions,
  fetchApi,
  fetchApiGroupDetails,
  fetchApiPlanDetails,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationList);
