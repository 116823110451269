import get from 'lodash/get';

const styles = ({ color, fontSize, margin, padding, typography , theme }) => ({
  headerContainer: {
    padding: `0 ${get(padding, 'p36')}`,
  },
  topContainer: {
    display: 'flex',
  },
  deleteContainer: {
    marginLeft: 'auto',
    marginTop: '30px',
    marginRight: '30px',
  },
  pageTitle: {
    margin: `${get(margin, 'm5')} 0`,
    wordWrap: 'break-word',
  },
  subTitle: {
    paddingBottom: `${get(padding, 'p10')}`,
    fontSize: `${get(fontSize, 'bodyText')}`,
    wordWrap: 'break-word',
  },
  statusBar: {
    margin: `${get(margin, 'm5')} 0 ${get(margin, 'm21')} 0`,
    '& span': {
      position: 'relative',
      display: 'inline-block',
      marginBottom: '5px',
      paddingLeft: '15px',
      verticalAlign: 'middle',
      fontSize: get(fontSize, 'smallText'),
      letterSpacing: 0,
    },
  },
  status: {
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  available: {
    color: get(color, 'success'),
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  pendingEnrollment: {
    color: get(color, 'disabledGraphic'),
    '&:before': {
      backgroundColor: get(color, 'disabledGraphic'),
    },
  },
  markerContainer: {
    backgroundColor: get(color, 'gray'),
    padding: get(padding, 'p5'),
    borderRadius: get(padding, 'p10'),
    marginLeft: get(margin, 'm4'),
    fontFamily: get(typography, 'bodyText'),
  },
  textFieldClass: {
    width: '58% !important',
  },
  editIcon: {
    border: '1px solid ',
    borderColor: get(color, 'primaryButtonBackground'),
    marginLeft: get(margin, 'm10'),
    borderRadius: '4px',
    height: '30px',
    width: '30px',
  },
  textFieldContainer: {
    marginTop: `${get(margin, 'm4')} !important`,
  },
  deleteButton: {
    textTransform: 'none',
    color: `${get(color, 'error')} !important`,
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor:  `${get(color, 'error')} !important`,
    marginLeft: 'auto',
    float: 'right',
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
    display: 'inline-flex',
    height: 'fit-content',
  },
  deleteConfirmContainer: {
    display: 'inline-flex',
    marginLeft: 'auto',
  },
  cancelIcon: {
    marginLeft: 3,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  errorBackgroundButton: {
    backgroundColor: get(color, 'error'),
    color: get(color, 'bodyBackground'),
    '&:hover': {
      backgroundColor: get(color, 'borderColor'),
    },
    '&:focus': {
      backgroundColor: get(color, 'borderColor'),
    },
  },
});

export default styles;
