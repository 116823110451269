import { get } from 'lodash';
import * as Constants from '../../constants';

const initialState = {
};
export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APPLICATION_AVAILABLE_API_PRODUCTS_GET_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Constants.APPLICATION_AVAILABLE_API_PRODUCTS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        applicationAPIProducts: action.payload,
      };
    }
    case Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        productAccessSaveStatus: {
          result: 'PENDING',
        },
      };
    }
    case Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productAccessSaveStatus: {
          result: 'SUCCESS',
        },
      };
    }
    case Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        productAccessSaveStatus: {
          result: 'FAIL',
          response: action.payload,
        },
      };
    }
    default:
      return state;
  }
}


export const getIsLoading = (state) => get(state, 'application.isLoading');
export const getAppAPIProducts = (state) => get(state, 'application.applicationAPIProducts.results');
export const getAppAPIProductsTotalPages = (state) => get(state, 'application.applicationAPIProducts.totalPages');
export const getAppAPIProductsTotalElements = (state) => get(state, 'application.applicationAPIProducts.totalElements');
export const getProductAccessSaveStatus = (state) => get(state,'application.productAccessSaveStatus');
