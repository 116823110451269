import get from 'lodash/get';

export default ({ color, typography, lineHeight }) => ({
  Header: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: get(color, 'headerBackground'),
    boxShadow: '0 1px 20px 0 rgba(0, 0, 0, 0.1)',
    fontFamily: get(typography, 'bodyText'),
    zIndex: 900,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
  },

  HeaderInner: {
    height: 70,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    //justifyContent: 'center',
  },
  saveButton: {
    textTransform: 'none',
    color: get(color, 'primaryButtonText'),
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    marginRight: '10px',
    marginLeft: '10px',
    lineHeight: get(lineHeight, 'lineHeight.lh20'),
  },
  buttonContainer: {
    marginLeft: 'auto',
  },
});
