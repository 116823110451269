import { get } from 'lodash';
import { convertHexToRGB } from '../../../utils';

const styles = ({ breakpoint, color, fontSize, typography, borderRadius }) => ({
  tablePaginationContainer: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    width: '100%',
    '& .MuiTablePagination-toolbar': {
      margin: '0 36px',
      padding: 0,
    },
    '& .MuiTablePagination-spacer': {
      flex: 'none',
    },
    '& p': {
      fontSize: get(fontSize, 'bodyText'),
    },
  },
  tablePagination: {
    padding: '0',
    position: 'absolute',
    right: 0,
    '& button:disabled': {
      opacity: 0.8,
    },
  },
  tablePaginationItem: {
    display: 'inline-block',
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    height: 32,
    padding: '0 6px',
    '& button': {
      border: `1px solid ${get(color, 'primaryButtonBackground')}`,
    },
    '& button.Mui-selected': {
      backgroundColor: get(color, 'primaryButtonBackground'),
      border: `1px solid ${get(color, 'primaryButtonBackground')}`,
      color: get(color, 'primaryButtonText'),
      '&:hover': {
        backgroundColor: get(color, 'primaryButtonHover'),
        borderColor: get(color, 'primaryButtonHover'),
        color: get(color, 'primaryButtonText'),
      },
    },
  },
  selectRoot: {
    margin: '0 8px',
  },
  select: {
    border: `1px solid ${convertHexToRGB(get(color, 'primaryButtonBackground'), 0.4)}`,
    color: get(color, 'bodyText'),
    borderRadius: get(borderRadius, 'br3'),
  },
  selectIcon: {
    color: get(color, 'primaryButtonBackground'),
    right: 2,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
  [`@media (max-width: ${get(breakpoint, 'xs')})`]: {
    tablePaginationContainer: {
      '& .MuiTablePagination-toolbar': {
        margin: '0 16px',
      },
    },
  },
});

export default styles;
