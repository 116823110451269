import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { withStyles, Link } from '@material-ui/core';
import { upperCase, lowerCase, capitalize, get } from 'lodash';
import queryString from 'query-string';
import { number, bool, func, shape, arrayOf } from 'prop-types';

import { ToolTip } from '../../../components';
import { fetchList, resetApp } from '../../../actions/rateQuota';
import { getUserDetails, getFeatureFlagForProduct } from '../../../reducers/portalConfig';
import {
  getListTotalPages,
  getListTotalElements,
  getListResults,
  getIsDeleteSuccess,
} from '../../../reducers/rateQuota';
import styles from './styles';
import ListContainer from '../../list';
import {
  GRID_ROWS_PER_PAGE_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ALERT_SUCCESS,
  QUOTA_ASSIGNMENT_API_ORG,
  QUOTA_ASSIGNMENT_API_ORG_TEXT,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION,
  QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION_TEXT,
 } from '../../../constants';
import {
  FilterByName,
  FilterByLevels,
  FilterByLimit,
  SortBy,
  FilterAndSortSeparator,
} from './controls';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import { formatNumber } from '../../../utils';

const getLinkContent = (value, secondaryValue, uuid, classes) => (
  <div>
    <Link underline="none" href={`/admin/rate-quotas/details/${uuid}`}>
      {value}
    </Link>
    {secondaryValue &&
      <span className={classes.tooltipContainer}>
        {getI18nFormattedMessage('label.default')}
      </span>
    }
  </div>
);


const getListColumns = ({ classes }) => [{
    id: 'name',
    secondaryId: 'defaultRateQuota',
    label: getI18nFormattedMessage('label.rate.quota.name'),
    minWidth: 200,
    format: (value, secondaryValue, uuid) => {
      return getLinkContent(value, secondaryValue, uuid, classes);
    },
  },{
    id: 'description',
    label: getI18nFormattedMessage('label.rate.quota.description'),
    minWidth: 50,
    isCellOverflow: true,
  },{
    id: 'assignmentLevel',
    label: getI18nFormattedMessage('label.rate.quota.assignment.level'),
    minWidth: 50,
    format: value => value === 'API' ? value : value === QUOTA_ASSIGNMENT_API_ORG ?
      QUOTA_ASSIGNMENT_API_ORG_TEXT : value === QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION ?
      QUOTA_ASSIGNMENT_PRODUCT_PER_APPLICATION_TEXT : capitalize(value),
  }, {
    id: 'rateLimit',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nFormattedMessage('label.rate.quota.rate.limit')}
        info={<div>{getI18nFormattedMessage('label.rate.quota.rate.limit.tool.tip')}</div>}
      />
    ),
    format: value => get(value, 'maxRequestsPerSecond') ? formatNumber(get(value, 'maxRequestsPerSecond')) : 'None',
    minWidth: 50,
  },{
    id: 'rateLimit',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nFormattedMessage('label.rate.quota.spread.limit.window')}
        info={<div>{getI18nFormattedMessage('label.rate.quota.spread.limit.window.tool.tip')}</div>}
      />
    ),
    format: value => get(value, 'windowSizeInSeconds') ? formatNumber(get(value, 'windowSizeInSeconds')) : '',
    minWidth: 50,
  },{
    id: 'rateLimit',
    label: getI18nFormattedMessage('label.rate.quota.maximum.concurrency'),
    format: value => get(value, 'maxConcurrency') ? formatNumber(get(value, 'maxConcurrency')) : '',
    minWidth: 50,
  },{
    id: 'quota',
    label: getI18nFormattedMessage('label.rate.quota.limit.quota'),
    format: (value) => get(value, 'quota') ? `${formatNumber(get(value, 'quota'))} / ${lowerCase(get(value, 'interval'))}` : 'None',
    minWidth: 50,
  },
];

export const ListRaw = (props) => {
  const {
    classes,
    isLoading,
    userContext,
    featureFlagProduct,
    totalPages,
    totalElements = 0,
    results = [],
    isDeleteSuccess,
  } = props;

  const intl = getI18n(useIntl());
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByLevel, setFilterByLevel] = useState('all');
  const [filterByLimit, setFilterByLimit] = useState('all');
  const [sortBy, setSortBy] = useState('name,ASC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);

  const emptyFunction = () => {};

  useEffect(() => {
    if (!get(userContext, 'permissions.RATE_QUOTA', []).includes('READ')) {
      props.push('/404');
    }
  }, [userContext]);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const level = upperCase(parsed.level) || 'all';
    setFilterByLevel(level);
    props.fetchList({ filterByName, filterByLevel: level, filterByLimit, page, rowsPerPage });
  }, [props.location]);

  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      notifyMessages(
        ALERT_SUCCESS,
        intl.getI18nMessage('label.rate.quota.deleted'),
      );
    }
  }, [isDeleteSuccess]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      props.fetchList({ filterByName, filterByLevel, filterByLimit,
        sortBy, page: 0, rowsPerPage });
    }
  };

  const onFilterByLevelChange = (level) => {
    setFilterByLevel(level);
    setPage(0);
    props.fetchList({ filterByName, filterByLevel: level, filterByLimit,
      sortBy, page: 0, rowsPerPage });
  };

  const onFilterByLimitChange = (limit) => {
    setFilterByLimit(limit);
    setPage(0);
    props.fetchList({ filterByName, filterByLevel, filterByLimit: limit,
      sortBy, page: 0, rowsPerPage });
  };

  const onSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(0);
    props.fetchList({ filterByName, filterByLevel, filterByLimit,
      sortBy: newSortBy , page: 0, rowsPerPage });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchList({ filterByName, filterByLevel, filterByLimit,
      sortBy, page: newPage, rowsPerPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchList({ filterByName, filterByLevel, filterByLimit,
      sortBy, page: 0, rowsPerPage: newRowsPerPage });
  };

  const onAddPlan = () => {
    props.resetApp();
    props.push('/admin/rate-quotas/add');
  };

  const hasAddButton = get(userContext, 'permissions.RATE_QUOTA', []).includes('CREATE') ?
    onAddPlan : null;

  return (
    <ListContainer
      listPageId="rate-quota-list"
      isLoading={isLoading}
      notificationId="rate-quota-notifications"
      notificationStatus={notificationStatus}
      setNotificationStatus={setNotificationStatus}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      pageHeaderTitle={intl.getI18nMessage('label.rate.quota.list.page.title')}
      pageHeaderTooltipTitle={''}
      addButtonLabel={intl.getI18nMessage('label.rate.quota.add.button')}
      onAdd={hasAddButton}
      showListHeader={true}
      filterAndSortContent={(
        <Fragment>
          <FilterByName
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter')}
            value={filterByName}
            placeholder={intl.getI18nMessage('label.rate.quota.name')}
            handleChange={onFilterByNameChange}
            onKeyPress={onFilterByNameKeyPress}
          />
          <FilterByLevels
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByLevel}
            handleChange={onFilterByLevelChange}
            featureFlagProduct={featureFlagProduct}
            hideLabel
          />
          <FilterByLimit
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.filter.by')}
            value={filterByLimit}
            handleChange={onFilterByLimitChange}
            hideLabel
          />
          <FilterAndSortSeparator />
          <SortBy
            fieldContainerClass={classes.fieldContainer}
            name={intl.getI18nMessage('label.sort')}
            value={sortBy}
            handleChange={onSortByChange}
          />
        </Fragment>
     )}
      columns={getListColumns(props)}
      rows={results}
      noResultsMessage={intl.getI18nMessage('label.rate.quota.list.no.results')}
      page={page}
      totalElements={totalElements}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onChangePreviousPage={onChangePreviousPage}
      onChangeNextPage={onChangeNextPage}
      notifyMessages={emptyFunction}
    />
  );
};

ListRaw.propTypes = {
  classes: shape({}),
  userContext: shape({}),
  featureFlagProduct: bool,
  location: shape({}),
  isLoading: bool,
  totalPages: number,
  totalElements: number,
  results: arrayOf(shape({})),
  fetchList: func,
  resetApp: func,
  notifyMessages: func,
  push: func,
  isDeleteSuccess: bool,
};

const mapStateToProps = (state) => ({
  isLoading: false,
  totalPages: getListTotalPages(state),
  totalElements: getListTotalElements(state),
  results: getListResults(state),
  userContext: getUserDetails(state),
  featureFlagProduct: getFeatureFlagForProduct(state),
  isDeleteSuccess: getIsDeleteSuccess(state),
});

const mapDispatchToProps = {
  fetchList,
  resetApp,
  push,
};
ListRaw.displayName = 'RateQuotas';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListRaw);
