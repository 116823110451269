import React, { Fragment, useEffect, useState } from 'react';
import { bool, func, string, object, number, arrayOf } from 'prop-types';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { sentenceCase } from 'change-case';
import { Button, Dialog, Menu, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { FormTextField, TablePagination } from '../../../../components';

import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import {
  deleteKey,
  updateKey,
} from '../../../../actions/application';

import {
  KEY_NAME_LABEL,
  STATUS_LABEL,
  KEY_STATUS_ENABLED,
} from '../labels';

import styles from './styles';
import copy from 'copy-to-clipboard';
import { ALERT_ERROR, ALERT_SUCCESS, API_KEY_STATUS_DELETE_FAILED } from '../../../../constants';
import EditKey from '../EditKey';
import { KeyboardArrowDown, Lock, ReportProblem } from '@material-ui/icons';
import clsx from 'clsx';
import { uuidv4 } from '../../../../utils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getDeleteKeyErrors, getDeleteKeyStatus, getUpdateKeyStatus } from '../../../../reducers/application';

const displayItem = (applicationDetails, classes, item) => {
  const value = get(applicationDetails, item.key) || '';
  return (
    <div key={item.id} id={item.id} className={classes.leftPanelItemContainer}>
      <Typography className={classes.leftPanelTitle}>{item.label}</Typography>
         <Typography className={classes.leftPanelDesc}>
          {item.type === 'link'
          ? value && value.split(",").map(url=> <li key={url}><Link href={url}>{url}</Link></li>)
          :item.useSentenceCase ? sentenceCase(value) : value
          }
        </Typography>
    </div>
  );
};

const items = [
  {
    id: 'app-oauth-callback-url',
    key: 'oauthCallbackUrl',
    label: getI18nFormattedMessage('label.application.details.key.overview.oauth.urls'),
    type: 'link',
  },
  {
    id: 'app-oauth-scope',
    key: 'oauthScope',
    label: getI18nFormattedMessage('label.application.details.key.overview.oauth.scope'),
  },
  {
    id: 'app-oauth-type',
    key: 'oauthType',
    label: getI18nFormattedMessage('label.application.details.key.overview.oauth.type'),
    useSentenceCase: true,
  },
];
const showCopied = (value, valueType, notifyMessages) => {
  let strType = '';
  if (valueType === 'API_KEY') {
    strType = 'API Key';
  } else if (valueType === 'SECRET') {
    strType = 'Secret';
  }
  notifyMessages(`${strType}: ${value} was copied to the clipboard.`, ALERT_SUCCESS, true);
};

const SingleKeyView = ({
  keyDetails,
  classes,
  notifyMessages,
  setIsEditKeyOpen,
  isLocking,
  locked,
  disabledByInternal,
  appSecretHashingMetadata,
  updateKeyStatus,
  deleteKey,
  updateKey,
  applicationUuid,
  defaultHashType,
  isEditDisabled,
  isPortalAdmin,
  isAdmin,
  onSaveDone,
  idx }) => {
    const intl = getI18n(useIntl());
    const [selectedKeyType, setSelectedKeyType] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isGenerateSecretDialogOpen, setIsGenerateSecretDialogOpen] =useState(false);
    const [keyClientSecret, setKeyClientSecret] = useState('');
    const handleGenerateSecretByType = (type) => {
      setSelectedKeyType(type);
      generateSecret();
      setIsGenerateSecretDialogOpen(true);
      setAnchorEl(false);
    }
    const displayHashingOptions = get(appSecretHashingMetadata, 'plaintextAllowed');
    const handleGenerateSecretClick = (event) => {
      if(displayHashingOptions) {
        setAnchorEl(event.currentTarget);
      } else {
        setSelectedKeyType(defaultHashType);
        generateSecret();
        setIsGenerateSecretDialogOpen(true);
      }
    };
    const handleMenuClose = () => {
      setAnchorEl(false);
    };
    useEffect(() => {
      if(updateKeyStatus === 'SUCCESS' && isGenerateSecretDialogOpen) {
        notifyMessages(intl.getI18nMessage(`label.application.details.keys.secret.generate.${isLocking ? 'request.' : ''}success`), ALERT_SUCCESS, true);
        setIsGenerateSecretDialogOpen(false);
        onSaveDone();
      } else if (updateKeyStatus === 'FAIL' && isGenerateSecretDialogOpen) {
        notifyMessages(intl.getI18nMessage('label.application.details.keys.secret.update.error'), ALERT_ERROR, true);
        setIsGenerateSecretDialogOpen(false);
      }
    },[updateKeyStatus]);
    const generateSecret = () => {
      const newSecret = uuidv4();
      setKeyClientSecret(newSecret.replace(/-/g, ''));
    };
    const saveGeneratedKey = () => {
      const data = {
        ...keyDetails,
        appUuid: applicationUuid,
        keySecret: keyClientSecret,
        keySecretHashed: selectedKeyType === 'HASHED_SECRET',
      };
      updateKey(data);
    };

    const getLeftPanelButton = () => {
      const { status, apiKey } = keyDetails;
      if (status === API_KEY_STATUS_DELETE_FAILED && isAdmin) {
        return (<Button
          onClick={() => {
            deleteKey({
              keyDetails: {
                appUuid: applicationUuid,
                apiKey,
              },
              isForceDelete: isPortalAdmin,
            });
          }}
          className={classes.forceDeleteButton}
        >
          {getI18nFormattedMessage(isPortalAdmin? 'label.application.details.keys.overview.force.delete.button': 'label.delete.button')}
        </Button>);
      }
      
      return (<Button
        variant="outlined"
        onClick={() => {
          setIsEditKeyOpen(true);
        }}
        className={classes.editKeyButton}
        disabled={locked || disabledByInternal
            || isEditDisabled || status === API_KEY_STATUS_DELETE_FAILED}
      >
        {getI18nFormattedMessage('label.application.details.keys.overview.editkey.button')}
      </Button>);
    };
      return (
    <Grid container className={classes.singleKeyViewContainer}>
      <Dialog
        open={isGenerateSecretDialogOpen}
        onClose={() => setIsGenerateSecretDialogOpen(false)}
        aria-labelledby="generate-secret-dialog"
        id="generate-secret-dialog"
        classes={{
          paper: classes.paperClass,
        }}
      >
        <div className={classes.generateSecretDialog}>
          <h3>{intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.heading')}</h3>
          <p className={classes.generateSecretDialogContent}>
            {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.content')}
          </p>
          <div className={classes.fieldCopyContainer}>
            <FormTextField
              id={`client-secret-key-${keyClientSecret}`}
              name={intl.getI18nMessage('label.application.key.shared.secret')}
              value={keyClientSecret}
              fieldContainerClass={classes.dialogTextField}
              disabled
              errorHelperText={selectedKeyType === 'HASHED_SECRET'}
              helperText={selectedKeyType === 'HASHED_SECRET' ?
                getI18nFormattedMessage('label.application.key.shared.secret.hashed.generated.help'): ''}
            />
            <Button
              variant="outlined"
              onClick={() => {
                copy(keyClientSecret);
                showCopied(keyClientSecret, 'SECRET', notifyMessages);
              }}
              className={classes.copyButton}
              >
              {getI18nFormattedMessage('label.application.details.keys.overview.copy.button')}
            </Button>
          </div>
          <div className={classes.dialogButtonPanel}>
            <Button
              onClick={() => {
                saveGeneratedKey();
              }}
              className={classes.errorBackgroundButton}
            >
            {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.save.button')}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setSelectedKeyType('');
                setIsGenerateSecretDialogOpen(false);
              }}
              className={classes.cancelButton}
            >
            {intl.getI18nFormattedMessage('label.application.details.keys.key.generate.secret.dialog.cancel.button')}
            </Button>
          </div>
        </div>
      </Dialog>
      <Grid item xs={6} className={classes.leftPanel}>
        <h3>{getI18nFormattedMessage('label.application.details.key.overview.oauth.title')}</h3>
        {items.map((item) => displayItem(keyDetails, classes, item))}
        <div className={classes.buttonContainer}>
            {getLeftPanelButton()}
            {locked &&
              <Tooltip
                title={getI18nFormattedMessage(disabledByInternal? 'label.application.details.locked.by.internal.tooltip':
                'label.application.details.keys.locked.tooltip')}
                arrow placement="right">
                <Lock className={classes.lockIcon}/>
              </Tooltip>
            }
            {!locked && isLocking &&
              <Tooltip
                title={getI18nFormattedMessage('label.application.details.keys.locking.tooltip')}
                arrow placement="right">
                <ReportProblem className={classes.warningIcon}/>
              </Tooltip>
            }
          </div>
      </Grid>
      <Grid item xs={6} className={classes.rightPanel}>
        <h3>{getI18nFormattedMessage('label.application.details.key.overview.apikey.title')}</h3>
        <div className={classes.fieldCopyContainer}>
          <FormTextField
            id={`client-secret-key-${keyDetails.apiKey}`}
            name={intl.getI18nMessage('label.application.key')}
            value={get(keyDetails, 'apiKey')}
            disabled
            fieldContainerClass={classes.textField}
          />
          <Button
            variant="outlined"
            onClick={() => {
              copy(keyDetails.apiKey);
              showCopied(keyDetails.apiKey, 'API_KEY', notifyMessages);
            }}
            className={classes.copyButton}
            >
            {getI18nFormattedMessage('label.application.details.keys.overview.copy.button')}
          </Button>
        </div>
        <div className={classes.fieldCopyContainer}>
          <FormTextField
            id={`client-secret-password-${keyDetails.keySecret}`}
            name={intl.getI18nMessage('label.application.key.shared.secret')}
            value={get(keyDetails, 'keySecret')}
            fieldContainerClass={classes.textField}
            disabled
          />
          {!keyDetails.keySecretHashed &&
          <Button
            variant="outlined"
            onClick={() => {
              copy(keyDetails.keySecret);
              showCopied(keyDetails.keySecret, 'SECRET', notifyMessages);
            }}
            className={classes.copyButton}
            >
            {getI18nFormattedMessage('label.application.details.keys.overview.copy.button')}
          </Button>
            }
          </div>
          <Fragment>
            <Grid container className={classes.gridContainer}>
              <Grid md={12} item>
                <Fragment>
                  <Grid container>
                    <Grid xs={12} sm={10} item className={classes.generateSecretContainer}>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.generateSecretMenuButton}
                        aria-controls={'basic-menu'}
                        aria-haspopup="true"
                        aria-expanded="true"
                        id={`btn-key-generate-secret-${idx}`}
                        onClick={handleGenerateSecretClick}
                        disabled={locked || isEditDisabled || get(keyDetails, 'status') === API_KEY_STATUS_DELETE_FAILED}
                      >
                        {getI18nFormattedMessage('label.application.details.keys.generatenew')}
                        {displayHashingOptions &&
                          <KeyboardArrowDown />
                        }
                      </Button>
                      {displayHashingOptions &&
                        <Menu
                          id="secret-type-menu"
                          anchorEl={anchorEl}
                          open={anchorEl}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                          onClose={handleMenuClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          disabled={locked || isEditDisabled || get(keyDetails, 'status') === API_KEY_STATUS_DELETE_FAILED}
                        >
                          <MenuItem onClick={() => handleGenerateSecretByType('PLAIN_SECRET')}>
                            {getI18nFormattedMessage('label.application.details.keys.generatenew.plain')}
                          </MenuItem>
                          <MenuItem onClick={() => handleGenerateSecretByType('HASHED_SECRET')}>
                            {getI18nFormattedMessage('label.application.details.keys.generatenew.hashed')}
                          </MenuItem>
                        </Menu>
                      }
                    {locked &&
                      <Tooltip
                        title={getI18nFormattedMessage(disabledByInternal? 'label.application.details.locked.by.internal.tooltip':
                        'label.application.details.keys.locked.tooltip')}
                        arrow placement="right">
                        <Lock className={classes.lockIcon2}/>
                      </Tooltip>
                    }
                    {!locked && isLocking &&
                      <Tooltip
                        title={getI18nFormattedMessage('label.application.details.keys.locking.tooltip')}
                        arrow placement="right">
                        <ReportProblem className={classes.warningIcon2}/>
                      </Tooltip>
                    }
                    </Grid>
                  </Grid>
                </Fragment>
              </Grid>
            </Grid>
            <br />
          </Fragment>
      </Grid>
    </Grid>
);
}

SingleKeyView.propTypes = {
  keyDetails: object,
  classes: object,
  notifyMessages: func,
  isAdmin: bool,
  setIsEditKeyOpen: func,
  isLocking: bool,
  locked: bool,
  disabledByInternal: bool,
  appSecretHashingMetadata: object,
  updateKeyStatus: string,
  deleteKey: func,
  updateKey: func,
  onSaveDone: func,
  applicationUuid: string,
  idx: number,
  defaultHashType: string,
  isEditDisabled: bool,
  isPortalAdmin: bool,
};

const SingleRow = ({
  row,
  idx,
  classes,
  notifyMessages,
  appSecretHashingMetadata,
  applicationUuid,
  isLocking,
  locked,
  disabledByInternal,
  expandIndex,
  setExpandIndex,
  onSaveDone,
  deleteKey,
  updateKey,
  updateKeyStatus,
  isAdmin,
  isEditDisabled,
  defaultHashType,
  isPortalAdmin,
  fetchApplicationKeys }) => {
  const [isEditKeyOpen, setIsEditKeyOpen] = useState(false);
  const getKeyStatus = () => {
    const status = get(row, 'status');
    let className = classes.enabled;
    if (status === API_KEY_STATUS_DELETE_FAILED) {
      className = classes.deleteFailed;
    } else if (status === KEY_STATUS_ENABLED) {
      className = classes.enabled;
    } else {
      className = classes.disabled;
    }
    return (<span className={clsx(classes.status, className)}>
      {sentenceCase(get(row, 'status'))}
    </span>);
  };
  return (
    <Fragment>
      <Dialog
      open={isEditKeyOpen}
      onClose={() => setIsEditKeyOpen(false)}
      aria-labelledby="edit-key-dialog"
      id={row.apiKey}
      classes={{
        paper: classes.paperClass,
      }}
      >
        <EditKey
          applicationUuid={applicationUuid}
          details={row}
          closeAction={() => {setIsEditKeyOpen(false);
                        fetchApplicationKeys(applicationUuid);}}
          appSecretHashingMetadata={appSecretHashingMetadata}
          notifyMessages={notifyMessages}
          isLocking={isLocking}
          onSaveDone={onSaveDone}
          isAdmin={isAdmin}
          isPortalAdmin={isPortalAdmin}
        />
      </Dialog>
      <TableRow onClick={() => setExpandIndex(expandIndex === idx ? -1 : idx)}>
        <TableCell component="th" scope="row">
            {row.name}
          {get(row, 'defaultKey') &&
            <Chip
              className={classes.defaultChip}
              size="small"
              label={'Default'}
            />
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {getKeyStatus()}
        </TableCell>
        <TableCell component="th">
          <div id={`expand-collapse-button-${idx}`}>
            <IconButton
            className={classes.expandCollapseButton}
            size="small"
            onClick={() => setExpandIndex(expandIndex === idx ? -1 : idx)}>
              {expandIndex===idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={expandIndex === idx} timeout="auto" unmountOnExit>
            <div className={classes.singleKeyViewCell}>
              <SingleKeyView
                idx={idx}
                keyDetails={row}
                classes={classes}
                notifyMessages={notifyMessages}
                setIsEditKeyOpen={setIsEditKeyOpen}
                isLocking={isLocking}
                locked={locked}
                disabledByInternal={disabledByInternal}
                appSecretHashingMetadata={appSecretHashingMetadata}
                onSaveDone={onSaveDone}
                deleteKey={deleteKey}
                updateKey={updateKey}
                applicationUuid={applicationUuid}
                updateKeyStatus={updateKeyStatus}
                defaultHashType={defaultHashType}
                isEditDisabled={isEditDisabled}
                isPortalAdmin={isPortalAdmin}
                isAdmin={isAdmin}
              />
              </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

SingleRow.propTypes = {
  row: object,
  idx: number,
  classes: object,
  notifyMessages: func,
  isAdmin: bool,
  appSecretHashingMetadata: object,
  applicationUuid: string,
  isLocking: bool,
  locked: bool,
  disabledByInternal: bool,
  expandIndex: number,
  setExpandIndex: func,
  updateKeyStatus: string,
  deleteKey: func,
  updateKey: func,
  onSaveDone: func,
  defaultHashType: string,
  isEditDisabled:bool,
  fetchApplicationKeys: func,
  isPortalAdmin: bool,
};

const GRID_ROWS_PER_PAGE_OPTIONS = [10, 20];
const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 10;

export const ConfigurationRaw = ({
  applicationUuid,
  applicationDetails,
  fetchApplicationKeys,
  appKeys,
  appKeysTotalPages,
  appKeysTotalElements,
  isMultiKeySupport,
  classes,
  notifyMessages,
  isAdmin,
  appSecretHashingMetadata,
  locked,
  isLocking,
  disabledByInternal,
  onSaveDone,
  deleteKey,
  updateKey,
  updateKeyStatus,
  isEditDisabled,
  isPortalAdmin,
  deleteKeyStatus,
  deleteKeyErrors,
}) => {
  const intl = getI18n(useIntl());
  const rows = isMultiKeySupport ? appKeys : [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [isEditKeyOpen, setIsEditKeyOpen] = useState(false);
  const [expandIndex, setExpandIndex] = useState(0);
  const [defaultHashType, setDefaultHashType] = useState('');
  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchApplicationKeys(applicationUuid, newPage, rowsPerPage);
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };
  const addNewKey = () => {
    setIsEditKeyOpen(true);
  };
  useEffect(() => {
    if (!get(appSecretHashingMetadata, 'plaintextAllowed')) {
      if (get(appSecretHashingMetadata, 'algorithm')) {
        setDefaultHashType('HASHED_SECRET');
      } else {
        setDefaultHashType('PLAIN_SECRET');
      }
    } else {
      setDefaultHashType('HASHED_SECRET');
    }
  }, [appSecretHashingMetadata]);
  useEffect(() => {
    if(isAdmin && appKeys[expandIndex] &&
       appKeys[expandIndex].status === API_KEY_STATUS_DELETE_FAILED) {
      if(deleteKeyStatus === 'SUCCESS') {
        notifyMessages(intl.getI18nMessage('label.application.details.keys.key.delete.success'), ALERT_SUCCESS, true);
      } else if (deleteKeyStatus === 'FAIL') {
        if (deleteKeyErrors.length > 0 && (deleteKeyErrors[0].field === 'apiKey' || deleteKeyErrors[0].field === 'proxyCheck')) {
          notifyMessages(deleteKeyErrors[0].error, ALERT_ERROR, true);
        } else {
          notifyMessages(intl.getI18nMessage('label.application.details.keys.key.delete.error'), ALERT_ERROR, true);
        }
      }
    }
  },[deleteKeyStatus]);
  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchApplicationKeys(applicationUuid, 0, newRowsPerPage);
  };
  const addKeyDetails = {
    name : '',
    status : 'ENABLED',
    defaultKey : false,
    callbackUrl : '',
    oauthScope : '',
    oauthType : 'PUBLIC',
    apiKey : '',
    keySecret : '',
    isHashed : defaultHashType === 'HASHED_SECRET',
    isRegenerateSecret : false,
  }
  return (
    <div id="app-configuration-container" className={classes.appConfigurationContainer}>
        <Fragment>
          <Dialog
            open={isEditKeyOpen}
            onClose={() => setIsEditKeyOpen(false)}
            aria-labelledby="edit-key-dialog"
            id={"new-api-key"}
            classes={{
              paper: classes.paperClass,
            }}
            >
              <EditKey
                applicationUuid={applicationUuid}
                details={addKeyDetails}
                closeAction={() => {setIsEditKeyOpen(false);
                                fetchApplicationKeys(applicationUuid);}}
                appSecretHashingMetadata={appSecretHashingMetadata}
                notifyMessages={notifyMessages}
                keyCount={appKeysTotalElements}
                onSaveDone={onSaveDone}
                isPortalAdmin={isPortalAdmin}
              />
          </Dialog>
          <div className={classes.filtersAndAdd}>
            {(isAdmin || appKeysTotalElements === 0) &&
              <Button
                id="add-key-button"
                data-apim-test="add-key-button"
                variant="contained"
                color="secondary"
                className={classes.addKeyButton}
                onClick={addNewKey}
                disabled={(appKeysTotalElements === 1 && applicationDetails.status === 'INCOMPLETE')
                                        || isEditDisabled}
              >
                {getI18nFormattedMessage('label.application.details.keys.addkey.button')}
              </Button>
            }
          </div>
          
          <TableContainer className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>{KEY_NAME_LABEL}</TableCell>
                  <TableCell>{STATUS_LABEL}</TableCell>
                  <TableCell className={classes.expanderColumn}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length > 0 ? rows.map((row, idx) =>
                  <SingleRow
                    key={idx}
                    row={row}
                    idx={idx}
                    classes={classes}
                    notifyMessages={notifyMessages}
                    isAdmin={isAdmin}
                    appSecretHashingMetadata={appSecretHashingMetadata}
                    applicationUuid={applicationUuid}
                    isLocking={isLocking}
                    locked={locked}
                    expandIndex={expandIndex}
                    setExpandIndex={setExpandIndex}
                    disabledByInternal={disabledByInternal}
                    onSaveDone={onSaveDone}
                    deleteKey={deleteKey}
                    updateKey={updateKey}
                    updateKeyStatus={updateKeyStatus}
                    defaultHashType={defaultHashType}
                    isEditDisabled={isEditDisabled}
                    fetchApplicationKeys={fetchApplicationKeys}
                    isPortalAdmin={isPortalAdmin}
                  />)
                :<span>{getI18nFormattedMessage('label.application.details.keys.overview.nokeys')}</span>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.paginationContainer}>
            <TablePagination
              id="app-configuration-keys-pagination"
              data-apim-test="app-configuration-keys-pagination"
              page={page}
              count={appKeysTotalElements}
              pageCount={appKeysTotalPages}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={onChangePage}
              onChangePreviousPage={onChangePreviousPage}
              onChangeNextPage={onChangeNextPage}
              labelRowsPerPage={intl.getI18nMessage('label.pagination.rows.per.page')}
            />
          </div>
        </Fragment>
    </div>
  );
};

const mapStateToProps = state => ({
  updateKeyStatus: getUpdateKeyStatus(state),
  deleteKeyStatus: getDeleteKeyStatus(state),
  deleteKeyErrors: getDeleteKeyErrors(state),
});

const mapDispatchToProps = {
  updateKey,
  deleteKey,
};
ConfigurationRaw.propTypes = {
  applicationUuid: string,
  applicationDetails: object,
  fetchApplicationKeys: func,
  appKeys: arrayOf(object),
  appKeysTotalPages: number,
  appKeysTotalElements: number,
  isMultiKeySupport: bool,
  classes: object,
  isAdmin: bool,
  notifyMessages: func,
  appSecretHashingMetadata: object,
  isLocking: bool,
  locked: bool,
  disabledByInternal: bool,
  onSaveDone: func,
  updateKey: func,
  updateKeyStatus: string,
  deleteKey: func,
  isEditDisabled: bool,
  isPortalAdmin: bool,
  deleteKeyStatus: func,
  deleteKeyErrors: arrayOf(object),
};
ConfigurationRaw.displayName = 'Configuration';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ConfigurationRaw);
