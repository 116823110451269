import React, { Fragment } from 'react';
import { map } from 'lodash';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import { Button, Paper, Popper, IconButton } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import PropTypes from 'prop-types';
import theme from './index.theme';
import { hasOrgBoundRole } from '../../../utils';

/* eslint-disable */
const personSVG =
  (<Fragment>
    <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M12 1C18.072 1 23 5.928 23 12C23 14.5948 22.1 16.9807 20.5954 18.8624C20.2412 16.755 18.8459 15.2639 16.3176 14.5263C16.9625 13.6434 17.3431 12.5552 17.3431 11.3781C17.3431 8.42714 14.9509 6.03493 12 6.03493C9.04906 6.03493 6.65685 8.42714 6.65685 11.3781C6.65685 12.5587 7.03974 13.6498 7.6881 14.5341C5.17573 15.276 3.76609 16.7677 3.40639 18.8647C1.90069 16.9827 1 14.5959 1 12C1 5.928 5.928 1 12 1Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6863 20.7334C16.8329 22.1549 14.5145 23 12 23C9.48546 23 7.16711 22.1549 5.3137 20.7334V20.0826C5.3137 18.2146 6.34508 16.2169 12 16.2169C17.7447 16.2169 18.6863 18.2328 18.6863 20.0826V20.7334ZM12 14.7212C10.1536 14.7212 8.65685 13.2245 8.65685 11.3781C8.65685 9.53172 10.1536 8.03494 12 8.03494C13.8464 8.03494 15.3431 9.53172 15.3431 11.3781C15.3431 13.2245 13.8464 14.7212 12 14.7212Z" />
  </Fragment>);
/* eslint-enable */

const collapseSVG = <path d="M12.023 7.04426L19.1319 13.9239C19.6227 14.3988 19.6227 15.1689 19.1319 15.6438C18.6412 16.1187 17.8455 16.1187 17.3547 15.6438L11.9771 10.4397L6.6453 15.5995C6.15453 16.0745 5.35884 16.0745 4.86808 15.5995C4.37731 15.1246 4.37731 14.3546 4.86808 13.8796L11.977 7L12.0229 7.04442L12.023 7.04426Z" />;

const PersonIcon = createSvgIcon(personSVG, 'person');
const CollapseIcon = createSvgIcon(collapseSVG, 'collapse');

const renderAccessibleOrgs = (userAccessibleOrgs,
  {
    classes,
    userActiveOrgUuid,
    updateUserContexts,
  }) => {
  const menuItems = [];
  menuItems.push(map(userAccessibleOrgs, (accessibleOrg) =>
    <MenuItem
      className={classes.UserMenuContent__AccessibleOrgWrapper}
      key={accessibleOrg.uuid}
      onClick={() => updateUserContexts({ orgUuid: accessibleOrg.uuid })}
      data-apim-test={accessibleOrg.uuid}
    >
      <Button
        color="secondary"
        classes={{
          root: classes.UserMenuContent__AccessibleOrgs,
          label: classes.UserMenuContent__AccessibleOrgs_label,
        }}
        onClick={() => updateUserContexts({ orgUuid: accessibleOrg.uuid })}
        title={accessibleOrg.name}
      >
        {accessibleOrg.name}
      </Button>
      {userActiveOrgUuid === accessibleOrg.uuid && (
        <div>
          <Check className={classes.UserMenuContent__Check} />
        </div>
      )}
    </MenuItem>,
  ));
  return menuItems;
};

const renderUser = ({
  classes,
  userAccessibleOrgs,
  userActiveOrgUuid,
  userDetails,
  updateUserContexts,
}) => {
  const menuItems = [];
  menuItems.push(
    <MenuItem
      key={'userName'}
      className={clsx(classes.UserMenuContent__Item, classes.UserMenuContent__Name)}
      disabled
      data-apim-test="user-name"
    >
      {userDetails.username}
    </MenuItem>,
  );
  const myProfileHandler = () => {
    window.location.href = `https://${window.location.hostname}/admin/user-profile`;
  };
  menuItems.push(
    <MenuItem
      className={classes.UserMenuContent__Item}
      key={'myProfile'}
      onClick={myProfileHandler}
      tabIndex={0}
      data-apim-test="my-profile"
    >
      <Button
        href={`https://${window.location.hostname}/admin/user-profile`}
        tabIndex={-1}
      >
        My Profile
      </Button>
    </MenuItem>,
  );
  if (hasOrgBoundRole(userDetails)) {
    menuItems.push(
      renderAccessibleOrgs(userAccessibleOrgs,
        { classes, userActiveOrgUuid, updateUserContexts },
      ),
    );
  }
  const logOutHandler = () => {
    localStorage.clear();
    window.location.href = `//${window.location.hostname}/admin/logout`;
  };
  menuItems.push(
    <MenuItem
      className={classes.UserMenuContent__Item}
      key={'logout'}
      onClick={logOutHandler}
      tabIndex={0}
      data-apim-test="logout"
    >
      <Button
        onClick={logOutHandler}
      >
        Logout
      </Button>
    </MenuItem>,
  );
  return menuItems;
};

function User({
  classes = {},
  userAccessibleOrgs,
  userActiveOrgUuid,
  userDetails,
  updateUserContexts,
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event) => {
    if ((event.key === 'Tab') || (event.key === 'Escape')) {
      event.preventDefault();
      anchorEl.focus();
      setAnchorEl(null);
    }
  };

  const handleBtnKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setAnchorEl(null);
    }
  };
  return (
    <div className={classes.UserMenuButton}>
      <IconButton
        onKeyDown={handleBtnKeyDown}
        color="secondary"
        onClick={handleClick}
        data-apim-test="user-menu"
      >
        {!anchorEl ?
          <PersonIcon className={classes.PersonIcon} />
          :
          <CollapseIcon className={classes.PersonIcon} />
        }
      </IconButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        role={undefined}
        transition
        className={classes.Popper}
        disablePortal
        placement={'bottom-end'}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              classes={{
                root: classes.MenuPaper,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  classes={{
                    root: classes.Menu,
                  }}
                  onKeyDown={handleListKeyDown}
                >
                  {renderUser({
                    classes,
                    userAccessibleOrgs,
                    userActiveOrgUuid,
                    userDetails,
                    updateUserContexts,
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

User.propTypes = {
  classes: PropTypes.shape({}),
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.shape({})),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.shape({}),
  updateUserContexts: PropTypes.func,
};

renderUser.propTypes = {
  classes: PropTypes.shape({}),
  userAccessibleOrgs: PropTypes.arrayOf(PropTypes.shape({})),
  userActiveOrgUuid: PropTypes.string,
  userDetails: PropTypes.shape({}),
  updateUserContexts: PropTypes.func,
};

export default withStyles(theme)(User);
