import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ color, typography, fontSize }) => ({
  appDeploymentsContainer: {
    padding: '0 36px',
    '& table': {
      backgroundColor: get(color, 'uiBackground'),
    },
    '& tr': {
      borderTop: `1px solid ${get(color, 'borderColor')}`,
    },
    '& th': {
      color: get(color, 'bodyText'),
      padding: 2,
      verticalAlign: 'middle',
      paddingLeft: 12,
      cursor: 'pointer',
      border: 'none',
      '&:first-child' : {
        color: get(color, 'primaryButtonBackground'),
      },
    },
    '& td': {
      padding: 2,
      paddingLeft: 12,
      border: 'none',
    },
  },
  actionsColumnTitle: {
    display: 'inline-flex',
  },
  tableContainer: {
    overflowY:'hidden',
  },
  innerTableHead: {
    '& th': {
        cursor: 'default',
        color: get(color, 'bodyText'),
    },
  },
  button: {
    color: get(color, 'primaryButtonBackground'),
    '&.Mui-disabled' : {
      border: 'none !important',
      backgroundColor: `${get(color, 'uiBackground')} !important`,
    },
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  innerTable: {
    paddingTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 16,
    backgroundColor: convertHexToRGB(get(color, 'borderColor'), 0.3),
  },
  expandCollapseButton: {
    color: get(color, 'primaryButtonBackground'),
    float: 'right',
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  deploymentCard: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  card: {
    margin: 5,
    marginLeft: 0,
    minHeight: 160,
    backgroundColor: get(color, 'uiBackground'),
  },
  cardTitle: {
    fontSize: get(fontSize, 'smallTitle'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '5px 0px',
  },
  cardType: {
    color: get(color, 'bodyText'),
    marginTop: 5,
    marginBottom: 5,
    verticalAlign: 'text-bottom',
    '& span': {
      verticalAlign: 'bottom',
      color: get(color, 'accentDark'),
      fontFamily: get(typography, 'bodyText'),
      margin: 'auto 0',
    },
  },
  cardStatus: {
    color: get(color, 'bodyText'),
    marginTop: 5,
    marginBottom: 5,
  },
  cardStatusIcon: {
    fontSize: 12,
  },
  cardLastUpdated: {
    color: get(color, 'smallText'),
    marginBottom: 10,
  },
  helpIcon: {
    fontSize: 12,
    margin: 4,
    verticalAlign: 'inherit',
    cursor: 'pointer',
    color: `${get(color, 'link')} !important`,
  },
  helpTextContainer: {
    '& ui': {
      padding: 0,
      listStyleType: 'none',
    },
  },
  status: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '15px',
    verticalAlign: 'middle',
    letterSpacing: 0,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      marginTop: '-6px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
    },
  },
  enabled: {
    '&:before': {
      backgroundColor: get(color, 'success'),
    },
  },
  disabled: {
    '&:before': {
      backgroundColor: get(color, 'disabledBackground'),
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkWrapper: {
    '& a': {
      cursor: 'pointer',
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      height: '0px !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textDecoration: 'none',
    },
  },
  actionLink: {
    margin: 10,
  },
  deploymentButtonsContainer: {
    margin: 5,
    '& button:first-of-type': {
      paddingLeft: '10px !important',
    },
    '& button': {
      border: 'none !important',
      margin: '0px !important',
      padding: '0px !important',
      paddingRight: '10px !important',
      height: '0px !important',
      lineHeight: '1 !important',
      fontWeight: '300 !important',
      color: `${get(color, 'link')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      verticalAlign: 'initial',
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:hover': {
      color: `${get(color, 'linkHover')} !important`,
      textTransform: 'none',
      textDecoration: 'none',
    },
    '& button:focus': {
      boxShadow: 'none !important',
      outline: '0px !important',
      textTransform: 'none',
      textDecoration: 'none',
    },
  },
  checkStatusContainer: {
    margin: -5,
  },
  checkStatusButtonContainer: {
    display: 'block',
  },
  emptyResults: {
    marginLeft: 10,
    marginRight: 10,
  },
  tableHead: {
    '& th': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold !important',
      padding: 12,
      cursor: 'auto',
    },
  },
  paginationContainer: {
    margin: '0 !important',
    '& .MuiTablePagination-toolbar': {
      margin: '0 !important',
    },
  },
  defaultChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'bodyText'), 0.05)} !important`,
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  enabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'success'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  disabledChip: {
    backgroundColor: `${convertHexToRGB(get(color, 'error'))} !important`,
    color: `${get(color, 'white')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    margin: '0 10px',
    height: '24px',
  },
  editKeyButton: {
    color: `${get(color, 'grayDark')} !important`,
    textTransform: 'none',
    float: 'right',
  },
  lockIcon: {
    fill: convertHexToRGB(get(color, 'bodyText'), 0.6),
    marginLeft: 5,
  },
});

export default styles;
