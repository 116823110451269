import React from 'react';
import {
  Checkbox, FormHelperText, FormLabel, MenuItem, NativeSelect, Select, makeStyles,
} from '@material-ui/core';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';
import clsx from 'clsx';

import styles from './styles';

export default function FormSelect(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    fieldContainerClass,
    id,
    name,
    data = [],
    helperText,
    handleChange,
    error = false,
    defaultText,
    value,
    selectFieldClass = null,
    noNoneOption = false,
    noNativeSelect = false,
    optional,
    warning,
    disabled,
    hideLabel,
    multiple,
    renderValue,
    displayEmpty,
  } = props;

  const rootSelectClass = warning ?
    clsx(classes.selectField, classes.warningSelectField) : classes.selectField;

  return (
    <div
      className={clsx(fieldContainerClass, classes.fieldContainer)}
      id={id} data-apim-test={id} data-layer7-test={id}
    >
      <div className={classes.formLabelContainer}>
        <FormLabel className={hideLabel && classes.hideFormLabel}>{name}</FormLabel>
        {optional &&
          <FormLabel className={classes.optional}>Optional</FormLabel>
        }
      </div>
      {noNativeSelect ?
        <Select
          className={clsx(selectFieldClass, rootSelectClass)}
          error={error}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          displayEmpty={displayEmpty}
          renderValue={renderValue}
          disabled={disabled}
          inputProps={{
            disabled,
          }}
          multiple={multiple}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!noNoneOption &&
            <MenuItem value={'0'}>
              {defaultText || 'none'}
            </MenuItem>
          }
          {data && data.map(obj =>
            <MenuItem key={obj.uuid} value={obj.uuid}>
              {multiple &&
                <Checkbox className={classes.checkbox} checked={value.includes(obj.uuid)} />
              }
              {obj.name || obj.text}
            </MenuItem>,
          )}
        </Select>
        :
        <NativeSelect
          className={clsx(selectFieldClass, rootSelectClass)}
          error={error}
          onChange={(e) => handleChange(e)}
          value={value}
          disabled={disabled}
          inputProps={{
            disabled,
          }}
        >
          {!noNoneOption && <option value={'0'}>{defaultText || 'none'}</option>}
          {data && data.map(obj =>
            <option key={obj.uuid} value={obj.uuid}>{obj.name || obj.text}</option>,
          )}
        </NativeSelect>}
      <FormHelperText
        className={error ? classes.errorHelperText : classes.helperText}
        error={error}
      >
        {helperText}
      </FormHelperText>
    </div>
  );
}

FormSelect.propTypes = {
  fieldContainerClass: string,
  id: string,
  name: string,
  data: arrayOf(shape({})),
  helperText: string,
  handleChange: func,
  error: bool,
  defaultText: string,
  value: oneOfType([string, arrayOf(string)]),
  noNoneOption: bool,
  noNativeSelect: bool,
  selectFieldClass: string,
  optional: bool,
  warning: bool,
  disabled: bool,
  hideLabel: bool,
  multiple: bool,
  renderValue: func,
  displayEmpty: bool,
};
