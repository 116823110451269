import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'connected-react-router';
import { arrayOf, object, bool, number, func, string } from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  fetchApplicationKeys,
  fetchSelectedApiGroups,
  fetchSingleApp,
  saveApplicationDetails,
  updateApplicationDetails,
  fetchCustomFields,
  fetchAppCustomFieldValues,
  updateApplicationCustomFields,
  deleteSingleApplication,
  checkAppNameUnique,
  fetchAppAssignedAPIs,
  fetchAppAssignedAPIGroups,
  createKey,
  updateKey,
  deleteKey,
  publishApplication,
  fetchAppRequestStatus,
} from '../../../actions/application';
import {
  getSingleApplication,
  getKeysResults,
  getKeysTotalPages,
  getKeysTotalElements,
  isAddApplicationSuccess,
  isUpdateApplicationSuccess,
  getCustomFields,
  getAppCustomFieldValues,
  getIsUpdateApplicationCustomFieldsSuccess,
  getIsUpdateApplicationCustomFieldsError,
  getIsAppNameUnique,
  getAppAssignedAPIs,
  getAppAssignedAPIGroups,
  getSingleApplicationErrors,
  getCreateKeyStatus,
  getUpdateKeyStatus,
  getDeleteKeyStatus,
  getPublishResult,
  getAppRequestStatus,
} from '../../../reducers/application';
import {
  fetchRequestSettings,
} from "../../../actions/requestSettings";
import {
  fetchAppSecretHashingMetadata,
} from '../../../actions/portalConfig';
import {
  getUserDetails,
  getFeatureFlagApiPlans,
  getFeatureFlags,
  getAppSecretHashingMetadata,
} from '../../../reducers/portalConfig';
import {
  getRequestSettings,
} from '../../../reducers/requestSettings';
import {
  hasOrgBoundRole,
  hasPublisherRole,
  isOnlyDeveloper,
  hasAdminRole,
 } from '../../../utils';

import {
  AlertMessages,
  TabsContainer,
  TabPanel,
  Footer,
} from '../../../components';

import Header from './Header';

import styles from './styles';
import Overview from './Overview';
import { isApplicationDirty,
  checkCustomFieldsDirty,
  validateAppDetails,
  validateCustomFields,
} from './utils';
import AppAPIManagement from './APIManagement';
import { ALERT_ERROR, ALERT_SUCCESS, APPLICATION_STATUS_DISABLED, APPLICATION_STATUS_EDIT_PENDING_APPROVAL, APPLICATION_STATUS_ENABLED, APPLICATION_STATUS_INCOMPLETE, APPLICATION_STATUS_REJECTED } from '../../../constants';
import AppAPIGroupManagement from './APIGroupManagement';
import APPAPIProductManagement from './APIProductManagement';
import Deployments from './Deployments';
import Configuration from './Configuration';
import { useIntl } from 'react-intl';
import ApplicationFooter from './Footer';
import map from 'lodash/map';
import { getHashParams } from '../List';

export const getApplicationUuid = (props) => get(props, 'match.path')
  && get(props, 'match.path').includes('/details/')
  && get(props, 'match.params.applicationUuid');

const isAddFlow = () => window.location.href.endsWith('admin/console/applications/add');
export const ApplicationDetails = (props) => {
  const {
    classes,
    user,
    singleApplication,
    addApplicationSuccess,
    updateApplicationSuccess,
    customFields,
    customFieldValues,
    checkAppNameUnique,
    isAppNameUnique,
    isUpdateAppCustomFieldsSuccess,
    assignedAPIs,
    isUpdateApplicationCustomFieldsError,
    apiPlansEnabled,
    assignedAPIGroups,
    appGetErrors,
    appKeys,
    appKeysTotalElements,
    appKeysTotalPages,
    featureFlags,
    createKeyStatus,
    updateKeyStatus,
    deleteKeyStatus,
    appSecretHashingMetadata,
    publishResult,
    requestSettings,
    appRequestStatus = {},
  } = props;
  const [notificationMessage, setNotificationMessage] = useState(localStorage.getItem('notificationMessage'));
  const [notificationStatus, setNotificationStatus] = useState(localStorage.getItem('notificationStatus'));
  const [appDetailsObject, setAppDetailsObject] = useState({});
  const [isSaveClicked, setSaveClicked] = useState(false);
  const [customFieldValuesObjArr, setCustomFieldValuesObjArr] = useState([]);
  const [customFieldValuesBaseMap, setCustomFieldValuesBaseMap] = useState({});
  const [deleteAndFetchApp, setDeleteAndFetchApp] = useState(false);
  const [isCustomFieldsFormDirty, setCustomFieldsFormDirty]= useState(false);
  const [apisArray, setApisArray] = useState([]);
  const [isApisTabDirty, setIsApisTabDirty] = useState(false);
  const [isApiGroupsTabDirty, setIsApiGroupsTabDirty] = useState(false);
  const [isAdmin, setIsAdmin]=useState(false);
  const [canEditName, setCanEditName] = useState(true);
  const intl = getI18n(useIntl());
  const notifyMessages = (message, status, timeout) => {
    setNotificationStatus(status);
    localStorage.setItem('notificationStatus', status);
    setNotificationMessage(message);
    localStorage.setItem('notificationMessage', message);
    if(timeout) {
      setTimeout(() => {
        notifyMessages('', '');
      }, 3000);
    }
  };
  const setCustomFieldValues = (customFieldsMap) => {
    setCustomFieldsFormDirty(checkCustomFieldsDirty(customFieldValuesBaseMap, customFieldsMap));
    setCustomFieldValuesObjArr(Object.keys(customFieldsMap).map(key => {
      return { customFieldUuid: key, value: customFieldsMap[key] };
    }));
  };
  const appUuid = getApplicationUuid(props);
  useEffect(() => {
    if (appUuid) {
      props.fetchSingleApp(appUuid);
      props.fetchApplicationKeys(appUuid);
      props.fetchSelectedApiGroups(appUuid);
      props.fetchAppCustomFieldValues(appUuid);
      props.fetchAppAssignedAPIs(appUuid);
      props.fetchAppRequestStatus(appUuid);
      if(!apiPlansEnabled) {
        props.fetchAppAssignedAPIGroups(appUuid);
      }
    }
    props.fetchAppSecretHashingMetadata();
    props.fetchCustomFields();
    props.fetchRequestSettings();
    setTimeout(() => {
      notifyMessages('', '');
    }, 2500);
  }, []);
  useEffect(() => {
    if(user) {
      setIsAdmin(hasPublisherRole(user));
    }
  },[user]);
  useEffect(() => {
    if (appUuid) {
      if (updateKeyStatus === 'SUCCESS' || deleteKeyStatus === 'SUCCESS' || createKeyStatus === 'SUCCESS') {
        props.fetchApplicationKeys(appUuid);
      }
    }
  },[createKeyStatus, deleteKeyStatus, updateKeyStatus])
  useEffect(() => {
    if(appGetErrors) {
      if(deleteAndFetchApp) {
        props.push('/admin/console/applications');
      } else {
        props.push('/404');
      }
    }
  },[appGetErrors]);
  useEffect(() => {
    if(customFieldValues) {
      setCustomFieldValuesObjArr(JSON.parse(JSON.stringify(customFieldValues)));
      const map={};
      customFieldValues.forEach(customField => map[customField.customFieldUuid]=customField.value);
      setCustomFieldValuesBaseMap(map);
    }
  },[customFieldValues]);
  useEffect(() => {
    if(assignedAPIs && assignedAPIs.length > 0) {
      setApisArray(assignedAPIs.map(api => api.uuid));
    }
    const hashParam = getHashParams(props);
    if(hashParam === "#api-management") {
      setCurrentTab('app-apis-tab');
      setCanEditName(false);
    }
  }, [assignedAPIs]);
  useEffect(() => {
    if(singleApplication && singleApplication.uuid) {
      setAppDetailsObject(JSON.parse(JSON.stringify(singleApplication)));
    }
  }, [singleApplication]);
  useEffect(() => {
    setSaveClicked(false);
    if(addApplicationSuccess) {
      setAppDetailsObject(JSON.parse(JSON.stringify(singleApplication)));
      notifyMessages(
        intl.getI18nMessage('label.application.details.overview.application.save.success'),
        ALERT_SUCCESS);
      if(!isCustomFieldsFormDirty) {
        props.push(
          `/admin/console/applications/details/${singleApplication.uuid}`);
      } else {
        props.updateApplicationCustomFields(singleApplication.uuid, customFieldValuesObjArr);
      }
    }
  },[addApplicationSuccess]);
  useEffect(() => {
    setSaveClicked(false);
    if(isUpdateApplicationCustomFieldsError) {
      notifyMessages(
        intl.getI18nMessage('label.application.details.overview.customfields.save.failed'),
        ALERT_ERROR, true);
    }
  },[isUpdateApplicationCustomFieldsError])
  useEffect(() => {
    setSaveClicked(false);
    if(isUpdateAppCustomFieldsSuccess) {
      notifyMessages(
        intl.getI18nMessage(`label.application.details.overview.application.${isLocking() ? 'submit' : 'save'}.success`),
        ALERT_SUCCESS, true);
        props.fetchCustomFields(appUuid);
      if(isAddFlow()) {
        props.push(`/admin/console/applications/details/${singleApplication.uuid}`);
      } else {
        onSaveDone();
      }
      setCustomFieldsFormDirty(false);
    }
  }, [isUpdateAppCustomFieldsSuccess]);
  useEffect(() => {
    setSaveClicked(false);
    if(updateApplicationSuccess) {
      props.fetchSingleApp(singleApplication.uuid);
      props.fetchApplicationKeys(singleApplication.uuid);
      notifyMessages(
        intl.getI18nMessage(`label.application.details.overview.application.${isLocking() ? 'save' : 'submit'}.success`),
        ALERT_SUCCESS, true);
      onSaveDone();
    }
  },[updateApplicationSuccess]);
  useEffect(() => {
    if(publishResult && publishResult.result) {
      if(publishResult.result === 'SUCCESS') {
        notifyMessages(
          intl.getI18nMessage('label.application.details.overview.application.publish.success'),
          ALERT_SUCCESS, true);
          props.fetchSingleApp(appUuid);
      } else if(publishResult.result === 'FAIL') {
        notifyMessages(
          intl.getI18nMessage('label.application.details.overview.application.publish.fail',
          { error: publishResult.errors && publishResult.errors.length>0 ?publishResult.errors[0].error: '' }),
          ALERT_ERROR, true);
      }
    }
  }, [publishResult]);
  const [currentTab, setCurrentTab] = useState('app-overview-tab');
  const handleChange = (event) => {
    setCanEditName(event.currentTarget.id === 'app-overview-tab');
    setCurrentTab(event.currentTarget.id);
  };

  const validateNameUniqueness = (appName, appUuid, orgUuid) => {
    if(appName !== '' && (hasOrgBoundRole(user) || ( orgUuid && orgUuid !== ''))) {
      checkAppNameUnique(appName, appUuid, orgUuid);
    }
  };
  const publish = () => {
    props.publishApplication(appUuid);
  }
  const isOverviewTabDirty = () =>
    isApplicationDirty(singleApplication, appDetailsObject) || isCustomFieldsFormDirty;
  const onSave =() => {
    setSaveClicked(true);
    if(currentTab === 'app-overview-tab') {
      if(isAddFlow()) {
        const cfValid = validateCustomFields(customFields, geCustomFieldValuesMap());
        if(isApplicationDirty(singleApplication, appDetailsObject)
            && validateAppDetails(appDetailsObject)
            && (!customFields || customFields.length === 0 || cfValid)) {
            props.saveApplicationDetails(appDetailsObject);
        }
      } else {
        if(isApplicationDirty(singleApplication, appDetailsObject)
            && validateAppDetails(appDetailsObject)) {
          props.updateApplicationDetails(appDetailsObject);
        }
        if(checkCustomFieldsDirty(customFieldValuesBaseMap, geCustomFieldValuesMap())
          && validateCustomFields(customFields, geCustomFieldValuesMap())) {
          props.updateApplicationCustomFields(appDetailsObject.uuid, customFieldValuesObjArr);
        }
      }
    }
  };
  const onSaveDone = () => {
    props.fetchSingleApp(appUuid);
    props.fetchAppRequestStatus(appUuid);
    if(currentTab === 'app-apis-tab') {
      setIsApisTabDirty(false);
    } else if (currentTab === 'app-api-groups-tab') {
      setIsApiGroupsTabDirty(false);
    }
  }
  const geCustomFieldValuesMap = () => {
    const map={};
    customFieldValuesObjArr.forEach(customField =>
      map[customField.customFieldUuid]=customField.value);
    return map;
  };
  const onDelete = () => {
    props.deleteSingleApplication(appDetailsObject.uuid);
  };
  const changeStatus = (flag) => {
    const updateObject = {
      ...singleApplication,
      status: flag ? 'ENABLED' : 'DISABLED',
    };
    setAppDetailsObject(updateObject);
    props.updateApplicationDetails(updateObject);
  }
  const isLocking =() =>
    !isAdmin &&
    singleApplication &&
    requestSettings &&
    requestSettings.editApplicationRequestWorkflowStatus === 'ENABLED' &&
      (singleApplication.status === APPLICATION_STATUS_ENABLED ||
       singleApplication.status === APPLICATION_STATUS_DISABLED ||
       singleApplication.status === APPLICATION_STATUS_EDIT_PENDING_APPROVAL);
  const featureFlagMultiApiKeys = JSON.parse(get(featureFlags, 'useMultiApiKeys.Value'));
  const featureFlagAPIProduct = JSON.parse(get(featureFlags, 'product.Value'));
  const isMultiKeySupport = featureFlagMultiApiKeys && appKeys.length > 0;
  const tabItems = [
    {
      tabId: 'app-overview-tab',
      label: getI18nFormattedMessage('label.application.details.overview.title'),
      visible: true,
    },
    {
      tabId: 'app-apis-tab',
      label: getI18nFormattedMessage('label.application.details.apis.title'),
      visible: true,
      disabled: isAddFlow(),
    },
    {
      tabId: 'app-api-groups-tab',
      label: getI18nFormattedMessage('label.application.details.apigroups.title'),
      visible: !apiPlansEnabled,
      disabled: isAddFlow(),
    },
    {
      tabId: 'app-api-products-tab',
      label: getI18nFormattedMessage('label.application.details.apiproducts.title'),
      visible: featureFlagAPIProduct && hasAdminRole(user),
      disabled: isAddFlow(),
    },
    {
      tabId: 'app-key-overview-tab',
      label: getI18nFormattedMessage('label.application.details.keyoverview.title'),
      visible: true,
      disabled: isAddFlow(),
    },
    {
      tabId: 'app-key-deployment-tab',
      label: getI18nFormattedMessage('label.application.details.deployments.title'),
      visible: isAdmin,
      disabled: isAddFlow() || singleApplication.status === 'INCOMPLETE',
    }];
  map(tabItems, (item, idx) => (item.id = idx));
  return (
    <div
      className={classes.content}
      id="application-details-page" data-layer7-test="application-details-page"
    >
      {notificationMessage &&
        <AlertMessages
          id="app-details-notification"
          variant={notificationStatus}
          message={notificationMessage}
          containerClass={classes.notificationWidthClass}
          onClose={() => { notifyMessages('', ''); }}
        />
      }
      <Header
        applicationDetails={appDetailsObject}
        setAppDetails={setAppDetailsObject}
        isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
        isAddFlow={isAddFlow()}
        changeStatus={changeStatus}
        isSaveClicked={isSaveClicked}
        onDelete={onDelete}
        validateNameUniqueness={validateNameUniqueness}
        isAppNameUnique={isAppNameUnique}
        keyTabs={hasPublisherRole(user)&&
           (currentTab === 'app-key-overview-tab' ||
           currentTab === 'app-key-deployment-tab')}
        isDeleteWorkflowEnabled={requestSettings &&
          requestSettings.deleteApplicationRequestWorkflowStatus === 'ENABLED'}
        notifyMessages={notifyMessages}
        isAdmin={hasPublisherRole(user)}
        isPortalAdmin={hasAdminRole(user)}
        isOnlyDev={isOnlyDeveloper(user)}
        canEditName={canEditName}
        locked={appRequestStatus['APPLICATION_DETAILS']}
        isLocking={isLocking()}
        fetchSingleApp={props.fetchSingleApp}
        setDeleteAndFetchApp={setDeleteAndFetchApp}
        fetchAppRequestStatus={props.fetchAppRequestStatus}
      />
      <Grid container className={classes.gridContainer}>
        <TabsContainer
          id="app-details-tab-container"
          tabValue={currentTab}
          orientation="horizontal"
          tabItems={tabItems.filter(({ visible }) => visible)}
          handleTabChange={handleChange}
          tabActiveClass={classes.tabActive}
          tabIndicatorClass={classes.tabIndicator}
          useTabId
        />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.bodyContent}>
        <TabPanel visible={currentTab === 'app-overview-tab'} id="app-overview-tab">
          <Overview
            appDetailsObj={appDetailsObject}
            setAppDetails={setAppDetailsObject}
            isOrgBoundUser={hasOrgBoundRole(user)}
            customFields={customFields}
            customFieldValues={geCustomFieldValuesMap()}
            setCustomFieldValues={setCustomFieldValues}
            isSaveClicked={isSaveClicked}
            validateNameUniqueness={validateNameUniqueness}
            setActiveTab={setCurrentTab}
            apiCount={assignedAPIs ? assignedAPIs.length : 0}
            apiGroupsCount={assignedAPIGroups ? assignedAPIGroups.length : 0}
            keysCount={appKeysTotalElements}
            isApiPlansEnabled={apiPlansEnabled}
            locked={appRequestStatus['APPLICATION_DETAILS']}
            customFieldsLocked={appRequestStatus['APPLICATION_CUSTOM_FIELDS']}
            isLocking={isLocking()}
            disabledByInternal={singleApplication.disabledByType === "INTERNAL"}
            disableEntityScrollBar={isAddFlow()}
            isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
          />
        </TabPanel>
        <TabPanel visible={currentTab === 'app-apis-tab'} id="app-apis-tab">
          {appUuid &&
            <AppAPIManagement
              assignedAPIs={apisArray}
              updateAssignedAPIs={setApisArray}
              applicationDetails={singleApplication}
              fetchAssignedAPIs={props.fetchAppAssignedAPIs}
              apiPlansEnabled={apiPlansEnabled}
              locked={appRequestStatus[apiPlansEnabled ? 'APPLICATION_API_PLANS': 'APPLICATION_APIS']}
              setApisTabDirty={setIsApisTabDirty}
              isSaveClicked={currentTab === 'app-apis-tab' && isSaveClicked}
              setSaveClicked={setSaveClicked}
              isLocking={isLocking()}
              onSaveDone={onSaveDone}
              disabledByInternal={singleApplication.disabledByType === "INTERNAL"}
              isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
            />
          }
        </TabPanel>
        <TabPanel visible={currentTab === 'app-api-groups-tab'} id="app-api-groups-tab">
          {appUuid &&
            <AppAPIGroupManagement
            applicationDetails={singleApplication}
            fetchAppAssignedAPIGroups={props.fetchAppAssignedAPIGroups}
            assignedAPIGroups={assignedAPIGroups}
            isAdmin={hasPublisherRole(user)}
            locked={appRequestStatus['APPLICATION_API_GROUPS']}
            setApiGroupsTabDirty={setIsApiGroupsTabDirty}
            isLocking={isLocking()}
            isSaveClicked={currentTab === 'app-api-groups-tab' && isSaveClicked}
            setSaveClicked={setSaveClicked}
            onSaveDone={onSaveDone}
            disabledByInternal={singleApplication.disabledByType === "INTERNAL"}
            isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
            />
          }
        </TabPanel>
        <TabPanel visible={currentTab === 'app-api-products-tab'} id="app-api-products-tab">
          {appUuid &&
            <APPAPIProductManagement
              {...props}
              currentTab={currentTab}
              applicationDetails={singleApplication}
              isAdmin={hasPublisherRole(user)}
              locked={false}
              setApiGroupsTabDirty={setIsApiGroupsTabDirty}
              isLocking={isLocking()}
              isSaveClicked={currentTab === 'app-api-products-tab' && isSaveClicked}
              setSaveClicked={setSaveClicked}
              onSaveDone={onSaveDone}
              setActiveTab={setCurrentTab}
              disabledByInternal={singleApplication.disabledByType === "INTERNAL"}
              visible={featureFlagAPIProduct && hasAdminRole(user)}
              isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
            />
          }
        </TabPanel>
        <TabPanel visible={currentTab === 'app-key-overview-tab'} id="app-key-overview-tab">
          <Configuration
            applicationUuid={appUuid}
            applicationDetails={singleApplication}
            fetchApplicationKeys={props.fetchApplicationKeys}
            appKeys={appKeys}
            appKeysTotalPages={appKeysTotalPages}
            appKeysTotalElements={appKeysTotalElements}
            isMultiKeySupport={isMultiKeySupport}
            notifyMessages={notifyMessages}
            isAdmin={hasPublisherRole(user)}
            isPortalAdmin={hasAdminRole(user)}
            createKey={props.createKey}
            deleteKey={props.deleteKey}
            updateKey={props.updateKey}
            createKeyStatus={createKeyStatus}
            updateKeyStatus={updateKeyStatus}
            appSecretHashingMetadata={appSecretHashingMetadata}
            locked={appRequestStatus['APPLICATION_API_KEYS']}
            isLocking={isLocking()}
            onSaveDone={onSaveDone}
            disabledByInternal={singleApplication.disabledByType === "INTERNAL"}
            isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
          />
        </TabPanel>
        <TabPanel visible={currentTab === 'app-key-deployment-tab'} id="app-key-deployment-tab">
          <Deployments
            applicationUuid={appUuid}
            applicationDetails={singleApplication}
            appKeys={appKeys}
            appKeysTotalPages={appKeysTotalPages}
            appKeysTotalElements={appKeysTotalElements}
            isMultiKeySupport={isMultiKeySupport}
            history={history}
            isAdmin={hasPublisherRole(user)}
            fetchApplicationKeys={props.fetchApplicationKeys}
            isEditDisabled={singleApplication && singleApplication.status === 'DELETE_FAILED'}
          />
        </TabPanel>
      </Grid>
      { ((singleApplication.status === APPLICATION_STATUS_INCOMPLETE &&
        currentTab !== 'app-api-products-tab') ||
        (singleApplication.status === APPLICATION_STATUS_REJECTED &&
        currentTab !== 'app-api-products-tab') ||
        (currentTab === 'app-overview-tab' && isOverviewTabDirty()) ||
        (currentTab === 'app-apis-tab' && isApisTabDirty) ||
        (currentTab === 'app-api-groups-tab' && isApiGroupsTabDirty)) &&
        <ApplicationFooter
          className={classes.appFooter}
          onSave={onSave}
          onPublish={publish}
          onCancel={() => isAddFlow() ? props.push('/admin/console/applications') : location.reload()}
          showPublish={singleApplication.status === APPLICATION_STATUS_INCOMPLETE ||
            singleApplication.status === APPLICATION_STATUS_REJECTED}
          saveText={intl.getI18nMessage(`label.application.details.footer.${isLocking() ? 'submit': 'save'}.button`)}
          publishText={intl.getI18nMessage(`label.application.details.footer.publish.${!hasPublisherRole(user) && requestSettings && requestSettings.applicationRequestWorkflowStatus === 'ENABLED'? 'approval.': ''}button`)}
          showSave={ (currentTab === 'app-overview-tab' && isOverviewTabDirty()) ||
                      (currentTab === 'app-apis-tab' && isApisTabDirty) ||
                      (currentTab === 'app-api-groups-tab' && isApiGroupsTabDirty)}
        />
      }
      <Footer />
    </div>
  );
};
const mapStateToProps = state => ({
  apiPlansEnabled: getFeatureFlagApiPlans(state),
  singleApplication: getSingleApplication(state),
  appKeys: getKeysResults(state),
  appKeysTotalPages: getKeysTotalPages(state),
  appKeysTotalElements: getKeysTotalElements(state),
  user: getUserDetails(state),
  addApplicationSuccess: isAddApplicationSuccess(state),
  updateApplicationSuccess: isUpdateApplicationSuccess(state),
  customFields: getCustomFields(state),
  customFieldValues: getAppCustomFieldValues(state),
  isUpdateAppCustomFieldsSuccess: getIsUpdateApplicationCustomFieldsSuccess(state),
  isAppNameUnique: getIsAppNameUnique(state),
  assignedAPIs: getAppAssignedAPIs(state),
  isUpdateApplicationCustomFieldsError: getIsUpdateApplicationCustomFieldsError(state),
  assignedAPIGroups: getAppAssignedAPIGroups(state),
  appGetErrors: getSingleApplicationErrors(state),
  featureFlags: getFeatureFlags(state),
  createKeyStatus: getCreateKeyStatus(state),
  updateKeyStatus: getUpdateKeyStatus(state),
  deleteKeyStatus: getDeleteKeyStatus(state),
  appSecretHashingMetadata: getAppSecretHashingMetadata(state),
  publishResult: getPublishResult(state),
  requestSettings: getRequestSettings(state),
  appRequestStatus: getAppRequestStatus(state),
});

const mapDispatchToProps = {
  push,
  fetchSingleApp,
  fetchApplicationKeys,
  fetchSelectedApiGroups,
  saveApplicationDetails,
  updateApplicationDetails,
  fetchCustomFields,
  fetchAppCustomFieldValues,
  updateApplicationCustomFields,
  deleteSingleApplication,
  checkAppNameUnique,
  fetchAppAssignedAPIs,
  fetchAppAssignedAPIGroups,
  createKey,
  updateKey,
  deleteKey,
  fetchAppSecretHashingMetadata,
  publishApplication,
  fetchRequestSettings,
  fetchAppRequestStatus,
};

ApplicationDetails.propTypes = {
  singleApplication: object,
  fetchApplicationKeys: func,
  appKeys: arrayOf(object),
  appKeysTotalPages: number,
  appKeysTotalElements: number,
  user: object,
  classes: object,
  hideDeployments: func,
  fetchSingleApp: func,
  fetchSelectedApiGroups: func,
  saveApplicationDetails: func,
  isAddApplicationSuccess: func,
  isUpdateApplicationSuccess: func,
  addApplicationSuccess: bool,
  updateApplicationSuccess: bool,
  updateApplicationDetails: func,
  customFields: arrayOf(object),
  customFieldValues: arrayOf(object),
  isUpdateAppCustomFieldsSuccess: bool,
  fetchCustomFields: func,
  fetchAppCustomFieldValues: func,
  updateApplicationCustomFields: func,
  deleteSingleApplication: func,
  checkAppNameUnique: func,
  isAppNameUnique: bool,
  fetchAppAssignedAPIs: func,
  assignedAPIsUpdateResult: bool,
  assignedAPIs: arrayOf(object),
  isUpdateApplicationCustomFieldsError: bool,
  apiPlansEnabled: bool,
  assignedAPIGroups: arrayOf(object),
  fetchAppAssignedAPIGroups: func,
  appGetErrors:arrayOf(object),
  push:func,
  featureFlags:object,
  createKeyStatus: string,
  deleteKeyStatus: string,
  updateKeyStatus: string,
  createKey: func,
  updateKey: func,
  deleteKey: func,
  appSecretHashingMetadata: object,
  fetchAppSecretHashingMetadata: func,
  publishApplication: func,
  publishResult: object,
  requestSettings: object,
  appRequestStatus: object,
  fetchRequestSettings: func,
  fetchAppRequestStatus: func,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationDetails);
