import get from 'lodash/get';
import { convertHexToRGB } from '../../../../utils';

const styles = ({ breakpoint, color, margin, padding, typography, fontSize }) => ({
  textFieldContainer: {
    margin: `${get(margin, 'm0')} !important`,
    marginRight: `${get(margin, 'm8')} !important`,
  },
  pageFilterAndSort: {
    justifyContent: 'space-between',
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0,
  },
  pageClass: {
    margin: 0,
    minHeight: 'calc(100vh - 340px)',
  },
  pageBodyClass: {
    padding: get(padding, 'p0'),
  },
  textFiltersContainer: {
    display: 'flex',
    width: '80%',
    marginTop: 24,
  },
  [`@media (max-width: ${get(breakpoint, 'sm')})`]: {
    textFiltersContainer: {
      display: 'block',
    },
  },
  [`@media (max-width: ${get(breakpoint, 'md')})`]: {
    textFiltersContainer: {
      width: '100%',
    },
  },
  panelContent: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontWeight: 400,
    lineHeight: 1.5,
    height: 300,
    overflowY: 'scroll',
  },
  apiGroupPanel: {
    color: get(color, 'pageTitle'),
  },
  paperClass: {
    width: '40%',
    height: 610,
    maxWidth: 'none',
    '& button:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '1px !important',
      outlineStyle: 'solid !important',
      outlineColor: get(color, 'primaryButtonBackground'),
    },
  },
  accordionTitle: {
    fontWeight: 700,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
  },
  assignToolTip: {
    display: 'inline-flex',
  },
  lockIcon: {
    fill: convertHexToRGB(get(color, 'bodyText'), 0.6),
    marginLeft: 5,
  },
  warningIcon: {
    fill: get(color, 'warning'),
    marginLeft: 5,
  },
  accessField: {
    display: 'flex',
  },
});

export default styles;
