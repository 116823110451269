import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PowerOff from '@material-ui/icons/PowerOff';
import { bool, func, number, shape, string } from 'prop-types';

import { RefreshCounter } from '../../../../../components';
import Table from './table';
import Visualization from './visualization';
import Footer from './footer';
import { getI18n } from '../../../../../utils/intl';
import {
  BULK_DEPLOYMENT_STATUS_RUNNING, PROXY_DISCONNECTED,
} from '../../../../../constants';

export const ToggleSubtotal = ({
 classes = {},
 hideSubtotal,
 setHideSubtotal,
}) => {
  const intl = getI18n(useIntl());
  return (
    <IconButton
      className={classes.hideSubtotal}
      id="hide-subtotal" data-layer7-test="hide-subtotal"
      onClick={() => setHideSubtotal(!hideSubtotal)}
    >
      <Typography variant="body1">
        {hideSubtotal ?
          intl.getI18nMessage('label.proxy.details.api.show.subtotal') :
          intl.getI18nMessage('label.proxy.details.api.hide.subtotal')
        }
      </Typography>
    </IconButton>
  );
}

ToggleSubtotal.propTypes = {
  classes: shape({}),
  hideSubtotal: bool,
  setHideSubtotal: func,
};

export const PaperWithStatus = (props) => {
  const {
    classes = {},
    id,
    title,
    deploymentType,
    proxyStatus,
    bulkDeploymentStatus,
    refreshTime,
    checkStatus,
    errorCount = 0,
    lastUpdated,
    showBreakdown,
    deploymentTypeTooltip,
  } = props;

  const [hideSubtotal, setHideSubtotal] = useState(false);

  const isProxyDisconnected = (proxyStatus === PROXY_DISCONNECTED);
  const isBulkDeploymentStatusRunning =
    (bulkDeploymentStatus === BULK_DEPLOYMENT_STATUS_RUNNING);
  const containerClass = isProxyDisconnected ?
    cx(classes.paper, classes.disconnectedBackground) : classes.paper;

  return (
    <MuiPaper
      square elevation={0}
      className={containerClass}
      id={`${id}-status`} data-layer7-test={`${id}-status`}
    >
      <Grid container>
        <Grid item>
          <Typography
            variant="h2"
            className={classes.title}
            id={`${id}-status-title`} data-layer7-test={`${id}-status-title`}
          >
            {title}
          </Typography>
        </Grid>
        {isProxyDisconnected &&
          <Grid item>
            <IconButton
              className={classes.disconnectedIndicatorIcon}
              data-disconnected
            >
              <PowerOff />
            </IconButton>
          </Grid>
        }
        {!isProxyDisconnected && (errorCount > 0) &&
          <Grid item>
            <IconButton
              className={classes.errorIndicatorIcon}
              data-error
            >
              <WarningIcon />
            </IconButton>
          </Grid>
        }
        {!isProxyDisconnected && isBulkDeploymentStatusRunning &&
          <Grid item>
            <RefreshCounter
              refreshTime={refreshTime}
              checkStatus={checkStatus}
            />
          </Grid>
        }
      </Grid>
      <Visualization
        {...props}
        isProxyDisconnected={isProxyDisconnected}
      />
      <Table
        {...props}
        hideSubtotal={hideSubtotal}
        isProxyDisconnected={isProxyDisconnected}
      />
      {showBreakdown &&
        <ToggleSubtotal
          classes={classes}
          hideSubtotal={hideSubtotal}
          setHideSubtotal={setHideSubtotal}
        />
      }
      <Footer
        id={id}
        deploymentType={deploymentType}
        tooltip={deploymentTypeTooltip}
        lastUpdated={lastUpdated}
      />
    </MuiPaper>
  );
}

PaperWithStatus.propTypes = {
  classes: shape({}),
  id: string,
  title: string,
  deploymentType: string,
  deploymentTypeTooltip: shape({}),
  proxyStatus: string,
  bulkDeploymentStatus: string,
  refreshTime: string,
  checkStatus: func,
  errorCount: number,
  lastUpdated: string,
  showBreakdown: bool,
};
PaperWithStatus.displayName = 'PaperWithStatus';

export default PaperWithStatus;
