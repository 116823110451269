import axios from 'axios';
import qs from 'query-string';
import { get, isArray, isEmpty, map } from 'lodash';
import { stringify } from 'query-string';

import * as Constants from '../../constants';
import { getConfig } from '../../reducers/portalConfig';
import { AXIOS_DEFAULT_OPTIONS, AXIOS_DEFAULT_OPTIONS_HEADERS } from '../';
import { getValidationErrors } from '../../utils';
import { debouncedAxiosGet, stringifyUrl } from '../../utils/actions';
import { getCreateData, getUpdateData, getDeleteData } from './requestParser';
import { checkSession } from '../portalConfig';

const initApplication = (dispatch, isLoading = true) =>
  dispatch({
    type: Constants.APPLICATION_LOADING,
    isLoading,
  });

export const showLoading = isLoading => dispatch => initApplication(dispatch, isLoading);
export const resetApp = () =>
    dispatch => dispatch({ type: Constants.APP_RESET });
export const resetDeleteApplicationStatus = () =>
    dispatch => dispatch({ type: Constants.APPLICATION_DELETE_RESET });

export const fetchApplicationKeys = (appUuid, page = 0, size = 10) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys?page=${page}&size=${size}`;

      await axios.get(url, AXIOS_DEFAULT_OPTIONS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_ALL_KEYS_GET_SUCCESS,
            payload: get(response, 'data'),
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_ALL_KEYS_GET_ERROR,
            payload: getValidationErrors(error),
          }),
        );
      } else {
        window.location.href =
          `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };

export const fetchProxies = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/internal/proxies?status=ACTIVE`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.PROXIES_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.PROXIES_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchProxiesByApiKey = (apikey) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apikey}/proxies`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.API_KEY_PROXIES_GET_SUCCESS,
          payload: {
            key: apikey,
            proxies: response.data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.API_KEY_PROXIES_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

const getDeploymentValidationErrors = (error) => get(error, 'validationErrors');
const processDeploymentErrors = (validationErrors, defaultMessage) => {
  let errorMessage = defaultMessage;
  const keys = Object.keys(validationErrors);
  if (keys.length > 0) {
    errorMessage = map(keys, (key) => validationErrors[key].devMessage).join('\n');
  }
  return errorMessage;
};

export const deployApiKeyToProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = { proxyUuid: uuid };
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies`;

      await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.API_KEY_DEPLOY_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          const statusCode = get(error, 'response.status');
          if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
            window.location.href =
              `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
          }

          const responseError = get(error, 'response.data');
          const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
            ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
            : get(error, 'message');
          dispatch({
            type: Constants.API_KEY_DEPLOY_ERROR,
            payload: {
              apiKey,
              proxyUuid: uuid,
              errorMessage: strErrorMessage,
            },
          });
        });
      } else {
        window.location.href =
          `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };

export const redeployApiKeyToProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = {
      status: 'PENDING_DEPLOYMENT',
    };
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies/${uuid}`;
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.API_KEY_REDEPLOY_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          const statusCode = get(error, 'response.status');
          if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
            window.location.href =
              `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
          }

          const responseError = get(error, 'response.data');
          const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
            ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
            : get(error, 'message');
          dispatch({
            type: Constants.API_KEY_REDEPLOY_ERROR,
            payload: {
              apiKey,
              proxyUuid: uuid,
              errorMessage: strErrorMessage,
            },
          });
        });
      } else {
        window.location.href =
          `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };

export const undeployApiKeyFromProxy = (apiKey, uuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/deployments/1.0/api-keys/${apiKey}/proxies/${uuid}`;
      await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
        .then(() => {
          dispatch({
            type: Constants.API_KEY_UNDEPLOY_SUCCESS,
            payload: null,
          });
        })
        .catch((error) => {
          const statusCode = get(error, 'response.status');
          if (statusCode === Constants.HTTP_STATUS_UNAUTHORIZED) {
            window.location.href =
              `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
          }

          const responseError = get(error, 'response.data');
          const strErrorMessage = (statusCode === Constants.HTTP_STATUS_BAD_REQUEST)
            ? processDeploymentErrors(getDeploymentValidationErrors(responseError), '')
            : get(error, 'message');
          dispatch({
            type: Constants.API_KEY_UNDEPLOY_ERROR,
            payload: {
              apiKey,
              proxyUuid: uuid,
              errorMessage: strErrorMessage,
            },
          });
        });
      } else {
        window.location.href =
          `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };


export const fetchAllApis = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/2.0/Apis`;
    
    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_ALL_APIS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllApps = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/Applications`;

    await axios.get(url, { credentials: 'include' })
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_ALL_APPS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
export const fetchApplications = ({
  name = null,
  orgName = null,
  apiGroupUuid = null,
  apiUuid = null,
  apiPlanUuid = null,
  status = Constants.ANY,
  page = 0,
  size = 12,
}, isDev) => async (dispatch, getState) => {
  const config = getConfig(getState());
  const { portal } = config;
  const allStatusList = isDev ? undefined :
  'ENABLED,DISABLED,INCOMPLETE,APPLICATION_PENDING_APPROVAL,EDIT_APPLICATION_PENDING_APPROVAL,DELETE_APPLICATION_PENDING_APPROVAL,DELETE_FAILED';
  
  const params = stringify(
    {
      name: !isEmpty(name) ? name : undefined,
      orgName: !isEmpty(orgName) ? orgName : undefined,
      apiGroupUuid: !isEmpty(apiGroupUuid) ? apiGroupUuid : undefined,
      apiUuid: !isEmpty(apiUuid) ? apiUuid : undefined,
      apiPlanUuid: !isEmpty(apiPlanUuid) ? apiPlanUuid : undefined,
      //status should be set to undefined when there is no filter,
      //setting this workaround as backend is restricting
      //INCOMPLETE applications when no filter is used.
      status: status !== Constants.ANY ? status : allStatusList,
      page,
      size,
    },
    true,
  );
  const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications?${params}`;
  dispatch({
    type: Constants.APPLICATION_LIST_REQUEST,
  });
  await axios
    .get(url, { credentials: "include" })
    .then((response) => {
      dispatch({
        type: Constants.APPLICATION_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) =>
      dispatch({
        type: Constants.APPLICATION_LIST_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

 export const fetchSingleApplication = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/Applications('${applicationUuid}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SINGLE_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApplicationStatus = (applicationDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_STATUS_UPDATE_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);

    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;
    await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_STATUS_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_STATUS_UPDATE_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllApiPlans = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-plans`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_API_PLANS_GET_SUCCESS,
          payload: get(response, 'data.results', []),
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApplication = (applicationUuid) =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/Applications('${applicationUuid}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const createApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getCreateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_CREATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateAndGetApplication = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const updateUrl = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;
    const fetchUrl = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await Promise.all([
        await axios.put(updateUrl, data, AXIOS_DEFAULT_OPTIONS_HEADERS),
        await axios.get(fetchUrl, AXIOS_DEFAULT_OPTIONS),
      ])
        .then(responseArr => {
          dispatch({
            type: Constants.APPLICATION_UPDATE_AND_GET_SUCCESS,
            isLoading: false,
            payload: {
              ...responseArr[0].data,
              ...responseArr[1].data,
            },
          });
        })
        .catch(error =>
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateApplicationSecret = (applicationDetails) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getUpdateData(applicationDetails);
    const url = `${portal.hostname}/admin/Portal.svc/Applications('${applicationDetails.uuid}')`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_SECRET_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const deleteApplication = (applicationDetails, isForceDelete, ignoreProxyCheck) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const data = getDeleteData(applicationDetails);
    const params = qs.stringify({
      forceDelete: isForceDelete ? true : undefined,
      ignoreProxyCheck: ignoreProxyCheck ? true : undefined,
    }, true);

    dispatch({ type: Constants.APPLICATION_DELETE_RESET });
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${applicationDetails.uuid}?${params}`;
      await axios.delete(url, data, { credentials: 'include' })
        .then(() => {
          dispatch({
            type: Constants.APPLICATION_DELETE_SUCCESS,
            payload: null,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_DELETE_ERROR,
            payload: getValidationErrors(error),
          }),
        );
      } else {
        window.location.href =
          `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };

export const deleteSingleApplication = (appUuid, ignoreProxyCheck, forceDelete) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    dispatch({ type: Constants.APPLICATION_DELETE_RESET });
    const url = stringifyUrl({
      url: `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}`,
      query: {
        ...(forceDelete ? { forceDelete } : {}),
        ...(ignoreProxyCheck ? { ignoreProxyCheck } : {}),
      },
      options: {
        encode: true,
        skipNull: true,
        arrayFormat: 'comma',
      },
    });
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.delete(url, AXIOS_DEFAULT_OPTIONS)
        .then(() => {
          dispatch({
            type: Constants.APPLICATION_DELETE_SUCCESS,
            payload: null,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_DELETE_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
      `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const fetchOrganizations = (pageNumber = 0, size = 10) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations?status=ENABLED&size=${size}&page=${pageNumber}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_ORGANIZATIONS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAllOrganizations = () => fetchOrganizations(1000);

export const getApplicationNameUniqueUrl = (applicationName, currentId, organizationUuid) => {
  const name = encodeURIComponent(applicationName);
  if (currentId && organizationUuid) {
    return `Name='${name}'&CurrentId='${currentId}'&OrganizationUuid='${organizationUuid}'`;
  } else if (currentId) {
    return `Name='${name}'&CurrentId='${currentId}'`;
  } else if (organizationUuid) {
    return `Name='${name}'&OrganizationUuid='${organizationUuid}'`;
  }
  return `Name='${name}'`;
};

export const fetchApplicationNameUnique = (applicationName, currentId, organizationUuid) =>
  (dispatch, getState) => {
    // Run Unique check when applicationName is non-null.
    if (!applicationName) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const applicationNameUniqueUrl =
      getApplicationNameUniqueUrl(applicationName, currentId, organizationUuid);
    const url =
      `${portal.hostname}/admin/Portal.svc/ApplicationNameUnique()?${applicationNameUniqueUrl}`;

    debouncedAxiosGet.cancel();
    debouncedAxiosGet(
      url,
      AXIOS_DEFAULT_OPTIONS,
      (response) => dispatch({
        type: Constants.APPLICATION_NAME_UNIQUE_SUCCESS,
        payload: response.data,
      }),
      (error) => dispatch({
        type: Constants.APPLICATION_ERROR,
        payload: getValidationErrors(error),
      }),
    );
  };

export const fetchCustomFields = () =>
  async (dispatch, getState) => {
    initApplication(dispatch);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/CustomFields?%24inlinecount=allpages&%24filter=EntityType+eq+%27APPLICATION%27+and+Status+eq+%27ENABLED%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_CUSTOM_FIELDS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchSelectedApis = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/ApplicationApiXrefs?%24inlinecount=allpages&%24filter=ApplicationUuid+eq+%27${applicationUuid}%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SELECTED_APIS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          isLoading: false,
          payload: getValidationErrors(error),
        }),
      );
  };

const fetchQueryString = (params) => {
  let queryString = qs.stringify(params, true);
  queryString = queryString ? `?${queryString}` : '';
  return queryString;
};

export const fetchAvailableApis = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/apis${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_APIS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableApps = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_APPS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApplicationsLegacy = () =>
  async (dispatch, getState) => {
    const DC_UUID_EXCLUDE_FILTER = 'Uuid ne \'2c824a55-cb6d-471e-bd3c-08b46309d0ee\'';
    const params = {
      filter: DC_UUID_EXCLUDE_FILTER,
    };
    const queryString = fetchQueryString(params);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/Applications${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.API_LIST_APPLICATIONS_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableOrgs = (params) =>
  async (dispatch, getState) => {
    const defaultParams = {
      size: 10,
      page: 0,
      portalStatus: 'ENABLED',
    };
    const { tags: tagsParam, searchText: searchParam, ...otherParams } = params;
    const updatedParams = {
      ...defaultParams,
      ...otherParams,
      ...(isArray(tagsParam) && (tagsParam.length > 0) && ({ tags: tagsParam.join(',') })),
      ...(searchParam && ({ name: searchParam })),
    };
    const queryString = fetchQueryString(updatedParams);
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/tenant-admin/internal/organizations${queryString}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_ORGS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page: updatedParams.page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiEula = (eulaUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/eulas/${eulaUuid}`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.get(url, AXIOS_DEFAULT_OPTIONS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_API_EULA_GET_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const fetchSelectedApiGroups = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/applications/ApplicationApiGroupXrefs?%24inlinecount=allpages&%24filter=ApplicationUuid+eq+%27${applicationUuid}%27`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SELECTED_API_GROUPS_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchAvailableApiGroups = ({ orgUuid, size = 10, page = 0, searchText = '' }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/OrganizationApiGroups?orgUuid=${orgUuid}&skipStatusCheck=true&size=${size}&page=${page}&name=${searchText}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_API_GROUPS_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
          page,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchApiGroupEulas = (apiGroupUuid, organizationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/api-management/internal/ApiGroups/${apiGroupUuid}/Eulas?%24filter=OrganizationUuid+eq+%27${organizationUuid}%27`;
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.get(url, AXIOS_DEFAULT_OPTIONS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_API_GROUP_EULAS_GET_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const generateSecret = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/Portal.svc/GenerateNewSharedSecret`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_GENERATE_SECRET_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const resetSecret = () =>
  (dispatch) => {
    dispatch({
      type: Constants.APPLICATION_GENERATE_SECRET_GET_SUCCESS,
      isLoading: false,
      payload: { d: { result: null } },
    });
  };

export const fetchApiPlanDetails = (uuid) =>
  async (dispatch, getState) => {
    if (!uuid) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/api-plans/${uuid}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.FETCH_API_PLAN_SUCCESS,
          isLoading: false,
          payload:data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
export const fetchApiGroupDetails = (uuid) =>
  async (dispatch, getState) => {
    if (!uuid) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/ApiGroups('${uuid}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.FETCH_API_GROUP_SUCCESS,
          isLoading: false,
          payload:data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };
export const fetchAvailableApiPlans = (orgUuid, apiUuid) =>
  async (dispatch, getState) => {
    if (!(orgUuid && apiUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/admin/api-management/internal/api-plans?orgUuid=${orgUuid}&apiUuid=${apiUuid}&size=10&page=0`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_AVAILABLE_API_PLANS_GET_SUCCESS,
          isLoading: false,
          payload: {
            [apiUuid]: data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const fetchTenantInfos = () =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/admin/Portal.svc/TenantInfos('${portal.tenantPrefix}')`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.TENANT_INFO_GET_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const getKeyNameUniqueUrl = (appUuid, keyName, apiKey) => {
  const name = encodeURIComponent(keyName);
  if (apiKey) {
    return `${appUuid}/api-keys/isNameUnique?name=${name}&apiKey=${apiKey}`;
  }
  return `${appUuid}/api-keys/isNameUnique?name=${name}`;
};

export const fetchKeyNameUnique = (keyName, apiKey, appUuid) =>
  async (dispatch, getState) => {
    // Run Unique check when applicationName is non-null.
    if (!keyName) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const keyNameUniqueUrl =
      getKeyNameUniqueUrl(appUuid, keyName, apiKey);
    const url =
      `${portal.hostname}/admin/api-management/internal/applications/${keyNameUniqueUrl}`;

    debouncedAxiosGet.cancel();
    debouncedAxiosGet(
      url,
      AXIOS_DEFAULT_OPTIONS,
      (response) => dispatch({
        type: Constants.KEY_NAME_UNIQUE_SUCCESS,
        payload: get(response, 'data.isUnique'),
      }),
      (error) => dispatch({
        type: Constants.KEY_NAME_UNIQUE_ERROR,
        payload: getValidationErrors(error),
      }),
    );
  };

export const createKey = (keyDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const data = {
      name: get(keyDetails, 'name'),
      applicationUuid: appUuid,
      oauthCallbackUrl: get(keyDetails, 'oauthCallbackUrl', ''),
      oauthScope: get(keyDetails, 'oauthScope', ''),
      oauthType: get(keyDetails, 'oauthType', null),
      keySecretHashed: get(keyDetails, 'keySecretHashed', false),
      defaultKey: get(keyDetails, 'defaultKey', false),
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.post(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.KEY_CREATE_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.KEY_CREATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const updateKey = (keyDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const apiKey = get(keyDetails, 'apiKey');
    const data = {
      name: get(keyDetails, 'name'),
      applicationUuid: appUuid,
      oauthCallbackUrl: get(keyDetails, 'oauthCallbackUrl', ''),
      oauthScope: get(keyDetails, 'oauthScope', ''),
      oauthType: get(keyDetails, 'oauthType', null),
      status: get(keyDetails, 'status'),
      keySecretHashed: get(keyDetails, 'keySecretHashed', false),
      keySecret: get(keyDetails, 'keySecret', undefined),
      defaultKey: get(keyDetails, 'defaultKey', false),
    };
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys/${apiKey}`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.KEY_UPDATE_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.KEY_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

export const deleteKey = ({ keyDetails, isForceDelete, ignoreProxyCheck }) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const appUuid = get(keyDetails, 'appUuid');
    const apiKey = get(keyDetails, 'apiKey');

    dispatch({
      type: Constants.KEY_STATUS_RESET,
      payload: '',
    });

    const params = qs.stringify({
      forceDelete: isForceDelete ? true : undefined,
      ignoreProxyCheck: ignoreProxyCheck ? true : undefined,
    }, true);

    dispatch({
      type: Constants.KEY_DELETE_STATUS_RESET,
      payload: '',
    });
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-keys/${apiKey}?${params}`;
      await axios.delete(url, { credentials: 'include' })
        .then(() => {
          dispatch({
            type: Constants.KEY_DELETE_SUCCESS,
            payload: null,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.KEY_DELETE_ERROR,
            payload: getValidationErrors(error),
          });
        });
      } else {
        window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
      }
  };

  export const fetchSingleApp = (applicationUuid) =>
  async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${applicationUuid}`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_SINGLE_GET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_SINGLE_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const saveApplicationDetails = (applicationDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_GRANULAR_CREATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.post(url, applicationDetails, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_GRANULAR_CREATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_GRANULAR_CREATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  export const updateApplicationDetails = (applicationDetails) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_GRANULAR_UPDATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${applicationDetails.uuid}`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, applicationDetails, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_GRANULAR_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_GRANULAR_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  export const publishApplication = (uuid) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_PUBLISH_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${uuid}/publish`;
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, null, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_PUBLISH_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_PUBLISH_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  export const publishApplicationForProducts = async(uuid, portal, dispatch) => {
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${uuid}/publish`;
    await axios.put(url, null, AXIOS_DEFAULT_OPTIONS_HEADERS)
      .then((response) => {
        dispatch({
          type: Constants.APPLICATION_PUBLISH_SUCCESS,
          isLoading: false,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.APPLICATION_PUBLISH_ERROR,
          payload: getValidationErrors(error),
        });
      });
  };
  
export const checkAppNameUnique = (appName, uuid, orgUuid) =>
async (dispatch, getState) => {
  dispatch({
    type: Constants.APPLICATION_NAME_UNIQUE_REQUEST,
  });
  const config = getConfig(getState());
  const { portal } = config;
  let url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/unique-name?name=${appName}`;
  if(orgUuid) {
    url = `${url}&organizationUuid=${orgUuid}`
  }
  if (uuid) {
    url = `${url}&uuid=${uuid}`;
  }
  await axios.get(url, AXIOS_DEFAULT_OPTIONS)
    .then((response) => {
      dispatch({
        type: Constants.APPLICATION_NAME_UNIQUE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) =>
      dispatch({
        type: Constants.APPLICATION_NAME_UNIQUE_ERROR,
        payload: getValidationErrors(error),
      }),
    );
};

export const fetchAppCustomFieldValues = (appUuid) =>
  async (dispatch, getState) => {
    if (!(appUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/custom-fields`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_CUSTOM_FIELD_VALUES_GET_SUCCESS,
          isLoading: false,
          payload:  data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_CUSTOM_FIELD_VALUES_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateApplicationCustomFields= (appUuid, customFields) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/custom-fields`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, customFields, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_CUSTOM_FIELDS_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  export const fetchAppAssignedAPIs = (appUuid) =>
  async (dispatch, getState) => {
    if (!(appUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/apis`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_APIS_GET_SUCCESS,
          isLoading: false,
          payload:  data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_APIS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const updateAppAssignedAPIs= (appUuid, apis) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_ASSIGNED_APIS_UPDATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/apis`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, apis, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_APIS_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_APIS_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  
  export const fetchAppAssignedAPIGroups = (appUuid) =>
  async (dispatch, getState) => {
    if (!(appUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-groups`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_API_GROUPS_GET_SUCCESS,
          isLoading: false,
          payload:  data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_API_GROUPS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

export const updateAppAssignedAPIGroups= (appUuid, apiGroups) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/api-groups`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, apiGroups, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_API_GROUPS_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };


  export const fetchAppAssignedApiPlans = (appUuid) =>
  async (dispatch, getState) => {
    if (!(appUuid)) { return; }

    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/applications/${appUuid}/api-plans`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_API_PLANS_GET_SUCCESS,
          isLoading: false,
          payload:  data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_ASSIGNED_API_PLANS_GET_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const updateAppAssignedApiPlans= (appUuid, apiPlans) =>
  async (dispatch, getState) => {
    dispatch({
      type: Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/applications/${appUuid}/api-plans`;

    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.put(url, apiPlans, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_SUCCESS,
            isLoading: false,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_ASSIGNED_API_PLANS_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  };

  export const fetchAppRequestStatus = (appUuid) =>
  async (dispatch, getState) => {
    if (!(appUuid)) { return; }
    dispatch({
      type: Constants.APPLICATION_GET_REQUEST_STATUS_REQUEST,
      isLoading: true,
    });
    const config = getConfig(getState());
    const { portal } = config;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/1.0/applications/${appUuid}/request-status`;

    await axios.get(url, AXIOS_DEFAULT_OPTIONS)
      .then(({ data }) => {
        dispatch({
          type: Constants.APPLICATION_GET_REQUEST_STATUS_SUCCESS,
          isLoading: false,
          payload:  data,
        });
      })
      .catch((error) =>
        dispatch({
          type: Constants.APPLICATION_GET_REQUEST_STATUS_ERROR,
          payload: getValidationErrors(error),
        }),
      );
  };

  export const fetchApplicationAPIProducts =
  ({ appUuid, filterByName, filterByTier, hasAccess, page, rowsPerPage }) =>
    async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const params = stringify({
      ...(filterByName && { productName: filterByName }),
      ...(filterByTier && { tierName: filterByTier }),
      productAppAccessStatus : hasAccess ? 'ASSIGNED' : 'NOT_ASSIGNED',
      page,
      size: rowsPerPage,
    }, true);
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      dispatch({
        type: Constants.APPLICATION_AVAILABLE_API_PRODUCTS_GET_REQUEST,
      });
      const url =
        `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/access/products-application/${appUuid}?${params}`;

      await axios.get(url, AXIOS_DEFAULT_OPTIONS)
        .then(({ data }) => {
          dispatch({
            type: Constants.APPLICATION_AVAILABLE_API_PRODUCTS_GET_SUCCESS,
            isLoading: false,
            payload: data,
          });
        })
        .catch((error) =>
          dispatch({
            type: Constants.APPLICATION_ERROR,
            payload: getValidationErrors(error),
          }),
        );
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  }

  export const updateApplicationAPIProductsAccess =
  ({ appUuid, action, data, hasAppPublish }) => async (dispatch, getState) => {
    const config = getConfig(getState());
    const { portal } = config;
    const url =
      `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/applications/${appUuid}/products?action=${action}`;
    dispatch({
      type: Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_REQUEST,
    });
    const isValidSession = await checkSession(getState());
    if (isValidSession) {
      await axios.patch(url, data, AXIOS_DEFAULT_OPTIONS_HEADERS)
        .then((response) => {
          if(hasAppPublish) {
            publishApplicationForProducts(appUuid, portal, dispatch);
          } else {
            dispatch({
              type: Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_SUCCESS,
              isLoading: false,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: Constants.APPLICATION_API_PRODUCTS_ACCESS_UPDATE_ERROR,
            payload: getValidationErrors(error),
          });
        });
    } else {
      window.location.href =
        `${portal.hostname}/admin/login?to-default-config=true&referrer=${window.location.pathname}`;
    }
  }
