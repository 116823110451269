import { get } from 'lodash';

const styles = ({ color }) => ({
  content: {
    minHeight: 0,
    marginBottom: 0,
  },
  notificationContainer: {
    width: 'auto !important',
    left: '35% !important',
    backgroundColor: get(color, 'white'),
    color: get(color, 'black'),
    fontWeight: 800,
  },
});

export default styles;
