import { get } from 'lodash';
import { convertHexToRGB } from '../../../../utils';
const styles = ({ color }) => ({
  gridContainer: {
    display: 'inline-flex',
    width: '100%',
    padding: '0 36px',
  },
  customFields: {
    marginTop: 24,
  },
  autoComplete :{
    width: '99%',
    color: 'red',
  },
  selectField: {
    width: '99%',
  },
  h2Container: {
    display: 'inline-flex',
  },
  lockIcon: {
    fill: convertHexToRGB(get(color, 'bodyText'), 0.6),
    marginTop: 30,
    marginLeft: 5,
  },
  selectFieldClass: {
    '&:focus-within': {
      outline: '1px solid',
      outlineColor: get(color, 'primaryButtonBackground'),
    },
  },
  warningIcon: {
    fill: get(color, 'warning'),
    marginTop: 32,
    marginLeft: 5,
  },
});

export default styles;
