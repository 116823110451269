import React, { Fragment, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button, Grid, Link, withStyles } from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from '@material-ui/icons/Check';
import { useIntl } from 'react-intl';
import { cloneDeep, difference, findIndex, each, keys, map, get } from 'lodash';

import { AlertDialog, FormActionButtons, Switch, Table, ToolTip } from '../../../../components';
import {
  FilterByName,
  FilterByType,
} from './controls';
import styles from './styles';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_EXTRA_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24,
  APPLICATION_STATUS_INCOMPLETE,
  APPLICATION_STATUS_REJECTED,
 } from '../../../../constants';
import { getI18n } from '../../../../utils/intl';
import {
  fetchTiersProduct,
} from '../../../../actions/products'
import {
  fetchApplicationAPIProducts,
  updateApplicationAPIProductsAccess,
} from '../../../../actions/application';
import {
  fetchOrgProducts,
} from '../../../../actions/organization';
import {
  fetchTiers,
} from '../../../../actions/tier';
import {
  getIsLoading,
  getAppAPIProducts,
  getAppAPIProductsTotalPages,
  getAppAPIProductsTotalElements,
  getProductAccessSaveStatus,
} from '../../../../reducers/application/apiProductsReducer';
import {
  getIsTiersProductLoading,
  getTiersProduct,
} from '../../../../reducers/products';
import {
  getOrgProducts,
} from '../../../../reducers/organization';
import {
  getTiers,
} from '../../../../reducers/tier';

const CHANGE_MODE_NO_CHANGE = 'CHANGE_MODE_NO_CHANGE';
const CHANGE_MODE_TIER_CHANGE = 'CHANGE_MODE_TIER_CHANGE';
const CHANGE_MODE_REMOVE_ACCESS = 'CHANGE_MODE_REMOVE_ACCESS';
const CHANGE_MODE_ADD_ACCESS = 'CHANGE_MODE_ADD_ACCESS';
const ASSIGNED = 'ASSIGNED';
const NOT_ASSIGNED = 'NOT_ASSIGNED';

export const APIProductManagement = (props) => {
  const {
    applicationDetails,
    classes,
    fetchList,
    fetchTiers,
    fetchTiersProduct,
    productAccessSaveStatus,
    isAPIProductTiersLoading,
    isLoading,
    productTiers = [],
    rows,
    tiers,
    totalElements,
    totalPages,
    updateAccess,
    publishResult,
    setActiveTab,
    currentTab,
    orgProducts,
    visible,
    isEditDisabled,
  } = props;
  const { getI18nMessage, getI18nFormattedMessage } = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24);
  const [notificationStatus, setNotificationStatus] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByTier, setFilterByTier] = useState('');
  const [accessFilter, setAccessFilter] = useState(true);
  const [showTierSelectionDialog, setShowTierSelectionDialog] = useState(false);
  const [editedProductID, setEditedProductID] = useState('');
  const [showFormActionBtns, setShowFormActionBtns] = useState(false);
  const [changeMode, setChangeMode] = useState(CHANGE_MODE_NO_CHANGE);
  const [currentProductsRows, setCurrentProductRows] = useState([]);
  const [modifiedRecords, setModifiedRecords] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [hasAppPublish, setHasAppPublish] = useState(false);
  const [productTiersMap, setProductTiersMap] = useState({});
  const [orgProductTierMap, setOrgProductTierMap] = useState({});
  const emptyFunction = () => {};
  const { uuid: appUuid } = applicationDetails;

  useEffect(() => {
    if (appUuid && visible) {
      fetchListWithFilters();
      fetchTiers();
      props.fetchOrgProducts(applicationDetails.organizationUuid);
    }
  }, [appUuid]);

  useEffect(() => {
    if(currentTab === 'app-api-products-tab' && visible) {
      setChangeMode(CHANGE_MODE_NO_CHANGE);
      setPage(0);
      setAccessFilter(true);
      fetchListWithFilters({
        hasAccess: true,
        pageNum: 0,
      });
    }
  }, [currentTab]);

  useEffect(() => {
    if(orgProducts) {
      const tempMap = {};
      orgProducts.forEach(orgProd => tempMap[orgProd.productUuid] = orgProd.tierUuid);
      setOrgProductTierMap(tempMap);
    }
  }, [orgProducts]);

  const getListColumns = ({ getI18nMessage }) => [{
    id: 'productName',
    label: getI18nMessage('label.application.details.apiproducts.list.product.title'),
    minWidth: 800,
    format: ({ productName, uuid }) => (<Link underline="none" href={`/admin/products/details/${uuid}`}
          disabled={isEditDisabled}>
      {productName}
    </Link>),
    value: ({ productUuid, productName }) => (
      { uuid: productUuid, productName }
    ),
  },{
    id: 'productAppAccessStatus',
    label: getI18nMessage('label.application.details.apiproducts.list.access.title'),
    minWidth: 50,
    // eslint-disable-next-line react/prop-types
    format: ({ productAppAccessStatus, uuid }) =>
      (<Switch
        onChange={(val) => onAccessToggle(val, uuid)}
        checked={productAppAccessStatus === ASSIGNED}
        disabled={(changeMode === CHANGE_MODE_TIER_CHANGE || isEditDisabled)}
      />
    ),
    value: ({ productUuid, productAppAccessStatus }) => (
        { uuid: productUuid, productAppAccessStatus }
      ),
  },{
    id: 'tierName',
    label: (<ToolTip
      label={getI18nFormattedMessage('label.application.details.apiproducts.list.tier.title')}
      info={<div>{getI18nMessage('label.application.details.apiproducts.list.tier.tool.tip')}</div>}
    />),
    minWidth: 50,
    // eslint-disable-next-line react/prop-types
    format: ({ hasAccess, tierName, uuid, tierUuid }) => {
      if (!hasAccess) {
        return (<span />);
      }
      return (<Fragment>
        <Button
          onClick={() => { showTierSelection(uuid) }}
          color="primary"
          disabled={(changeMode === CHANGE_MODE_REMOVE_ACCESS || isEditDisabled)}
        >
          {tierName}
        </Button>
        {orgProductTierMap[uuid] === tierUuid && <span className={classes.tooltipContainer}>
          {getI18nMessage('label.default')}
        </span>}
      </Fragment>
      );
    },
    value: ({ productAppAccessStatus, productUuid, tierName, tierUuid }) =>
    ({
      hasAccess: productAppAccessStatus === ASSIGNED,
      uuid: productUuid,
      tierName,
      tierUuid,
    }),
  }];

  useEffect(() => {
    const productAccessSaveStatusResult = productAccessSaveStatus && productAccessSaveStatus.result;
    if (currentTab === 'app-api-products-tab') {
      if ((productAccessSaveStatusResult === 'SUCCESS') || (publishResult && publishResult.result === 'SUCCESS')) {
        setNotificationStatus('success');
        setNotificationMessage(getI18nMessage('label.application.details.apiproducts.save.success'))
        setChangeMode(CHANGE_MODE_NO_CHANGE);
        setPage(0);
        setAccessFilter(true);
        fetchListWithFilters({
          hasAccess: true,
          pageNum: 0,
        });
        setTimeout(() => {
          setNotificationStatus('');
          setNotificationMessage('');
        }, 2500);
      } else if(publishResult && publishResult.result === 'FAIL') {
        setChangeMode(CHANGE_MODE_NO_CHANGE);
        setPage(0);
        setAccessFilter(true);
        fetchListWithFilters({
          hasAccess: true,
          pageNum: 0,
        });
      } else if(productAccessSaveStatusResult === 'FAIL') {
        let errorMsg = '';
        const errors = get(productAccessSaveStatus, 'response', []);
        errors.forEach(item => {
          errorMsg += item.error;
        });
        if (!errorMsg) {
          errorMsg = getI18nMessage('label.application.details.apiproducts.save.error.unknown');
        }
        setNotificationStatus('error');
        setNotificationMessage(errorMsg);
        setTimeout(() => {
          setNotificationStatus('');
          setNotificationMessage('');
        }, 2500);
      }
    }
  }, [productAccessSaveStatus, publishResult]);

  useEffect(() => {
    if(productTiers && (productTiers.length > 0)) {
      const productUuid = productTiers[0].productUuid;
      setProductTiersMap({ ...productTiersMap, [productUuid]: productTiers });
      const productRow = currentProductsRows.find(item => item.productUuid === productUuid);
      if (productRow) {
          // Assign default org-product tier if there
          // is no tier associated with the product.
          // Retaining earlier assoicated tier if available instead of
          // assoicating always assigning default org-product tier,
          // to avoid dirtying the form if user removes a product and adds it back.
        if (!productRow.tierUuid || !productRow.tierName) {
          let productTier;
          // fix for DE557402
          // we might run into this scenario if user associates the selected product
          // with org using another tab
          // if we don't have an entry in org product, just use first tier in available tiers
          if (orgProductTierMap[productUuid]) {
            productTier = productTiers.find(tier =>
              tier.tierUuid === orgProductTierMap[productUuid],
            );
          } else {
            productTier = productTiers[0];
          }
          updateCurrentProductRow(productUuid, true, productTier.tierUuid, productTier.tierName);
        }
      }
    }
  }, [productTiers]);
  
  useEffect(() => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS) || isFormDirty) {
      setShowFormActionBtns(true);
    } else if(!hasAppPublish) {
      setShowFormActionBtns(false);
    }
  }, [changeMode, isFormDirty]);

  useEffect(() => {
    if(applicationDetails.status === APPLICATION_STATUS_INCOMPLETE ||
      applicationDetails.status === APPLICATION_STATUS_REJECTED) {
        setHasAppPublish(true);
        setShowFormActionBtns(true);
      } else {
        setHasAppPublish(false);
      }
  }, [applicationDetails])

  useEffect(() => {
    if(rows) {
      setCurrentProductRows(cloneDeep(rows));
    }
  }, [rows]);

  const onAccessToggle = (val, uuid) => {
    if (val) {
      updateCurrentProductRow(uuid, true);
      if (changeMode === CHANGE_MODE_ADD_ACCESS) {
        setEditedProductID(uuid)
      }
      if (!productTiersMap[uuid]) {
        // if tiers of this product are unavailable,
        // tier is on received product tiers
        fetchTiersProduct(uuid, 'ASSIGNED');
      } else {
        // if tiers of this product are available,
        // assign default org-product tier if there
        // is no tier associated with the product.
        // Retaining earlier assoicated tier if available instead of
        // assoicating always assigning default org-product tier,
        // to avoid dirtying the form if user removes a product and adds it back.
        const productRow = currentProductsRows.find(item => item.productUuid === uuid);
        if (!productRow.tierUuid || !productRow.tierName) {
          const productTier =
            productTiersMap[uuid].find(tier => tier.tierUuid === orgProductTierMap[uuid]);
          updateCurrentProductRow(uuid, true, productTier.tierUuid, productTier.tierName);
        }
      }
    } else {
      updateCurrentProductRow(uuid, false);
    }
  };

  useEffect(() => {
    const rowsMap = {};
    each(rows, item => {
      rowsMap[item.productUuid] = item;
    });
    const currentProductsRowsMap = {};
    each(currentProductsRows, item => {
      currentProductsRowsMap[item.productUuid] = item;
    });
    const rowIds = keys(rowsMap);
    const currentProductsRowsIds = keys(currentProductsRowsMap);
    if ( difference(rowIds, currentProductsRowsIds).length !== 0) {
      return ;
    }
    let modifiedRecords = 0;
    if (changeMode === CHANGE_MODE_ADD_ACCESS) {
      each(rowIds, id => {
        if (currentProductsRowsMap[id].productAppAccessStatus === ASSIGNED) {
          modifiedRecords++;
        }
      });
    } else if (changeMode === CHANGE_MODE_NO_CHANGE) {
      let removedRecords = 0;
      each(rowIds, id => {
        if (currentProductsRowsMap[id].productAppAccessStatus === NOT_ASSIGNED) {
          removedRecords++;
        }
      });
      let tierChangedRecords = 0;
      each(rowIds, id => {
        if (rowsMap[id].tierUuid !== currentProductsRowsMap[id].tierUuid) {
          tierChangedRecords++;
        }
      });
      if (removedRecords) {
        modifiedRecords = removedRecords;
        setChangeMode(CHANGE_MODE_REMOVE_ACCESS)
      } else if (tierChangedRecords) {
        modifiedRecords = tierChangedRecords;
        setChangeMode(CHANGE_MODE_TIER_CHANGE);
      }
    } else if (changeMode === CHANGE_MODE_REMOVE_ACCESS) {
      each(rowIds, id => {
        if (currentProductsRowsMap[id].productAppAccessStatus === NOT_ASSIGNED) {
          modifiedRecords++;
        }
      });
      if (modifiedRecords === 0) {
        setChangeMode(CHANGE_MODE_NO_CHANGE);
      }
    } else if (changeMode === CHANGE_MODE_TIER_CHANGE) {
      each(rowIds, id => {
        if (rowsMap[id].tierUuid !== currentProductsRowsMap[id].tierUuid) {
          modifiedRecords++;
        }
      });
      if (modifiedRecords === 0) {
        setChangeMode(CHANGE_MODE_NO_CHANGE);
      }
    }
    setModifiedRecords(modifiedRecords);
  }, [currentProductsRows]);

  useEffect(() => {
    if (modifiedRecords > 0) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [modifiedRecords]);

  const updateCurrentProductRow = (uuid, access, tierUuid, tierName) => {
    const index = findIndex(currentProductsRows, item => item.productUuid === uuid);
    if (index < 0)
      return;
    currentProductsRows[index].productAppAccessStatus =
      access ? ASSIGNED : NOT_ASSIGNED ;
      if (tierUuid) {
        currentProductsRows[index].tierUuid = tierUuid;
      }
      if (tierName) {
        currentProductsRows[index].tierName = tierName;
      }
    setCurrentProductRows([...currentProductsRows]);
  };

  const showTierSelection = (uuid) => {
    setEditedProductID(uuid);
    if (!productTiersMap[uuid]) {
      fetchTiersProduct(uuid, 'ASSIGNED');
    }
    setShowTierSelectionDialog(true);
  };

  const fetchListWithFilters = ({
    hasAccess = accessFilter,
    name = filterByName,
    pageNum = page,
    rowsPerPageArg = rowsPerPage,
    tier = filterByTier,
  } = { }) => {
    const selectedTierObjIndex = findIndex(tiers, item => item.uuid === tier);
    const tierName = selectedTierObjIndex > -1 ? tiers[selectedTierObjIndex].name : '';
    fetchList({
      appUuid,
      filterByName : name,
      filterByTier : tierName,
      hasAccess,
      page: pageNum,
      size: rowsPerPageArg,
    });
  };
  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      fetchListWithFilters();
    }
  };
  const onFilterByTierChange = (tier) => {
    setFilterByTier(tier);
    setPage(0);
    fetchListWithFilters({ tier });
  };
  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchListWithFilters({ pageNum: newPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchListWithFilters({ pageNum: 0, rowsPerPageArg : newRowsPerPage });
  };

  const addButtonLabel = getI18nMessage('label.application.details.apiproducts.list.add.button');

  const handleAddButton = () => {
    setChangeMode(CHANGE_MODE_ADD_ACCESS);
    setShowFormActionBtns(true);
    setAccessFilter(false);
    fetchListWithFilters({ hasAccess: false });
  };
  const addBtnFunc = accessFilter ? handleAddButton : emptyFunction;

  const onTierClick = (tierUuid, tierName, productUuid) => {
    const index = findIndex(currentProductsRows, item => item.productUuid === productUuid);
    currentProductsRows[index].tierUuid = tierUuid;
    currentProductsRows[index].tierName = tierName;
    setCurrentProductRows([...currentProductsRows]);
    setShowTierSelectionDialog(false);
  };

  const renderProdTiers = () => {
    if(!productTiersMap[editedProductID]) return;
    let assignedProductTiers = productTiersMap[editedProductID].filter(
        tier => tier.productTierAccessStatus !== NOT_ASSIGNED,
      );
    let activeTierUuid = '-1';
    const productIndex = findIndex(currentProductsRows,
      item => item.productUuid === editedProductID);
    if (productIndex > -1) {
      activeTierUuid = currentProductsRows[productIndex].tierUuid;
    }
    assignedProductTiers = map(
        assignedProductTiers, item => ({
          ... item,
          onClick: onTierClick.bind(null,item.tierUuid, item.tierName, item.productUuid),
          selected: item.tierUuid === activeTierUuid,
          classes: { root: classes.tableRow },
        }),
      );
    const rateLabel = 'label.application.details.apiproducts.tiers.list.product.rate.label';
    const emptyRateLabel = 'label.application.details.apiproducts.tiers.list.product.empty.rate.label';
    const quotaLabel = 'label.application.details.apiproducts.tiers.list.product.quota.label';
    const emptyQuotaLabel = 'label.application.details.apiproducts.tiers.list.product.empty.quota.label';
    const getListColumns = ({ getI18nMessage }) => [{
      id: 'tierUuid',
      label: '',
      width: 16,
      format: (tierUuid) => tierUuid === activeTierUuid ? <CheckIcon className={classes.checkIcon}/> : "",
    },{
      id: 'tierName',
      label: getI18nMessage('label.application.details.apiproducts.tiers.list.tier.title'),
      width: 200,
      format: (name) => <span className={classes.tsTierName}>{name}</span>,
    },{
      id: 'rateQuota',
      label: getI18nMessage('label.application.details.apiproducts.tiers.list.product.title'),
      width: 600,
      format: (value = {}) => (<div>
        <div>{value.name}</div>
        <div>
          {value.rateLimit ? getI18nMessage(rateLabel, value) : getI18nMessage(emptyRateLabel)}
        </div>
        <div>
          {(value.quota && value.quotaInterval) ?
            getI18nMessage(quotaLabel, value) : getI18nMessage(emptyQuotaLabel)}
        </div>
      </div>),
    }];

    return (<Table
      columns={getListColumns({ getI18nMessage })}
      rows={assignedProductTiers}
    />);
  };

  const saveUpdatedRows = () => {
    if(hasAppPublish && !isFormDirty) {
      props.publishApplication(appUuid)
    }
    if (changeMode === CHANGE_MODE_REMOVE_ACCESS) {
      const removedAPIProducts = currentProductsRows.filter(
        item => item.productAppAccessStatus === NOT_ASSIGNED,
        );
        const data = map(removedAPIProducts,
            item => ({ productUuid: item.productUuid, tierUuid: item.tierUuid }),
          );
        updateAccess({
          appUuid,
          action: 'remove',
          data,
          hasAppPublish,
        });
    } else if (changeMode === CHANGE_MODE_ADD_ACCESS) {
      const removedAPIProducts = currentProductsRows.filter(
        item => item.productAppAccessStatus === ASSIGNED);
      const data = map(removedAPIProducts,
          item => ({ productUuid: item.productUuid, tierUuid: item.tierUuid }),
        );
      updateAccess({
        appUuid,
        action: 'add',
        data,
        hasAppPublish,
      });
    } else if (changeMode === CHANGE_MODE_TIER_CHANGE) {
      const rowsMap = {};
      each(rows, item => {
        rowsMap[item.productUuid] = item;
      });
      const currentProductsRowsMap = {};
      each(currentProductsRows, item => {
        currentProductsRowsMap[item.productUuid] = item;
      });
      const rowIds = keys(rowsMap);
      const data = [];
      each(rowIds, id => {
        if (rowsMap[id].tierUuid !== currentProductsRowsMap[id].tierUuid) {
          data.push({
            productUuid: currentProductsRowsMap[id].productUuid,
            tierUuid: currentProductsRowsMap[id].tierUuid,
          });
        }
      });
      updateAccess({
        appUuid,
        action: 'add',
        data,
        hasAppPublish,
      });
    }
  };

  const alertDialogTitle = getI18nMessage('label.application.details.apiproducts.alert.dialog.title',
    { name: applicationDetails.name });
  let editedProductName = '';
  if (editedProductID) {
    const productIndex = findIndex(currentProductsRows,
      item => item.productUuid === editedProductID);
    editedProductName = productIndex > -1 ? currentProductsRows[productIndex].productName : '';
  }
  const alertDialogDescription = getI18nMessage('label.application.details.apiproducts.alert.dialog.description',
    { name: editedProductName })
  
  const assistText = hasAppPublish ? getI18nMessage('label.application.details.footer.publish.assist.text1') :
      isFormDirty ? (<span>
      {getI18nMessage('label.application.details.apiproducts.list.save.assist.text', { num: modifiedRecords })}
      <WarningIcon className={classes.warningIcon} />
      {getI18nMessage('label.redepolyment.required')}
    </span>) : '';

  const onCancel = () => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS)) {
      setChangeMode(CHANGE_MODE_NO_CHANGE);
      setPage(0);
      setAccessFilter(true);
      fetchListWithFilters({
        hasAccess: true,
        pageNum: 0,
      });
    } else if (isFormDirty) {
      setPage(0);
      setAccessFilter(true);
      fetchListWithFilters({
        hasAccess: true,
        pageNum: 0,
      });
    } else {
      setActiveTab('app-overview-tab');
    }
  }


  return (
    <Fragment>
      <AlertDialog
        description={alertDialogDescription}
        cancelText={'Cancel'}
        component={renderProdTiers()}
        dialogId={'app-tier-selection-dialog'}
        isOpen={showTierSelectionDialog}
        onClose={() => {}}
        onSubmit={emptyFunction}
        onCancel={setShowTierSelectionDialog.bind(null, false)}
        showSubmit={false}
        submitText={''}
        title={alertDialogTitle}
        assistText={getI18nMessage('label.application.details.apiproducts.tiers.list.assist.text')}
      />
      <ListContainer
        hasPaginationDisabled={isFormDirty}
        listPageId="product-list"
        isLoading={isLoading||isAPIProductTiersLoading}
        notificationId="product-notifications"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        pageBodyClass={classes.pageBodyClass}
        pageHeaderTitle={''}
        pageHeaderDescription={''}
        pageHeaderTooltipTitle={getI18nMessage('label.request.list.page.title.help')}
        addButtonLabel={''}
        onAdd={false}
        showListHeader={true}
        filterAndSortContent={(
          <Fragment>
            <Grid className={classes.controlsContainer} item md={6} sm={6} xs={12}>
              <FilterByName
                fieldContainerClass={classes.nameFieldContainer}
                handleChange={setFilterByName}
                name={getI18nMessage('label.filter')}
                onKeyPress={onFilterByNameKeyPress}
                placeholder={getI18nMessage('label.apiproduct')}
                value={filterByName}
              />
              {accessFilter && <FilterByType
                data={tiers}
                fieldContainerClass={classes.typeFieldContainer}
                handleChange={onFilterByTierChange}
                placeholder={getI18nMessage('label.tier')}
                hideLabel
                name={getI18nMessage('label.filter.by')}
                defaultText={getI18nMessage('label.application.details.products.alltiers.filter')}
                value={filterByTier}
              />}
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              {accessFilter && <Button
                className={classes.addBtn}
                disabled={changeMode !== CHANGE_MODE_NO_CHANGE || isEditDisabled}
                variant="contained" color="secondary"
                onClick={addBtnFunc}
                id={'api-products-add'} data-apim-test={'api-products-add'} data-layer7-test={'api-products-add'}
              >
                {addButtonLabel}
              </Button>}
            </Grid>
          </Fragment>
        )}
        columns={getListColumns({ getI18nMessage, classes })}
        rows={currentProductsRows}
        noResultsMessage={getI18nMessage('label.request.list.no.results')}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_EXTRA_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        notifyMessages={emptyFunction}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
      />
      {showFormActionBtns && <FormActionButtons
        isContainerFooter={true}
        onNextClick={saveUpdatedRows}
        assistText={assistText}
        isDisabled={isFormDirty ? !isFormDirty : hasAppPublish ? !hasAppPublish : true}
        nextText={isFormDirty &&
          hasAppPublish ? 'Save & Publish' : hasAppPublish ? 'Publish' : 'Save'}
        onCancelClick={onCancel}
        id="app-api-products-form-buttons"
      />}
    </Fragment>
  );
};

APIProductManagement.propTypes = {
  applicationDetails: object,
  classes: object,
  fetchList: func,
  fetchTiersProduct: func,
  fetchTiers: func,
  productAccessSaveStatus: object,
  isLoading: bool,
  isAPIProductTiersLoading: bool,
  productTiers: shape({}),
  rows: arrayOf(shape({})),
  tiers: arrayOf(shape({})),
  totalElements: number,
  totalPages: number,
  updateAccess: func,
  publishResult: shape({}),
  currentTab: string,
  setActiveTab: func,
  publishApplication: func,
  fetchOrgProducts: func,
  orgProducts: arrayOf(shape({})),
  visible: bool,
  isEditDisabled: bool,
};

const mapStateToProps = (state) => ({
  isAPIProductTiersLoading: getIsTiersProductLoading(state),
  productAccessSaveStatus: getProductAccessSaveStatus(state),
  isLoading: getIsLoading(state),
  productTiers: getTiersProduct(state),
  rows: getAppAPIProducts(state),
  tiers: getTiers(state),
  totalElements: getAppAPIProductsTotalElements(state),
  totalPages: getAppAPIProductsTotalPages(state),
  orgProducts: getOrgProducts(state),
});

const mapDispatchToProps = {
  fetchList: fetchApplicationAPIProducts,
  updateAccess: updateApplicationAPIProductsAccess,
  fetchTiersProduct,
  fetchTiers,
  fetchOrgProducts,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(APIProductManagement);
