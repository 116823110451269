import React, { createContext, Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Link, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { omit, uniq } from 'lodash';
import {
  fetchAvailableApiGroups,
  fetchApiGroupEulas,
  updateAppAssignedAPIGroups,
} from '../../../../actions/application';
import { Switch, FormDialog as Dialog, FormTextField } from '../../../../components';

import { getEulaDetails } from '../../../../reducers/eula';
import {
  getAppAssignedAPIGroupsUpdateResult,
  getAvailableApiGroups,
  getApiGroupEulas,
  getAvailableApiGroupsCount,
  getErrors,
} from '../../../../reducers/application';
import styles from './styles';
import ListContainer from '../../../list';
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
} from '../../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../../utils/intl';
import { getConfig } from '../../../../reducers/portalConfig';
import useNotification from '../../../../hooks/useNotification';
import { Lock, ReportProblem } from '@material-ui/icons';
export const AppAPIGroupListContext = createContext();
export const AppAPIGroupManagement = (props) => {
  const {
    classes,
    isLoading,
    totalElements = 0,
    availableAPIGroups = [],
    assignedAPIGroups,
    applicationDetails = {},
    assignedAPIGroupsUpdateResult,
    apiGroupEulas=[],
    isAdmin,
    locked,
    isLocking,
    isSaveClicked,
    setSaveClicked,
    setApiGroupsTabDirty,
    disabledByInternal,
    errors,
    isEditDisabled,
  } = props;
  const isDisabled = (uuid) => {
    if(availableAPIGroups && availableAPIGroups.length>0) {
      const apiGroup = availableAPIGroups.find(api => api.uuid === uuid);
      return apiGroup.status === 'DISABLED'
          || (apiGroup.apis && apiGroup.apis.find(api => api.status === 'DISABLED'));
    }
    return false;
  }
  const formatSwitch = (uuid) => (<div className={classes.accessField}><Switch
    id={`${uuid}-access`}
    onChange={(checked) => onSwitch(checked, uuid)}
    checked={apiGroupsToBeSaved.includes(uuid)}
    disabled={locked || isEditDisabled || (isDisabled(uuid) && !apiGroupsToBeSaved.includes(uuid))}
    />
    {isDisabled(uuid) && !locked && !isEditDisabled &&
      <Tooltip
        title={getI18nFormattedMessage('label.application.details.disabled.group.locked')}
        arrow placement="right">
          <ReportProblem className={classes.warningIcon}/>
      </Tooltip>
    }
    </div>);
  const onSwitch = (checked, uuid) => {
    if (checked) {
      const switchingApiGroup = availableAPIGroups.find(api => api.uuid === uuid);
      props.fetchApiGroupEulas(switchingApiGroup.uuid, applicationDetails.organizationUuid);
      setIsApiEulaDialogOpen(true);
      setRowsToAdd([uuid]);
    } else {
      updateApiGroups('unassign', [uuid]);
    }
  }
  const SHOW_ERROR_FROM_BACKEND_LIST = ['error.validation.applications.save.noapisorapigroups'];
  const getDialogLinkContent = (value, uuid) => (
    <Link href={`/publish/apis/details/${uuid}`}>
      {value}
    </Link>
  );

  const getLinkContent = (value, uuid) => (
    <Link component="button" onClick={() => showAPIDialog(uuid)}>{value}</Link>
  );
  const showAPIDialog = (groupUuid) => {
    setApiGroupForDialog(availableAPIGroups.find(group => group.uuid === groupUuid));
    setIsApiListDialogOpen(true);
  };
  const formatApiGroupAssignTitle = () => (<div className={classes.assignToolTip}>
    {getI18nFormattedMessage('label.application.details.apigroups.table.columns.assign.title')}
    {locked &&
      <Tooltip
        title={getI18nFormattedMessage(disabledByInternal ? 'label.application.details.locked.by.internal.tooltip':
        'label.application.details.apigroups.locked.tooltip')}
        arrow placement="right">
        <Lock className={classes.lockIcon}/>
      </Tooltip>
    }
    {!locked && isLocking &&
      <Tooltip
        title={getI18nFormattedMessage('label.application.details.apigroups.locking.tooltip')}
        arrow placement="right">
        <ReportProblem className={classes.warningIcon}/>
      </Tooltip>
    }
    </div>
    );
  const getAppAPIGroupAssignListColumns = () => [{
      id: 'name',
      label: getI18nFormattedMessage('label.application.details.apigroups.table.columns.apigroup.title'),
      minWidth: 200,
      value: (item) => getLinkContent(item.name, item.uuid),
    }, {
      id: 'assign',
      label: formatApiGroupAssignTitle(),
      minWidth: 100,
      value: (item) => formatSwitch(item.uuid),
    },
  ];
  const getAppGroupAPIsListColumns = () => [{
    id: 'api',
    label: getI18nFormattedMessage('label.application.details.apigroups.dialog.columns.api.title'),
    minwidth: 100,
    value: (item) => getDialogLinkContent(item.name, item.uuid),
  }];
  const intl = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [notification, setNotification] = useNotification({ status: '', message: '' });
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [appliedNameFilter, setAppliedNameFilter] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [filterByTag, setFilterByTag] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isApiEulaDialogOpen, setIsApiEulaDialogOpen] = useState(false);
  const [rowsToAdd, setRowsToAdd] = useState([]);
  const [expandedEulaPanel, setExpandedEulaPanel] = useState('first');
  const [apiGroupForDialog, setApiGroupForDialog] = useState([]);
  const [isApiListDialogOpen, setIsApiListDialogOpen] = useState(false);
  const [apiGroupsToBeSaved, setApiGroupsToBeSaved] = useState([]);
  useEffect(() => {
    if(applicationDetails && applicationDetails.organizationUuid) {
      props.fetchAvailableApiGroups({
        page: 0,
        size: rowsPerPage,
        orgUuid: applicationDetails.organizationUuid,
      });
    }
  }, [applicationDetails]);
  useEffect(() => {
    if(assignedAPIGroupsUpdateResult === 'FAILURE') {
      props.fetchAppAssignedAPIGroups(applicationDetails.uuid);
      let errorMessage = getI18nFormattedMessage('label.application.details.apigroups.save.failed');
      if(errors && errors.length > 0 && SHOW_ERROR_FROM_BACKEND_LIST.includes(errors[0].key)) {
        errorMessage = errors[0].error;
      }
      setNotification({ status: ALERT_ERROR,
                      message:  errorMessage });
      window.setTimeout(() => setNotification(), 3000);
    } else if(assignedAPIGroupsUpdateResult === 'SUCCESS') {
      props.fetchAppAssignedAPIGroups(applicationDetails.uuid);
      props.onSaveDone();
      setSelectedRows([]);
      setNotification({
        status: ALERT_SUCCESS,
        message: getI18nFormattedMessage(`label.application.details.apigroups.${isLocking ? 'submit' : 'save'}.success`),
      });
      window.setTimeout(() => setNotification(), 3000);
    }
  }, [assignedAPIGroupsUpdateResult]);
  useEffect(() => {
    if(assignedAPIGroups) {
      setApiGroupsToBeSaved(assignedAPIGroups.map(group => group.uuid));
    }
  },[assignedAPIGroups]);
  useEffect(() => {
    if(isSaveClicked) {
      callUpdateApiGroups(apiGroupsToBeSaved);
      setSaveClicked(false);
    }
  },[isSaveClicked]);
  const anyFilterApplied = () =>
      (filterByName && filterByName !== '')
     

  const handleBlur = () => {
    if (appliedNameFilter !== filterByName) {
      setAppliedNameFilter(filterByName);
      applyFilter('searchText', filterByName);
    }
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter' && appliedNameFilter !== filterByName) {
      setAppliedNameFilter(filterByName);
      applyFilter('searchText', filterByName);
    }
  };

  const onChangePage = (newPage) => {
   if(page !== newPage) {
    setPage(newPage);
    applyFilter('page', newPage);
   }
  };
  const applyFilter = (field, value) => {
    const filter ={
      searchText: filterByName,
      page: page,
      size: rowsPerPage,
      orgUuid: applicationDetails.organizationUuid,
    };
    if(field !== 'page') {
      filter['page'] = 0;
      setPage(0);
    }
    filter[field] = value;
    props.fetchAvailableApiGroups(filter);
  }
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    applyFilter('size', newRowsPerPage);
  };

  const onClickTag = (tag) => {
    const tagFilter = uniq([...filterByTag, tag]);
    setFilterByTag(tagFilter);
    applyFilter('tags', tagFilter);
  }
  const noResultsMessage = anyFilterApplied ?
      intl.getI18nMessage('label.application.details.apigroups.filter.no.results') :
      intl.getI18nMessage('label.application.details.apigroups.no.results');
  const updateApiGroups = (action, apis) => {
    const apiGroupsToSave = action === 'assign' ?
        uniq([...apiGroupsToBeSaved, ...apis]) :
        apiGroupsToBeSaved.filter(api => !apis.includes(api));
    if(isLocking) {
      setApiGroupsTabDirty(assignedAPIGroups.length !== apiGroupsToSave.length ||
        assignedAPIGroups.some(apiGroup => !apiGroupsToSave.includes(apiGroup.uuid)));
      setApiGroupsToBeSaved(apiGroupsToSave);
    } else {
      callUpdateApiGroups(apiGroupsToSave);
    }
  };
  const callUpdateApiGroups = (apiGroupsToSave) => {
    props.updateAppAssignedAPIGroups(
      applicationDetails.uuid, apiGroupsToSave.map(api => { return { 'uuid' : api } }));
  }
  const handleExpandEulaPanel = eulaUuid => (event, expanded) => {
    setExpandedEulaPanel(expanded ? eulaUuid : false);
  }
  const getApiGroupDialogTitle = () =>
    isAdmin ?
      (<span>
        <Link variant="h3" href={`admin/api-groups#edit/${apiGroupForDialog.uuid}`}>{apiGroupForDialog.name}</Link> {getI18nFormattedMessage('label.application.details.apigroups.apilist.title')}</span>) :
      (<span>{apiGroupForDialog.name} {getI18nFormattedMessage('label.application.details.apigroups.apilist.title')}</span>);
  
  const renderApiGroupDialog = () => (
    <Fragment>
      {apiGroupEulas && apiGroupEulas.map((apiEula, index) => (
        <ExpansionPanel
          expanded={expandedEulaPanel === apiEula.uuid || (index === 0 && expandedEulaPanel === 'first')}
          className={classes.apiGroupPanel}
          key={apiEula.uuid}
          onChange={handleExpandEulaPanel(apiEula.uuid)}
          >
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <span className={classes.heading}>
              <span className={classes.accordionTitle}>
                {getI18nFormattedMessage('label.application.details.apigroups.eulas.accordion.title',
              { index: index+1, count: apiGroupEulas.length }) } </span>
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <span
              className={classes.panelContent}
              dangerouslySetInnerHTML={{ __html: (apiEula.content) }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Fragment>
  );
  const listClasses = omit(classes, [
    'accordionTitleBold', 'accordionTitle', 'actionsFieldContainer', 'actionsLabel', 'addTagsButton', 'apiGroupPanel',
    'apiStatusField', 'assignToolTip', 'blueTick', 'fieldContainer', 'greyTick', 'headerButtons',
    'helpIconContainer', 'lockIcon', 'managedContainer', 'pageBodyClass', 'pageClass', 'panelContent',
    'paperClass', 'radioButton', 'removeTagsButton', 'statusIcon', 'tagButtons', 'tagsContainer',
    'textFieldContainer', 'textFiltersContainer', 'warningIcon',
  ]);
  const renderApiListDialog = () => (
    <ListContainer
        listPageId="app-apigroup-apilist-dialog"
        isLoading={isLoading}
        columns={getAppGroupAPIsListColumns()}
        rows={apiGroupForDialog.apis}
        noResultsMessage={noResultsMessage}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
        classes={listClasses}
        hasPaginationDisabled={true}
        hidePagination
        hideFooter
      />
  );
  return (
    <AppAPIGroupListContext.Provider value={{ onClickTag }}>
     <Dialog
        dialogId="application-api-group-eula-dialog"
        isOpen={isApiEulaDialogOpen}
        title={intl.getI18nMessage('label.application.details.apis.eulas.title')}
        submitText={intl.getI18nMessage('label.application.details.apis.eulas.acceptbutton')}
        isDialogContentText={false}
        handleClose={() => setIsApiEulaDialogOpen(false)}
        onSubmit={() => {
          updateApiGroups('assign', rowsToAdd);
          setIsApiEulaDialogOpen(false);
        }}
        cancelText="Cancel"
        onCancel={() => { setIsApiEulaDialogOpen(false); }}
        paperClass={classes.paperClass}
      >
        {renderApiGroupDialog()}
      </Dialog>
      <Dialog
        dialogId="application-api-list-dialog"
        isOpen={isApiListDialogOpen}
        title={getApiGroupDialogTitle()}
        submitText={intl.getI18nMessage('label.application.details.apigroups.apilist.submit.text')}
        isDialogContentText={false}
        hideCancelButton
        handleClose={() => setIsApiListDialogOpen(false)}
        onSubmit={() => {
          setIsApiListDialogOpen(false);
        }}
        paperClass={classes.paperClass}
      >
        {renderApiListDialog()}
      </Dialog>
      <ListContainer
        listPageId="app-apigroupaccesslist-page"
        isLoading={isLoading}
        notificationId="apis-org-access-notifications"
        notificationStatus={notification.status}
        setNotificationStatus={(status) => setNotification({ status, message: '' })}
        notificationMessage={notification.message}
        setNotificationMessage={(message) => setNotification({ status: '', message })}
        pageFilterAndSortClass={classes.pageFilterAndSortClass}
        columns={getAppAPIGroupAssignListColumns()}
        rows={availableAPIGroups}
        filterAndSortContent={(
          <>
            <div className={classes.textFiltersContainer}>
            <FormTextField
                id="apis-org-filter-by-name"
                data-apim-test="apis-org-filter-by-name"
                type="text"
                fieldContainerClass={classes.textFieldContainer}
                name={intl.getI18nMessage('label.filter')}
                value={filterByName}
                placeholder={intl.getI18nMessage('label.application.details.apigroups.filter.name.placeholder')}
                handleChange={val=>setFilterByName(val)}
                onKeyPress={onFilterByNameKeyPress}
                handleBlur={handleBlur}
              />
            </div>
          </>
        )}
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={totalElements}
        totalPages={Math.ceil(totalElements / rowsPerPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
        classes={listClasses}
      />
    </AppAPIGroupListContext.Provider>
    );
};

AppAPIGroupManagement.propTypes = {
  classes: object,
  orgUuid: string,
  isLoading: bool,
  isError: bool,
  totalElements: number,
  isLoadingError: bool,
  applicationDetails: object,
  assignedAPIGroups: arrayOf(object),
  assignedAPIGroupsUpdateResult: string,
  updateAppAssignedAPIs: func,
  eulaDetails: object,
  fetchAvailableApiGroups: func,
  fetchApiGroupEulas: func,
  fetchAppAssignedAPIGroups: func,
  updateAppAssignedAPIGroups: func,
  availableAPIGroups: arrayOf(object),
  apiGroupEulas: arrayOf(object),
  isAdmin: bool,
  locked: bool,
  setApiGroupsTabDirty: func,
  isLocking: bool,
  isSaveClicked: func,
  setSaveClicked: func,
  onSaveDone: func,
  disabledByInternal: bool,
  getErrors: func,
  errors: arrayOf(object),
  isEditDisabled: bool,
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  totalElements: getAvailableApiGroupsCount(state),
  assignedAPIGroupsUpdateResult: getAppAssignedAPIGroupsUpdateResult(state),
  eulaDetails: getEulaDetails(state),
  availableAPIGroups: getAvailableApiGroups(state),
  apiGroupEulas: getApiGroupEulas(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  fetchAvailableApiGroups,
  fetchApiGroupEulas,
  updateAppAssignedAPIGroups,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AppAPIGroupManagement);
