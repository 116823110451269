import { get, map, reduce, sortBy, assign, isArray, indexOf } from 'lodash';

import * as constants from '../../constants';
import { getHashingMetadata } from './responseParser';

const initialState = {
  certificatesStatus: [],
  connectionStatus: [],
  deploymentStatus: {
    results: [],
  },
  proxyErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload,
      };
    case constants.FETCH_APP_SECRET_HASHING_METADATA_SUCCESS:
      return {
        ...state,
        appSecretHashingMetadata: getHashingMetadata(get(action, 'payload.Value')),
      };
    case constants.FETCH_DEPLOYMENT_REFRESH_COUNTDOWN_IN_SECONDS_SUCCESS:
      return {
        ...state,
        deploymentRefreshTime: get(action, 'payload.Value'),
      };
    case constants.FETCH_APPLICATION_REQUEST_SETTING_SUCCESS:
      {
        const { requestSettings } = state;
        return {
          ...state,
          requestSettings: {
            ...requestSettings,
            isApplicationRequestWorkflowEnabled: get(action, 'payload'),
          },
        };
      }
    case constants.FETCH_APPLICATION_EDIT_REQUEST_SETTING_SUCCESS:
      {
        const { requestSettings } = state;
        return {
          ...state,
          requestSettings: {
            ...requestSettings,
            isEditApplicationRequestWorkflowEnabled: get(action, 'payload'),
          },
        };
      }
    case constants.FETCH_APPLICATION_DELETE_REQUEST_SETTING_SUCCESS:
        {
          const { requestSettings } = state;
          return {
            ...state,
            requestSettings: {
              ...requestSettings,
              isDeleteApplicationRequestWorkflowEnabled: get(action, 'payload'),
            },
          };
        }
    case constants.HEADER_PROXY_STATUS_GET_SUCCESS:
      return {
        ...state,
        certificatesStatus: get(action, 'payload.certificatesStatus'),
        connectionStatus: get(action, 'payload.connectionStatus'),
        deploymentStatus: get(action, 'payload.deploymentStatus'),
        proxyErrors: [],
      };
    case constants.HEADER_PROXY_STATUS_GET_ERROR:
      return {
        ...state,
        certificatesStatus: [],
        connectionStatus: [],
        deploymentStatus: [],
        proxyErrors: get(action, 'payload'),
      };
    case constants.FETCH_SETTING_USER_LOCK_CREATION_SUCCESS:
      {
        const { featureFlags } = state;
        return {
          ...state,
          featureFlags: {
            ...featureFlags,
            lockUserCreation: action.payload,
          },
        };
      }
    default:
      return state;
  }
};

export const getConfig = (state) => state.portalConfig && state.portalConfig.config;
export const getDict = (state) => state.portalConfig && state.portalConfig.dict;
export const getUserContexts = (state) => state.portalConfig && state.portalConfig.userContexts;
export const getUserAccessibleOrgs = (state) =>
  map(
    getUserContexts(state) && getUserContexts(state).userContexts[0].accessibleOrgs,
    (uuid, name) => ({ uuid, name }),
  );
export const getUserActiveOrgUuid = (state) =>
  getUserContexts(state) && getUserContexts(state).userContexts[0].activeOrgUuid;
export const getUserDetails = (state) =>
  getUserContexts(state) && getUserContexts(state).userContexts[0].userDetails;
export const getCanAddAPI = (state) => {
    const user = getUserContexts(state) && getUserContexts(state).userContexts[0].userDetails;
    const permissions = get(user, 'permissions', {});
    const apiPermissions = (isArray(permissions && permissions.API) && permissions.API) || [];
    return (indexOf(apiPermissions, 'CREATE') !== -1);
  };
export const getUserPrefs = (state) => get(state, 'portalConfig.userPrefs', {});

export const getServices = (state) => state.portalConfig && state.portalConfig.services;
export const getGlobalNavigation = (state) => state.portalConfig &&
  state.portalConfig.globalNavigation;
export const getCQNavigation = (state) => state.portalConfig && state.portalConfig.cqNavigation;
export const getThemes = (state) => state.portalConfig && state.portalConfig.themes;
export const getDisplayCopyright = (state: Object) =>
  get(state, 'portalConfig.themes.display.copyright', true);
export const getPublisherNavigation = (state) => state.portalConfig &&
state.portalConfig.publisherNavigation;
export const getDeveloperNavigations = (state) => state.portalConfig &&
state.portalConfig.developerNavigation;
export const getSettingsNavigation = (state) => state.portalConfig &&
state.portalConfig.settingsNavigation;

export const getFeatureFlags = (state) => state.portalConfig && state.portalConfig.featureFlags;
export const getFeatureFlagApiPlans = (state) => (
  get(getFeatureFlags(state), 'apiPlans.Value') === 'true'
);
export const getUseLegacyApiPages = (state) => (
  get(getFeatureFlags(state), 'useLegacyApiPages.Value') === 'true'
);
export const getUseLegacyApplicationPages = (state) => (
  get(getFeatureFlags(state), 'useLegacyApplicationPages.Value') === 'true'
);
export const getNewLandingPage = (state) => (
  get(getFeatureFlags(state), 'newLandingPage.Value') === 'true'
);
export const getUseMultiApiKeys = (state) => (
  get(getFeatureFlags(state), 'useMultiApiKeys.Value') === 'true'
);
export const getLockUserCreation = (state) => (
  get(getFeatureFlags(state), 'lockUserCreation.Value') === 'true'
);

export const getFeatureFlagForCustomPages = (state) => (
  get(getFeatureFlags(state), 'customPages.Value') === 'true'
);
export const getFeatureFlagForProduct = (state) => (
  get(getFeatureFlags(state), 'product.Value') === 'true'
);

export const getRequestSettings = (state) => (
  get(state, 'portalConfig.requestSettings')
);
export const getIsApplicationRequestWorkflowEnabled = (state) => (
  get(getRequestSettings(state), 'isApplicationRequestWorkflowEnabled.Value') === 'ENABLED'
);
export const getIsEditApplicationRequestWorkflowEnabled = (state) => (
  get(getRequestSettings(state), 'isEditApplicationRequestWorkflowEnabled.Value') === 'ENABLED'
);
export const getIsDeleteApplicationRequestWorkflowEnabled = (state) => (
  get(getRequestSettings(state), 'isDeleteApplicationRequestWorkflowEnabled.Value') === 'ENABLED'
);

export const getRequests = (state) => state.portalConfig &&
       state.portalConfig.requests && state.portalConfig.requests.results;
export const getAppSecretHashingMetadata = (state) => get(state, 'portalConfig.appSecretHashingMetadata');
export const getDeploymentRefreshTime = (state) => get(state, 'portalConfig.deploymentRefreshTime');

const reducePortalAdminAuthorities = (acc: boolean, value: Object) => {
  const adminAuthorities = ['portaladministrators', 'apiowners'];
  return (acc || adminAuthorities.includes(value.authority));
};

export const getIsPortalAdmin = (state: Object) =>
  getUserDetails(state) &&
  getUserDetails(state).authorities &&
  reduce(getUserDetails(state).authorities, reducePortalAdminAuthorities, false);

const reduceOnlyPortalAdminAuthorities = (acc: boolean, value: Object) => {
  const adminAuthorities = ['portaladministrators'];
  return (acc || adminAuthorities.includes(value.authority));
};

export const getIsOnlyPortalAdmin = (state: Object) =>
  getUserDetails(state) &&
  getUserDetails(state).authorities &&
  reduce(getUserDetails(state).authorities, reduceOnlyPortalAdminAuthorities, false);

const reduceApiOwnerAuthorities = (acc: boolean, value: Object) => {
  const adminAuthorities = ['apiowners'];
  return (acc || adminAuthorities.includes(value.authority));
};

export const getIsApiOwner = (state: Object) =>
  getUserDetails(state) &&
  getUserDetails(state).authorities &&
  reduce(getUserDetails(state).authorities, reduceApiOwnerAuthorities, false);

const reduceOrgPublisherAuthorities = (acc: boolean, value: Object) => {
  const adminAuthorities = ['orgpublishers'];
  return (acc || adminAuthorities.includes(value.authority));
};

export const getIsOrgPublisher = (state: Object) =>
  getUserDetails(state) &&
  getUserDetails(state).authorities &&
  reduce(getUserDetails(state).authorities, reduceOrgPublisherAuthorities, false);

export const getIsAnonymousRole = (state: Object) =>
  getUserDetails(state) &&
  getUserDetails(state).authorities &&
  (getUserDetails(state).authorities.length === 0);

export const getCookieConsent = (state) =>
  get(state, 'portalConfig.themes.display.cookieConsent');
export const getThemeBrandedLabels = (state) =>
  get(state, 'portalConfig.themes.label');

export const getProxyStatus = (state) => {
  const connectionStatus = get(state, 'portalConfig.connectionStatus', []);
  const notConnected = connectionStatus.filter((obj) => {
    obj.count = 1;
    return (
      obj.connectionStatus === 'NOT_CONNECTED' &&
      obj.enrollmentStatus === constants.PROXY_ENROLMENT_STATUS_ACTIVE
    );
  });
  const deploymentStatus = get(state, 'portalConfig.deploymentStatus.results', []);
  const obj = {};
  deploymentStatus.forEach((item)=>{
    const type = [
      constants.PROXY_PORTAL_API_TYPE,
      constants.PROXY_GATEWAY_API_TYPE,
    ].includes(item.type) ? 'api' : item.type;
    const key = `${item.uuid}_${type}`;
    if(obj[key]) {
      obj[key].count = obj[key].count + item.count;
    } else {
      obj[key] = assign({}, item);
    }
  });
  const errorDeployment = Object.values(obj);
  const certsStatusRaw = get(state, 'portalConfig.certificatesStatus.results', []);
  const certsStatus = [];
  certsStatusRaw.forEach((item) => {
    const { proxyName, proxyUuid, type, count } = item;
    if (count > 0) {
      certsStatus.push({
        count,
        name: proxyName,
        type,
        uuid: proxyUuid,
      });
    }
  });
  return sortBy([...notConnected, ...errorDeployment, ...certsStatus], obj => obj.uuid);
};

export const getErrors = state => get(state, 'portalConfig.proxyErrors');

export const getCmsSettings = state => get(state, 'portalConfig.cmsSettings')
