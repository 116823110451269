import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/intl';
import theme from './index.theme';

/* eslint-disable */
const settingsSVG =
  (<Fragment>
    <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M11 3.23708L11.5041 2.69655C11.7445 2.43872 12.1478 2.42402 12.4048 2.66371L13.5782 3.58168C14.2409 3.20222 14.9898 2.96984 15.7722 2.91727L16.234 1.49301C16.313 1.15057 16.6556 0.937248 16.9991 1.01655L18.4762 1.35758C18.8197 1.43688 19.0341 1.77878 18.955 2.12122L18.7271 3.62027C19.3829 4.00834 19.9345 4.5341 20.3516 5.14917L21.8171 4.83671C22.1532 4.73395 22.5092 4.92395 22.6123 5.26108L23.0555 6.71085C23.1586 7.04798 22.9697 7.40457 22.6336 7.50733L21.2307 8.05582C21.2268 8.41628 21.1842 8.7818 21.0998 9.1474C21.011 9.53196 20.8807 9.89616 20.7147 10.2364L21.7129 11.3437C21.97 11.5834 21.9834 11.9867 21.743 12.2445L20.7091 13.3533C20.4687 13.6111 20.0654 13.6258 19.8084 13.3861L19.2853 12.9678C20.1281 12.0835 20.3221 10.716 19.6815 9.60631L18.6997 7.90578C17.9402 6.59034 16.2804 6.10952 14.9443 6.81243L14.3716 7.07947C14.105 6.90514 13.8288 6.74556 13.5442 6.60157L13.4932 5.93727C13.4448 4.53381 12.3693 3.39696 11 3.23708Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.7973 14C2.7973 14.5155 2.85753 15.0172 2.9714 15.4984L1.4512 16.5628C1.05987 16.7888 0.927714 17.2925 1.15602 17.6879L2.13782 19.3885C2.36613 19.7839 2.86845 19.9213 3.25978 19.6954L4.98842 18.8667C5.69893 19.4995 6.54875 19.9821 7.48683 20.264L7.65463 22.1818C7.65463 22.6337 8.02479 23 8.48141 23H10.445C10.9016 23 11.2718 22.6337 11.2718 22.1818L11.4209 20.2379C12.3278 19.9521 13.1498 19.478 13.8399 18.862L15.5784 19.6954C15.9697 19.9213 16.4721 19.7839 16.7004 19.3885L17.6822 17.6879C17.9105 17.2925 17.7783 16.7888 17.387 16.5628L15.8538 15.4893C15.9663 15.0109 16.0258 14.5123 16.0258 14C16.0258 13.513 15.972 13.0385 15.8701 12.5819L17.4311 11.5136C17.8224 11.2876 17.9546 10.7839 17.7263 10.3885L16.7445 8.68794C16.5162 8.2925 16.0139 8.15508 15.6225 8.38102L13.8941 9.18696C13.193 8.54711 12.3517 8.05541 11.4209 7.76205L11.2718 5.81818C11.2718 5.36631 10.9016 5 10.445 5H8.48141C8.02479 5 7.65463 5.36631 7.65463 5.81818L7.48683 7.73599C6.5247 8.02513 5.65541 8.5254 4.93404 9.1823L3.21566 8.38102C2.82433 8.15508 2.32202 8.2925 2.09371 8.68794L1.11191 10.3885C0.883598 10.7839 1.01575 11.2876 1.40708 11.5136L2.95496 12.5729C2.85174 13.0323 2.7973 13.5099 2.7973 14ZM12.4033 14.0064C12.4033 15.638 11.0667 16.9607 9.418 16.9607C7.76925 16.9607 6.43267 15.638 6.43267 14.0064C6.43267 12.3748 7.76925 11.0521 9.418 11.0521C11.0667 11.0521 12.4033 12.3748 12.4033 14.0064Z" />
  </Fragment>);
const collapseSVG = <path d="M12.023 7.04426L19.1319 13.9239C19.6227 14.3988 19.6227 15.1689 19.1319 15.6438C18.6412 16.1187 17.8455 16.1187 17.3547 15.6438L11.9771 10.4397L6.6453 15.5995C6.15453 16.0745 5.35884 16.0745 4.86808 15.5995C4.37731 15.1246 4.37731 14.3546 4.86808 13.8796L11.977 7L12.0229 7.04442L12.023 7.04426Z" />;
/* eslint-enable */

const SettingsIcon = createSvgIcon(settingsSVG, 'settings');
const CollapseIcon = createSvgIcon(collapseSVG, 'collapse');

function Settings({
 classes = {},
 settingItems = [],
}) {
  const [anchorEl = false, setAnchorEl] = React.useState(null);
  const intl = getI18n(useIntl());
  const handleClick = event => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event) => {
    if ((event.key === 'Tab') || (event.key === 'Escape')) {
      event.preventDefault();
      anchorEl.focus();
      setAnchorEl(null);
    }
  };

  const handleBtnKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setAnchorEl(null);
    }
  };

  return (
    <div className={classes.HeaderSettingsButton}>
      <IconButton
        onKeyDown={handleBtnKeyDown}
        onClick={handleClick}
        data-apim-test="header-settings"
      >
        {!anchorEl ?
          <SettingsIcon className={classes.SettingsIcon} />
          :
          <CollapseIcon className={classes.SettingsIcon} />
        }
      </IconButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        role={undefined}
        transition
        className={classes.Popper}
        disablePortal
        placement={'bottom-end'}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
            }}
          >
            {!isEmpty(settingItems) &&
              <Paper
                classes={{
                  root: classes.MenuPaper,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    classes={{
                      root: classes.Menu,
                    }}
                    onKeyDown={handleListKeyDown}
                  >
                    {settingItems.map(item =>
                      (<MenuItem
                        className={classes.HeaderSettingsMenuItem}
                        component={'a'}
                        href={`//${window.location.hostname}${item.href}`}
                        key={item.titleKey}
                        value={item.titleKey}
                        data-apim-test={item.titleKey || item.menuItem}
                      >
                        {intl.getI18nMessage(item.titleKey)}
                      </MenuItem>),
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            }
          </Grow>
        )}
      </Popper>
    </div>
  );
}

Settings.propTypes = {
  classes: PropTypes.shape({}),
  settingItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withStyles(theme)(Settings);
