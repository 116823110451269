import React from 'react';
import {
  makeStyles,
  Button,
  Grid,
  AppBar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles';

export default function FormActionButtons(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    id,
    assistText,
    cancelText = 'Cancel',
    nextText = 'Next',
    onCancelClick,
    onNextClick,
    showCancel = true,
    showNext = true,
    isDisabled = false,
    isContainerFooter = false,
    containerClass,
  } = props;

  return (
    <AppBar className={clsx(containerClass, classes.appBar)} id={id} data-layer7-test={id}>
        {isContainerFooter ?
         <Grid container>
            <Grid md={2} item className={classes.cancelContainer}>
              {showCancel &&
                <Button
                  id="cancel-button"
                  data-apim-test="cancel-button"
                  color="primary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={() => onCancelClick()}
                >
                  {cancelText}
                </Button>
              }
            </Grid>
            <Grid md={10} item>
              <div className={classes.saveContainer}>
                {assistText &&
                  <div className={classes.assistText}>{assistText}</div>
                }
                {showNext &&
                  <Button
                    id="next-button"
                    data-apim-test="next-button"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                    onClick={() => onNextClick()}
                    disabled={isDisabled}
                  >
                    {nextText}
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
        :
        <Grid container>
          <Grid md={2} item />
          <Grid md={2} item className={classes.cancelContainer}>
            {showCancel &&
              <Button
                id="cancel-button"
                data-apim-test="cancel-button"
                color="primary"
                variant="outlined"
                className={classes.cancelButton}
                onClick={() => onCancelClick()}
              >
                {cancelText}
              </Button>
            }
          </Grid>
          <Grid md={5} item>
            <div className={classes.saveContainer}>
              {assistText &&
                <div className={classes.assistText}>{assistText}</div>
              }
              {showNext &&
                <Button
                  id="next-button"
                  data-apim-test="next-button"
                  variant="contained"
                  color="secondary"
                  className={classes.saveButton}
                  onClick={() => onNextClick()}
                  disabled={isDisabled}
                >
                  {nextText}
                </Button>
              }
            </div>
          </Grid>
          <Grid md={3} item />
        </Grid>
      }
    </AppBar>
  );
}

FormActionButtons.propTypes = {
  id: PropTypes.string,
  assistText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelText: PropTypes.string,
  nextText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onCancelClick: PropTypes.func,
  onNextClick: PropTypes.func,
  showCancel: PropTypes.bool,
  showNext: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isContainerFooter: PropTypes.bool,
  containerClass:  PropTypes.string,
};
