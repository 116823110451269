import { get } from 'lodash';
import { modifyAlpha } from '../../../../utils/modifyColour';

const styles = ({ color, fontSize, typography }) => ({
  keyNameContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  inputFieldContainer: {
    display: 'block',
  },
  heading: {
    color: get(color, 'bodyText'),
  },
  inputField: {
    '& input': {
      margin: 0,
      padding: 10,
      border: `1px solid ${get(color, 'borderColor')}`,
      borderRadius: 3,
    },
  },
  notchedOutline: {
    outline: 0,
    border: 0,
  },
  inputHelpText: {
    color: get(color, 'bodyText'),
    margin: 1,
    marginTop: 8,
    marginBottom: 10,
  },
  statusField: {
    '& legend': {
      color: `${get(color, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontWeight: 'bold',
    },
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    marginBottom: 10,
  },
  helperText: {
    color: `${get(color, 'smallText')} !important`,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
  },
  helpIcon: {
    fontSize: 16,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  sectionTitle: {
    color: get(color, 'bodyText'),
    fontFamily: get(typography, 'bodyText'),
    fontSize: get(fontSize, 'bodyText'),
    fontWeight: 'bold',
  },
  lockingWarning: {
    color: `${get(color, 'smallText')} !important`,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    display: 'inline-flex',
  },
  defaultCheckboxContainer: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontWeight: 'bold',
    marginTop: -20,
  },
  checkboxHelpText: {
    color: `${get(color, 'bodyText')} !important`,
    fontFamily: `${get(typography, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    marginBottom: 20,
    marginTop: -10,
  },
  defaultKeyWarningText: {
    marginTop: -20,
    marginBottom: 20,
    fontFamily: `${get(typography, 'smallText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    color: get(color, 'warning'),
  },
  secretTypeErrorText: {
    marginTop: -5,
    marginBottom: 5,
    color: get(color, 'error'),
  },
  generateSecretDialog: {
    fontFamily: `${get(typography, 'bodyText')} !important`,
    color: get(color, 'bodyText'),
    margin: 20,
    width: 500,
  },
  generateSecretDialogContent: {
    fontSize: `${get(fontSize, 'bodyText')} !important`,
  },
  errorBackgroundButton: {
    backgroundColor: get(color, 'error'),
    color: get(color, 'bodyBackground'),
    '&:hover': {
      color: get(color, 'bodyBackground'),
      backgroundColor: modifyAlpha(get(color, 'error'), '0.7'),
    },
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
    marginRight: 20,
  },
  fieldCopyContainer:{
    display: 'inline-flex',
    width: '100%',
  },
  buttons: {
    marginTop: 20,
  },
  cancelButton: {
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  dialogButtonPanel: {
    marginTop: 30,
    float: 'right',
  },
  headingLine: {
    display: 'inline-flex',
    marginTop: 40,
    width: '100%',
    justifyContent: 'space-between',
  },
  headingAndDelete: {
    display: 'inline-flex',
  },
  generateSecretMenuButton: {
    border: `1px solid ${get(color, 'borderColor')}`,
    color: get(color, 'error'),
    '&:hover': {
      color: get(color, 'error'),
    },
    '&:focus': {
      border: `1px solid ${get(color, 'error')}`,
    },
    marginBottom: 50,
  },
  deleteKeyButton: {
    border: `1px solid ${get(color, 'borderColor')}`,
    color: get(color, 'error'),
    '&:hover': {
      color: get(color, 'error'),
    },
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'borderColor'),
    },
    marginLeft: 10,
    marginTop: 10,
  },
  proxyAlertSubmitButton: {
    backgroundColor: `${get(color, 'error')} !important`,
    borderColor: `${get(color, 'error')} !important`,
  },
  warningIcon: {
    fill: get(color, 'warning'),
    marginLeft: 5,
  },
  notificationWidthClass: {
    width: '28%',
    padding: '0 15px',
    marginLeft: 0,
  },
  deleteConfirmPanel: {
    marginLeft: 10,
    marginTop: 10,
  },
  secretOptionsContainer: {
    color: get(color, 'bodyText'),
  },
  textField: {
    width: '70%',
  },
  copyButton: {
    color: get(color, 'primaryButtonBackground'),
    marginTop: 51,
    width: '20%',
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
      outlineColor: get(color, 'borderColor'),
    },
  },
  saveButton: {
    marginBottom: 10,
    '&:focus': {
      backgroundColor: get(color, 'borderColor'),
    },
  },
  closeButton: {
    marginBottom: 10,
    marginLeft: 10,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  iconRoot: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  closeIconButton: {
    minWidth: 0,
    width: 35,
    height: 35,
    padding: 0,
    marginTop: 10,
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
});

export default styles;
