import React from 'react';
import { Link, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { lowerCase, startCase } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getStatusColor } from '../../Api/List/utils';
import { getI18nFormattedMessage } from '../../../utils/intl';

export const buildSingleApplicationRequestMap = (classes, requestDetails) => {
  const originalData = requestDetails.original;
  const updateRequest = requestDetails.updateRequest;
  let requestAppDetails, requestCustomFields, requestApis, requestApiGroups,requestApiKeys;
  if(updateRequest) {
    requestAppDetails = updateRequest.applicationDetails;
    requestCustomFields = updateRequest.applicationCustomFields;
    requestApis = updateRequest.applicationApis;
    requestApiGroups = updateRequest.applicationApiGroups;
    requestApiKeys = updateRequest.applicationApiKeys;
  }

  const returnMap = { "details" : buildDetailsData(classes, originalData.applicationDetails, requestAppDetails) };
  const customFields = originalData.applicationCustomFields;
  if(customFields && customFields.length > 0) {
    returnMap['customfields'] = buildCustomFieldsData(customFields, requestCustomFields);
  }
  const apis = originalData.applicationApis;
  if(requestApis) {
    const type = requestDetails.request.requestType === "APPLICATION_API_PLANS" ? "apiplans": "apis";
    returnMap['apis'] = buildApisCompareTable(apis, requestApis, type);
  } else if(apis && apis.length > 0) {
    returnMap['apis'] = apis;
  }
  const apiGroups = originalData.applicationApiGroups;
  if(requestApiGroups && requestApiGroups.length > 0) {
    returnMap['apigroups'] = buildApisCompareTable(apiGroups, requestApiGroups);
  } else if(apiGroups && apiGroups.length >0) {
    returnMap['apigroups'] = apiGroups;
  }
  returnMap['apikeys'] = buildKeyDetailsData(originalData.applicationApiKeys, requestApiKeys);
  return returnMap;
};
const getLinkContent = (value, link) => (
  <Link underline="none" href={link}>
    {value}
  </Link>
);

export const buildRegistrationRequestTable = (request) => [{
    uuid: 'EMAIL',
    field: getI18nFormattedMessage('label.request.details.email.field.value'),
    value: request.entityName,
  },{
    uuid: 'ORGANIZATION',
    field: getI18nFormattedMessage('label.request.details.organization.field.value'),
    value: getLinkContent(request.organizationName, `/admin/console/organizations/details/${request.organizationUuid}`),
}];
const formatApiStatus = (classes, status) => {
  const statusStyle = {
    fill: getStatusColor(status),
  };
  return (
    <span className={classes.statusField}>
      <FiberManualRecordIcon
        style={statusStyle} className={classes.statusIcon} />
      <span>{startCase(lowerCase(status))}</span>
    </span>);
}
export const getTableColumns = (classes, isSingleRequest, type) => {
  switch(type) {
    case "details":
    case "customfields":
    case "apikeys":
    case "status":
    case "addinfo":
    case "registration":
    case "drydetails": {
        const fields =
          [{
          id: 'field',
          label: getI18nFormattedMessage('label.request.details.section.field.column.title'),
          minWidth: 200,
          value: (item) => item.field,
        }, {
          id: 'value',
          label: getI18nFormattedMessage(`label.request.details.section.${isSingleRequest || type === "drydetails" ? '': 'current.'}value.column.title`),
          minWidth: 300,
          value: (item) => item.value,
        }];
        if((!isSingleRequest && type !== "drydetails") || type === "status") {
          fields.push({
            id: 'requestValue',
            label: getI18nFormattedMessage('label.request.details.section.request.value.column.title'),
            minWidth: 200,
            value: (item) => item.requestValue,
          });
        }
        return fields;
      }
    case "apis":
    case "apigroups":
    case "apiplans": {
      const apiFields=[{
        id: 'name',
        label: getI18nFormattedMessage(`label.request.details.section.${type}.name.column.title`),
        minWidth: 200,
        value: (item) => getLinkContent(item.name,
          type === 'apis' || type === 'apiplans' ? `/publish/apis/details/${item.uuid}`
                                              : `/admin/api-groups#edit/${item.uuid}`),
      }, {
        id: 'status',
        label: getI18nFormattedMessage('label.request.details.section.state.column.title'),
        minWidth: 300,
        value: (item) => formatApiStatus(classes, type === 'apis' || type === 'apiplans' ? item.portalStatus : item.status),
      }];
      if(type === "apiplans") {
        apiFields.push({
          id: 'apiPlan',
          label: getI18nFormattedMessage(`label.request.details.section.${isSingleRequest ? '' : 'current.'}apiplan.column.title`),
          minWidth: 300,
          value: (item) => getLinkContent(item.apiPlanName, `/admin/apis/api-plans#edit/${item.apiPlanUuid}`),
        });
        if(!isSingleRequest) {
          apiFields.push({
            id: 'requestApiPlan',
            label: getI18nFormattedMessage('label.request.details.section.request.apiplan.column.title'),
            minWidth: 300,
            value: (item) => getLinkContent(item.requestApiPlanName, `/admin/apis/api-plans#edit/${item.requestApiPlanUuid}`),
          });
        }
      }
      if(!isSingleRequest) {
        apiFields.push({
          id: 'request',
          label: getI18nFormattedMessage('label.request.details.section.request.column.title'),
          minWidth: 300,
          value: (item) => item.request,
        });
      }
      return apiFields;
    }
  }
}
export const buildDetailsData = (classes, applicationDetails, requestAppDetails, infoTable) => [{
    uuid: "NAME",
    field: getI18nFormattedMessage('label.request.details.section.field.appname'),
    value: applicationDetails.name,
    requestValue: requestAppDetails && requestAppDetails.name,
    selected: requestAppDetails ? applicationDetails.name !== requestAppDetails.name : false,
  }, {
    uuid: "DESCRIPTION",
    field: <div className={classes.descriptionField}>
              {getI18nFormattedMessage('label.request.details.section.field.appdesc')}
              {!infoTable &&
                <Tooltip
                  title={getI18nFormattedMessage('label.request.details.section.field.description.tooltip')}
                  arrow placement="right">
                    <HelpIcon className={classes.helpIcon}/>
                </Tooltip>
              }
            </div>,
    value: applicationDetails.description,
    requestValue: requestAppDetails && requestAppDetails.description,
    selected: requestAppDetails ?
                applicationDetails.description !== requestAppDetails.description : false,
}];

const buildCustomFieldsData = (customFields) => customFields.map(cf => {
  return {
    uuid: cf.customFieldUuid,
    field: cf.name,
    value: cf.value,
  };
 });

 export const buildEditRequestTable = (classes, requestDetails) => {
  if(requestDetails) {
    const originalData = requestDetails.original;
    const requestData = requestDetails.updateRequest;
    let dataTable;
    let type;
    if(originalData && requestData) {
      switch(requestDetails.request.requestType) {
        case 'APPLICATION_DETAILS':
          if(requestDetails.request.requestSubtype === 'APP_STATUS_CHANGE') {
            type = 'status';
            dataTable = buildStatusChangeDataTable(
                    requestDetails.request.previousState, requestData.applicationDetails.status);
          } else {
            type = 'details';
            dataTable = buildDetailsData(
              classes, originalData.applicationDetails, requestData.applicationDetails);
          }
          break;
        case 'APPLICATION_CUSTOM_FIELDS' :
          type = "customfields";
          dataTable = buildCustomFieldsCompareTable(
            originalData.applicationCustomFields, requestData.applicationCustomFields);
          break;
        case 'APPLICATION_APIS' :
        case 'APPLICATION_API_PLANS':
          type = requestDetails.request.requestType === "APPLICATION_API_PLANS" ? "apiplans": "apis";
          dataTable =
            buildApisCompareTable(originalData.applicationApis, requestData.applicationApis, type);
          break;
        case 'APPLICATION_API_GROUPS':
          type = "apigroups";
          dataTable =
            buildApisCompareTable(
              originalData.applicationApiGroups, requestData.applicationApiGroups, type);
          break;
        case 'APPLICATION_API_KEYS':
          type = 'apikeys';
          dataTable = buildKeyDetailsData(
            originalData.applicationApiKeys, requestData.applicationApiKeys);
          dataTable.forEach(entry => entry['selected'] = (entry.value !== entry.requestValue));
          break;
      }
      return {
        type,
        dataTable,
      };
    }
  }
 };

const buildCustomFieldsCompareTable = (originalCustomFields, requestCustomFields) => {
  const customFieldsMerged = {};
  originalCustomFields.forEach(cf => {
    customFieldsMerged[cf.customFieldUuid]= {
        uuid: cf.customFieldUuid,
        field: cf.name,
        value: cf.value,
    };
  });
  requestCustomFields.forEach(cf => {
    if(Object.keys(customFieldsMerged).includes(cf.customFieldUuid)) {
      customFieldsMerged[cf.customFieldUuid].requestValue = cf.value;
    } else {
      customFieldsMerged[cf.customFieldUuid] = {
        uuid: cf.customFieldUuid,
        field: cf.name,
        requestValue: cf.value,
      }
    }
  });
  const cfMergedList = Object.values(customFieldsMerged);
  cfMergedList.forEach(cf => cf['selected'] = cf.value !== cf.requestValue);
  return cfMergedList;
};

const buildApisCompareTable = (originalApis, requestApis, type) => {
  const originalApiKeys = originalApis.map(api => api.uuid);
  const requestApisMap = {};
  requestApis.forEach(api => requestApisMap[api.uuid] = api);
  requestApis.forEach(api => {
    if(!originalApiKeys.includes(api.uuid)) {
      api["request"] = getI18nFormattedMessage('label.request.details.section.api.added');
      api['selected'] = true;
      originalApis.push(api);
    }
  });
  originalApis.forEach(api => {
    if(Object.keys(requestApisMap).includes(api.uuid)) {
      if(type === "apiplans") {
        const reqApi=requestApisMap[api.uuid];
        api["requestApiPlanUuid"] = reqApi.apiPlanUuid;
        api["requestApiPlanName"] = reqApi.apiPlanName;
        if(api.selected) {
          api["apiPlanUuid"] = null;
          api["apiPlanName"] = null;
        }
        if(reqApi.apiPlanUuid !== api.apiPlanUuid) {
          api['selected'] = true;
          api['request'] = getI18nFormattedMessage('label.request.details.section.api.plan.changed');
        }
      }
    } else {
      api["request"] = getI18nFormattedMessage('label.request.details.section.api.removed');
      api['selected'] = true;
    }
  });
  return originalApis;
};
const buildStatusChangeDataTable = (prevStatus, reqStatus) => [{
  uuid: 'STATUS',
  field: getI18nFormattedMessage('label.request.details.section.field.status'),
  value: startCase(lowerCase(prevStatus)),
  requestValue: startCase(lowerCase(reqStatus)),
  selected: prevStatus !== reqStatus,
}];

const buildKeyDetailsData = (apiKey, requestKeys) => [{
  uuid: `KEY_NAME_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.keyname'),
  value: apiKey.name,
  requestValue: requestKeys && requestKeys.name,
},{
  uuid: `KEY_IS_DEFAULT_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.isdefault'),
  value: apiKey.defaultKey ? "true" : "false",
  requestValue: requestKeys && requestKeys.defaultKey ? "true" : "false",
},{
  uuid: `KEY_STATUS_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.status'),
  value: apiKey.status,
  requestValue: requestKeys && requestKeys.status,
},{
  uuid: `KEY_OAUTH_URL_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.oauth.url'),
  value: apiKey.oauthCallbackUrl,
  requestValue: requestKeys && requestKeys.oauthCallbackUrl,
}, {
  uuid: `KEY_OAUTH_SCOPE_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.oauth.scope'),
  value: apiKey.oauthScope,
  requestValue: requestKeys && requestKeys.oauthScope,
}, {
  uuid: `KEY_OAUTH_TYPE_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.oauth.type'),
  value: apiKey.oauthType,
  requestValue: requestKeys && requestKeys.oauthType,
}, {
  uuid: `KEY_API_KEY_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.apikey'),
  value: apiKey.apiKey,
  requestValue: requestKeys && requestKeys.apiKey,
}, {
  uuid: `KEY_SECRET_${apiKey.apiKey}`,
  field: getI18nFormattedMessage('label.request.details.section.field.secret'),
  value: apiKey.keySecret,
  requestValue: requestKeys && requestKeys.keySecret,
}];
