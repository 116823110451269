import React, { forwardRef } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { string } from 'prop-types';

export const MarkdownView = forwardRef(
    (
        {
            value,
            ...props
        },
        ref,
    ) => {
        return (
            <div ref={ref} {...props}>
                <MDEditor.Markdown data-color-mode="light" source={value} />
            </div>
        );
    },
);

MarkdownView.propTypes = {
    value: string,
};