import colorMod from 'color';
import { get } from 'lodash';

export default ({ color, fontSize, typography }) => ({
  HeaderRequestsButton: {
    display: 'inline-block',
    marginRight: 8,
    '& button': {
      padding: 8,
      color: get(color, 'headerLink'),
      border: '1px solid',
      borderRadius: 3,
      borderColor: 'rgba(0, 0, 0, 0)',
      '&:hover': {
        color: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      '&:focus': {
        borderColor: colorMod(get(color, 'headerLink')).alpha(0.7).string(),
      },
      '&:active': {
        borderColor: get(color, 'headerLink'),
      },
    },
    '.skipPrint &': {
      '@media print': {
        display: 'none',
      },
    },
  },

  NotificationsIcon: {
    width: 24,
    height: 24,
  },

  BadgePrimary: {
    backgroundColor: '#DE1B1B',
    color: '#FFFFFF',
    fontFamily: get(typography, 'bodyText'),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  Popper: {
    marginTop: 4,
  },

  MenuPaper: {
    borderRadius: 3,
    boxShadow: 'none',
    filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },

  Menu: {
    fontFamily: get(typography, 'bodyText'),
    margin: '0 0 0 0 !important',
    maxHeight: '500px',
    overflowY: 'auto',
    padding: '4px 0 12px 0 !important',
    width: 225,
  },

  HeaderRequestsMenuItem: {
    borderLeft: '3px solid !important',
    borderColor: 'rgba(0, 0, 0, 0) !important',
    color: `${get(color, 'bodyText')} !important`,
    display: 'block',
    fontSize: `${get(fontSize, 'bodyText')}`,
    lineHeight: '36px !important',
    overflow: 'hidden',
    padding: '0 12px 0 12px !important',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
    },
    '&:focus': {
      backgroundColor: `${colorMod(get(color, 'headerLink')).alpha(0.08).string()} !important`,
      borderColor: `${get(color, 'headerLink')} !important`,
      outline: 'none !important',
    },
    '& a': {
      color: `${get(color, 'bodyText')} !important`,
      fontSize: `${get(fontSize, 'bodyText')} !important`,
      fontFamily: `${get(typography, 'bodyText')} !important`,
      fontStyle: 'normal !important',
      fontWeight: 'normal !important',
      textDecoration: 'none !important',
    },
  },

  menuItemClass: {
    marginBottom: 8,
    marginTop: 8,
  },

  HeaderRequestsNoRequestsIcon: {
    color: `${get(color, 'headerLink')} !important`,
    display: 'inline-block',
    height: '24px',
    margin: '0 auto',
    paddingBottom: '6px',
    paddingTop: '6px',
    textAlign: 'center',
    width: '100%',
  },

  NoRequestsIcon: {
    height: '24px !important',
    width: '24px !important',
  },

  HeaderRequestsNoRequestsText: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: 'bold !important',
    lineHeight: '36px !important',
    margin: '0 auto !important',
    textAlign: 'center',
    width: '100%',
  },

  HeaderRequestsNoRequestsMessage: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'bodyText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: '100 !important',
    margin: '0 auto',
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: 'center',
    width: '100%',
  },

  SubHeaderUppercase: {
    color: `${get(color, 'bodyText')} !important`,
    fontSize: `${get(fontSize, 'smallText')} !important`,
    fontStyle: 'normal !important',
    fontWeight: 'bold !important',
    lineHeight: '36px !important',
    opacity: '1.0 !important',
    padding: '0 12px 0 12px !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subTitle: {
    color: colorMod(get(color, 'bodyText')).alpha(0.7).string(),
  },
});
