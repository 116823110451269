import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';

// globals must be imported before other modules
// as variables like noZensmooth are referred in global context

import './globals';
import './index.css';
import App from './App';

import loadPortalConfig,
  { loadHeaderConfig,
    loadCookieConsentConfig,
    checkValidSession,
    loadNoAuthConfig,
  }
from './config';

async function init() {
  let portalConfig;
  const root = document.getElementById('devconsole-16-root');
  const validSession = await checkValidSession();
  const isLoggedIn = get(validSession, 'status');
  if (root) {
    if(!isLoggedIn && window.location.pathname === '/admin/app/home') {
      portalConfig = await loadNoAuthConfig();
      ReactDOM.render(
        <App portalConfig={portalConfig} isLoggedIn={isLoggedIn} component="root" />,
        root,
      );
    } else {
      portalConfig = await loadPortalConfig();
      ReactDOM.render(
        <App portalConfig={portalConfig} isLoggedIn={isLoggedIn} component="root" />,
        root,
      );
    }
  } else {
    const globalHeader = document.getElementById('global-header');
    const footerElement = document.getElementById('global-footer');
    const cookieConsentElement = document.getElementById('global-cookie-consent');
    if (globalHeader) {
      if (!portalConfig) {
        portalConfig = await loadHeaderConfig();
      }
      ReactDOM.render(
        <App portalConfig={portalConfig} component="header" />,
        globalHeader,
      );
      if (footerElement) {
        ReactDOM.render(
          <App portalConfig={portalConfig} component="footer" />,
          footerElement,
        );
      }
      if (cookieConsentElement) {
        ReactDOM.render(
          <App portalConfig={portalConfig} component="cookieConsent" />,
          cookieConsentElement,
        );
      }
    } else {
      if (!portalConfig) {
        portalConfig = await loadCookieConsentConfig();
      }
      if (cookieConsentElement) {
        ReactDOM.render(
          <App portalConfig={portalConfig} component="cookieConsent" />,
          cookieConsentElement,
        );
      }
    }
  }
}

window.addEventListener('DOMContentLoaded', () =>
  init(),
);
