import { get } from 'lodash';

const styles = ({ theme, color }) => ({
  appBar: {
    paddingTop: '24px',
    top: 'auto',
    bottom: 0,
    background: get(theme, 'color.uiBackground'),
    minHeight: '84px',
    position: 'fixed',
    width: 'auto !important',
    right: 0,
    left: 0,
    paddingBottom: '2px',
    transition: "height 4s linear",
  },
  assistText: {
    color: get(theme, 'color.bodyText'),
    fontSize: get(theme, 'fontSize.smallText'),
    float: 'left',
    paddingTop: '5px',
    paddingRight: '8px',
  },
  cancelContainer: {
    paddingLeft: '30px',
  },
  cancelButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonBackground'),
    backgroundColor: get(theme, 'color.primaryButtonText'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
    lineHeight: get(theme, 'lineHeight.lh20'),
    '&:focus': {
      outlineWidth: '1px !important',
      outlineOffset: '-4px !important',
      outlineStyle: 'solid !important',
      outlineRadius: '3px !important',
    },
  },
  saveContainer: {
    float: 'right',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  saveButton: {
    textTransform: 'none',
    color: get(theme, 'color.primaryButtonText'),
    backgroundColor: get(theme, 'color.primaryButtonBackground'),
    borderColor: get(theme, 'color.primaryButtonBackground'),
    float: 'left',
    marginRight: '10px',
    lineHeight: get(theme, 'lineHeight.lh20'),
    '&:focus': {
      backgroundColor: get(color, 'borderColor'),
    },
  },
  '@media screen and (min-width: 400px) and (max-width: 900px)': {
    assistText: {
      fontSize: '10px',
    },
  },
});

export default styles;
