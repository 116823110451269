import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import {
  Button,
  Grid,
  withStyles,
  Link,
  Radio,
} from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import { useIntl } from 'react-intl';
import { cloneDeep, difference, findIndex, each, keys, map, get } from 'lodash';
import axios from 'axios';

import {
  FilterByName, FilterByTier,
} from './controls';
import styles from './styles';
import {
  FormActionButtons,
  Switch,
  AlertDialog,
  ToolTip,
  ErrorContainer,
} from '../../../../components';
import ListContainer from '../../../list';
import {
  GRID_ROWS_PER_PAGE_EXTRA_OPTIONS,
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24,
 } from '../../../../constants';
import {
  fetchProducts,
  updateOrgsProducts,
} from '../../../../actions/organization';
import { fetchTiers } from '../../../../actions/products';
import {
  getIsProductUpdateSuccess,
  getProductsListTotalPages,
  getProductsListTotalElements,
  getProductsListResults,
  getProductTiers,
  getErrors,
} from '../../../../reducers/organization';
import { getTiers } from '../../../../reducers/products';
import { getConfig } from '../../../../reducers/portalConfig';
import { getI18n } from '../../../../utils/intl';

const CHANGE_MODE_REMOVE_ACCESS = 'CHANGE_MODE_REMOVE_ACCESS';
const CHANGE_MODE_ADD_ACCESS = 'CHANGE_MODE_ADD_ACCESS';

export const Products = (props) => {
  const {
    orgUuid,
    classes,
    fetchList,
    fetchTiers,
    isProductUpdateSuccess,
    notifyMessages,
    rows = [],
    totalElements,
    totalPages,
    updateOrgsProducts,
    portalConfig,
    filterTiersListResults,
    errors,
  } = props;

  const { getI18nMessage } = getI18n(useIntl());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION_24);
  const [filterByName, setFilterByName] = useState('');
  const [filterByTier, setFilterByTier] = useState('all');
  const [showFormActionBtns, setShowFormActionBtns] = useState(false);
  const [changeMode, setChangeMode] = useState(CHANGE_MODE_REMOVE_ACCESS);
  const [currentProductsRows, setCurrentProductRows] = useState([]);
  const [modifiedRecords, setModifiedRecords] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showTierDialog, setShowTierDialog] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [filterTiersList, setFilterTiersList] = useState([])
  const emptyFunction = () => {};

  useEffect(() => {
    if (orgUuid) {
      fetchListWithFilters();
      fetchTiers();
    }
  }, [orgUuid]);

  useEffect(() => {
    setFilterTiersList([{ uuid: 'all', name: 'All Tiers' }, ...filterTiersListResults]);
  }, [filterTiersListResults]);

  const getListColumns = ({ getI18nMessage }) => [{
    id: 'productName',
    label: getI18nMessage('label.api.details.apiproducts.list.product.title'),
    minWidth: 200,
    value: (item) => (
      <Link underline="none" href={`/admin/products/details/${item.productUuid}`}>
        {item.productName}
      </Link>
    ),
  },{
    id: 'productApiAccessStatus',
    label: getI18nMessage('label.assign'),
    minWidth: 50,
    // eslint-disable-next-line react/prop-types
    format: ({ hasAccess, uuid }) =>
      (<Switch
        onChange={(val) => onAccessToggle(val, uuid)}
        checked={hasAccess}
      />
    ),
    value: ({ productUuid, orgProductAccessStatus }) => ({ uuid: productUuid, hasAccess: orgProductAccessStatus === 'ASSIGNED' }),
  }, {
    id: 'tierName',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nMessage('label.product.tiers.title')}
        info={<div>{getI18nMessage('label.organization.tier.tooltip')}</div>}
      />
    ),
    minWidth: 200,
    value: (item) => <Link className={classes.link} onClick={() => onTierClick(item)} underline="none">{item.tierName}</Link>,
  }, {
    id: 'rateQuotaName',
    label: (
      <ToolTip
        classes={classes}
        label={getI18nMessage('label.product.per.app.limit')}
        info={<div>{getI18nMessage('label.organization.product.per.app.tooltip')}</div>}
      />
    ),
    minWidth: 200,
    value: (item) => <Link className={classes.link} onClick={() => onTierClick(item)} underline="none">{item.rateQuotaName}</Link>,
  }];

  const getTierListColumns = () => [{
    id: 'selected',
    label: '',
    minwidth: 30,
    maxWidth: 40,
    value: (item) =>
      <Radio id={item.tierUuid}
        name="tier-radio"
        checked={item.tierUuid === get(selectedTier, 'tierUuid')}
        onChange={() => setSelectedTier(item)}
        className={classes.radioButton}
      />,
    },
    {
    id: 'tierName',
    label: getI18nMessage('label.tier'),
    minwidth: 100,
    value: (item) => <span className={classes.linkText}>
      {item.tierName}
      {item.isDefaultTier &&
        <span className={classes.tooltipContainer}>
          {getI18nMessage('label.default')}
        </span>
      }
    </span>,
  },
  {
    id: 'rateQuotaName',
    label: getI18nMessage('label.product.per.app.rate.limit.quota'),
    minwidth: 100,
    value: (item) => <span>
      <p className={classes.rate}>{get(item, 'rateQuota.name')}</p>
      <p className={classes.rate}>{get(item, 'rateQuota.rateLimit') ? `Rate: ${get(item, 'rateQuota.rateLimit')}/sec` : ''}</p>
      <p className={classes.rate}>{get(item, 'rateQuotaName.quota') ?
        `Quota: ${get(item, 'rateQuotaName.quota')}/${get(item, 'rateQuotaName.quotaInterval')}`:''}
      </p>
    </span>,
  }];

  const tiersList = () => (
    <ListContainer
        listPageId="org-products-tiers-list-dialog"
        isLoading={false}
        columns={getTierListColumns()}
        rows={tiers}
        noResultsMessage={getI18nMessage('label.application.details.apis.apiplans.no.results')}
        hasPaginationDisabled={true}
        hidePagination
        hideFooter
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
      />
  );

  useEffect(() => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS) || isFormDirty) {
      setShowFormActionBtns(true);
    }
    if ((changeMode !== CHANGE_MODE_ADD_ACCESS) && !isFormDirty) {
      setShowFormActionBtns(false);
    }
  }, [changeMode, isFormDirty]);

  useEffect(() => {
    setCurrentProductRows(cloneDeep(rows));
  }, [rows]);

  useEffect(() => {
    if (isProductUpdateSuccess) {
      notifyMessages('success', getI18nMessage('label.product.updated'));
      setChangeMode(CHANGE_MODE_REMOVE_ACCESS);
      setPage(0);
      fetchListWithFilters();
      setTimeout(() => {
        notifyMessages('', '');
      }, 2500);
    }
  }, [isProductUpdateSuccess]);

  const fetcTiers = async(uuid) => {
    const { portal } = portalConfig;
    const url = `${portal.hostname}/api/${portal.tenantPrefix}/api-management/0.1/access/tiers-product/${uuid}?productTierAccessStatus=ASSIGNED`;
    const response = await axios.get(url);
    return get(response, 'data') || [];
  }

  const onAccessToggle = async(val, uuid) => {
    let tier = {};
    if(changeMode === CHANGE_MODE_ADD_ACCESS) {
      const tiers = await fetcTiers(uuid);
      tier = tiers.find((o) => o.isDefaultTier);
    }
    updateCurrentProductRow(uuid, !!val, tier);
  };

  const onTierClick = async(product) => {
    setSelectedProduct(product);
    setSelectedTier(product);
    const tiers = await fetcTiers(product.productUuid);
    setTiers(tiers);
    setShowTierDialog(true);
  }

  useEffect(() => {
    const rowsMap = {};
    each(rows, item => {
      rowsMap[item.productUuid] = item;
    });
    const currentProductsRowsMap = {};
    each(currentProductsRows, item => {
      currentProductsRowsMap[item.productUuid] = item;
    });
    const rowIds = keys(rowsMap);
    const currentProductsRowsIds = keys(currentProductsRowsMap);
    if ( difference(rowIds, currentProductsRowsIds).length !== 0) {
      return ;
    }
    let modifiedRecords = 0;
    each(rowIds, id => {
      if (rowsMap[id].orgProductAccessStatus !==
        currentProductsRowsMap[id].orgProductAccessStatus ||
        rowsMap[id].tierUuid !== currentProductsRowsMap[id].tierUuid) {
        modifiedRecords++;
      }
    });
    setModifiedRecords(modifiedRecords);
  }, [currentProductsRows]);

  useEffect(() => {
    if (modifiedRecords > 0) {
      setIsFormDirty(true);
      setModifiedRecords(modifiedRecords);
    } else {
      setIsFormDirty(false);
    }
  }, [modifiedRecords]);

  useEffect(() => {
    fetchListWithFilters();
  }, [changeMode]);

  const updateCurrentProductRow = (uuid, access, tier) => {
    const index = findIndex(currentProductsRows, item => item.productUuid === uuid);
    currentProductsRows[index].orgProductAccessStatus = access ? 'ASSIGNED' : 'NOT_ASSIGNED';
    if(tier && tier.tierUuid) {
      currentProductsRows[index].tierName = tier.tierName;
      currentProductsRows[index].tierUuid = tier.tierUuid;
      currentProductsRows[index].rateQuotaUuid = get(tier, 'rateQuota.uuid');
      currentProductsRows[index].rateQuotaName = get(tier, 'rateQuota.name');
      currentProductsRows[index].isDefaultTier = tier.isDefaultTier;
    }
    setCurrentProductRows([...currentProductsRows]);
  };

  const fetchListWithFilters = ({
    hasAccess = changeMode === CHANGE_MODE_REMOVE_ACCESS ? true : false,
    name = filterByName,
    tierName,
    pageNum = page,
    rowsPerPageArg = rowsPerPage,
  } = { }) => {
    fetchList({
      orgUuid,
      filterByName : name,
      tierName,
      hasAccess,
      page: pageNum,
      size: rowsPerPageArg,
    });
  };
  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      fetchListWithFilters({ pageNum: 0 });
    }
  };

  const onFilterByTier = (tierUuid) => {
    setPage(0);
    setFilterByTier(tierUuid);
    const tier = filterTiersList.find( ({ uuid }) => uuid === tierUuid);
    fetchListWithFilters({ pageNum: 0, tierName: get(tier, 'name') });
  }

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    fetchListWithFilters({ pageNum: newPage });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchListWithFilters({ pageNum: 0, rowsPerPageArg : newRowsPerPage });
  };

  const addButtonLabel = getI18nMessage('label.product.add');
  const handleAddButton = () => {
    setChangeMode(CHANGE_MODE_ADD_ACCESS);
    setPage(0);
  };
  const showAddBtn = changeMode === CHANGE_MODE_REMOVE_ACCESS;
  const addBtnFunc = showAddBtn ? handleAddButton : emptyFunction;

  const saveUpdatedRows = () => {
    if (changeMode === CHANGE_MODE_REMOVE_ACCESS) {
      const removedAPIProducts = currentProductsRows.filter(item => item.orgProductAccessStatus === 'NOT_ASSIGNED');
      if(removedAPIProducts.length > 0 ) {
        const data = map(removedAPIProducts,
            item => ({ productUuid: item.productUuid, tierUuid: item.tierUuid }),
          );
          updateOrgsProducts({
          orgUuid,
          action: 'remove',
          data,
        });
      }
    }
    const addededAPIProducts = currentProductsRows.filter(item => item.orgProductAccessStatus === 'ASSIGNED');
    if (addededAPIProducts.length > 0 && changeMode === CHANGE_MODE_ADD_ACCESS) {
      const data = map(addededAPIProducts,
          item => ({ productUuid: item.productUuid, tierUuid: item.tierUuid }),
        );
        updateOrgsProducts({
        orgUuid,
        data,
      });
    }
  };

  const onCancel = () => {
    if ((changeMode === CHANGE_MODE_ADD_ACCESS) && !isFormDirty) {
      setChangeMode(CHANGE_MODE_REMOVE_ACCESS);
    } else {
      setCurrentProductRows(cloneDeep(rows));
    }
  }

  const onModalClose = () => {
    setSelectedTier('');
    setSelectedProduct('');
    setShowTierDialog(false);
  };
  
  const updateTier = () => {
    const index = findIndex(currentProductsRows, item =>
      item.productUuid === selectedProduct.productUuid);
    currentProductsRows[index].tierName = selectedTier.tierName;
    currentProductsRows[index].tierUuid = selectedTier.tierUuid;
    currentProductsRows[index].rateQuotaUuid = get(selectedTier, 'rateQuota.uuid') ?
      get(selectedTier, 'rateQuota.uuid') : selectedTier.rateQuotaUuid;
    currentProductsRows[index].rateQuotaName = get(selectedTier, 'rateQuota.name') ?
      get(selectedTier, 'rateQuota.name') : selectedTier.rateQuotaName;
    currentProductsRows[index].isDefaultTier = selectedTier.isDefaultTier;
    setCurrentProductRows([...currentProductsRows]);
    setSelectedTier('');
    setSelectedProduct('');
    setShowTierDialog(false);
  }

  const assistText = isFormDirty ? (<span>
    {getI18nMessage('label.api.details.apiproducts.list.save.assist.text', { num: modifiedRecords })}
    <WarningIcon className={classes.warningIcon} />
    {getI18nMessage('label.redepolyment.required')}
  </span>) : '';

  return (
    <Fragment>
      {showTierDialog && (
        <AlertDialog
          id="org-product-list-tiers-dialog"
          isOpen={showTierDialog}
          title={getI18nMessage('label.organization.tier.select.org', { orgName: get(selectedProduct, 'productName') })}
          component={
            tiersList()
          }
          submitText={getI18nMessage('label.apply.button')}
          cancelText={getI18nMessage('label.cancel.button')}
          onClose={onModalClose}
          onSubmit={updateTier}
          onCancel={onModalClose}
          containerClass={classes.dialogContainer}
          dialogActionClass={classes.dialogActionClass}
        />
      )}
      {errors.length > 0 &&
        <ErrorContainer errors={errors} />
      }
      <ListContainer
        hasPaginationDisabled={isFormDirty}
        listPageId="products-list"
        isLoading={false}
        notificationId="request-notifications"
        notificationStatus={''}
        setNotificationStatus={() => {}}
        notificationMessage={''}
        setNotificationMessage={() => {}}
        pageHeaderTitle={''}
        pageHeaderDescription={''}
        pageHeaderTooltipTitle={getI18nMessage('label.request.list.page.title.help')}
        addButtonLabel={''}
        onAdd={false}
        showListHeader={true}
        filterAndSortContent={(
          <Fragment>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              handleChange={setFilterByName}
              name={getI18nMessage('label.filter')}
              onKeyPress={onFilterByNameKeyPress}
              placeholder={getI18nMessage('label.product')}
              value={filterByName}
            />
            {changeMode === CHANGE_MODE_REMOVE_ACCESS &&
              <FilterByTier
                handleChange={onFilterByTier}
                name={getI18nMessage('label.filter')}
                id={`orgs-products`}
                data={filterTiersList}
                value={filterByTier}
                fieldContainerClass={classes.fieldContainer}
                hideLabel
              />
            }
            <Grid item md={4} sm={4} xs={6}>
              {showAddBtn && <Button
                className={classes.addBtn}
                data-apim-test={'org-products-add'}
                data-layer7-test={'org-products-add'}
                disabled={isFormDirty}
                id={'org-products-add'}
                onClick={addBtnFunc}
                variant="contained" color="secondary"
                fieldContainerClass={classes.fieldContainer}
              >
                {addButtonLabel}
              </Button>}
            </Grid>
          </Fragment>
        )}
        columns={getListColumns({ getI18nMessage, classes })}
        rows={currentProductsRows}
        noResultsMessage={getI18nMessage('label.no.results')}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_EXTRA_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        notifyMessages={emptyFunction}
        pageBodyClass={classes.pageBodyClass}
        pageClass={classes.pageClass}
      />
      {showFormActionBtns && <FormActionButtons
        assistText={assistText}
        isDisabled={!isFormDirty}
        nextText={'Save'}
        onCancelClick={onCancel}
        onNextClick={saveUpdatedRows}
        id="org-products-form-buttons"
      />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  rows: getProductsListResults(state),
  totalElements: getProductsListTotalElements(state),
  totalPages: getProductsListTotalPages(state),
  isProductUpdateSuccess: getIsProductUpdateSuccess(state),
  productTiers: getProductTiers(state),
  portalConfig: getConfig(state),
  filterTiersListResults: getTiers(state),
  errors: getErrors(state),
});

Products.propTypes = {
  orgUuid: string,
  apiDetails: object,
  classes: shape({}),
  fetchList: func,
  isApiUpdateSuccess: bool,
  notifyMessages: func,
  rows: arrayOf(shape({})),
  totalElements: number,
  totalPages: number,
  updateOrgsProducts: func,
  userContext: object,
  productTiers: arrayOf(shape({})),
  isProductUpdateSuccess: bool,
  portalConfig: arrayOf(shape({})),
  filterTiersListResults: arrayOf(shape({})),
  fetchTiers: func,
  errors: arrayOf(shape({})),
};
Products.displayName = 'Products';

const mapDispatchToProps = {
  fetchList: fetchProducts,
  updateOrgsProducts,
  fetchTiers,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Products);
