import { get } from 'lodash';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  info: {
    backgroundColor: get(theme, 'color.iconsLight'),
    color: get(theme, 'color.black'),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  closeButton: {
    fontSize: 14,
    color: get(theme, 'color.primaryButtonText'),
  },
  icon: {
    fontSize: 20,
  },
  doneIcon: {
    color: get(theme, 'color.success'),
  },
  errorIcon: {
    color: get(theme, 'color.error'),
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: get(theme, 'typography.bodyText'),
    fontSize: get(theme, 'fontSize.bodyText'),
  },
  messageContainer: {
    marginRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: '0px',
    minHeight: '40px',
    top: '70px',
    position: 'fixed',
    zIndex: get(theme, 'zIndex.z800'),
    marginLeft: '-20px',
    width: '54%',
    '& .MuiSnackbarContent-message': {
      padding: 0,
      paddingLeft: '0px',
      width: '80%',
    },
    '& .MuiSnackbarContent-action': {
      borderLeft: `1px solid ${get(theme, 'color.white')}`,
      paddingLeft: '0px',
      marginRight: '-12px',
    },
    '& .MuiIconButton-root': {
      padding: '10px',
    },
  },
});

export default styles;
