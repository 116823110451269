import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button, withStyles, Tooltip } from '@material-ui/core';
import { get, isEmpty, lowerCase, upperFirst, uniq } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HelpIcon from '@material-ui/icons/Help';

import {
  fetchOrganizations,
  fetchRateLimitQuotas,
  createAndUpdateOrganizationTag,
  updateOrganizationTags,
} from '../../../actions/organization';
import { getConfig, getUserDetails } from '../../../reducers/portalConfig';
import {
  getOrganizationsListTotalPages,
  getOrganizationsListTotalElements,
  getOrganizationsListResults,
  getRateLimitQuotas,
  getIsListError,
  getIsLoading,
  getIsUpdatingOrganizationTags,
  getIsUpdatedOrganizationTags,
  getCreatedTagUuid,
  getUpdateOrganizationTagsErrors,
} from '../../../reducers/organization';
import styles from './styles';
import ListContainer from '../../list';
import {
  FilterByName,
  FilterByRateLimitQuota,
  FilterByType,
  FilterByStatus,
  Tags,
} from './controls';
import FilterByTag from './controls/filterByTag';
import UpdateTags from './controls/updateTags';
import {
  GRID_ROWS_PER_PAGE_DEFAULT_OPTION,
  ALERT_SUCCESS,
  ALERT_ERROR,
  ANY,
  ALL,
  ORG_TAG_ADD,
  ORG_TAG_REMOVE,
} from '../../../constants';
import { getI18n, getI18nFormattedMessage } from '../../../utils/intl';
import {
  hasPublisherRole,
  hasAdminRole,
  hasOrgPublisherRole,
  getOrgStatusColor,
} from '../../../utils';
import useNotification from '../../../hooks/useNotification';

const formatType = (type) => upperFirst(lowerCase(type));
const getDetailsLink =(status, userContext) =>
    hasAdminRole(userContext) || (hasPublisherRole(userContext) && status === 'ENABLED')
        ? '/admin/console/organizations/details' : null;
const getLinkContent = (value, secondaryValue, classes) => (
  <div>
    { value }
    {secondaryValue &&
      <span className={classes.tooltipContainer}>
        {getI18nFormattedMessage('label.default')}
      </span>
    }
  </div>
);
export const getOrganizations = ({ results, rateLimitQuotas }) =>
  results.map(item => {
    const arr = rateLimitQuotas.filter((org) =>
      org.uuid === item.accountPlanUuid);
    return {
      ...item,
      rateLimitQuotaName: arr.length === 1 ? arr[0].name : '',
      defaultRateQuota: arr.length === 1 ? arr[0].defaultRateQuota : '',
    }
  });

export const TypeHeaderTooltip = () => {
  const intl = getI18n(useIntl());
  return (
    <div>
      <div>
        {`${intl.getI18nMessage('label.organization.list.page.filter.by.publisher.types')}:
          ${intl.getI18nMessage('label.organization.list.page.help.text.publisher')}`}
      </div>
      <div>
        {`${intl.getI18nMessage('label.organization.list.page.filter.by.consumer.types')}:
        ${intl.getI18nMessage('label.organization.list.page.help.text.consumer')}`}
      </div>
    </div>
  );
}

export const TypeHeader = ({ classes, label }) => (
  <div className={classes.helpIconContainer}>
    <div>
      {label}
    </div>
    <Tooltip title={<TypeHeaderTooltip />} arrow placement="right">
      <HelpIcon className={classes.helpIcon} />
    </Tooltip>
  </div>
);

TypeHeader.propTypes = {
  classes: object,
  label: object,
}

export const getOrgListColumns = ({ classes, userContext }) => [{
  id: 'name',
  label: getI18nFormattedMessage('label.organization.name'),
  minWidth: 200,
  link: (item) => getDetailsLink(item.status, userContext),
}, {
  id: 'tags',
  label: getI18nFormattedMessage('label.organization.tags'),
  minWidth: 300,
  value: Tags,
}, {
  id: 'rateLimitQuotaName',
  secondaryId: 'defaultRateQuota',
  label: getI18nFormattedMessage('label.organization.rate.limit.quota'),
  minWidth: 200,
  format: (value, secondaryValue) => {
    return getLinkContent(value, secondaryValue, classes);
  },
}, {
  id: 'type',
  label: (
    <TypeHeader classes={classes} label={getI18nFormattedMessage('label.organization.type')} />
  ),
  minWidth: 100,
  value: (item) => formatType(item.type),
}, {
  id: 'status',
  label: getI18nFormattedMessage('label.organization.state'),
  minWidth: 100,
  value: (item) => {
    const { status } = item;
    const statusLabel = upperFirst(lowerCase(status))
    const statusIconStyle = {
      fill: getOrgStatusColor(status),
    };
    return (
      <span>
          <FiberManualRecordIcon size="0.82em" style={statusIconStyle} className={classes.statusIcon} />{statusLabel}
      </span>);
  },
}];

export const OrganizationListContext = createContext();

export const OrganizationList = (props) => {
  const {
    classes,
    userContext,
    isError,
    isLoading,
    totalPages,
    totalElements = 0,
    results = [],
    rateLimitQuotas,
    isUpdatedOrganizationTags,
    createdTagUuid,
    updateOrganizationTagsErrors,
    defaultSelectedRows = [],
  } = props;

  const intl = getI18n(useIntl());
  const [notification, setNotification] = useNotification({ status: '', message: '' });
  const [filterByName, setFilterByName] = useState('');
  const [filterByTag, setFilterByTag] = useState([]);
  const [filterByRateLimitQuota, setFilterByRateLimitQuota] = useState();
  const [filterByType, setFilterByType] = useState(ANY);
  const [filterByStatus, setFilterByStatus] = useState(ALL);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [filterByAnyApplied, setFilterByAnyApplied] = useState(false);
  const [hideAddButton, setHideAddButton] = useState(true);
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows);
  const [undoTagUpdate, setUndoTagUpdate] = useState();

  const isAdmin = useMemo(
    () => hasAdminRole(userContext),
    [userContext],
  );

  useEffect(() => {
    props.fetchRateLimitQuotas();
    props.fetchOrganizations({
      name: filterByName,
      type: filterByType,
      page,
      size: rowsPerPage,
    });

    const isUpdateOrg = localStorage.getItem('isUpdateOrg');
    const isCreateOrg = localStorage.getItem('isCreateOrg');
    if (isUpdateOrg || isCreateOrg) {
      localStorage.removeItem('isUpdateOrg');
      localStorage.removeItem('isCreateOrg');
      const message = isUpdateOrg ?
        intl.getI18nMessage('label.organization.updated.message') :
        intl.getI18nMessage('label.organization.created.message');
      setNotification({ status: ALERT_SUCCESS, message });
      const timeoutID = window.setTimeout(() => {
        setNotification();
      }, 3000);
      // make sure to return the function which clears timer if timer is used
      return () => window.clearTimeout(timeoutID);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!hasPublisherRole(userContext) && !hasOrgPublisherRole(userContext)) {
      props.push('/404');
    }
    if (isAdmin) {
      setHideAddButton(false);
    }
  }, [userContext]);

  useEffect(() => {
    if (isError) {
      setNotification({
        status: ALERT_ERROR,
        message: intl.getI18nMessage('error.organization.list.fetch'),
      });
    }
    if (!isEmpty(updateOrganizationTagsErrors)) {
      setNotification({
        status: ALERT_ERROR,
        message: updateOrganizationTagsErrors
          .map(({ error }) => (error))
          .join(', '),
      });
    }
  }, [isError, updateOrganizationTagsErrors]);

  useEffect(() => {
    if (isUpdatedOrganizationTags) {
      const uuid = createdTagUuid || get(undoTagUpdate, 'tagUuid');
      const onClickUndo = () => {
        switch (undoTagUpdate.action) {
          case ORG_TAG_ADD:
            return onRemoveTag({ uuid });
          case ORG_TAG_REMOVE:
            return onAddTag({ uuid });
        }
      };
      setNotification({
        status: ALERT_SUCCESS,
        message: (
          <>
            <span>{intl.getI18nMessage('label.organization.list.tags.updated')}</span>
            <Button onClick={onClickUndo}>
              {intl.getI18nMessage('label.undo')}
            </Button>
          </>
        ),
      });
      props.fetchOrganizations({
        name: filterByName,
        tag: filterByTag,
        accountPlanUuid: filterByRateLimitQuota,
        type: filterByType,
        status: filterByStatus,
        page,
        size: rowsPerPage,
      });
      window.setTimeout(() => setNotification(), 3000);
    }
  }, [isUpdatedOrganizationTags]);

  const onFilterByNameChange = (value) => {
    setFilterByName(value);
  };

  const onFilterByNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilterByAnyApplied(true);
      setPage(0);
      props.fetchOrganizations({
        name: filterByName,
        tag: filterByTag,
        accountPlanUuid: filterByRateLimitQuota,
        type: filterByType,
        status: filterByStatus,
        page: 0,
        size: rowsPerPage,
      });
    }
  };

  const onFilterByTagChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByTag(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      tag: value,
      accountPlanUuid: filterByRateLimitQuota,
      type: filterByType,
      status: filterByStatus,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onFilterByRateLimitQuotaChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByRateLimitQuota(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      tag: filterByTag,
      accountPlanUuid: value,
      type: filterByType,
      status: filterByStatus,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onFilterByTypeChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByType(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      tag: filterByTag,
      accountPlanUuid: filterByRateLimitQuota,
      type: value,
      status: filterByStatus,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onFilterByStatusChange = (value) => {
    setFilterByAnyApplied(true);
    setFilterByStatus(value);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      tag: filterByTag,
      accountPlanUuid: filterByRateLimitQuota,
      type: filterByType,
      status: value,
      page: 0,
      size: rowsPerPage,
    });
  };

  const handleBlur = () => {
    setFilterByAnyApplied(true);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      tag: filterByTag,
      accountPlanUuid: filterByRateLimitQuota,
      type: filterByType,
      status: filterByStatus,
      page: 0,
      size: rowsPerPage,
    });
  };

  const onChangePage = (newPage) => {
    if (page === newPage) { return; }
    setPage(newPage);
    props.fetchOrganizations({
      name: filterByName,
      type: filterByType,
      page: newPage,
      size: rowsPerPage,
    });
  };
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };

  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    props.fetchOrganizations({
      name: filterByName,
      type: filterByType,
      page: 0,
      size: newRowsPerPage,
    });
  };

  const onClickTag = (tag) => {
    onFilterByTagChange(
      uniq([...filterByTag, tag]),
    );
  }
  const onAddNewTag = (tag) => {
    const payload = {
      tags: [{ name: tag }],
      orgUuid: selectedRows,
      action: ORG_TAG_ADD,
    }
    setUndoTagUpdate(payload);
    props.createAndUpdateOrganizationTag(payload);
  }
  const onAddTag = (tag) => {
    const payload = {
      tagUuid: tag.uuid,
      orgUuid: selectedRows,
      action: ORG_TAG_ADD,
    }
    setUndoTagUpdate(payload);
    props.updateOrganizationTags(payload);
  }
  const onRemoveTag = (tag) => {
    const payload = {
      tagUuid: tag.uuid,
      orgUuid: selectedRows,
      action: ORG_TAG_REMOVE,
    }
    setUndoTagUpdate(payload)
    props.updateOrganizationTags(payload);
  }

  const onAddOrganization = () => {
    props.push('/admin/console/organizations/add');
  };
  
  const organizations = getOrganizations({ results, rateLimitQuotas });
  const noResultsMessage = filterByAnyApplied ?
    intl.getI18nMessage('label.organization.filter.no.results') :
    intl.getI18nMessage('label.organization.no.results');

  return (
    <OrganizationListContext.Provider value={{ onClickTag }}>
      <ListContainer
        listPageId="organization-list-page"
        isLoading={isLoading}
        notificationId="organization-notifications"
        notificationStatus={notification.status}
        setNotificationStatus={(status) => setNotification({ status, message: '' })}
        notificationMessage={notification.message}
        setNotificationMessage={(message) => setNotification({ status: '', message })}
        pageHeaderTitle={intl.getI18nMessage('label.organization.list.page.title')}
        addButtonLabel={intl.getI18nMessage('label.organization.list.add.button')}
        onAdd={!hideAddButton && onAddOrganization}
        pageFilterAndSortClass={classes.pageFilterAndSort}
        filterAndSortContent={(
          <>
            <FilterByName
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByName}
              placeholder={intl.getI18nMessage('label.organization.list.page.filter.by.name.placeholder')}
              handleChange={onFilterByNameChange}
              onKeyPress={onFilterByNameKeyPress}
              handleBlur={handleBlur}
            />
            <FilterByTag
              {...props}
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByTag}
              placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.group.placeholder')}
              handleChange={onFilterByTagChange}
              hideLabel
            />
            <FilterByRateLimitQuota
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              selectFieldClass={classes.selectField}
              data={rateLimitQuotas}
              value={filterByRateLimitQuota}
              handleChange={onFilterByRateLimitQuotaChange}
              hideLabel
            />
            <FilterByType
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByType}
              placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.group.placeholder')}
              handleChange={onFilterByTypeChange}
              hideLabel
            />
            <FilterByStatus
              fieldContainerClass={classes.fieldContainer}
              name={intl.getI18nMessage('label.filter')}
              value={filterByStatus}
              placeholder={intl.getI18nMessage('label.gateway.bundle.list.page.filter.by.group.placeholder')}
              handleChange={onFilterByStatusChange}
              hideLabel
            />
          </>
        )}
        bulkActionsContent={
          isAdmin &&
          <UpdateTags
            {...props}
            fieldContainerClass={classes.fieldContainer}
            bulkActionsItemsCount={selectedRows.length}
            onAddNewTag={onAddNewTag}
            onAddTag={onAddTag}
            onRemoveTag={onRemoveTag}
          />
        }
        columns={getOrgListColumns(props)}
        rows={organizations}
        noResultsMessage={noResultsMessage}
        page={page}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </OrganizationListContext.Provider>
  );
};

OrganizationList.propTypes = {
  classes: object,
  userContext: object,
  isLoading: bool,
  isError: bool,
  push: func,
  totalPages: number,
  totalElements: number,
  results: arrayOf(object),
  rateLimitQuotas: arrayOf(object),
  isUpdatedOrganizationTags: bool,
  createdTagUuid: string,
  updateOrganizationTagsErrors: arrayOf(object),
  fetchOrganizations: func,
  fetchRateLimitQuotas: func,
  createAndUpdateOrganizationTag: func,
  updateOrganizationTags: func,
  defaultSelectedRows: arrayOf(string),
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  userContext: getUserDetails(state),
  isError: getIsListError(state),
  isLoading: getIsLoading(state),
  totalPages: getOrganizationsListTotalPages(state),
  totalElements: getOrganizationsListTotalElements(state),
  results: getOrganizationsListResults(state),
  rateLimitQuotas: getRateLimitQuotas(state),
  isUpdatingOrganizationTags: getIsUpdatingOrganizationTags(state),
  isUpdatedOrganizationTags: getIsUpdatedOrganizationTags(state),
  createdTagUuid: getCreatedTagUuid(state),
  updateOrganizationTagsErrors: getUpdateOrganizationTagsErrors(state),
});

const mapDispatchToProps = {
  push,
  fetchOrganizations,
  fetchRateLimitQuotas,
  createAndUpdateOrganizationTag,
  updateOrganizationTags,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationList);
