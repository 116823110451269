import { get } from 'lodash';
import * as Constants from '../../constants';

const resetState = {
  isError: false,
  errors: [],
  isProductSaveSuccess: false,
  isTiersProductSaveSuccess: false,
  isTiersSaveSuccess: false,
  deleteProductStatus: Constants.NONE,
};
const initialState = {
  ...resetState,
  isLoading: false,
  productDetails: {},
  tiersProduct: [],
  tiersProductLoading: false,
  apisList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  orgsList: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  products: {
    totalPages: 1,
    totalElements: 0,
    results: [],
  },
  tiers: [],
  isAPIListLoading: false,
  isApiUpdateSuccess: false,
  isOrgsListLoading: false,
  isOrgUpdateSuccess: false,
  isFetchOrgs: false,
  isTiersSaveSuccess: false,
  deleteProductStatus: Constants.NONE,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.APP_RESET:
      return {
        ...state,
        ...resetState,
      };
    case Constants.PRODUCTS_DETAILS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        productDetails: action.payload,
        isProductSaveSuccess: false,
      };
    case Constants.PRODUCTS_DETAILS_GET_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
      };
    case Constants.PRODUCTS_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errors: [],
        productDetails: action.payload,
        isProductSaveSuccess: true,
      };
    case Constants.PRODUCTS_SAVE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isProductSaveSuccess: false,
      };
    case Constants.PRODUCTS_APIS_GET_LIST_SUCCESS: {
      return {
        ...state,
        apisList: action.payload,
        errors: [],
        isAPIListLoading: false,
        isApiUpdateSuccess: false,
      };
    }
    case Constants.PRODUCT_LIST_SUCCESS: {
      const products = get(action, 'payload', {});
      return {
        ...state,
        products,
        errors: [],
        isError: false,
        isLoading: false,
      };
    }
    case Constants.PRODUCTS_APIS_GET_LIST_ERROR: {
      return {
        ...state,
        apisList: [],
        errors: action.payload,
        isAPIListLoading: false,
        isApiUpdateSuccess: false,
        isError: true,
        isLoading: false,
      };
    }
    case Constants.PRODUCTS_APIS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiUpdateSuccess: true,
      };
    }
    case Constants.PRODUCTS_APIS_REMOVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isApiUpdateSuccess: true,
      };
    }
    case Constants.PRODUCTS_APIS_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        isApiUpdateSuccess: false,
      };
    }
    case Constants.PRODUCT_LIST_ERROR: {
      return {
        ...state,
        products: { results: [] },
        errors: [],
        isError: true,
        isLoading: false,
      };
    }
    case Constants.PRODUCTS_ORGS_RESET_LIST: {
      return {
        ...state,
        isFetchOrgs: false,
      };
    }
    case Constants.PRODUCTS_ORGS_GET_LIST_SUCCESS: {
      return {
        ...state,
        orgsList: action.payload,
        errors: [],
        isOrgsListLoading: false,
        isOrgUpdateSuccess: false,
        isFetchOrgs: true,
      };
    }
    case Constants.PRODUCTS_ORGS_GET_LIST_ERROR: {
      return {
        ...state,
        orgsList: [],
        errors: action.payload,
        isOrgsListLoading: false,
        isOrgUpdateSuccess: false,
        isError: true,
        isLoading: false,
        isFetchOrgs: true,
      };
    }
    case Constants.PRODUCTS_ORGS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isOrgUpdateSuccess: true,
      };
    }
    case Constants.PRODUCTS_ORGS_REMOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isOrgUpdateSuccess: true,
      };
    case Constants.PRODUCTS_ORGS_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        isOrgUpdateSuccess: false,
      };
    case Constants.TIERS_PRODUCT_GET_REQUEST:
      return {
        ...state,
        tiersProductLoading: true,
      };
    case Constants.TIERS_PRODUCT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        tiersProduct: action.payload,
        tiersProductLoading: false,
        isFetchTiersProduct: true,
      };
    case Constants.PRODUCTS_TIERS_PRODUCT_RESET_LIST: {
      return {
        ...state,
        isFetchTiersProduct: false,
      }
    }
    case Constants.TIERS_PRODUCT_GET_ERROR:
      return {
      ...state,
      isError: true,
      isLoading: false,
      errors: action.payload,
    };
    case Constants.TIERS_PRODUCT_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isTiersProductSaveSuccess: true,
      };
    case Constants.TIERS_PRODUCT_SAVE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errors: action.payload,
        isTiersProductSaveSuccess: false,
      };
    case Constants.PRODUCTS_TIERS_SUCCESS: {
      const tiers = get(action, 'payload', []);
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        tiers,
      };
    }
    case Constants.PRODUCTS_TIERS_ERROR: {
      return {
        ...state,
        tiers: [],
        errors: action.payload,
        isError: true,
        isLoading: false,
      };
    }
    case Constants.PRODUCTS_TIERS_SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: [],
        isTiersSaveSuccess: true,
      };
    }
    case Constants.PRODUCTS_TIERS_SAVE_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isError: true,
        isLoading: false,
        isTiersSaveSuccess: false,
      };
    }
    case Constants.PRODUCT_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: [],
        isError: false,
        deleteProductStatus: Constants.SUCCESS,
      };
    }
    case Constants.PRODUCT_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.payload,
        deleteProductStatus: Constants.FAIL,
      };
    }
    default:
      return state;
  }
}

export const getProducts = (state) =>
  get(state, 'products.products.results', []);
export const getProductsListTotalElements = (state) =>
  get(state, 'products.products.totalElements', 0);
export const getProductsListTotalPages = (state) =>
  get(state, 'products.products.totalPages', 1);

export const getIsLoading = (state) => get(state, 'products.isLoading');

export const getIsError = (state) => get(state, 'products.isError');

export const getErrors = (state) => get(state, 'products.errors');

export const getIsProductSaveSuccess = (state) =>
  get(state, 'products.isProductSaveSuccess');

export const getproductDetails = (state) =>
  get(state, 'products.productDetails');

export const getApisListResults = (state) =>
  get(state, 'products.apisList.results');
export const getApisListTotalPages = (state) =>
  get(state, 'products.apisList.totalPages');
export const getApisListTotalElements = (state) =>
  get(state, 'products.apisList.totalElements');

export const getIsApiUpdateSuccess = (state) =>
  get(state, 'products.isApiUpdateSuccess');

export const getOrgsListResults = (state) => {
  const orgList = get(state, 'products.orgsList.results') || [];
  if(orgList.length > 0 ) {
   return orgList.map(org => { return { uuid: org.organizationUuid, ...org } });
  }
  return orgList;
};
export const getOrgsListTotalPages = (state) =>
  get(state, 'products.orgsList.totalPages');
export const getOrgsListTotalElements = (state) =>
  get(state, 'products.orgsList.totalElements');

export const getIsOrgUpdateSuccess = (state) =>
  get(state, 'products.isOrgUpdateSuccess');

export const getIsFetchOrgs = (state) => get(state, 'products.isFetchOrgs');

export const getTiersProduct = (state) => {
  const tiersProduct = get(state, 'products.tiersProduct') || [];
  if (tiersProduct.length > 0) {
    return tiersProduct.map((obj) => {
      return { uuid: obj.tierUuid, name: obj.tierName, ...obj };
    });
  }
  return tiersProduct;
}

export const getIsFetchTiersProduct = (state) => get(state, 'products.isFetchTiersProduct');

export const getIsTiersProductSaveSuccess = (state) =>
  get(state, 'products.isTiersProductSaveSuccess');

export const getTiers = (state) =>
  get(state, 'products.tiers', []);

export const getIsTiersSaveSuccess = (state) =>
  get(state, 'products.isTiersSaveSuccess');

export const getIsTiersProductLoading = (state) => get(state, 'products.tiersProductLoading');

export const getDeleteProductStatus = state => get(state, 'products.deleteProductStatus');
