import get from 'lodash/get';

const styles = ({ margin, padding } = {}) => ({
  fieldContainer: {
    margin: `${get(margin, 'm2')} ${get(margin, 'm4')} ${get(margin, 'm2')} ${get(margin, 'm0')} !important`,
  },
  autoContainer: {
    marginTop: `${get(margin, 'm24')} !important`,
    marginRight: `${get(margin, 'm5')}`,
    marginBottom: '0 !important',
  },
  selectField: {
    padding: `${get(padding, 'p2')} ${get(padding, 'p10')} !important`,
  },
});

export default styles;
