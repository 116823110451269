import { get } from 'lodash';

const styles = ({ color }) => ({
  actionsContainer: {
    display: 'inline-block',
    float: 'right',
    backgroundColor: get(color, 'primaryButtonBackground'),
    borderColor: get(color, 'primaryButtonBackground'),
    color: `${get(color, 'primaryButtonText')} !important`,
    margin: '0 36px',
    borderRadius: 4,
    '& nav': {
      padding: 0,
      '& > div > div > span': {
        color: get(color, 'primaryButtonText'),
      },
    },
    minWidth: 160,
  },
  collapseContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: get(color, 'uiBackground'),
    borderColor: get(color, 'uiBackground'),
    borderRadius: 4,
    zIndex: 1,
  },
  listButton: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listItem: {
    color: get(color, 'link'),
    backgroundColor: get(color, 'uiBackground'),
    borderColor: get(color, 'uiBackground'),
    borderRadius: 4,
  },
  submitButton: {
    backgroundColor: `${get(color, 'red')} !important`,
  },
});

export default styles;
