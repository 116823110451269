import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, Button, Grid } from '@material-ui/core';
import { func, object, arrayOf, string, bool, number } from 'prop-types';
import { pascalCase } from 'change-case';
import { map, uniq, intersection, union, difference, each, get } from 'lodash';
import { push } from 'connected-react-router';
import { getStatusColor, getStatusLabel } from './utils';

import IconGrid from '../icons/Icon_Grid.svg';
import IconGridSelected from '../icons/Icon_Grid_Selected.svg';
import IconList from '../icons/Icon_List.svg';
import IconListSelected from '../icons/Icon_List_Selected.svg';
import SearchBar from '../../../components/SearchBar';
import {
  MultiSelect,
  TagsGroup,
  FormDialog as Dialog,
} from '../../../components';
import ListContainer from '../../list';
import GridContainer from '../../grid';
import GridItem from './GridItem';
import {
  fetchAllTags,
  fetchAPIs,
  fetchAutoSuggestions,
  fetchLatencyData,
  fetchRecentAPISearches,
} from '../../../actions/api';
import { fetchApiPlanDetails } from '../../../actions/application';
import {
  getAllPortalTags,
  getAPIs,
  getAPIListTotalElements,
  getAPIListTotalPages,
  getRecentSearches,
  getAutoSuggestions,
  getIsAnalyticsEnabled,
  getIsAPIsListLoading,
  getMetricsLatencyData,
  getShouldRefreshMetricsData,
} from '../../../reducers/api';
import { getSingleApiPlan } from '../../../reducers/application';
import {
  getCanAddAPI,
  getUserDetails,
  getUserPrefs,
} from '../../../reducers/portalConfig';
import {
  FilterByState,
  FilterByTags,
  FilterByVisibility,
  SortBy,
} from './controls';
import { INFO } from '../../../constants';
import { getI18n } from '../../../utils/intl';
import usePrefs from '../../../hooks/userPrefs';
import styles from './styles';

export const GRID_ROWS_PER_PAGE_OPTIONS = [24, 48, 72, 96];
export const GRID_ROWS_PER_PAGE_DEFAULT_OPTION = 24;

const renderGridItem = (props) => {
  const { uuid } = props;
  return (<GridItem key={uuid} {...props} />);
};

export const getAPIListColumns = (intl, classes) => [{
  id: 'name',
  label: intl.getI18nFormattedMessage('label.api.list.column.name'),
  link: '/publish/apis/details',
}, {
  id: 'description',
  label: intl.getI18nFormattedMessage('label.api.list.column.description'),
  value: (item) => item.description,
}, {
  id: 'tags',
  label: intl.getI18nFormattedMessage('label.api.list.column.tags'),
  value: (item) => (
      <div className={classes.tagsGroupContainer}>
        <TagsGroup
          onTagClick={(tag) => item.onTagClick(tag, item.tags)}
          tags={item.tags}
        />
      </div>
    ),
}, {
  id: 'version',
  label: intl.getI18nFormattedMessage('label.api.list.column.version'),
}, {
  id: 'type',
  label: intl.getI18nFormattedMessage('label.api.list.column.type'),
  value: (item) => item.ssgServiceType,
}, {
  id: 'source',
  label: intl.getI18nFormattedMessage('label.api.list.column.source'),
  value: (item) => item.publishedByPortal ?
    intl.getI18nFormattedMessage('label.api.list.value.source.portal')
    :
    intl.getI18nFormattedMessage('label.api.list.value.source.gateway'),
}, {
  id: 'visibility',
  label: intl.getI18nFormattedMessage('label.api.list.column.visibility'),
  value: (item) => pascalCase(item.accessStatus),
}, {
  id: 'state',
  label: intl.getI18nFormattedMessage('label.api.list.column.state'),
  value: (item) => {
    const statusStyle = {
      backgroundColor: getStatusColor(item.portalStatus),
    };
    return (
      <span
        style={statusStyle}
        className={classes.itemState}
      >
        {getStatusLabel(item.portalStatus)}
      </span>);
  },
}];

export const ApiListPage = (props) => {
  const {
    allTags,
    apis,
    autoSuggestions,
    canAddAPI,
    push,
    classes,
    fetchAPIs,
    fetchAllTags,
    fetchAutoSuggestions,
    fetchLatencyData,
    fetchRecentAPISearches,
    fetchApiPlanDetails,
    isAnalyticsEnabled,
    isAPIsListLoading,
    latencyData,
    recentSearches,
    shouldRefreshMetricsData,
    totalElements,
    totalPages,
    user,
    userPrefs,
    apiPlanDetails,
  } = props;
  const intl = getI18n(useIntl());
  const [getUserPref, setUserPref] = usePrefs(userPrefs);
  const autoSuggestHandler = (val) => fetchAutoSuggestions({ searchText: val });
  const options = autoSuggestions.map(entity => ({ key: entity.uuid, value: entity.name }));
  const recentOptions = recentSearches.map(value => ({ key: value, value }));
  const noResultsMessage = intl.getI18nMessage('label.api.list.no.results');
  const [rowsPerPage, setRowsPerPage] = useState(GRID_ROWS_PER_PAGE_DEFAULT_OPTION);
  const [page, setPage] = useState(0);
  const onChangePreviousPage = () => { onChangePage(page - 1); };
  const onChangeNextPage = () => { onChangePage(page + 1); };
  const [visibilityFilterValue, setVisibilityFilterValue] = useState('All');
  const [stateFilterValue, setStateFilterValue] = useState('All');
  const [sortFilterValue, setSortFilterValue] = useState('name,ASC');
  const [nameFilterValue, setNameFilterValue] = useState('');
  const [nameFilterInputValue, setNameFilterInputValue] = useState('');
  const [descriptionFilterValue, setDescriptionFilterValue] = useState('');
  const [gridView, setGridView] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [prefsRead, setPrefsRead] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const notifyMessages = (status, message) => {
    setNotificationStatus(status);
    setNotificationMessage(message);
  };
  useEffect(() => {
    fetchAllTags();
  }, []);
  useEffect(() => {
    if (shouldRefreshMetricsData && apis.length > 0 && gridView) {
      const apisIDs = apis.map(item => item.uuid);
      fetchLatencyData(apisIDs);
    }
  }, [shouldRefreshMetricsData, gridView]);
  const { username, tenantId } = user;
  useEffect(() => {
    if (username && tenantId) {
      setGridView(getUserPref(username, tenantId, 'API_VIEW') === 'GRID' ? true : false );
      if (getUserPref(username, tenantId, 'PAGE_SIZE')) {
        setRowsPerPage(getUserPref(username, tenantId, 'PAGE_SIZE'));
      }
      if (getUserPref(username, tenantId, 'API_SORT')) {
        setSortFilterValue(getUserPref(username, tenantId, 'API_SORT'));
      }
      setPrefsRead(true);
    }
  }, [username, tenantId]);
  useEffect(() => {
    if (prefsRead) {
      const hashParams = get(props, 'location.hash');
      const hashArray = hashParams.split("/");
      let apiPlanUuid = '';
      if (hashArray && hashArray.length === 2) {
        const entityType = hashArray[0].substring(1);
        if(entityType === "api-plans") {
          apiPlanUuid = hashArray[1];
          fetchApiPlanDetails(apiPlanUuid);
         }
      }
      fetchAPIs({
        page,
        size: rowsPerPage,
        ...(nameFilterValue && {
          name: nameFilterValue,
        }),
        ...(descriptionFilterValue && {
          description: descriptionFilterValue,
        }),
        sort: sortFilterValue,
        ...((visibilityFilterValue !== 'All') && {
          accessStatus: visibilityFilterValue,
        }),
        ...((stateFilterValue !== 'All') && {
          portalStatus: stateFilterValue,
        }),
        ...((selectedTags.length > 0) && {
          tags: selectedTags,
        }),
        ...((apiPlanUuid) && {
          apiPlanUuid: apiPlanUuid,
        }),
      });
    }
  }, [
    page,
    prefsRead,
    rowsPerPage,
    nameFilterValue,
    descriptionFilterValue,
    sortFilterValue,
    stateFilterValue,
    visibilityFilterValue,
    selectedTags,
  ]);
  useEffect(() => {
    if(apiPlanDetails && apiPlanDetails.name) {
      notifyMessages(INFO,
        `Currently filtering Applications by API Plan → ${apiPlanDetails.name}`);
    }
  }, [apiPlanDetails]);
  const onChangePage = (newPage) => {
    setPage(newPage);
  };
  const onChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setUserPref(username, tenantId, 'PAGE_SIZE', newRowsPerPage);
    setPage(0);
  };
  const onFilterByStateChange = (value) => {
    setStateFilterValue(value);
    setPage(0);
  };
  const onFilterByVisibilityChange = (value) => {
    setVisibilityFilterValue(value);
    setPage(0);
  };
  const onFilterBySortChange = (value) => {
    setSortFilterValue(value);
    setUserPref(username, tenantId, 'API_SORT', value);
    setPage(0);
  };
  const onFilterByNameChange = () => {
    setNameFilterValue(nameFilterInputValue);
    setDescriptionFilterValue(nameFilterInputValue);
    setPage(0);
  };
  const onFilterByNameSelectionChange = (option) => {
    if (option && option.value) {
      setNameFilterInputValue(option.value);
      setNameFilterValue(option.value);
      setDescriptionFilterValue(option.value);
      setPage(0);
    }
  };
  const onFilterByNameInputChange = (value) => {
    setNameFilterInputValue(value);
  };
  const updateGridView = (value) => {
    setUserPref(username, tenantId, 'API_VIEW', value ? 'GRID' : 'TABLE');
    setGridView(value);
  };
  const renderViewBtns = () => (
    <>
      <Button
        className={gridView ? classes.viewBtn__selected : classes.viewBtn}
        onClick={() => updateGridView(true)}
      >
        {gridView ? (
          <img
            src={IconGridSelected}
            className={classes.viewIcon}
            alt="Grid Selected"
          />
        ) : (
          <img
            src={IconGrid}
            className={classes.viewIcon}
            alt="Grid"
        />)}
      </Button>
      <Button
        className={gridView ? classes.viewBtn : classes.viewBtn__selected}
        onClick={() => updateGridView(false)}
      >
        {gridView ? (
          <img
            src={IconList}
            className={classes.viewIcon}
            alt="List"
          />
        ) : (
          <img
            src={IconListSelected}
            className={classes.viewIcon}
            alt="List"
          />
        )}
      </Button>
    </>
  );
  // tags related code
  const allTagsOptions = allTags.map(({ name }) => ({ value: name, label: name }));
  const onTagsSelectionChange = (items) => {
    onTagsSelect(uniq(map(items, item => item.value)));
  };
  const [popupTags, setPopupTags] = useState([]);
  const [popupSelectedTags, setPopupSelectedTags] = useState([]);
  const [isApiTagSelectDialogOpen, setIsApiTagSelectDialogOpen] = useState(false);
  const onTagsSelect = (tags, apiTags) => {
    if (!tags) {
      setPopupTags(apiTags);
      const popupSelectedTagsParams = intersection(selectedTags, apiTags);
      setPopupSelectedTags(popupSelectedTagsParams);
      setIsApiTagSelectDialogOpen(true);
    } else {
      setPage(0);
      setSelectedTags(uniq(tags));
    }
  };
  const onTagClick = (tag, apiTags) => {
    if (tag) {
      onTagsSelect(uniq([...selectedTags, tag]), apiTags);
    } else {
      onTagsSelect(null, apiTags);
    }
  };

  const onPopupTagsSelectionChange = (tags) =>
  setPopupSelectedTags(map(tags, item => item.value));

  const renderTagModalDialog = () => {
    const apiTagsOptions = map(popupTags, name => ({ value: name, label: name }));
    return (
      <div className={classes.selectWrapper}>
        <MultiSelect
          menuIsOpen
          name="tags"
          onChange={onPopupTagsSelectionChange}
          noOptionsMessage={() => intl.getI18nMessage('label.application.api.tag.nooptions.text')}
          optionsData={apiTagsOptions}
          placeholder={intl.getI18nMessage('label.application.api.tag.placeholder.text')}
          value={popupSelectedTags}
        />
      </div>
    );
  };

  const onTagsPopupSubmit = () => {
    const newTags = union(difference(selectedTags, popupTags), popupSelectedTags);
    setPage(0);
    setSelectedTags(newTags);
    setIsApiTagSelectDialogOpen(false);
  };

  let rowsData = map(apis, (item) => ({ ...item, onTagClick }));

  const addAnalyticsData = (items) => {
    const latencyDataMap = {};
    each(latencyData, (item) => {
      latencyDataMap[item.apiId] = item;
    });
    const errorData = [];
    const errorDataMap = {};
    each(errorData, (item) => {
      errorDataMap[item.apiId] = item;
    });
    const updatedItems = [];
    each(items, (item) => {
      const updatedItem = Object.assign({}, item);
      updatedItem.isAnalyticsEnabled = isAnalyticsEnabled;
      updatedItem.isErrorMetricsDisabled = true;
      updatedItem.metricsData = {};
      if (latencyData && latencyData.error) {
        updatedItem.metricsData.latency = latencyData;
      } else {
        updatedItem.metricsData.latency =
          latencyDataMap[item.uuid] && latencyDataMap[item.uuid].avg;
      }
      updatedItem.metricsData.error =
        errorDataMap[item.uuid] && errorDataMap[item.uuid].errors;
      updatedItems.push(updatedItem);
    });
    return updatedItems;
  };
  if (gridView) {
    rowsData = addAnalyticsData(rowsData);
  }

  const renderControls = () =>
    (<>
      <Grid item className={classes.searchBarContainerWrapper}>
        <label className={classes.filterLabel}>
          {intl.getI18nMessage('label.filter')}
        </label>
        <Grid className={classes.searchBarContainer}>
          <SearchBar
            autoSuggestSuggestions={options}
            dispatchAutoSuggest={autoSuggestHandler}
            fetchRecentSearch={fetchRecentAPISearches}
            handleSelection={onFilterByNameSelectionChange}
            handleInputChange={onFilterByNameInputChange}
            handleTextChange={onFilterByNameChange}
            inputValue={nameFilterInputValue}
            placeHolderText={'Search APIs'}
            recentSearches={recentOptions}
          />
        </Grid>
      </Grid>
      <FilterByVisibility
        fieldContainerClass={classes.visibilityContainer}
        name={intl.getI18nMessage('label.filter')}
        selectFieldClass={classes.selectField}
        value={visibilityFilterValue}
        handleChange={onFilterByVisibilityChange}
        hideLabel
      />
      <FilterByState
        fieldContainerClass={classes.fieldContainer}
        name={intl.getI18nMessage('label.filter')}
        selectFieldClass={classes.selectField}
        value={stateFilterValue}
        handleChange={onFilterByStateChange}
        hideLabel
      />
      <FilterByTags
        fieldContainerClass={classes.tagsContainer}
        name="tags"
        onChange={onTagsSelectionChange}
        noOptionsMessage={() => intl.getI18nMessage('label.application.api.tag.nooptions.text')}
        optionsData={allTagsOptions}
        placeholder={intl.getI18nMessage('label.application.api.tag.placeholder.text')}
        value={selectedTags}
      />
      <Grid item className={classes.sortContainer}>
        <SortBy
          fieldContainerClass={classes.fieldContainer}
          name={intl.getI18nMessage('label.sort')}
          selectFieldClass={classes.selectField}
          value={sortFilterValue}
          handleChange={onFilterBySortChange}
        />
        {renderViewBtns(false)}
      </Grid>
    </>);
  
  const onAdd = () => push('/publish/apis/add');
  const onCloseNotification = () => {
    window.location.href = '/publish/apis';
  };

  return (<>
    { !gridView ?
      <ListContainer
        listPageId="apis-list-page"
        isLoading={isAPIsListLoading}
        notificationId="api-list-notifications"
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
        onCloseNotification={onCloseNotification}
        closeNotificationText={intl.getI18nMessage('label.application.list.notification.close.button.text')}
        pageHeaderTitle={intl.getI18nMessage('label.api.list.page.title')}
        addButtonLabel={intl.getI18nMessage('label.api.add')}
        disableAdd={!canAddAPI}
        onAdd={onAdd}
        filterAndSortContent={renderControls()}
        columns={getAPIListColumns(intl, classes) || []}
        rows={rowsData}
        noResultsMessage={noResultsMessage}
        page={page}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
      />
    :
      <GridContainer
        listPageId="apis-list-page"
        isLoading={isAPIsListLoading}
        notificationId="api-list-notifications"
        notificationStatus={''}
        setNotificationStatus={() => {}}
        notificationMessage={''}
        setNotificationMessage={() => {}}
        pageHeaderTitle={intl.getI18nMessage('label.api.list.page.title')}
        addButtonLabel={intl.getI18nMessage('label.api.add')}
        disableAdd={!canAddAPI}
        onAdd={onAdd}
        filterAndSortContent={renderControls()}
        rows={rowsData}
        noResultsMessage={noResultsMessage}
        page={page}
        rowsPerPageOptions={GRID_ROWS_PER_PAGE_OPTIONS}
        totalElements={totalElements}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangePreviousPage={onChangePreviousPage}
        onChangeNextPage={onChangeNextPage}
        renderGridItem={renderGridItem}
      />
    }
    <Dialog
      dialogId="api-list-tag-dialog"
      isOpen={isApiTagSelectDialogOpen}
      title={intl.getI18nMessage('label.api.tags.title')}
      submitText={intl.getI18nMessage('label.application.api.tag.filter.text')}
      onSubmit={onTagsPopupSubmit}
      cancelText={intl.getI18nMessage('label.cancel.button')}
      onCancel={() => { setIsApiTagSelectDialogOpen(false); }}
    >
      {renderTagModalDialog()}
    </Dialog>
  </>);
};
  
ApiListPage.propTypes = {
  apis: arrayOf(object),
  canAddAPI: bool,
  shouldRefreshMetricsData: bool,
  latencyData: arrayOf(object),
  allTags: arrayOf(string),
  autoSuggestions: arrayOf(object),
  classes: object,
  fetchAPIs: func,
  fetchAllTags: func,
  push: func,
  fetchAutoSuggestions: func,
  fetchLatencyData: func,
  fetchRecentAPISearches: func,
  isAnalyticsEnabled: bool,
  isAPIsListLoading: bool,
  recentSearches: arrayOf(string),
  totalElements: number,
  totalPages: number,
  user: object,
  userPrefs: object,
  fetchApiPlanDetails: func,
  apiPlanDetails: object,
};

const mapStateToProps = (state) => ({
  apis: getAPIs(state),
  canAddAPI: getCanAddAPI(state),
  shouldRefreshMetricsData: getShouldRefreshMetricsData(state),
  latencyData: getMetricsLatencyData(state),
  allTags: getAllPortalTags(state),
  autoSuggestions: getAutoSuggestions(state),
  isAnalyticsEnabled: getIsAnalyticsEnabled(state),
  isAPIsListLoading: getIsAPIsListLoading(state),
  recentSearches: getRecentSearches(state),
  totalElements: getAPIListTotalElements(state),
  totalPages: getAPIListTotalPages(state),
  user: getUserDetails(state),
  userPrefs: getUserPrefs(state),
  apiPlanDetails: getSingleApiPlan(state),
});

const mapDispatchToProps = {
  push,
  fetchAllTags,
  fetchAPIs,
  fetchAutoSuggestions,
  fetchLatencyData,
  fetchRecentAPISearches,
  fetchApiPlanDetails,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiListPage);