// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  getThemes,
  getCmsSettings,
} from '../../reducers/portalConfig';
import Branding from './Branding';
import styles from './index.theme';

export const NonAuthHeader = (props) => {
  const {
    logo,
    siteTitle,
    classes,
    registrationStatus,
    localLoginsDisabled,
  } = props;

  return (
    <div className={classes.Header}>
      <div className={classes.HeaderInner}>
        <Branding
          logo={logo}
          siteTitle={siteTitle}
          homeHref={'/admin/homeRedirect'}
        />
        <div className={classes.buttonContainer} >
          {registrationStatus === 'ENABLED' && localLoginsDisabled !== 'true' &&
            <Button
              id="signup-button"
              data-apim-test="signup-button"
              color="primary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={() => window.location.href = '/admin/redirect/registrationPageRedirect'}
            >
              {'Sign Up'}
            </Button>
          }
          <Button
            id="login-button"
            data-apim-test="login-button"
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onClick={() => window.location.href = '/admin/loginRedirect'}
          >
            {'Login'}
          </Button>
        </div>
      </div>
    </div>
  );
};


export const mapStateToProps = (state) => {
  const { logo, siteTitle } = getThemes(state);
  const { REGISTRATION_STATUS, DISABLE_LOCAL_LOGINS } = getCmsSettings(state);
  return {
    logo,
    siteTitle,
    registrationStatus: REGISTRATION_STATUS,
    localLoginsDisabled: DISABLE_LOCAL_LOGINS,
  };
};

export const mapDispatchToProps = {
};

NonAuthHeader.propTypes = {
  logo: PropTypes.string,
  siteTitle: PropTypes.string,
  classes: PropTypes.object,
  registrationStatus: PropTypes.string,
  localLoginsDisabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NonAuthHeader);
