import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { shape, func, string, bool } from 'prop-types';
import {
  Grid,
  withStyles,
} from '@material-ui/core';
import { get } from 'lodash';

import { getI18n } from '../../../../utils/intl';
import {
  Footer,
  HelpContainer,
  FormTextField,
  FormActionButtons,
} from '../../../../components';
import {
  fetchProduct,
  saveProducts,
  updateProducts,
} from '../../../../actions/products';
import {
  getIsProductSaveSuccess,
  getproductDetails,
} from '../../../../reducers/products';
import { PRODUCTS_ADD_HELP_ITEMS, ALERT_SUCCESS } from '../../../../constants';
import styles from './styles';


export const Overview = (props) => {
  const {
    classes,
    productName,
    productDetails,
    isProductSaveSuccess,
    productUuid,
    isFieldEditName,
    notifyMessages,
    onHideEditTitle,
    isCurrentTab,
  } = props;

  const intl = getI18n(useIntl());
  const [name, setName] = useState(productName);
  const [description, setDescription] = useState('');
  const [isFieldEdit, setIsFieldEdit] = useState(isFieldEditName);

  const onClearDescription = () => {
    setDescription(get(productDetails, 'description'));
  }

  useEffect(() => {
    if(productDetails && productDetails.name) {
      setName(productDetails.name);
      setDescription(productDetails.description);
    }
  }, [productDetails]);

  useEffect(()=> {
    setIsFieldEdit(isFieldEditName);
  }, [isFieldEditName]);

  useEffect(()=> {
    setName(productName);
  }, [productName]);

  useEffect(() =>{
    if(isCurrentTab){
      onClearDescription();
    }
  }, [isCurrentTab]);


  useEffect(() => {
    if (isProductSaveSuccess) {
      localStorage.getItem('page') === 'add' ?
        notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.product.created'))
      : notifyMessages(ALERT_SUCCESS, intl.getI18nMessage('label.product.updated'));
      props.push(`/admin/products/details/${productDetails.uuid}`);
    }
  }, [isProductSaveSuccess, productDetails]);

  const handleChange = (value) => {
    localStorage.setItem('isUnSavedChanges-products', true);
    setIsFieldEdit(true);
    setDescription(value);
  }

  const onNext = () => {
    if(productUuid) {
      localStorage.setItem('page', 'edit');
      props.updateProducts(name, description, productUuid);
    } else {
      localStorage.setItem('page', 'add');
      props.saveProducts(name, description);
    }
    localStorage.setItem('isUnSavedChanges-products', false);
    setIsFieldEdit(false);
  }

  const onCancel = () => {
    localStorage.setItem('isUnSavedChanges-products', false);
    if(productUuid) {
      onHideEditTitle();
      onClearDescription();
      setIsFieldEdit(false);
      setDescription(productDetails.description);
    } else {
      props.push('/admin/products');
    }
  }

  return (
    <div
      id="products-details-overview-container"
      data-apim-test="products-details-overview-container"
      data-layer7-test="products-details-overview-container"
    >
      <Grid container className={classes.marginPanelContainer}>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <h4 className={classes.title}>{intl.getI18nMessage('label.details')}</h4>
          <FormTextField
            id={'product-details-page-description'}
            name={intl.getI18nMessage('label.description')}
            value={description}
            handleChange={(value) => handleChange(value)}
            helperText={intl.getI18nMessage(
              'label.description.help',
            )}
            multiline
            rows={4}
            optional
            maxLength={'255'}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12} className={classes.paper}>
          <HelpContainer
            id="product-details-overview-help-container"
            helpItems={PRODUCTS_ADD_HELP_ITEMS}
            titleClass={classes.title}
          />
        </Grid>
        <FormActionButtons
          isContainerFooter={true}
          onNextClick={onNext}
          onCancelClick={onCancel}
          nextText={intl.getI18nMessage('label.save.button')}
          cancelText={intl.getI18nMessage('label.cancel.button')}
          id="product-details-form-buttons"
          containerClass={!isFieldEdit?
            classes.hideFooterContainer: classes.showFooterContainer}
        />
      </Grid>
      <Footer />
    </div>
  );
};

Overview.propTypes = {
  classes: shape({}),
  productName: string,
  productDetails: shape({}),
  isProductSaveSuccess: bool,
  productUuid: string,
  isFieldEditName: bool,
  isCurrentTab: bool,
  notifyMessages: func,
  push: func,
  updateProducts: func,
  saveProducts: func,
  onHideEditTitle: func,
};

const mapStateToProps = state => ({
  isProductSaveSuccess: getIsProductSaveSuccess(state),
  productDetails: getproductDetails(state),
});

const mapDispatchToProps = {
  saveProducts,
  updateProducts,
  fetchProduct,
};

Overview.displayName = 'Overview';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Overview);

